import React, { useContext } from 'react';
import { Table, Button, Message } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { numberFormat } from '../../../app/common/options/IStyle';



const ReglementList: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { reglements } = baseStore.reglementStore;
    return (
        <>
            {reglements?.length != 0 ?
                (
                    <Table loading={false} celled striped compact="very" style={{ textAlign: "center" }}>

                        <Table.Header >

                            <Table.Row>
                                <Table.HeaderCell>Date</Table.HeaderCell>
                                <Table.HeaderCell>Client</Table.HeaderCell>
                                <Table.HeaderCell>Référence</Table.HeaderCell>
                                <Table.HeaderCell>Montant</Table.HeaderCell>
                                <Table.HeaderCell>Mode de paiement</Table.HeaderCell>
                                <Table.HeaderCell>Compte bancaire</Table.HeaderCell>
                            </Table.Row>

                        </Table.Header>
                        {reglements &&
                            reglements.map(reglement => (
                                <Table.Body>
                                    <Table.Cell>{new Date(reglement.creationDate).toLocaleDateString('en-GB')}</Table.Cell>
                                    <Table.Cell>{reglement.agencyName}-{reglement.companyName} </Table.Cell>
                                    <Table.Cell>
                                        {reglement.reference != null ?
                                            (<a href={reglement.commande != 0 ? `/detailCommande/${reglement.commande}` : `/detailFacture/${reglement.invoice}`}>{reglement.reference}</a>) :
                                            <p>Attente d'affectation</p>
                                        }
                                    </Table.Cell>
                                    <Table.Cell>{numberFormat.format(reglement.montant)}</Table.Cell>
                                    <Table.Cell>{reglement.modePaiement}</Table.Cell>
                                    <Table.Cell>{reglement.account}</Table.Cell>
                                </Table.Body>
                            ))}
                    </Table>

                ) : (
                    <Message
                        style={{ padding: '20px 40px', margin: '40px 40px' }}
                        error
                        list={[
                            "Aucun Reglement pour le moment",

                        ]}
                    />
                )}
        </>
    );
}

export default observer(ReglementList);