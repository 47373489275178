import React from 'react'
import { Tab } from 'semantic-ui-react';
import Utilisateur from '../../clients/clientDetails/Utilisateur';
import FranchiseurAddressDescription from './FranchiseurAddressDescription';
import FranchiseurAdresse from './FranchiseurAdresse';
import AchatGroupee from './AchatGroupee';
import FranchiseurDescription from './FranchiseurDescription';
import FranchiseurFichier from './FranchiseurFichier';
import FranchiseurGestionCommercialDescription from './FranchiseurGestionCommercialDescription';
import FranchiseurPhoto from './FranchiseurPhoto';
import FranchiseurReglement from './FranchiseurReglement';
import FranchiseurRemise from './FranchiseurRemise';
import Specification from './specification';
import CommandeFranchiseur from './CommandeFranchiseur';

interface IProps {
    setActiveTab: (activeIndex: any) => void;
}

const FranchiseurContent: React.FC<IProps> = ({ setActiveTab }) => {
    const panes = [
        { menuItem: 'Detail Franchiseur', render: () => <FranchiseurDescription /> },
        { menuItem: 'Utilisateurs', render: () => <Utilisateur /> },
        //{ menuItem: 'Adresse(s) ', render: () => <FranchiseurAddressDescription /> },
        { menuItem: 'Adresses', render: () => <FranchiseurAdresse/> },
        { menuItem: 'Gestion Commerciale', render: () => <FranchiseurGestionCommercialDescription /> },
        { menuItem: 'Spécification', render: () => <Specification /> },
        //{ menuItem: 'Comptabilité', render: () => <FranchiseurComptabiliteDescription /> },
        { menuItem: 'Compte client', render: () => <FranchiseurReglement />},
        { menuItem: 'Avoirs', render: () => <FranchiseurRemise /> },
        { menuItem: 'Logo', render: () => <FranchiseurPhoto /> },
        { menuItem: 'Commandes', render: () => <CommandeFranchiseur /> },
        { menuItem: 'Fichier(s) à télécharger', render: () => <FranchiseurFichier /> },
        { menuItem: 'Achats Groupées', render: () => <AchatGroupee /> },
    ]
    return (
        <Tab
            menu={{ fluid: true, vertical: true }}
            menuPosition='right'
            panes={panes}
            onTabChange={(e, data) => setActiveTab(data.activeIndex)}
        />
    )
}

export default FranchiseurContent