export const ITListAchat = [
    {
        key: ' Gestion Commande Fournisseurs', text: 'Gestion Commande Fournisseurs', value: 'Gestion Commande Fournisseurs'
    },
    {
        key: 'Gestion Commande Stockees', text: 'Gestion Commande Stockees', value: 'Gestion Commande Stockees'
    },
    {
        key: 'Gestion Des Reception ', text: 'Gestion Des Reception', value: 'Gestion Des Reception'
    },
    {
        key: 'Gestion De Commande Client ', text: 'Gestion De Commande Client', value: 'Gestion De Commande Client'
    },
    {
        key: 'Gestion De Commande Direct ', text: 'Gestion De Commande Direct', value: 'Gestion De Commande Direct'
    },
];

export const ITListGestionPrix = [
    {
        key: 'TTC', text: 'TTC', value: 'TTC'
    },
    {
        key: 'HT', text: 'HT', value: 'HT'
    }
];


export const ITListModeImpression = [
    {
        key: 'Impression1', text: 'Impression1', value: 'Impression1'
    },
    {
        key: 'Impression1', text: 'Impression2', value: 'Impression2'
    }
];

export interface IInterfaceList {
    key: string;
    text: string;
    value: string;
}

export interface IInterfaceModeReglement {
    key: string;
    text: string;
    value: string;
}

export interface IInterfaceModePaiement {
    key: string;
    text: string;
    value: string;
}

export const ITListModeEnvoie = [
    {
        key: 'Mail', text: 'Mail', value: 'Mail'
    },
    {
        key: 'Courrier', text: 'Courrier', value: 'Courrier'
    }
];


export const ITListTypeCommission = [
    {
        key: 'Exemple1', text: 'Exemple1', value: 'Exemple1'
    },
    {
        key: 'Exemple2', text: 'Exemple2', value: 'Exemple2'
    }
];

export const ITListJoursFermeture: IInterfaceList[] = [
    {
        key: '0', text: 'Lundi', value: 'Lundi'
    },
    {
        key: '1', text: 'Mardi', value: 'Mardi'
    },
    {
        key: '2', text: 'Mercredi', value: 'Mercredi'
    },
    {
        key: '3', text: 'Jeudi', value: 'Jeudi'
    },
    {
        key: '4', text: 'Vendredi', value: 'Vendredi'
    },
    {
        key: '5', text: 'Samedi', value: 'Samedi'
    },
    {
        key: '6', text: 'Dimanche', value: 'Dimanche'
    },
];

export const ITListTypeMemo = [
    {
        key: 'Commande', text: 'Commande', value: 'Commande'
    },
    {
        key: 'Facture', text: 'Facture', value: 'Facture'
    }
];
export const ITListTypeCivilite = [
    {
        key: 'Monsieur', text: 'Monsieur', value: 'Monsieur'
    },
    {
        key: 'Madame', text: 'Madame', value: 'Madame'
    },
    {
        key: 'Mademoiselle', text: 'Mademoiselle', value: 'Mademoiselle'
    }
];
export const ITListTypeDevise = [
    {
        key: 'Euro', text: 'Euro', value: 'Euro'
    },
    {
        key: 'Dolar', text: 'Dolar', value: 'Dolar'
    },
    {
        key: 'Pound', text: 'Pound', value: 'Pound'
    },
    {
        key: 'Yen', text: 'Yen', value: 'Yen'
    },
    {
        key: 'Dinar', text: 'Dinar', value: 'Dinar'
    }
];
export const ITListTypeMarche = [
    {
        key: 'National', text: 'National', value: 'National'
    },
    {
        key: 'CEE', text: 'CEE', value: 'CEE'
    },
    {
        key: 'HorsCEE', text: 'HorsCEE', value: 'HorsCEE'
    },
];
export const ITListSeuil = [
    {
        key: 'Aucun', text: 'Aucun', value: 'Aucun'
    },
    {
        key: 'Quantite', text: 'Quantite', value: 'Quantite'
    },
    {
        key: 'Montant', text: 'Montant', value: 'Montant'
    },
    {
        key: 'Poids', text: 'Poids', value: 'Poids'
    }
];
export const ITListSeuilFranco = [
    {
        key: 'Aucun', text: 'Aucun', value: 'Aucun'
    },
    {
        key: 'Quantite', text: 'Quantite', value: 'Quantite'
    },
    {
        key: 'Montant', text: 'Montant', value: 'Montant'
    },
    {
        key: 'Poids', text: 'Poids', value: 'Poids'
    }
];
export const ITListValeur = [
    {
        key: 'Aucun', text: 'Aucun', value: 'Aucun'
    },
    {
        key: 'Forfait', text: 'Forfait', value: 'Forfait'
    },
    {
        key: 'Poucentage', text: 'Poucentage', value: 'Poucentage'
    }
];
export const ITListTypeRemise = [
    {
        key: 'Aucun', text: 'Aucun', value: 'Aucun'
    },
    {
        key: 'Fixe', text: 'Fixe', value: 'Fixe'
    },
    {
        key: 'Poucentage', text: 'Poucentage', value: 'Poucentage'
    }
];
export const ITListTarifBase = [
    {
        key: 'TarifBrut', text: 'Tarif Brut', value: 'TarifBrut'
    },
    {
        key: 'TarifNet', text: 'Tarif Net', value: 'TarifNet'
    }
];
export const ITListTolerenceFacture = [
    {
        key: 'AucunEcart', text: 'AucunEcart', value: 'AucunEcart'
    },
    {
        key: 'EcartSpecifiqueMontant', text: 'Ecart Specifique Montant', value: 'EcartSpecifiqueMontant'
    },
    {
        key: 'EcartSpecifiquePourcentage', text: 'Ecart Specifique Pourcentage', value: 'EcartSpecifiquePourcentage'
    }
];
export const ITListModeAppro = [
    {
        key: 'VenteDirectePersonalise', text: 'Prod interne', value: 'VenteDirectePersonalise'
    },
    {
        key: 'VenteDirecte', text: 'Vente Directe', value: 'VenteDirecte'
    },
    {
        key: 'Stock', text: 'Stock', value: 'Stock'
    }
];
export const ITListTva = [
    {
        key: 'tva1', text: 'tva1', value: 'tva1'
    },
    {
        key: 'tva2', text: 'tva2', value: 'tva2'
    }
];
export const ITListEcoContribution = [
    {
        key: 'eco1', text: 'eco1', value: 'eco1'
    },
    {
        key: 'eco2', text: 'eco2', value: 'eco2'
    }
];

export const ITListTypeVente = [
    {
        key: 'VenteDirecte', text: 'Vente Directe', value: 'VenteDirecte'
    },
    {
        key: 'VenteAchatGroupe', text: 'Vente Achat Groupe', value: 'VenteAchatGroupe'
    },
    {
        key: 'VenteStock', text: 'Vente Stock', value: 'VenteStock'
    }
];


export const ITListStatutCommand = [
    {
        key: 'SentToCrm', text: 'Ecommerce', value: 'SentToCrm'
    },
    {
        key: 'WaitingValidation', text: 'En Attente', value: 'WaitingValidation'
    },
    {
        key: 'Validated', text: 'Validee', value: 'Validated'
    },
    {
        key: 'Delivered', text: 'Livree', value: 'Delivered'
    },
    {
        key: 'Cancelled', text: 'Annulee', value: 'Cancelled'
    },
    {
        key: 'Rejected', text: 'Rejetee', value: 'Rejected'
    },   
    {
        key: 'Prepared', text: 'Preparee', value: 'Prepared'
    },
    {
        key: 'Dispatched', text: 'Expediee', value: 'Dispatched'
    },
    {
        key: 'Facture', text: 'Facturee', value: 'Facture'
    },
    {
        key: 'Partially_Prepared', text: 'Preparee partiellement', value: 'Partially_Prepared'
    },
    {
        key: 'Preparing', text: 'Preparation EnCours', value: 'Preparing'
    },
    {
        key: 'Delivering', text: 'Livraison EnCours', value: 'Delivering'
    },
    {
        key: 'WaitingForOnlinePayment', text: 'En attente de paiement', value: 'WaitingForOnlinePayment'
    },
    {
        key: 'OnlinePaymentAccepted', text: 'Paiement en ligne accepte', value: 'OnlinePaymentAccepted'
    }
];

export const ITListStatutDevis = [
    {
        key: 'EnAttente', text: 'En Attente', value: 'EnAttente'
    },
    {
        key: 'Valide', text: 'Valide', value: 'Valide'
    }
];

export const ITListDeliveryService = [
    {
        key: 'ETIKEO', text: 'ETIKEO', value: 'ETIKEO'
    },
    {
        key: 'CHRONOPOST', text: 'CHRONOPOST', value: 'CHRONOPOST'
    },
    {
        key: 'EXAPRINT', text: 'EXAPRINT', value: 'EXAPRINT'
    }
];

 export const ITListTypeFranchiseur = [
    {
        key: 'Franchiseur', text: 'Franchiseur', value: 'Franchiseur'
    },
    {
        key: 'Franchise', text: 'Franchise', value: 'Franchise'
    },
    {
        key: 'Succursale', text: 'Succursale', value: 'Succursale'
    }

];

export const ITListOrderFournisseurState = [
    {
        key: 'AttenteValidation', text: 'Attente Validation', value: 'AttenteValidation'
    },
    {
        key: 'Validee', text: 'Validee', value: 'Validee'
    },
    {
        key: 'Livree', text: 'Livree', value: 'Livree'
    },
    {
          key: 'Annulee', text: 'Annulee', value: 'Annulee'
    },
];
export const ITListSort = [
    {
        key: 'AZ', text: 'A-Z', value: 'AZ'
    },
    {
        key: 'ZA', text: 'Z-A', value: 'ZA'
    },
    {
        key: 'DateUp', text: 'Plus recent', value: 'DateUp'
    },
    {
        key: 'DateDown', text: 'Plus ancien', value: 'DateDown'
    },
];
export const ITListType = [
    {
        key: 'Banque', text: 'Banque', value: 'Banque'
    },
    {
        key: 'Caisse', text: 'Caisse', value: 'Caisse'
    },

];
export const ITListSide = [
    {
        key: 'Recto', text: 'Recto', value: 'recto'
    },
    {
        key: 'Verso', text: 'Verso', value: 'verso'
    },
    {
        key: 'RectoVerso', text: 'RectoVerso', value: 'rectoverso'
    },
];
export const ITListCustomizedType = [
    {
        key: 'None', text: 'Aucun', value: 'None'
    },
    {
        key: 'Online', text: 'En Ligne', value: 'Online'
    },
    {
        key: 'offline', text: 'Hors Ligne', value: 'offline'
    },
];
export const ITListProductType = [
    {
        key: 'Print', text: 'Impression', value: 'Print'
    },
    {
        key: 'PAO', text: 'PAO', value: 'PAO'
    },
    {
        key: 'PLV', text: 'PLV', value: 'PLV'
    },
    {
        key: 'Goodies', text: 'Goodies', value: 'Goodies'
    },
    {
        key: 'Textile', text: 'Textile', value: 'Textile'
    },
    {
        key: 'EPI', text: 'EPI', value: 'EPI'
    },
    {
        key: 'Other', text: 'Autre', value: 'Other'
    },
];

export const ITListBillingType = [
    {
        key: 0, text: 'Une seule facture', value: 'BillByCompany'
    },
    {
        key: 1, text: 'Commande', value: 'BillByCm'
    },
    {
        key: 2, text: 'Bon de livraison', value: 'BillByBl'
    },
];
export const ITListPaymentMode = [
    {
        key: 31, text: 'Carte Bancaire', value: '31'
    },
    {
        key: 3, text: 'Cheque Bancaire', value: '03'
    },
    {
        key: 1, text: 'Especes', value: '01'
    },
    {
        key: 15, text: 'Prelevement Bancaire', value: '15'
    },
    {
        key: 11, text: 'Virement Bancaire', value: '11'
    },
    {
        key: 'Impaye', text: 'En attente de paiement', value: 'Impaye'
    },
];
export const ITListPayment = [
    {
        key: 'paye', text: 'Paye', value: 'paye'
    },
    {
        key: 'PartiellemntPaye', text: 'Partiellement Paye', value: 'PartiellemntPaye'
    },
    {
        key: 'impaye', text: 'Impaye', value: 'impaye'
    },
  
];
