import { observer } from "mobx-react-lite";
import React, { useContext } from 'react';
import { Checkbox, Container, Header } from "semantic-ui-react";
import Loading from "../../app/layout/Loading";
import { BaseStoreContext } from '../../app/store/baseStore';


const ListeRolesModal = () => {

    const baseStore = useContext(BaseStoreContext);
    const { userRoleModel, changeRoles } = baseStore.usersRoleStore;

    return (
        <Container>
            <Header icon='add' content='Ajout/Suppression des roles' style={{ marginLeft: 20 }} />
            <p>
                Vous pouver modifier les roles
            </p>

            {userRoleModel == null && <Loading content="Chargement..." />}

            {userRoleModel! && userRoleModel.roles.map((role: any, key: any) => (
                <div className="checkbox-wrapper">
                    <label>
                        <Checkbox className="form-check-input" checked={role.isSelected} value={role.isSelected} onClick={() => { changeRoles(role.displayValue) }} />  &nbsp;
                        <span>{role.displayValue} </span> <br></br>
                    </label>
               </div>
            )
            )}
        </Container>
       )
}
export default observer(ListeRolesModal);