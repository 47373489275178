import React, { useContext } from 'react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import {Button, Grid,Item  } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import ArticleListItem from './ArticleListItem';
import AucunArticle from './AucunArticle';
import { IProductItem } from '../../../app/models/IArticle';

const ArticleList: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const {  articles } = baseStore.articleStore;

    if (articles.length < 1) return < AucunArticle />
    else
    return (
        <Item.Group >          
            <Grid>
                <Grid.Row columns={4}>                   
                    {articles.map((article: IProductItem) => (
                    <Grid.Column>
                            <ArticleListItem article={article} />
                    </Grid.Column>                   
                ))}
                    </Grid.Row>
            </Grid>
        </Item.Group>
    );
}
export default observer(ArticleList);

