import React, { useContext, useEffect, useState } from 'react';
import { Tab, Header, Button, Grid, Table, Form, Label } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../app/store/baseStore';
import TextInput from '../../app/common/form/inputText';
import AffectArticleToPacks from './AffectArticleToPacks';
import DeleteArticleCompose from './DeleteArticleCompose';
import { Link, useParams } from 'react-router-dom';

interface UrlParams {
    id: string;
}

const ArticleComposeDescription = () => {
    const baseStore = useContext(BaseStoreContext)
    const { deletingPack, edit, submitting, packs, loadListe } = baseStore.articleComposeStore;
    const { article, loadArticlesForPack } = baseStore.articleStore;
    const [addPackMode, setAddPackMode] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    const [editTarget, setEditTarget] = useState<string | undefined>(undefined);
    const [editForm, setEditForm] = useState(false);
    const [quantite, setQuantite] = useState<number>();
    const { openModal } = baseStore.modalStore;
    const { id } = useParams<UrlParams>();

    useEffect(() => {
        loadArticlesForPack(id);
        loadListe();
    }, [])

    const handleChangeQuantite = (e: any, result: any) => {
        setQuantite(result.value);
    }

    const handleFinalFormSubmit = () => {
        let newPack = {
            quantite: quantite,
            articleComposeId: parseInt(editTarget!)
        };
        edit(newPack);
        setEditForm(false)
    }
    return (
        <Tab.Pane>
            <Grid>
                <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                    <Header floated='left' icon='shopping basket' content={addPackMode ? 'Ajout Packs' : ' Packs'} />
                    <Button
                        onClick={() => setAddPackMode(!addPackMode)}
                        floated='right'
                        basic
                        content={addPackMode ? 'Annuler' : 'Ajouter Packs'}
                    />
                </Grid.Column>
                <Grid.Column width={16}>
                    {addPackMode ? (
                        <AffectArticleToPacks setMode={setAddPackMode}
                        />
                    ) : (
                        <>
                            <Table loading={false} celled>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Articles</Table.HeaderCell>
                                        <Table.HeaderCell>Quantité</Table.HeaderCell>
                                        <Table.HeaderCell style={{ textAlign: "center" }}>Actions</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {packs &&
                                        packs.filter(x => x.idPack == article?.productId.toString()).map(pack => (
                                            <Table.Row key={pack.articleComposeId}>
                                                {editForm && editTarget === pack.articleComposeId.toString() ? (
                                                    <>
                                                        <Table.Cell>{pack.article}-{pack.articleLibelle}</Table.Cell>
                                                        <Table.Cell>
                                                            <Form.Input style={{ height: '37px', width: '80px' }} name='quantite' component={TextInput} defaultValue={pack!.quantite} type='number' onChange={handleChangeQuantite} />
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            <Button.Group fluid widths={2}>
                                                                <Button
                                                                    type='button'
                                                                    onClick={() => {
                                                                        setEditForm(false);
                                                                    }}>
                                                                    Annuler
                                                                </Button>
                                                                <Button.Or />
                                                                <Button
                                                                    name={pack.articleComposeId}
                                                                    positive
                                                                    type='submit'
                                                                    onClick={(e) => {
                                                                        handleFinalFormSubmit()

                                                                    }}
                                                                    loading={submitting}
                                                                >Confirmer</Button>
                                                            </Button.Group>
                                                        </Table.Cell>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Table.Cell>
                                                            <a style={{ backgroundColor: 'none' }} href={`/detailArticle/${pack.article}`}>
                                                                {pack.article}-{pack.articleLibelle}
                                                            </a>
                                                        </Table.Cell>
                                                        <Table.Cell>{pack!.quantite}</Table.Cell>
                                                        <Table.Cell>
                                                            <Button.Group fluid widths={3}>
                                                                <Button
                                                                    onClick={(e) => {
                                                                        setEditForm(true);
                                                                        setEditTarget(e.currentTarget.name)
                                                                        setQuantite(pack.quantite)
                                                                    }}
                                                                    basic
                                                                    color="blue"
                                                                    icon='edit outline'
                                                                    name={pack.articleComposeId}
                                                                    style={{ marginRight: '2%' }}
                                                                />
                                                                <Button
                                                                    name={pack.articleComposeId}
                                                                    onClick={(e) => {
                                                                        openModal(<DeleteArticleCompose PackId={pack!.articleComposeId} />)
                                                                        setDeleteTarget(e.currentTarget.name)
                                                                    }}
                                                                    loading={deletingPack && deleteTarget === pack.articleComposeId.toString()}
                                                                    basic
                                                                    negative
                                                                    icon='trash'
                                                                />
                                                            </Button.Group>
                                                        </Table.Cell>
                                                    </>
                                                )}
                                            </Table.Row>
                                        ))}
                                </Table.Body>
                            </Table>
                        </>
                    )}
                </Grid.Column>
            </Grid>
        </Tab.Pane>
    );
};
export default observer(ArticleComposeDescription);
