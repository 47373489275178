import React from 'react';
import { Header, Grid, Segment, Item, Statistic, Divider, Reveal, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import NotFound from '../../../app/layout/NoFound';
import { IFournisseur } from '../../../app/models/IFournisseur';

interface IProps {
    fournisseur: IFournisseur,
    loading: boolean,
    isLoggedIn: boolean;
    disable: (id: number) => Promise<void>;
    enable: (id: number) => Promise<void>;
}
const FournisseurHeader: React.FC<IProps> = ({ fournisseur, loading, isLoggedIn, disable, enable }) => {
    if (!fournisseur) return <NotFound />;

    return (
        <Segment>
            <Grid>
                <Grid.Column width={12}>
                    <Item.Group>
                        <Item>
                            <Item.Image avatar size='small' src={fournisseur.uri} />
                            <Item.Content verticalAlign='middle'>
                                <Header as='h1'>{fournisseur!.fabricant}</Header>
                            </Item.Content>
                        </Item>
                    </Item.Group>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Statistic.Group widths={1}>
                        <Statistic label='Etat Fournisseur' value={fournisseur.isActive} />
                    </Statistic.Group>
                    <Divider />
                    {isLoggedIn &&
                        <Reveal animated='move'>
                            <Reveal.Content visible style={{ width: '100%' }}>
                                <Button
                                    fluid
                                    color={fournisseur.isActive ? 'green' : 'red'}
                                    content={fournisseur.isActive ? 'Fournisseur Active' : 'Fournisseur désactivé '}
                                />
                            </Reveal.Content>
                            <Reveal.Content hidden>
                                <Button
                                    loading={loading}
                                    fluid
                                    basic
                                    color={fournisseur.isActive ? 'red' : 'green'}
                                    content={fournisseur.isActive ? 'Désactiver' : 'Activer'}
                                    onClick={
                                        fournisseur.isActive
                                            ? () => disable(fournisseur.fournisseurId)
                                            : () => enable(fournisseur.fournisseurId)
                                    }
                                />
                            </Reveal.Content>
                        </Reveal>
                    }
                </Grid.Column>
            </Grid>
        </Segment >
    );
};
export default observer(FournisseurHeader);