import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Breadcrumb, Grid, Icon, Segment } from "semantic-ui-react"

interface IProps {
    key: number,
    name: string,
    root: string,
}
interface ListIprops {
    items: IProps[],
}
const Breadcrumbs: React.FC<ListIprops> = ({ items }) => {

    return (
        <Grid>
            <Grid.Row columns={1} style={{ paddingBottom: 0 }}>
                <Grid.Column style={{ padding: "0 1rem", margin: 0 }}>
                    <Segment style={{ padding: "1rem" }}>
                        {items.length > 2 &&
                            <span style={{ float: "right", textDecoration: "underline" }}>
                                <Link key={items[items.length - 2].key} to={items[items.length - 2].root} className="section"> Retour liste
                            </Link>
                            </span>
                        }
                        <Breadcrumb >
                            {items.splice(0, items.length - 1).map((item: any) => (
                                item.root == "" ? (
                                    <a key={item.key} className="section"> {item.name}
                                        <Icon className="icon divider iconfont icon-BAI-youjiantou" />
                                    </a>
                                        ): (
                                        <Link key={item.key} to={item.root} className="section"> {item.name}
                                            <Icon className="icon divider iconfont icon-BAI-youjiantou" />
                                        </Link>
                                    )     
                            ))}
                            <a key={items[items.length - 1].key} href={items[items.length - 1].root} className="active section">{items[items.length - 1].name}
                            </a>
                           
                            
                        </Breadcrumb>
                    </Segment>
                   
                </Grid.Column>
               
            </Grid.Row>
        </Grid>
    );
}
export default observer(Breadcrumbs);
   
 
