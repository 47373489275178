import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { Button, Header, Icon, Container, Table, Input, Checkbox } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { IReceptionLine } from '../../../app/models/IReception';

interface IProps {
    id: number;
    lines: IReceptionLine[];
    setModalOpen: Dispatch<SetStateAction<boolean>>;
}

const UpdateReceptionLineDatesModal: React.FC<IProps> = ({ id, lines, setModalOpen }) => {
    const baseStore = useContext(BaseStoreContext);
    const { editReceptionLineDate } = baseStore.receptionStore;
    const [newDate, setNewDate] = useState<string>(new Date().toISOString());
    const [line, setLine] = useState<IReceptionLine[]>([]);
    const [changeQuantity, setchangeQuantity] = useState(false);
    const [valid, setValid] = useState(true);


    const handleChangeSelectedItem = (item: IReceptionLine) => {
        var itemTab: IReceptionLine[] = [];
        for (let i = 0; i < line.length; i++) {
            itemTab.push(line[i]);
        }
        if (itemTab.findIndex(x => x.id == item.id) != -1)
            itemTab = itemTab.filter(x => x != item);
        else {
            itemTab.push(item);
        }
        setLine(itemTab);
    }
    const handleChangeDate = (e: any) => {
        setValid(false);
        setNewDate(e.target.value);
    }
    const handleChangeQuantity = (quantity: number, item: IReceptionLine) => {
        var itemTab: IReceptionLine[] = line;
        if (quantity != item.requieredQuantity) {
            let newObj: IReceptionLine = {
                deliveryDelay: item.deliveryDelay,
                id: item.id,
                paperFormat: item.paperFormat,
                orderFournisseurLine: item.orderFournisseurLine,
                orderReception: item.orderReception,
                paperWeight: item.paperWeight,
                printing: item.printing,
                receivedQuantity: item.receivedQuantity,
                receptionState: item.receptionState,
                requieredQuantity: quantity,
                weight: item.weight
            };
            itemTab = itemTab.filter(x => x.id != item.id);
            itemTab.push(newObj);
            setchangeQuantity(true);
        }

        setLine(itemTab);
    }

    const handleSubmit = () => {
        if (line.length == 0)
            toast.warning('Selectionnez au moins un article');
        else {
            let newObj: any = {
                id: id,
                dateReception: newDate,
                lines: line,
                changeQuantity: changeQuantity,
            };
            editReceptionLineDate(newObj);
            setModalOpen(false)
        }
    }

    return (
        <Container textAlign='center'>
            <Header icon='edit' content='Configuration les dates de livraison' style={{ margin: "10px 0 40px 20px" }} />
            <div style={{ display: "flex", marginBottom: 10 }}>
                <Input name='creationDate' onChange={handleChangeDate} value={newDate} type='date' />
            </div>
            <Table style={{ border: "none" }}>
                <Table.Body style={{ border: "none" }}>
                    {lines.filter(x => x.receptionState != "Receptionnee").map(item => (
                        <Table.Row key={item.id}>
                            <Table.Cell>
                                <Checkbox style={{ float: 'right', padding: '0 10px', paddingTop: 10 }} onClick={() => { handleChangeSelectedItem(item) }} />
                            </Table.Cell>
                            <Table.Cell>{item.orderFournisseurLine.codeArticle} - {item.orderFournisseurLine.article}</Table.Cell>
                            <Table.Cell>
                                <Input disabled={line.findIndex(x => x.id == item.id) == -1 ? true : false} name='receivedQuantity' type='number' placeholder='quantitePrepare' style={{ width: '60%', padding: '1%' }}
                                    defaultValue={item.requieredQuantity} max={item.requieredQuantity} min={item.receivedQuantity}
                                    onChange={(e) => handleChangeQuantity(parseInt(e.target.value), item)}
                                />
                            </Table.Cell>
                        </Table.Row>
                    ))}

                </Table.Body>
            </Table>
            <Button.Group style={{ marginTop: 30, marginBottom: 20 }}>
                <Button onClick={() => { setModalOpen(false); }}>
                    <Icon name='remove' /> Annuler
                </Button>
                <Button
                    color='green'
                    disabled={valid || line.length == 0}
                    onClick={(e) => {
                        handleSubmit();
                    }}>
                    <Icon name='checkmark' /> Confirmer
                </Button>
            </Button.Group>

        </Container>
    );
}
export default observer(UpdateReceptionLineDatesModal);