import { BaseStore } from "./baseStore";
import { observable, action, runInAction } from 'mobx';
import agent from '../agent/agent';
import { toast } from "react-toastify";
import { setImpressionProps } from "../common/util/util";
import { IImpression, IImpressionList } from "../models/IImpression";

export default class ImpressionStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
    }
    @observable loadingImpressionAction = false;
    @observable loadingImpression = false;
    @observable loadingInitial = false;
    @observable submittingImpression = false;
    @observable deletingImpression = false;
    @observable impression: IImpression | null = null;
    @observable impressions: IImpression[] = [];
    @observable impressionRegestry = new Map();
    @observable ITListImpression: IImpressionList[] = [];
    @observable editImpression: boolean = false;
    @observable addMode: boolean = false;
    @observable progress: number = 50;

    @action changeButtonStateEdit = async (newState: boolean) => {
        this.editImpression = newState;
    }

    @action changeButtonStateAdd = async (newState: boolean) => {
        this.addMode = newState;
    }

    @action loadImpressions = async () => {
        this.loadingInitial = true;
        this.ITListImpression = [];
        try {
            const impressions = await agent.impressionAgent.list();
            runInAction(() => {
                this.impressions = impressions;
                let aucunImpression: IImpressionList = {
                    label: "Aucune impression",
                    value: ""
                }
                this.ITListImpression.push(aucunImpression)
                impressions.forEach((impression) => {
                    setImpressionProps(impression);
                    this.impressionRegestry.set(impression.impressionId, impression);
                    let impressionn: IImpressionList = {
                        label: impression.libelle,
                        value: impression.libelle
                    }
                    this.ITListImpression.push(impressionn)
                })
                this.loadingInitial = false;
            })
        } catch (e) {
            toast.error("Problem loading impression!");
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }

    @action loadImpression = async (id: string) => {

        this.loadingImpression = true;
        let impression = this.getImpression(id);
        if (impression) {
            this.impression = impression;
            this.loadingImpression = false;
            return impression;
        }
        else {
            try {
                impression = await agent.impressionAgent.details(id);
                runInAction(() => {
                    this.impressionRegestry.set(impression.impressionId ,impression);
                    this.impression = impression;
                    this.loadingImpression = false;
                })
                return impression;
            } catch (e) {
                runInAction(() => {
                    this.loadingImpression = false;
                })
                console.log(e);
            }
        }
    }

    getImpression = (id: string) => {
        return this.impressionRegestry.get(id);
    }

    @action create = async (values: IImpression) => {
        this.submittingImpression = true;
        try {
            await agent.impressionAgent.create(values);
            runInAction(() => {
                this.impressionRegestry.set(values.impressionId, values)
                this.impression = values;
                const newOption: IImpressionList = {
                    label: values.libelle,
                    value: values.libelle
                }
                this.ITListImpression.push(newOption);
                this.impressions.push(this.impression);
                this.submittingImpression = false;
                toast.success("ajout éffectué avec succès");
            })

        } catch (e) {
            runInAction(() => {
                this.submittingImpression = false;
                console.log(e);
            })
        }
    }

    @action edit = async (values: Partial<IImpression>) => {
        this.submittingImpression = true;
        this.progress = 80;
        try {
            await agent.impressionAgent.update(values);
            let impression = await agent.impressionAgent.details(values.impressionId!);
            runInAction(() => {
                this.impressions = this.impressions.filter(a => a.impressionId != values.impressionId);
                this.impressions.push(impression);
                toast.success("Modification éffectuée avec succès");
                this.submittingImpression = false;
            })

        } catch (e) {
            runInAction(() => {
                this.submittingImpression = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action deleteImpression = async (id: string) => {
        this.loadingImpressionAction = true;
        try {
            await agent.impressionAgent.delete(id);
            runInAction(() => {
                this.loadingImpressionAction = false;
                this.impressions = this.impressions.filter(a => a.impressionId !== id);
                toast.success("suppression éffectuée avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de suppréssion d'impression!");
            runInAction(() => {
                this.loadingImpressionAction = false;
            })
        }

    }
}