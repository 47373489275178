import React, { useContext } from 'react';
import { Form, Button, Grid } from 'semantic-ui-react';
import { Form as FinalForm, Field } from 'react-final-form';
import { combineValidators, composeValidators, isRequired } from 'revalidate';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { v4 as uuid } from 'uuid';
import TextInput from '../../../app/common/form/inputText';
import ErrorMessage from '../../../app/common/form/ErrorMessage';
import { ITListType } from '../../../app/common/options/IAchat';
import InputSelect from '../../../app/common/form/inputSelect';


const validate = combineValidators({
    name: composeValidators(
        isRequired,
    )({
        message: 'Veuillez enter un nom de compte'
    }),
    type: composeValidators(
        isRequired,
    )({
        message: 'Veuillez enter un type'
    }),

});

const AccountForm = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { create } = baseRepo.accountStore;
    const handleFinalFormSubmit = (values: any) => {
        let newAccount = {
            ...values,
            accountId: uuid(),
        };
        create(newAccount);
        baseRepo.accountStore.changeButtonStateAdd(!baseRepo.accountStore.addMode)
    }
    return (

        <FinalForm
            onSubmit={handleFinalFormSubmit}
            validate={validate}
            render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit }) => (

                <Form onSubmit={handleSubmit} error>
                    <Grid columns={3}>
                        <Grid.Row>
                            <Grid.Column>
                                <Field fluid name='name' component={TextInput} placeholder='Nom de compte' />
                            </Grid.Column>
                            {submitError && !dirtySinceLastSubmit && (
                                <ErrorMessage
                                    error={submitError}
                                    text='nom de compte invalide'
                                />
                            )}
                            <Grid.Column>
                                <Field component={InputSelect} options={ITListType} name='type' placeholder="Type" />
                            </Grid.Column>
                            {submitError && !dirtySinceLastSubmit && (
                                <ErrorMessage
                                    error={submitError}
                                    text='type invalide'
                                />
                            )}
                            <Grid.Column>
                                <Button
                                    fluid size='large'
                                    disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                                    loading={submitting}
                                    color='orange'
                                    content='Ajouter'
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                </Form>

            )} />

    );
}
export default AccountForm;