import { useContext, useEffect, } from 'react';
import { Tab, Header, Button, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import NotFound from '../../../app/layout/NoFound';
import ClientEditForm from './ClientEditForm';
import FormGridColumn from '../../../app/common/form/FormGridColumn';
import { MdArticle, MdMonetizationOn, MdOutlineArticle } from 'react-icons/md';

const ClientDescription = () => {

    const baseRepo = useContext(BaseStoreContext);
    const { client, editClient } = baseRepo.clientStore;
    useEffect(() => {
        baseRepo.clientStore.changeButtonState(false);
    }, [])

    if (!client) {
        return <NotFound />
    }
    else {
        return (
            <Tab.Pane>
                <Grid>
                    <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                        <Header floated='left' icon='address card' /> <h4>{client.agencyName}</h4>
                        <Button
                            onClick={() => { baseRepo.clientStore.changeButtonState(!baseRepo.clientStore.editClientMode) }}
                            floated='right'
                            color='orange'
                            size="small"
                            content={baseRepo.clientStore.editClientMode ? 'Annuler' : 'Modifier franchisé'}
                        />
                    </Grid.Column>

                    <Grid.Column width={16}>
                        {baseRepo.clientStore.editClientMode ? (
                            <ClientEditForm editClient={editClient} client={client!} />
                        ) : (

                            <Grid className="ui stackable">
                                <Grid.Row columns={3} className="ui grid stackable" style={{ backgroundColor: "rgba(255,255,255,.7)", borderRadius: 10, paddingBottom: 0 }}>
                                    <Grid.Column style={{ borderRight: '1px solid #eee', margin: 0 }}>
                                        <MdArticle style={{ background: '#e32c26', height: 35, width: 35, padding: 7, marginRight: 10, borderRadius: 50, color: "white", float: "left" }} />
                                        <span style={{ paddingTop: 5, display: 'block', fontSize: 17 }}>Commandes: <br /><b style={{ paddingLeft: 5, float: 'right' }}>{client!.totalCmd} €</b></span>
                                    </Grid.Column>
                                    <Grid.Column style={{ borderRight: '1px solid #eee', margin: 0 }}>
                                        <MdOutlineArticle style={{ background: '#ff9307', height: 35, width: 35, padding: 7, marginRight: 10, borderRadius: 50, color: "white", float: "left" }} />
                                        <span style={{ paddingTop: 5, display: 'block', fontSize: 17 }}>Factures: <br /><b style={{ paddingLeft: 5, float: 'right' }}>{client!.totalInvoices} €</b></span>
                                    </Grid.Column>
                                    <Grid.Column style={{ margin: 0 }}>
                                        <MdMonetizationOn style={{ background: '#1757b8', height: 35, width: 35, padding: 7, marginRight: 10, borderRadius: 50, color: "white", float: "left" }} />
                                        <span style={{ paddingTop: 5, display: 'block', fontSize: 17 }}>En Cours: <br /><b style={{ paddingLeft: 5, float: 'right' }}>{client!.totalInvoiceNotPayed} €</b></span>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{ padding: 0, margin: 0 }} >
                                    <FormGridColumn header="Nom Agence" content={client.companyName} />
                                    <FormGridColumn header="Dénomination sociale" content={client.socialName} />
                                    <FormGridColumn header="Nom de contact" content={client.deliveryLastName} />
                                    <FormGridColumn header="Prénom de contact" content={client.deliveryFirstName} />
                                    <FormGridColumn header="Téléphone (Livraison)" content={client.deliveryPhone} />
                                    <FormGridColumn header="Forme Juridique" content={client!.formeJuridique} />
                                    <FormGridColumn header="Capital" content={client!.capital} />
                                    <FormGridColumn header="RCS" content={client!.rcs} />
                                    <FormGridColumn header="Siret" content={client!.siretNumber} />
                                    <FormGridColumn header="Code APE" content={client!.apeCode} />
                                    <FormGridColumn header="Numéro d'agrément" content={client!.agreementNumber} />
                                    <FormGridColumn header="Tva Intra Communautaire" content={client.europeanTvaNumber} />
                                    <FormGridColumn header="Montant Pack Ouverture" content={client!.openingPackAmount} />
                                    <FormGridColumn header="Site Web" content={client!.website} />
                                    <FormGridColumn header="Société de Facturation" content={client.invoiceCompany} />
                                    <FormGridColumn header="Société de Livraison" content={client!.deliveryCompany} />
                                    <FormGridColumn header="Email de Facturation" content={client.invoiceEmails ? client.invoiceEmails.split(';').map(x => <>{x}<br /></>) : null} />
                                    <FormGridColumn header="Email de Livraison" content={client.deliveryEmails ? client.deliveryEmails.split(';').map(x => <>{x}<br /></>) : null} />
                                    <FormGridColumn header="Mode de paiement" content={client.modePaiement} />
                                </Grid.Row>
                            </Grid>
                        )}
                    </Grid.Column>
                </Grid>
            </Tab.Pane>
        );
    }
}
export default observer(ClientDescription);


