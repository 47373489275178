import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Form, Button, Input, Grid, Checkbox } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import { IProductFournisseur } from '../../../app/models/IArticle';
import { toast } from 'react-toastify';
import { ICommandeFournisseur } from '../../../app/models/ICommandeFournisseur';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { ITarifFournisseur } from '../../../app/models/ITarifFournisseur';
import { combineValidators, composeValidators, createValidator } from 'revalidate';
import { IOptionList } from '../../../app/models/IOption';
import { v4 as uuid } from 'uuid';
import { IItems } from '../../../app/models/IProductAttributes';
import { handleSetAction } from '../../commande/detailCommande/AddCommandeArticle';

interface IProps {
    setMode: Dispatch<SetStateAction<boolean>>;
    order: ICommandeFournisseur;
}

const isFloat = createValidator(
    message => value => {
        if (value && !/^[+-]?\d+(\.\d+)?$/i.test(value)) {
            return message;
        }
    },
    'Invalid number'
);
const validate = combineValidators({
    unitPrice: composeValidators(
        isFloat({
            message: 'invalide valeur'
        }))({
            message: ''
        }),
});

const AddCommandeFournisseurArticle: React.FC<IProps> = ({ setMode, order }) => {

    const baseStore = useContext(BaseStoreContext);
    const { loadArticlesFournisseur, loadingArticle, articlesFournisseur } = baseStore.articleStore;
    const { loadingOption, loadOptions, ITListOption, submittingOption, loadOptionsArticle, loadOptionsProduct, ITListOptionArticle } = baseStore.optionStore;
    const { addArticle, addingArticle } = baseStore.commandeFournisseurStore;
    const { loadingInitial, loadTarifFournisseurList, tarifFournisseurs, loadTarifFournisseurByTypeAndQuantite } = baseStore.tarifFournisseurStore;
    const { loadListProductAttribute, ITListProductAttributes } = baseStore.productAttributesStore;
    const [minQuantite, setMinQuantite] = useState<number | null>();
    const [validArticle, setValidArticle] = useState(true);
    const [article, setArticle] = useState<IProductFournisseur>();
    const [unitPrice, setUnitPrice] = useState<number>();
    const [unitPriceWithTaxes, setUnitPriceWithTaxes] = useState<number>(0);
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [totalPriceWithTaxes, setTotalPriceWithTaxes] = useState<number>(0);
    const [quantity, setQuantity] = useState<number | null>();
    const [disabled, setDisabled] = useState(true);
    const [toBeAdded, setToBeAdded] = useState(false);
    const [remise, setRemise] = useState<number>(0);
    const [comment, setComment] = useState<string>("");
    const [tarif, setTarif] = useState<ITarifFournisseur | null>();
    const [productReference, setProductReference] = useState("");
    const [options, setOptions] = useState<Array<IOptionList>>([]);
    const [attributes, setAttributes] = useState<IItems[]>([]);


    useEffect(() => {
        loadOptions();
        order.codeFranchiseur == 0 ? loadArticlesFournisseur(order.fournisseur, 'Fournisseur') : loadArticlesFournisseur(order.franchiseur, 'Franchiseur');
    }, [])

    const handleChangeOption = (inputValue: string) => {
        setTimeout(() => {
            var id = uuid();
            const newOption: IOptionList = {
                key: id,
                label: inputValue,
                value: inputValue
            }
            setOptions((prev) => [...prev, newOption]);
        }, 1000);
    }
    const handleSetArticle = (e: IProductFournisseur) => {
        setArticle(e);
        loadListProductAttribute(e.productId).then((data: any) => {
            setAttributes(data)
        });
        setValidArticle(true);
        loadOptionsProduct(e.productId.toString()).then(data => {
            var finition: IOptionList[] = [];
            data.forEach((optionArticle) => {
                let optionn: IOptionList = {
                    key: optionArticle.option.optionId,
                    label: optionArticle.option.libelle,
                    value: optionArticle.option.optionId
                }
                finition.push(optionn)
            })
            setOptions(finition);
        });
        loadTarifFournisseurList(e.productId)
            .then((data: ITarifFournisseur[] | undefined) => {
                const tarifFournisseur = data!.filter(x => x.lastPrice == true)[0] ?? data![0];
                if (tarifFournisseur == undefined) {
                    setTarif(null)
                    setMinQuantite(0);
                    setQuantity(0);
                    setUnitPrice(0);
                    setUnitPriceWithTaxes(0);
                    setTotalPrice(0);
                    setTotalPriceWithTaxes(0);
                }
                else {
                    setTarif(tarifFournisseur)
                    setMinQuantite(tarifFournisseur.quantite);
                    setQuantity(tarifFournisseur.quantite);
                    setProductReference(tarifFournisseur.productReference);
                    setUnitPrice(tarifFournisseur.prix);
                    setUnitPriceWithTaxes(tarifFournisseur.prix + (tarifFournisseur.prix * e.tvaRate / 100));
                    setTotalPrice(tarifFournisseur.prix * tarifFournisseur.quantite);
                    setTotalPriceWithTaxes(tarifFournisseur.prix * tarifFournisseur.quantite + (tarifFournisseur.prix * e.tvaRate / 100) * tarifFournisseur.quantite);
                }

                setDisabled(false);
                setRemise(0);

                setValidArticle(false);
            });
    }
    const handleSetInformation = (e: any) => {
        var value = e.target.value == "" ? 0 : parseFloat(e.target.value);
        const tauxTva = article!.tvaRate!;
        const UnitPriceWithTaxes = value + value * tauxTva / 100;
        setUnitPrice(value);
        setUnitPriceWithTaxes(UnitPriceWithTaxes);
        setTotalPrice(value * quantity! - (value * quantity! * remise / 100));
        setTotalPriceWithTaxes(UnitPriceWithTaxes * quantity! - (UnitPriceWithTaxes * quantity! * remise / 100));
    }
    const handleSetPrixByTypeandQuantite = (e: any) => {
        setValidArticle(true);
        const parsedQuantite = parseFloat(e.quantite);
        setTarif(e);
        if (parsedQuantite > 0) {
            setQuantity(parsedQuantite);
            loadTarifFournisseurByTypeAndQuantite(article!.productId, order!.codeFranchiseur == 0 ? 'Fournisseur' : 'Franchiseur', order!.codeFranchiseur == 0 ? parseInt(e.id) :  parsedQuantite)
                .then(data => {
                    const total = parsedQuantite * data!;
                    const tauxTva = article!.tvaRate;
                    const taxePrice = (data! * (100 + tauxTva)) / 100;
                    const totalTaxe = (total * (100 + tauxTva)) / 100;
                    setUnitPrice(data!);
                    setUnitPriceWithTaxes(taxePrice);
                    setTotalPrice(total - total * remise / 100);
                    setTotalPriceWithTaxes(totalTaxe - totalTaxe * remise / 100);
                    setValidArticle(false);
                });
        } else {
            toast.error("Quantité minimal est " + 1);
            setQuantity(minQuantite);
            setValidArticle(false);
        }
    }

    const handleChangeQuantity = (inputValue: string) => {
        setTimeout(() => {
            const newOption: ITarifFournisseur = {
                quantite: parseInt(inputValue),
                fournisseur: order!.fournisseur,
                franchiseur: order!.franchiseur,
                dateCreation: tarif?.dateCreation!,
                prix: tarif?.prix ?? 0,
                article: article!.reference,
                companyName: order!.companyName,
                fabricant: order!.fabricant,
                productReference: article!.reference,
                type: order!.fournisseur ? '' : '',
                id: tarif?.id ?? 0,
                lastPrice: false,
            }
            setTarif(newOption);
            handleSetPrixByTypeandQuantite(newOption);
        }, 1000);

    }

    const handleSetAttribute = (e: any, id: number, key: any) => {
        var list: IItems[] = attributes;
        const attributs = list[list.findIndex(x => x.id == id)].attributs;
        list[list.findIndex(x => x.id == id)].attributs[attributs.findIndex(x => x.key == key)].value = parseInt(e.target.value);
        setAttributes(list);
    }

    const handleSetAttributeSelect = (id: number, key: any) => {
        var list: IItems[] = attributes;
        const attributs = list[list.findIndex(x => x.id == id)].attributs;
        const item = list[list.findIndex(x => x.id == id)].attributs[attributs.findIndex(x => x.value == 1)];
        if (item)
            list[list.findIndex(x => x.id == id)].attributs[attributs.findIndex(x => x.value == 1)].value = 0;
        list[list.findIndex(x => x.id == id)].attributs[attributs.findIndex(x => x.key == key)].value = 1;
        setAttributes(list);
    }


    const handleChangePriceRemise = (e: any) => {
        const Remise = e.target.value;
        setRemise(Remise);
        setTotalPrice(unitPrice! * quantity! - (unitPrice! * quantity! * Remise / 100));
        setTotalPriceWithTaxes(unitPriceWithTaxes * quantity! - (unitPriceWithTaxes * quantity! * Remise / 100));
    }

    const handleFinalFormSubmit = (values: any) => {
        var tab: string[] = [];
        options.map((item: any) => {
            tab.push(item.label)
        })
        var check = true;
        attributes.forEach((item, key) => {
            var som = 0;
            if (key == ITListProductAttributes.findIndex(x => x.displayAllWithDistinctQuantities)) {
                item.attributs.forEach(item => {
                    som += item.value;
                });
                if (som != quantity)
                    check = false;
            }
            else
                if (item.attributs.findIndex(x => x.value == 1) == -1) {
                    check = false;
                }
        });
        if (!check)
            alert("Vous devez atteindre " + quantity + " unités cumulées");
        else {
            let newArticle = {
                ...values,
                commandeFournisseurId: order.id,
                quantity: quantity,
                articleId: article!.productId,
                unitPrice: unitPrice,
                unitPriceWithTaxes: (unitPriceWithTaxes),
                tvaRate: article!.tvaRate,
                codeArticle: article?.reference,
                article: article?.label,
                remise: remise,
                comment: comment,
                toBeAdded: toBeAdded,
                internalRef: productReference,
                optionArticles: tab,
                attributes: attributes,
            };
            addArticle(newArticle);
            setMode(false);
        }
    }

    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            validate={validate}
            render={({ handleSubmit, invalid }) => (
                <Form onSubmit={handleSubmit} error >
                    <Form.Group unstackable widths={2}>
                        <div className="field">
                            <h5>Article</h5>
                            <Select isSearchable name="article" isLoading={loadingArticle} options={articlesFournisseur}
                                getOptionLabel={e => e!.reference + ' - ' + e!.label}
                                getOptionValue={e => e!.productId.toString()}
                                onChange={(e) => handleSetArticle(e!)}
                            />
                        </div>
                        <div className="field">
                            <Checkbox checked={toBeAdded} label="Ajouter ce tarif s'il n'existe pas" style={{ paddingTop: 10 }} onChange={(value) => setToBeAdded(!toBeAdded)} />
                        </div>

                    </Form.Group>
                    {ITListProductAttributes && ITListProductAttributes.map((productAttribute, key) => (
                        productAttribute.displayAllWithDistinctQuantities && key == ITListProductAttributes.findIndex(x => x.displayAllWithDistinctQuantities) ? (
                            <div className="field">
                                <Grid>
                                    {productAttribute.possibleValues.split(';').map((item: string, index) => (
                                        <Grid.Column width={4} style={{ textAlign: "center" }}>
                                            <label style={{ width: '50%', marginTop: 5 }}>{item}</label>
                                            <input style={{ width: '50%', float: "right" }} type="number" min={0} defaultValue={0}
                                                onBlur={e => handleSetAttribute(e, productAttribute.id, item)} />
                                        </Grid.Column >
                                    ))}
                                </Grid>
                            </div>
                        ) : (
                            <Form.Group unstackable widths={2}>
                                <div className="field">
                                    <h5>{productAttribute.name}</h5>
                                    <Select
                                        placeholder="Séléctionner un ... "
                                        options={handleSetAction(productAttribute.possibleValues.split(';'))}
                                        getOptionLabel={e => e!.text}
                                        getOptionValue={e => e!.value}
                                        onChange={e => handleSetAttributeSelect(productAttribute.id, e!.value)}
                                    />
                                </div>
                            </Form.Group>
                        )
                    ))
                    }
                    <Form.Group unstackable widths={3}>
                        {!validArticle && (
                            <>
                                <div className="field">
                                    <label>Quantité *</label>
                                    <CreatableSelect name="quantiy"
                                        isDisabled={disabled}
                                        isLoading={loadingInitial}
                                        options={tarifFournisseurs.filter(x => x.lastPrice == false)}
                                        value={tarif}
                                        getOptionLabel={e => e?.quantite ? e?.quantite.toString() : `Affecter cette quantité`}
                                        getOptionValue={e => e?.id ? e?.id.toString() : ""}
                                        placeholder='Quantité'
                                        onChange={e => handleSetPrixByTypeandQuantite(e)}
                                        onCreateOption={handleChangeQuantity}
                                        formatCreateLabel={() => `Affecter cette quantité`}
                                    />
                                </div>
                                <div className="field">
                                    <label>Réf. Article Fournisseur</label>
                                    <input name='internalRef' type="text" value={productReference} onChange={(e) => setProductReference(e.target.value)} />
                                </div>
                                <div className="field">
                                    <label style={{ marginTop: 32 }} color="grey">TVA : {article?.tvaRate}</label>
                                </div>
                            </>
                        )}
                    </Form.Group>
                    <Form.Group unstackable widths={2}>
                        <div className="field">
                            <label>Prix unitaire</label>
                            <Form.Input name='unitPrice' disabled={validArticle} defaultValue={unitPrice} step={0.0001} type='number' onChange={handleSetInformation} />
                        </div>
                        <div className="field">
                            <label>Prix unitaire avec taxes</label>
                            <input name='unitPriceTaxes' disabled={true} value={unitPriceWithTaxes.toFixed(3)} />
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={2}>
                        <div className="field">
                            <label>Prix Total</label>
                            <input name='totalPrice' disabled={true} placeholder="Prix Total" value={totalPrice.toFixed(2)} />
                        </div>
                        <div className="field">
                            <label>Prix Total avec taxes</label>
                            <input name='totalPriceTaxes' disabled={true} value={totalPriceWithTaxes.toFixed(2)} />
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={3}>
                        <div className="field">
                            <label>Remise</label>
                            <input name='remise' min={0} max={100} defaultValue={0} value={remise} type="number" onChange={(e) => handleChangePriceRemise(e)} />
                        </div>
                        <Form.Field width={16}>
                            <label>Commentaire</label>
                            <input name='comment' type="text" onChange={(e) => setComment(e.target.value)} />
                        </Form.Field >
                    </Form.Group>
                    <Grid unstackable>
                        <Grid.Column width={8}>
                            {article?.paperFormat &&
                                <Grid.Row width={16}>
                                    <div className="field">
                                        <label color="grey">Format: <span style={{ fontSize: 14, color: 'grey' }}>{article?.paperFormat}</span></label>
                                    </div>
                                </Grid.Row>
                            }

                            {article?.printing &&
                                <Grid.Row width={16}>
                                    <div className="field">
                                        <label color="grey">Impression: <span style={{ fontSize: 14, color: 'grey' }}>{article?.printing}</span></label>
                                    </div>
                                </Grid.Row>
                            }
                            {article?.paperWeight &&
                                <Grid.Row width={16}>
                                    <div className="field">
                                        <label color="grey">Support: <span style={{ fontSize: 14, color: 'grey' }}>{article?.paperWeight}</span></label>
                                    </div>
                                </Grid.Row>
                            }
                            {article?.deliveryDelay &&
                                <Grid.Row width={16}>
                                    <div className="field">
                                        <label color="grey">Délais de livraison: <span style={{ fontSize: 14, color: 'grey' }}>{article?.deliveryDelay}</span></label>
                                    </div>
                                </Grid.Row>
                            }
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <div className="field">
                                <label color="grey">Poids unitaire: <span style={{ fontSize: 14, color: 'grey' }}>{article?.weight} Kg</span></label>
                            </div>
                            <div className="field">
                                <label> Finition </label>
                                <CreatableSelect
                                    isMulti
                                    isDisabled={loadingOption || submittingOption}
                                    isLoading={loadingOption || submittingOption}
                                    options={ITListOption}
                                    value={options}
                                    placeholder="Finition / Option"
                                    onChange={(option: any) => {
                                        setOptions(option);
                                    }}
                                    onCreateOption={handleChangeOption}
                                />

                            </div>
                        </Grid.Column>
                    </Grid>
                    <Form.Group unstackable style={{ marginLeft: "70%" }} >
                        <Button.Group floated="right">
                            <Button onClick={() => setMode(false)} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive disabled={addingArticle || invalid || validArticle || unitPrice!.toString() == "NaN"} loading={addingArticle} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </Form.Group>
                </Form>
            )}
        />
    );
}
export default observer(AddCommandeFournisseurArticle);
