import { useContext } from 'react';
import {  Button, Grid, Table } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import NotFound from '../../../app/layout/NoFound';
import AucunProduit from '../../commande/detailCommande/AucunProduit';
import CommandeFournisseurDuplicationModal from './CommandeFournisseurDuplicationModal';
import FormGridColumn from '../../../app/common/form/FormGridColumn';

const CommandeFournisseurDuplicationInformations = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { openModal } = baseRepo.modalStore;
    const { commandeFournisseur, changeDuplicationState, duplicationMode } = baseRepo.commandeFournisseurStore;
    if (!commandeFournisseur) {
        return <NotFound />
    }
    else {
        return (
            <div>
                <Grid className="ui stackable">
                    <h3>Informations du devis:</h3>
                    <Grid.Row style={{ padding: 0, margin: 0 }}>
                        <FormGridColumn header="Client" content={commandeFournisseur!.codeFournisseur ?? commandeFournisseur!.codeFranchiseur} root={commandeFournisseur!.fournisseur == null ? `/detailFranchiseur/${commandeFournisseur.franchiseur}` : `/detailFournisseur/${commandeFournisseur.fournisseur}`} />
                    </Grid.Row>
                    <Grid.Row style={{ padding: 0, margin: 0 }}>
                        <FormGridColumn header="Num Commande" content={commandeFournisseur!.id} />
                        <FormGridColumn header="Date Commande" content={new Date(commandeFournisseur!.orderDate).toLocaleDateString('en-GB')} />
                        <FormGridColumn header="Date exp demandée" content={commandeFournisseur!.validationDate ? new Date(commandeFournisseur!.validationDate).toLocaleDateString('en-GB') : "non introduite"} />
                        <FormGridColumn header="Transport" content={commandeFournisseur!.deliveryCompany} />
                        <FormGridColumn header="Livraison" content={commandeFournisseur!.singleDelivery} />
                        <FormGridColumn header="Montant HT" content={commandeFournisseur!.amountWithoutTaxes} />
                        <FormGridColumn header="Montant TTC" content={commandeFournisseur!.amountWithTaxes} />
                        <FormGridColumn header="Commentaires" content={commandeFournisseur!.comment} />
                        <FormGridColumn header="Transporteur" content={commandeFournisseur!.deliveryService} />
                    </Grid.Row>
                    <h3>Adresses Commande:</h3>
                    <Grid.Row style={{ padding: 0, margin: 0 }}>
                        <FormGridColumn header="Adresse de facturation" content={commandeFournisseur!.invoiceAddress1} />
                        <FormGridColumn header="Complément d’adresse facturation" content={commandeFournisseur!.invoiceAddress2} />
                        <FormGridColumn header="Ville de facturation" content={commandeFournisseur!.invoiceCity} />
                        <FormGridColumn header="Code postal facturation" content={commandeFournisseur!.invoicePostalCode} />
                    </Grid.Row>
                    <h3>Article(s):</h3>
                    {commandeFournisseur?.orderFournisseurLines.length == 0 ? (
                        <AucunProduit />
                    ) : (
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Code Article</Table.HeaderCell>
                                    <Table.HeaderCell>Article</Table.HeaderCell>
                                    <Table.HeaderCell>Quantité</Table.HeaderCell>
                                    <Table.HeaderCell>Prix unitaire</Table.HeaderCell>
                                    <Table.HeaderCell>Total</Table.HeaderCell>
                                    <Table.HeaderCell>TVA</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {commandeFournisseur &&
                                    commandeFournisseur.orderFournisseurLines.map(commandeFournisseur => (
                                        <Table.Row key={commandeFournisseur.articleId}>
                                            <Table.Cell>{commandeFournisseur.codeArticle}</Table.Cell>
                                            <Table.Cell>{commandeFournisseur.article}</Table.Cell>
                                            <Table.Cell>{commandeFournisseur.quantity}</Table.Cell>
                                            <Table.Cell>{commandeFournisseur.unitPrice}</Table.Cell>
                                            <Table.Cell>00,00</Table.Cell>
                                            <Table.Cell>{commandeFournisseur.tvaRate}</Table.Cell>
                                        </Table.Row>
                                    ))}
                            </Table.Body>
                        </Table>
                    )}
                </Grid>
                <div style={{ marginTop: '20px' }}>
                    <Button.Group floated="right">
                        <Button onClick={() => changeDuplicationState(!duplicationMode)} floated='right' type='button'>Annuler</Button>
                        <Button.Or />
                        <Button positive onClick={(e) => {
                            openModal(<CommandeFournisseurDuplicationModal commandeFournisseur={commandeFournisseur} />)
                        }} floated='right' type='submit'>Dupliquer</Button>
                    </Button.Group>
                </div>
            </div>
        );
    }
}
export default observer(CommandeFournisseurDuplicationInformations);