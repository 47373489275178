import { useContext, useEffect } from 'react';
import { Tab, Header, Button, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import NotFound from '../../../app/layout/NoFound';
import FournisseurComplementInfosEditForm from './FournisseurComplementInfosEditForm';
import FormGridColumn from '../../../app/common/form/FormGridColumn';

const FournisseurComplementInfosDescription = () => {

    const baseRepo = useContext(BaseStoreContext);
    const { fournisseur, editFournisseurComplementInfos } = baseRepo.fournisseurStore;
    useEffect(() => {
        baseRepo.fournisseurStore.changeButtonState(false)
    }, [])
    if (!fournisseur) {
        return <NotFound />
    }
    else {
        return (
            <Tab.Pane>
                <Grid>
                    <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                        <Header floated='left' icon='address card' content={`Autre Informations`} />
                        <Button
                            onClick={() => { baseRepo.fournisseurStore.changeButtonState(!baseRepo.fournisseurStore.editFournisseurMode) }}
                            floated='right'
                            basic
                            content={baseRepo.fournisseurStore.editFournisseurMode ? 'Annuler' : 'Modifier Fournisseur'}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        {baseRepo.fournisseurStore.editFournisseurMode ? (
                            <FournisseurComplementInfosEditForm editFournisseur={editFournisseurComplementInfos} fournisseur={fournisseur!} />
                        ) : (
                            <Grid className="ui stackable">
                                    <Grid.Row>
                                        <FormGridColumn icon="user" header="Type Tolerence factures" content={fournisseur!.tolerenceFacture} />
                                        <FormGridColumn icon="user" header="Valeur Tolerence factures" content={fournisseur!.tolerenceFacture == 'EcartSpecifiquePourcentage' ? fournisseur!.valeurTolerenceFacture + " %" : fournisseur!.tolerenceFacture == "EcartSpecifiqueMontant" ? fournisseur!.valeurTolerenceFacture + " €" : "0"}/>
                                  {/*  <FormGridColumn icon="building" header="Tarif de base" content={fournisseur!.tarifBase} />*/}
                                    <FormGridColumn icon="circle" iconColor={fournisseur.documentValorise ? "green" : "red"} header={fournisseur!.documentValorise ? "Document Volorisé" : "Document non Volorisé"} />
                                </Grid.Row>
                            </Grid>
                        )}
                    </Grid.Column>
                </Grid>
            </Tab.Pane>
        );
    }
}
export default observer(FournisseurComplementInfosDescription);