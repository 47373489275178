import React from 'react'
import { Tab } from 'semantic-ui-react';
import ClientDescription from './ClientDescription'
import GestionCommercialDescription from './GestionCommercialDescription';
import ClientPhoto from './ClientPhoto';
import Specification from '../clientDetails/specification'
import ClientFichier from './ClientFichier';
import { IClient } from '../../../app/models/IClient';
import ClientAdresses from './ClientAdresses';
import FranchiseurRemise from '../../franchiseur/DetailFranchiseur/FranchiseurRemise';
import Utilisateur from './Utilisateur';
import FranchiseurReglement from '../../franchiseur/DetailFranchiseur/FranchiseurReglement';
import CommandesClient from './CommandesClient';

const panes = [
    { menuItem: 'A Propos', render: () => <ClientDescription /> },
    { menuItem: 'Utilisateur', render: () => <Utilisateur /> },
    //{ menuItem: 'Logo', render: () => <ClientPhoto /> },
    //{ menuItem: 'Adresse(s) ', render: () => <AdressDescription /> },
    { menuItem: 'Adresse(s) ', render: () => <ClientAdresses /> },
    { menuItem: 'Gestion Commerciale', render: () => <GestionCommercialDescription /> },
    { menuItem: 'Specification', render: () => <Specification /> },
    { menuItem: 'Avoirs', render: () => <FranchiseurRemise /> },
    { menuItem: 'Compte client', render: () => <FranchiseurReglement /> },
    {
        menuItem: 'Commandes', render: () => <CommandesClient /> },
    { menuItem: 'Fichier(s) à télécharger', render: () => <ClientFichier /> },
]

interface IProps {
    setActiveTab: (activeIndex: any) => void;
    client: IClient,
    loading: boolean,
}

const ClientContent: React.FC<IProps> = ({ setActiveTab }) => {

    return (
            <Tab
                menu={{ fluid: true, vertical: true }}
                menuPosition='right'
                panes={panes}
                onTabChange={(e, data) => setActiveTab(data.activeIndex)}
            />
    )
}

export default ClientContent