import { useContext } from 'react';
import { Tab, Header, Button, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import NotFound from '../../../app/layout/NoFound';
import CommandeAdressesEditForm from './CommandeAdressesEditForm';
import FormGridColumn from '../../../app/common/form/FormGridColumn';

const CommandeAdresses = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { commande, editAdressesCommande, submitting } = baseRepo.commandeStore;
    if (!commande) {
        return <NotFound />
    }
    else {
        return (
            <Tab.Pane>
                <Grid>
                    <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                        <Header floated='left' icon='map marker alternate' content='Adresses du Commande' />
                        <Button
                            onClick={() => { baseRepo.commandeStore.changeButtonState(!baseRepo.commandeStore.editCommandeMode) }}
                            floated='right'
                            basic
                            content={baseRepo.commandeStore.editCommandeMode ? 'Annuler' : 'Modifier'}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        {baseRepo.commandeStore.editCommandeMode ? (
                            <CommandeAdressesEditForm editCommande={editAdressesCommande} commande={commande!} submitting={submitting} />
                        ) : (
                            <Grid className="ui stackable">
                                <Grid.Row style={{ padding: 0, margin: 0 }}>
                                    <FormGridColumn header="Raison Sociale de facturation" content={commande!.invoiceCompany} />
                                    <FormGridColumn header="Raison social de livraison" content={commande!.deliveryCompany} />
                                    <FormGridColumn header="Adresse de facturation" content={commande!.invoiceAddress1} />
                                    <FormGridColumn header="Adresse de livraison" content={commande!.deliveryAddress1} />
                                    <FormGridColumn header="Complément d’adresse facturation" content={commande!.invoiceAddress2} />
                                    <FormGridColumn header="Complément d’adresse livraison" content={commande!.deliveryAddress2} />
                                    <FormGridColumn header="Ville de facturation" content={commande!.invoiceCity} />
                                    <FormGridColumn header="Ville de livraison" content={commande!.deliveryCity} />
                                    <FormGridColumn header="Pays de facturation" content={commande!.invoiceCountry} />
                                    <FormGridColumn header="Pays de livraison" content={commande!.deliveryCountry} />
                                    <FormGridColumn header="Code postal facturation" content={commande!.invoicePostalCode} />
                                    <FormGridColumn header="Code postal livraison" content={commande!.deliveryPostalCode} />
                                    <FormGridColumn header="Tel de facturation" content={commande!.invoicePhone} />
                                    <FormGridColumn header="Tel de livraison" content={commande!.deliveryPhone} />
                                    <FormGridColumn header="Email de facturation" content={commande!.invoiceEmails?.split(';').map(x => <>{x}<br /></>)} />
                                    <FormGridColumn header="Email de livraison" content={commande!.deliveryEmail?.split(';').map(x => <>{x}<br /></>)} />
                                    <FormGridColumn />
                                    <FormGridColumn header="Nom de contact de livraison" content={commande!.deliveryFirstName + " " + commande!.deliveryLastName} />

                                </Grid.Row>
                            </Grid>
                        )}
                    </Grid.Column>
                </Grid>
            </Tab.Pane>
        );
    }
}
export default observer(CommandeAdresses);