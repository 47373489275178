import { BaseStore } from "./baseStore";
import { observable, action, runInAction, computed, reaction } from 'mobx';
import agent from '../agent/agent';
import { toast } from "react-toastify";
import { IMouvementStock, IMouvementStockList } from "../models/IMouvementStock";
import { setMouvementStockProps } from "../common/util/util";

const LIMIT = 20;

export default class MouvementStockStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0;
                this.stockRegestry.clear();
                this.loadStocksMouvement();
            }
        )
        reaction(
            () => this.activeTab,
            activeTab => {
                if (activeTab != '')
                    return true;
            }
        )
    }
    @observable loadingAction = false;
    @observable loadingStock = false;
    @observable loadingInitial = false;
    @observable loadingExport = false;
    @observable submitting = false;
    @observable deletingStock = false;
    @observable stock: IMouvementStock | null = null;
    @observable stocks: IMouvementStock[] = [];
    @observable stockRegestry = new Map();
    @observable editStock: boolean = false;
    @observable addStock: boolean = false;
    @observable ITListStock: IMouvementStockList[] = [];
    @observable progress: number = 50;
    @observable predicate = new Map();
    @observable page = 0
    @observable stocksCount: number = 0;
    @observable activeTab: string = "";

    @computed get axiosParams() {
        const params = new URLSearchParams();
        params.append('limit', String(LIMIT));
        params.append('offset', `${this.page ? this.page * LIMIT : 0}`);
        this.predicate.forEach((value, key) => {
            if (key === 'startDate') {
                params.append(key, value.toISOString())
            } if (key === 'searchMultiple') {
                var entrepots = [...value][0];
                var article = [...value][1];
                var dateCreation = [...value][2];
                var dateCreationTo = [...value][3]
                var tri = [...value][4]
                params.append(entrepots[0], entrepots[1])
                params.append(article[0], article[1])
                params.append(dateCreation[0], dateCreation[1])
                params.append(dateCreationTo[0], dateCreationTo[1])
                params.append(tri[0], tri[1])
            } else {
                var article = [...value][1];
                params.append(article[0], article[1])
                params.append(key, value)
            }
        })
        return params;
    }

    @computed get totalPages() {
        return Math.ceil(this.stocksCount / LIMIT);
    }

    @action setPage = (page: number) => {
        this.page = page;
    }

    @action setPredicate = (predicate: string, value: any) => {
        if (predicate != 'all')
            this.stocks = [];
        this.predicate.clear();
        if (predicate !== 'searchMultiple') {
            this.predicate.clear();
        }
        if (predicate !== 'all') {
            this.predicate.set(predicate, value);
        }
    }

    @action changeButtonStateEdit = async (newState: boolean) => {
        this.editStock = newState;
    }

    @action changeButtonStateAdd = async (newState: boolean) => {
        this.addStock = newState;
    }

    @action loadStocksMouvement = async () => {
        this.loadingInitial = true;
        try {
            const stockEnveloppe = await agent.mouvementStockAgent.list(this.axiosParams);
            const { stock, stockCount } = stockEnveloppe;
            runInAction(() => {
                stock.forEach((commande) => {
                    setMouvementStockProps(commande);
                    this.stockRegestry.set(commande.mouvementsStockId, commande);
                    if (this.page == 0) {
                        this.stocks = stock;
                    } else {
                        this.stocks.push(commande);
                    }
                })
                this.stocksCount = stockCount;
                this.loadingInitial = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingInitial = false;
            })
            console.log(e);
        }
    }

    getStock = (id: number) => {
        return this.stockRegestry.get(id);
    }

    @action create = async (values: IMouvementStock) => {
        this.progress = 80;
        this.submitting = true;
        try {
            await agent.mouvementStockAgent.create(values);
            runInAction(() => {
                this.stockRegestry.set(values.mouvementsStockId, values)
                this.stock = values;
                this.stocks.push(this.stock);
                this.submitting = false;
                toast.success("ajout éffectué avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    @action edit = async (values: Partial<IMouvementStock>) => {
        this.submitting = true;
        try {
            await agent.mouvementStockAgent.update(values);
            let stock = await agent.mouvementStockAgent.details(values.mouvementsStockId!);
            runInAction(() => {
                this.stocks = this.stocks.filter(a => a.mouvementsStockId != values.mouvementsStockId);
                this.stocks.push(stock);
                toast.success("Modification éffectuée avec succès");
                this.submitting = false;
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action loadStockById = async (id: number) => {

        this.loadingStock = true;
        let stocks = this.getStock(id);
        if (stocks) {
            this.stock = stocks;
            this.loadingStock = false;
            return stocks;
        }
        else {
            try {
                stocks = await agent.mouvementStockAgent.details(id);
                runInAction(() => {
                    this.stockRegestry.set(stocks.mouvementsStockId, stocks);
                    this.stock = stocks;
                    this.loadingStock = false;
                })
                return stocks;
            } catch (e) {
                runInAction(() => {
                    this.loadingStock = false;
                })
                console.log(e);
            }
        }
    }

    @action Export = async (obj:any) => {
        this.loadingExport = true;
        try {
            await agent.mouvementStockAgent.exportExcel(obj);
            runInAction(() => {
                this.loadingExport = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingExport = false;
            })
            console.log(e);
        }
    }
}