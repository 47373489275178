import React, { Fragment, useContext, useState } from 'react';
import { Menu, Header, FormInput, Form, Input, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { Form as FinalForm } from 'react-final-form';
import { BsSearch } from 'react-icons/bs';

const FranchiseurFilter = () => {
    const baseStore = useContext(BaseStoreContext);
    const { predicate, setPredicate } = baseStore.franchiseurStore;
    const [searchResults, setSearchResults] = useState([]);

    const handleChange = (event: any) => {
        setSearchResults(event.target.value)       
    }
    const handleChangeSearch = (event: any) => {
        var predicate = new Map()
        predicate.set('search', searchResults)
        if (searchResults!.length > 2) {
            setPredicate('searchFranch', predicate)
        }
        else if (searchResults!.length == 0)
            setPredicate('all', 'true')
    }

    const handleFilter = (key: string, value: string) => {
        setPredicate(key, value);
        setSearchResults([]);
    }

    return (
        <FinalForm
            onSubmit={handleChangeSearch}
            render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit} error style={{ width: "100%", fontSize: 14 }}>
                    <Fragment>
                        <Menu vertical size={'large'} style={{ width: '100%', marginTop: 30 }}>
                            <Header icon={'filter'} attached color='teal' content={'Filters'} />
                            <Input label={<Button icon={<BsSearch />} type='submit' onClick={handleChangeSearch} basic />}
                                type="text"
                                placeholder="Rechercher Franchiseur"
                                value={searchResults}
                                onChange={handleChange}
                                style={{ width: '345px' }}
                                
                            />
                            <Menu.Item
                                active={predicate.size === 0}
                                onClick={() => handleFilter('all', 'true')}
                                color='teal'
                                name={'all'}
                                content={'Tout les franchiseurs'}
                            />
                            <Menu.Item
                                active={predicate.has('Enable')}
                                onClick={() => handleFilter('isEnabled', 'true')}
                                color={'blue'}
                                name={'isEnabled'}
                                content={"Actif"}
                            />
                            <Menu.Item
                                active={predicate.has('Disabled')}
                                onClick={() => handleFilter('isDisabled', 'true')}
                                color={'blue'}
                                name={'isDisabled'}
                                content={"Inactif"}
                            />
                        </Menu>
                    </Fragment>
                </Form >
            )}
        />
    );
};

export default observer(FranchiseurFilter);
