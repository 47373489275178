import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useContext, useState } from "react";
import { Button, Form, Grid, Header, Icon, Label, Message, Tab, Table } from "semantic-ui-react";
import { BaseStore, BaseStoreContext } from "../../../app/store/baseStore";
import { format } from 'date-fns'
import Loading from "../../../app/layout/Loading";
import { Link } from "react-router-dom";
import { Form as FinalForm, Field } from 'react-final-form';
import InputSelect from "../../../app/common/form/inputSelect";
import { Modal } from 'semantic-ui-react'
import AchatGroupeeModal from "./AchatGroupeeModal";
import { toast } from "react-toastify";
import { combineValidators, composeValidators, isRequired } from "revalidate";
import InfiniteScroll from "react-infinite-scroller";
import OfferDetailModal from "./OfferDetailModal";
import { ISpecialOffer } from "../../../app/models/ISpecialOffer";
import DeleteOfferModal from "./DeleteOfferModal";
import TextInput from "../../../app/common/form/inputText";
import ErrorMessage from "../../../app/common/form/ErrorMessage";


const validate = combineValidators({
    startDate: composeValidators(
        isRequired,
    )({
        message: 'Veuillez enter une date de début'
    }),
    endDate: composeValidators(
        isRequired,
    )({
        message: 'Veuillez enter une date de fin'
    }),
});

const validatePalier = combineValidators({
    minimumQuantity: composeValidators(
        isRequired,
    )({
        message: 'Veuillez enter la quantité'
    }),
    unitPrice: composeValidators(
        isRequired,
    )({
        message: 'Veuillez enter le prix'
    }),

});


const AchatGroupee = () => {
    const baseStore = useContext(BaseStoreContext)
    const [addAchatMode, setAddAchatMode] = useState(false);
    const { franchiseur } = baseStore.franchiseurStore;
    const { getListSpecialOfferByCompany, updateDateFinSpecialOffre, addSpecialPrice, setSpecialOffer, specialOffer, listSpecialOffers, listPrices, clearListPrice, codePromo, createSpecialOffer, page, setPage, totalPages, clearListOffer } = baseStore.specialOfferStore;
    const { loadArticleFranchiseurs, ITListArticleByFranchiseur, loadingInitial } = baseStore.articleStore;
    const { openModal } = baseStore.modalStore;
    

    const [modalOpen, setModalOpen] = useState(false);
    const [modalButton, setModalButton] = useState(false);

    // Details Modal
    const [modalDetailsOpen, setModalDetailsOpen] = useState(false);
    const [offer, setOffer] = useState<ISpecialOffer>();

    
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [productId, setProductId] = useState('');

    const [modalDateOpen, setModalDateOpen] = useState(false);
    const [idOffre, setIdOffre] = useState();
    const [chosenDate, setChosenDate] = useState('');
    const [modalPalierOpen, setModalPalierOpen] = useState(false);

    //scroll
    const [loadingNext, setLoadingNext] = useState(false);
    const handleGetNext = () => {
        setLoadingNext(true);
        setPage(page + 1);
        getListSpecialOfferByCompany(franchiseur!.id);
    }

    useEffect(() => {
        loadArticleFranchiseurs(franchiseur!.id, "Franchiseur");
        getListSpecialOfferByCompany(franchiseur!.id);
        return () => {
            clearListOffer();
        };
    }, []);

    const handleModalButtonClick = () => {
        setModalButton(true);
    }

    const handleChangeDateFin = (e: any) => {
        let day1 = e.target.value
        setEndDate(day1);

        let day2 = startDate;
        console.log(day2)
        
        if (day2! >= day1) {
            alert("date fin doit etre superieur a date debut");
            let newdate = new Date(new Date().setDate(new Date(day2!).getDate() + 7));
            let x = formattedDate(newdate);
            console.log(x);
            setEndDate(x);
        } 
    }
    const handleUpdateDateFin = (e: any) => {
        let selecteDate = e.target.value;
        let today = new Date();
        let stringtoday = formattedDate(today);
        setChosenDate(selecteDate);

        if (selecteDate <= stringtoday) {
            alert("date fin doit etre superieur ou égal a la date d'aujourd'hui ");
            let newdate = new Date(new Date().setDate(new Date(stringtoday).getDate() + 3));
            let x = formattedDate(newdate);
            setChosenDate(x);
        }

    }

    const formattedDate = (date: Date) => {
        var year = date.toLocaleString("default", { year: "numeric" });
        var month = date.toLocaleString("default", { month: "2-digit" });
        var day = date.toLocaleString("default", { day: "2-digit" });

        // Generate yyyy-mm-dd date string
        var formattedDate = year + "-" + month + "-" + day;
        return formattedDate;
    }

    const handleFinalFormSubmit = (values: any) => {
        if (startDate == '' || endDate == '' || productId == '') {
            toast.warning("vous devez remplire tous les champs");
        } else {
        let newAchatGroupee = {
            ...values,
            startDate: startDate,
            endDate: endDate,
            productId: productId,
            companyId: franchiseur!.id,
            promotionCode: codePromo,
            prices: listPrices
        }
            console.log(newAchatGroupee);
            createSpecialOffer(newAchatGroupee).then(() => {
                setAddAchatMode(false);
                clearListPrice();
                setStartDate('');
                setEndDate('');
            });
            
        }
    }

    const handleSetArticle = (e: any, result: any) => {
        setProductId(result.value);
    }

    const handleUpdateDateSubmit = () => {
        console.log("chosen date in component : " + chosenDate);
        updateDateFinSpecialOffre(idOffre!, chosenDate).then(() => {
            setModalDateOpen(false);
            clearListOffer().then(() => getListSpecialOfferByCompany(franchiseur!.id));
        });
    }

    const checkQuantite = (value: any) => {

        let maxValue = Math.max.apply(null, specialOffer!.specialPrices.map(function (o) { return o.minimumQuantity }));
        if (value <= maxValue) {
            return `la quantitée doit étre superieur à ${maxValue}`;
        } else
            return undefined;
    }

    const checkPrix = (value: any) => {

        let minValue = Math.min.apply(null, specialOffer!.specialPrices.map(function (o) { return o.unitPrice }));
        if (value >= minValue) {
            return `le prix doit étre inférieur à ${minValue}`;
        } else
            return undefined;
    }

    const handleFinalFormPalier = (values: any) => {
        let newPrice = {
            ...values,
            specialOfferId: specialOffer!.id
        };
        console.log(newPrice);
        addSpecialPrice(newPrice).then(() => setModalPalierOpen(false));
    }

    return (
        <Tab.Pane>
            <Grid>
                <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                    <Header floated='left' icon='object group outline' content='Achats Groupées' />
                    <Button
                        onClick={() => setAddAchatMode(!addAchatMode)}
                        floated='right'
                        basic
                        content={addAchatMode ? 'Annuler' : 'Ajouter Achat'}
                    />
                </Grid.Column>
                <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                    {addAchatMode ? (
                        <>
                            <FinalForm
                                onSubmit={handleFinalFormSubmit}
                                
                                render={({ handleSubmit }) => (
                                    <Form onSubmit={handleSubmit} error >

                                        <Grid columns={2} divided>

                                            <Grid.Column>
                                                <div className="field">
                                                    <h5> Code Promo:  {codePromo} </h5>
                                                </div>
                                            </Grid.Column>
                                     
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <h5>Date début</h5>
                                                    <input  value={startDate} type="date" placeholder='Date début' onChange={(date: any) => setStartDate(date.target.value)} />
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <h5>Date fin</h5>
                                                    <input  value={endDate} placeholder='Date fin' type="date" onChange={(e) => handleChangeDateFin(e)} />
                                                </Grid.Column>
                                            </Grid.Row>
                                        
                                            <Grid.Column>
                                                <div className="field">
                                                    <h5>Article</h5>
                                                    <Form.Select search name="productId" loading={loadingInitial} component={InputSelect} options={ITListArticleByFranchiseur} onChange={(e, data) => handleSetArticle(e, data)} />
                                                </div>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <h5> Les prix </h5>
                                                <Button onClick={() => setModalOpen(true)} floated='left' type='button'>Ajouter prix par quantités</Button>
                                               
                                            </Grid.Column>

                                        </Grid>
                                        {listPrices.length > 0 ? (
                                            <Form.Group unstackable style={{ marginLeft: "70%" }} >
                                                <Button.Group floated="right">
                                                    <Button onClick={() => setAddAchatMode(false)} floated='right' type='button'>Annuler</Button>
                                                    <Button.Or />
                                                    <Button
                                                        positive
                                                        floated='right'
                                                        type='submit'
                                                    >
                                                        Confirmer
                                                    </Button>
                                                </Button.Group>
                                            </Form.Group>
                                        ) : (
                                            <></>
                                        )}
                                        

                                    </Form>
                                    )}
                            />
                            
                        </>
                    ): (
                            <>
                                {listSpecialOffers.length == 0 ? (
                                    <div style={{ marginTop: '50px' }}>
                                        <Message
                                            error
                                            list={[
                                                "Aucunne offre pour le moment",
                                                "Veuillez ajouter des offres pour accèder à la liste",
                                            ]}
                                        />
                                    </div>
                                ):
                                    (
                                   
                                        <InfiniteScroll
                                            pageStart={0}
                                            loadMore={handleGetNext}
                                            hasMore={!loadingNext && page + 1 < totalPages}
                                            initialLoad={false}
                                        >
                                            <Table loading={false} celled>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell>Promotion Code</Table.HeaderCell>
                                                        <Table.HeaderCell>Produit</Table.HeaderCell>
                                                        <Table.HeaderCell>Date debut</Table.HeaderCell>
                                                        <Table.HeaderCell>Date fin</Table.HeaderCell>
                                                        <Table.HeaderCell>Quantite</Table.HeaderCell>
                                                        <Table.HeaderCell>Details</Table.HeaderCell>
                                                        {listSpecialOffers.find(o => o.isClosed == false) &&
                                                            <Table.HeaderCell textAlign='center'>X</Table.HeaderCell>
                                                        }
                                                    </Table.Row>
                                                </Table.Header>                                         
                                                <Table.Body >
                                                    {listSpecialOffers.length > 0 && listSpecialOffers.map((offer: any) => (
                                                        <Table.Row key={offer.id}>
                                                            <Table.Cell>
                                                                <Header size='huge'>
                                                                    <Label style={{ marginLeft: '2em' }} className={offer.isClosed ? "disabled" : ""} as={Link} to={`#`}>
                                                                        {offer.promotionCode}
                                                                    </Label>

                                                                </Header>
                                                            </Table.Cell>
                                                            <Table.Cell className={offer.isClosed ? "disabled" : ""}>{offer.productLabel}</Table.Cell>
                                                            <Table.Cell className={offer.isClosed ? "disabled" : ""}>{new Date(offer.startDate).toLocaleDateString('en-GB')}</Table.Cell>
                                                            <Table.Cell className={offer.isClosed ? "disabled" : ""}>
                                                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                    {new Date(offer.endDate).toLocaleDateString('en-GB')}
                                                                    &nbsp;
                                                                    {!offer.isClosed &&
                                                                        <Button
                                                                            onClick={() => {
                                                                                setModalDateOpen(true);
                                                                                setEndDate(offer.endDate);
                                                                                setIdOffre(offer.id);
                                                                                setChosenDate(offer.endDate.toString());
                                                                            }}
                                                                            basic
                                                                            color="green"
                                                                            icon='edit outline'
                                                                            style={{ borderRadius: '100%', height: 30, width: 30, fontSize: 10 }}
                                                                        />
                                                                    }
                                                                </span>
                                                            </Table.Cell>
                                                            <Table.Cell className={offer.isClosed ? "disabled" : ""}>{offer.currentOrderedQuantity}</Table.Cell>
                                                            <Table.Cell textAlign='center'>
                                                                <Button
                                                                    onClick={(e) => {
                                                                        setModalDetailsOpen(true);
                                                                        setSpecialOffer(offer)
                                                                    }}
                                                                    basic
                                                                    color="blue"
                                                                    icon='info'
                                                                    style={{ borderRadius: '100%', height:30, width:30, fontSize:10}}
                                                                />
                                                               
                                                                
                                                            </Table.Cell>
                                                            {!offer.isClosed &&
                                                                <Table.Cell textAlign='center'>
                                                                <Button
                                                                    onClick={(e) => {
                                                                        openModal(<DeleteOfferModal OfferId={offer.id} />);
                                                                    }}
                                                                    basic
                                                                    style={{ display: !offer.isClosed ? 'block' : 'none', fontSize: "10px", borderRadius: '100%' }}
                                                                    color='red'
                                                                    icon='trash'
                                                                />
                                                                </Table.Cell>
                                                            }
                                                        </Table.Row>
                                                    ))}
                                                    </Table.Body>                                               
                                            </Table>
                                            </InfiniteScroll>                                           
                                    )}
                            </>
                        )}
                </Grid.Column>
            </Grid>
            <Modal
                open={modalOpen}
                size='large'
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >
                <Modal.Header icon='add' style={{ marginLeft: 20 }} >
                    Liste des prix et quantitées 
                </Modal.Header>

                <Modal.Content scrolling>
                    <AchatGroupeeModal modalButton={modalButton} setModalOpen={setModalOpen} setModalButton={setModalButton} />
                </Modal.Content>

                <Modal.Actions>
                        <div style={{ paddingBottom: 20, marginTop: 10, marginRight:10, display:'inline-block'}}>
                        <Button.Group floated="right">
                            <Button
                                type='button'
                                floated='right'
                                onClick={() => {
                                    setModalOpen(false)
                                    clearListPrice()
                                }}
                            >
                                <Icon name='remove' /> Annuler
                            </Button>
                            <Button.Or text='Ou' />
                            <Button
                                positive
                                type='submit'
                                floated='right'
                                onClick={(e) => {
                                    if (listPrices.length > 0) {
                                        handleModalButtonClick()
                                        setModalOpen(false)
                                    } else {
                                        toast.error("vous devez ajouter des prix");
                                    }
                                }}
                            >
                                <Icon name='checkmark' /> Confirmer
                            </Button>
                            </Button.Group>
                            </div>
                </Modal.Actions>

            </Modal>

            <Modal
                open={modalDetailsOpen}
                size='small'
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >
                <Modal.Header icon='add' style={{ marginLeft: 20 }} >
                    Details de l'offre {specialOffer?.promotionCode}
                </Modal.Header>

                <Modal.Content scrolling>
                    <OfferDetailModal offer={specialOffer!} />
                </Modal.Content>

                <Modal.Actions>
                    <div style={{ margin: 15, display: 'inline-block' }}>
                        <Button.Group floated="right">
                            <Button onClick={() => setModalDetailsOpen(false)} floated='right' type='button'>Retour</Button>
                            <Button.Or />
                            <Button
                                onClick={() => setModalPalierOpen(true)}
                                positive
                                floated='right'
                                type='submit'
                            >
                                Ajouter Palier
                            </Button>
                        </Button.Group>
                    </div>
                </Modal.Actions>
            </Modal>

            <Modal
                open={modalDateOpen}
                size='mini'
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >
                <Modal.Header icon='add' style={{ marginLeft: 20 }} >
                    Modifier la date {new Date(endDate).toLocaleDateString('en-GB')}
                </Modal.Header>

                <Modal.Content>
                    <Grid>
                        <h5>Date fin </h5>
                        <input value={chosenDate} placeholder=' Date fin' type="date" onChange={(e) => handleUpdateDateFin(e)} />
                    </Grid>
                </Modal.Content>

                <Modal.Actions>
                    <div style={{ margin: 15, display: 'inline-block' }}>

                        <Button.Group floated="right">
                            <Button onClick={() => setModalDateOpen(false)} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button
                                onClick={() => { handleUpdateDateSubmit(); }}
                                positive
                                floated='right'
                                type='submit'
                            >
                                Confirmer
                            </Button>
                        </Button.Group>

                    </div>
                </Modal.Actions>
            </Modal>

            <Modal
                open={modalPalierOpen}
                size='mini'
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >
                <Modal.Header icon='add' style={{ marginLeft: 20 }} >
                    Ajouter un nouveau Palier pour l'offre {specialOffer?.promotionCode}
                </Modal.Header>

                <Modal.Content>
                    <FinalForm
                        onSubmit={handleFinalFormPalier}
                        validate={validatePalier}
                        render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit }) => (
                            <Form onSubmit={handleSubmit}>
                                <Grid columns={2}>
                                    <Grid.Row style={{ padding: 0 }}>
                                        <Grid.Column style={{ display: "flex", margin: 0, width: '100%' }}>
                                            <h5 style={{ width: '50%' }}>Quantité </h5>
                                            {/* <Input style={{ width: '50%' }} value={first} type='number' placeholder='quantité'  onChange={(e) => checkQuantite(e)} />*/}
                                            <Field fluid name='minimumQuantity' component={TextInput} type='number' placeholder='Quantité' validate={checkQuantite} />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row style={{ padding: 0 }}>
                                        <Grid.Column style={{ display: "flex", margin: 0, width: '100%' }}>
                                            <h5 style={{ width: '50%' }}>Prix </h5>
                                            {/*<Input style={{ width: '50%' }} value={last} type='number' placeholder='prix' onChange={(e) => handleChangeDateFin(e)} />*/}
                                            <Field fluid name='unitPrice' component={TextInput} type='number' placeholder='prix' validate={checkPrix} />
                                        </Grid.Column>
                                    </Grid.Row>
                                    {submitError && !dirtySinceLastSubmit && (
                                        <ErrorMessage
                                            error={submitError}
                                            text=' invalide'
                                        />
                                    )}
                                </Grid>
                                <div style={{ margin: 15, display: 'inline-block' }}>

                                    <Button.Group floated="right">
                                        <Button onClick={() => setModalPalierOpen(false)} floated='right' type='button'>Annuler</Button>
                                        <Button.Or />
                                        <Button
                                            disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                                            loading={submitting}
                                            positive
                                            floated='right'
                                            type='submit'
                                        >
                                            Confirmer
                                        </Button>
                                    </Button.Group>

                                </div>
                            </Form>
                        )} />
                </Modal.Content>
            </Modal>

        </Tab.Pane>
        );
}

export default observer(AchatGroupee);