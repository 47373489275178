import { debug } from "console";
import { action, computed, observable, runInAction, reaction } from "mobx";
import { toast } from "react-toastify";
import agent from "../agent/agent";
import { ISpecialOffer, ISpecialPrices } from "../models/ISpecialOffer";
import { BaseStore } from "./baseStore";


const LIMIT = 10;

export default class SpecialOfferStore {
    _baseStore: BaseStore;

    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0;
                this.Limit = 10;
                this.loadSpecialOffers();
            }
        )
        reaction(
            () => this.activeTab,
            activeTab => {
                if (activeTab != '')
                    return true;
            }
        )
    }


    @observable page = 0;
    @observable specialOffer: ISpecialOffer | null = null;
    @observable listSpecialOffers: ISpecialOffer[] = [];
    @observable listPrices: ISpecialPrices[] = [];
    @observable codePromo = '';
    @observable submitting = false;
    @observable offerCount: number = 0;
    @observable loadingInitial = false;
    @observable Limit = 10;
    @observable activeTab: string = "";
    @observable predicate = new Map();

    @computed get axiosParams() {
        const params = new URLSearchParams();
        params.append('limit', String(this.Limit));
        params.append('offset', `${this.page ? this.page * this.Limit : 0}`);
        this.predicate.forEach((value, key) => {
            params.append(key, value);
        });

        return params;
    }

    @action setPredicate = (predicate: string, value: any) => {
        if (predicate != 'all')
            this.listSpecialOffers = [];
        this.predicate.clear();
        if (predicate !== 'searchMultiple') {
            this.predicate.clear();
        }
        if (predicate !== 'all') {
            this.predicate.set(predicate, value);
        }
    }

    @action setLimit = (Limit: number) => {
        this.Limit = Limit;
    }

    

    @computed get totalPages() {
        return Math.ceil(this.offerCount / this.Limit);
    }


    @action setPage = (page: number) => {
        this.page = page;
    }

    @action setPriceToList = async (newPrice: ISpecialPrices) => {
        runInAction(() => {
            this.listPrices.push(newPrice);
        })
    }

    @action deletePriceFromList = async (quantite: number, price: number) => {
        runInAction(() => {
            this.listPrices = this.listPrices.filter(x => x.minimumQuantity != quantite && x.unitPrice != price);
        })
    }

    @action clearListPrice = async () => {
        runInAction(() => {
            this.listPrices = [];
        })
    }

    @action setNewPricesList = async (priceList: ISpecialPrices[]) => {
        runInAction(() => {
            this.specialOffer!.specialPrices = priceList;
        })
    }

    @action setSpecialOffer = async (offer: ISpecialOffer) => {
        runInAction(() => {
            this.specialOffer = offer;
        })
    }

    @action clearListOffer = async () => {
        runInAction(() => {
            this.listSpecialOffers = [];
            this.page = 0;
        })
    }

    parseCodePromo = (value: string) => {
        var tonumber = parseInt(value) + 1;
        var result = tonumber.toString();
        while (result.length < 6)
        result = "0" + result;
        return result
    }

    @action getListSpecialOfferByCompany = async (company: number) => {
        try {
            var result = await agent.specialOfferAgent.listSpecialOfferByCompany(this.axiosParams, company);
            runInAction(() => {
                result.listeSpecialOffer.forEach((item) => {
                    this.listSpecialOffers.push(item);
                    });

                this.codePromo = this.parseCodePromo(result.promotionCode);
                this.offerCount = result.offerCount;
            });
            return result;
        } catch (e) {
            console.log(e);
        }
    }
    @action createSpecialOffer = async (offer: ISpecialOffer) => {
        this.submitting = true;
        try {
            var result = await agent.specialOfferAgent.create(offer);
            console.log(result);
            runInAction(() => {
                this.submitting = false;
                this.listSpecialOffers.unshift(result);
                toast.success("ajout effectué avec succès");
            })
        } catch (e) {
            this.submitting = false;
            console.log(e);
        }
    }

    @action deleteSpecialOffer = async (offerId: number) => {

        try {
            var result = await agent.specialOfferAgent.delete(offerId);
            runInAction(() => {
                this.listSpecialOffers = this.listSpecialOffers.filter(x => x.id != offerId);
                toast.success("offre suprimé");
            })
        } catch (e) {
            toast.error("probléme lors de la suppression");
        }
    }

    @action getListCardOffer = async () => {
        try {
            var result = await agent.specialOfferAgent.listcard();
            runInAction(() => {
                this.listSpecialOffers = result.data;
            })
        } catch (e) {
            toast.error("probléme lors de la récuperation des données");
        }
    }

    @action loadSpecialOffers = async () => {
        
        this.loadingInitial = true;
        try {
            var result = await agent.specialOfferAgent.list(this.axiosParams);
            runInAction(() => {
                result.listeSpecialOffer.forEach((item) => {
                    if (this.page == 0) {
                        this.listSpecialOffers = result.listeSpecialOffer;
                    } else {
                        this.listSpecialOffers.push(item);
                    }
                });

                this.offerCount = result.offerCount;
                this.codePromo = this.parseCodePromo(result.promotionCode);
                this.loadingInitial = false;
                localStorage.setItem("codePromo",this.parseCodePromo(result.promotionCode));
            })
        } catch (e) {
            runInAction(() => {
                this.loadingInitial = false;
            })
            console.log(e);
        }
    }

    @action updateDateFinSpecialOffre = async (idOffre: number, dateFin: string) => {
        try {
            console.log("chosen date in Store : " + dateFin);
            var result = await agent.specialOfferAgent.updateDateFin(idOffre, dateFin);
        } catch (e) {
            console.log(e);
        }
    }

    @action addSpecialPrice = async (newPrice: ISpecialPrices) => {
        this.submitting = true;
        try {
            var result = await agent.specialOfferAgent.addSpecialPrice(newPrice);
            runInAction(() => {
                this.specialOffer!.specialPrices.push(result);
                toast.success("ajout effectué avec succès");
                this.submitting = false;

            })
        } catch (e) {
            this.submitting = false;
            console.log(e);
        }
    }

}