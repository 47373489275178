import React, { SetStateAction, useContext, useState } from 'react';
import { Button, Modal, Input, Grid, Loader, Dimmer } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../app/store/baseStore';

import { toast } from 'react-toastify';
import Select from 'react-select'
import FormGridColumn from '../../app/common/form/FormGridColumn';
import { Dispatch } from 'react';
import { ICommandeFournisseurForAlert } from '../../app/models/ICommandeFournisseur';
interface IProps {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>;

}

const AlertStockModal: React.FC<IProps> = ({ open, setOpen }) => {
    const baseStore = useContext(BaseStoreContext);
    const { createOrderForAlert, submitting } = baseStore.commandeFournisseurStore;
    const { loadTarifFournisseurList, tarifFournisseurs, loadingInitial, loadTarifFournisseurByTypeAndQuantite } = baseStore.tarifFournisseurStore;
    const { article, loadingArticle } = baseStore.articleStore;
    const [minQuantite, setMinQuantite] = useState<number | null>();
    const [unitPrice, setUnitPrice] = useState<number>(0);
    const [unitPriceWithTaxes, setUnitPriceWithTaxes] = useState<number>(0);
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [totalPriceWithTaxes, setTotalPriceWithTaxes] = useState<number>(0);
    const [quantity, setQuantity] = useState<number | null>();
    const [fournisseur, setFournisseur] = useState(0);
    const [franchiseur, setFranchiseur] = useState(0);
    const [disabled, setDisabled] = useState(true);

    const handleSetArticle = (e: any, type: string) => {
        loadTarifFournisseurList(article!.productId)
            .then((data: any) => {
                const tarifFournisseur = type == 'Fournisseur' ?
                    data!.filter((x: any) => x.fournisseur == e)[0] :
                    data!.filter((x: any) => x.franchiseur == e)[0];
                setMinQuantite(tarifFournisseur.quantite);
                setQuantity(tarifFournisseur.quantite);
                setDisabled(false);
                setUnitPrice(tarifFournisseur.prix);
                setUnitPriceWithTaxes(tarifFournisseur.prix + (tarifFournisseur.prix * article!.tvaRate / 100));
                setTotalPrice(tarifFournisseur.prix * tarifFournisseur.quantite);
                setTotalPriceWithTaxes(tarifFournisseur.prix * tarifFournisseur.quantite + (tarifFournisseur.prix * article!.tvaRate / 100) * tarifFournisseur.quantite);

            });
    }


    const handleSetPrixByTypeandQuantite = (e: any) => {
        const parsedQuantite = article?.incrementation ? parseFloat(e.target.value) : parseFloat(e.quantite);
        if (parsedQuantite >= minQuantite!) {
            setQuantity(parsedQuantite);
            loadTarifFournisseurByTypeAndQuantite(article!.productId, franchiseur == 0 ? 'Fournisseur' : 'Franchiseur', parsedQuantite)
                .then(data => {
                    setUnitPrice(data!);
                    setUnitPriceWithTaxes(data! + (data! * article!.tvaRate / 100));
                    setTotalPrice(data! - data! * parsedQuantite);
                    setTotalPriceWithTaxes((data! * parsedQuantite + (data! * article!.tvaRate / 100) * parsedQuantite) - (data! * parsedQuantite + (data! * article!.tvaRate / 100) * parsedQuantite) / 100);
                });
        } else {
            toast.error("Quantité minimal est " + minQuantite);
            setQuantity(minQuantite);
        }
    }

    const handleFinalFormSubmit = (values: any) => {
        let newOrderLine: any = {
            quantity: quantity,
            articleId: article!.productId,
            unitPrice: unitPrice,
            unitPriceWithTaxes: (unitPriceWithTaxes),
            tvaRate: article!.tvaRate,
            codeArticle: article?.reference,
            article: article?.label,
        };
        let newOrder: ICommandeFournisseurForAlert = {
            fournisseur: fournisseur,
            franchiseur: franchiseur,
            orderFournisseurLine: newOrderLine
        }
        createOrderForAlert(newOrder);
    }
    return (
        <Modal
            size={"large"}
            open={open}
            onClose={() => { setOpen(false); setDisabled(true); setFournisseur(0); setFranchiseur(0) }}
        >
            <Modal.Header>Affecter Fournisseur</Modal.Header>
            <Modal.Content>

                {!loadingArticle ? (
                    <Grid >
                        <Grid.Row >
                            <label style={{ marginLeft: "20px", paddingTop: "8px" }} >Article : </label>
                            <span style={{ marginLeft: 10, paddingTop: "8px" }}>{article?.reference} - {article?.label}</span>
                            <label style={{ paddingTop: "8px", marginLeft: "20px" }}>Fournisseur :</label>
                            <div style={{ width: 250, float: "left", marginLeft: "20px" }}>

                                <Select isLoading={loadingInitial}
                                    placeholder='Sélectionnez ...'
                                    options={tarifFournisseurs}
                                    onChange={(e) => {
                                        if (e?.type == "Fournisseur") { setFournisseur(e!.fournisseur); setFranchiseur(0); handleSetArticle(e!.fournisseur, 'Fournisseur') }
                                        else { setFournisseur(0); setFranchiseur(e!.franchiseur); handleSetArticle(e!.franchiseur, 'Franchiseur'); }
                                    }}
                                    getOptionLabel={e => e.fabricant ?? e.companyName}
                                    getOptionValue={e => e.fournisseur.toString()}

                                />
                            </div>
                            {!disabled &&
                                <>
                                    <label style={{ marginLeft: "20px", paddingTop: "8px" }} > Quantite :</label>
                                    <div style={{ height: '37px', width: '200px', marginLeft: "10px" }}>
                                        {
                                            article?.incrementation ? (
                                                <input id='Qte' placeholder="Quantité" name='quantiy' min={minQuantite?.toString()} defaultValue={minQuantite?.toString()} type="number"
                                                    disabled={disabled} onBlur={e => handleSetPrixByTypeandQuantite(e)} />
                                            ) : (
                                                <Select
                                                    placeholder={minQuantite}
                                                    isLoading={loadingInitial}
                                                    options={tarifFournisseurs.filter(x => franchiseur == 0 ? x.fournisseur == fournisseur : x.franchiseur == franchiseur)}
                                                    getOptionLabel={e => e!.quantite.toString()}
                                                    getOptionValue={e => e!.quantite.toString()}
                                                    onChange={e => handleSetPrixByTypeandQuantite(e)}
                                                />
                                            )}
                                    </div>
                                </>
                            }
                        </Grid.Row>
                        {!disabled &&
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <Grid>
                                        <FormGridColumn header="Prix unitaire sans taxe" content={unitPrice} />
                                        <FormGridColumn header="Prix unitaire avec taxe" content={unitPriceWithTaxes} />
                                    </Grid>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <Grid>
                                        <FormGridColumn header="Total sans taxe" content={totalPrice} />
                                        <FormGridColumn header="Total avec taxe" content={totalPriceWithTaxes} />
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                        }
                    </Grid>) : (
                        <Grid>
                            <Grid.Row>
                        <Dimmer active inverted>
                            <Loader style={{ color: "green" }} >Chargement de l'article</Loader>
                        </Dimmer>
                                </Grid.Row>
                                </Grid>
                )
                }
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={() => { setOpen(false); setDisabled(true); setFournisseur(0); setFranchiseur(0) }}>
                    Quitter
                </Button>
                <Button positive
                    disabled={fournisseur == 0 && franchiseur == 0}
                    onClick={handleFinalFormSubmit}
                >
                    Commander
                </Button>
            </Modal.Actions>
        </Modal>
    )
}
export default observer(AlertStockModal);