import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Button, Form, Grid, Icon, Radio, Segment, Select } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import { history } from '../../../index';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { useState } from 'react';
import AsyncSelect from "react-select/async";
import Select2 from "react-select";
import FactureAvoireModal from '../detailFacture/FactureAvoireModal';
import { Modal } from 'semantic-ui-react'
import { toast } from 'react-toastify';
import { numberFormat } from '../../../app/common/options/IStyle';
import FactureAccompteModal from './FactureAccompteModal';

interface IProps {
    clientId: string;
    origin: string;
}
const AddFactureContent: React.FC<IProps> = () => {

    const baseRepo = useContext(BaseStoreContext);
    const { create, getListFactureByUser, FactureForAvoir, loadFacture, facture, loadingFacture,submitting } = baseRepo.factureStore;
    const { loadCompanyOrders, companyOrders, loadingCommandeCompany, loadCommande, commande, loadingCommande, filterOrdersNotBilled } = baseRepo.commandeStore;
    
    const [selectedValue, setSelectedValue] = useState(null);
    const [selectedLabel, setSelectedLabel] = useState(null);
    const [valeurRadio, setValeurRadio] = useState<string | undefined>("Facture");
    const [showFacture, setShowFacture] = useState(false);
    const [showOrders, setShowOrders] = useState(false);
    const [showFactureAvoir, setShowFactureAvoir] = useState(false);
    const [idFactureOrigin, setIdFactureOrigin] = useState();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalButton, setModalButton] = useState(false);
    const [isValid, setIsValid] = useState(true);

    const [accompteModalOpen, setAccompteModalOpen] = useState(false);
    

    const handleModalButtonClick = () => {
        setModalButton(true);
    }

    const handleFinalFormSubmit = (values: any) => {
        if (valeurRadio == 'Facture') {
            let newFacture = {
            ...values,
            idUser: selectedValue,
            invoiceType: valeurRadio,
            idFactureOrigin: idFactureOrigin
        };
            create(newFacture);
        }
        
    }

    const handleSetFactureOrigin = (e: any, result: any) => {
        setIdFactureOrigin(result.value);
        loadFacture(result.value);
    }

    const handleSetOrderOrigin = (e: any, result: any) => {
        setIdFactureOrigin(e.id);
        loadCommande(e.id);
    }


    // handle selection
    const handleChangeSelect = (value: any) => {
        let idSelected = value.id  ;
        setSelectedValue(idSelected);
        let labelSelected: any = value.agencyName + '  ' + value.companyName;
        setSelectedLabel(labelSelected);
        setValeurRadio("Facture");
        getListFactureByUser(idSelected).then(data => {

            if (data!.length > 0)
                setShowFacture(true);
            else
                setShowFacture(false);
        });

    }
    //Radio handle Change
    const handleChangeRadio = (e: any, result: any) => {
        setValeurRadio(result.value);
        if (result.value == 'Facture_Avoir') {
            setShowFactureAvoir(true);
        }
        else if (result.value == 'Facture_Acompte') {
            setShowOrders(true);
            loadCompanyOrders(selectedValue!).then(() => filterOrdersNotBilled());
        }
        else {
            setShowOrders(false);
            setShowFactureAvoir(false);
        }
    }
 

    return (
        <Segment style={{ backgroundColor: 'rgb(255,255,255)', padding: '20px' }} >
            <FinalForm
                onSubmit={handleFinalFormSubmit}
                render={({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit} error >
                        <Grid>
                            <Grid.Column width={10}>
                                <Form.Group>
                                 
                                        <div className="field" style={{ width: '100%' }}>
                                        <h5>Client  </h5>
                                        <AsyncSelect
                                            name="form-field-name"
                                            noOptionsMessage={() => "Code introuvable"}
                                            cacheOptions
                                            defaultOptions
                                            placeholder={selectedLabel}
                                            value={selectedValue}
                                            getOptionLabel={e => e!.agencyName + '  ' + e!.companyName+' '+e!.companyType}
                                            getOptionValue={e => e!.id.toString()}
                                            loadOptions={baseRepo.clientStore.loadClientOptions}
                                            onChange={handleChangeSelect}
                                        />
                                        </div>
                                    
                                </Form.Group>

                                <h5>Type Facture : </h5> <br/>

                                <Form.Group>
                                    &nbsp;
                                    &nbsp;
                                    <Radio
                                        label='Facture'
                                        name='radioGroup'
                                        value='Facture'
                                        checked={valeurRadio == 'Facture'}
                                        onChange={handleChangeRadio}
                                    />
                                </Form.Group>
                                <Form.Group style={{ marginBottom: '6px' }}>
                                    &nbsp;
                                    &nbsp;
                                    <Radio
                                        label='Facture_Acompte'
                                        name='radioGroup'
                                        value='Facture_Acompte'
                                        checked={valeurRadio == 'Facture_Acompte'}
                                        onChange={handleChangeRadio}
                                    />
                                    &nbsp;&nbsp;
                                    {showOrders &&
                                        <Grid.Column width={12} style={{ paddingLeft: '40px', width:'100%' }}>
                                        <Select2 name="facture"
                                            
                                            isLoading={loadingCommandeCompany}
                                            options={companyOrders}
                                            getOptionLabel={e => e.reference + ' | ' + new Date(e.orderDate).toLocaleDateString('en-GB') + " | " + numberFormat.format(e.amountWithTaxes)}
                                            getOptionValue={e => e.id.toString()}
                                            placeholder='Listes des commandes'
                                            onChange={(e, data) => handleSetOrderOrigin(e, data)} />
                                        </Grid.Column>
                                    }
                                </Form.Group>
                                {showFacture &&
                                <Form.Group>
                                    &nbsp;
                                    &nbsp;
                                    <Grid.Column width={4}>
                                    <Radio
                                        style={{ paddingTop: '10px' }}
                                        label='Facture_Avoir'
                                        name='radioGroup'
                                        value='Facture_Avoir'
                                        checked={valeurRadio == 'Facture_Avoir'}
                                        onChange={handleChangeRadio}
                                        />
                                     </Grid.Column>
                                    &nbsp;&nbsp;
                                    {showFactureAvoir && 
                                        <Grid.Column width={12} style={{ paddingLeft: '40px' }}>
                                        <Select name="facture" options={FactureForAvoir} placeholder='Listes des factures' onChange={(e, data) => handleSetFactureOrigin(e, data)} />
                                        </Grid.Column>
                                    }
                                 </Form.Group>
                                }

                            </Grid.Column>
                            <Grid.Column width={6}>
                                <Form.Group style={{ paddingTop: '100px', paddingLeft:'120px' }}>
                                    <Button.Group floated="right">
                                        <Button onClick={() => history.push('/factureDashboard')} floated='right' type='button'>Annuler</Button>
                                        <Button.Or />
                                        <Button positive disabled={selectedValue == null || loadingFacture || loadingCommande} loading={submitting} floated='right' type='submit'
                                            onClick={() => {
                                                if (valeurRadio == 'Facture_Avoir') {
                                                    if (!facture)
                                                        toast.warning("Séléctionnez une facture pour l'avoir");
                                                    else
                                                        setModalOpen(true);
                                                } else if (valeurRadio == 'Facture_Acompte') {
                                                    if (!commande)
                                                        toast.warning("Séléctionnez une commande pour l'accompte");
                                                    else
                                                        setAccompteModalOpen(true);
                                                }
                                            }}
                                        >Confirmer
                                        </Button>
                                    </Button.Group>
                                </Form.Group>
                            </Grid.Column>
                        </Grid>
                    </Form>
                )}
            />
            {facture && 
            <Modal
                open={modalOpen}
                size='large'
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >
                <Modal.Header icon='add' style={{ marginLeft: 20 }} >
                    Créer Facture Avoir pour la facture {(facture) ? facture!.reference : ' '}
                </Modal.Header>
                <div style={{ margin: 10, paddingRight: 20, textAlign: 'right' }}> ( {(facture) ? facture!.invoiceLines.length : ' '} ) Article au total
                </div>
                   
                <hr />
                <Modal.Content scrolling>  
                    <FactureAvoireModal Facture={facture!} modalButton={modalButton} setModalOpen={setModalOpen} setModalButton={setModalButton}  setIsValid={setIsValid}/>
                </Modal.Content>
                <Modal.Actions>
                    <div style={{ marginBottom: '18px', marginTop:10 }}>
                        <Button.Group floated="right">
                            <Button
                                type='button'
                                floated='right'
                                onClick={() => {
                                    setIsValid(true)
                                    setModalOpen(false)
                                }}
                            >
                             <Icon name='remove' /> Annuler
                            </Button>
                            <Button.Or text='Ou' />
                            <Button
                                disabled={isValid}
                                positive
                                type='submit'
                                floated='right'
                                onClick={(e) => {
                                        handleModalButtonClick()     
                                }}
                            >                          
                                <Icon name='checkmark' /> Confirmer
                            </Button>
                        </Button.Group>
                    </div>
                </Modal.Actions>
                </Modal>
            }
            {commande &&
                <Modal
                open={accompteModalOpen}
                size='large'
                closeOnEscape={true}
                closeOnRootNodeClick={true}>
                    <Modal.Header icon='add' style={{ marginLeft: 20 }} >
                        Créer une Facture Acopmpte pour la commande {(commande) ? commande!.reference : ' '}
                    </Modal.Header>
                    <hr />
                <Modal.Content scrolling>
                    <FactureAccompteModal Commande={commande} modalButton={modalButton} setModalButton={setModalButton} setModalOpen={setModalOpen} />
                    </Modal.Content>
                    <Modal.Actions>
                        <div style={{ marginBottom: '18px', marginTop: 10 }}>
                            <Button.Group floated="right">
                                <Button
                                    type='button'
                                    floated='right'
                                    onClick={() => {
                                        setAccompteModalOpen(false)
                                    }}
                                >
                                    <Icon name='remove' /> Annuler
                                </Button>
                                <Button.Or text='Ou' />
                                <Button
                                    positive
                                    type='submit'
                                    floated='right'
                                    onClick={(e) => {
                                        handleModalButtonClick()
                                    }}
                                >
                                    <Icon name='checkmark' /> Confirmer
                                </Button>
                            </Button.Group>
                        </div>
                    </Modal.Actions>
                </Modal>
            }
        </Segment>
       
    );
}
export default observer(AddFactureContent);