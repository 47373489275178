import React, { useContext, Fragment } from 'react';
import { Item, Label } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import FranchiseurListItem from './FranchiseurListItem';
import ErrorAucunFranchiseur from './aucunFranchiseur';
import { IFranchiseur } from '../../../app/models/IFranchiseur';

const ListFranchiseur: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { franchiseurs } = baseStore.franchiseurStore;
    if (franchiseurs.length < 1) return < ErrorAucunFranchiseur />
    else
        return (
            <Item.Group divided>
                <Fragment>
                    <Item.Group>
                        {franchiseurs.map((franchiseur: IFranchiseur) => (
                            <FranchiseurListItem key={franchiseur.id} franchiseur={franchiseur} />
                        ))}
                    </Item.Group>

                </Fragment>
            </Item.Group>
        );
}

export default observer(ListFranchiseur);