import { useContext } from 'react';
import { Button, Grid, Table } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import NotFound from '../../../app/layout/NoFound';
import CommandeDuplicationModal from './CommandeDuplicationModal';
import AucunProduit from './AucunProduit';
import FormGridColumn from '../../../app/common/form/FormGridColumn';

const CommandeDuplicationInformations = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { openModal } = baseRepo.modalStore;
    const { commande } = baseRepo.commandeStore;
    if (!commande) {
        return <NotFound />
    }
    else {
        return (
            <div>
                <Grid className="ui stackable">
                    <h3>Informations du Commande:</h3>
                    <Grid.Row style={{ padding: 0, margin: 0 }}>
                        <FormGridColumn header="Client" content={commande!.companyName} root={commande!.companyType == "Franchiseur" ? `/detailFranchiseur/${commande.companyId}` : `/detailClient/${commande.companyId}`} />
                    </Grid.Row>
                    <Grid.Row style={{ padding: 0, margin: 0 }}>
                        <FormGridColumn header="Numéro Commande" content={commande!.id} />
                        <FormGridColumn header="Date Commande" content={new Date(commande!.orderDate).toLocaleDateString('en-GB')} />
                        <FormGridColumn header="Date exp demandée" content={commande!.validationDate ? new Date(commande!.validationDate).toLocaleDateString('en-GB') : "non introduite"} />
                        <FormGridColumn header="Transport" content={commande!.deliveryCompany} />
                        <FormGridColumn header="Livraison" content={commande!.singleDelivery} />
                        <FormGridColumn header="Montant HT" content={commande!.amountWithoutTaxes} />
                        <FormGridColumn header="Montant TTC" content={commande!.amountWithTaxes} />
                        <FormGridColumn header="Commentaires" content={commande!.comment} />
                        <FormGridColumn header="Transporteur" content={commande!.deliveryService} />
                    </Grid.Row>
                    <h3>Adresses commande:</h3>
                    <Grid.Row style={{ padding: 0, margin: 0 }}>
                        <FormGridColumn header="Adresse de facturation" content={commande!.invoiceAddress1} />
                        <FormGridColumn header="Adresse de livraison" content={commande!.deliveryAddress1} />
                        <FormGridColumn header="Complément d’adresse facturation" content={commande!.invoiceAddress2} />
                        <FormGridColumn header="Complément d’adresse livraison" content={commande!.deliveryAddress2} />
                        <FormGridColumn header="Ville de facturation" content={commande!.invoiceCity} />
                        <FormGridColumn header="Ville de livraison" content={commande!.deliveryCity} />
                        <FormGridColumn header="Code postal facturation" content={commande!.invoicePostalCode} />
                        <FormGridColumn header="Code postal livraison" content={commande!.deliveryPostalCode} />
                    </Grid.Row>
                    <h3>Article(s):</h3>
                    {commande?.orderLines.length == 0 ? (
                        <AucunProduit />
                    ) : (
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Code Produit</Table.HeaderCell>
                                    <Table.HeaderCell>Produit</Table.HeaderCell>
                                    <Table.HeaderCell>Quantité</Table.HeaderCell>
                                    <Table.HeaderCell>Prix unitaire</Table.HeaderCell>
                                    <Table.HeaderCell>Total</Table.HeaderCell>
                                    <Table.HeaderCell>TVA</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {commande &&
                                    commande.orderLines.map(order => (
                                        <Table.Row key={order.productId}>
                                            <Table.Cell>{order.articleCode}</Table.Cell>
                                            <Table.Cell>{order.articleName}</Table.Cell>
                                            <Table.Cell>{order.quantity}</Table.Cell>
                                            <Table.Cell>{order.unitPrice}</Table.Cell>
                                            <Table.Cell>{order.unitPriceWithTaxes * order.quantity - order.unitPriceWithTaxes * order.quantity * order.remise/100}</Table.Cell>
                                            <Table.Cell>{order.tvaRate}</Table.Cell>
                                        </Table.Row>
                                    ))}
                            </Table.Body>
                        </Table>
                    )}
                </Grid>
                <div style={{ marginTop: '20px' }}>
                    <Button.Group floated="right">
                        <Button onClick={() => baseRepo.commandeStore.changeDuplicationState(false)} floated='right' type='button'>Annuler</Button>
                        <Button.Or />
                        <Button positive onClick={(e) => {
                            openModal(<CommandeDuplicationModal commande={commande} />)
                        }} floated='right' type='submit'>Dupliquer</Button>
                    </Button.Group>
                </div>
            </div>
        );
    }
}
export default observer(CommandeDuplicationInformations);