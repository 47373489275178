import { observer } from "mobx-react-lite";
import { Fragment, useContext, useState } from "react";
import { FormInput, Header, Menu } from "semantic-ui-react";
import { BaseStoreContext } from "../../../app/store/baseStore";


const OffreFilter = () => {

    const baseStore = useContext(BaseStoreContext);
    const { predicate, setPredicate } = baseStore.specialOfferStore;

    const [searchResults, setSearchResults] = useState([]);

    const handleChange = (event: any) => {
        var predicate = new Map();
        setSearchResults(event.target.value);

        if (event.target.value.length > 1) {
            setPredicate('search', event.target.value)
        }
        else
            setPredicate('all', 'true');
    }

    const handleFilter = (key: string, value: string) => {
        setPredicate(key, value);
    }
    const handleFilterAll = (key: string, value: string) => {
        setPredicate('all', 'true')
        setSearchResults([]);
    }

    return (
        <Fragment>
            <Menu vertical size={'large'} style={{ width: '100%', marginTop: 15 }}>
                <Header icon={'filter'} attached color='teal' content={'Filters'} />
                <FormInput
                    icon='search'
                    type="text"
                    placeholder="Rechercher offre"
                    value={searchResults}
                    onChange={handleChange}
                    style={{ width: '100%' }}
                />
                <Menu.Item
                    active={predicate.size === 0}
                    onClick={() => handleFilterAll('all', 'true')}
                    color='teal'
                    name={'all'}
                    content={'Toutes les commandes'}
                />
                <Menu.Item
                    active={predicate.has('Actif')}
                    onClick={() => handleFilter('Actif', 'true')}
                    color={'blue'}
                    name={'Actif'}
                    content={"Actif"}
                />
            </Menu>
        </Fragment>
    );
}
export default observer(OffreFilter);
