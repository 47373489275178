import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useContext } from "react";
import { Form as FinalForm } from 'react-final-form';
import { Button, Checkbox, Form, Grid, Segment } from "semantic-ui-react";
import { BaseStoreContext } from "../../app/store/baseStore";
import Breadcrumbs from '../dashboard/breadcrumbs';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import AsyncSelect from "react-select/async";
import { StylesConfig } from "react-select";
import { MdCancel } from "react-icons/md";

const ExportComptables: React.FC = () => {
    const { RangePicker } = DatePicker;
    const baseStore = useContext(BaseStoreContext);
    const { looading, exportComptables, exportVentes } = baseStore.factureStore;
    const { looadingAchat, exportAchat } = baseStore.factureFournisseurStore;
    const { loading, exportRGLTComptables } = baseStore.reglementStore;
    const [year, setYear] = useState<number>(0);
    const [month, setMonth] = useState<number>(0);
    const [type, setType] = useState<string>("VENTE");
    const [searchDateCommande, setSearchDateCommande] = useState<any>('');
    const [searchDateCommandeTo, setSearchDateCommandeTo] = useState<any>('');
    const [searchResultsClient, setSearchResultsClient] = useState<any>('');
    const [selectedLabelClient, setSelectedLabelClient] = useState<any>('Client');
    const [searchResultsClientAll, setSearchResultsClientAll] = useState(false);

    const rangePresets: {
        label: string;
        value: [Dayjs, Dayjs];
    }[] = [
            { label: '7 derniers jours', value: [dayjs().add(-7, 'd'), dayjs()] },
            { label: '14 derniers jours', value: [dayjs().add(-14, 'd'), dayjs()] },
            { label: '30 derniers jours', value: [dayjs().add(-30, 'd'), dayjs()] },
            { label: '90 derniers jours', value: [dayjs().add(-90, 'd'), dayjs()] },
        ];
    const handleSetDate = (e: string) => {
        setMonth(parseInt(e.substring(5, 7)));
        setYear(parseInt(e.substring(0, 4)));
    }

    const style: StylesConfig<any> = {
        control: (styles) => ({ ...styles, minWidth:280 }),
    };

    const onRangeChange = (dates: null | (Dayjs | null)[]) => {
        if (dates) {
            setSearchDateCommande(dates[0]?.toISOString());
            setSearchDateCommandeTo(dates[1]?.toISOString());
        }
        else {
            setSearchDateCommande('');
            setSearchDateCommandeTo('');
        }
    };

    const handleClear= () => {
        setSearchResultsClient('');
        setSearchResultsClientAll(false);
        setSelectedLabelClient("Client");
        setMonth(0);
    }

    const handleChangeClient = (value: any) => {
        setSearchResultsClient(value.id);
        setSearchResultsClientAll(value.all);
        setSelectedLabelClient(value!.agencyName + "-" + value!.companyName + (value!.all ? "-Tous les réseaux" : ""));
    }

    const handleFinalFormSubmit = () => {
        if (type != "COMPTABLE") {
            let obj = {
                dateCommande: searchDateCommande,
                dateCommandeTo: searchDateCommandeTo,
                searchClient: searchResultsClient,
                searchClientAll: searchResultsClientAll
            }
            if (type == "RGLT")
                exportRGLTComptables(obj);
            if (type == "VENTE")
                exportVentes(obj);
            if (type == "ACHAT")
                exportAchat(obj);
        }
        if (type == "COMPTABLE") {
            let dateTime = {
                year: year,
                month: month,
                searchClient: searchResultsClient,
                searchClientAll: searchResultsClientAll
            }
            exportComptables(dateTime);
        }

    }

    return (
        <>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Comptabilité", root: "" },
                    { key: 1, name: "Export comtables", root: "/accounting" },
                ]}
            />
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <FinalForm
                            onSubmit={handleFinalFormSubmit}

                            render={({ handleSubmit }) => (
                                <Form onSubmit={handleSubmit} error >
                                    <Segment>
                                        <Grid columns={16}>
                                            <Grid.Row style={{ paddingLeft: 50 }}>
                                                <span style={{ fontSize: 18, fontWeight: 700, color: "#db2828" }}>Veillez sélectionner le mois pour l'exportation</span>
                                            </Grid.Row >

                                            <Grid.Row style={{ textAlign: "center" }}>
                                                <Grid.Column width={16} style={{ margin: 0 }}>
                                                    <div>
                                                        <Checkbox style={{ marginRight: 40, width: 150 }} checked={type == "COMPTABLE"} onClick={(e, value) => value.checked ? setType("COMPTABLE") : setType("VENTE")} label="Export Comptables" />
                                                        <Checkbox style={{ marginRight: 40, width: 150 }} checked={type == "VENTE"} onClick={(e, value) => value.checked ? setType("VENTE") : setType("COMPTABLE")} label="Export Ventes" />
                                                        <Checkbox style={{ marginRight: 40, width: 150 }} checked={type == "ACHAT"} onClick={(e, value) => value.checked ? setType("ACHAT") : setType("VENTE")} label="Export Achats" />
                                                        <Checkbox style={{ marginRight: 40, width: 150 }} checked={type == "RGLT"} onClick={(e, value) => value.checked ? setType("RGLT") : setType("VENTE")} label="Export Règlements" />
                                                    </div>
                                                </Grid.Column >
                                            </Grid.Row >
                                            <Grid.Row style={{ justifyContent: "center" }}>
                                                {type != "ACHAT" &&

                                                    <div className="ResponsiveColumn" style={{ textAlign: "center", display: 'flex' }}>
                                                        <AsyncSelect
                                                            styles={style}
                                                            name="form-field-name"
                                                            noOptionsMessage={() => "Code introuvable"}
                                                            cacheOptions
                                                            defaultOptions
                                                            placeholder={selectedLabelClient}
                                                            value={searchResultsClient}
                                                            getOptionLabel={e => e!.agencyName + "-" + e!.companyName + (e!.all ? "-Tous les réseaux" : "")}
                                                            loadOptions={baseStore.clientStore.loadOptions}
                                                        onChange={handleChangeClient}
                                                    />
                                                    <Button type="button" style={{ padding: 5, fontSize: 17, border: 'none', backgroundColor: 'inherit', color: '#aaa', float:"left" }} onClick={(e: any) => handleClear()}>
                                                        <MdCancel style={{ fontSize: 17 }} />
                                                    </Button>
                                                    </div>
                                                }
                                                <div className={type != "ACHAT" ? "ResponsiveColumn" : ""} >
                                                    {type == "COMPTABLE" ?
                                                        <input style={{ height: 50, borderRadius: 4, color: "green", padding: "12px 20px", width: 250, border: "1px solid #ccc" }}
                                                            type="month" id="month" name="start" min="2023-08" onChange={(e) => handleSetDate(e.target.value)} />
                                                        :
                                                        <RangePicker
                                                            presets={rangePresets}
                                                            onChange={onRangeChange}
                                                            format={'DD/MM/YYYY'}
                                                            placeholder={["Début", "Fin"]}                                                            
                                                        />
                                                    }
                                                </div>
                                                


                                            </Grid.Row>
                                            <Grid.Row style={{ textAlign: "center" }}>

                                                {(loading || looading || looadingAchat) &&
                                                    <Grid.Column width={16}>
                                                        <span style={{ color: "red", fontWeight: 700 }}>Le fichier est en cours de création. Merci pour votre attente !!</span>
                                                    </Grid.Column>

                                                }

                                                <Grid.Column width={16}>
                                                    <Button type="submit" basic active color="green" content="Exporter" loading={looading || loading || looadingAchat} disabled={month == 0 && searchDateCommande == ''} />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Segment>

                                </Form>

                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
}
export default observer(ExportComptables);