import { useContext, useEffect, useState } from 'react';
import { Form, Button, Segment, Accordion, Checkbox } from 'semantic-ui-react';
import { Form as FinalForm, Field } from 'react-final-form';
import { combineValidators, composeValidators, isRequired, createValidator } from 'revalidate';
import { BaseStoreContext } from '../../../app/store/baseStore';
import TextInput from '../../../app/common/form/inputText';
import { v4 as uuid } from 'uuid';
import { Link } from 'react-router-dom';
import InputNumber from '../../../app/common/form/inputNumber';
import InputSelect from '../../../app/common/form/inputSelect';
import { ITListSeuilFranco, ITListTolerenceFacture, ITListTypeDevise, ITListValeur } from '../../../app/common/options/IAchat';
import { history } from '../../../index';
import { observer } from 'mobx-react-lite';
import { IFournisseur } from '../../../app/models/IFournisseur';
import AsyncSelect from "react-select/async";
import Select from 'react-select/';


const isValidEmail = createValidator(
    message => value => {
        if (value && !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,4}$/i.test(value)) {
            return message;
        }
    },
    'Invalid email address'
);
const isPositive = createValidator(
    message => value => {
        if (value && !/^[1-9][0-9]*$/i.test(value)) {
            return message;
        }
    },
    'Invalid number'
);
const isValidSiret = createValidator(
    message => value => {
        if (value && !/^[0-9]{1}[0-9]{13}$/i.test(value)) {
            return message;
        }
    },
    'Invalid Siret'
);
const isValidSiren = createValidator(
    message => value => {
        if (value && !/^[0-9]{1}[1-9]{8}$/i.test(value)) {
            return message;
        }
    },
    'Invalid email address'
);
const isValidtelephone = createValidator(
    message => value => {
        if (value && !/^((\+||00)33||0)*[1-9]([ .-]*[0-9]{2}){4}$/i.test(value)) {
            return message;
        }
    },
    'Invalid number'
);
const isFloat = createValidator(
    message => value => {
        if (value && !/^[+-]?\d+(\.\d+)?$/i.test(value)) {
            return message;
        }
    },
    'Invalid number'
);

const validate = combineValidators({


    telephone: composeValidators(isRequired, isValidtelephone({
        message: 'Entrer un numéro de tel valide'
    }))
        ({
            message: "Veuillez saisir le telephone de l'agence"
        }),

    email: composeValidators(
        isRequired, isValidEmail({
            message: 'Entrez une adresse valide. Exemple : contact@nom.com'
        })
    )({
        message: 'Veuillez saisir l email de la boutique'
    }),
    siret: composeValidators(isValidSiret({
        message: 'il faut entrer 14 chiffres'
    }))({
        message: ''
    }),
    siren: composeValidators(
        isValidSiren({
            message: 'il faut entrer 9 chiffres'
        }))({
            message: ''
        }),
    fraisPort: composeValidators(
        isFloat({
            message: 'invalide valeur'
        }))({
            message: ''
        }),
    valeurTolerenceFacture: composeValidators(
        isPositive({
            message: 'entrer un valeur positif'
        }))({
            message: ''
        }),
});

const AddFournisseurContent = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { create } = baseRepo.fournisseurStore;
    const [state, setState] = useState({ activeIndex: 0 });
    const { activeIndex } = state
    const { loadModePaiements, ITListModePaiements } = baseRepo.modePaiementStore;
    const { loadModeReglements, ITListModeReglement } = baseRepo.modeReglementStore;
    const { ITListTypeMarche, loadTypeMarches } = baseRepo.TypeMarcheStore;
    const [esCompte, setEsCompte] = useState(false);
    const [seuilFranco, setSeuilFranco] = useState("Aucun");
    const [tolérence, setTolérence] = useState("AucunEcart");
    const [typeMarche, setTypeMarche] = useState<string | undefined>(undefined);
    const [fabricant, setFabricant] = useState<string>("");
    const [validFabricant, setValidFabricant] = useState(false);
    const [label, setLabel] = useState<string>();
    const [fraisPort, setFraisPort] = useState("Aucun");
    const [codePostal, setCodePostal] = useState(null);
    const [ville, setVille] = useState<string>("");
    const [countryCode, setCountryCode] = useState(null);
    const [fraisPortValeur, setFraisPortValeur] = useState<string>("0");

    useEffect(() => {
        loadModePaiements();
        loadModeReglements();
        loadTypeMarches();
    }, [loadModePaiements, loadModeReglements, loadTypeMarches])

    const handleChangeFabricant = (e: any, result: any) => {
        setFabricant(result.value);
        setValidFabricant(true);
    }
    const handleChangeSeuilFranco = (e: any, result: any) => {
        setSeuilFranco(result.value)
    }
    const handleChangeTolerence = (e: any, result: any) => {
        setTolérence(result.value);
    }
    const handleChangetypeMarche = (e: any, result: any) => {
        setTypeMarche(result.value);
    }
    const handleClick = (e: any, titleProps: any) => {
        const { index } = titleProps
        const { activeIndex } = state
        const newIndex = activeIndex === index ? -1 : index
        setState({ activeIndex: newIndex })
    }
    const handleClickNext = () => {
        const { activeIndex } = state
        const newIndex = activeIndex + 1
        setState({ activeIndex: newIndex })
    }
    const handleClickPrev = () => {
        const { activeIndex } = state
        const newIndex = activeIndex - 1
        setState({ activeIndex: newIndex })
    }
    const handleChangeFraisPort = (e: any) => {
        setFraisPort(e);
    }
    const handleChangeFraisPortValeur = (e: any, result: any) => {
        fraisPort != 'Aucun' ? setFraisPortValeur(result.value) : setFraisPortValeur('0');
    }
    const handleFinalFormSubmit = (values: any) => {
        let newFournisseur: IFournisseur = {
            ...values,
            country: countryCode,
            codePostal: codePostal,
            ville: ville,
            esCompte: esCompte,
            fournisseurId: uuid(),
            isActive: true,
            typeMarche: typeMarche,
            fabricant: fabricant,
            compteAuxiliere: values.compteAuxiliere ?? fabricant,
            tolerenceFacture: tolérence,
            fraisPort: fraisPortValeur,
            valeur: fraisPort,
            seuilFranco: seuilFranco,
        };
        create(newFournisseur);
        if (values.fabricant != undefined) {
            setLabel("veuillez saisir votre raison sociale");
            history.push('/fournisseurDashboard')
        }
    }

    const handleChangeSelect = (value: any) => {

        setCodePostal(value.codePostal);
        setVille(value.city);
    }
    const handleCountrySelect = (value: any) => {
        setCountryCode(value.iso);
    }

    return (
        <Segment raised style={{ backgroundColor: 'rgb(220,219,219)' }}>
            <FinalForm
                onSubmit={handleFinalFormSubmit}
                validate={validate}
                render={({ handleSubmit, submitting, invalid, pristine }) => (
                    <Form onSubmit={handleSubmit}>
                        <Accordion fluid styled>
                            <Accordion.Title
                                active={activeIndex === 0}
                                index={0}
                                onClick={handleClick}
                                style={{ textAlign: 'center', fontSize: 'large', color: 'black' }}>
                                <i className="dropdown icon"></i>
                                Informations Fournisseurs
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 0}>
                                <Form.Group unstackable widths={2}>
                                    <div className="field">
                                        <label> Société *</label>
                                        <Form.Input name='fabricant' component={TextInput} placeholder="Société " onChange={handleChangeFabricant} />
                                        {/*{!validFabricant && <p className="ui pointing above prompt label " style={{ width: 500 }}>{label}</p> }*/}

                                    </div>
                                    {/*<div className="field">*/}
                                    {/*    <label> Société </label>*/}
                                    {/*    <Field name='societe' component={TextInput} placeholder="Société" />*/}
                                    {/*</div>*/}
                                    <div className="field">
                                        <label> Nom Contact </label>
                                        <Field name='nomContact' component={TextInput} placeholder="Nom Contact" />
                                    </div>
                                </Form.Group>
                                <Form.Group unstackable widths={3}>
                                    <div className="field">
                                        <label>Code Fournisseur *</label>
                                        <Field name='codeFournisseur' component={TextInput} placeholder='codeFournisseur' />
                                    </div>
                                    <div className="field">
                                        <label>Adresse </label>
                                        <Field name='adresse' component={TextInput} placeholder='Adresse' />
                                    </div>
                                    <div className="field">
                                        <label>Adresse complémentaire</label>
                                        <Field name='adresseComplement' component={TextInput} placeholder="Adresse complémentaire" />
                                    </div>
                                </Form.Group>
                                <Form.Group unstackable widths={3}>
                                    <div className="field">
                                        <label>Pays </label>
                                        <AsyncSelect
                                            name="form-field-name"
                                            noOptionsMessage={() => "Code introuvable"}
                                            cacheOptions
                                            defaultOptions
                                            placeholder={countryCode}
                                            value={countryCode}
                                            getOptionLabel={e => e!.pays + '(' + e!.iso + ')'}
                                            getOptionValue={e => e!.iso}
                                            loadOptions={baseRepo.countryCodeStore.getCountryCodes}
                                            onChange={handleCountrySelect}
                                        />
                                    </div>
                                    <div className="field">
                                        <label>Code Postal </label>
                                        <AsyncSelect
                                            name="form-field-name"
                                            noOptionsMessage={() => "Code introuvable"}
                                            cacheOptions
                                            defaultOptions
                                            placeholder={codePostal}
                                            value={codePostal}
                                            getOptionLabel={e => e!.city + '(' + e!.codePostal + ')'}
                                            getOptionValue={e => e!.codePostal}
                                            loadOptions={baseRepo.cityCodeStore.getCityCodes}
                                            onChange={handleChangeSelect}
                                        />
                                    </div>
                                    <div className="field">
                                        <label>Ville </label>
                                        <input name='ville' value={ville} placeholder='Ville' />
                                    </div>
                                </Form.Group>
                                <Form.Group unstackable widths={2}>
                                    <div className="field">
                                        <label>Téléphone *</label>
                                        <Field name='telephone' component={InputNumber} placeholder="Téléphone" type='tel' />
                                    </div>
                                    <div className="field">
                                        <label>Email *</label>
                                        <Field name='email' component={TextInput} placeholder="E-mail" type='email' />
                                    </div>
                                </Form.Group>
                                <Form.Group unstackable widths={2}>
                                    <div className="field">
                                        <label>Site Web</label>
                                        <Field name='siteWeb' component={TextInput} placeholder="Site Web" />
                                    </div>
                                    <div className="field">
                                        <label>TVA Intracommunautaire</label>
                                        <Field name='europeanTvaNumber' component={TextInput} placeholder="TVA Intracommunautaire" />
                                    </div>
                                </Form.Group>
                                <Form.Group unstackable widths={2}>
                                    <div className="field">
                                        <label>Devise </label>
                                        <Field component={InputSelect} options={ITListTypeDevise} name='devise' placeholder="Choisir Devise" defaultValue="Euro" />
                                    </div>
                                    <div className="field">
                                        <label>Type Marché </label>
                                        <Form.Select name='typeMarche' component={InputSelect} options={ITListTypeMarche} placeholder='Choisir Type Marché' onChange={handleChangetypeMarche} />
                                    </div>
                                </Form.Group>
                                <Form.Group unstackable widths={2}>
                                    <div className="field">
                                        <label>Naf</label>
                                        <Field name='naf' component={TextInput} placeholder="Naf" />
                                    </div>
                                    <div className="field">
                                        <label>Siret </label>
                                        <Field name='siret' component={TextInput} placeholder="Siret" />
                                    </div>
                                </Form.Group>
                                <Form.Group unstackable widths={2}>
                                    <div className="field">
                                        <label>Siren</label>
                                        <Field name='siren' component={TextInput} placeholder="Siren" />
                                    </div>
                                    <div className="field">
                                        <label>Cnuf </label>
                                        <Field name='cnuf' component={InputNumber} placeholder="Cnuf" />
                                    </div>
                                </Form.Group>
                                <Button
                                    onClick={handleClickNext}
                                    color='orange'
                                    content='suivant'
                                    style={{ width: '20%', marginLeft: '80%' }}
                                    fluid
                                    as={Link}
                                />
                            </Accordion.Content>
                        </Accordion>
                        <Accordion fluid styled>
                            <Accordion.Title
                                onClick={handleClick}
                                active={activeIndex === 1}
                                index={1}
                                style={{ textAlign: 'center', fontSize: 'large', color: 'black', marginRight: '14.5%' }}>
                                <i className="dropdown icon"></i>
                                Achats
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 1}>
                                {/*<Form.Group unstackable widths={2}>*/}
                                {/*    <div className="field">*/}
                                {/*        <label>Seuil Commande</label>*/}
                                {/*        <Form.Select component={InputSelect} options={ITListSeuil} name='seuil' placeholder="Choisir Seuil " onChange={handleChangeSeuil} />*/}
                                {/*    </div>*/}
                                {/*    {seuil &&*/}
                                {/*        <div className="field">*/}
                                {/*            <label>Valeur</label>*/}
                                {/*            <Field component={TextInput} name='miniCommande' placeholder='Mini Commande ' type='number' />*/}
                                {/*        </div>*/}
                                {/*    }*/}
                                {/*</Form.Group>*/}
                                <Form.Group unstackable widths={2}>
                                    <div className="field">
                                        <label>Seuil Franco  </label>
                                        <Form.Select component={InputSelect} options={ITListSeuilFranco} name='seuilFranco' placeholder="Choisir Seuil Franco " onChange={handleChangeSeuilFranco} />
                                    </div>
                                    {seuilFranco != "Aucun" &&
                                        <div className="field">
                                            <label>Valeur</label>
                                            <Field name='franco' component={TextInput} placeholder="0" type='number' />
                                        </div>
                                    }
                                </Form.Group>
                                {/*<Form.Group unstackable widths={3}>*/}
                                {/*    <div className="field">*/}
                                {/*        <label>Type Remise </label>*/}
                                {/*        <Form.Select component={InputSelect} name='typeRemise' options={ITListValeur} placeholder='Type Remise' onChange={handleChangeRemise} />*/}
                                {/*    </div>*/}
                                {/*    {remise &&*/}
                                {/*        <div className="field">*/}
                                {/*            <label>Montant Remise </label>*/}
                                {/*            <Field component={TextInput} name='montantRemise' placeholder='Montant Remise' type='number' />*/}
                                {/*        </div>*/}
                                {/*    }*/}
                                {/*    {remise &&*/}
                                {/*        <div className="field">*/}
                                {/*            <label>Seuil Minimum </label>*/}
                                {/*            <Field component={TextInput} name='seuilMin' placeholder='Seuil Minimum' type='number' />*/}
                                {/*        </div>*/}
                                {/*    }*/}
                                {/*</Form.Group>*/}
                                <Form.Group unstackable widths={2}>
                                    <div className="field">
                                        <h5>Frais de port</h5>
                                        <Select
                                            getOptionLabel={e => e.text}
                                            getOptionValue={e => e.value}
                                            options={ITListValeur}
                                            placeholder={fraisPort}
                                            name='valeur'
                                            onChange={e => handleChangeFraisPort(e!.value)}
                                        />
                                    </div>
                                    {fraisPort != 'Aucun' &&
                                    <div className="field">
                                        <h5 >Valeur</h5>
                                        <Form.Input name='fraisPort' defaultValue={fraisPortValeur } placeholder='Frais de port' type='number' onChange={handleChangeFraisPortValeur}  />
                                    </div>}
                                    {/*<div className="field">*/}
                                    {/*    <label>En Compte </label>*/}
                                    {/*    <Checkbox toggle value="En Compte" onChange={() => setEsCompte(!esCompte)} style={{ marginTop: '10px' }} />*/}
                                    {/*</div>*/}
                                </Form.Group>
                                <div style={{ width: '100%', marginTop: '20px' }}>
                                    <Button
                                        onClick={handleClickPrev}
                                        color='orange'
                                        content='Retour'
                                        style={{ width: '20%', display: 'inline-block' }}
                                        fluid
                                        basic
                                        as={Link}
                                    />
                                    <Button
                                        onClick={handleClickNext}
                                        color='orange'
                                        content='Suivant'
                                        style={{ width: '20%', display: 'inline-block', marginLeft: '59.3%' }}
                                        fluid
                                        as={Link}
                                    />
                                </div>
                            </Accordion.Content>
                        </Accordion>
                        <Accordion fluid styled>
                            <Accordion.Title
                                onClick={handleClick}
                                active={activeIndex === 2}
                                index={2}
                                style={{ textAlign: 'center', fontSize: 'large', color: 'black', marginRight: '12%' }}>
                                <i className="dropdown icon"></i>
                                Comptable
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 2}>
                                <Form.Group unstackable widths={2}>
                                    <div className="field">
                                        <label>Compte Collectif </label>
                                        <Field component={TextInput} name='compteCollectif' defaultValue='401000' placeholder='Compte Collectif ' />
                                    </div>
                                    <div className="field">
                                        <label>Compte Auxiliere </label>
                                        <Field component={TextInput} name='compteAuxiliere' placeholder={fabricant} />
                                    </div>
                                </Form.Group>
                                <div style={{ width: '100%', marginTop: '20px' }}>
                                    <Button
                                        onClick={handleClickPrev}
                                        color='orange'
                                        content='Retour'
                                        style={{ width: '20%', display: 'inline-block' }}
                                        fluid
                                        basic
                                        as={Link}
                                    />
                                    <Button
                                        onClick={handleClickNext}
                                        color='orange'
                                        content='Suivant'
                                        style={{ width: '20%', display: 'inline-block', marginLeft: '59.3%' }}
                                        fluid
                                        as={Link}
                                    />
                                </div>
                            </Accordion.Content>
                        </Accordion>
                        <Accordion fluid styled>
                            <Accordion.Title
                                onClick={handleClick}
                                active={activeIndex === 3}
                                index={3}
                                style={{ textAlign: 'center', fontSize: 'large', color: 'black', marginLeft: '1.5%' }}>
                                <i className="dropdown icon"></i>
                                Informations Complémentaire
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 3}>
                                <Form.Group unstackable widths={2}>
                                    {/*<div className="field">*/}
                                    {/*    <label>Tarif De Base </label>*/}
                                    {/*    <Field component={InputSelect} options={ITListTarifBase} name='tarifBase' placeholder="Choisir Un Tarif De Base" />*/}
                                    {/*</div>*/}
                                    <div className="field">
                                        <label>Tolerence Facture </label>
                                        <Form.Select component={InputSelect}  options={ITListTolerenceFacture} name='tolerenceFacture' onChange={handleChangeTolerence} />

                                    </div>
                                    {tolérence != "AucunEcart" &&
                                        <div className="field">
                                            <label>Valeur Tolérence Facture </label>
                                            <Field component={TextInput} name='valeurTolerenceFacture' placeholder='Valeur Tolérence Facture' type='number' />
                                        </div>

                                    }
                                </Form.Group>
                                <Form.Group unstackable widths={2}>
                                    <div className="field">
                                        <label>Mode de paiement </label>
                                        <Field component={InputSelect} options={ITListModePaiements} name='modePaiement' placeholder='Mode de paiements' />
                                    </div>
                                    <div className="field">
                                        <label>Echéance </label>
                                        <Field component={InputSelect} options={ITListModeReglement} name='modeReglement' placeholder='Echéance' />
                                    </div>
                                </Form.Group>
                                <div style={{ width: '100%' }}>
                                    <Button
                                        onClick={handleClickPrev}
                                        color='orange'
                                        content='Retour'
                                        style={{ width: '20%', display: 'inline-block' }}
                                        fluid
                                        basic
                                        as={Link}
                                    />
                                    <Button
                                        disabled={submitting || invalid || pristine || !validFabricant}
                                        loading={submitting}
                                        color='orange'
                                        content='Sauvegarder'
                                        style={{ width: '20%', display: 'inline-block', marginLeft: '59.3%' }}
                                        fluid
                                    />
                                </div>
                            </Accordion.Content>
                        </Accordion>
                    </Form>
                )}
            />
        </Segment >
    );
}
export default observer(AddFournisseurContent);
