import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Header, Label, Table, Button } from "semantic-ui-react";
import { BaseStoreContext } from "../../../app/store/baseStore";
import { observer } from 'mobx-react-lite';
import NoResult from "../../devis/devisDashboard/erreurNoResult";
import { IFactureFournisseur } from "../../../app/models/IFactureFournisseur";
import DeleteFactureFournisseurModal from "./DeleteFactureFournisseur";
import { numberFormat } from "../../../app/common/options/IStyle";

const getStatus = (status: string) => {
    switch (status) {
        case 'Valider':
            return 'Validée';
        case 'Brouillon':
            return 'Brouillon';
        default:
            return '';
    }
}

const ListFacture: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { factures, deletingOrder} = baseStore.factureFournisseurStore;
    const { openModal } = baseStore.modalStore;
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    if (factures.length < 1) return < NoResult name="Facture Fournisseur" />
    else
        return (
            <>
                <Table compact='very' style={{ marginTop: '0em' }} textAlign='center'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Référence</Table.HeaderCell>
                            <Table.HeaderCell>N° Facture Fournisseur</Table.HeaderCell>
                            <Table.HeaderCell>Fournisseur</Table.HeaderCell>
                            <Table.HeaderCell>Date Facture</Table.HeaderCell>
                            <Table.HeaderCell>Montant HT</Table.HeaderCell>
                            <Table.HeaderCell>TVA</Table.HeaderCell>
                            <Table.HeaderCell>Montant TTC</Table.HeaderCell>
                            <Table.HeaderCell>Etat</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {factures && factures.map((facture: IFactureFournisseur) => (
                            <Table.Row key={facture.id}>
                                <Table.Cell>
                                    <Header size='small'><Label style={{ marginLeft: '1px', marginTop: '5px', marginBottom: '5px' }} as={Link} to={`/detailFactureFournisseur/${facture.id}`}>{facture.reference}</Label></Header>
                                </Table.Cell>
                                <Table.Cell>{facture.numSupplierInvoice}</Table.Cell>
                                <Table.Cell>{facture.fournisseur ? (facture.fournisseur.codeFournisseur) : (facture.company.agencyName + "-" + facture.company.companyName)}</Table.Cell>
                                <Table.Cell>{new Date(facture.invoiceDate).toLocaleDateString('en-GB')}</Table.Cell>
                                <Table.Cell>{numberFormat.format(facture.amountWithoutTaxes)}</Table.Cell>
                                <Table.Cell>{numberFormat.format(facture.amountWithTaxes - facture.amountWithoutTaxes)}</Table.Cell>
                                <Table.Cell>{numberFormat.format(facture.amountWithTaxes)}</Table.Cell>
                                <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }} >{getStatus(facture.invoiceState)}</Table.Cell>
                                <Table.Cell>
                                    {facture.invoiceState === "Brouillon" ? (
                                        <Button.Group fluid widths={3}>
                                            <Button
                                                style={{ marginRight: '3%' }}
                                                name={facture.id}
                                                basic
                                                size = "small"
                                                color='red'
                                                icon='trash'
                                                onClick={(e) => {
                                                    openModal(<DeleteFactureFournisseurModal commande={facture} />)
                                                    setDeleteTarget(e.currentTarget.name)
                                                }}
                                                loading={deletingOrder && deleteTarget === facture.id.toString()}
                                            />
                                        </Button.Group>
                                    ) : (<></>
                                    )}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </>
        );
}
export default observer(ListFacture);
