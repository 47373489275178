import { observer } from 'mobx-react-lite';
import { Grid } from 'semantic-ui-react';
import Breadcrumbs from '../../dashboard/breadcrumbs';
import AddAchatGContent from './AddAchatGContent';
import AddAchatGHeader from './AddAchatGHeader';

const AddAchatGPage = () => {
    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Achats Groupées", root: "" },
                    { key: 1, name: "Liste des Achats Groupées", root: "/offreDashboard" },
                    { key: 2, name: "Ajouter_Achats_Groupées", root: "/addAchatGroupee" },
                ]}
            />
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Grid style={{ marginTop: 2 }}>
                            <Grid.Column width={16}>
                                <AddAchatGHeader />
                                <AddAchatGContent />
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>

                </Grid.Row>
            </Grid>
        </div>
        );
}
export default observer(AddAchatGPage);

