import React, { Fragment, useContext, useState } from 'react';
import { Menu, FormInput, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../app/store/baseStore';


const AlertStockMultipleSearch = () => {
    const baseStore = useContext(BaseStoreContext);
    const { setPredicate } = baseStore.articleStockStore;
    const [searchRefArticle, setSearchRefArticle] = useState({
        refArticle: '',
    });
    const [searchLibelle, setSearchLibelle] = useState({
        libelle: '',
    });

    const handleInputChangeLibelleEntrepot = (event: any) => {
        setSearchLibelle({
            ...searchLibelle,
            [event.target.name]: event.target.value
        })
    }
    const handleInputChangeCodeArticle = (event: any) => {
        setSearchRefArticle({
            ...searchRefArticle,
            [event.target.name]: event.target.value
        })
    }
    
    const multipleSearch = (event: any) => {
        event.preventDefault()
        var predicate = new Map()
        predicate.set("refArticle", searchRefArticle.refArticle);
        predicate.set("libelle", searchLibelle.libelle);

        if (searchRefArticle.refArticle.length > 0 || searchLibelle.libelle.length > 2)
            setPredicate('searchMultiple', predicate)
        if (searchRefArticle.refArticle.length === 0 && searchLibelle.libelle.length === 0)
            setPredicate('all', 'true')
    }

    return (
        <Fragment>
            <Menu horizontal size={'large'} style={{ width: '100%', border: "none", boxShadow: "none", background: "none" }}>
                <FormInput
                    icon='search'
                    type="text"
                    placeholder="Libelle Entrepot"
                    name="libelle"
                    style={{ width: '190px', height: '50px' }}
                    onChange={handleInputChangeLibelleEntrepot}
                />
                <FormInput
                    icon='code'
                    type="text"
                    placeholder="Code Article"
                    name="refArticle"
                    style={{ width: '190px', height: '50px' }}
                    onChange={handleInputChangeCodeArticle}
                />
                <Button
                    style={{ textAlign: 'right', justifyContent: 'center', height: '45px', marginTop: "2px" }}
                    floated="right"
                    content="Search"
                    color="blue"
                    onClick={multipleSearch}
                />
            </Menu>
        </Fragment>
    );
};

export default observer(AlertStockMultipleSearch);
