import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { history } from '../..';
import { BaseStoreContext } from '../../app/store/baseStore';


const NavBarLeft: React.FC = () => {

    return (
        <div id="sidebar" >
            <div className="ui fluid vertical accordion styled menu borderless side-menu">
                <a className="item side-header" href="/menuPage">
                    <div className="ui header">
                        <img src="/assets/img/logo-e.png" alt="logo" style={{ height: "30px", width: "auto", margin: "0 10px 0 15px" }} />
                        <div className="content">etikeo</div>
                    </div>
                </a>
                <div className="slimScrollDiv" style={{ position: "relative", overflow: "hidden", width: "auto", height: "877px" }} >
                    <div className="slimscroll" style={{ overflow: "hidden", width: "auto", height: 877 }}  >
                        <a href="/menuPage">
                            <div className="title waves-effect">
                                <i className="left-bar-icon"></i>
                                <i className="menu-icon icon iconfont icon-viewgallery" style={{ fontSize: "25px" }} />
                                <span className="accordion-text">Dashboards</span>
                            </div>
                            <div className="ui dropdown item display-none" >
                                <i className="left-bar-icon"></i>
                                <i className="menu-icon icon iconfont icon-viewgallery" />
                            </div>
                        </a>
                        <div className="title waves-effect">
                            <i className="left-bar-icon"></i>
                            <i className="menu-icon icon iconfont icon-zhusu" style={{ fontSize: "25px" }} />
                            <span className="accordion-text">Tiers</span>
                            <i className="drop-icon icon iconfont icon-BAI-xiajiantou" />
                        </div>
                        <div className="content">
                            <a className="item" href="/franchiseurDashboard">Réseaux</a>
                            <a className="item" href="/fournisseurDashboard">Fournisseurs</a>
                            <a className="item" href="/offreDashboard">Achat groupés</a>
                            <a className="item" href="/requests">Demandes</a>
                        </div>
                        <div className="ui dropdown item display-none" tabIndex={0}>
                            <i className="left-bar-icon"></i>
                            <i className="menu-icon icon iconfont icon-zhusu" />
                            <div className="menu hidden transition" tabIndex={-1}>
                                <span className="menu-text">Tiers</span>
                                <a className="item" href="/franchiseurDashboard">Réseaux</a>
                                <a className="item" href="/fournisseurDashboard">Fournisseurs</a>
                                <a className="item" href="/offreDashboard">Achat groupés</a>
                                <a className="item" href="/requests">Demandes</a>
                            </div>
                        </div>
                        <div className="title waves-effect ">
                            <i className="left-bar-icon"></i>
                            <i className="menu-icon icon iconfont icon-changyongshili" style={{ fontSize: "25px" }} />
                            <span className="accordion-text">Articles</span>
                            <i className="drop-icon icon iconfont icon-BAI-xiajiantou" />
                        </div>
                        <div className="content ">
                            <a className="item" href="/articles">Liste des Articles</a>
                            <a className="item" href="/articleService">Liste des services</a>
                            <a className="item" href="/dashboardFamille">Familles</a>
                            <a className="item" href="/statics">Export Statistiques</a>
                        </div>
                        <div className="ui dropdown item display-none" tabIndex={0}>
                            <i className="left-bar-icon"></i>
                            <i className="menu-icon icon iconfont icon-changyongshili" />
                            <div className="menu hidden transition" tabIndex={-1}>
                                <span className="menu-text">Articles</span>
                                <a className="item" href="/articles">Liste des Articles</a>
                                <a className="item" href="/articleService">Liste des services</a>
                                <a className="item" href="/dashboardFamille">Familles</a>
                                <a className="item" href="/statics">Export Statistiques</a>
                            </div>
                        </div>
                        <div className="title waves-effect">
                            <i className="left-bar-icon"></i>
                            <i className="menu-icon icon iconfont icon-yifahuo" style={{ fontSize: "25px" }} />
                            <span className="accordion-text">Stock</span>
                            <i className="drop-icon icon iconfont icon-BAI-xiajiantou" />
                        </div>
                        <div className="content">
                            <a className="item" href="/preparationDashboard">Préparation</a>
                            <a className="item" href="/expeditionDashboard">Expeditions</a>
                            <a className="item" href="/reliquats">Reliquats</a>
                            <a className="item" href="/receptionDashboard">Réception</a>
                            <a className="item" href="/mouvementStockDashboard">Mouvements</a>
                            <a className="item" href="/alertStockDashboard">Alert Stock</a>
                        </div>
                        <div className="ui dropdown item display-none" tabIndex={0}>
                            <i className="left-bar-icon"></i>
                            <i className="menu-icon icon iconfont icon-yifahuo" />
                            <div className="menu hidden transition" tabIndex={-1}>
                                <span className="menu-text">Stock</span>
                                <a className="item" href="/preparationDashboard">Préparation</a>
                                <a className="item" href="/expeditionDashboard">Expeditions</a>
                                <a className="item" href="/reliquats">Reliquats</a>
                                <a className="item" href="/receptionDashboard">Réception</a>
                                <a className="item" href="/mouvementStockDashboard">Mouvements</a>
                                <a className="item" href="/alertStockDashboard">Alert Stock</a>
                            </div>
                        </div>
                        <div className="title waves-effect">
                            <i className="left-bar-icon"></i>
                            <i className="menu-icon icon iconfont icon-fuzhi" style={{ fontSize: "25px" }} />
                            <span className="accordion-text">Vente</span>
                            <i className="drop-icon icon iconfont icon-BAI-xiajiantou" />
                        </div>
                        <div className="content">
                            <a className="item" href="/devisDashboard">Devis</a>
                            <a className="item" href="/commandeDashboard">Commandes</a>
                            <a className="item" href="/commandeEnAttente">Commandes En Attente</a>
                            <a className="item" href="/factureDashboard">Factures</a>
                            <a className="item" href="/bLAFacturer">BL à facturer</a>
                            <a className="item" href="/brAFacturer">BR à facturer</a>
                        </div>
                        <div className="ui dropdown item display-none" tabIndex={0}>
                            <i className="left-bar-icon"></i>
                            <i className="menu-icon icon iconfont icon-fuzhi" />
                            <div className="menu hidden transition" tabIndex={-1}>
                                <span className="menu-text">Vente</span>
                                <a className="item" href="/devisDashboard">Devis</a>
                                <a className="item" href="/commandeDashboard">Commandes</a>
                                <a className="item" href="/commandeEnAttente">Commandes En Attente</a>
                                <a className="item" href="/factureDashboard">Factures</a>
                                <a className="item" href="/bLAFacturer">BL à facturer</a>
                                <a className="item" href="/brAFacturer">BR à facturer</a>
                            </div>
                        </div>
                        <div className="title waves-effect">
                            <i className="left-bar-icon"></i>
                            <i className="menu-icon icon iconfont icon-bianji" style={{ fontSize: "25px" }} />
                            <span className="accordion-text">Achat</span>
                            <i className="drop-icon icon iconfont icon-BAI-xiajiantou" />
                        </div>
                        <div className="content">
                            <a className="item" href="/commandeFournisseurDashboard">Commandes Fournisseurs</a>
                            <a className="item" href="/factureFournisseurDashboard">Facture Fournisseurs</a>
                        </div>
                        <div className="ui dropdown item display-none" tabIndex={0}>
                            <i className="left-bar-icon"></i>
                            <i className="menu-icon icon iconfont icon-bianji" />
                            <div className="menu hidden transition" tabIndex={-1}>
                                <span className="menu-text">Achat</span>
                                <a className="item" href="/commandeFournisseurDashboard">Commandes Fournisseus</a>
                                <a className="item" href="/factureFournisseurDashboard">Facture Fournisseurs</a>
                            </div>
                        </div>
                        <div className="title waves-effect">
                            <i className="left-bar-icon"></i>
                            <i className="menu-icon icon iconfont icon-yifahuo" style={{ fontSize: "25px" }} />
                            <span className="accordion-text">Comptabilité</span>
                            <i className="drop-icon icon iconfont icon-BAI-xiajiantou" />
                        </div>
                        <div className="content">
                            <a className="item" href="/exportList">Export Facture</a>
                            <a className="item" href="/reglement">Reglements</a>
                            <a className="item" href="/accounting">Export Comptables</a>
                            <a className="item" href="/soldes">Export Soldes</a>
                            <a className="item" href="/commission">Export Commission</a>
                            <a className="item" href="/inventaire">Inventaire</a>
                        </div>
                        <div className="ui dropdown item display-none" tabIndex={0}>
                            <i className="left-bar-icon"></i>
                            <i className="menu-icon icon iconfont icon-yifahuo" />
                            <div className="menu hidden transition" tabIndex={-1}>
                                <span className="menu-text">comptabilité</span>
                                <a className="item" href="/exportList">Export Facture</a>
                                <a className="item" href="/reglement">Reglements</a>
                                <a className="item" href="/accounting">Export Comptables</a>
                                <a className="item" href="/soldes">Export Soldes</a>
                                <a className="item" href="/commission">Export Commission</a>
                                <a className="item" href="/inventaire">Inventaire</a>
                            </div>
                        </div>

                        <div className="title waves-effect ">
                            <i className="left-bar-icon"></i>
                            <i className="menu-icon icon iconfont icon-tuandui" style={{ fontSize: "25px" }} />
                            <span className="accordion-text">Role & Permissions</span>
                            <i className="drop-icon icon iconfont  icon-BAI-xiajiantou" />
                        </div>
                        <div className="content ">
                            <a className="item" href="/dashboardUsers">Gestion Utilisateur</a>
                            <a className="item" href="/allRoles">Liste des roles</a>
                        </div>
                        <div className="ui dropdown item display-none" tabIndex={0}>
                            <i className="left-bar-icon"></i>
                            <i className="menu-icon icon iconfont icon-tuandui" />
                            <div className="menu hidden transition" tabIndex={-1}>
                                <span className="menu-text">Role & Permissions</span>
                                <a className="item" href="/dashboardUsers">Gestion Utilisateur</a>
                                <a className="item" href="/allRoles">Liste des roles</a>
                            </div>
                        </div>


                        <div className="title waves-effect">
                            <i className="left-bar-icon"></i>
                            <i className="menu-icon icon iconfont icon-shezhi" style={{ fontSize: "25px" }} />
                            <span className="accordion-text">Paramétres</span>
                            <i className="drop-icon icon iconfont icon-BAI-xiajiantou" />
                        </div>
                        <div className="content">
                            <a className="item" href="/dashboardParametreArticle">Paramètres article</a>
                            <a className="item" href="/dashboard">Mode de paiement</a>
                            <a className="item" href="/dashboardReglement">Echéance</a>
                            <a className="item" href="/dashboardFormeJuridique">Forme Juridique</a>
                            <a className="item" href="/typeMarche">Paramètres Type Marché</a>
                            <a className="item" href="/dashboardTva">Tva</a>
                            <a className="item" href="/dashboardEntrepot">Entrepot</a>
                            <a className="item" href="/dashboardCompteBancaire">Compte Bancaire</a>
                        </div>
                        <div className="ui dropdown item display-none" tabIndex={0}>
                            <i className="left-bar-icon"></i>
                            <i className="menu-icon icon iconfont icon-shezhi" />
                            <div className="menu hidden transition" tabIndex={-1}>
                                <span className="menu-text">Paramétres</span>
                                <a className="item" href="/dashboardParametreArticle">Paramètres article</a>
                                <a className="item" href="/dashboard">Mode de paiement</a>
                                <a className="item" href="/dashboardReglement">Echéance</a>
                                <a className="item" href="/dashboardFormeJuridique">Forme Juridique</a>
                                <a className="item" href="/typeMarche">Paramètres Type Marché</a>
                                <a className="item" href="/dashboardTva">Tva</a>
                                <a className="item" href="/dashboardEntrepot">Entrepot</a>
                                <a className="item" href="/dashboardCompteBancaire">Compte Bancaire</a>
                            </div>
                        </div>
                    </div>
                    <div className="slimScrollBar" style={{ background: "rgb(158, 165, 171)", width: "0.1px", position: "absolute", top: 0, opacity: "0.4", display: "none", borderRadius: 7, zIndex: 99, right: 1, height: "656.814px" }} />
                    <div className="slimScrollRail" style={{ width: "0.1px", height: "100%", position: "absolute", top: 0, display: "none", borderRadius: 7, background: "rgb(51, 51, 51)", opacity: "0.2", zIndex: 90, right: 1 }} />
                </div>
            </div>
        </div>
    );
}

export default observer(NavBarLeft);
