import { observer } from "mobx-react-lite"
import { Link } from "react-router-dom";
import { Button, Grid, Header, Item } from "semantic-ui-react";



const AddAchatGHeader = () => {
    return (
        <Grid>
            <Grid.Column width={16}>
                <Item.Group>

                    <Item>
                        <Item.Content>
                            <Header as='h2' icon='object group outline'></Header>
                            <Header as='h2'>{'Ajouter un Achat Groupée'}</Header>
                            <Button
                                floated="right"
                                content="Retour vers les Achats Groupées"
                                color="orange"
                                icon='arrow alternate circle left outline'
                                basic
                                as={Link}
                                to="/offreDashboard"
                            />
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Grid.Column>
        </Grid>
        );
}

export default observer(AddAchatGHeader);
