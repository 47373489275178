import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Form, Button, Input, Grid } from 'semantic-ui-react';
import { Field, Form as FinalForm } from 'react-final-form';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import { IArticle } from '../../../app/models/IArticle';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { ITarifFranchiseur } from '../../../app/models/ITarifFranchiseur';
import { IDevis } from '../../../app/models/IDevis';
import { IOrderLine } from '../../../app/models/ICommande';
import InputTextArea from '../../../app/common/form/inputTextArea';
import { combineValidators, composeValidators, createValidator } from 'revalidate';
import { IArticleCompose } from '../../../app/models/IArticleCompose';
import { ITListModeAppro } from '../../../app/common/options/IAchat';
import CreatableSelect from 'react-select/creatable';
import { colourStyles } from '../../../app/common/options/IStyle';
import { IOptionList } from '../../../app/models/IOption';
import { v4 as uuid } from 'uuid';

interface IProps {
    devis: IDevis;
    quoteLine: IOrderLine;
    setModalOpen: Dispatch<SetStateAction<boolean>>;
}
const isFloat = createValidator(
    message => value => {
        if (value && !/^[+-]?\d+(\.\d+)?$/i.test(value)) {
            return message;
        }
    },
    'Invalid number'
);
const validate = combineValidators({
    unitPrice: composeValidators(
        isFloat({
            message: 'invalide valeur'
        }))({
            message: ''
        }),
});
const EditDevisArticle: React.FC<IProps> = ({ devis, quoteLine, setModalOpen }) => {

    const baseStore = useContext(BaseStoreContext);
    const { editQuoteLine, submitting } = baseStore.devisStore;

    const { loadArticleFranchiseurs, loadingArticle, ITListArticleByFranchiseur, loadArticle, article } = baseStore.articleStore;
    const { loadtarifFranchiseurs, tarifFranchiseurs, loadingtarifFranchiseur } = baseStore.tarifFranchiseurStore;
    const { loadingOption, loadOptions, ITListOption, submittingOption, loadOptionsArticle, loadOptionsProduct, ITListOptionArticle } = baseStore.optionStore;
    const [minQuantite, setMinQuantite] = useState<number>(quoteLine!.quantity);
    const [validArticle, setValidArticle] = useState(true);
    const [product, setArticle] = useState<IArticle | undefined>();
    const [unitPrice, setUnitPrice] = useState<number>(quoteLine.unitPrice);
    const [unitPriceWithTaxes, setUnitPriceWithTaxes] = useState<number>(quoteLine.unitPrice + (quoteLine.unitPrice * quoteLine.tvaRate / 100));
    const [totalPrice, setTotalPrice] = useState<number>(quoteLine.unitPrice * quoteLine.quantity - quoteLine.unitPrice * quoteLine.quantity * quoteLine.remise / 100);
    const totalPriceTaxes = (quoteLine.unitPrice * quoteLine.quantity + (quoteLine.unitPrice * quoteLine.tvaRate / 100) * quoteLine.quantity);
    const [totalPriceWithTaxes, setTotalPriceWithTaxes] = useState<number>(totalPriceTaxes - totalPriceTaxes * quoteLine.remise / 100);
    const [quantity, setQuantity] = useState<number>(quoteLine.quantity);
    const [disabled, setDisabled] = useState(true);
    const [tarif, setTarif] = useState<ITarifFranchiseur>();
    const [tva, setTVA] = useState(quoteLine.tvaRate);
    const [remise, setRemise] = useState<number>(quoteLine.remise);
    const [packProducts, setPackProducts] = useState<IArticleCompose[]>([]);
    const [modeAppro, setModeAppro] = useState<string>(quoteLine.modeAppro);
    const [isService, setIsService] = useState(quoteLine.isService == true);
    const [paperFormat, setPaperFormat] = useState(quoteLine.paperFormat);
    const [paperWeight, setPaperWeight] = useState(quoteLine.paperWeight);
    const [printing, setPrinting] = useState(quoteLine.printing);
    const [options, setOptions] = useState<Array<IOptionList>>([]);

    useEffect(() => {
        loadOptions();

        if (quoteLine.isService == true) {
            loadArticle(quoteLine.productId).then((data) => {

                const newOption: ITarifFranchiseur = {
                    quantite: quoteLine.quantity,
                    companyId: devis!.companyId,
                    dateCreation: devis!.quoteDate,
                    prix: quoteLine!.unitPrice,
                    product: data,
                    typeFranchiseur: devis.companyType,
                    id: ""
                }
                setTarif(newOption);
                setArticle(data);
            });
            setMinQuantite(quoteLine.quantity);
            setDisabled(false);
            setValidArticle(false);
        }
        else {
            loadOptionsProduct(quoteLine.productId.toString()).then(data => {
                var finition: IOptionList[] = [];
                data.forEach((optionArticle) => {
                    let optionn: IOptionList = {
                        key: optionArticle.option.optionId,
                        label: optionArticle.option.libelle,
                        value: optionArticle.option.optionId
                    }
                    finition.push(optionn)
                })
                setOptions(finition);
            });
            loadtarifFranchiseurs(quoteLine.productId, devis.companyId)
                .then((data: any) => {
                    const tarifFranchiseur = (data![data!.length - 1].quantite <= quoteLine.quantity) ? data![data!.length - 1] : (data![0].quantite > quoteLine.quantity) ? data![0] : data![data!.findIndex((x: ITarifFranchiseur) => x.quantite > quoteLine.quantity) - 1];
                    if (tarifFranchiseur.quantite != quoteLine.quantity)
                        tarifFranchiseur.quantite = quoteLine.quantity;
                    const minQuanity = data![0];
                    setMinQuantite(minQuanity?.quantite);
                    setTarif(tarifFranchiseur);
                    setArticle(tarifFranchiseur!.product);
                    setDisabled(false);
                    setValidArticle(false);
                });
        }
        loadArticleFranchiseurs(devis!.companyId!, devis!.companyType!);
    }, [])

    const handleSetArticle = (result: any) => {
        setValidArticle(true);
        setPackProducts(result.packProducts);
        setModeAppro(result.modeAppro);
        loadOptionsProduct(result.value.toString()).then(data => {
            var finition: IOptionList[] = [];
            data.forEach((optionArticle) => {
                let optionn: IOptionList = {
                    key: optionArticle.option.optionId,
                    label: optionArticle.option.libelle,
                    value: optionArticle.option.optionId
                }
                finition.push(optionn)
            })
            setOptions(finition);
        });
        if (result.isService) {
            setMinQuantite(1);
            setQuantity(1);
            setDisabled(false);
            setValidArticle(false);
            setUnitPrice(1);
            setUnitPriceWithTaxes(1 + (result.tvaRate / 100));
            setTotalPrice(1);
            setIsService(true);
            setArticle(undefined);
            setTotalPriceWithTaxes(1 + (result.tvaRate / 100));
            setTVA(result.tvaRate)
            setPaperFormat("");
            setPaperWeight("");
            setPrinting("");
        }
        else {

            loadtarifFranchiseurs(result.value, devis.companyId)
                .then((data) => {
                    if (data!.length > 0) {
                        let resultTarif = data?.sort((a, b) => a.quantite - b.quantite)
                            .filter((item, index, array) => item.quantite === array[0].quantite)
                            .find(s => { return s; })
                        const minQ = resultTarif?.quantite;
                        const unitPriceByTarif = resultTarif?.prix;
                        const tauxTva = devis!.hasTva ? resultTarif?.product!.tvaRate! : 0;
                        setTVA(tauxTva);
                        setTarif(resultTarif);
                        setArticle(resultTarif?.product);
                        setPaperWeight(resultTarif?.product.paperWeight!);
                        setPaperFormat(resultTarif?.product.paperFormat!);
                        setPrinting(resultTarif?.product.printing!);
                        setMinQuantite(minQ!);
                        setQuantity(minQ!);
                        setUnitPrice(unitPriceByTarif!);
                        setTotalPrice(minQ! * unitPriceByTarif!);
                        setUnitPriceWithTaxes((unitPriceByTarif! * (100 + tauxTva)) / 100);
                        setTotalPriceWithTaxes((minQ! * unitPriceByTarif! * (100 + tauxTva)) / 100);
                        setDisabled(false);
                        setValidArticle(false);
                        setRemise(0);
                    } else {
                        setMinQuantite(0);
                        setQuantity(0);
                        setDisabled(true);
                        setUnitPrice(0);
                        setUnitPriceWithTaxes(0);
                        setTotalPrice(0);
                        setTotalPriceWithTaxes(0);
                        setPaperFormat("");
                        setPaperWeight("");
                        setPrinting("");
                        toast.error("cette article n'a pas de tarif");
                    }
                });
        }
    }
    const handleSetPrixByTypeandQuantite = (e: any) => {
        if (isService) {
            const parsedQuantite = parseFloat(e.quantite)
            setQuantity(parsedQuantite);
            const total = (parsedQuantite * unitPrice);
            const totalTaxes = (total) + (total * tva / 100);
            setTotalPrice(total - total * remise / 100);
            setTotalPriceWithTaxes(totalTaxes - totalTaxes * remise / 100);
        }
        else {
            setValidArticle(true);
            const parsedQuantite = parseFloat(e.quantite);
            setTarif(e);
            if (parsedQuantite > 0) {
                const tauxTva = devis!.hasTva ? product!.tvaRate! : 0;
                setQuantity(parsedQuantite);
                var data = (tarifFranchiseurs![tarifFranchiseurs!.length - 1].quantite <= parsedQuantite) ? tarifFranchiseurs![tarifFranchiseurs!.length - 1].prix : (tarifFranchiseurs![0].quantite > parsedQuantite) ? tarifFranchiseurs![0].prix : tarifFranchiseurs[tarifFranchiseurs!.findIndex((x: ITarifFranchiseur) => x.quantite > parsedQuantite) - 1].prix;
                setUnitPrice(data!);
                const total = parsedQuantite * data!;
                setTotalPrice(total - total * remise / 100);
                const taxePrice = (data! * (100 + tauxTva)) / 100;
                setUnitPriceWithTaxes(taxePrice);
                const totalTaxe = (total * (100 + tauxTva)) / 100;
                setTotalPriceWithTaxes(totalTaxe - totalTaxe * remise / 100);
                setValidArticle(false);
            } else {
                toast.error("Quantité minimal est " + 1);
                setQuantity(minQuantite);
                setValidArticle(false);
            }

        }
    }
    const handleChangeOption = (inputValue: string) => {
        setTimeout(() => {
            var id = uuid();
            const newOption: IOptionList = {
                key: id,
                label: inputValue,
                value: inputValue
            }
            setOptions((prev) => [...prev, newOption]);
        }, 1000);
    }
    const handleChangeQuantity = (inputValue: string) => {
        setTimeout(() => {
            const newOption: ITarifFranchiseur = {
                quantite: parseInt(inputValue),
                companyId: devis!.companyId,
                dateCreation: tarif!.dateCreation,
                prix: tarif!.prix,
                product: tarif!.product,
                typeFranchiseur: tarif!.typeFranchiseur,
                id: tarif!.id
            }
            setTarif(newOption);
            handleSetPrixByTypeandQuantite(newOption);
        }, 1000);
    }

    const handleSetInformation = (e: any) => {
        var value = e.target.value == "" ? 0 : parseFloat(e.target.value);
        const tauxTva = devis!.hasTva ? product!.tvaRate! : 0;
        const UnitPriceWithTaxes = value + value * tauxTva / 100;
        setUnitPrice(value);
        setUnitPriceWithTaxes(UnitPriceWithTaxes);
        setTotalPrice(value * quantity! - (value * quantity! * remise / 100));
        setTotalPriceWithTaxes(UnitPriceWithTaxes * quantity! - (UnitPriceWithTaxes * quantity! * remise / 100));
    }
    const handleChangePriceRemise = (e: any) => {
        const Remise = e.target.value;
        setRemise(Remise);
        setTotalPrice(unitPrice * quantity! - (unitPrice * quantity! * Remise / 100));
        setTotalPriceWithTaxes(unitPriceWithTaxes * quantity! - (unitPriceWithTaxes * quantity! * Remise / 100));
    }
    const handleFinalFormSubmit = (values: any) => {
        var tab: string[] = [];
        options.map((item: any) => {
            tab.push(item.label)
        })
        let newQuoteLine: IOrderLine = {
            ...quoteLine,
            ...values,
            quantity: quantity,
            productId: product?.productId!,
            unitPrice: unitPrice,
            unitPriceWithTaxes: (unitPriceWithTaxes),
            tvaRate: devis!.hasTva ? tva : 0,
            articleCode: product?.reference!,
            articleName: product?.label!,
            id: quoteLine.id,
            weight: product?.weight!,
            totalPrice: totalPrice,
            totalPriceWithTaxes: totalPriceWithTaxes,
            deliveryDelay: product!.deliveryDelay!,
            paperFormat: paperFormat,
            printing: printing,
            paperWeight:paperWeight,
            remise: remise,
            modeAppro: modeAppro,
            optionArticles: tab

        };
        editQuoteLine(quoteLine.id, newQuoteLine);
        setModalOpen(false);
    }

    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            render={({ handleSubmit, invalid }) => (
                <Form onSubmit={handleSubmit} error >
                    <Form.Group unstackable widths={2} >

                        <div className="field">
                            <h5>Article</h5>
                            <Select isSearchable name="article" isLoading={loadingArticle}
                                options={ITListArticleByFranchiseur}
                                getOptionLabel={(x) => x.text}
                                getOptionValue={(x) => x.value.toString()}
                                placeholder={quoteLine.articleName}
                                onChange={(e) => handleSetArticle(e!)}
                                styles={colourStyles}
                            />
                            <div className="field">
                                {packProducts && packProducts.map((product) => (
                                    <div style={{ paddingTop: 10 }}><h5>{product.article} - {product.articleLibelle} / {product.quantite}</h5></div>
                                ))}
                            </div>
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={3}>
                        <div className="field">
                            <label>Quantité *</label>
                            {!validArticle && (
                                <CreatableSelect name="quantiy"
                                    isDisabled={disabled}
                                    isLoading={loadingtarifFranchiseur}
                                    options={tarifFranchiseurs}
                                    value={tarif}
                                    getOptionLabel={e => e?.quantite ? e?.quantite.toString() : `Affecter cette quantité`}
                                    getOptionValue={e => e?.quantite?.toString()}
                                    placeholder='Quantité'
                                    onChange={e => handleSetPrixByTypeandQuantite(e)}
                                    onCreateOption={handleChangeQuantity}
                                    formatCreateLabel={() => `Affecter cette quantité`}
                                />
                            )}
                        </div>
                        <div className="field">
                            <label>Mode Appro</label>
                            {!validArticle &&
                                <Select
                                    isMulti={false}
                                    options={ITListModeAppro}
                                    getOptionLabel={(x) => x.text}
                                    getOptionValue={(x) => x.value.toString()}
                                    defaultInputValue={modeAppro}
                                    onChange={(x) => setModeAppro(x!.value)}
                                />
                            }
                        </div>
                        <div className="field">
                            <label style={{ marginTop: 32 }} color="grey">TVA : {devis!.hasTva ? tva : " (Non assujéti.)"}</label>
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={2}>
                        <div className="field">
                            <label>Prix unitaire</label>
                            <Form.Input name='unitPrice' disabled={validArticle} defaultValue={unitPrice} step={0.0001} type='number' onChange={handleSetInformation} />
                        </div>
                        <div className="field">
                            <label>Prix unitaire avec taxes</label>
                            <input name='unitPriceTaxes' disabled={true} value={unitPriceWithTaxes.toFixed(4)} />
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={2}>
                        <div className="field">
                            <label>Prix Total</label>
                            <input name='totalPrice' disabled={true} placeholder="Prix Total" value={totalPrice.toFixed(2)} />
                        </div>
                        <div className="field">
                            <label>Prix Total avec taxes</label>
                            <input name='totalPriceTaxes' disabled={true} value={totalPriceWithTaxes.toFixed(2)} />
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={2}>
                        <div className="field">
                            <label>Commentaire</label>
                            <Field name='comment' rows={2} defaultValue={quoteLine.comment} component={InputTextArea} placeholder="Commentaire" />
                        </div>
                        <div className="field" style={{ display: validArticle ? 'none' : 'block' }}>
                            <label>Remise</label>
                            <input name='remise' min={0} max={100} defaultValue={0} value={remise} type="number" onChange={(e) => handleChangePriceRemise(e)} />
                        </div>
                    </Form.Group>
                    <Grid>
                      <Grid.Column width={8}>
                            {product?.paperFormat &&
                                <Grid.Row width={16}>
                                    <div className="field">
                                    <label color="grey">Format: <span style={{ fontSize: 14, color: 'grey' }}>{product?.paperFormat}</span></label>
                                    </div>
                                </Grid.Row>
                            }

                            {product?.printing &&
                                <Grid.Row width={16}>
                                    <div className="field">
                                    <label color="grey">Impression: <span style={{ fontSize: 14, color: 'grey' }}>{product?.printing}</span></label>
                                    </div>
                                </Grid.Row>
                            }
                            {product?.paperWeight &&
                                <Grid.Row width={16}>
                                    <div className="field">
                                    <label color="grey">Support: <span style={{ fontSize: 14, color: 'grey' }}>{product?.paperWeight}</span></label>
                                    </div>
                                </Grid.Row>
                            }
                            {product?.deliveryDelay &&
                                <Grid.Row width={16}>
                                    <div className="field">
                                    <label color="grey">Délais de livraison: <span style={{ fontSize: 14, color: 'grey' }}>{product?.deliveryDelay}</span></label>
                                    </div>
                                </Grid.Row>
                            }
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <div className="field">
                                <label color="grey">Poids unitaire: <span style={{ fontSize: 14, color: 'grey' }}>{product?.weight} Kg</span></label>
                            </div>
                            <div className="field">
                                <label> Finition </label>
                                <CreatableSelect
                                    isMulti
                                    isDisabled={loadingOption || submittingOption}
                                    isLoading={loadingOption || submittingOption}
                                    options={ITListOption}
                                    value={options}
                                    placeholder="Finition / Option"
                                    onChange={(option: any) => {
                                        setOptions(option);
                                    }}
                                    onCreateOption={handleChangeOption}
                                />

                            </div>
                        </Grid.Column>
                    </Grid>
                    <Form.Group unstackable style={{ marginLeft: "70%" }} >
                        <Button.Group floated="right">
                            <Button onClick={() => { setModalOpen(false) }} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive disabled={submitting || invalid || validArticle || unitPrice?.toString() == "NaN"} loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </Form.Group>
                </Form>
            )}
        />
    );
}
export default observer(EditDevisArticle);
