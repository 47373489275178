import React, { useContext, useState } from 'react';
import { Table, Button, Modal, Icon, Input } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../app/store/baseStore';
import NoResult from '../commande/commandeDashboard/ErrorNoResult';
import { IDispatch } from '../../app/models/IExpedition';
import _ from 'lodash';


const numberFormat = new Intl.NumberFormat('FR-fr', {
    style: 'currency',
    currency: 'EUR',
    useGrouping: true,
});
const ListBLToBeInvoiced: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { expeditionsAF, factureListExpeditions, submitting } = baseStore.expeditionStore;
    const [ids, setIds] = useState<number[]>([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [date, setDate] = useState<string>(new Date().toISOString());
    const dateNow = new Date();
    const handleCheck = (key: number, value: any) => {
        if (value.target.checked) {
            setIds([...ids, key]);
        } else {
            setIds(_.without(ids, key));
        }
    }

    const handleCheckAll = (value: any) => {
        var tab: number[] = [];
        expeditionsAF.map((stock) => {
            tab.push(stock.id);
        });
        if (value.target.checked) {
            setIds(tab);
        } else {
            setIds([]);
        }
    }

    const getBackgroundColor = (key: number) => {
        return ids.includes(key) ? 'darkgrey' : 'white';
    }

    const Footer = () => {
        return <footer className='footer footer.collapse' style={{
            background: 'gray', textAlign: 'center', left: 0, right: 0, opacity: 0.8
        }}>
            <Button positive type='submit' style={{ margin: '5px' }} disabled={submitting} loading={submitting} onClick={(e) => {
                setModalOpen(true)
            }}  >Facturer</Button>
        </footer>;
    }
     if (expeditionsAF.length < 1) return < NoResult name="Expedition" />
    else
        return (
            <>
                <Table compact='very' style={{ marginTop: '0em' }} >
                    <Table.Header>
                        <Table.Row style={{ textAlign: 'center' }}>
                            <Table.HeaderCell><input type="checkbox" onChange={(value) => handleCheckAll(value)} /></Table.HeaderCell>
                            <Table.HeaderCell>Réference</Table.HeaderCell>
                            <Table.HeaderCell>Ref Commande</Table.HeaderCell>
                            <Table.HeaderCell>Montant HT</Table.HeaderCell>
                            <Table.HeaderCell>Montant TTC</Table.HeaderCell>
                            <Table.HeaderCell>Client</Table.HeaderCell>
                            <Table.HeaderCell>Date Expédition</Table.HeaderCell>
                        {/*    <Table.HeaderCell></Table.HeaderCell>*/}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {expeditionsAF && expeditionsAF.map((stock: IDispatch, key) => (
                            <Table.Row key={stock.id} style={{ backgroundColor: getBackgroundColor(stock.id), textAlign: 'center' }}>
                                <Table.Cell> {(<input type="checkbox" checked={ids.includes(stock.id)} onChange={(value) => handleCheck(stock.id, value)} />)}  </Table.Cell>
                                <Table.Cell>
                                    <span><a style={{ background: "none", cursor: 'pointer' }} href={`/detailExpedition/${stock.id}`}><b>{stock.reference}</b></a></span>
                                </Table.Cell>
                                <Table.Cell><a href={`/detailCommande/${stock.refOrder.substring(7)}`}>{stock.refOrder}</a></Table.Cell>
                                <Table.Cell>{numberFormat.format(stock.amountWithoutTaxes)}</Table.Cell>
                                <Table.Cell>{numberFormat.format(stock.amountWithTaxes)}</Table.Cell>
                                <Table.Cell>{stock.agencyName}-{stock.companyName}</Table.Cell>
                                <Table.Cell>{new Date(stock.dispatchDate).toLocaleDateString('en-GB')}</Table.Cell>
                                {/*<Table.Cell >*/}

                                {/*    <Button*/}
                                {/*        color="orange"*/}
                                {/*        cursor="pointer"*/}
                                {/*        content=' Facturer'*/}
                                {/*        style={{ width: 70, paddingRight: 5, paddingLeft: 5, fontSize: 11 }}*/}
                                {/*        icon={<FaFileInvoice style={{ fontSize: 10 }} />}*/}
                                {/*        onClick={*/}
                                {/*            () => baseStore.expeditionStore.OrderDispatchFacture(stock.id)}*/}
                                {/*    />*/}
                                {/*</Table.Cell>*/}
                                
                            </Table.Row>
                        ))} 
                    </Table.Body>
                </Table>
                {ids.length > 0 ? (<Footer />) : (<br />)}
                <Modal
                    open={modalOpen}
                    size='tiny'
                    closeOnEscape={true}
                    closeOnRootNodeClick={true}
                >
                    <Modal.Header icon='add' style={{ marginLeft: 20 }} >
                        Séléctionnez la date de facturation 
                    </Modal.Header>
                    <Modal.Content style={{ textAlign: "center" }}>
                        <Input name='creationDate' onChange={(e: any) => setDate(e.target.value)} value={date} style={{ height: 50, borderRadius: 4, color: "green", width: 250, border: "1px solid #ccc" }} type='date' />
                    </Modal.Content>
                    <Modal.Actions style={{display:'grid'}}>
                        <div style={{ padding:15 }}>
                            <Button.Group floated="right">
                                <Button
                                    type='button'
                                    floated='right'
                                    onClick={() => {
                                        setModalOpen(false)
                                    }}
                                >
                                    <Icon name='remove' /> Annuler
                                </Button>
                                <Button.Or text='Ou' />
                                <Button
                                    disabled={false}
                                    positive
                                    type='submit'
                                    floated='right'
                                    onClick={(e) => {
                                        setModalOpen(false)
                                        factureListExpeditions(ids, date)
                                    }}
                                >
                                    <Icon name='checkmark' /> Confirmer
                                </Button>
                            </Button.Group>
                        </div>
                    </Modal.Actions>
                </Modal>
            </>

        );
}
export default observer(ListBLToBeInvoiced);