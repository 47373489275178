import React from 'react';
import { Header, Grid, Segment, Item, Statistic, Divider, Reveal, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import NotFound from '../../../app/layout/NoFound';
import { IClient } from '../../../app/models/IClient';

interface IProps {
    client: IClient,
    loading: boolean,
    transforming: boolean,
    isLoggedIn: boolean;
    disable: (id: number) => Promise<void>;
    enable: (id: number) => Promise<void>;
    generateAccountClient: (id: number) => Promise<void>;
    changeType: (id: number) => Promise<void>;
}

const ClientHeader: React.FC<IProps> = ({ client, loading, isLoggedIn, disable, enable, generateAccountClient, transforming, changeType }) => {

    if (!client) return <NotFound />;

    return (
        <Segment>
            <Grid>
                <Grid.Column width={8}>
                    <Item.Group>
                        <Item>
                            <Item.Image avatar size='small' style={{paddingTop:5}} src={client.uri} />
                            <Item.Content verticalAlign='middle'>
                                <Grid>
                                    <Grid.Row>
                                        <div style={{ marginLeft: 20, textAlign: 'center', }}>
                                            <div>
                                                <Header as='h3' style={{ margin: 0 }}>{client!.companyName}</Header>
                                                <Header as='h2' style={{ margin: 0 }}><span style={{ color: "#db2828", }}>{client!.agencyName}</span></Header>
                                            </div>
                                        </div>
                                    </Grid.Row>
                                    <Grid.Row>
                                        {client.id === null &&
                                            <Button
                                                color='orange'
                                                content='Générer compte E-commerce'
                                                basic
                                                onClick={
                                                    () => generateAccountClient(client.id)
                                                }
                                            />
                                        }
                                    </Grid.Row>
                                </Grid>
                            </Item.Content>
                        </Item>
                    </Item.Group>
                </Grid.Column>
                <Grid.Column width={4}>
                    {client.isOpeningPackConsummed==false && (
                        <div style={{ borderRadius: 5, backgroundColor: 'darkseagreen', fontFamily: 'helvetica', color: 'white', margin: 5, padding: 5, textAlign: 'center' }}>
                            <div>
                                Montant restant pris en charge pour votre pack d’ouverture:
                                <br />
                                <span style={{ fontWeight: 'bold', fontFamily: 'helvetica' }}>{client.openingPackAmount.toFixed(2)} € TTC</span>
                            </div>
                        </div>
                    )}
                </Grid.Column>
                <Grid.Column width={4}>
                    <Button
                        fluid
                        color={'orange'}
                        content={client.companyType == "Franchise" ? "Basculer en Succursale" : "Basculer en Franchisé"}
                        onClick={() => { changeType(client.id) }}
                        loading={transforming}
                    />
                    <Divider />
                    {isLoggedIn &&
                        <Reveal animated='move'>
                            <Reveal.Content visible style={{ width: '100%' }}>
                                <Button
                                    fluid
                                    color={client.isActive ? 'green' : 'red'}
                                    content={client.isActive ? 'Franchisé actif' : 'Franchisé désactivé '}
                                />
                            </Reveal.Content>
                            <Reveal.Content hidden>
                                <Button
                                    loading={loading}
                                    fluid
                                    basic
                                    color={client.isActive ? 'red' : 'green'}
                                    content={client.isActive ? 'Désactiver' : 'Activer'}
                                    onClick={
                                        client.isActive
                                            ? () => disable(client.id)
                                            : () => enable(client.id)
                                    }
                                />
                            </Reveal.Content>
                        </Reveal>
                    }
                </Grid.Column>
            </Grid>
        </Segment >
    );
};
export default observer(ClientHeader);