import React, { useContext, useState } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button, Grid, Checkbox } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import InputSelect from '../../../app/common/form/inputSelect';
import { ITListTarifBase, ITListTolerenceFacture } from '../../../../src/app/common/options/IAchat'
import { IFournisseur } from '../../../app/models/IFournisseur';
import { BaseStoreContext } from '../../../app/store/baseStore';
import Select from 'react-select/';

interface IProps {
    editFournisseur: (profil: Partial<IFournisseur>) => void;
    fournisseur: IFournisseur;
}
const FournisseurComplementInfosEditForm: React.FC<IProps> = ({ editFournisseur, fournisseur }) => {
    const [documentValorise, setDocumentValorise] = useState(fournisseur.remise);
    const [tolerenceFacture, setTolerenceFacture] = useState(fournisseur.tolerenceFacture);
    const [valeurTolerenceFacture, setvaleurTolerenceFacture] = useState<string>(fournisseur.valeurTolerenceFacture);
    const baseStore = useContext(BaseStoreContext);

    const handleChangeTolerenceFacture = (e: any) => {
        setTolerenceFacture(e);
    }
    const handleChangeValeurTolerenceFacture = (e: any, result: any) => {
        tolerenceFacture != 'AucunEcart' ? setvaleurTolerenceFacture(result.value) : setvaleurTolerenceFacture('0');
    }
    const handleFinalFormSubmit = (values: any) => {
        let newFournisseur: IFournisseur = {
            ...values,
            tolerenceFacture: tolerenceFacture,
            valeurTolerenceFacture: tolerenceFacture == "AucunEcart" ? '0' : valeurTolerenceFacture,
            documentValorise: documentValorise,
        };
        editFournisseur(newFournisseur);
    }
    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            initialValues={fournisseur!}
            render={({ handleSubmit, invalid, submitting }) => (
                <Form onSubmit={handleSubmit} error>
                    <Grid columns={2} >
                        <Grid.Column>
                            <h5 >Type Tolerence sur factures</h5>
                            <Select
                                getOptionLabel={e => e.text}
                                getOptionValue={e => e.value}
                                options={ITListTolerenceFacture}
                                placeholder={fournisseur!.tolerenceFacture}
                                name='tolerenceFacture'
                                onChange={e => handleChangeTolerenceFacture(e!.value)}
                            />
                        </Grid.Column>
                        {tolerenceFacture != 'AucunEcart' &&
                            <Grid.Column>
                                <h5 >Valeur Tolerence sur factures</h5>
                                <Form.Input name='valeurTolerenceFacture' placeholder='valeurTolerenceFacture' type='number' defaultValue={fournisseur!.valeurTolerenceFacture} onChange={handleChangeValeurTolerenceFacture} disabled={tolerenceFacture == 'AucunEcart'} />
                            </Grid.Column>}
                        {/*<Grid.Column>*/}
                        {/*    <h5 >Tarif de base</h5>*/}
                        {/*    <Field name='tarifBase' component={InputSelect} options={ITListTarifBase} value={fournisseur!.tarifBase} />*/}
                        {/*</Grid.Column>*/}
                        <Grid.Column>
                            <Checkbox  value="documentValorise" defaultChecked={fournisseur!.documentValorise} onChange={() => setDocumentValorise(!fournisseur.documentValorise)} label="Valorisation document" />
                        </Grid.Column>
                    </Grid>
                    <div style={{ marginTop: '30px' }}>
                        <Button.Group floated="right">
                            <Button disabled={submitting} onClick={() => { baseStore.fournisseurStore.changeButtonState(!baseStore.fournisseurStore.editFournisseurMode) }} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive disabled={submitting || invalid } loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </div>
                </Form >
            )}
        />
    );
};

export default observer(FournisseurComplementInfosEditForm);