import React, { useContext } from 'react';
import { Button, Header, Icon, Container } from 'semantic-ui-react';
import { IPreparationCommande } from '../../app/models/IPreparation';
import { BaseStoreContext } from '../../app/store/baseStore';

interface IProps {
    preparation: IPreparationCommande;
}

const CancelPreparationModal: React.FC<IProps> = ({ preparation }) => {
    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    const { canceled, actived } = baseStore.preparationStore;

    return (
        <>
            {preparation.isCanceled ? (
                <Container textAlign='center'>
                    <Header icon='check' content='Activation du bon de préparation' style={{ marginLeft: 20 }} />
                    <p>
                        Voulez vous Activer ce bon de préparation ?
                    </p>
                    <Button.Group fluid>
                        <Button onClick={() => closeModal()}>
                            <Icon name='cancel' /> Annuler
                        </Button>
                        <Button.Or text='Ou' />
                        <Button
                            name={preparation.idPreparation}
                            color='green'
                            onClick={(e) => {
                                actived(preparation.idPreparation)
                                closeModal()
                            }}>
                            <Icon name='checkmark' /> Confirmer
                        </Button>
                    </Button.Group>
                </Container>
            ) : (
                <Container textAlign='center'>
                    <Header icon='cancel' content='Annulation du bon de préparation' style={{ marginLeft: 20 }} />
                    <p>
                        Voulez vous Annuler ce bon de préparation ?
                    </p>
                    <Button.Group fluid>
                        <Button onClick={() => closeModal()}>
                            <Icon name='cancel' /> Annuler
                        </Button>
                        <Button.Or text='Ou' />
                        <Button
                            name={preparation.idPreparation}
                            color='green'
                            onClick={(e) => {
                                canceled(preparation.idPreparation)
                                closeModal()
                            }}>
                            <Icon name='checkmark' /> Confirmer
                        </Button>
                    </Button.Group>
                </Container>
            )
            }

        </>

    )
}
export default CancelPreparationModal;
