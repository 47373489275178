import React, { useContext } from 'react';
import { Form, Button, Header, Segment, Image } from 'semantic-ui-react';
import { BaseStoreContext } from '../../app/store/baseStore';
import { FORM_ERROR } from 'final-form';
import { Form as FinalForm, Field } from 'react-final-form';
import { IUserFromValues } from '../../app/models/IUser';
import TextInput from '../../app/common/form/inputText';
import ErrorMessage from '../../app/common/form/ErrorMessage';
import { combineValidators, composeValidators, isRequired } from 'revalidate';
import ForgetPassword from './ForgetPassword';

const validate = combineValidators({
    email: composeValidators(
        isRequired,
    )({
        message: 'Veuillez enter un email valide'
    }),
    password: composeValidators(
        isRequired,
    )({
        message: 'Veuillez enter une mot de passe valide'
    }),
});

const LoginForm = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { login, getUserClaims } = baseRepo.userStore;
    const { openModal } = baseRepo.modalStore;
    return (
        <FinalForm
            onSubmit={(values: IUserFromValues) => login(values).catch((error: any) => ({
                [FORM_ERROR]: error
            })).then(() => getUserClaims())}
            validate={validate}
            render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit }) => (
                <Form onSubmit={handleSubmit} error>
                    <div style={{ display: "flex", alignItems: "center", justifyContent:"center" }}>
                    <Image
                        src='/assets/img/logo_etikeo.png' alt="Etikeo ERP"
                        className="center"
                        style={{ alignSelf: 'center' }}
                        />
                        </div>
                    <Header as='h2' content='Connectez-vous' color='orange' textAlign='center'>
                    </Header>
                    <Segment>
                        <Field fluid Icon='user' name='email' iconPosition='left' component={TextInput} placeholder='Email' />
                        <Field fluid Icon='lock' iconPosition='left' name='password' component={TextInput} placeholder='Mot de passe' type='Password' />
                        {submitError && !dirtySinceLastSubmit && (
                            <ErrorMessage
                                error={submitError}
                                text='Email ou mot de passe invalide'
                            />
                        )}
                        <Button fluid size='large'
                            disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                            loading={submitting}
                            color='orange'
                            content='Login'
                        />
                        <br/>
                        <a style={{ color: 'red' }} onClick={() => openModal(<ForgetPassword />)} > Mot De Passe Oublié ?</a>
                    </Segment>
                </Form>
            )} />
    );
}
export default LoginForm;