import { observer } from 'mobx-react-lite';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button, Grid } from 'semantic-ui-react';
import InputSelect from '../../app/common/form/inputSelect';
import TextInput from '../../app/common/form/inputText';
import { BaseStoreContext } from '../../app/store/baseStore';


interface IProps {
    setMode: Dispatch<SetStateAction<boolean>>;
}
const AffectArticleToPacks: React.FC<IProps> = ({ setMode }) => {
    const baseRepo = useContext(BaseStoreContext);
    const { article, ITListArticle } = baseRepo.articleStore;
    const { create } = baseRepo.articleComposeStore;
    const [Article, setArticle] = useState();
    const handleFinalFormSubmit = (values: any) => {
        let newPacks = {
            ...values,
            idPack: article?.productId,
            article: Article,
        };
        create(newPacks);
        setMode(false);
    }
    const handleChangeArticle = (e:any,result:any) => {      
        setArticle(result.value);
    }
    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            render={({ handleSubmit, invalid, pristine, submitting}) => (
                <Form onSubmit={handleSubmit} error>
                    <Grid columns={2} divided>
                        <Grid.Column>
                            <h5 >Article</h5>
                            <Form.Select search name='article' component={InputSelect} options={ITListArticle} onChange={handleChangeArticle} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5 >Quantité</h5>
                            <Field name='quantite' component={TextInput} type='number' />
                        </Grid.Column>
                    </Grid>
                    <div style={{ marginTop: '30px' }}>
                        <Button.Group floated="right">
                            <Button disabled={submitting} onClick={() => { setMode(false)}} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive disabled={submitting || invalid || pristine} loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </div>
                </Form >
            )}
        />
    );
};

export default observer(AffectArticleToPacks);