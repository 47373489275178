import React from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { Form, Button, Grid, Input } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { history } from '../../../index';
import { columnStyle } from '../../../app/common/options/IStyle';
import { IFactureFournisseur } from '../../../app/models/IFactureFournisseur';
import { useState } from 'react';
import TextInput from '../../../app/common/form/inputText';

interface IProps {
    editFactureFournisseur: (PurchaseInvoice: Partial<IFactureFournisseur>) => void;
    PurchaseInvoice: IFactureFournisseur;
    submitting: boolean;
}

const EditForm: React.FC<IProps> = ({ editFactureFournisseur, PurchaseInvoice, submitting }) => {
    const [amountWithoutTaxes, setAmountWithoutTaxes] = useState<number>(PurchaseInvoice.amountWithoutTaxes);
    const [amountWithTaxes, setAmountWithTaxes] = useState<number>(PurchaseInvoice.amountWithTaxes);
    const [dateFacture, setDateFacture] = useState<any>(new Date(PurchaseInvoice.invoiceDate).toISOString().split('T')[0]);

    const handleFinalFormSubmit = (values: any) => {
        let newCommande: IFactureFournisseur = {
            ...values,
            amountWithoutTaxes: amountWithoutTaxes,
            amountWithTaxes: amountWithTaxes,
            invoiceDate: dateFacture
        };
        editFactureFournisseur(newCommande);
    }
    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            initialValues={PurchaseInvoice!}
            render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit} error>
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column style={columnStyle}>
                                <h5>N° facture fournisseur</h5>
                                <Field name='numSupplierInvoice' component={TextInput} placeholder='N° facture fournisseur' />
                            </Grid.Column>
                            <Grid.Column style={columnStyle}>
                                <h5>Date Facture
                                </h5>
                                <Input
                                    type="date"
                                    name="invoiceDate"
                                    defaultValue={new Date(PurchaseInvoice.invoiceDate).toISOString().split('T')[0]}
                                    onChange={(e) => setDateFacture(e.target.value)}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column style={columnStyle}>
                                <h5>Montant Ht</h5>
                                <Input name='amountWithoutTaxes' type='number' min={0} step="0.01" defaultValue={PurchaseInvoice.amountWithoutTaxes} onChange={(e) => setAmountWithoutTaxes(parseFloat(e.target.value))} />
                            </Grid.Column>
                            <Grid.Column style={columnStyle}>
                                <h5>Montant TTC</h5>
                                <Input name='amountWithTaxes' type='number' min={0} step="0.01" defaultValue={PurchaseInvoice.amountWithTaxes} onChange={(e) => setAmountWithTaxes(parseFloat(e.target.value))} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <div style={{ marginTop: '20px' }}>
                        <Button.Group floated="right">
                            <Button disabled={submitting} onClick={() => history.push('/commandeFournisseurDashboard')} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </div>
                </Form>
            )}
        />
    );
};
export default observer(EditForm);