import React, { useContext, useEffect, useState } from 'react';
import { Button, Header, Icon, Container } from 'semantic-ui-react';
import { ICommandeFournisseur } from '../../../app/models/ICommandeFournisseur';
import { BaseStoreContext } from '../../../app/store/baseStore';
import Select from 'react-select';
import { IEntrepot } from '../../../app/models/IEntrepot';
import { observer } from 'mobx-react-lite';

interface IProps {
    order: ICommandeFournisseur;
}

const AddReceptionModal: React.FC<IProps> = ({ order }) => {
    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    const { create } = baseStore.receptionStore;
    const { loadEntrepots, entrepots, loadingInitial } = baseStore.entrepotStore;
    const [entrepot, setEntrepot] = useState<IEntrepot>();

    const addReception = () => {
        let newReception = {
            entrepots: entrepot?.entrepotsId! ?? entrepots[entrepots.findIndex(x => x.isMain == true)]?.entrepotsId!,
            orderFournisseur:order.id
        }; 
        create(newReception);
    }

    useEffect(() => {
        loadEntrepots()
    }, [])

    return (
        <Container textAlign='center'>
            <Header icon='add' content='Ajouter une réception pour cette commande' style={{ margin: "10px 0 40px 20px"}} />
            <Select
                name='entrepots'
                placeholder={entrepots[entrepots.findIndex(x => x.isMain == true)]?.libelle??"Séléctionnez un entrepôt.."}
                isLoading={loadingInitial}
                options={entrepots}
                getOptionLabel={e => e!.libelle}
                getOptionValue={e => e!.entrepotsId.toString()}
                onChange={e => { setEntrepot(e!);}}
            />
            <Button.Group  style={{ marginTop: 30,marginBottom:20 }}>
                <Button onClick={() => { closeModal(); }}>
                    <Icon name='remove' /> Annuler
                </Button>
                <Button
                    color='green'
                    onClick={(e) => {
                        addReception();
                        closeModal()
                    }}>
                    <Icon name='checkmark' /> Confirmer
                </Button>
            </Button.Group>

        </Container>
    )
}
export default observer(AddReceptionModal);
