import { useContext, useEffect, useState } from 'react';
import { Tab, Header, Button, Grid, Table, Modal } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import AddReglementModal from '../../Reglement/dashboard/AddReglementModal';
import DeleteReglementModal from '../../Reglement/dashboard/DeleteReglementModal';
import NoResult from '../../devis/devisDashboard/erreurNoResult';
import EditReglementCommande from '../../Reglement/dashboard/EditReglementCommande';
import { IReglement } from '../../../app/models/IReglement';

interface IProps {
    orderId: number;
}

const numberFormat = new Intl.NumberFormat('FR-fr', {
    style: 'currency',
    currency: 'EUR',
    useGrouping: true,
});

const CommandeReglements: React.FC<IProps> = ({ orderId }) => {
    const baseRepo = useContext(BaseStoreContext);
    const { commande } = baseRepo.commandeStore;
    const {  reglements, loadReglementByOrder, loadingReglementByOrder, deletingReglement } = baseRepo.reglementStore; 
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    const [modalOpen, setModalOpen] = useState(false);
    const [reglement, setReglement] = useState<IReglement>();

    const { openModal } = baseRepo.modalStore;
    useEffect(() => {
        loadReglementByOrder(orderId);
    }, []);


    return (
        <Tab.Pane  >
            <Modal
                open={modalOpen}
                size='large'
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >
                <Modal.Header icon='add' >
                    Modifier l'article
                    <Button
                        style={{ height: 35 }}
                        type='button' icon='x' color='red'
                        floated='right'
                        onClick={() => { setModalOpen(false) }}
                    >
                    </Button>
                </Modal.Header>
                <Modal.Content>
                    <EditReglementCommande  reglement={reglement!} setModalOpen={setModalOpen} />
                </Modal.Content>
            </Modal>
            <Grid>
                <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                    <Header floated='left' icon='file' content={`Reglement pour la commande : ${commande?.reference}`} />
                    {commande?.orderLines.length != 0 && 
                        <Button
                            onClick={() => { baseRepo.reglementStore.changeButtonStateAdd(!baseRepo.reglementStore.addMode) }}
                            floated='right'
                            basic
                            content={baseRepo.reglementStore.addMode ? 'Annuler' : 'Affecter Reglement'}
                        />}
                </Grid.Column>
                <Grid.Column width={16}>
                    {baseRepo.reglementStore.addMode ? (
                        <AddReglementModal />
                    ) : (
                        <>
                                {loadingReglementByOrder ? (
                                    <div style={{ marginTop: 50 }} className="ui segment">
                                        <div className="ui active inverted dimmer">
                                            <div className="ui medium text loader">Chargement ...</div>
                                        </div>
                                        <p></p>
                                        <p></p>
                                    </div>)
                                    :
                                    (reglements?.length != 0 ? (
                                        <Table loading={loadingReglementByOrder} celled >
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>Montant</Table.HeaderCell>
                                                    <Table.HeaderCell>Date de paiement</Table.HeaderCell>
                                                    <Table.HeaderCell>Mode de paiement</Table.HeaderCell>
                                                    <Table.HeaderCell>Compte bancaire</Table.HeaderCell>
                                                    <Table.HeaderCell style={{ textAlign: "center" }}>Actions</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {reglements &&
                                                    reglements.map(reglement => (
                                                        <Table.Row key={reglement.idReglement} width={ 4}>                                                         
                                                                <>
                                                                    <Table.Cell>{numberFormat.format(reglement.montant)}</Table.Cell>
                                                                    <Table.Cell>{new Date(reglement.creationDate).toLocaleDateString('en-GB')}</Table.Cell>
                                                                    <Table.Cell>{reglement.modePaiement}</Table.Cell>
                                                                    <Table.Cell>{reglement.account}</Table.Cell>
                                                                    <Table.Cell>
                                                                        <Button.Group fluid widths={2}>
                                                                            <Button
                                                                            onClick={(e) => {
                                                                                setModalOpen(true)
                                                                                setReglement(reglement)
                                                                                }}
                                                                                basic
                                                                                color="blue"
                                                                                icon='edit outline'
                                                                                name={reglement.idReglement}
                                                                                style={{ marginRight: '2%' }}
                                                                            />
                                                                            <Button
                                                                                name={reglement!.idReglement}
                                                                                onClick={(e) => {
                                                                                    openModal(<DeleteReglementModal idreglement={reglement!.idReglement} montant={reglement.montant} />)
                                                                                    setDeleteTarget(e.currentTarget.name)
                                                                                }}
                                                                                loading={deletingReglement && deleteTarget === reglement!.idReglement}
                                                                                basic
                                                                                negative
                                                                                icon='trash'
                                                                            />
                                                                        </Button.Group>
                                                                    </Table.Cell>
                                                                </>
                                                            
                                                        </Table.Row>
                                                    ))}
                                            </Table.Body>
                                        </Table>
                                    ) : (
                                        <NoResult name='reglement' />
                                    ))}
                        </>
                    )}
                </Grid.Column>
            </Grid>
        </Tab.Pane>
    );
}
export default observer(CommandeReglements);