import React, { useContext } from 'react';
import { Button, Header, Icon, Container } from 'semantic-ui-react';
import { IDispatchOrder, IExpeditionCommande } from '../../app/models/IExpedition';
import { BaseStoreContext } from '../../app/store/baseStore';


interface IProps {
    commande: IDispatchOrder | IExpeditionCommande;
}

const DeleteOrderDispatchModal: React.FC<IProps> = ({ commande }) => {
    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    const { deleteOrder } = baseStore.expeditionStore;

    return (
        <Container textAlign='center'>
            <Header icon='trash' content='Supprimer ce bon de livraison' style={{ marginLeft: 20 }} />
            <p>
                Voulez vous supprimer ce bon de livraison ?
            </p>
            <Button.Group fluid>
                <Button onClick={() => closeModal()}>
                    <Icon name='remove' /> Annuler
                </Button>
                <Button.Or text='Ou' />
                <Button
                    name={commande.id}
                    color='green'
                    onClick={(e) => {
                        deleteOrder(commande.id)
                        closeModal()
                    }}>
                    <Icon name='checkmark' /> Confirmer
                </Button>
            </Button.Group>
        </Container>
    )
}
export default DeleteOrderDispatchModal;
