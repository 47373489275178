import React, { useContext, useState } from 'react';
import { Button, Header, Icon, Container, Table, Input } from 'semantic-ui-react';
import { ICommandeFournisseur, IOrderDelivery } from '../../../app/models/ICommandeFournisseur';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';

interface IProps {
    id: number;
    orderDeliveris: IOrderDelivery[];
}

const UpdateDeliveryDateModal: React.FC<IProps> = ({ id, orderDeliveris }) => {
    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    const { updateDeliveryDates } = baseStore.commandeFournisseurStore;
    const [dates, setDates] = useState<IOrderDelivery[]>(orderDeliveris);
    const [editTarget, setEditTarget] = useState<string>();
    const [editForm, setEditForm] = useState<boolean>();
    const [date, setDate] = useState<Date>();
    const [newDate, setNewDate] = useState<string>(new Date().toISOString());
    const [Id, setId] = useState<string>("x");

    const handleDeleteDate = (id: string) => {
        setDates(dates.filter(x => x.id != id));
    }

    const handleUpdateDate = (id: string) => {
        var d = dates;
        runInAction(() => {
            d[d.findIndex(x => x.id == id)].orderDate = date!
        })
        setDates(d);
        setEditForm(false)
    }

    const handleAddDate = (value: any) => {       
        var newDateDelivery:any = {
            id: Id,
            orderDate: newDate!
        }
        setId(Id + "x");
        var d = dates;
        runInAction(() => {
            d.push(newDateDelivery)
        })
   
        setDates(d);
        setNewDate("");
    }

    const handleSubmit = () => {
        let newObj: Partial<ICommandeFournisseur> = {
            id: id,
            orderDeliveries: dates
        };
        updateDeliveryDates(newObj);
    }

    return (
        <Container textAlign='center'>
            <Header icon='add' content='Configuration des dates de livraison' style={{ margin: "10px 0 40px 20px" }} />
            <div style={{ display: "flex", marginBottom: 10 }}>
                <Input name='creationDate' onChange={(e: any) => setNewDate(e.target.value)} value={newDate} type='date' />
                <Button
                    positive
                    disabled={newDate == ""}
                    onClick={(e) => {
                        handleAddDate(e)
                    }}
                    style={{ marginLeft: 10 }}
                    content="Ajouter" />
            </div>
            <Table style={{ border: "none" }}>
                <Table.Body style={{ border: "none" }}>
                    {dates.map(item => (
                        <Table.Row key={item.id}>
                            {editForm && editTarget === item.id ? (
                                <>
                                    <Table.Cell>
                                        <Input name='creationDate' onChange={(e: any) => setDate(e.target.value)} value={date!} style={{ height: 25 }} type='date' />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Button.Group widths={2} style={{ width: 42, height: 20, fontSize: 10, margin: 0, padding: 0 }}>

                                        <Button
                                            style={{ marginRight: '2%', padding: "0px 0px" }}
                                            type='button'
                                            size="tiny"
                                            onClick={() => {
                                                setEditForm(false);
                                            }}
                                            icon='cancel'
                                        />
                                        <Button
                                            positive
                                            size="tiny"
                                            type='button'
                                            onClick={(e) => {
                                                handleUpdateDate(item.id)
                                            }}
                                            style={{ padding: "0px 0px" }}
                                            icon='check'
                                            />
                                            </Button.Group>
                                    </Table.Cell>
                                </>
                            ) : (
                                    <>
                                        <Table.Cell>{new Date(item.orderDate).toLocaleDateString('en-GB')}</Table.Cell>
                                    <Table.Cell style={{ width: 91 }}>
                                        <Button.Group widths={2} style={{ width: 42, height: 20, fontSize: 10, margin: 0, padding: 0 }}>
                                            <Button
                                                    onClick={(e) => {
                                                        setEditTarget(item.id)
                                                        setDate(item.orderDate)
                                                    setEditForm(true);
                                                }}
                                                size="tiny"
                                                basic
                                                color="blue"
                                                icon='edit outline'
                                                style={{ marginRight: '2%', padding: "0px 0px", float: 'right' }}
                                            />
                                            <Button
                                                onClick={(e) => {
                                                    handleDeleteDate(item.id)
                                                }}
                                                basic
                                                size="tiny"
                                                negative
                                                icon='trash'
                                                style={{ padding: "0px 0px" }}
                                            />
                                        </Button.Group>
                                    </Table.Cell>
                                </>
                            )}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Button.Group style={{ marginTop: 30, marginBottom: 20 }}>
                <Button onClick={() => { closeModal(); }}>
                    <Icon name='remove' /> Annuler
                </Button>
                <Button
                    color='green'
                    disabled={dates.length==0}
                    onClick={(e) => {
                        handleSubmit();
                        closeModal()
                    }}>
                    <Icon name='checkmark' /> Confirmer
                </Button>
            </Button.Group>

        </Container>
    )
}
export default observer(UpdateDeliveryDateModal);
