import React, { useContext, useEffect } from 'react';
import { GridColumn, Grid, Label } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import Breadcrumbs from '../../dashboard/breadcrumbs';
import ListBRToBeInvoiced from './ListBRToBeInvoiced';
import RetourAFFiltre from './RetourAFFiltre';
import ListItemPlaceholder from '../../../app/common/form/ListItemPlaceholder';
import { numberFormat } from '../../../app/common/options/IStyle';

const BrAFacturerDashboard: React.FC = () => {

    const baseStore = useContext(BaseStoreContext);
    const { loadRetourAFacturerList, loadingInitialRetourAF, totalHt, totalTtc } = baseStore.expeditionStore;



    useEffect(() => {
        loadRetourAFacturerList(null);
    }, []);

    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Vente", root: "" },
                    { key: 1, name: "Retours à facturer", root: "/brAFacturer" },
                ]}
            />

            <Grid>
                <GridColumn width={16}>

                    <Grid.Row style={{ padding: 0, margin: "10px 20px 0 20px" }}>
                        <RetourAFFiltre />
                    </Grid.Row>

                </GridColumn>
                <GridColumn width={16} style={{ marginLeft: "15px" }}>
                    <Label>Total HT : {numberFormat.format(totalHt)}</Label>
                    <Label>Total TTC : {numberFormat.format(totalTtc)}</Label>
                </GridColumn>
                <GridColumn width={16} style={{ marginTop: "-15px" }}>
                    <Grid style={{ marginTop: 2 }}>
                        <GridColumn width={16}>
                            {loadingInitialRetourAF ? <ListItemPlaceholder /> :
                                <ListBRToBeInvoiced />
                            }
                        </GridColumn>
                    </Grid>
                </GridColumn>
            </Grid>
        </div>
    );
}
export default observer(BrAFacturerDashboard);