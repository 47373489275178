import React, { useContext, useState } from 'react';
import { Table, Button, Label, Header } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../app/store/baseStore';
import NoResult from '../commande/commandeDashboard/ErrorNoResult';
import { IPreparationCommande } from '../../app/models/IPreparation';
import DeleteBonDePreparationModal from './DeleteBonDePreparationModal';
import { Link } from 'react-router-dom';

const getStatus = (status: string) => {
    switch (status) {
        case 'EnAttente':
            return 'En Attente';
        case 'EnCours':
            return 'En Cours';
        case 'Termine':
            return 'Terminée';
        case 'Cloture':
            return 'Clôturée';
        case 'Expedie':
            return 'Expediée';
        default:
            return '';
    }
}
const numberFormat = new Intl.NumberFormat('FR-fr', {
    style: 'currency',
    currency: 'EUR',
    useGrouping: true,
});


const ListPreparation: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { preparations, deletingOrder } = baseStore.preparationStore;
    const { openModal } = baseStore.modalStore;
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    if (preparations.length < 1) return < NoResult name="Preparation" />
    else
        return (
            <>
                <Table compact='very' style={{ marginTop: '1em' }} >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Numéro</Table.HeaderCell>
                            <Table.HeaderCell>Ref Commande</Table.HeaderCell>
                            <Table.HeaderCell>Montant Commande</Table.HeaderCell>
                            <Table.HeaderCell>Nom Client</Table.HeaderCell>
                            <Table.HeaderCell>Date Creation</Table.HeaderCell>
                            <Table.HeaderCell>Etat</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {preparations && preparations.map((stock: IPreparationCommande) => (
                            <Table.Row key={stock.idPreparation}>
                                <Table.Cell>
                                    <Header size='small' ><Label style={{ marginLeft: '1px', backgroundColor: 'unset',  display: 'ruby'}} as={Link} to={`/detailPreparation/${stock.idPreparation}`}>N° {stock!.idPreparation}</Label></Header>
                                </Table.Cell>
                                <Table.Cell><a href={`/detailCommande/${stock.refOrder.substring(7)}`}>{stock.refOrder}</a></Table.Cell>
                                <Table.Cell>{numberFormat.format(stock.amountWithTaxes)}</Table.Cell>
                                <Table.Cell>{stock.codeClient}-{stock.companyName}</Table.Cell>
                                <Table.Cell>{new Date(stock.dateCreation).toLocaleDateString('en-GB')}</Table.Cell>
                                <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }}>{stock.isCanceled == false ? getStatus(stock.prepareState) : "Annulé"}</Table.Cell>
                                <Table.Cell >
                                    {stock.prepareState === "Cloture" && stock.isCanceled !== true ? (

                                        <Button.Group fluid widths={1}>                                          
                                            <Button color="yellow"
                                                cursor="pointer"
                                                size='tiny'
                                                content="Expédier"
                                                icon='truck'
                                                onClick={() => baseStore.expeditionStore.create(stock.idPreparation)}
                                                loading={baseStore.expeditionStore.submitting}
                                            />
                                        </Button.Group>
                                    ) : (<></>
                                    )}
                                </Table.Cell>
                                <Table.Cell>
                                    {stock?.isCanceled == false && stock.prepareState != "Expedie" ? (
                                        <Button.Group fluid widths={3}>
                                            <Button
                                                style={{ marginRight: '3%' }}
                                                name={stock.idPreparation}
                                                basic
                                                size="small"
                                                color='red'
                                                icon='trash'
                                                onClick={(e) => {
                                                    openModal(<DeleteBonDePreparationModal BP={stock} />)
                                                    setDeleteTarget(e.currentTarget.name)
                                                }}
                                                loading={deletingOrder && deleteTarget === stock.idPreparation.toString()}
                                            />
                                        </Button.Group>
                                    ) : (<></>
                                    )}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </>
        );
}
export default observer(ListPreparation);