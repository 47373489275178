import React, { useContext, useEffect } from 'react';
import { Item, Table, Button, Header } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import Loading from '../../../app/layout/Loading';
import { observer } from 'mobx-react-lite';

const NouvellesCommandes: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { loadingInitial, loadCommandes, commandes, isRead } = baseStore.commandeStore;

    useEffect(() => {
        loadCommandes();
    }, [loadCommandes]);

    if (loadingInitial) return <Loading content="Chargement des Commandes en attentes ..." />

    return (
        <div>
            <Item.Group>
                <Item>
                    <Item.Content>
                        <Header as='h2' icon='address card outline'></Header>
                        <Header as='h2'>{'Commandes en attentes'}</Header>
                    </Item.Content>
                </Item>
            </Item.Group>

            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>id</Table.HeaderCell>
                        <Table.HeaderCell>comment</Table.HeaderCell>
                        <Table.HeaderCell>companyName</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {commandes && commandes.filter(x => x.isNotRead).map((commande: any) => (
                        <Table.Row key={commande.id}>
                            <>
                                <Table.Cell>{commande.id}</Table.Cell>
                                <Table.Cell>{commande.comment}</Table.Cell>
                                <Table.Cell >{commande.companyName}</Table.Cell>
                                <Table.Cell >
                                    <Button.Group fluid widths={1}>
                                        <Button
                                            /*  name={commande.id}
                                            as={Link} to={`/detailCommande/${commande.id}`}*/
                                            basic
                                            color='red'
                                            onClick={(e) => {
                                                isRead(commande.id)
                                            }}
                                        >Detail</Button>
                                    </Button.Group>
                                </Table.Cell>
                            </>

                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
}
export default observer(NouvellesCommandes);