import { IFacture } from "../../../app/models/IFacture";
import { observer } from 'mobx-react-lite';
import { history } from '../../../index';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button, Grid } from "semantic-ui-react";
import TextInput from "../../../app/common/form/inputText";

interface IProps {
    editFacture: (commande: Partial<IFacture>) => void;
    facture: IFacture;
    submitting: boolean;
}
const FactureClientEditForm: React.FC<IProps> = ({ editFacture, facture, submitting }) => {

    const handleFinalFormSubmit = (values: any) => {
        let newFacture = {
            ...values,
        };
        editFacture(newFacture);
    }

    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            initialValues={facture!}
            render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit} error>
                    <Grid columns={2} divided>
                        <Grid.Row>
                            <Grid.Column>
                                <h5>Téléphone *</h5>
                                <Field name='invoicePhone' component={TextInput} placeholder='Téléphone' value={facture!.invoicePhone} />
                            </Grid.Column>
                            <Grid.Column>
                                <h5>Email *</h5>
                                <Field name='invoiceEmails' component={TextInput} placeholder='Email' value={facture!.invoiceEmails} />
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column>
                                <h5>Adresse de facture *</h5>
                                <Field name='invoiceAddress1' component={TextInput} placeholder='Adresse' value={facture!.invoiceAddress1} />
                            </Grid.Column>
                            <Grid.Column>
                                <h5>Adresse de facture complemenatire </h5>
                                <Field name='invoiceAddress2' component={TextInput} placeholder='Adresse' value={facture!.invoiceAddress2} />
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column>
                                <h5>City *</h5>
                                <Field name='invoiceCity' component={TextInput} placeholder='City' value={facture!.invoiceCity} />
                            </Grid.Column>
                            <Grid.Column>
                                <h5>Country *</h5>
                                <Field name='invoiceCountry' component={TextInput} placeholder='Country' value={facture!.invoiceCountry} />
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column>
                                <h5>Code Postal *</h5>
                                <Field name='invoicePostalCode' component={TextInput} placeholder='Code Postal' value={facture!.invoicePostalCode} />
                            </Grid.Column>
                            <Grid.Column>
                                <h5>Company *</h5>
                                <Field name='invoiceCompany' component={TextInput} placeholder='Company' value={facture!.invoiceCompany} />
                            </Grid.Column>
                        </Grid.Row>

                    </Grid>
                    <div style={{ marginTop: '20px' }}>
                        <Button.Group floated="right">
                            <Button onClick={() => history.push('/factureDashboard')} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive disabled={submitting} loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </div>
                </Form>
            )}
        />
    );
}

export default observer(FactureClientEditForm);