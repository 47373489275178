import React, { useContext } from 'react';
import { Header, Grid, Segment, Item, Reveal, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import DevisCommandeModal from './DevisCommandeModal';
import { IDevis } from '../../../app/models/IDevis';
import { VscFilePdf } from 'react-icons/vsc';
import { toast } from 'react-toastify';
import { ITVA } from '../../../app/models/ICommande';

const style = {
    margin: 0,
    padding: 0,
    paddingLeft: 5,
}
const styleTitle = {
    margin: 0,
    padding: 0,
    paddingLeft: 5,
    fontWeight: "bold"
}
const numberFormat = new Intl.NumberFormat('FR-fr', {
    style: 'currency',
    currency: 'EUR',
    useGrouping: true,
});

interface IProps {
    devis: IDevis,
    loading: boolean,
    enAttente: (id: number) => Promise<void>;
    valide: (id: number) => Promise<void>;
}
const DevisHeader: React.FC<IProps> = ({ devis, loading, enAttente, valide }) => {
    const baseRepo = useContext(BaseStoreContext);
    const { openModal } = baseRepo.modalStore;
    const { devisCommande, generatePDF } = baseRepo.devisStore;

    const handlePDF = () => {
        generatePDF(devis.id.toString());
    }

    return (
        <Segment>
            <Grid>
                <Grid.Column width={9}>
                    <Item.Group style={{ padding: 20 }}>
                        <Item>
                            <Item.Content verticalAlign='middle'>
                                <Header style={{ paddingBottom: 10, fontSize: 18 }}>Devis n° {devis?.reference}</Header>
                            </Item.Content>
                            <VscFilePdf
                                title="Télécharger PDF"
                                color={baseRepo.devisStore.generatePdfMode ? 'orange' : 'green'}
                                onMouseEnter={() => {
                                    baseRepo.devisStore.changeButtonPdfState(!baseRepo.devisStore.generatePdfMode);
                                }}
                                onMouseLeave={() => {
                                    baseRepo.devisStore.changeButtonPdfState(!baseRepo.devisStore.generatePdfMode);
                                }}
                                style={{ marginLeft: 20, fontSize: "30px", cursor: 'pointer', content: "dddd" }}
                                onClick={handlePDF} />
                        </Item>
                        <Item>
                            <Item.Content verticalAlign='middle'>
                                <Header as='h3'>{devis?.agencyName}-{devis?.companyName}</Header>
                                <p>{new Date(devis?.quoteDate).toLocaleDateString('en-GB')}</p>
                                <span><b>Facturation :</b> {devis!.invoiceCompany}, {devis!.invoiceAddress1} {devis!.invoicePostalCode} {devis!.invoiceCity}, {devis!.invoiceCountry}</span>
                                <br />
                                <span><b>Livraison :</b> {devis!.deliveryCompany}, {devis!.deliveryAddress1} {devis!.deliveryPostalCode} {devis!.deliveryCity}, {devis!.deliveryCountry}</span>
                            </Item.Content>
                        </Item>
                    </Item.Group>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Grid style={{ margin: 10 }}>
                        <Grid.Row style={{ margin: 0, padding: 0 }}>
                            <Grid.Column width={8} style={styleTitle}>
                                Montant HT
                            </Grid.Column>
                            <Grid.Column width={8} style={style}>
                                {numberFormat.format(devis.amountWithoutTaxes)}
                            </Grid.Column>
                            {devis.deliveryCosts > 0 &&
                                <>
                                    <hr style={{ textAlign: "center", width: "100%" }} />
                                    <Grid.Column width={8} style={styleTitle}>
                                    Livraison
                                    </Grid.Column>
                                    <Grid.Column width={8} style={style}>
                                    {numberFormat.format(devis.deliveryCosts)}
                                    </Grid.Column>
                                </>
                            }
                            {devis.tvaPrices && devis.tvaPrices.map((value: ITVA) => (
                                <>
                                    <hr style={{ textAlign: "center", width: "100%" }} />
                                    <Grid.Column width={8} style={styleTitle}>
                                        TVA {value.key} %
                                    </Grid.Column>
                                    <Grid.Column width={8} style={style}>
                                        {numberFormat.format(value.value)}
                                    </Grid.Column>
                                </>
                            ))}
                            <hr style={{ textAlign: "center", width: "100%" }} />
                            <Grid.Column width={8} style={styleTitle}>
                                Montant TTC
                            </Grid.Column>
                            <Grid.Column width={8} style={style}>
                                {numberFormat.format(devis.amountWithTaxes)}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
                <Grid.Column width={3}>
                    <Button.Group vertical style={{ width: '100%', marginTop: 4 }}>
                        {devis.orderLines.length == 0 ?
                            (null) : (
                                <Reveal >
                                    <Reveal.Content visible style={{ width: '100%' }}>
                                        <Button
                                            fluid
                                            color={'blue'}
                                            size="small"
                                            style={{ display: devis?.quoteState == "Transformed" ? "none" : "block" }}
                                            onClick={(e) => {
                                                if (devis?.quoteState == "EnAttente") {
                                                    openModal(<DevisCommandeModal devis={devis} />)
                                                }
                                                else {
                                                    devisCommande(devis?.id);
                                                }
                                            }}
                                            content={'Générer Commande'}
                                        />
                                    </Reveal.Content>
                                </Reveal>)}
                        <Button
                            style={{ marginTop: 4 }}
                            fluid
                            size="small"
                            color={baseRepo.devisStore.duplicationMode ? 'red' : 'orange'}
                            onClick={() => {
                                baseRepo.devisStore.changeDuplicationState(!baseRepo.devisStore.duplicationMode);
                            }}
                            content={baseRepo.devisStore.duplicationMode ? 'Annuler' : 'Dupliquer'}
                        />
                    </Button.Group>
                    {devis.orderLines.length == 0 ? (
                        <Reveal >
                            <Reveal.Content visible style={{ width: '100%' }}>
                                <Button
                                    size='small'
                                    fluid
                                    color={'red'}
                                    content={'Devis En Attente '}
                                />

                            </Reveal.Content>
                        </Reveal>) : (

                        <Reveal animated='move'>
                            <Reveal.Content visible style={{ width: '100%' }}>
                                <Button
                                    fluid
                                    style={{ display: devis?.quoteState == "Transformed" ? "none" : "block" }}
                                    color={devis?.quoteState == "Valide" ? 'green' : 'red'}
                                    content={devis?.quoteState == "Valide" ? 'Devis valide' : 'Devis En Attente '}
                                />
                            </Reveal.Content>
                            <Reveal.Content hidden>
                                <Button
                                    loading={loading}
                                    fluid
                                    basic
                                    color={devis?.quoteState == "Valide" ? 'red' : 'green'}
                                    content={devis?.quoteState != "Valide" ? 'Valider' : 'EnAttente'}
                                    onClick={() => {
                                        devis?.quoteState == "Valide"
                                            ? enAttente(devis.id)
                                            : devis.orderLines.length > 0
                                                ? valide(devis.id)
                                                : toast.warning("veillez ajouter au moins un article")
                                    }}
                                />
                            </Reveal.Content>
                        </Reveal>)}
                </Grid.Column>
            </Grid>
        </Segment >
    );
};
export default observer(DevisHeader);