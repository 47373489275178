import { BaseStore } from "./baseStore";
import { observable, action, computed, runInAction, reaction, toJS } from 'mobx';
import agent from '../agent/agent';
import { toast } from "react-toastify";
import { history } from '../../index';
import { IDeviCFList } from "../models/IDevis";
import { ICommandeFournisseur, ICommandeFournisseurForAlert, IOrderFournisseurLine, IOrderSupplier, IRelatedElement } from "../models/ICommandeFournisseur";
import { IFournisseur, ISupplierDetail } from "../models/IFournisseur";
import { IFranchiseur } from "../models/IFranchiseur";
import { IListRelatedElements } from "../models/IFacture";

const LIMIT = 20;

export default class commandeFournisseurStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0;
                this.loadListCommandeFournisseur();
            }
        )
        reaction(
            () => this.activeTab,
            activeTab => {
                if (activeTab != '')
                    return true;
            }
        )
    }
    @observable loadingInitial = false;
    @observable loadingExport = false;
    @observable addingArticle = false;
    @observable deletingcommandeFournisseurArticle = false;
    @observable loadingCommandeFournisseur = false;
    @observable loadingListFournisseur = false;
    @observable loadingOrderState = false;
    @observable loadingOrderFournisseurLine = false;
    @observable loadingRelatedElements = false;
    @observable submitting = false;
    @observable editCommandeFournisseurMode = false;
    @observable commandesFournisseur: IOrderSupplier[] = [];
    @observable supplierOrders: IOrderSupplier[] = [];
    @observable commandeFournisseur: ICommandeFournisseur | null = null;
    @observable relatedElements: IRelatedElement [] = [];
    @observable commandeFournisseurLine: IOrderFournisseurLine | null = null;
    @observable commandeFournisseurRegestry = new Map();
    @observable commandeFournisseurLineRegestry = new Map();
    @observable commandeFournisseurCount: number = 0;
    @observable totalHt: number = 0;
    @observable totalTtc: number = 0;
    @observable predicate = new Map();
    @observable page = 0
    @observable activeTab: string = "0";
    @observable progress: number = 50;
    @observable duplicationMode = false;
    @observable ITListClientsFranchis: IDeviCFList[] = [];
    @observable ITListFournisseur: ISupplierDetail[] = [];
    @observable ITListFranchiseur: IFranchiseur[] = [];
    @observable relatedElementsList: IListRelatedElements[] = [];
    @observable deletingOrder = false;


    @computed get axiosParams() {
        const params = new URLSearchParams();
        params.append('limit', String(LIMIT));
        params.append('offset', `${this.page ? this.page * LIMIT : 0}`);
        this.predicate.forEach((value, key) => {
            if (key === 'startDate') {
                params.append(key, value.toISOString())
            } if (key === 'searchMultiple') {
                var search = [...value][0];
                var codeArticle = [...value][1];
                var codeFranchiseur = [...value][2];
                var codeFournisseur = [...value][3];
                var dateCommande = [...value][4];
                var dateCommandeTo = [...value][5];
                var network = [...value][6];
                var key = [...value][7];
                var tri = [...value][8];
                var SearchNFournisseur = [...value][9];
                var InternalRef = [...value][10];
                params.append(search[0], search[1])
                params.append(codeArticle[0], codeArticle[1])
                params.append(codeFranchiseur[0], codeFranchiseur[1])
                params.append(codeFournisseur[0], codeFournisseur[1])
                params.append(dateCommande[0], dateCommande[1])
                params.append(dateCommandeTo[0], dateCommandeTo[1])
                params.append(network[0], network[1])
                params.append(key[0], key[1])
                params.append(tri[0], tri[1])
                params.append(SearchNFournisseur[0], SearchNFournisseur[1])
                params.append(InternalRef[0], InternalRef[1])
    
            } else {
                params.append(key, value)
            }
        })
        return params;
    }

    //@computed get commandeFournisseurByStatus() {
    //    return this.sortByStatus(Array.from(this.commandeFournisseurRegestry.values()));
    //}

    @computed get totalPages() {
        return Math.ceil(this.commandeFournisseurCount / LIMIT);
    }


    @action setPage = (page: number) => {
        this.page = page;
    }

    @action setPredicate = (predicate: string, value: any) => {
        this.commandesFournisseur = [];
        this.predicate.clear();
        if (predicate !== 'searchMultiple') {
            this.predicate.clear();
        }
        if (predicate !== 'all') {
            this.predicate.set(predicate, value);
        }
    }


    getOrderFournisseurLine = (id: number) => {
        return this.commandeFournisseurLineRegestry.get(id);
    }

    //sortByStatus(commandesFournisseur: ICommandeFournisseur[]) {
    //    const sortedCommandeFournisseur = commandesFournisseur.sort(
    //        (a, b) => new Date(a.orderDate).getTime() - new Date(b.orderDate).getTime());
    //    const sotedByStatus = sortedCommandeFournisseur.sort((a, b) => a.orderFournisseurState.localeCompare(b.orderFournisseurState));

    //    return Object.entries(
    //        sotedByStatus.reduce(
    //            (commandesFournisseur, commandeFournisseur) => {

    //                const cate = commandeFournisseur.orderFournisseurState;
    //                commandesFournisseur[cate] = commandesFournisseur[cate]
    //                    ? [...commandesFournisseur[cate], commandeFournisseur]
    //                    : [commandeFournisseur];
    //                return commandesFournisseur;
    //            },
    //            {} as { [key: string]: ICommandeFournisseur[] }
    //        )
    //    );
    //}

    @action changeButtonState = async (newState: boolean) => {
        this.editCommandeFournisseurMode = newState;
    }

    @action changeDuplicationState = async (newState: boolean) => {
        this.duplicationMode = newState;
    }

    @action setActiveTab = async (activeIndex: string) => {
        runInAction(() => {
            this.activeTab = activeIndex;
        })
    }

    @action exportCommandeFournisseur = async (obj: any) => {
        this.loadingExport = true;
        try {
            await agent.commandeFournisseurAgent.exportExcel(obj);
            runInAction(() => {
                this.loadingExport = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingExport = false;
            })
            console.log(e);
        }
    }

    @action loadListCommandeFournisseur = async () => {
        this.loadingInitial = true;
        try {
            const commandeFournisseurEnveloppe = await agent.commandeFournisseurAgent.list(this.axiosParams);
            const { commandesFournisseur, commandeFournisseurCount, totalHt,totalTtc } = commandeFournisseurEnveloppe;
            runInAction(() => {
                commandesFournisseur.forEach((commandeFournisseur) => {
                    if (this.page == 0) {
                        this.commandesFournisseur = commandesFournisseur;
                    } else {
                        this.commandesFournisseur.push(commandeFournisseur);
                    }
                })
                this.commandeFournisseurCount = commandeFournisseurCount;
                this.totalTtc = totalTtc;
                this.totalHt = totalHt;
                this.loadingInitial = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingInitial = false;
            })
            console.log(e);
        }
    }
    @action create = async (values: Partial<ICommandeFournisseur>) => {
        this.submitting = true;
        try {
            var id = await agent.commandeFournisseurAgent.create(values);
            runInAction(() => {
                this.submitting = false;
                toast.success("ajout effectué avec succès");
            })
            history.push(`/detailCommandeFournisseur/${id}`);
        } catch (e) {
            toast.error("Problème d'ajout d'une commande fournisseur!");
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    @action loadCommandeFournisseur = async (id: string) => {
        this.loadingCommandeFournisseur = true;

        try {
            var commandeFournisseur = await agent.commandeFournisseurAgent.details(id);
            runInAction(() => {
                this.commandeFournisseur = commandeFournisseur;
                this.loadingCommandeFournisseur = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingCommandeFournisseur = false;
            })
            console.log(e);
        }
    }

    @action editCommandeFournisseur = async (values: Partial<ICommandeFournisseur>) => {
        this.submitting = true;
        
        try {
            await agent.commandeFournisseurAgent.update(values);
            runInAction(() => {
                this.commandeFournisseur = { ...this.commandeFournisseur!, ...values };
                this.submitting = false;
                this.changeButtonState(false);
                toast.success("commande Fournisseur modifié avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action dupliquer = async (id: number) => {
        this.submitting = true;
        try {
            await agent.commandeFournisseurAgent.duplicate(id);
            runInAction(() => {
                this.submitting = false;
                toast.success("commande dupliqué avec succès");
            })
            history.push(`/commandeFournisseurDashboard`);
        } catch (e) {
            toast.error("Problème de duplication!");
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    @action addArticle = async (values: IOrderFournisseurLine) => {
        this.submitting = true;
        try {
            const lines = await agent.commandeFournisseurAgent.addOrderFournisseurLine(values);
            runInAction(() => {
                lines.map((line) => {
                    this.commandeFournisseur!.orderFournisseurLines.push(line);
                    var UnitPriceWithTaxes = line.unitPrice + (line.unitPrice * line.tvaRate / 100);
                    this.commandeFournisseur!.amountWithoutTaxes += (line.quantity * line.unitPrice) - (line.quantity * line.unitPrice * line.remise / 100);
                    this.commandeFournisseur!.amountWithTaxes += (line.quantity * UnitPriceWithTaxes) - (line.quantity * UnitPriceWithTaxes * line.remise / 100);
                })

                this.submitting = false;
                this.loadingInitial = true;
                toast.success("ajout effectué avec succès");
            })
        } catch (e) {
            toast.error("Problème d'ajout commande!");
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    @action deleteOrderFournisseurArticle = async (orderLine: IOrderFournisseurLine) => {
        this.deletingcommandeFournisseurArticle = true;
        try {
            await agent.commandeFournisseurAgent.deleteCommandeFournisseurArticle(orderLine.id);
            runInAction(() => {
                this.commandeFournisseur!.orderFournisseurLines = this.commandeFournisseur!.orderFournisseurLines.filter(a => a.id !== orderLine.id);
                var UnitPriceWithTaxes = orderLine.unitPrice + (orderLine.unitPrice * orderLine.tvaRate / 100);
                this.commandeFournisseur!.amountWithoutTaxes -= (orderLine.quantity * orderLine.unitPrice) - (orderLine.quantity * orderLine.unitPrice * orderLine.remise / 100);
                this.commandeFournisseur!.amountWithTaxes -= (orderLine.quantity * UnitPriceWithTaxes) - (orderLine.quantity * UnitPriceWithTaxes * orderLine.remise / 100);
                if (this.commandeFournisseur!.amountWithoutTaxes == 0)
                    this.commandeFournisseur!.orderFournisseurState = "AttenteValidation";
                this.deletingcommandeFournisseurArticle = false;
                toast.success("ligne de commande supprimée avec succès");
            })
        } catch (e) {
            toast.error("Probléme de suppression!");
            runInAction(() => {
                this.deletingcommandeFournisseurArticle = false;
            })
        }
    }

    @action editOrderFournisseurLine = async (id: number, values: Partial<IOrderFournisseurLine>) => {
        this.submitting = true;
        this.progress = 80;
        try {
            const commandeFournisseur = await agent.commandeFournisseurAgent.updateOrderFournisseurLine(id, values);
            runInAction(() => {
                this.commandeFournisseur = commandeFournisseur;
                this.commandeFournisseurRegestry.set(commandeFournisseur.id, commandeFournisseur);
                this.submitting = false;
                toast.success("Ligne de commande modifié avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action loadOrderFournisseurLine = async (id: number) => {
        this.loadingOrderFournisseurLine = true;
        let commandeFournisseurLine = this.getOrderFournisseurLine(id);
        if (commandeFournisseurLine) {
            this.commandeFournisseurLine = commandeFournisseurLine;
            this.loadingOrderFournisseurLine = false;
            return toJS(commandeFournisseurLine);
        }
        else {
            try {
                commandeFournisseurLine = await agent.commandeFournisseurAgent.detailOrderFournisseurLine(id);
                runInAction(() => {
                    this.commandeFournisseurLine = commandeFournisseurLine;
                    this.commandeFournisseurLineRegestry.set(commandeFournisseurLine.id, commandeFournisseurLine);
                    this.loadingOrderFournisseurLine = false;
                })
                return commandeFournisseurLine;
            } catch (e) {
                runInAction(() => {
                    this.loadingOrderFournisseurLine = false;
                })
                console.log(e);
            }
        }
    }
    @action editAdressesCommandeFournisseur = async (values: Partial<ICommandeFournisseur>) => {
        this.submitting = true;
        try {
            await agent.commandeFournisseurAgent.updateAdresseCommandeFournisseur(values);
            runInAction(() => {
                this.commandeFournisseurRegestry.set(values.id, values);
                this.commandeFournisseur = { ...this.commandeFournisseur!, ...values };
                this.submitting = false;
                this.changeButtonState(false);
                this.loadingInitial = true;
                toast.success("Adresse commande modifiée avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    };

    @action loadFranchiseurByPurchasePrice = async (searchType: string) => {
        this.loadingListFournisseur = true;
        this.ITListFranchiseur = [];
        try {

            var franchiseurs = await agent.franchiseurAgent.listFranchiseurByTarifAchat(searchType);
            runInAction(() => {
                this.ITListFranchiseur = franchiseurs;
                this.loadingListFournisseur = false;
            })
        } catch (e) {
            toast.error("Problem loading !");
            runInAction(() => {
                this.loadingListFournisseur = false;
            })
        }
    }

    @action loadFournisseurByPurchasePrice = async (searchType: string) => {
        this.loadingListFournisseur = true;
        this.ITListFournisseur = [];
        try {
            var fournisseurs = await agent.fournisseurAgent.listFournisseurByTarifAchat(searchType);
            runInAction(() => {
                this.ITListFournisseur = fournisseurs;
                this.loadingListFournisseur = false;
            })
        } catch (e) {
            toast.error("Problem loading !");
            runInAction(() => {
                this.loadingListFournisseur = false;
            })
        }
    }
    @action setEnAttente = async (id: number) => {
        this.loadingOrderState = true;
        try {
            await agent.commandeFournisseurAgent.setPending(id);
            runInAction(() => {
                this.commandeFournisseur!.orderFournisseurState = "AttenteValidation";
                this.loadingOrderState = false;
                toast.success("Commande En Attente");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de mettre la commande en attente!");
            runInAction(() => {
                this.loadingOrderState = false;
            })
        }
    }

    @action setValid = async (id: number) => {
        this.loadingOrderState = true;
        try {
            var ref = await agent.commandeFournisseurAgent.setValid(id);
            runInAction(() => {
                this.commandeFournisseur!.orderFournisseurState = "AttenteReception";
                this.commandeFournisseur!.reference = ref;
                this.loadingOrderState = false;
                toast.success("Commande Valide");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de validation du commande!");
            runInAction(() => {
                this.loadingOrderState = false;
            })
        }
    };

    @action createOrderForAlert = async (values: Partial<ICommandeFournisseurForAlert>) => {
        this.submitting = true;
        try {
            var id = await agent.commandeFournisseurAgent.createOrderAlertStock(values);
            runInAction(() => {
                this.submitting = false;
                toast.success("ajout effectué avec succès");
            })
            history.push(`/detailCommandeFournisseur/${id}`);
        } catch (e) {
            toast.error("Problème d'ajout d'une commande fournisseur!");
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    @action generatePDF = async (id: number) => {
        try {
            await agent.commandeFournisseurAgent.generatePdf(id);
            toast.success("téléchargement avec succès")
        }
        catch (e) {
            console.log(e);
        }
    }
    @action getRelatedObject = async (id: number) => {
        this.submitting = true;
        this.relatedElementsList = [];
        try {
            var result = await agent.factureAgent.relatedObject(id, "CommandeFournisseur");
            runInAction(() => {
                result.forEach((item: IListRelatedElements) => {
                    if (["Expedition", "Devis", "Preparation", "Facture-Avoir", "Facture","Facture_Acompte"].includes(item.typeObject) == false) {
                            let objt: IListRelatedElements = {
                                idObject: item.idObject,
                                typeObject: item.typeObject,
                                referenceObject: item.referenceObject,
                                date: item.date,
                                ht: item.ht,
                                ttc: item.ttc,
                                name: item.name
                            };
                            this.relatedElementsList.push(objt);
                    }
                })
                this.submitting = false;
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Aucun objet liés");
        }
    }

    @action updateDeliveryDates = async (values: Partial<ICommandeFournisseur>) => {
        this.submitting = true;
        try {
            var dates = await agent.commandeFournisseurAgent.updateDeliveryDates(values);
            runInAction(() => {
                this.commandeFournisseur!.orderDeliveries = dates;
                this.loadingInitial = true;
                toast.success("Les dates de livraison sont modifiées avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action deleteOrder = async (id: number) => {
        this.deletingOrder = true;
        try {
            await agent.commandeFournisseurAgent.deleteOrder(id);
            runInAction(() => {
                this.deletingOrder = false;
                this.commandesFournisseur = this.commandesFournisseur.filter(a => a.id !== id);
                toast.success("suppression éffectuée avec succès");
            })
        } catch (e) {
            toast.error("Problème de suppresion !");
            runInAction(() => {
                this.deletingOrder = false;
            })
        }
    }

    @action loadRelatedElements = async (id: number, type: string) => {
        this.loadingRelatedElements = true;
        try {
            var relatedElements = await agent.commandeFournisseurAgent.loadRelatedElements(id,type);
            runInAction(() => {
                this.relatedElements = relatedElements;
                this.loadingRelatedElements = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingRelatedElements = false;
            })
        }
    }

    @action loadSupplierOrders = async (id: number) => {
        this.loadingCommandeFournisseur = true;
        try {
            const commandes = await agent.commandeFournisseurAgent.getSupplierOrders(id);
            runInAction(() => {

                this.supplierOrders = commandes;
                
                this.loadingCommandeFournisseur = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingCommandeFournisseur = false;
            })
            console.log(e);
        }
    }
}