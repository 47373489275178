import React, { useContext, useState } from 'react';
import { Table, Header, Label, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../app/store/baseStore';
import NoResult from '../commande/commandeDashboard/ErrorNoResult';
import { FaFileInvoice } from 'react-icons/fa'
import AlertStockModal from './AlertStockModal';
import AlertStockMultipleSearch from './AlertStockMultipleSearch';


const ListAlertStock: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { INotificationList } = baseStore.articleStockStore;
    const { loadTarifFournisseurList } = baseStore.tarifFournisseurStore;
    const { loadArticle } = baseStore.articleStore;
    const [ open, setOpen ] = useState(false)

    if (INotificationList.length < 1) return < NoResult name="Alert" />
    else
        return (
            <>
                <AlertStockMultipleSearch />
                <AlertStockModal open={open} setOpen={setOpen}/>
                <Table compact='very' style={{ marginTop: '2em' }} >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Numéro</Table.HeaderCell>
                            <Table.HeaderCell>Code Article</Table.HeaderCell>
                            <Table.HeaderCell>Libelle</Table.HeaderCell>
                            <Table.HeaderCell>Seuil</Table.HeaderCell>
                            <Table.HeaderCell>Quantite</Table.HeaderCell>
                            <Table.HeaderCell>Entrepots</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {INotificationList && INotificationList.map((stock: any) => (
                            <Table.Row key={stock.articleStockId}>
                                <Table.Cell>
                                    <Header size='huge'><Label style={{ marginLeft: '2em' }}>N°{stock.articleStockId}</Label></Header>
                                </Table.Cell>
                                <Table.Cell>{stock.refArticle}</Table.Cell>
                                <Table.Cell>{stock.libelleArticle}</Table.Cell>
                                <Table.Cell>{stock.seuilArticle}</Table.Cell>
                                <Table.Cell>{stock.quantite}</Table.Cell>
                                <Table.Cell>{stock.libelle}</Table.Cell>
                                <Table.Cell >
                                    <Button.Group fluid widths={1}>
                                        <Button
                                            color="yellow"
                                            cursor="pointer"
                                            content='Commander'
                                            icon={<FaFileInvoice style={{ float: 'right' }} />}
                                            onClick={() => { loadArticle(stock.article); loadTarifFournisseurList(stock.article); setOpen(true); }}
                                        />
                                    </Button.Group>

                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </>
        );
}
export default observer(ListAlertStock);