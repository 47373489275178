import { useContext, useEffect, useState } from 'react';
import { Tab, Header, Button, Grid, Table, Message, Form } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import MouvementStockForm from './AddMouvementStockModal';
import { history } from '../../..';
import { IArticleStock } from '../../../app/models/IArticleStock';
import InputNumber from '../../../app/common/form/inputNumber';
import { convertStringToArray } from '../../../app/common/util/util';
import { IAttributeItems, IProductAttributes } from '../../../app/models/IProductAttributes';
import Select from 'react-select';
import { IEntrepot } from '../../../app/models/IEntrepot';
import { Id, toast } from 'react-toastify';

const ArticleStockDescription = () => {
    const [isVisibleBtnCorrige, setIsVisibleBtnCorrige] = useState(false);
    const baseStore = useContext(BaseStoreContext)
    const { stocks, edit, updating } = baseStore.articleStockStore;
    const { article } = baseStore.articleStore;
    const [editForm, setEditForm] = useState(false);
    const [editTarget, setEditTarget] = useState<number>(0);
    const [quantite, setQuantite] = useState<number>(0);
    const [quantiteVirt, setQuantiteVirt] = useState<number>(0);
    const [attributes, setAttributes] = useState<IAttributeItems[]>([]);
    const { loadEntrepots, entrepots, loadingInitial } = baseStore.entrepotStore;
    const [entrepot, setEntrepot] = useState<IEntrepot>(entrepots[entrepots.findIndex(x => x.isMain == true)]);

    const handleChangeAttributes = (values: string, productAttribute: IProductAttributes) => {
        var index = attributes.findIndex(x => x.id == productAttribute.id);
        var tab = attributes;
        if (index == -1) {
            tab.push({ id: productAttribute.id, item: values, isMain: false })
        } else {
            tab[index].item = values;
        }
        setAttributes(tab);
    }

    const HandleUpdateStock = (id: number) => {
        if (attributes.length != article?.productAttributes.length) {
            toast.warning("Vous devez séléctionner les attributs")
        } else {
            let newStock = {
                articleStockId: id,
                entrepots: entrepot?.entrepotsId! ?? entrepots[entrepots.findIndex(x => x.isMain == true)]?.entrepotsId!,
                article: article?.productId,
                quantite: quantite,
                quantiteVirt: quantiteVirt,
                productAttributes: attributes
            };
            edit(newStock).then(
                () => {
                    setEditForm(false);
                    setEditTarget(0)
                }
            )
        }

    }


    useEffect(() => {
        loadEntrepots();
        baseStore.articleStockStore.editStock ? setIsVisibleBtnCorrige(false) : setIsVisibleBtnCorrige(true);
    }, [])

    return (
        <Tab.Pane>
            <Grid>
                <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                    <Header floated='left' icon='dolly flatbed' content='Stock(s)' />
                    {article?.modeAppro != "VenteDirecte" &&
                        <>
                            <Button
                                floated='right'
                                content={baseStore.mouvementStockStore.addStock ? 'Annuler' : 'Corriger le stock'}
                                color="purple"
                                icon='arrow alternate circle left outline'
                                style={{ display: isVisibleBtnCorrige ? 'block' : 'none' }}
                                basic
                                onClick={() => {
                                    baseStore.mouvementStockStore.changeButtonStateAdd(!baseStore.mouvementStockStore.addStock)
                                }}
                            />
                            <Button
                                floated='right'
                                content={'Mouvement stock'}
                                color="grey"
                                basic
                                onClick={() => {
                                    var predicate = new Map()
                                    predicate.set("entrepots", '');
                                    predicate.set("article", article?.reference);
                                    predicate.set("dateCreation", '');
                                    predicate.set("dateCreationTo", '');
                                    baseStore.mouvementStockStore.setPredicate('search', predicate); history.push('/mouvementStockDashboard');
                                }}
                            />
                        </>
                    }
                    {baseStore.mouvementStockStore.addStock &&
                        <div>
                            <MouvementStockForm />
                        </div>
                    }
                </Grid.Column>
                {article?.modeAppro != "VenteDirecte" ?
                    (
                        <Grid.Column width={16}>
                            <Table compact='very' style={{ marginTop: '1em' }} sortable >
                                <Table.Header >
                                    <Table.Row>
                                        <Table.HeaderCell>Entrepot</Table.HeaderCell>
                                        <Table.HeaderCell>Attributs</Table.HeaderCell>
                                        <Table.HeaderCell>Qtt.</Table.HeaderCell>
                                        <Table.HeaderCell>Qtt. Virt.</Table.HeaderCell>
                                        <Table.HeaderCell></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {stocks && stocks.map((stock: IArticleStock) => (
                                        <Table.Row key={stock.articleStockId}>
                                            <>
                                                {editForm && editTarget == stock.articleStockId ? (
                                                    <>
                                                        <Table.Cell style={{ width: 200 }}>
                                                            <Select
                                                                name='entrepots'
                                                                placeholder={entrepots[entrepots.findIndex(x => x.isMain == true)]?.libelle ?? "Séléctionnez un entrepôt.."}
                                                                isLoading={loadingInitial}
                                                                options={entrepots}
                                                                getOptionLabel={e => e!.libelle}
                                                                getOptionValue={e => e!.entrepotsId.toString()}
                                                                onChange={e => { setEntrepot(e!); }}
                                                            />
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {article?.productAttributes.map(productAttribute => (
                                                                <Select
                                                                    name='attribute'
                                                                    placeholder={"Attribut..."}
                                                                    options={convertStringToArray(productAttribute.possibleValues)}
                                                                    getOptionLabel={e => e!.text}
                                                                    getOptionValue={e => e!.value}
                                                                    onChange={e => { handleChangeAttributes(e!.value, productAttribute) }}
                                                                />
                                                            ))}

                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <Form.Input name='quantite' type='number' style={{ width: 150, display: "block" }} component={InputNumber} placeholder='quantiteLivree' defaultValue={stock.quantite} onChange={(e) => setQuantite(parseInt(e.target.value))} />
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <Form.Input name='quantiteVirt' type='number' style={{ width: 150, display: "block" }} component={InputNumber} placeholder='quantiteLivree' defaultValue={stock.quantiteVirt} onChange={(e) => setQuantiteVirt(parseInt(e.target.value))} />
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <div style={{ display: "flex" }}>
                                                                <Button
                                                                    color="green"
                                                                    icon='check'
                                                                    size="small"
                                                                    basic
                                                                    loading={updating && stock.articleStockId == editTarget}
                                                                    onClick={(e) => {
                                                                        HandleUpdateStock(stock.articleStockId)
                                                                    }}
                                                                />
                                                                <Button
                                                                    color="red"
                                                                    icon='cancel'
                                                                    size="small"
                                                                    basic
                                                                    onClick={(e) => {
                                                                        setEditForm(false);
                                                                        setEditTarget(0)
                                                                    }}
                                                                />
                                                            </div>
                                                        </Table.Cell>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Table.Cell>{stock.libelle}</Table.Cell>
                                                        <Table.Cell>{stock.attribute}</Table.Cell>
                                                        <Table.Cell>{stock.quantite}</Table.Cell>
                                                        <Table.Cell>{stock.quantiteVirt}</Table.Cell>
                                                        <Table.Cell>
                                                            <Button
                                                                color="grey"
                                                                icon='pencil'
                                                                size="small"
                                                                basic
                                                                loading={updating && stock.articleStockId == editTarget}
                                                                onClick={(e) => {
                                                                    setEditForm(true);
                                                                    setAttributes([]);
                                                                    setQuantite(stock.quantite);
                                                                    setQuantiteVirt(stock.quantiteVirt);
                                                                    setEditTarget(stock.articleStockId)
                                                                }}
                                                            />
                                                        </Table.Cell>
                                                    </>
                                                )}
                                            </>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                    ) : (
                        <Message
                            style={{ padding: '20px 40px', margin: '40px 40px' }}
                            error
                            list={[
                                "Le mode appro de l'article " + article?.label + " n'est pas en stock",
                                "Veuillez changer le mode appro pour afficher cet option",
                            ]}
                        />
                    )}
            </Grid>
        </Tab.Pane>
    );
};

export default observer(ArticleStockDescription);
