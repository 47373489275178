import { BaseStore } from "./baseStore";
import { observable, action, runInAction } from 'mobx';
import agent from '../agent/agent';
import { toast } from "react-toastify";
import { ISupport, ISupportList } from "../models/ISupport";
import { setSupportProps } from "../common/util/util";

export default class ImpressionStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
    }
    @observable loadingSupportAction = false;
    @observable loadingSupport = false;
    @observable loadingInitial = false;
    @observable submittingSupport = false;
    @observable deletingSupport = false;
    @observable support: ISupport | null = null;
    @observable supports: ISupport[] = [];
    @observable supportRegestry = new Map();
    @observable ITListSupport: ISupportList[] = [];
    @observable editSupport: boolean = false;
    @observable addMode: boolean = false;
    @observable progress: number = 50;

    @action changeButtonStateEdit = async (newState: boolean) => {
        this.editSupport = newState;
    }

    @action changeButtonStateAdd = async (newState: boolean) => {
        this.addMode = newState;
    }

    @action loadSupports = async () => {
        this.loadingInitial = true;
        this.ITListSupport = [];
        try {
            const supports = await agent.supportAgent.list();
            runInAction(() => {
                this.supports = supports;
                let aucunSupport: ISupportList = {
                    label: "Aucun support",
                    value: ""
                }
                this.ITListSupport.push(aucunSupport)
                supports.forEach((support) => {
                    setSupportProps(support);
                    this.supportRegestry.set(support.supportId, support);
                    let supportt: ISupportList = {
                        label: support.libelle,
                        value: support.libelle
                    }
                    this.ITListSupport.push(supportt)
                })
                this.loadingInitial = false;
            })
        } catch (e) {
            toast.error("Problem loading support!");
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }

    @action loadSupport = async (id: string) => {

        this.loadingSupport = true;
        let support = this.getSupport(id);
        if (support) {
            this.support = support;
            this.loadingSupport = false;
            return support;
        }
        else {
            try {
                support = await agent.supportAgent.details(id);
                runInAction(() => {
                    this.supportRegestry.set(support.supportId, support);
                    this.support = support;
                    this.loadingSupport = false;
                })
                return support;
            } catch (e) {
                runInAction(() => {
                    this.loadingSupport = false;
                })
                console.log(e);
            }
        }
    }

    getSupport = (id: string) => {
        return this.supportRegestry.get(id);
    }

    @action create = async (values: ISupport) => {
        this.submittingSupport = true;
        try {
            await agent.supportAgent.create(values);
            runInAction(() => {
                this.supportRegestry.set(values.supportId, values)
                this.support = values;
                const newOption: ISupportList = {
                    label: values.libelle,
                    value: values.libelle
                }
                this.ITListSupport.push(newOption);
                this.supports.push(this.support);
                toast.success("ajout éffectué avec succès");
                this.submittingSupport = false;
            })

        } catch (e) {
            runInAction(() => {
                this.submittingSupport = false;
                console.log(e);
            })
        }
    }

    @action edit = async (values: Partial<ISupport>) => {
        this.submittingSupport = true;
        this.progress = 80;
        try {
            await agent.supportAgent.update(values);
            let support = await agent.supportAgent.details(values.supportId!);
            runInAction(() => {
                this.supports = this.supports.filter(a => a.supportId != values.supportId);
                this.supports.push(support);
                toast.success("Modification éffectuée avec succès");
                this.submittingSupport = false;
            })

        } catch (e) {
            runInAction(() => {
                this.submittingSupport = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action deleteSupport = async (id: string) => {
        this.loadingSupportAction = true;
        var mode = await agent.supportAgent.details(id);
        try {
            await agent.supportAgent.delete(id);
            runInAction(() => {
                this.loadingSupportAction = false;
                this.supports = this.supports.filter(a => a.supportId !== id);
                toast.success("suppression éffectuée avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de suppréssion d'option!");
            runInAction(() => {
                this.loadingSupportAction = false;
            })
        }

    }
}