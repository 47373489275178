import React, { useContext, useState } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button, Grid } from 'semantic-ui-react';
import TextInput from '../../../app/common/form/inputText';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import AsyncSelect from "react-select/async";
import { IAdresse } from '../../../app/models/IAdresse';

interface IProps {
    match : number
}

const AddFranchiseurAdresse: React.FC<IProps> = ({  match }) => {
    const baseStore = useContext(BaseStoreContext);
    const { create } = baseStore.adresseStore;
    const [codePostal, setCodePostal] = useState(null);
    const [city, setCity] = useState();
    const [country, setCountry] = useState<any>("FR");

    const handleChangedeliveryPostalCode = (value: any) => {
        setCodePostal(value.codePostal);
        setCity(value.city);
    }
    const handleChangePostalCode = (e: any, result: any) => {
        setCodePostal(result.value);
    }
    const handleChangeCity = (e: any, result: any) => {
        setCity(result.value);
    }
    const handleChangeCountry = (value: any) => {
        setCountry(value.iso);
    }
    const handleFinalFormSubmit = (values: any) => {
        let newAdresse: IAdresse = {
            ...values,
            company: match,
            postalCode: codePostal,
            city: city,
            country: country,
        };
        create(newAdresse);
        baseStore.franchiseurStore.changeButtonState(false)
    }
    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            render={({ handleSubmit, invalid, pristine, submitting }) => (
                <Form onSubmit={handleSubmit} error>
                    <Grid columns={2} >
                        <Grid.Column>
                            <h5 >Pays</h5>
                            <AsyncSelect
                                name="form-field-name"
                                noOptionsMessage={() => "Code introuvable"}
                                cacheOptions
                                defaultOptions
                                placeholder={country}
                                value={country}
                                getOptionLabel={e => e!.pays + '(' + e!.iso + ')'}
                                getOptionValue={e => e!.iso}
                                loadOptions={baseStore.countryCodeStore.getCountryCodes}
                                onChange={handleChangeCountry}

                            />
                        </Grid.Column>
                        {country == "FR" ?
                            <Grid.Column>
                            <h5>Code postal </h5>
                            <AsyncSelect
                                name="form-field-name"
                                noOptionsMessage={() => "Code introuvable"}
                                cacheOptions
                                defaultOptions
                                    placeholder={codePostal ?? "Code postal" }
                                value={codePostal}
                                getOptionLabel={e => e!.city + '(' + e!.codePostal + ')'}
                                getOptionValue={e => e!.codePostal}
                                loadOptions={baseStore.cityCodeStore.getCityCodes}
                                onChange={handleChangedeliveryPostalCode}
                            />
                            </Grid.Column> :
                            <Grid.Column>
                                <h5 >Code Postal</h5>
                                <Form.Input name='postalCode' component={TextInput} placeholder="Code postal" type='text' onChange={handleChangePostalCode}/>
                            </Grid.Column>
                            }
                        
                        <Grid.Column>
                            <label>Ville </label>
                            <Form.Input name='city' value={city} placeholder="Ville " onChange={handleChangeCity} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>Adresse </h5>
                            <Field name='address' placeholder="Adresse" component={TextInput} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>Adresse Complement </h5>
                            <Field name='additionalAddress' placeholder="Adresse complement" component={TextInput} />
                        </Grid.Column>                
                        <Grid.Column>
                            <h5>Societé </h5>
                            <Field name='companyName' placeholder="Scieté" component={TextInput} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>Téléphone </h5>
                            <Field name='phone' component={TextInput} placeholder="Téléphone" type='tel' />
                        </Grid.Column>
                        <Grid.Column>
                            <h5 >Email</h5>
                            <Field name='email' component={TextInput} placeholder="Email"  />
                        </Grid.Column>
                    </Grid>
                    <div style={{ marginTop: '30px' }}>
                        <Button.Group floated="right">
                            <Button disabled={submitting} onClick={() => { baseStore.franchiseurStore.changeButtonState(!baseStore.franchiseurStore.editFranchiseurMode) }} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive disabled={submitting || invalid || pristine} loading={submitting} floated='right'  type='submit'>Confirmer</Button>
                        </Button.Group>
                    </div>
                </Form >
            )}
        />
    );
};

export default observer(AddFranchiseurAdresse);