import React, { useContext, useEffect, useState } from 'react';
import { Form, Item, Table, Button, Header, Progress } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import Loading from '../../../app/layout/Loading';
import ModePaiementForm from './AddModePaiementModal';
import { observer } from 'mobx-react-lite';
import TextInput from '../../../app/common/form/inputText';
import DeleteModePaiementModal from './DeleteModePaiementModal';
import Breadcrumbs from '../../dashboard/breadcrumbs';

const ListModePaiement: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { loadModePaiements, loadingInitial, edit, modePaiements, loadingModePaiementAction, progress, submitting } = baseStore.modePaiementStore;
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    const [editTarget, setEditTarget] = useState<string | undefined>(undefined);
    const [editForm, setEditForm] = useState(false);
    const [label, setLabel] = useState<string | undefined>(undefined);
    const [desc, setDesc] = useState<string | undefined>(undefined);
    const [modePaiement, setModePaiement] = useState<string | undefined>(undefined);
    const { openModal } = baseStore.modalStore;

    const handleFinalFormSubmit = () => {

        let newMode = {
            label: label,
            description: desc,
            paiementId: editTarget,
            modePaiement: modePaiement,
        };
        edit(newMode);
        setEditForm(false)
    }
    useEffect(() => {
        loadModePaiements();
    }, []);

    if (loadingInitial) return <Loading content="Chargement des modes de paiements ..." />

    return (
        <div>
            <Breadcrumbs
                    items={[
                    { key: 0,name: "Paramétres", root: "" },
                    { key: 1,name: "Mode paiement", root: "/dashboard" },
                    ]}
            />
            <div style={{ margin: '2rem 1rem' }}>
            <Item.Group >
                <Item>
                    <Item.Content>
                        <Header as='h2' icon='address card outline'></Header>
                        <Header as='h2'>{'Mode de paiement'}</Header>
                        <Button
                            floated="right"
                            content={baseStore.modePaiementStore.addMode ? 'Annuler' : 'Ajouter un mode de paiement'}
                            color="orange"
                            icon='arrow alternate circle left outline'
                            basic
                            onClick={() => { baseStore.modePaiementStore.changeButtonStateAdd(!baseStore.modePaiementStore.addMode) }}
                        />
                    </Item.Content>
                </Item>
            </Item.Group>
            {baseStore.modePaiementStore.addMode &&
                <div>
                    <ModePaiementForm />
                    <br />
                </div>
            }
            {submitting &&
                <Progress color='orange'  percent={progress} size='tiny'/>
            }
            <Table loading={false} celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Label</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        <Table.HeaderCell>Crée par</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {modePaiements && modePaiements.map((modePaiement: any) => (
                        <Table.Row key={modePaiement.paiementId}>
                            {editForm && editTarget === modePaiement.paiementId ? (
                                <>
                                    <Table.Cell selectable>
                                        <Form.Input name='label' component={TextInput} placeholder='Label' defaultValue={modePaiement.label} style={{ width: '100%', padding: '1%' }} onChange={event => setLabel(event.target.value)} />
                                    </Table.Cell>
                                    <Table.Cell selectable>
                                        <Form.Input name='description' component={TextInput} placeholder='Description' defaultValue={modePaiement.description} style={{ width: '100%', padding: '1%' }} onChange={event => setDesc(event.target.value)} />
                                    </Table.Cell>
                                    <Table.Cell >{modePaiement.owner}</Table.Cell>
                                    <Table.Cell >
                                        <Button.Group fluid widths={2}>
                                            <Button
                                                type='button'
                                                onClick={() => {
                                                    setEditForm(false);
                                                }}>
                                                Annuler
                                            </Button>
                                            <Button.Or />
                                            <Button
                                                name={modePaiement.paiementId}
                                                positive
                                                type='submit'
                                                onClick={(e) => {
                                                    handleFinalFormSubmit()
                                                    setEditTarget(e.currentTarget.name)
                                                }}
                                            >Confirmer</Button>
                                        </Button.Group>
                                    </Table.Cell>
                                </>
                            ) : (
                                    <>
                                        <Table.Cell>{modePaiement.label}</Table.Cell>
                                        <Table.Cell>{modePaiement.description}</Table.Cell>
                                        <Table.Cell >{modePaiement.owner}</Table.Cell>
                                        <Table.Cell >
                                            <Button.Group fluid widths={2}>
                                                <Button
                                                    onClick={(e) => {
                                                        setEditForm(true);
                                                        setEditTarget(e.currentTarget.name)
                                                        setLabel(modePaiement.label)
                                                        setDesc(modePaiement.description)
                                                        setModePaiement(modePaiement.owner)
                                                    }}
                                                    color="blue"
                                                    icon='edit outline'
                                                    style={{ marginRight: '3%' }}
                                                    name={modePaiement.paiementId}
                                                />
                                                <Button
                                                    name={modePaiement.paiementId}
                                                    basic
                                                    color='red'
                                                    icon='trash'
                                                    onClick={(e) => {
                                                        openModal(<DeleteModePaiementModal modePaiement={modePaiement} />)
                                                        setDeleteTarget(e.currentTarget.name)
                                                    }}
                                                    loading={loadingModePaiementAction && deleteTarget === modePaiement.paiementId}
                                                />
                                            </Button.Group>
                                        </Table.Cell>
                                    </>
                                )}
                        </Table.Row>
                    ))}
                </Table.Body>
                </Table>
                </div>
        </div>
    );
}
export default observer(ListModePaiement);