import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Form, Button, Input, Grid } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import { IProductFournisseur } from '../../../app/models/IArticle';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { ITarifFournisseur } from '../../../app/models/ITarifFournisseur';
import { IFactureFournisseur, IFactureFournisseurLine } from '../../../app/models/IFactureFournisseur';
import CreatableSelect from 'react-select/creatable';

interface IProps {
    Line: IFactureFournisseurLine;
    facture: IFactureFournisseur
    setModalOpen: Dispatch<SetStateAction<boolean>>;
}

const EditFactureArticle: React.FC<IProps> = ({ Line, setModalOpen, facture }) => {

    const baseStore = useContext(BaseStoreContext);
    const { loadArticlesFournisseur, loadingArticle, articlesFournisseur, article, loadArticle } = baseStore.articleStore;
    const { editInvoiceLine, submitting } = baseStore.factureFournisseurStore;
    const { loadingInitial, loadTarifFournisseurList, tarifFournisseurs, loadTarifFournisseurByTypeAndQuantite } = baseStore.tarifFournisseurStore;
    const [minQuantite, setMinQuantite] = useState<number>(Line!.quantity);
    const [validArticle, setValidArticle] = useState(true);
    const [finalArticle, setArticle] = useState<IProductFournisseur>();
    const [unitPrice, setUnitPrice] = useState<number>(Line.unitPrice);
    const [unitPriceWithTaxes, setUnitPriceWithTaxes] = useState<number>(Line.unitPrice + (Line.unitPrice * Line.tvaRate / 100));
    const [totalPrice, setTotalPrice] = useState<number>(Line.unitPrice * Line.quantity - Line.unitPrice * Line.quantity * Line.discount / 100);
    const totalPriceTaxes = (Line.unitPrice * Line.quantity + (Line.unitPrice * Line.tvaRate / 100) * Line.quantity);
    const [totalPriceWithTaxes, setTotalPriceWithTaxes] = useState<number>(totalPriceTaxes - totalPriceTaxes * Line.discount / 100);
    const [quantity, setQuantity] = useState<number>(Line!.quantity);
    const [disabled, setDisabled] = useState(true);
    const [tarif, setTarif] = useState<ITarifFournisseur | null>();
    const [remise, setRemise] = useState<number>(Line!.discount);
    const [comment, setComment] = useState<string>(Line!.comment);

    useEffect(() => {
        loadArticle(Line.product.productId)
        loadTarifFournisseurList(Line.product.productId)
            .then((data: any) => {
                var tarifFournisseur = facture.fournisseur ?
                    data!.filter((x: ITarifFournisseur) => x.fournisseur == facture.fournisseur.fournisseurId && x.quantite <= Line.quantity)[data!.filter((x: ITarifFournisseur) => x.fournisseur == facture.fournisseur.fournisseurId && x.quantite <= Line.quantity).length - 1] :
                    data!.filter((x: ITarifFournisseur) => x.franchiseur == facture.company.id && x.quantite == Line.quantity)[data!.filter((x: ITarifFournisseur) => x.franchiseur == facture.company.id && x.quantite == Line.quantity).length - 1];
                if (tarifFournisseur == undefined)
                    tarifFournisseur = facture.fournisseur ?
                        data!.filter((x: any) => x.fournisseur == facture.fournisseur.fournisseurId)[0] :
                        data!.filter((x: any) => x.franchiseur == facture.company.id)[0];

                if (tarifFournisseur == undefined) {
                    var tarif: ITarifFournisseur = {
                        id: 0,
                        article: Line!.product.reference,
                        companyName: facture!.company?.companyName,
                        fournisseur: facture!.fournisseur?.fournisseurId,
                        dateCreation: new Date(),
                        fabricant: facture!.fournisseur?.fabricant,
                        productReference: Line!.product.reference,
                        prix: Line.unitPrice,
                        quantite: Line.quantity,
                        franchiseur: facture!.company?.id,
                        type: facture.company ? 'Franchiseur' : 'Fournisseur',
                        lastPrice:false
                    };
                    tarifFournisseur = tarif;
                }
                if (tarifFournisseur.quantite != Line.quantity)
                    tarifFournisseur.quantite = Line.quantity;
                setTarif(tarifFournisseur);
                setArticle(tarifFournisseur!.product);
                setDisabled(false);
                setValidArticle(false);
            });
        facture.fournisseur ? loadArticlesFournisseur(facture.fournisseur.fournisseurId, 'Fournisseur') : loadArticlesFournisseur(facture.company.id, 'Franchiseur');
    }, [])

    const handleSetArticle = (e: IProductFournisseur) => {
        setArticle(e);
        setValidArticle(true);
        loadTarifFournisseurList(e.productId)
            .then((data: ITarifFournisseur[] | undefined) => {
                const tarifFournisseur = data!.filter(x => x.lastPrice == true)[0] ?? data![0];
                if (tarifFournisseur == undefined) {
                    setTarif(null)
                    setMinQuantite(0);
                    setQuantity(0);
                    setUnitPrice(0);
                    setUnitPriceWithTaxes(0);
                    setTotalPrice(0);
                    setTotalPriceWithTaxes(0);
                }
                else {
                    setTarif(tarifFournisseur)
                    setMinQuantite(tarifFournisseur.quantite);
                    setQuantity(tarifFournisseur.quantite);
                    setUnitPrice(tarifFournisseur.prix);
                    setUnitPriceWithTaxes(tarifFournisseur.prix + (tarifFournisseur.prix * e.tvaRate / 100));
                    setTotalPrice(tarifFournisseur.prix * tarifFournisseur.quantite);
                    setTotalPriceWithTaxes(tarifFournisseur.prix * tarifFournisseur.quantite + (tarifFournisseur.prix * e.tvaRate / 100) * tarifFournisseur.quantite);
                }
                setDisabled(false);
                setRemise(0);

                setValidArticle(false);
            });
    }

    const handleSetInformation = (e: any) => {
        var value = e.target.value == "" ? 0 : parseFloat(e.target.value);
        const tauxTva = article!.tvaRate!;
        const UnitPriceWithTaxes = value + value * tauxTva / 100;
        setUnitPrice(parseFloat(e.target.value));
        setUnitPriceWithTaxes(UnitPriceWithTaxes);
        setTotalPrice(value * quantity! - (value * quantity! * remise / 100));
        setTotalPriceWithTaxes(UnitPriceWithTaxes * quantity! - (UnitPriceWithTaxes * quantity! * remise / 100));
    }

    const handleSetPrixByTypeandQuantite = (e: any) => {
        setValidArticle(true);
        const parsedQuantite = parseFloat(e.quantite);
        setTarif(e);
        if (parsedQuantite > 0) {
            setQuantity(parsedQuantite);
            loadTarifFournisseurByTypeAndQuantite(finalArticle?.productId ?? article!.productId, facture.fournisseur ? 'Fournisseur' : 'Franchiseur', parsedQuantite)
                .then(data => {
                    const total = parsedQuantite * data!;
                    const tauxTva = finalArticle != null ? finalArticle!.tvaRate : article!.tvaRate;
                    const taxePrice = (data! * (100 + tauxTva)) / 100;
                    const totalTaxe = (total * (100 + tauxTva)) / 100;
                    setUnitPrice(data!);
                    setUnitPriceWithTaxes(taxePrice);
                    setTotalPrice(total - total * remise / 100);
                    setTotalPriceWithTaxes(totalTaxe - totalTaxe * remise / 100);
                    setValidArticle(false);
                });
        } else {
            toast.error("Quantité minimal est " + 1);
            setQuantity(minQuantite);
            setValidArticle(false);
        }

    }

    const handleChangeQuantity = (inputValue: string) => {
        setTimeout(() => {

            const newOption: ITarifFournisseur = {
                quantite: parseInt(inputValue),
                fournisseur: facture!.fournisseur?.fournisseurId,
                franchiseur: facture!.company?.id,
                dateCreation: tarif?.dateCreation!,
                prix: tarif?.prix ?? 0,
                article: article!.reference,
                companyName: facture!.company?.companyName,
                fabricant: facture!.fournisseur?.fabricant,
                productReference: article!.reference,
                type: facture!.fournisseur ? '' : '',
                id: tarif?.id ?? 0,
                lastPrice:false
            }
            setTarif(newOption);
            handleSetPrixByTypeandQuantite(newOption);
        }, 1000);
    }

    const handleChangePriceRemise = (e: any) => {
        const Remise = e.target.value;
        setRemise(Remise);
        setTotalPrice(unitPrice * quantity! - (unitPrice * quantity! * Remise / 100));
        setTotalPriceWithTaxes(unitPriceWithTaxes * quantity! - (unitPriceWithTaxes * quantity! * Remise / 100));
    }
    const handleFinalFormSubmit = (values: any) => {
        let newLine = {
            ...values,
            quantity: quantity,
            articleId: finalArticle != null ? finalArticle?.productId : article?.productId,
            unitPrice: unitPrice,
            unitPriceWithTaxes: (unitPriceWithTaxes),
            tvaRate: finalArticle != null ? finalArticle?.tvaRate : article!.tvaRate,
            codeArticle: finalArticle != null ? finalArticle?.reference : article?.reference,
            article: finalArticle != null ? finalArticle?.label : article?.label,
            id: Line.id,
            weight: finalArticle != null ? finalArticle?.weight : article?.weight,
            discount: remise,
            comment: comment,
        };
        editInvoiceLine(Line.id, newLine);
        setModalOpen(false);
    }

    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            render={({ handleSubmit, invalid }) => (
                <Form onSubmit={handleSubmit} error >
                    <Form.Group unstackable widths={2} >

                        <div className="field">
                            <h5>Article</h5>
                            <Select isSearchable name="article" isLoading={loadingArticle} options={articlesFournisseur}
                                getOptionLabel={e => e!.reference + ' - ' + e!.label}
                                getOptionValue={e => e!.productId.toString()}
                                placeholder={finalArticle != null ? finalArticle?.label : article?.label}
                                onChange={(e) => handleSetArticle(e!)}
                            />
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={2}>
                        <div className="field">
                            <label>Quantité *</label>
                            {!validArticle && (
                                <CreatableSelect name="quantiy"
                                    isDisabled={disabled}
                                    isLoading={loadingInitial}
                                    options={tarifFournisseurs.filter(x => facture.fournisseur ? x.fournisseur == facture.fournisseur.fournisseurId : x.franchiseur == facture.company.id)}
                                    value={tarif}
                                    getOptionLabel={e => e?.quantite ? e?.quantite.toString() : `Affecter cette quantité`}
                                    getOptionValue={e => e?.quantite?.toString()}
                                    placeholder='Quantité'
                                    onChange={e => handleSetPrixByTypeandQuantite(e)}
                                    onCreateOption={handleChangeQuantity}
                                    formatCreateLabel={() => `Affecter cette quantité`}
                                />
                            )}
                        </div>
                        <div className="field">
                            <label style={{ marginTop: 32 }} color="grey">TVA : {finalArticle != null ? finalArticle!.tvaRate : article?.tvaRate}</label>
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={2}>
                        <div className="field">
                            <label>Prix unitaire</label>
                            <Input name='unitPrice' disabled={validArticle} placeholder="Prix unitaire" type='number' step="0.0001" min='0' value={unitPrice.toFixed(4)} onChange={handleSetInformation} />
                        </div>
                        <div className="field">
                            <label>Prix unitaire avec taxes</label>
                            <input name='unitPriceTaxes' disabled={true} value={unitPriceWithTaxes.toFixed(4)} />
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={2}>
                        <div className="field">
                            <label>Prix Total</label>
                            <input name='totalPrice' disabled={true} placeholder="Prix Total" value={totalPrice.toFixed(2)} />
                        </div>
                        <div className="field">
                            <label>Prix Total avec taxes</label>
                            <input name='totalPriceTaxes' disabled={true} value={totalPriceWithTaxes.toFixed(2)} />
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={3}>
                        <div className="field">
                            <label>Remise</label>
                            <input name='remise' min={0} max={100} value={remise} type="number" onChange={(e) => handleChangePriceRemise(e)} />
                        </div>
                        <Form.Field width={16}>
                            <label>Commentaire</label>
                            <input name='comment' type="text" value={comment} onChange={(e) => setComment(e.target.value)} />
                        </Form.Field >
                    </Form.Group>
                    <Grid unstackable>
                        {article?.paperFormat &&
                            <Grid.Column width={8}>
                                <div className="field">
                                    <label color="grey">Format: <span style={{ fontSize: 14, color: 'grey' }}>{article?.paperFormat}</span></label>
                                </div>
                            </Grid.Column>
                        }
                        <Grid.Column width={8}>
                            <div className="field">
                                <label color="grey">Poids unitaire: <span style={{ fontSize: 14, color: 'grey' }}>{article?.weight} Kg</span></label>
                            </div>
                        </Grid.Column>
                        {article?.printing &&
                            <Grid.Column width={8}>
                                <div className="field">
                                    <label color="grey">Impression: <span style={{ fontSize: 14, color: 'grey' }}>{article?.printing}</span></label>
                                </div>
                            </Grid.Column>
                        }
                        {article?.paperWeight &&
                            <Grid.Column width={8}>
                                <div className="field">
                                    <label color="grey">Support: <span style={{ fontSize: 14, color: 'grey' }}>{article?.paperWeight}</span></label>
                                </div>
                            </Grid.Column>
                        }
                        {article?.deliveryDelay &&
                            <Grid.Column width={8}>
                                <div className="field">
                                    <label color="grey">Délais de livraison: <span style={{ fontSize: 14, color: 'grey' }}>{article?.deliveryDelay}</span></label>
                                </div>
                            </Grid.Column>
                        }
                    </Grid>
                    <Form.Group unstackable style={{ marginLeft: "70%" }} >
                        <Button.Group floated="right">
                            <Button onClick={() => { setModalOpen(false) }} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive disabled={submitting || invalid || validArticle || unitPrice.toString() == "NaN"} loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </Form.Group>
                </Form>
            )}
        />
    );
}
export default observer(EditFactureArticle);
