import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";
import { Button, Form, FormInput, Grid } from "semantic-ui-react";
import { BaseStoreContext } from "../../app/store/baseStore";
import { Form as FinalForm } from 'react-final-form';
import { FaFileExcel, FaSearch } from "react-icons/fa";
import AsyncSelect from 'react-select/async';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { MdCancel } from "react-icons/md";

const ExpeditionAFFilter = () => {
    const { RangePicker } = DatePicker;
    const baseStore = useContext(BaseStoreContext);
    const { loadExpeditionAFacturerList, ExportBLAFacture, loadingInitial } = baseStore.expeditionStore;
    const [searchDateCommande, setSearchDateCommande] = useState<any>('');
    const [searchDateCommandeTo, setSearchDateCommandeTo] = useState<any>('');
    const [searchResultsClient, setSearchResultsClient] = useState<any>('');
    const [selectedLabelClient, setSelectedLabelClient] = useState<any>('Client');
    const [searchResults, setSearchResults] = useState([]);
    const [searchRefCmd, setSearchRefCmd] = useState('');
    const [searchResultsClientAll, setSearchResultsClientAll] = useState(false);

    const rangePresets: {
        label: string;
        value: [Dayjs, Dayjs];
    }[] = [
            { label: '7 derniers jours', value: [dayjs().add(-7, 'd'), dayjs()] },
            { label: '14 derniers jours', value: [dayjs().add(-14, 'd'), dayjs()] },
            { label: '30 derniers jours', value: [dayjs().add(-30, 'd'), dayjs()] },
            { label: '90 derniers jours', value: [dayjs().add(-90, 'd'), dayjs()] },
        ];
    const handleChangeClient = (value: any) => {
        setSearchResultsClient(value.id);
        setSearchResultsClientAll(value.all);
        setSelectedLabelClient(value!.agencyName + "-" + value!.companyName + (value!.all ? "-Tous les réseaux" : ""));
    }

    const onRangeChange = (dates: null | (Dayjs | null)[]) => {
        if (dates) {
            setSearchDateCommande(dates[0]?.toISOString());
            setSearchDateCommandeTo(dates[1]?.toISOString());
        }
    };
    const handleChange = (event: any) => {
        setSearchResults(event.target.value);
    }
    const handleChangeRefCmd = (event: any) => {
        setSearchRefCmd(event.target.value);
    }

    const handleSearch = (event: any) => {
        if (event == 'clear') {
            setSearchDateCommande('')
            setSearchDateCommandeTo('')
            setSearchResultsClient('')
            setSearchResults([])
            setSelectedLabelClient('Client')
            loadExpeditionAFacturerList(null);
            setSearchResultsClientAll(false);
        }
        else if (event == 'submit') {
            let obj: any = {
                dateCommande: searchDateCommande,
                dateCommandeTo: searchDateCommandeTo,
                searchClient: searchResultsClient,
                search: searchResults,
                searchClientAll: searchResultsClientAll,
                searchRefCmd: searchRefCmd,
            }; loadExpeditionAFacturerList(obj);

        }
        else if (event == 'clique') {
            let obj: any = {
                dateCommande: searchDateCommande,
                dateCommandeTo: searchDateCommandeTo,
                searchClient: searchResultsClient,
                search: searchResults,
                selectedLabel: selectedLabelClient
            }; ExportBLAFacture(obj);

        }
    }
    return (
        <FinalForm
            onSubmit={handleSearch}
            render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit} error style={{ width: "100%", fontSize: 14 }}>
                    <Grid>
                        <Grid.Row columns={5} style={{ padding: 0, margin: 0 }}>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <FormInput
                                    icon='search'
                                    type="text"
                                    placeholder="Expédition"
                                    value={searchResults}
                                    onChange={handleChange}
                                    style={{ width: '100%' }}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <FormInput
                                    icon='search'
                                    type="text"
                                    placeholder="Ref Commande"
                                    value={searchRefCmd}
                                    onChange={handleChangeRefCmd}
                                    style={{ width: '100%' }}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <AsyncSelect
                                    name="form-field-name"
                                    noOptionsMessage={() => "Code introuvable"}
                                    cacheOptions
                                    defaultOptions
                                    placeholder={selectedLabelClient}
                                    value={searchResultsClient}
                                    getOptionLabel={e => e!.agencyName + "-" + e!.companyName + (e!.all ? "-Tous les réseaux" : "")}
                                    loadOptions={baseStore.clientStore.loadOptions}
                                    onChange={handleChangeClient}
                                />
                            </Grid.Column>

                            <Grid.Column style={{ padding: 2, margin: 0 }}>

                                <RangePicker
                                    presets={rangePresets}
                                    onChange={onRangeChange}
                                    format={'DD/MM/YYYY'}
                                    placeholder={["Début", "Fin"]}
                                    style={{ width: '100%', height: '39.201px' }}
                                />

                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0, display: 'flex' }}>
                                <Button style={{ padding: 5, margin: "0 0 0 10px", color: 'blue', background: "none" }} type='submit' onClick={(e: any) => handleSearch('submit')} >
                                    <FaSearch />
                                </Button>
                                <Button style={{ float: "right", padding: 5, margin: 0, color: 'green', background: "none" }} >
                                    <MdCancel style={{ fontSize: 17 }} onClick={(e: any) => handleSearch('clear')} />
                                </Button>
                                <Button
                                    size="tiny"
                                    loading={loadingInitial}
                                    floated="right"
                                    style={{ paddingTop: 0, paddingBottom: 0, height: 32 }}
                                    content="Export excel"
                                    className="andi-btn filled success"
                                    icon={(<FaFileExcel style={{ marginRight: "10px" }} />)}
                                    onClick={(e: any) => handleSearch('clique')}

                                />
                            </Grid.Column >

                        </Grid.Row>

                    </Grid>
                </Form >
            )}
        />
    );
};
export default observer(ExpeditionAFFilter);