import React from 'react';
import { Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import ParametreArticleForm from './ParametreArticleForm';
import Breadcrumbs from '../../dashboard/breadcrumbs';


const ParametreArticlePage: React.FC = ({ }) => {
    return (
    <div>
            <Breadcrumbs
                items={[
                    { key: 0,name: "Paramétres", root: "" },
                    { key: 1,name: "Paramétres articles", root: "/dashboardParametreArticle" },
                ]}
            />
        <Grid>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Grid style={{ marginTop: 2 }}>
                        <Grid.Column width={16}>
                            <ParametreArticleForm />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </div>
    );
}

export default observer(ParametreArticlePage);