import { Helmet } from "react-helmet";
import { Segment } from 'semantic-ui-react'

const Footer = () => {
    return (
        <div>
            <Segment style={{ position: "fixed", bottom: 0,right:0 ,width:"150px"}} vertical>
                <span style={{ padding: '0 5% 0', fontSize: "13px", width: "100%"  }}>Powered by Atlatel</span>
            </Segment>
            <Helmet>
                <script src="/assets/js/jquery.slimscroll.min.js"></script>
                <script src="/assets/js/waves.min.js"></script>
                <script src="/assets/js/app.min.js"></script>
            </Helmet>
        </div>
    )
}
export default Footer;