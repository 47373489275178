import React from 'react';
import { Header, Grid, Item, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { FiDownload, FiDownloadCloud } from 'react-icons/fi';


const AddClientHeader: React.FC = ({ }) => {
    return (
        <Grid>
            <Grid.Column width={16}>
                <Item.Group>
                    <Item>
                        <Item.Content>
                            <Header as='h2' icon='upload'></Header>
                            <Header as='h2'>Ajouter Franchisé(s)</Header>
                            <Button
                                floated="right"
                                content="Retour vers franchiseurs"
                                color="orange"
                                icon='arrow alternate circle left outline'
                                basic
                                as={Link}
                                to="/franchiseurDashboard"
                            />
                        </Item.Content>
                    </Item>
                    <Item>
                        <Item.Content>
                            <span style={{ textDecorationLine: 'underline', color: 'green', fontSize: 18, float: "right", fontFamily: 'serif' }} >
                                <FiDownloadCloud style={{ fontSize:18, marginRight:3 }} />
                                <a
                                href={'https://etikeo.blob.core.windows.net/imporation-client/ExcelClient.xlsx'}
                            >Télécharger la structure de fichier excel
                                </a>
                                </span>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Grid.Column>
        </Grid>
    );
};
export default observer(AddClientHeader);