import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { Button, Grid, Icon, Modal, Tab, Table } from "semantic-ui-react";
import { Action, Module } from "../../app/common/ModuleClaims/ModulePermissions";
import Loading from "../../app/layout/Loading";
import { IUserClaims } from "../../app/models/IUsersRoles";
import { BaseStoreContext } from "../../app/store/baseStore";
import Breadcrumbs from '../dashboard/breadcrumbs';
import ListeRolesModal from "./ListeRolesModal";


const ListeUsers = () => {

    const baseStore = useContext(BaseStoreContext);
    const { allUsers, users, loadingInitial, getManageRoles } = baseStore.usersRoleStore;

    const [modalManageRoleOpen, setModalManageRoleOpen] = useState(false);
    const [email, setEmail] = useState("");

    useEffect(() => {
        var listeUC: IUserClaims[] = JSON.parse(sessionStorage.getItem("userClaimsSession")!)!.filter((i: any) => i.module == Module[Module.Users]);
        if (listeUC.some(i => i.action == Action[Action.View]))
            allUsers();
    }, []);

    const join = (value: any) => {
        let text = value.join(", ")
        return text;
    }

    if (loadingInitial) return <Loading content="Chargement des Utilisateurs ..." />
    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Admin", root: "" },
                    { key: 1, name: "Liste_Utilisateurs", root: "/dashboardUsers" },
                ]}
            />
            
            <Grid>
                <Grid.Column width={14} style={{ paddingBottom: 0 }} >
                    <Tab.Pane>
                    <Table loading={false} celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>UserName</Table.HeaderCell>
                                <Table.HeaderCell>Email</Table.HeaderCell>
                                <Table.HeaderCell>Roles</Table.HeaderCell>
                                <Table.HeaderCell style={{ textAlign: "center" }}>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {users && users.map((user: any) => (
                                <Table.Row key={user.id}>
                                    <Table.Cell>{user.userName}</Table.Cell>
                                    <Table.Cell>{user.email}</Table.Cell>
                                    <Table.Cell>{join(user.roles)}</Table.Cell>
                                    <Table.Cell style={{ textAlign: "center" }}>
                                        <Button
                                            onClick={(e) => {
                                                setModalManageRoleOpen(true);
                                                getManageRoles(user.id);
                                                setEmail(user.email);
                                            }}
                                            basic
                                            color="blue"
                                            icon='edit outline'
                                            style={{ marginRight: '2%' }}
                                            
                                        > Manage Roles</Button>
                                    </Table.Cell>
                                </Table.Row>
                            )
                            )}
                        </Table.Body>
                    </Table>
                    </Tab.Pane>
                </Grid.Column>
            </Grid>

            <Modal
                open={modalManageRoleOpen}
                size='small'
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >
                <Modal.Header icon='add' style={{ marginLeft: 20 }} >
                     {email}
                </Modal.Header>
                
                <Modal.Content scrolling>  
                    <ListeRolesModal />
                </Modal.Content>

                <Modal.Actions>
                    <div style={{ margin: 15, display: 'inline-block' }}>
                        <Button.Group >
                        <Button
                            type='button'
                            floated='right'
                            onClick={() => {
                                setModalManageRoleOpen(false);
                            }}
                        >
                            <Icon name='remove' /> Retour
                        </Button>
                            <Button.Or text='Ou' />
                            <Button
                                color='green'
                                onClick={(e) => {
                                    baseStore.usersRoleStore.updateUserRole().then(() => setModalManageRoleOpen(false));
                                }}>
                                <Icon name='checkmark' /> Confirmer
                            </Button>
                        </Button.Group>
                    </div>
                </Modal.Actions>
            </Modal>

        </div>
        );
}
export default observer(ListeUsers);
