import React from 'react';
import { Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import AddDevisHeader from './AddDevisHeader';
import AddDevisContent from './AddDevisContent';
import Breadcrumbs from '../../dashboard/breadcrumbs';

const AddDevisPage: React.FC = () => {

    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Commandes", root: "" },
                    { key: 1, name: "Devis", root: "/devisDashboard" },
                    { key: 2, name: "Ajouter devis", root: "addDevis" },
                ]}
            />
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Grid style={{ marginTop: 2 }}>
                            <Grid.Column width={16}>
                                <AddDevisHeader />
                                <AddDevisContent />
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>

                </Grid.Row>
            </Grid>
        </div>
    );
}
export default observer(AddDevisPage);