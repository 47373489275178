import React from 'react'
import { Tab } from 'semantic-ui-react';
import { IArticle } from '../../../app/models/IArticle';
import TarifFranchiseurDescription from './TarifFranchiseurDescription';
import TarifFournisseurDescription from './TarifFournisseurDescription';
import ArticleServiceDescription from './ArticleServiceDescription';
import ListCF from './ListCF';

interface IProps {
    setActiveTab: (activeIndex: any) => void;
    article: IArticle,
    loading: boolean,
    id: string,
}

const ArticleContent: React.FC<IProps> = ({ setActiveTab, id }) => {
    const panes = [
        { menuItem: 'Detail service', render: () => <ArticleServiceDescription /> },
        { menuItem: 'Tarif de vente', render: () => <TarifFranchiseurDescription /> },
        { menuItem: "Tarif d'achat", render: () => <TarifFournisseurDescription /> },
        { menuItem: 'Commandes Fournisseurs', render: () => <ListCF id={parseInt(id)} /> },

    ]
    return (
        <Tab
            menu={{ fluid: true, vertical: true }}
            menuPosition='right'
            panes={panes}
            onTabChange={(e, data) => setActiveTab(data.activeIndex)}
        />
    )
}

export default ArticleContent