import React, { useContext, useEffect, useState } from 'react';
import { Table, Button, Grid, Input, ItemContent, Item, ItemHeader, Header, Modal, ItemDescription, Label, Segment } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { RouteComponentProps } from 'react-router-dom';
import Loading from '../../../app/layout/Loading';
import Breadcrumbs from '../../dashboard/breadcrumbs';
import { Link } from 'react-router-dom';
import { IReceptionLineUpdated, IReceptionLine, IReceptionPartial, IReception } from '../../../app/models/IReception';
import { toast } from 'react-toastify';
import { FiCheckSquare, FiEdit } from 'react-icons/fi';
import { MdOutlineCancelPresentation } from 'react-icons/md';
import DeleteConfimationModal from './deleteConfirmationModal';
import UpdateReceptionLineDatesModal from './UpdateReceptionLineDatesModal';
import ListItemPlaceholder from '../../../app/common/form/ListItemPlaceholder';
import { VscFilePdf } from 'react-icons/vsc';

const getStatus = (status: string) => {
    switch (status) {
        case 'EnAttente':
            return 'En Attente';
        case 'ReceptionnePartielle':
            return 'Réceptionne Partielle';
        case 'Receptionnee':
            return 'Réceptionnée';
        default:
            return '';
    }
}
const numberFormat = new Intl.NumberFormat('FR-fr', {
    style: 'currency',
    currency: 'EUR',
    useGrouping: true,
});

interface RouteParams {
    id: string
}

interface IProps extends RouteComponentProps<RouteParams> { }
const DetailsReceptionPage: React.FC<IProps> = ({ match }) => {
    const baseStore = useContext(BaseStoreContext);
    const { loadReceptionLines, receptionLines, loadingInitial, changeStateItems, reception, addReceptionPartiel, deleting, updateReceptionDate, submitting, generatePDF } = baseStore.receptionStore;
    const [editTarget, setEditTarget] = useState(-1);
    const [receivedQuantity, setReceivedQuantity] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [addedQuantity, setAddedQuantity] = useState(0);
    const [editDate, setEditDate] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [date, setDate] = useState<string>(new Date().toISOString());
    const { openModal } = baseStore.modalStore;


    useEffect(() => {
        loadReceptionLines(parseInt(match.params.id));
    }, [])

    const handleChangeDate = (e: any) => {
        let obj: any = {
            id: reception?.id,
            date: date
        };
        updateReceptionDate(obj)
        setEditDate(false)
    }

    const handleReception = () => {
        if (addedQuantity > receivedQuantity && addedQuantity <= quantity) {
            var data: IReceptionLineUpdated = {
                idOrder: parseInt(match.params.id),
                idOrderLine: editTarget,
                requiredQuantity: quantity,
                receivedQuantity: receivedQuantity,
                addedQuantity: addedQuantity - receivedQuantity,

            }
            changeStateItems(data);
        } else { toast.warning('Vérifiez la quantité'); }
    }
    const handleReceptionPartielle = () => {

        var receptionPartielle: IReceptionPartial = {
            idOrder: parseInt(match.params.id),
        }
        addReceptionPartiel(receptionPartielle);
    }


    return (
        <>
            <Modal
                open={modalOpen}
                size='small'
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >
                <Modal.Header icon='add' >
                    <Button
                        style={{ height: 35 }}
                        type='button' icon='x' color='red'
                        floated='right'
                        onClick={() => { setModalOpen(false) }}
                    >
                    </Button>
                </Modal.Header>
                <Modal.Content>
                    <UpdateReceptionLineDatesModal id={parseInt(match.params.id)} lines={receptionLines} setModalOpen={setModalOpen} />
                </Modal.Content>
            </Modal>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Stock", root: "" },
                    { key: 1, name: "Réception des commandes", root: "/receptionDashboard" },
                    { key: 1, name: `Réception ${reception?.reference}`, root: `/detailReception/${match.params.id}` },
                ]}
            />
            <Grid>
                <Grid.Column width={16}>
                    <Button
                        floated="right"
                        color="orange"
                        icon='arrow alternate circle left outline'
                        content="Retour"
                        basic
                        as={Link}
                        to="/receptionDashboard"
                    />

                </Grid.Column>
            </Grid>
            {(loadingInitial || !reception) ?
                <ListItemPlaceholder />
                :
                <>
                    <Segment style={{ margin: "20px" }}>
                        <Grid columns={16}>

                            <Grid.Column style={{ margin: 0 }} width={12}>
                            <Item>
                                <span style={{ paddingBottom: 10, fontSize: 16 }}><b style={{ color: 'red', marginRight: 50 }}>{reception?.orderFournisseur!.fabricant}</b></span>
                            </Item>
                            <Item>
                                <ItemHeader style={{ fontWeight: 'bold', float: "left", width: "50%", fontSize: 14, padding: 2 }}>Commande :
                                </ItemHeader>
                                <ItemContent as={Link} to={`/detailCommandeFournisseur/${reception?.orderFournisseur!.id!}`} style={{ float: "left", width: "50%", fontSize: 14, padding: 2 }}>
                                    {reception?.orderFournisseur!.reference!}
                                </ItemContent>
                            </Item>
                            <Item>
                                <ItemHeader style={{ fontWeight: 'bold', float: "left", width: "50%", fontSize: 14, padding: 2 }}>Entrepôt :
                                </ItemHeader>
                                <ItemContent style={{ float: "left", width: "50%", fontSize: 14, padding: 2 }}>
                                    {reception?.entrepots!.libelle!}
                                </ItemContent>
                            </Item>
                            <Item>
                                <ItemHeader style={{ fontWeight: 'bold', float: "left", width: "50%", fontSize: 14, padding: 2 }}>Date réception :
                                </ItemHeader>
                                <ItemContent style={{ float: "left", width: "50%", fontSize: 14, padding: 2 }}>
                                    {editDate ?
                                        (
                                            <>
                                                <Input name='creationDate' onChange={(e: any) => setDate(e.target.value)} value={date!} style={{ marginLeft: 10, height: 30, width: 150 }} type='date' />
                                                <FiCheckSquare color="green" style={{ marginLeft: 10, fontSize: 18 }} onClick={(e) => handleChangeDate(e)} />
                                                <MdOutlineCancelPresentation color="red" style={{ marginLeft: 10, fontSize: 18 }} onClick={(e) => setEditDate(false)} />
                                            </>
                                        ) : (
                                            <>
                                                <b style={{ marginLeft: 10 }}>{new Date(reception?.dateReception!).toLocaleDateString('en-GB')}</b>
                                                <FiEdit style={{ marginLeft: 10 }} onClick={(e) => setEditDate(true)} />
                                            </>
                                        )
                                    }
                                </ItemContent>
                            </Item>
                            <Item>
                                <ItemDescription style={{ float: "left", width: "50%", fontSize: 14, fontWeight: 700, padding: 2 }}>
                                    Réf Fournisseur :{reception?.orderFournisseur!.numSupplierOrder!}
                                    </ItemDescription>
                                    <VscFilePdf
                                        title="Télécharger PDF"
                                        color={baseStore.receptionStore.generatePdfMode ? 'orange' : 'green'}
                                        onMouseEnter={() => {
                                            baseStore.receptionStore.changeButtonPdfState(!baseStore.receptionStore.generatePdfMode);
                                        }}
                                        onMouseLeave={() => {
                                            baseStore.receptionStore.changeButtonPdfState(!baseStore.receptionStore.generatePdfMode);
                                        }}
                                        style={{ right: 0, position: "absolute", fontSize: "30px", cursor: 'pointer', content: "dddd" }}
                                        onClick={() => generatePDF(parseInt(match.params.id))}
                                    />
                            </Item>
                        </Grid.Column>
                            <Grid.Column style={{ margin: 0 }} width={4}>
                            {reception?.receptionState != "Facturee" &&
                                <Button
                                    floated="right"
                                    color="green"
                                    icon='arrow alternate circle left outline'
                                    content="Facturer"
                                    disabled={receptionLines.filter(x => x.receivedQuantity == 0).length == receptionLines.length}
                                    onClick={handleReceptionPartielle}
                                    basic
                                />
                            }
                            <Button
                                floated="right"
                                color="red"
                                icon='arrow alternate circle left outline'
                                content="Supprimer"
                                loading={deleting}
                                onClick={(e) => {
                                    openModal(<DeleteConfimationModal Id={reception!.id} />)
                                }}
                                basic
                            />
                            <Button
                                floated="right"
                                color="orange"
                                cursor="pointer"
                                style={{ display: reception?.receptionState === "EnAttente" ? 'block' : 'none' }}
                                content='Receptionner tous'
                                loading={submitting}
                                onClick={
                                    () => baseStore.receptionStore.receptionnerTous(parseInt(match.params.id))
                                }

                            />
                            <Button
                                cursor="pointer"
                                floated="right"
                                content='Receptionner partiellement'
                                style={{
                                    backgroundColor: "#4682b4", color: "White", position: "absolute", bottom: 0,
                                    right: 0, width: 100, paddingRight: 5, paddingLeft: 5, marginBottom: 8, marginRight: 16, fontSize: 11,
                                    display: reception?.receptionState != "Facturee" && reception?.receptionState != "Receptionnee" ? 'block' : 'none'
                                }}
                                onClick={
                                    () => {
                                        setModalOpen(true)
                                    }
                                }

                            />
                            </Grid.Column>

                        </Grid>
                    </Segment>

                    <div style={{ margin: "20px" }}>
                        <Table compact='very'>
                            <Table.Header>
                                <Table.Row >
                                    <Table.HeaderCell style={{ paddingLeft: "20px" }}>Article</Table.HeaderCell>
                                    <Table.HeaderCell>PRIX UNITAIRE HT</Table.HeaderCell>
                                    <Table.HeaderCell>QTE COMMANDEE</Table.HeaderCell>
                                    <Table.HeaderCell>QTE RECEPTIONNEE</Table.HeaderCell>
                                    <Table.HeaderCell>MONTANT HT</Table.HeaderCell>
                                    <Table.HeaderCell>ETAT</Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {receptionLines && receptionLines.map((item: IReceptionLine) => (
                                    <Table.Row key={item.id}>
                                        {editTarget == item.id ? (
                                            <>
                                                <Table.Cell>
                                                    <a href={item.orderFournisseurLine.isService ? `/detailService/${item.orderFournisseurLine.articleId}` : `/detailArticle/${item.orderFournisseurLine.articleId}`}>
                                                        {item.orderFournisseurLine.codeArticle} - {item.orderFournisseurLine.article}
                                                    </a>
                                                    <ul className="ui list" style={{ fontSize: 12, fontWeight: 700 }}>
                                                        {item.printing && <li>Impression:{item.printing}</li>}
                                                        {item.paperFormat && <li>Format:{item.paperFormat}</li>}
                                                        {item.paperWeight && <li>Support:{item.paperWeight}</li>}
                                                        <li>Poids unitaire:{item.weight} Kg</li>
                                                        {item.deliveryDelay && <li>Délais de livraison:{item.deliveryDelay}</li>}
                                                    </ul>
                                                </Table.Cell>
                                                <Table.Cell>{item.orderFournisseurLine.unitPrice.toFixed(4)} €</Table.Cell>
                                                <Table.Cell>{item.requieredQuantity}</Table.Cell>
                                                <Table.Cell selectable>
                                                    <Input name='receivedQuantity' type='number' placeholder='quantitePrepare' style={{ width: '60%', padding: '1%' }}
                                                        defaultValue={quantity} max={quantity} min={receivedQuantity} onChange={(e: any, data: any) => setAddedQuantity(parseInt(data.value))} />
                                                </Table.Cell>

                                                {item!.orderFournisseurLine.remise != 0 ? (
                                                    <Table.Cell> {numberFormat.format((item!.orderFournisseurLine.unitPrice * item.receivedQuantity) - (((item!.orderFournisseurLine.unitPrice * item.receivedQuantity) * item.orderFournisseurLine.remise) / 100))}</Table.Cell>
                                                ) : (
                                                    <Table.Cell>{numberFormat.format(item.orderFournisseurLine.unitPrice * item.receivedQuantity)}</Table.Cell>
                                                )}


                                                <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }}>{getStatus(item.receptionState)}</Table.Cell>
                                                {reception?.receptionState != "Facturee" &&
                                                    <Table.Cell >
                                                        <Button.Group fluid widths={2}>
                                                            <Button
                                                                type='button'
                                                                icon='cancel'
                                                                negative
                                                                basic
                                                                style={{ width: 30 }}
                                                                onClick={() => {
                                                                    setEditTarget(-1)
                                                                }}>

                                                            </Button>
                                                            <Button
                                                                icon='check'
                                                                positive
                                                                basic
                                                                type='submit'
                                                                style={{ width: 30 }}
                                                                onClick={(e) => {
                                                                    handleReception()
                                                                    setEditTarget(-1);
                                                                }}
                                                            >
                                                            </Button>
                                                        </Button.Group>
                                                    </Table.Cell>
                                                }
                                            </>
                                        ) : (
                                            <>
                                                <Table.Cell>
                                                    <a href={item.orderFournisseurLine.isService ? `/detailService/${item.orderFournisseurLine.articleId}` : `/detailArticle/${item.orderFournisseurLine.articleId}`}>
                                                        {item.orderFournisseurLine.codeArticle} - {item.orderFournisseurLine.article}
                                                    </a>
                                                    <ul className="ui list" style={{ fontSize: 12, fontWeight: 700 }}>
                                                        {item.printing && <li>Impression:{item.printing}</li>}
                                                        {item.paperFormat && <li>Format:{item.paperFormat}</li>}
                                                        {item.paperWeight && <li>Support:{item.paperWeight}</li>}
                                                        <li>Poids unitaire:{item.weight} Kg</li>
                                                        {item.deliveryDelay && <li>Délais de livraison:{item.deliveryDelay}</li>}
                                                    </ul>
                                                </Table.Cell>
                                                <Table.Cell>{item.orderFournisseurLine.unitPrice.toFixed(4)} €</Table.Cell>
                                                <Table.Cell>{item.requieredQuantity}</Table.Cell>
                                                <Table.Cell>{item.receivedQuantity}</Table.Cell>
                                                {item!.orderFournisseurLine.remise != 0 ? (
                                                    <Table.Cell> {numberFormat.format((item!.orderFournisseurLine.unitPrice * item.receivedQuantity) - (((item!.orderFournisseurLine.unitPrice * item.receivedQuantity) * item.orderFournisseurLine.remise) / 100))}</Table.Cell>
                                                ) : (
                                                    <Table.Cell>{numberFormat.format(item!.orderFournisseurLine.unitPrice * item.receivedQuantity)}</Table.Cell>
                                                )}

                                                <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }}>{getStatus(item.receptionState)}</Table.Cell>
                                                {reception?.receptionState != "Facturee" &&

                                                    <Table.Cell >
                                                        {item.receptionState != "Receptionnee" &&
                                                            <Button.Group fluid widths={2}>
                                                                <Button
                                                                    onClick={(e) => {
                                                                        setEditTarget(item.id);
                                                                        setQuantity(item.requieredQuantity)
                                                                        setReceivedQuantity(item.receivedQuantity)
                                                                        setAddedQuantity(item.requieredQuantity)
                                                                    }}
                                                                    basic
                                                                    color="blue"
                                                                    icon='edit outline'
                                                                    style={{ width: 60 }}
                                                                />
                                                            </Button.Group>
                                                        }
                                                    </Table.Cell>
                                                }
                                            </>
                                        )}
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </div>
                </>
            }
        </>
    );
}
export default observer(DetailsReceptionPage);