import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Form, Button, Input, Grid, Checkbox } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import { IProductFournisseur } from '../../../app/models/IArticle';
import { toast } from 'react-toastify';
import { ICommandeFournisseur, IOrderFournisseurLine } from '../../../app/models/ICommandeFournisseur';
import Select from 'react-select';
import { ITarifFournisseur } from '../../../app/models/ITarifFournisseur';
import CreatableSelect from 'react-select/creatable';
import { combineValidators, composeValidators, createValidator } from 'revalidate';
import { IOptionList } from '../../../app/models/IOption';
import { v4 as uuid } from 'uuid';

interface IProps {
    order: ICommandeFournisseur;
    orderLine: IOrderFournisseurLine;
    setModalOpen: Dispatch<SetStateAction<boolean>>;
}
const isFloat = createValidator(
    message => value => {
        if (value && !/^[+-]?\d+(\.\d+)?$/i.test(value)) {
            return message;
        }
    },
    'Invalid number'
);
const validate = combineValidators({
    unitPrice: composeValidators(
        isFloat({
            message: 'invalide valeur'
        }))({
            message: ''
        }),
});

const EditCommandeFournisseurArticle: React.FC<IProps> = ({ orderLine, order, setModalOpen }) => {

    const baseStore = useContext(BaseStoreContext);
    const { loadArticlesFournisseur, loadingArticle, articlesFournisseur, article, loadArticle } = baseStore.articleStore;
    const { editOrderFournisseurLine, submitting } = baseStore.commandeFournisseurStore;
    const { loadingOption, loadOptions, ITListOption, submittingOption, loadOptionsArticle, loadOptionsProduct, ITListOptionArticle } = baseStore.optionStore;
    const { loadingInitial, loadTarifFournisseurList, tarifFournisseurs, loadTarifFournisseurByTypeAndQuantite } = baseStore.tarifFournisseurStore;
    const [minQuantite, setMinQuantite] = useState<number>(orderLine!.quantity);
    const [validArticle, setValidArticle] = useState(true);
    const [finalArticle, setArticle] = useState<IProductFournisseur>();
    const [unitPrice, setUnitPrice] = useState<number>(orderLine.unitPrice);
    const [unitPriceWithTaxes, setUnitPriceWithTaxes] = useState<number>(orderLine.unitPrice + (orderLine.unitPrice * orderLine.tvaRate / 100));
    const [totalPrice, setTotalPrice] = useState<number>(orderLine.unitPrice * orderLine.quantity - orderLine.unitPrice * orderLine.quantity * orderLine.remise / 100);
    const totalPriceTaxes = (orderLine.unitPrice * orderLine.quantity + (orderLine.unitPrice * orderLine.tvaRate / 100) * orderLine.quantity);
    const [totalPriceWithTaxes, setTotalPriceWithTaxes] = useState<number>(totalPriceTaxes - totalPriceTaxes * orderLine.remise / 100);
    const [quantity, setQuantity] = useState<number>(orderLine!.quantity);
    const [disabled, setDisabled] = useState(true);
    const [tarif, setTarif] = useState<ITarifFournisseur | null>();
    const [remise, setRemise] = useState<number>(orderLine!.remise);
    const [comment, setComment] = useState<string>(orderLine!.comment);
    const [toBeAdded, setToBeAdded] = useState(false);
    const [productReference, setProductReference] = useState(orderLine!.internalRef);
    const [options, setOptions] = useState<Array<IOptionList>>([]);


    useEffect(() => {
        loadOptions();
        loadArticle(orderLine.articleId);
        loadOptionsProduct(orderLine.articleId.toString()).then(data => {
            var finition: IOptionList[] = [];
            data.forEach((optionArticle) => {
                let optionn: IOptionList = {
                    key: optionArticle.option.optionId,
                    label: optionArticle.option.libelle,
                    value: optionArticle.option.optionId
                }
                finition.push(optionn)
            })
            setOptions(finition);
        });
        loadTarifFournisseurList(orderLine.articleId)
            .then((data: any) => {
                var tarifFournisseur = order.codeFranchiseur == 0 ?
                    data!.filter((x: ITarifFournisseur) => x.fournisseur == order!.fournisseur && x.quantite <= orderLine.quantity)[data!.filter((x: ITarifFournisseur) => x.fournisseur == order!.fournisseur && x.quantite <= orderLine.quantity).length - 1] :
                    data!.filter((x: ITarifFournisseur) => x.franchiseur == order!.franchiseur && x.quantite <= orderLine.quantity)[data!.filter((x: ITarifFournisseur) => x.franchiseur == order!.franchiseur && x.quantite <= orderLine.quantity).length - 1];
                if (tarifFournisseur == undefined)
                    tarifFournisseur = order.codeFranchiseur == 0 ?
                        data!.filter((x: ITarifFournisseur) => x.fournisseur == order!.fournisseur)[0] :
                        data!.filter((x: ITarifFournisseur) => x.franchiseur == order!.franchiseur)[0];

                if (tarifFournisseur == undefined) {
                    var tarif: ITarifFournisseur = {
                        id: 0,
                        article: orderLine.article,
                        companyName: order.companyName,
                        fournisseur: order.fournisseur,
                        dateCreation: new Date(),
                        fabricant: order.fabricant,
                        productReference: "",
                        prix: orderLine.unitPrice,
                        quantite: orderLine.quantity,
                        franchiseur: order.franchiseur,
                        type: order.franchiseur ? 'Franchiseur' : 'Fournisseur',
                        lastPrice: false
                    };
                    tarifFournisseur = tarif;
                }
                if (tarifFournisseur.quantite != orderLine.quantity)
                    tarifFournisseur.quantite = orderLine.quantity;
                setTarif(tarifFournisseur);
                setArticle(tarifFournisseur!.product);
                setDisabled(false);
                setValidArticle(false);
            });
        order.codeFranchiseur == 0 ? loadArticlesFournisseur(order.fournisseur, 'Fournisseur') : loadArticlesFournisseur(order.franchiseur, 'Franchiseur');
    }, [])

    const handleSetArticle = (e: IProductFournisseur) => {
        setArticle(e);
        setValidArticle(true);
        loadOptionsProduct(e.productId.toString()).then(data => {
            var finition: IOptionList[] = [];
            data.forEach((optionArticle) => {
                let optionn: IOptionList = {
                    key: optionArticle.option.optionId,
                    label: optionArticle.option.libelle,
                    value: optionArticle.option.optionId
                }
                finition.push(optionn)
            })
            setOptions(finition);
        });
        loadTarifFournisseurList(e.productId)
            .then((data: ITarifFournisseur[] | undefined) => {
                const tarifFournisseur = data!.filter(x => x.lastPrice == true)[0] ?? data![0];
                if (tarifFournisseur == undefined) {
                    setTarif(null)
                    setMinQuantite(0);
                    setQuantity(0);
                    setUnitPrice(0);
                    setUnitPriceWithTaxes(0);
                    setTotalPrice(0);
                    setTotalPriceWithTaxes(0);
                }
                else {
                    setTarif(tarifFournisseur)
                    setMinQuantite(tarifFournisseur.quantite);
                    setQuantity(tarifFournisseur.quantite);
                    setProductReference(tarifFournisseur.productReference);
                    setUnitPrice(tarifFournisseur.prix);
                    setUnitPriceWithTaxes(tarifFournisseur.prix + (tarifFournisseur.prix * e.tvaRate / 100));
                    setTotalPrice(tarifFournisseur.prix * tarifFournisseur.quantite);
                    setTotalPriceWithTaxes(tarifFournisseur.prix * tarifFournisseur.quantite + (tarifFournisseur.prix * e.tvaRate / 100) * tarifFournisseur.quantite);
                }
                setDisabled(false);
                setRemise(0);

                setValidArticle(false);
            });
    }

    const handleChangeOption = (inputValue: string) => {
        setTimeout(() => {
            var id = uuid();
            const newOption: IOptionList = {
                key: id,
                label: inputValue,
                value: inputValue
            }
            setOptions((prev) => [...prev, newOption]);
        }, 1000);
    }
    const handleSetInformation = (e: any) => {
        var value = e.target.value == "" ? 0 : parseFloat(e.target.value);
        const tauxTva = article!.tvaRate!;
        const UnitPriceWithTaxes = value + value * tauxTva / 100;
        setUnitPrice(value);
        setUnitPriceWithTaxes(UnitPriceWithTaxes);
        setTotalPrice(value * quantity! - (value * quantity! * remise / 100));
        setTotalPriceWithTaxes(UnitPriceWithTaxes * quantity! - (UnitPriceWithTaxes * quantity! * remise / 100));
    }

    const handleSetPrixByTypeandQuantite = (e: any) => {
        setValidArticle(true);
        const parsedQuantite = parseFloat(e.quantite);
        setTarif(e);
        if (parsedQuantite > 0) {
            setQuantity(parsedQuantite);
            loadTarifFournisseurByTypeAndQuantite(finalArticle?.productId ?? article!.productId, order!.codeFranchiseur == 0 ? 'Fournisseur' : 'Franchiseur', order!.codeFranchiseur == 0 ? parseInt(e.id) : parsedQuantite)
                .then(data => {
                    const total = parsedQuantite * data!;
                    const tauxTva = finalArticle != null ? finalArticle!.tvaRate : article!.tvaRate;
                    const taxePrice = (data! * (100 + tauxTva)) / 100;
                    const totalTaxe = (total * (100 + tauxTva)) / 100;
                    setUnitPrice(data!);
                    setUnitPriceWithTaxes(taxePrice);
                    setTotalPrice(total - total * remise / 100);
                    setTotalPriceWithTaxes(totalTaxe - totalTaxe * remise / 100);
                    setValidArticle(false);
                });
        } else {
            toast.error("Quantité minimal est " + 1);
            setQuantity(minQuantite);
            setValidArticle(false);
        }

    }

    const handleChangeQuantity = (inputValue: string) => {
        setTimeout(() => {
            const newOption: ITarifFournisseur = {
                quantite: parseInt(inputValue),
                fournisseur: tarif!.fournisseur,
                franchiseur: tarif!.franchiseur,
                dateCreation: tarif!.dateCreation,
                prix: tarif!.prix,
                article: tarif!.article,
                companyName: tarif!.companyName,
                fabricant: tarif!.fabricant,
                productReference: productReference,
                type: tarif!.type,
                id: tarif!.id,
                lastPrice:false
            }
            setTarif(newOption);
            handleSetPrixByTypeandQuantite(newOption);
        }, 1000);

    }

    const handleChangePriceRemise = (e: any) => {
        const Remise = e.target.value;
        setRemise(Remise);
        setTotalPrice(unitPrice * quantity! - (unitPrice * quantity! * Remise / 100));
        setTotalPriceWithTaxes(unitPriceWithTaxes * quantity! - (unitPriceWithTaxes * quantity! * Remise / 100));
    }
    const handleFinalFormSubmit = (values: any) => {
        var tab: string[] = [];
        options.map((item: any) => {
            tab.push(item.label)
        })
        let newOrderLine = {
            ...values,

            quantity: quantity,
            articleId: finalArticle != null ? finalArticle?.productId : article?.productId,
            unitPrice: unitPrice,
            unitPriceWithTaxes: (unitPriceWithTaxes),
            tvaRate: finalArticle != null ? finalArticle?.tvaRate : article!.tvaRate,
            codeArticle: finalArticle != null ? finalArticle?.reference : article?.reference,
            article: finalArticle != null ? finalArticle?.label : article?.label,
            id: orderLine.id,
            weight: finalArticle != null ? finalArticle?.weight : article?.weight,
            remise: remise,
            comment: comment,
            toBeAdded: toBeAdded,
            internalRef: productReference,
            optionArticles: tab
        };
        editOrderFournisseurLine(orderLine.id, newOrderLine);
        setModalOpen(false);
    }

    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            validate={validate}
            render={({ handleSubmit, invalid }) => (
                <Form onSubmit={handleSubmit} error >
                    <Form.Group unstackable widths={2} >

                        <div className="field">
                            <h5>Article</h5>
                            <Select isSearchable name="article" isLoading={loadingArticle} options={articlesFournisseur}
                                getOptionLabel={e => e!.reference + ' - ' + e!.label}
                                getOptionValue={e => e!.productId.toString()}
                                placeholder={finalArticle != null ? finalArticle?.productId + " - " + finalArticle?.label : article?.productId + " - " + article?.label}
                                onChange={(e) => handleSetArticle(e!)}
                            />
                        </div>
                        <div className="field">
                            <Checkbox checked={toBeAdded} label="Ajouter ce tarif s'il n'existe pas" style={{ paddingTop: 10 }} onChange={(value) => setToBeAdded(!toBeAdded)} />
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={3}>
                        {!validArticle && (
                            <>
                                <div className="field">
                                    <label>Quantité *</label>
                                    <CreatableSelect name="quantiy"
                                        isDisabled={disabled}
                                        isLoading={loadingInitial}
                                        options={tarifFournisseurs.filter(x => x.lastPrice == false)}
                                        value={tarif}
                                        getOptionLabel={e => e?.quantite ? e?.quantite.toString() : `Affecter cette quantité`}
                                        getOptionValue={e => e?.id ? e?.id.toString() : ""}
                                        placeholder='Quantité'
                                        onChange={e => handleSetPrixByTypeandQuantite(e)}
                                        onCreateOption={handleChangeQuantity}
                                        formatCreateLabel={() => `Affecter cette quantité`}
                                    />

                                </div>
                                <div className="field">
                                    <label>Réf. Article Fournisseur</label>
                                    <input name='internalRef' type="text" value={productReference} onChange={(e) => setProductReference(e.target.value)} />
                                </div>
                                <div className="field">
                                    <label style={{ marginTop: 32 }} color="grey">TVA : {finalArticle != null ? finalArticle!.tvaRate : article?.tvaRate}</label>
                                </div>
                            </>
                        )}
                    </Form.Group>
                    <Form.Group unstackable widths={2}>
                        <div className="field">
                            <label>Prix unitaire</label>
                            <Form.Input name='unitPrice' disabled={validArticle} defaultValue={unitPrice} step={0.0001} type='number' onChange={handleSetInformation} />
                        </div>
                        <div className="field">
                            <label>Prix unitaire avec taxes</label>
                            <input name='unitPriceTaxes' disabled={true} value={unitPriceWithTaxes.toFixed(4)} />
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={2}>
                        <div className="field">
                            <label>Prix Total</label>
                            <input name='totalPrice' disabled={true} placeholder="Prix Total" value={totalPrice.toFixed(2)} />
                        </div>
                        <div className="field">
                            <label>Prix Total avec taxes</label>
                            <input name='totalPriceTaxes' disabled={true} value={totalPriceWithTaxes.toFixed(2)} />
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={3}>
                        <div className="field">
                            <label>Remise</label>
                            <input name='remise' min={0} max={100} value={remise} type="number" onChange={(e) => handleChangePriceRemise(e)} />
                        </div>
                        <Form.Field width={16}>
                            <label>Commentaire</label>
                            <input name='comment' type="text" value={comment} onChange={(e) => setComment(e.target.value)} />
                        </Form.Field >
                    </Form.Group>
                    <Grid unstackable>
                        <Grid.Column width={8}>
                            {article?.paperFormat &&
                                <Grid.Row width={16}>
                                    <div className="field">
                                        <label color="grey">Format: <span style={{ fontSize: 14, color: 'grey' }}>{article?.paperFormat}</span></label>
                                    </div>
                                </Grid.Row>
                            }

                            {article?.printing &&
                                <Grid.Row width={16}>
                                    <div className="field">
                                        <label color="grey">Impression: <span style={{ fontSize: 14, color: 'grey' }}>{article?.printing}</span></label>
                                    </div>
                                </Grid.Row>
                            }
                            {article?.paperWeight &&
                                <Grid.Row width={16}>
                                    <div className="field">
                                        <label color="grey">Support: <span style={{ fontSize: 14, color: 'grey' }}>{article?.paperWeight}</span></label>
                                    </div>
                                </Grid.Row>
                            }
                            {article?.deliveryDelay &&
                                <Grid.Row width={16}>
                                    <div className="field">
                                        <label color="grey">Délais de livraison: <span style={{ fontSize: 14, color: 'grey' }}>{article?.deliveryDelay}</span></label>
                                    </div>
                                </Grid.Row>
                            }
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <div className="field">
                                <label color="grey">Poids unitaire: <span style={{ fontSize: 14, color: 'grey' }}>{article?.weight} Kg</span></label>
                            </div>
                            <div className="field">
                                <label> Finition </label>
                                <CreatableSelect
                                    isMulti
                                    isDisabled={loadingOption || submittingOption}
                                    isLoading={loadingOption || submittingOption}
                                    options={ITListOption}
                                    value={options}
                                    placeholder="Finition / Option"
                                    onChange={(option: any) => {
                                        setOptions(option);
                                    }}
                                    onCreateOption={handleChangeOption}
                                />

                            </div>
                        </Grid.Column>
                    </Grid>
                    <Form.Group unstackable style={{ marginLeft: "70%" }} >
                        <Button.Group floated="right">
                            <Button onClick={() => { setModalOpen(false) }} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive disabled={submitting || invalid || validArticle || unitPrice.toString() == "NaN"} loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </Form.Group>
                </Form>
            )}
        />
    );
}
export default observer(EditCommandeFournisseurArticle);
