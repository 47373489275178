import React, { useContext, useEffect, useState } from 'react';
import { GridColumn, Grid, Loader, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import ListClient from './listClient';
import ClientFilter from './ClientFilter';
import InfiniteScroll from 'react-infinite-scroller';
import { Link, RouteComponentProps } from 'react-router-dom';
import ListItemPlaceholder from '../../../app/common/form/ListItemPlaceholder';
import Breadcrumbs from '../../dashboard/breadcrumbs';
import { FaFileExcel } from 'react-icons/fa';

interface RouteParams {
    id: string
}

interface IProps extends RouteComponentProps<RouteParams> { }

const ClientDashboard: React.FC<IProps> = ({ match }) => {

    const baseStore = useContext(BaseStoreContext);
    const { loadClientsFranchise, loadingInitial, page, totalPages, setPage, ExportFranchise, exporting } = baseStore.clientStore;
    const [loadingNext, setLoadingNext] = useState(false);

    const handleGetNext = () => {
        setLoadingNext(true);
        setPage(page + 1);
        loadClientsFranchise(parseInt(match.params.id)).then(() => setLoadingNext(false));
    }
    const handleDownload = () => {
        ExportFranchise(parseInt(match.params.id));
    }
    useEffect(() => {
        loadClientsFranchise(parseInt(match.params.id));
    }, []);

    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Tiers", root: "" },
                    { key: 1, name: "Réseaux", root: "/franchiseurDashboard" },
                    { key: 2, name: "Liste franchisés", root: `/clients/${match.params.id}` },
                ]}
            />
            <Grid>
                <GridColumn width={10} style={{ marginTop: 2 }}>
                    <Grid style={{ marginTop: 2 }}>
                        <GridColumn width={16}>
                            {loadingInitial && page === 0 ? <ListItemPlaceholder /> : (
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={handleGetNext}
                                    hasMore={!loadingNext && page + 1 < totalPages}
                                    initialLoad={false}
                                >
                                    <ListClient />
                                </InfiniteScroll>
                            )}
                        </GridColumn>
                    </Grid>
                </GridColumn>
                <Grid.Column width={6}>
                    <Grid.Column width={16}>
                    </Grid.Column>                  
                    <Button
                        style={{ marginTop: 20, width: 140}}
                        floated="right"
                        content="Retour "
                        color="orange"
                        icon='arrow alternate circle left outline'
                        as={Link}
                        to="/franchiseurDashboard"
                    />
                    {/*<Button*/}
                    {/*    style={{ marginTop: 20 }}*/}
                    {/*    floated="right"*/}
                    {/*    content="Importer clients"*/}
                    {/*    color="blue"*/}
                    {/*    icon='plus circle'*/}
                    {/*    as={Link}*/}
                    {/*    to={`/addClient/${match.params.id}`}*/}
                    {/*/>*/}
                    <Button
                        style={{ marginTop: 20 }}
                        floated="right"
                        content="Export excel"
                        color="blue"
                        icon={(<FaFileExcel />)}
                        loading={exporting}
                        onClick={handleDownload}
                    />
                    <Button
                        style={{ marginTop: 20 }}
                        floated="right"
                        content="Ajouter client"
                        color="green"
                        icon='plus circle'
                        as={Link}
                        to={`/createClient/${match.params.id}`}
                    />
                 
                    <ClientFilter />
                </Grid.Column>
                <Grid.Column width={16}>
                    <Loader active={loadingNext} />
                </Grid.Column>
            </Grid>
        </div>
    );
}
export default observer(ClientDashboard);