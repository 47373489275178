import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button, Grid } from 'semantic-ui-react';
import TextInput from '../../../app/common/form/inputText';
import { observer } from 'mobx-react-lite';
import { history } from '../../../index';
import { ICommande } from '../../../app/models/ICommande';
import { combineValidators, composeValidators, isRequired } from 'revalidate';


const validate = combineValidators({
    invoiceAddress1: composeValidators(
        isRequired)({
            message: "Veuillez saisir l'adresse de facturation"
        }),
    deliveryAddress1: composeValidators(
        isRequired)({
            message: "Veuillez saisir l'adresse de livraison"
        }),
    invoiceCity: composeValidators(
        isRequired)({
            message: "Veuillez saisir la ville de facturation"
        }),
    deliveryCity: composeValidators(
        isRequired)({
            message: "Veuillez saisir la ville de livraison"
        }),
    invoicePostalCode: composeValidators(
        isRequired)({
            message: "Veuillez saisir le code postal facturation"
        }),
    deliveryPostalCode: composeValidators(
        isRequired)({
            message: "Veuillez saisir le code postal livraison"
        }),
});

interface IProps {
    editCommande: (commande: Partial<ICommande>) => void;
    commande: ICommande;
    submitting: boolean;
}
const CommandeAdressesEditform: React.FC<IProps> = ({ editCommande, commande, submitting }) => {
    const handleFinalFormSubmit = (values: any) => {
        let newCommande = {
            ...values,
        };
        editCommande(newCommande);
    }
    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            validate={validate}
            initialValues={commande!}
            render={({ handleSubmit, invalid }) => (
                <Form onSubmit={handleSubmit} error>
                    <Grid columns={2} divided className='WithoutSpace'>
                        <Grid.Row>
                            <Grid.Column style={{paddingBottom : 0}}>
                                <h5>Raison sociale de facturation </h5>
                                <Field name='invoiceCompany' component={TextInput} placeholder='Raison sociale de facturation' value={commande!.invoiceCompany} />
                            </Grid.Column>
                            <Grid.Column style={{paddingBottom : 0}}>
                                <h5>Raison sociale de livraison </h5>
                                <Field name='deliveryCompany' component={TextInput} placeholder='Raison sociale de livraison' value={commande!.deliveryCompany} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column style={{paddingBottom : 0}}>
                                <h5>Adresse de facturation *</h5>
                                <Field name='invoiceAddress1' component={TextInput} placeholder='Adresse de facturation' value={commande!.invoiceAddress1} />
                            </Grid.Column>
                            <Grid.Column style={{paddingBottom : 0}}>
                                <h5>Adresse de livraison *</h5>
                                <Field name='deliveryAddress1' component={TextInput} placeholder='Adresse de livraison' value={commande!.deliveryAddress1} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column style={{paddingBottom : 0}}>
                                <h5>Complément d’adresse facturation</h5>
                                <Field name='invoiceAddress2' component={TextInput} placeholder='Complément d’adresse facturation' value={commande!.invoiceAddress2} />
                            </Grid.Column>
                            <Grid.Column style={{paddingBottom : 0}}>
                                <h5>Complément d’adresse livraison</h5>
                                <Field name='deliveryAddress2' component={TextInput} placeholder='Complément d’adresse livraison' value={commande!.deliveryAddress2} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column style={{paddingBottom : 0}}>
                                <h5>ville de facturation </h5>
                                <Field name='invoiceCity' component={TextInput} placeholder='ville de facturation' value={commande!.invoiceCity} />
                            </Grid.Column>
                            <Grid.Column style={{paddingBottom : 0}}>
                                <h5>ville de livraison </h5>
                                <Field name='deliveryCity' component={TextInput} placeholder='ville de livraison' value={commande!.deliveryCity} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column style={{paddingBottom : 0}}>
                                <h5>Pays de facturation </h5>
                                <Field name='invoiceCountry' component={TextInput} placeholder='Pays de facturation' value={commande!.invoiceCity} />
                            </Grid.Column>
                            <Grid.Column style={{paddingBottom : 0}}>
                                <h5>Pays de livraison </h5>
                                <Field name='deliveryCountry' component={TextInput} placeholder='Pays de livraison' value={commande!.deliveryCity} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column style={{paddingBottom : 0}}>
                                <h5>Code postal facturation *</h5>
                                <Field name='invoicePostalCode' component={TextInput} type='number' placeholder='Code postal facturation' value={commande!.invoicePostalCode} />
                            </Grid.Column>
                            <Grid.Column style={{paddingBottom : 0}}>
                                <h5>Code postal livraison *</h5>
                                <Field name='deliveryPostalCode' component={TextInput} type='number' placeholder='Code postal livraison' value={commande!.deliveryPostalCode} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>              
                            <Grid.Column style={{paddingBottom : 0}}>
                                <h5>Téléphone de facturation</h5>
                                <Field name='invoicePhone' component={TextInput} placeholder='Téléphone de facturation' value={commande!.invoicePhone} />
                            </Grid.Column>
                            <Grid.Column style={{paddingBottom : 0}}>
                                <h5>Téléphone de livraison</h5>
                                <Field name='deliveryPhone' component={TextInput} placeholder='Téléphone de livraison' value={commande!.deliveryPhone} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column style={{paddingBottom : 0}}>
                                <h5>Email de facturation</h5>
                                <Field name='invoiceEmails' component={TextInput} placeholder='Email de facturation' value={commande!.invoiceEmails} />
                            </Grid.Column>
                            <Grid.Column style={{paddingBottom : 0}}>
                                <h5>Email de livraison</h5>
                                <Field name='deliveryEmail' component={TextInput} placeholder='Email de livraison' value={commande!.deliveryEmail} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column style={{paddingBottom : 0}}>
                                <h5>Nom de contact de livraison</h5>
                                <Field name='deliveryLastName' component={TextInput} placeholder='Nom de contact de livraison' value={commande!.deliveryLastName} />
                            </Grid.Column>
                            <Grid.Column style={{paddingBottom : 0}}>
                                <h5>Prénom de contact de livraison</h5>
                                <Field name='deliveryFirstName' component={TextInput} placeholder='Nom de contact de livraison' value={commande!.deliveryFirstName} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <div style={{ marginTop: '20px' }}>
                        <Button.Group floated="right">
                          <Button onClick={() => history.push('/commandeDashboard')} floated='right' type='button'>Annuler</Button>
                        <Button.Or />
                          <Button positive disabled={submitting || invalid} loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </div>
                </Form>
            )}
        />
    );
};

export default observer(CommandeAdressesEditform);