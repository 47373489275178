import React, { useContext } from 'react';
import { Button, Header, Icon, Container } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';

interface IProps {
    TarifId: number;
}

const DeleteTarifFournisseurModal: React.FC<IProps> = ({ TarifId }) => {
    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    const { deleteTarif } = baseStore.tarifFournisseurStore;
    return (
        <Container textAlign='center'>
            <Header icon='trash' content='Supprimer tarif Fournisseur' style={{ marginLeft: 20 }} />
            <p>
                Voulez vous supprimer ce tarif ?
            </p>
            <Button.Group fluid>
                <Button onClick={() => closeModal()}>
                    <Icon name='remove' /> Annuler
                </Button>
                <Button.Or text='Ou' />
                <Button
                    color='green'
                    onClick={(e) => {
                        deleteTarif(TarifId);
                        closeModal()
                    }}>
                    <Icon name='checkmark' /> Confirmer
                </Button>
            </Button.Group>

        </Container>
    )
}
export default DeleteTarifFournisseurModal;
