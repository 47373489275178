import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { Form, Button, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { history } from '../../../index';
import { IDevis } from '../../../app/models/IDevis';
import InputSelect from '../../../app/common/form/inputSelect';
import { BaseStoreContext } from '../../../app/store/baseStore';



interface IProps {
    devis: IDevis;
    submitting: boolean;
}
const DevisAdressesEditform: React.FC<IProps> = ({devis, submitting }) => {
    const baseRepo = useContext(BaseStoreContext);
    const { loadAdressesByClient, ITListAdresse } = baseRepo.adresseStore;
    const { editAdressesDevis } = baseRepo.devisStore;
    const [newAdresse, setNewAdresse] = useState<string | undefined>(undefined);

    useEffect(() => {
        loadAdressesByClient(devis!.companyId!);
   }, [loadAdressesByClient])
    const handleChangeAdresse= (e: any, result: any) => {
        setNewAdresse(result.value);
    }
    const handleFinalFormSubmit = () => {
        editAdressesDevis(devis.id, newAdresse!);
    }

    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            initialValues={devis!}
            render={({ handleSubmit, invalid }) => (
                <Form onSubmit={handleSubmit} error>
                    <Grid columns={2} divided>
                        <Grid.Row>
                            <Grid.Column>
                                <h5>Adresse de livraison *</h5>
                                <Form.Select search  component={InputSelect} options={ITListAdresse} onChange={handleChangeAdresse}/>
                            </Grid.Column>
                        </Grid.Row>                       
                    </Grid>
                    <div style={{ marginTop: '20px' }}>
                        <Button.Group floated="right">
                                <Button onClick={() => history.push('/devisDashboard')} floated='right' type='button'>Annuler</Button>
                        <Button.Or />
                            <Button positive disabled={submitting || invalid} loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </div>
                </Form>
            )}
        />
    );
};

export default observer(DevisAdressesEditform);