import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Button, Checkbox, Icon, Tab, Table } from "semantic-ui-react";
import Loading from "../../app/layout/Loading";
import { BaseStoreContext } from "../../app/store/baseStore";
import { history } from "../.."

interface RouteParams {
    id: string,
}
interface IProps extends RouteComponentProps<RouteParams> { }
const ListePermissions: React.FC<IProps> = ({ match }) => {

    const baseStore = useContext(BaseStoreContext);
    const { loadPermissionsByRole, permissionRoleModel, changePermissions, checkAll, uncheckAll } = baseStore.usersRoleStore;

    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        loadPermissionsByRole(match.params.id);
    }, [match.params.id]);

    const splitAtModule = (index: number, value: string) => {
        let text2 = value.slice(index);
        const lastIndex = text2.lastIndexOf('.');
        let module = text2.slice(0, lastIndex);
        return module;
    }

    const splitAtPermission = (index: number, value: string) => {
        let text2 = value.slice(index);
        const lastIndex = text2.lastIndexOf('.');
        let permission = text2.slice(lastIndex + 1);
        return permission;
    }

    if (permissionRoleModel == null) return <Loading content="Chargement des Permissions ..." />
    return (
        <div>
            <Tab.Pane>
                
               <h3> {permissionRoleModel.roleName} </h3>
                <br></br>
                <Checkbox checked={selectAll} label="tout sélectionner" style={{ float: 'left', padding: '0 10px', paddingTop: 10 }} onChange={() => { setSelectAll(!selectAll); selectAll ? uncheckAll() : checkAll() }} />
                <br></br>
                <Table loading={false} celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell>Module</Table.HeaderCell>
                            <Table.HeaderCell>Permission</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {permissionRoleModel.roleClaims.map((permission: any) => (
                            <Table.Row key={permission.id}>
                                <Table.Cell>
                                    <Checkbox className="form-check-input" checked={permission.isSelected} value={permission.isSelected} onClick={() => { changePermissions(permission.displayValue) }} />
                                </Table.Cell>
                                <Table.Cell>{splitAtModule(12, permission.displayValue)}</Table.Cell>
                                <Table.Cell>{splitAtPermission(12,permission.displayValue)}</Table.Cell>
                            </Table.Row>
                        )
                        )}
                    </Table.Body>
                </Table>

                <div style={{ margin: 15, display: 'inline-block' }}>
                    <Button.Group >
                        <Button
                            type='button'
                            floated='right'
                            as={Link}
                            to="/allRoles"
                        >
                            <Icon name='remove' /> Retour
                        </Button>
                        <Button.Or text='Ou' />
                        <Button
                            color='green'
                            onClick={(e) => {
                                baseStore.usersRoleStore.updateRolePermissions().then(() => history.push('/allRoles'));
                            }}>
                            <Icon name='checkmark' /> Confirmer
                        </Button>
                    </Button.Group>
                </div>

            </Tab.Pane>
        </div>
        );
}
export default observer(ListePermissions);