import React, { useContext } from 'react';
import { Button, Header, Icon, Container } from 'semantic-ui-react';
import { IFacture } from '../../../app/models/IFacture';
import { BaseStoreContext } from '../../../app/store/baseStore';

interface IProps {
    facture: IFacture;
}

const DeleteFactureModal: React.FC<IProps> = ({ facture }) => {
    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    const { deleteFacture } = baseStore.factureStore;

    return (
        <Container textAlign='center'>
            <Header icon='trash' content='Supprimer une Facture' style={{ marginLeft: 20 }} />
            <p>
                Voulez vous supprimer cette facture ?
            </p>
            <Button.Group fluid>
                <Button onClick={() => closeModal()}>
                    <Icon name='remove' /> Annuler
                </Button>
                <Button.Or text='Ou' />
                <Button
                    name={facture.id}
                    color='green'
                    onClick={(e) => {
                        deleteFacture(facture.id)
                        closeModal()
                    }}>
                    <Icon name='checkmark' /> Confirmer
                </Button>
            </Button.Group>
        </Container>
    )
}
export default DeleteFactureModal;
