import { BaseStore } from "./baseStore";
import { observable, action, runInAction } from 'mobx';
import agent from '../agent/agent';
import { IStatistic } from "../models/IStatistic";

export default class StatisticStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
    }
    @observable loadingInitial = false;
    @observable fournisseurCount: number = 0;
    @observable fournisseurInactifCount: number = 0;
    @observable franchiseurCount: number = 0;
    @observable franchiseurInactifCount: number = 0;
    @observable produitESCount: number = 0;
    @observable produitHSCount: number = 0;
    @observable commandeCount: number = 0;
    @observable commandeTodayCount: number = 0;
    @observable clientCount: number = 0;
    @observable clientInactifCount: number = 0;
    @observable blCountDelivered: number = 0;
    @observable blTodayCountDelivered: number = 0;
    @observable blCountWait: number = 0;
    @observable blTodayCountWait: number = 0;
    @observable prepareCountDelivered: number = 0;
    @observable prepareTodayCountDelivered: number = 0;
    @observable statistics: IStatistic | null = null;


    @action load = async () => {
        this.loadingInitial = true;
        try {
            const statisticEnveloppe = await agent.statisticAgent.get();
            runInAction(() => {
                this.statistics = statisticEnveloppe;
                this.fournisseurCount = statisticEnveloppe.fournisseurCount;
                this.fournisseurInactifCount = statisticEnveloppe.fournisseurInactifCount;
                this.franchiseurCount = statisticEnveloppe.franchiseurCount;
                this.franchiseurInactifCount = statisticEnveloppe.franchiseurInactifCount;
                this.produitESCount = statisticEnveloppe.produitESCount;
                this.produitHSCount = statisticEnveloppe.produitHSCount;
                this.commandeCount = statisticEnveloppe.commandeCount;
                this.commandeTodayCount = statisticEnveloppe.commandeTodayCount;
                this.clientCount = statisticEnveloppe.clientCount;
                this.clientInactifCount = statisticEnveloppe.clientInactifCount;
                this.blCountDelivered = statisticEnveloppe.blCountDelivered;
                this.blTodayCountDelivered = statisticEnveloppe.blTodayCountDelivered;
                this.blTodayCountWait = statisticEnveloppe.blTodayCountWait;
                this.blCountWait = statisticEnveloppe.blCountWait;
                this.prepareCountDelivered = statisticEnveloppe.prepareCountDelivered;
                this.prepareTodayCountDelivered = statisticEnveloppe.prepareTodayCountDelivered;
                this.loadingInitial = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingInitial = false;
            })
            console.log(e);
        }
    }
}