import React, { useContext } from 'react';
import { Segment, Grid, Header } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import FileUploadWidget from '../../../app/common/fileUpload/FileUploadWidget';

interface IProps {
    franchiseurId: string;
}

const AddClientContent: React.FC<IProps> = ({ franchiseurId }) => {
    const baseRepo = useContext(BaseStoreContext);
    const { submitting, uploadClient } = baseRepo.clientStore;

    const handleUploadFile = (file: Blob, id = franchiseurId) => {
        uploadClient(file, id);
    }

    return (
        <Segment basic>
            <Grid>
                <Grid.Column width={16}>
                    <FileUploadWidget
                        uploadFile={handleUploadFile}
                        loading={submitting}
                    />
                </Grid.Column>
            </Grid>
        </Segment >
    );
}
export default AddClientContent;
