import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { Button, Form, Grid, Label } from "semantic-ui-react";
import { Form as FinalForm } from 'react-final-form';
import Select, { components } from "react-select";
import { FaSearch, FaSort } from "react-icons/fa";
import AsyncSelect from 'react-select/async';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { BaseStoreContext } from "../../../app/store/baseStore";
import { MdCancel } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
import { numberFormat } from "../../../app/common/options/IStyle";
import { IAccount } from "../../../app/models/IAccount";

const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <FaSort color='green' />
        </components.DropdownIndicator>
    );
};

const customStyles = {
    option: (provided: any, state: { isSelected: any; }) => ({
        ...provided,
        color: state.isSelected ? 'orange' : 'grey',
        backgroundColor: state.isSelected ? "#eee" : "white",
        borderBottom: '1px solid #eee',
    }),
    valueContainer: (props: any) => ({
        ...props,
        paddingTop: 0,
        height: '39.201px',
        minHeight: '39.201px',
    }),
    control: (baseStyles: any, state: any) => ({
        ...baseStyles,
        height: '39.201px',
        minHeight: '39.201px',
    }),
}

const ReglementFilter = () => {
    const { RangePicker } = DatePicker;
    const baseStore = useContext(BaseStoreContext);
    const { setPredicate, ExportRglt, loadingExport, totalMontant, loadingInitial } = baseStore.reglementStore;
    const { loadModePaiements, modePaiements } = baseStore.modePaiementStore;
    const { loadAccounts, loadingInitialAccount, accounts } = baseStore.accountStore;
    const [selectedSort, setSelectedSort] = useState<any>('');
    const [selectedLabelTri, setSelectedTri] = useState<any>('Trier...');
    const [searchDateCommande, setSearchDateCommande] = useState<any>('');
    const [searchDateCommandeTo, setSearchDateCommandeTo] = useState<any>('');
    const [searchResultsClient, setSearchResultsClient] = useState<any>('');
    const [selectedLabelClient, setSelectedLabelClient] = useState<any>('Client');
    const [selectedModePaiement, setModePaiement] = useState<any>('');
    const [selectedAccount, setAccount] = useState<any>('');
    const [selectedLabelModePaiement, setLabelModePaiement] = useState<any>('Mode Paiement');
    const [selectedLabelAccount, setLabelAccount] = useState<any>('Compte bancaire');
    const [searchClientAll, setSearchResultsClientAll] = useState(false);

    useEffect(() => {
        loadModePaiements();
        loadAccounts();
    }, [])
    const optionsTri = [
        { key: 'DateUp', text: 'Plus recent', value: 'DateUp' },
        { key: 'DateDown', text: 'Plus ancien', value: 'DateDown' },
        ,
    ];
    const rangePresets: {
        label: string;
        value: [Dayjs, Dayjs];
    }[] = [
            { label: '7 derniers jours', value: [dayjs().add(-7, 'd'), dayjs()] },
            { label: '14 derniers jours', value: [dayjs().add(-14, 'd'), dayjs()] },
            { label: '30 derniers jours', value: [dayjs().add(-30, 'd'), dayjs()] },
            { label: '90 derniers jours', value: [dayjs().add(-90, 'd'), dayjs()] },
        ];



    const handleChangeClient = (value: any) => {
        setSearchResultsClient(value.id);
        setSearchResultsClientAll(value.all);
        setSelectedLabelClient(value!.agencyName + "-" + value!.companyName + (value!.all ? "-Tous les réseaux" : ""));
    }
    const handleChangeTri = (value: any) => {
        setSelectedSort(value.value);
        let labelSelected: any = value.text;
        setSelectedTri(labelSelected);
    }


    const onRangeChange = (dates: null | (Dayjs | null)[]) => {
        if (dates) {
            setSearchDateCommande(dates[0]?.toISOString());
            setSearchDateCommandeTo(dates[1]?.toISOString());
        }
    };
    const handleSearch = (event: any) => {
        
        if (event == 'clear') {
            setSelectedSort('')
            setSelectedTri('Trier ...')
            setSearchDateCommande('')
            setSearchDateCommandeTo('')
            setPredicate('all', 'true')
            setSearchResultsClient('')
            setLabelModePaiement('Mode Paiement')
            setLabelAccount('Compte bancaire')
            setModePaiement('')
            setAccount('')
            setSelectedLabelClient('Client')
            setSearchResultsClientAll(false);

        }
        else if (event == 'submit') {
            var predicate = new Map()
            predicate.set('searchClient', searchResultsClient)
            predicate.set('sort', selectedSort)
            predicate.set("dateCommande", searchDateCommande);
            predicate.set("dateCommandeTo", searchDateCommandeTo);
            predicate.set("modePaiement", selectedModePaiement);
            predicate.set('searchClientAll', searchClientAll)
            predicate.set("compteBancaire", selectedAccount);


            if (searchResultsClient != "" || selectedSort != "" || searchDateCommande != "" || searchDateCommandeTo != "" || selectedModePaiement != "" || selectedAccount != "")
                setPredicate('searchMultiple', predicate)
            else if (searchResultsClient.length == 0 && selectedSort.length == 0 && searchDateCommande.length == 0 && searchDateCommandeTo.length == 0 && selectedModePaiement.length == 0 && selectedAccount.length == 0)
                setPredicate('all', 'true')
        }
        else if (event == 'clique') {
            let obj: any = {
                dateCommande: searchDateCommande,
                dateCommandeTo: searchDateCommandeTo,
                searchClient: searchResultsClient,
                sort: selectedSort,
                modePaiement: selectedModePaiement,
                searchClientAll: searchClientAll,
                selectedLabelClient: selectedLabelClient,
                compteBancaire: selectedAccount,
            }; ExportRglt(obj);
        }
    }
    return (
        <FinalForm
            onSubmit={handleSearch}
            render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit} error style={{ width: "100%", fontSize: 14 }}>
                    <Grid>
                        <Grid.Row columns={5} style={{ padding: 0, margin: 0 }}>                    
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <AsyncSelect
                                    name="form-field-name"
                                    noOptionsMessage={() => "Code introuvable"}
                                    cacheOptions
                                    defaultOptions
                                    placeholder={selectedLabelClient}
                                    value={searchResultsClient}
                                    getOptionLabel={e => e!.agencyName + "-" + e!.companyName + (e!.all ? "-Tous les réseaux" : "")}
                                    loadOptions={baseStore.clientStore.loadOptions}
                                    onChange={handleChangeClient}
                                />
                            </Grid.Column>                                                 
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <Select
                                    isLoading={baseStore.modePaiementStore.loadingInitial}
                                    options={modePaiements}
                                    value={selectedModePaiement}
                                    getOptionValue={e => e!.paiementId.toString()}
                                    onChange={e => { setModePaiement(e?.label!); setLabelModePaiement(e?.label!) }}
                                    placeholder={selectedLabelModePaiement}
                                />
                            </Grid.Column>
                                <Grid.Column style={{ padding: 2, margin: 0 }}>
                                    <Select
                                        isLoading={loadingInitialAccount}
                                        options={accounts}
                                    value={selectedAccount}
                                    getOptionValue={(e: IAccount) => e!.id.toString()}
                                    getOptionLabel={(e: IAccount) => e!.name.toString()}
                                    onChange={e => { setAccount(e?.id!); setLabelAccount(e?.name!) }}
                                    placeholder={selectedLabelAccount}
                                    />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>

                                <RangePicker
                                    presets={rangePresets}
                                    onChange={onRangeChange}
                                    format={'DD/MM/YYYY'}
                                    placeholder={["Début", "Fin"]}
                                    style={{ width: '100%', height: '39.201px' }}
                                />

                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0, display: 'flex' }}>
                                <Select
                                    isSearchable={false}
                                    components={{ DropdownIndicator }}
                                    styles={customStyles}
                                    options={optionsTri}
                                    value={selectedSort}
                                    getOptionLabel={e => e.text}
                                    onChange={(e) => handleChangeTri(e)}
                                    placeholder={selectedLabelTri} />
                                <Button style={{ padding: 5, margin: "0 0 0 10px", color: 'blue', background: "none" }} type='submit' onClick={(e: any) => handleSearch('submit')} >
                                    <FaSearch />
                                </Button>
                                <Button style={{ float: "right", padding: 5, margin: 0, color: 'green', background: "none" }} >
                                    <MdCancel style={{ fontSize: 17 }} onClick={(e: any) => handleSearch('clear')} />
                                </Button>
                                <Button loading={loadingExport} style={{ float: "right", padding: 5, margin: 0, color: 'green', background: "none" }} >
                                    <RiFileExcel2Fill style={{ fontSize: 17 }} onClick={(e: any) => handleSearch('clique')} />
                                </Button>
                            </Grid.Column >
                            {!loadingInitial &&
                                <>
                                <Label style={{ margin: 0, marginRight: 10 }}> Total TTC : {numberFormat.format(totalMontant)}</Label>
                                </>
                            }
                        </Grid.Row>
                    </Grid>
                </Form >
            )}
        />
    );
};
    export default observer(ReglementFilter);