import { useContext } from "react";
import { Button, Container, Header, Icon } from "semantic-ui-react";
import { IFactureFournisseurLine } from "../../../app/models/IFactureFournisseur";
import { BaseStoreContext } from "../../../app/store/baseStore";


interface IProps {
    invoiceLine: IFactureFournisseurLine
}
const DeleteInvoiceLineModal: React.FC<IProps> = ({ invoiceLine }) => {
    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    const { deleteInvoiceArticle } = baseStore.factureFournisseurStore;
    const deleteArticle = () => {
        let deleted: IFactureFournisseurLine = {
            ...invoiceLine,
            totalPrice: invoiceLine.quantity * invoiceLine.unitPrice,
            totalPriceWithTaxes: invoiceLine.quantity * invoiceLine.unitPriceWithTaxes
        };
        deleteInvoiceArticle(deleted);
    }
    return (
        <Container textAlign='center'>
            <Header icon='trash' content='Supprimer ligne de facture' style={{ marginLeft: 20 }} />
            <p>
                Voulez vous supprimer cette ligne de facture ?
            </p>
            <Button.Group fluid>
                <Button onClick={() => closeModal()}>
                    <Icon name='remove' /> Annuler
                </Button>
                <Button.Or text='Ou' />
                <Button
                    color='green'
                    onClick={(e) => {
                        deleteArticle()
                        closeModal()
                    }}>
                    <Icon name='checkmark' /> Confirmer
                </Button>
            </Button.Group>

        </Container>
    )
}
export default DeleteInvoiceLineModal;
