import { BaseStore } from "./baseStore";
import { observable, action, runInAction } from 'mobx';
import agent from '../agent/agent';
import { toast } from "react-toastify";
import { ICountReglement, IModeReglement, IModeReglementList } from "../models/IModeReglement";

export default class ModeReglementStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
    }

    @observable loadingInitial = false;
    @observable loadingModeReglement = false;
    @observable submitting = false;
    @observable deletingModeReglement = false;
    @observable modeReglement: IModeReglement | null = null;
    @observable countReglement: ICountReglement | null = null;
    @observable modeReglements: IModeReglement[] = [];
    @observable modeReglementRegestry = new Map();
    @observable modeReglementsCount = 0;
    @observable addModeReglementMode: boolean = false;
    @observable ITListModeReglement: IModeReglementList[] = [];
    @observable loadingModeReglementAction = false;
    @observable editModeReglement: boolean = false;
    @observable addMode: boolean = false;
    @observable progress: number = 50;

    @action changeButtonStateEdit = async (newState: boolean) => {
        this.editModeReglement = newState;
    }

    @action changeButtonStateAdd = async (newState: boolean) => {
        this.addMode = newState;
    }

    @action loadModeReglements = async () => {
        this.loadingInitial = true;
        try {
            const modeReglements = await agent.modeReglementAgent.list();
            runInAction(() => {
                this.modeReglements = modeReglements;
                this.ITListModeReglement = []
                modeReglements.forEach((item) => {
                    this.ITListModeReglement.push({ key: item.reglementId, text: item.label, value: item.label })
                });
                this.loadingInitial = false;
            })
        } catch (e) {
            toast.error("Problem loading mode reglements!");
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }

    @action loadModeReglement = async (id: string) => {

        this.loadingModeReglement = true;
        let modeReglement = this.getModeRegelemnt(id);
        if (modeReglement) {
            this.modeReglement = modeReglement;
            this.loadingModeReglement = false;
            return modeReglement;
        }
        else {
            try {
                modeReglement = await agent.modeReglementAgent.details(id);
                runInAction(() => {
                    modeReglement.dateCreation = modeReglement.dateCreation.split('T')[0];
                    this.modeReglementRegestry.set(modeReglement.formeJuridiqueId, modeReglement);
                    this.modeReglement = modeReglement;
                    this.loadingModeReglement = false;
                })
                return modeReglement;
            } catch (e) {
                runInAction(() => {
                    this.loadingModeReglement = false;
                })
                console.log(e);
            }
        }
    }

    @action loadCountReglement = async (id: string) => {
        try {
            let countmodeReglement = await agent.modeReglementAgent.countReglement(id);
            runInAction(() => {
                this.countReglement = countmodeReglement;
            })
            return this.countReglement;
        } catch (e) {
            runInAction(() => {
                this.loadingModeReglement = false;
            })
        }
    }



    getModeRegelemnt = (id: string) => {
        return this.modeReglementRegestry.get(id);
    }


    @action create = async (values: IModeReglement) => {
        this.submitting = true;
        const user = await agent.userAgent.current();
        try {
            await agent.modeReglementAgent.create(values);
            runInAction(() => {
                this.modeReglementRegestry.set(values.reglementId, values)
                this.modeReglement = values;
                this.modeReglement.owner = user.username;
                this.modeReglements.push(this.modeReglement);
                this.submitting = false;
                toast.success("ajout éffectué avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    @action edit = async (values: Partial<IModeReglement>) => {
        this.submitting = true;
        try {
            await agent.modeReglementAgent.update(values);
            let modeReglement = await agent.modeReglementAgent.details(values.reglementId!);
            runInAction(() => {
                this.modeReglements = this.modeReglements.filter(a => a.reglementId != values.reglementId);
                this.modeReglements.push(modeReglement);
                toast.success("Modification éffectuée avec succès");
                this.submitting = true;
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action deleteModeReglement = async (id: string) => {
        this.loadingModeReglementAction = true;
        var mode = await agent.modeReglementAgent.details(id);
        if (mode.label == "Cash") {
            runInAction(() => {
                toast.warning("Cet échéance est par defaut et ne peut pas étre supprimer");
                this.loadingModeReglementAction = false;
            })
        }
        else {
            try {
                await agent.modeReglementAgent.delete(id);
                runInAction(() => {
                    this.loadingModeReglementAction = false;
                    this.modeReglements = this.modeReglements.filter(a => a.reglementId !== id);
                    toast.success("suppression éffectuée avec succès");
                })
            } catch (e) {
                console.log(e);
                toast.error("Problème de suppression d'échéance!");
                runInAction(() => {
                    this.loadingModeReglementAction = false;
                })
            }
        }
    }
}