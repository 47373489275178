import React, { useContext, useEffect, useState } from 'react';
import { Form, Item, Table, Button, Header, Progress } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import Loading from '../../../app/layout/Loading';
import { observer } from 'mobx-react-lite';
import TextInput from '../../../app/common/form/inputText';
import ImpressionForm from './AddImpressionModal';
import DeleteImpressionModal from './DeleteImpressionModal';

const ListImpression: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { loadImpressions, loadingInitial, edit, impressions, loadingImpressionAction, progress, submittingImpression } = baseStore.impressionStore;
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    const [editTarget, setEditTarget] = useState<string | undefined>(undefined);
    const [editForm, setEditForm] = useState(false);
    const [libelle, setLibelle] = useState<string | undefined>(undefined);
    const { openModal } = baseStore.modalStore;

    const handleFinalFormSubmit = () => {
        let newimpression = {
            libelle: libelle,
            impressionId: editTarget,
          
        };
        edit(newimpression);
        setEditForm(false)
    }
    useEffect(() => {
        loadImpressions();
    }, []);

    if (loadingInitial) return <Loading content="Chargement des impressions ..." />

    return (
        <div>
            <Item.Group>
                <Item>
                    <Item.Content>
                        <Header as='h2'>{'Impression'}</Header>
                        <Button
                            floated="right"
                            content={baseStore.impressionStore.addMode ? 'Annuler' : 'Ajouter impression'}
                            color="orange"
                            icon='arrow alternate circle left outline'
                            basic
                            onClick={() => { baseStore.impressionStore.changeButtonStateAdd(!baseStore.impressionStore.addMode) }}
                        />
                    </Item.Content>
                </Item>
            </Item.Group>
            {baseStore.impressionStore.addMode &&
                <div>
                <ImpressionForm />
                    <br />
                </div>
            }
            {submittingImpression &&
                <Progress color='orange' percent={progress} size='tiny' />
            }
            <Table loading={false} celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Libelle</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {impressions && impressions.map((impression: any) => (
                        <Table.Row key={impression.impressionId}>
                            {editForm && editTarget === impression.impressionId ? (
                                <>
                                    <Table.Cell selectable>
                                        <Form.Input name='libelle' component={TextInput} placeholder='libelle' defaultValue={impression.libelle} style={{ width: '100%', padding: '1%' }} onChange={event => setLibelle(event.target.value)} />
                                    </Table.Cell>
                                    <Table.Cell selectable>
                                        <Button.Group fluid widths={2}>
                                            <Button
                                                type='button'
                                                onClick={() => {
                                                    setEditForm(false);
                                                }}>
                                                Annuler
                                            </Button>
                                            <Button.Or />
                                            <Button
                                            name={impression.impressionId}
                                                positive
                                                type='submit'
                                                onClick={(e) => {
                                                    handleFinalFormSubmit()
                                                    setEditTarget(e.currentTarget.name)
                                                }}
                                            >Confirmer</Button>
                                        </Button.Group>
                                    </Table.Cell>
                                </>
                            ) : (
                                <>
                                        <Table.Cell>{impression.libelle}</Table.Cell>
                                        <Table.Cell >
                                        <Button.Group fluid widths={2}>
                                            <Button
                                                onClick={(e) => {
                                                    setEditForm(true);
                                                    setEditTarget(e.currentTarget.name)
                                                    setLibelle(impression.libelle)
                                                }}
                                                color="blue"
                                                icon='edit outline'
                                                style={{ marginRight: '3%' }}
                                                    name={impression.impressionId}
                                            />
                                            <Button
                                                    name={impression.impressionId}
                                                basic
                                                color='red'
                                                icon='trash'
                                                    onClick={(e) => {
                                                    openModal(<DeleteImpressionModal impression={impression} />)
                                                    setDeleteTarget(e.currentTarget.name)
                                                    }}
                                                    loading={loadingImpressionAction && deleteTarget === impression.impressionId}
                                            />
                                        </Button.Group>
                                    </Table.Cell>
                                </>
                            )}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
}
export default observer(ListImpression);
