import React, { useContext } from 'react';
import { Button, Header, Icon, Container } from 'semantic-ui-react';
import { IPreparationCommande } from '../../app/models/IPreparation';
import { BaseStoreContext } from '../../app/store/baseStore';


interface IProps {
    BP: IPreparationCommande;
}

const DeleteBonDePreparationModal: React.FC<IProps> = ({ BP }) => {
    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    const { deleteOrderPrepare } = baseStore.preparationStore;

    return (
        <Container textAlign='center'>
            <Header icon='trash' content='Supprimer ce bon de préparation' style={{ marginLeft: 20 }} />
            <p>
                Voulez vous supprimer ce bon de préparation ?
            </p>
            <Button.Group fluid>
                <Button onClick={() => closeModal()}>
                    <Icon name='remove' /> Annuler
                </Button>
                <Button.Or text='Ou' />
                <Button
                    name={BP.idPreparation}
                    color='green'
                    onClick={(e) => {
                        deleteOrderPrepare(BP.idPreparation)
                        closeModal()
                    }}>
                    <Icon name='checkmark' /> Confirmer
                </Button>
            </Button.Group>
        </Container>
    )
}
export default DeleteBonDePreparationModal;
