import { useContext, useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { Form, Button, Grid, Table } from 'semantic-ui-react';
import TextInput from '../../../app/common/form/inputText';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { FC } from 'react';
import { IAttributeItems, IAttributes } from '../../../app/models/IProductAttributes';

interface Iprops {
    id: number;
}

const AffectProductAttributeToArticle: FC<Iprops> = ({ id }) => {
    const baseStore = useContext(BaseStoreContext);
    const { create } = baseStore.productAttributesStore;
    const { changeButtonState, editArticleMode } = baseStore.articleStore;
    const [name, setName] = useState<string>("");
    const [checked, setChecked] = useState<boolean>(false);
    const [elements, setElement] = useState<IAttributeItems[]>([]);
    const [valid, setvalid] = useState(false);

    const handleChange = () => {
        setChecked(!checked);
    };
    const handleChangeName = (e: any, result: any) => {
        setName(result.value.toUpperCase());
    }

    const handleChangeMain = (id: number) => {
        var tab = elements;
        tab[tab.findIndex(x => x.isMain == true)].isMain = false;
        tab[id].isMain = true;
        setElement(tab.slice(0, elements.length));
    }

    const handleDelete = (id: number) => {
        var tab = elements;
        tab=tab.filter(x => x.id != id);
        setElement(tab);
    }

    const handleFinalFormSubmit = (values: any) => {
        if (valid) {
            var valeurs = "";
            elements.forEach((option, index) => {
                valeurs += (option.item);
                if (index != elements.length - 1)
                    valeurs += ';';
            })
            let newPro: IAttributes = {
                ...values,
                id: id,
                name: name.toUpperCase(),
                defaultValue: elements[elements.findIndex(x => x.isMain == true)].item,
                possibleValues: valeurs,
                displayAllWithDistinctQuantities: checked,
            };
            create(newPro);
            changeButtonState(!editArticleMode)
        } else {
            if (values.possibleValues != "") {
                const tab = elements;
                var element: IAttributeItems = {
                    id: elements.length,
                    item: values.possibleValues.toUpperCase(),
                    isMain: false
                }
                if (elements.length == 0)
                    element.isMain = true;
                tab.push(element);
                setElement(tab);
                values.possibleValues = '';
            }
        }
    }

    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            render={({ handleSubmit, invalid, pristine, submitting }) => (
                <Form onSubmit={handleSubmit} error>
                    <Grid>
                        <Grid.Column width={7}>
                            <h5 >Libellé de l'attribut</h5>
                            <Form.Input name='name' value={name} component={TextInput} type='text' onChange={handleChangeName} />
                        </Grid.Column>
                        <Grid.Column width={9}>
                            <h5>Valeurs possibles</h5>
                            <div style={{ display: "flex", flexWrap: 'wrap', width: '100%' }}>
                                <Field name='possibleValues' component={TextInput} />
                                <Button primary type='submit'>+</Button>
                            </div>
                        </Grid.Column>
                    </Grid>
                    <Grid  >
                        <Table style={{ height: 150 }} loading={false} celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Valeur</Table.HeaderCell>
                                    <Table.HeaderCell>Par Défaut</Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {elements &&
                                    elements.map((element) =>
                                    (
                                        <Table.Row>
                                            <Table.Cell>{element.item}</Table.Cell>
                                            <Table.Cell>
                                                <input type="checkbox" disabled={element.isMain} checked={element.isMain} onClick={() => handleChangeMain(element.id)} />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Button
                                                    disabled={element.isMain}
                                                    onClick={(e) => {
                                                        handleDelete(element.id)
                                                    }}
                                                    basic
                                                    negative
                                                    icon='trash'
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                            </Table.Body>
                        </Table>
                    </Grid>
                    <Grid columns={2} >
                        <Grid.Column>
                            <h5>Permettre l'affichage de toutes les options pour commander simultanément les variations</h5>
                            <input type="checkbox" checked={checked} onChange={handleChange} name="displayAllWithDistinctQuantities" />
                        </Grid.Column>
                    </Grid>
                    <div >
                        <Button.Group floated="right">
                            <Button disabled={submitting} onClick={() => { changeButtonState(!editArticleMode) }} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive disabled={submitting || invalid} loading={submitting} floated='right' type='submit' onClick={() => setvalid(true)}>Confirmer</Button>
                        </Button.Group>
                    </div>
                </Form >
            )}
        />
    );
};
export default observer(AffectProductAttributeToArticle);