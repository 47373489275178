import React from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button, Grid } from 'semantic-ui-react';
import TextInput from '../../../app/common/form/inputText';
import { observer } from 'mobx-react-lite';
import { history } from '../../../index';
import { ICommandeFournisseur } from '../../../app/models/ICommandeFournisseur';

interface IProps {
    edit: (commandeFournisseur: Partial<ICommandeFournisseur>) => void;
    commandeFournisseur: ICommandeFournisseur;
    submitting: boolean;
}
const CommandeFournisseurAdressesEditForm: React.FC<IProps> = ({ edit, commandeFournisseur, submitting }) => {
    const handleFinalFormSubmit = (values: any) => {
        let newCommande = {
            ...values,
        };
        edit(newCommande);
    }
    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            initialValues={commandeFournisseur!}
            render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit} error>
                    <Grid columns={2} divided>
                        <Grid.Row>
                            <Grid.Column>
                                <h5>Adresse de facturation *</h5>
                                <Field name='invoiceAddress1' component={TextInput}  value={commandeFournisseur!.invoiceAddress1} />
                            </Grid.Column>
                            <Grid.Column>
                                <h5>Complément d’adresse facturation</h5>
                                <Field name='invoiceAddress2' component={TextInput}  value={commandeFournisseur!.invoiceAddress2} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <h5>ville de facturation *</h5>
                                <Field name='invoiceCity' component={TextInput}  value={commandeFournisseur!.invoiceCity} />
                            </Grid.Column>
                            <Grid.Column>
                                <h5>Code postal facturation *</h5>
                                <Field name='invoicePostalCode' component={TextInput} type='text' value={commandeFournisseur!.invoicePostalCode} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <div style={{ marginTop: '20px' }}>
                        <Button.Group floated="right">
                            <Button onClick={() => history.push('/commandeFournisseurDashboard')} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive disabled={submitting} loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </div>
                </Form>
            )}
        />
    );
};

export default observer(CommandeFournisseurAdressesEditForm);