import React, { SetStateAction, useContext, useEffect, useState } from 'react';
import { Button, Modal, Input, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import TextInput from '../../../app/common/form/inputText';
import { Dispatch } from 'react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { IOrderSupplier, ISupplier, ISupplierList } from '../../../app/models/ICommande';
import AsyncSelect from 'react-select/async';


interface IProps {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>;
    id: string;

}
const OrderFournisseurModal: React.FC<IProps> = ({ open, setOpen,id }) => {
    const baseStore = useContext(BaseStoreContext);
    const { loadArticleFournisseurList, articleFournisseurs, createOrderForunisseur, submitting } = baseStore.commandeStore;
    const [nbrFr, setNbrFr] = useState<number>(0);
    const [orderFournisseur, setOrderFournisseur] = useState<Array<IOrderSupplier>>([]);




    useEffect(() => {
        loadArticleFournisseurList(parseInt(id))
    }, [])


    const handleChangeinternalRef = (e: any, stock: ISupplierList) => {
        var orderSup: IOrderSupplier = {
            fournisseurs: undefined,
            orderLineId: stock.orderLineId,
            product: stock.product.productId,
            quantity: stock.quantity,
            internalRef: e.target.value
        };
        var table = orderFournisseur;
        var index = table.findIndex(x => x.orderLineId == stock.orderLineId && x.product == orderSup.product);
        if (index != -1) {
            table[index].internalRef = e.target.value;
        } else {
            table.push(orderSup);
        }
        setOrderFournisseur(table)
    }

    const handleChange = (value: any, stock: ISupplierList) => {
        var orderSup: IOrderSupplier = {
            fournisseurs: value,
            orderLineId: stock.orderLineId,
            product: stock.product.productId,
            quantity: stock.quantity,
            internalRef: "",
        };
        var table = orderFournisseur;
        var index = table.findIndex(x => x.orderLineId == stock.orderLineId && x.product == orderSup.product);
        if (index != -1) {
            if (table[index].fournisseurs == null)
                setNbrFr(nbrFr + 1);
            table[index].fournisseurs = value
        } else {
            table.push(orderSup);
            setNbrFr(nbrFr + 1);
        }
        setOrderFournisseur(table)
    }
    const handleSetQuantite = (e: any, stock: ISupplierList) => {
        var orderSup: IOrderSupplier = {
            fournisseurs: undefined,
            orderLineId: stock.orderLineId,
            product: stock.product.productId,
            quantity: e.target.value,
            internalRef: "",
        };
        var table = orderFournisseur;
        var index = table.findIndex(x => x.orderLineId == stock.orderLineId && x.product == orderSup.product);
        if (index != -1) {
            table[index].quantity = e.target.value;
        } else {
            table.push(orderSup);
        }
        setOrderFournisseur(table)
    }

    const handleFinalFormSubmit = (values: any) => {
                let newOrder: ISupplier = {
                    commandeId: parseInt(id),
                    orderSuppliers: orderFournisseur,
                }
        createOrderForunisseur(newOrder);
        }
    return (
        <Modal
            size={"large"}
            open={open}
            onClose={() => setOpen(false)}
        >
            <Modal.Header>Commande Fournisseur</Modal.Header>
            <Modal.Content >
                {articleFournisseurs && articleFournisseurs.map((stock: ISupplierList) => (
                <Grid>
                    <Grid.Row >
                        <label style={{ marginLeft: "20px", paddingTop: "8px" }} >Article :</label>
                            <label style={{ marginLeft: "20px", paddingTop: "8px", width: '150px' }} >{stock?.product.label}</label>
                            <label style={{ paddingTop: "8px", marginLeft: "20px" }}>Fournisseur :</label>
                        <div style={{ width: 250, float: "left", marginLeft: "20px" }}>
                                <AsyncSelect
                                    name="form-field-name"
                                    placeholder="Fournisseur"
                                    noOptionsMessage={() => "Code introuvable"}
                                    cacheOptions
                                    defaultOptions
                                    getOptionLabel={e => e!.text}
                                    getOptionValue={e => e!.value}
                                    loadOptions={baseStore.fournisseurStore.loadFournisseurOptions}
                                    onChange={(e) => handleChange(e!.key, stock)}
                                />
                        </div>
                            <label style={{ marginLeft: "20px", paddingTop: "8px" }} >Quantite :</label>
                            <Input style={{ height: '37px', width: '150px', marginLeft: "5px" }} component={TextInput} defaultValue={stock?.quantity} type='number' onChange={e => handleSetQuantite(e, stock)} />

                            <label style={{ marginLeft: "20px", paddingTop: "8px" }} >Réf :</label>
                            <Input style={{ height: '37px', width: '100px', marginLeft: "5px" }} component={TextInput} type='text' onChange={e => handleChangeinternalRef(e, stock)} />
                    </Grid.Row>
                  
                </Grid>
                ))}
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={() => setOpen(false)}>
                    Quitter
                </Button>
                <Button positive
                    disabled={nbrFr == 0}
                    loading={submitting}
                    onClick={handleFinalFormSubmit}
                >
                    Enregistrer
                </Button>
            </Modal.Actions>
        </Modal>


    )
}
export default observer(OrderFournisseurModal);