import { BaseStore } from "./baseStore";
import { observable, action, runInAction, reaction, toJS, computed } from 'mobx';
import agent from '../agent/agent';
import { toast } from "react-toastify";
import { IFichier } from "../models/IFranchiseur";
import { IFournisseur, IFournisseurList, IPhoto } from "../models/IFournisseur";
import { history } from '../../index';

const LIMIT = 5;

export default class FournisseurStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0;
                this.fournisseurRegestry.clear();
                this.loadFournisseurs();
            }
        )
        reaction(
            () => this.activeTab,
            activeTab => {
                if (activeTab != '')
                    return true;
            }
        )
    }
    @observable loadingPhotoAction = false;
    @observable loadingInitial = false;
    @observable loadingExport = false;
    @observable loadingFournisseur = true;
    @observable submitting = false;
    @observable deletingFournisseur = false;
    @observable fournisseur: IFournisseur | null = null;
    @observable fournisseurs: IFournisseur[] = [];
    @observable ITListFournisseur: IFournisseurList[] = [];
    @observable fournisseurRegestry = new Map();
    @observable fournisseursCount = 0;
    @observable page = 0
    @observable predicate = new Map();
    @observable activeTab: string = "";
    @observable uploadingFile = false;
    @observable uploadingPhoto = false;

    @computed get axiosParams() {
        const params = new URLSearchParams();
        params.append('limit', String(LIMIT));
        params.append('offset', `${this.page ? this.page * LIMIT : 0}`);
        this.predicate.forEach((value, key) => {
            if (key === 'startDate') {
                params.append(key, value.toISOString())
            } else {
                params.append(key, value)
            }
        })
        return params;
    }

    @computed get totalPages() {
        return Math.ceil(this.fournisseursCount / LIMIT);
    }


    @action setPage = (page: number) => {
        this.page = page;
    }

    @observable editFournisseurMode: boolean = false;


    @computed get fournisseurByFabricant() {
        return this.sortByFabricant(Array.from(this.fournisseurRegestry.values()));
    }

    @action setPredicate = (predicate: string, value: string | Date) => {
        this.predicate.clear();
        if (predicate !== 'all') {
            this.predicate.set(predicate, value);
        }
    }

    @action clearActivity = () => {
        this.fournisseur = null;
    };

    @action setActiveTab = async (activeIndex: string) => {
        runInAction(() => {
            this.activeTab = activeIndex;
        })
    }

    sortByFabricant(fournisseurs: IFournisseur[]) {
        const sotedByFabricant = fournisseurs.sort((a, b) => a.fabricant.localeCompare(b.fabricant));
        return Object.entries(
            sotedByFabricant.reduce(
                (fournisseurs, fournisseur) => {
                    const code = fournisseur.fabricant;
                    fournisseurs[code] = fournisseurs[code]
                        ? [...fournisseurs[code], fournisseur]
                        : [fournisseur];
                    return fournisseurs;
                },
                {} as { [key: string]: IFournisseur[] }
            )
        );
    }

    @observable verificationUpdate(newFornisseur: IFournisseur, oldFournisseur: IFournisseur) {
        var duplication = true;

        if (newFornisseur.miniCommande != oldFournisseur.miniCommande) return false;
        if (newFornisseur.seuil != oldFournisseur.seuil) return false;
        if (newFornisseur.franco != oldFournisseur.franco) return false;
        if (newFornisseur.seuilFranco != oldFournisseur.seuilFranco) return false;
        if (newFornisseur.fraisPort != oldFournisseur.fraisPort) return false;
        if (newFornisseur.valeur != oldFournisseur.valeur) return false;
        if (newFornisseur.esCompte != oldFournisseur.esCompte) return false;
        if (newFornisseur.repliquats != oldFournisseur.repliquats) return false;
        if (newFornisseur.remise != oldFournisseur.remise) return false;
        if (newFornisseur.seuilMin != oldFournisseur.seuilMin) return false;
        if (newFornisseur.typeRemise != oldFournisseur.typeRemise) return false;
        if (newFornisseur.montantRemise != oldFournisseur.montantRemise) return false;
        if (newFornisseur.typeMarcheId != oldFournisseur.typeMarcheId) return false;
        return (duplication);
    }
    @action loadFournisseurs = async () => {
        this.loadingInitial = true;
        this.ITListFournisseur = [];
        try {
            const fournisseurEnveloppe = await agent.fournisseurAgent.list(this.axiosParams);
            const { fournisseurs, fournisseurCount } = fournisseurEnveloppe;
            runInAction(() => {
                fournisseurs.forEach((fournisseur) => {
                    this.fournisseurRegestry.set(fournisseur.fournisseurId, fournisseur);
                    let fournisseurs: IFournisseurList = {
                        key: fournisseur.fournisseurId,
                        text: fournisseur.fabricant,
                        value: fournisseur.fournisseurId
                    }
                    this.ITListFournisseur.push(fournisseurs)

                })
                this.fournisseursCount = fournisseurCount;
                this.loadingInitial = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingInitial = false;
            })
            console.log(e);
        }
    }

    @action changeButtonState = async (newState: boolean) => {
        this.editFournisseurMode = newState;
    }

    @action loadFournisseur = async (id: number) => {
        this.loadingFournisseur = true;
        let fournisseur = this.getFournisseur(id);
        if (fournisseur) {
            this.fournisseur = fournisseur;
            this.loadingFournisseur = false;
            return toJS(fournisseur);
        }
        else {
            try {
                fournisseur = await agent.fournisseurAgent.details(id);
                runInAction(() => {
                    this.fournisseur = fournisseur;
                    this.fournisseurRegestry.set(fournisseur.fournisseurId, fournisseur);
                    this.loadingFournisseur = false;
                })
                return fournisseur;
            } catch (e) {
                runInAction(() => {
                    this.loadingFournisseur = false;
                })
                console.log(e);
            }
        }
    }

    getFournisseur = (id: number) => {
        return this.fournisseurRegestry.get(id);
    }

    @action create = async (values: IFournisseur) => {
        this.submitting = true;
        try {
             await agent.fournisseurAgent.create(values);
            runInAction(() => {
                this.fournisseurRegestry.set(values.fournisseurId, values)
                this.fournisseur = values;
                this.submitting = false;
                history.push(`/fournisseurDashboard`)
                toast.success("ajout effectué avec succès");
            })
        } catch (e) {
            toast.error("Problème d'ajout de Fournisseur!");
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    @action editFournisseur = async (values: Partial<IFournisseur>) => {
        this.submitting = true;
        try {
            await agent.fournisseurAgent.update(values);
  
            runInAction(() => {
                /*  this.fournisseurRegestry.set(values.fournisseurId, values);*/
                this.fournisseur = { ...this.fournisseur!, ...values };
                this.submitting = false;
                this.changeButtonState(false);
                toast.success("Modification effectuée avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action editFournisseurComptabilite = async (values: Partial<IFournisseur>) => {
        this.submitting = true;
        try {
            await agent.fournisseurAgent.updateComptabilite(values);
            runInAction(() => {
                this.fournisseur = { ...this.fournisseur!, ...values };
                this.submitting = false;
                this.changeButtonState(false);
                toast.success("Fournisseur modifié avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action editFournisseurAchat = async (values: Partial<IFournisseur>) => {
        this.submitting = true;
        try {
            await agent.fournisseurAgent.updateAchat(values);
            if (values.typeMarche)
                var typeMarche = (await agent.typeMarcheAgent.details(values.typeMarcheId!)).description;
            runInAction(() => {
                this.fournisseur = { ...this.fournisseur!, ...values };
                this.fournisseur.typeMarche = typeMarche;
                this.submitting = false;
                this.changeButtonState(false);
                toast.success("Fournisseur modifié avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action editFournisseurComplementInfos = async (values: Partial<IFournisseur>) => {
        this.submitting = true;
        try {
            await agent.fournisseurAgent.updateComplementInfos(values);
            runInAction(() => {
                this.fournisseur = { ...this.fournisseur!, ...values };
                this.submitting = false;
                this.changeButtonState(false);
                toast.success("Fournisseur modifié avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action disable = async (id: number) => {
        this.deletingFournisseur = true;
        try {
            await agent.fournisseurAgent.disable(id);
            runInAction(() => {
                this.deletingFournisseur = false;
                this.fournisseur!.isActive = false;
                toast.success("Fournisseur désactivé avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème désactivation fournisseur!");
            runInAction(() => {
                this.deletingFournisseur = false;
            })
        }
    }

    @action enable = async (id: number) => {
        this.deletingFournisseur = true;
        try {
            await agent.fournisseurAgent.enable(id);
            runInAction(() => {
                this.deletingFournisseur = false;
                this.fournisseur!.isActive = true;
                toast.success("Fournisseur activé avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème d'activation fournisseur!");
            runInAction(() => {
                this.deletingFournisseur = false;
            })
        }
    };

    @action uploadFile = async (file: Blob, id: number) => {
        this.uploadingFile = true;
        try {
            const fichier = await agent.fournisseurAgent.uploadFichier(file, id);
            runInAction(() => {
                if (this.fournisseur) {
                    this.fournisseur.fichiers.push(fichier);
                }
                this.uploadingFile = false;
                toast.success("Chargement de fichier est effectué avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de mise à jour fichier!");
            runInAction(() => {
                this.uploadingFile = false;
            })
        }
    }

    @action deleteFile = async (file: IFichier, id: number) => {
        this.deletingFournisseur = true;
        try {
            await agent.fournisseurAgent.deleteFichier(file.id, id);
            runInAction(() => {
                this.fournisseur!.fichiers = this.fournisseur!.fichiers.filter(a => a.id !== file.id);
                this.deletingFournisseur = false;
                toast.success("Suppression de fichier est effectué avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de suppresion du fichier!");
            runInAction(() => {
                this.deletingFournisseur = false;
            })
        }
    }

    loadFournisseurOptions = async (inputValue: string | null) => {
        if (!inputValue || !inputValue.trim() || inputValue.length < 2) {
            return await agent.fournisseurAgent.listFournisseur(null).then(r => r.data);
        } else
            return await agent.fournisseurAgent.listFournisseur(inputValue).then(r => r.data);
    };

    @action uploadPhoto = async (file: Blob, id: number) => {
        this.uploadingPhoto = true;
        try {
            const photo = await agent.fournisseurAgent.uploadPhoto(file, id);
            runInAction(() => {
                if (this.fournisseur) {
                    this.fournisseur.photos.push(photo);
                    this.fournisseur.uri = photo.url;
                }
                this.uploadingPhoto = false;
                toast.success("Chargement de photo effectué avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de mise à jour photo!");
            runInAction(() => {
                this.uploadingPhoto = false;
            })
        }
    }
    @action setMainPhoto = async (photo: IPhoto, id: number) => {
        this.loadingPhotoAction = true;
        try {
            await agent.fournisseurAgent.setMainPhoto(photo.id, id);
            runInAction(() => {
                this._baseStore.userStore.user!.image = photo.url;
                this.fournisseur!.photos.find(a => a.isMain)!.isMain = false;
                this.fournisseur!.photos.find(a => a.id === photo.id)!.isMain = true;
                this.fournisseur!.image = photo.url;
                this.loadingPhotoAction = false;
                toast.success("Photo affectée avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de mise à jour photo de franchiseur!");
            runInAction(() => {
                this.loadingPhotoAction = false;
            })
        }
    };
    @action deletePhoto = async (photo: IPhoto, id: number) => {
        this.loadingPhotoAction = true;
        try {
            await agent.fournisseurAgent.deletePhoto(photo.id, id);
            runInAction(() => {
                this.fournisseur!.photos = this.fournisseur!.photos.filter(a => a.id !== photo.id);
                if (this.fournisseur?.photos.length == 0)
                    this.fournisseur.uri = "/assets/img/no-logo.png";
                this.loadingPhotoAction = false;
                toast.success("Photo franchiseur supprimée avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Probléme de suppression!");
            runInAction(() => {
                this.loadingPhotoAction = false;
            })
        }
    }
    @action ExportSuppliers = async () => {
        this.loadingExport = true
        try {
            await agent.fournisseurAgent.exportAllSuppliers();
            runInAction(() => {
                toast.success("Les clients sont exportés avec succés");
                this.loadingExport = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingExport = false;
            })
            toast.error("Problem d'éxportation des donnèes!");
        }
    }
}