import React, { useContext, useEffect } from 'react';
import { GridColumn, Grid, Button, Table } from 'semantic-ui-react';
import { BaseStoreContext } from '../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import ListItemPlaceholder from '../../app/common/form/ListItemPlaceholder';
import Breadcrumbs from '../dashboard/breadcrumbs';
import { IRequest } from '../../app/models/IClient';
import NoResult from '../devis/devisDashboard/erreurNoResult';
import { history } from '../../index';


const RequestDashboard: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { loadRequests, loadingInitial, requests } = baseStore.clientStore;

    useEffect(() => {
        loadRequests();
    }, []);

    return (


        <div>

            <Breadcrumbs
                items={[
                    { key: 0, name: "Tiers", root: "" },
                    { key: 1, name: "Demandes", root: "/requests" },
                ]}
            />


            <Grid>
                <Grid.Row style={{ padding: 0, margin: "0 20px 0 20px" }}>


                </Grid.Row>
                <GridColumn width={16} style={{ marginTop: 2 }}>
                    {loadingInitial ? (<ListItemPlaceholder />) : (
                        <>
                            {(!loadingInitial && requests.length < 1) ?
                                (< NoResult name="Demande" />)
                                :
                                (
                                    <Grid style={{ marginTop: 2 }}>
                                        <GridColumn width={16}>
                                            <Table compact='very' style={{ marginTop: '0em' }} textAlign='center' >
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell>RESEAU</Table.HeaderCell>
                                                        <Table.HeaderCell>NOM D'AGENCE</Table.HeaderCell>
                                                        <Table.HeaderCell>NOM DE SOCIETE</Table.HeaderCell>
                                                        <Table.HeaderCell>DATE DE LA DEMANDE</Table.HeaderCell>
                                                        <Table.HeaderCell>ADRESSE DE FACTURATION</Table.HeaderCell>
                                                        <Table.HeaderCell>ADRESSE DE LIVRAISON</Table.HeaderCell>
                                                        <Table.HeaderCell></Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    {requests && requests.map((request: IRequest, key) => (
                                                        <>
                                                            <Table.Row key={request.id} >
                                                                <Table.Cell>  {request.agencyName == 0 ? request.network : request.agencyName + "-" + request.network}  </Table.Cell>
                                                                <Table.Cell>  {request.companyName}    </Table.Cell>
                                                                <Table.Cell> {request.socialName} </Table.Cell>
                                                                <Table.Cell>{new Date(request.requestDate).toLocaleDateString('en-GB')}</Table.Cell>
                                                                <Table.Cell>{request.invoiceCountry == null ? "FR" : request.invoiceCountry}, {request.invoiceCity} {request.invoiceAddress1}</Table.Cell>
                                                                <Table.Cell>{request.deliveryCountry == null ? "FR" : request.deliveryCountry}, {request.deliveryCity} {request.deliveryAddress1}</Table.Cell>
                                                                <Table.Cell>
                                                                    {request.networkId != 0 &&
                                                                        <Button

                                                                            basic
                                                                            size="small"
                                                                            color='green'
                                                                            icon='check'
                                                                            content="Créer"
                                                                            onClick={(e) => {
                                                                                history.push(`/generateClient/${request.networkId}/${request.id}`);
                                                                            }}
                                                                        />
                                                                    }
                                                                </Table.Cell>
                                                            </Table.Row>

                                                        </>
                                                    ))}
                                                </Table.Body>
                                            </Table>
                                        </GridColumn>


                                    </Grid>)}

                        </>
                    )}
                </GridColumn>


            </Grid>

        </div>
    );
}
export default observer(RequestDashboard);