import { useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import { BaseStoreContext } from "../../../app/store/baseStore";
import { observer } from 'mobx-react-lite';
import Breadcrumbs from '../../dashboard/breadcrumbs';
import FactureHeader from "./FactureHeader";
import FactureContent from "./FactureContent";
import Loading from "../../../app/layout/Loading";


interface RouteParams {
    id: string,
}
interface IProps extends RouteComponentProps<RouteParams> { }

const FacturePage: React.FC<IProps> = ({ match }) => {
    const baseStore = useContext(BaseStoreContext);
    const { loadFacture, facture, setActiveTab } = baseStore.factureFournisseurStore;

    useEffect(() => {
        loadFacture(match.params.id);
    }, [])

    if (!facture)
        return <Loading content="Chargement du facture ..." />;
    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Achat", root: "" },
                    { key: 1, name: "Factures Fournisseurs", root: "/factureFournisseurDashboard" },
                    { key: 2, name: "" + facture?.reference, root: `/detailFactureFournisseur/${match.params.id}` },
                ]}
            />
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Grid style={{ marginTop: 2 }}>
                            <Grid.Column width={16}>
                                <FactureHeader facture={facture} id={match.params.id} />
                                <FactureContent setActiveTab={setActiveTab} />
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}
export default observer(FacturePage);