import React, { useContext, useEffect, useState } from 'react';
import { Tab, Header, Button, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import DevisClientEditForm from './DevisClientEditForm';
import FormGridColumn from '../../../app/common/form/FormGridColumn';
import Loading from '../../../app/layout/Loading';

const DevisClient = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { devis, editInformationClientDevis, submitting } = baseRepo.devisStore;


    return (
        <Tab.Pane>
            <Grid>
                <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                    <Header floated='left' icon='user' content={`Information de client: ${devis!.agencyName}`} />
                    {devis!.quoteState == 'EnAttente' &&
                        <Button
                            onClick={() => { baseRepo.devisStore.changeButtonState(!baseRepo.devisStore.editDevisMode) }}
                            floated='right'
                            basic
                            content={baseRepo.devisStore.editDevisMode ? 'Annuler' : 'Modifier'}
                        />}
                </Grid.Column>
                <Grid.Column width={16}>
                    {baseRepo.devisStore.editDevisMode ? (
                        <DevisClientEditForm editDevis={editInformationClientDevis} devis={devis!} submitting={submitting} />
                    ) : (
                        <Grid className="ui stackable">
                                <Grid.Row style={{ padding: 0, margin: 0 }}>
                                    <FormGridColumn header="Code Client" content={devis?.agencyName} />
                                    <FormGridColumn header="Nom D'agence" content={devis?.companyName} />
                                    <FormGridColumn header="Digit code" content={devis!.deliveryDigiCode} />
                                    <FormGridColumn header="Interphone" content={devis!.deliveryInterphone} />
                                    <FormGridColumn header="Horaires d'ouverture" content={devis!.openHours} />
                                    <FormGridColumn header="Jours de fermeture" content={devis!.closingDays} />
                                    <FormGridColumn header="Email" content={devis?.deliveryEmail && devis!.deliveryEmail.split(';').map(x => <>{x}<br /></>)} />
                                </Grid.Row>
                        </Grid>
                    )}
                </Grid.Column>
            </Grid>
        </Tab.Pane>
    );
}
export default observer(DevisClient);


