import { useContext } from 'react';
import { Tab, Grid, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import NotFound from '../../../app/layout/NoFound';
import DevisDuplicationInformations from './DevisDuplicationInformations';
import DevisEditForm from './DevisEditForm';
import FormGridColumn from '../../../app/common/form/FormGridColumn';

const DevisDescription = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { devis, editDevis, submitting } = baseRepo.devisStore;
    if (!devis) {
        return <NotFound />
    }
    else {
        return (
            <Tab.Pane>
                <Grid>
                    <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                        {devis!.quoteState == 'EnAttente' &&
                            <Button
                                onClick={() => { baseRepo.devisStore.changeButtonState(!baseRepo.devisStore.editDevisMode) }}
                                floated='right'
                                basic
                                content={baseRepo.devisStore.editDevisMode ? 'Annuler' : 'Modifier'}
                            />
                        }
                    </Grid.Column>
                    <Grid.Column width={16}>
                        {baseRepo.devisStore.duplicationMode ? (
                            <DevisDuplicationInformations />
                        ) : (
                            baseRepo.devisStore.editDevisMode ? (
                                <DevisEditForm editDevis={editDevis} devis={devis!} submitting={submitting} />
                            ) : (
                                <Grid className="ui stackable">
                                    <Grid.Row style={{ padding: 0, margin: 0 }}>
                                        <FormGridColumn header="Client" content={devis!.companyName}
                                            root={devis!.companyType == "Franchiseur" ? `/detailFranchiseur/${devis.companyId}` : `/detailClient/${devis.companyId}`} />
                                        <FormGridColumn header="Date Devis" content={new Date(devis!.quoteDate).toLocaleDateString('en-GB')} />
                                        <FormGridColumn header="Date exp demandée" content={devis!.validationDate ? new Date(devis!.validationDate).toLocaleDateString('en-GB') : "non introduite"} />
                                        <FormGridColumn header="Transport" content={devis!.deliveryCompany} />
                                        <FormGridColumn header="Livraison" content={devis!.singleDelivery} />
                                        <FormGridColumn header="Montant HT" content={devis!.amountWithoutTaxes} />
                                        <FormGridColumn header="Montant TTC" content={devis!.amountWithTaxes} />
                                        <FormGridColumn header="Montant Restant" content={(devis!.amountWithTaxes - devis!.depositeAmount).toFixed(2)} />
                                        <FormGridColumn header="Commentaires" content={devis!.comment} />
                                        <FormGridColumn header="Transporteur" content={devis!.deliveryService} />
                                    </Grid.Row>
                                </Grid>
                            )
                        )}
                    </Grid.Column>
                </Grid>
            </Tab.Pane>
        );
    }
}
export default observer(DevisDescription);