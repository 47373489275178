import React, { useContext, useEffect, useState } from 'react';
import { GridColumn, Grid, Loader, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import ListFranchiseur from './listFranchiseur';
import FranchiseuFilter from './FranchiseurFilter';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';
import ListItemPlaceholder from '../../../app/common/form/ListItemPlaceholder';
import Breadcrumbs from '../../dashboard/breadcrumbs';
import { FaFileExcel } from 'react-icons/fa';

const FranchiseurDashboard: React.FC = () => {

    const baseStore = useContext(BaseStoreContext);
    const { loadFranchiseurs, loadingFranchideurs, page, totalPages, setPage, ExportFranchisseur, ExportCompanies, exporting, exportingAll } = baseStore.franchiseurStore;
    const [loadingNext, setLoadingNext] = useState(false);
    const handleGetNext = () => {
        setLoadingNext(true);
        setPage(page + 1);
        loadFranchiseurs().then(() => setLoadingNext(false));
    }
    const handleDownload = () => {
        ExportFranchisseur();
    }

    const handleDownloadAll = () => {
        ExportCompanies();
    }

    useEffect(() => {
        loadFranchiseurs();
    }, []);

    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Tiers", root: "" },
                    { key: 1, name: "Réseaux", root: "/franchiseurDashboard" },
                ]}
            />
            <Grid>
                <GridColumn width={10} style={{ marginTop: 2 }}>
                    <Grid style={{ marginTop: 2 }}>
                        <GridColumn width={16}>
                            {loadingFranchideurs && page === 0 ? <ListItemPlaceholder /> : (
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={handleGetNext}
                                    hasMore={!loadingNext && page + 1 < totalPages}
                                    initialLoad={false}
                                >
                                    <ListFranchiseur />
                                </InfiniteScroll>
                            )}
                        </GridColumn>
                    </Grid>
                </GridColumn>
                <Grid.Column width={6} >
                    <Grid.Column width={16}>
                    </Grid.Column>
                    <Button
                        style={{ marginTop: 20 }}
                        loading={exporting}
                        content="Export excel"
                        icon={(<FaFileExcel />)}
                        onClick={handleDownload}
                        color="green"
                    />
                    <Button
                        style={{ marginTop: 20 }}

                        content="Export Tous les réseaux"
                        icon={(<FaFileExcel />)}
                        loading={exportingAll}
                        onClick={handleDownloadAll}
                        color="blue"
                    />
                    <Button
                        style={{ marginTop: 20 }}
                        content="Ajouter Franchiseur"
                        color="orange"
                        icon='add circle'
                        as={Link}
                        to={`/addFranchiseur`}

                    />
                    <Button
                        content="Importer client"
                        color="green"
                        as={Link}
                        to={`/addFranchise`}

                    />  
                    <FranchiseuFilter />

                </Grid.Column>

                <Grid.Column width={16}>
                    <Loader active={loadingNext} />
                </Grid.Column>
            </Grid>
        </div>
    );
}
export default observer(FranchiseurDashboard);