import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { combineValidators, composeValidators, createValidator, isRequired } from 'revalidate';
import { Button, Header, Icon, Container, Form, Grid } from 'semantic-ui-react';
import TextInput from '../../../app/common/form/inputText';
import { IProductPriceAdd } from '../../../app/models/ITarifFranchiseur';
import { BaseStoreContext } from '../../../app/store/baseStore';

interface IProps {
    agencyName: string;
    companyName: string;
    product: number;
}
        
const isPositive = createValidator(
    message => value => {
        if (value && !/^[1-9][0-9]*$/.test(value)) {
            return message;
        }
    },
    'Invalid number'
);
const isFloat = createValidator(
    message => value => {
        if (value && !/^[+]?\d+(\.\d+)?$/i.test(value)) {
            return message;
        }
    },
    'Invalid number'
);
const validate = combineValidators({
    unitPrice: composeValidators(
        isRequired, isFloat({ message: "Veuillez saisir un valeur positif " }))({
            message: 'Veuillez saisir le prix'
        }),
    quantity: composeValidators(
        isRequired, isPositive({ message: "Veuillez saisir un valeur valid " }))({
            message: 'Veuillez saisir la quantité'
        }),
});


const AddProductPriceModal: React.FC<IProps> = ({ agencyName, companyName, product}) => {
    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    const { add } = baseStore.tarifFranchiseurStore;

    const handleFinalFormSubmit = (values: any) => {

        //if (tarifFranchiseurs.find(x => x.quantite == values.quantite && x.typeFranchiseur == "Franchiseur" && x.companyId == article.companyId)) {
        //    toast.warning("Quantite déja existe pour Type client selectionné");
        //}
        //else {
        let newTraif: IProductPriceAdd = {
            ...values,
            product: product,
            agencyName: agencyName,
            companyName: companyName,
            };
        add(newTraif);
        closeModal();
      /*  }*/
    }
    return (
        <Container textAlign='center'>
            <Header icon='add' content={`Ajouter tarif tarif Franchiseur`} style={{ marginLeft: 20 }} />
            <b>{agencyName}-{companyName}</b>
            <Grid>
            <FinalForm
                onSubmit={handleFinalFormSubmit}
                validate={validate}
                render={({ handleSubmit, invalid, pristine, submitting }) => (
                    <Form onSubmit={handleSubmit} error>
                        <Grid columns={2} >
                            <Grid.Column>
                                <span>Tarif unitaire</span>
                                <Field name='unitPrice' component={TextInput} type='number' />
                            </Grid.Column>
                            <Grid.Column>
                                <span>Quantité</span>
                                <Field name='quantity' component={TextInput} type='number' />
                            </Grid.Column>
                        </Grid>
                        <div style={{ marginTop: '30px' }}>
                            <Button.Group floated="right">
                                <Button disabled={submitting} onClick={() => { closeModal() }} floated='right' type='button'>Annuler</Button>
                                <Button.Or />
                                <Button positive disabled={submitting || invalid || pristine} loading={submitting} floated='right' type='submit'>Ajouter</Button>
                            </Button.Group>
                        </div>
                    </Form >
                )}
            />
           </Grid>
        </Container>
    )
}
export default observer(AddProductPriceModal);