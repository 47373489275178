import { BaseStore } from "./baseStore";
import { observable, action, computed, runInAction, reaction, toJS } from 'mobx';
import { ICommande, ICommandeList, ICompanyOrder, IOrder, IOrderLine, ISupplier, ISupplierList, OrderCompanyDto } from '../models/ICommande'
import agent from '../agent/agent';
import { toast } from "react-toastify";
import { setCommandeProps } from "../common/util/util";
import { history } from '../../index';
import { IListRelatedElements } from "../models/IFacture";

const LIMIT = 20;

export default class CommandeStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0;
                this.loadCommandes();
            }
        )
        reaction(
            () => this.predicateCmdAtt.keys(),
            () => {
                this.page = 0;
                this.ListCommande();
            }
        )
        reaction(
            () => this.activeTab,
            activeTab => {
                if (activeTab != '')
                    return true;
            }
        )
    }
    @observable loadingInitial = false;
    @observable loadingExport = false;
    @observable loadingUploadProforma = false;
    @observable addingArticle = false;
    @observable deletingCommandeArticle = false;
    @observable loadingCommande = false;
    @observable loadingCommandes = false;
    @observable loadingCommandeCompany = false;
    @observable loadingOrderLine = false;
    @observable submitting = false;
    @observable loadBtnCancel = false;
    @observable editCommandeMode = false;
    @observable editCommandeState = false;
    @observable generatePdfMode = false;
    @observable commandes: IOrder[] = [];
    @observable commandesList: IOrder[] = [];
    @observable commandesListCount: number = 0;
    @observable commandesCompany: OrderCompanyDto[] = [];
    @observable companyOrders: ICompanyOrder[] = [];
    @observable commande: ICommande | null = null;
    @observable orderline: IOrderLine | null = null;
    @observable commandeRegestry = new Map();
    @observable orderLineRegestry = new Map();
    @observable commandesCount: number = 0;
    @observable commandesFiltreCount: number = 0;
    @observable commandesHTCount: number = 0;
    @observable notificationCommande: number = 0;
    @observable predicate = new Map();
    @observable predicateCmdAtt = new Map();
    @observable page = 0
    @observable pageCmdAttente = 0
    @observable activeTab: string = "0";
    @observable progress: number = 50;
    @observable duplicationMode = false;
    @observable articleWithModeAppro: boolean = false;
    @observable relatedElementsList: IListRelatedElements[] = [];
    @observable deletingOrder = false;
    @observable articleFournisseurs: ISupplierList[] = [];
    @observable ItListCommande: ICommandeList[] = [];
    @observable customOrderlineList: IOrderLine[] = [];
    @observable totalTtc: number = 0;
    @observable totalHt: number = 0;


    @computed get axiosParams() {
        const params = new URLSearchParams();
        params.append('limit', String(LIMIT));
        params.append('offset', `${this.page ? this.page * LIMIT : 0}`);     
        params.append('offsetCmd', `${this.pageCmdAttente ? this.pageCmdAttente * LIMIT : 0}`);
        this.predicate.forEach((value, key) => {
            if (key === 'startDate') {
                params.append(key, value.toISOString())
            }
            if (key === 'searchMultiple') {
                var searchClient = [...value][0];
                var article = [...value][1];
                var search = [...value][2];
                var Key = [...value][3];
                var sort = [...value][4];
                var dateCommande = [...value][5];
                var dateCommandeTo = [...value][6];
                var montant = [...value][7];
                var modePaiement = [...value][8];
                var payment = [...value][9];
                var searchClientAll = [...value][10];
                params.append(searchClient[0], searchClient[1])
                params.append(article[0], article[1])
                params.append(search[0], search[1])
                params.append(Key[0], Key[1])
                params.append(sort[0], sort[1])
                params.append(dateCommande[0], dateCommande[1])
                params.append(dateCommandeTo[0], dateCommandeTo[1])
                params.append(montant[0], montant[1])
                params.append(modePaiement[0], modePaiement[1])
                params.append(payment[0], payment[1])
                params.append(searchClientAll[0], searchClientAll[1])
            } else {
                params.append(key, value)
            }
        })
        this.predicateCmdAtt.forEach((value, key) => {
            if (key === 'startDate') {
                params.append(key, value.toISOString())
            }
            if (key === 'searchMultiple') {
                var searchClient = [...value][0];
                var article = [...value][1];
                var search = [...value][2];
                var Key = [...value][3];
                var sort = [...value][4];
                var dateCommande = [...value][5];
                var dateCommandeTo = [...value][6];
                var montant = [...value][7];
                var modePaiement = [...value][8];
                var payment = [...value][9];
                var searchClientAll = [...value][10];
                params.append(searchClient[0], searchClient[1])
                params.append(article[0], article[1])
                params.append(search[0], search[1])
                params.append(Key[0], Key[1])
                params.append(sort[0], sort[1])
                params.append(dateCommande[0], dateCommande[1])
                params.append(dateCommandeTo[0], dateCommandeTo[1])
                params.append(montant[0], montant[1])
                params.append(modePaiement[0], modePaiement[1])
                params.append(payment[0], payment[1])
                params.append(searchClientAll[0], searchClientAll[1])
            } else {
                params.append(key, value)
            }
        })
        return params;
    }


    @computed get totalPages() {
        return Math.ceil(this.commandesCount / LIMIT);
    }


    @action setPage = (page: number) => {
        this.page = page;
    }
    @action setPageCmdAttente = (page: number) => {
        this.pageCmdAttente = page;
    }
    @computed get totalPagesCmdAttente() {
        return Math.ceil(this.commandesListCount / LIMIT);
    }

    @action setPredicate = (predicate: string, value: any) => {
        if (predicate != 'all')
            this.commandes = [];
        this.predicate.clear();
        if (predicate !== 'searchMultiple') {
            this.predicate.clear();
        }
        if (predicate !== 'all') {
            this.predicate.set(predicate, value);
        }
    }
    @action setPredicateCmdAtt = (predicate: string, value: any) => {
        if (this.predicateCmdAtt.get(predicate)) {
            this.commandesList = [];
            this.predicateCmdAtt.clear();
        }
        if (predicate == 'all') {
            this.predicateCmdAtt.clear();
        }
        else {
            this.predicateCmdAtt.set(predicate, value);
        }
    }
 
    getCommande = (id: number) => {
        return this.commandeRegestry.get(id);
    }
    getOrderLine = (id: number) => {
        return this.orderLineRegestry.get(id);
    }


    @action changeButtonState = async (newState: boolean) => {
        this.editCommandeMode = newState;
    }

    @action changeButtonPdfState = async (newState: boolean) => {
        this.generatePdfMode = newState;
    }

    @action changeDuplicationState = async (newState: boolean) => {
        this.duplicationMode = newState;
    }

    @action filterOrdersNotBilled = async () => {
        this.companyOrders = this.companyOrders.filter(o => o.isBilled == false);
    }

    @action loadCommandes = async () => {
        this.articleWithModeAppro = false;
        this.loadingInitial = true;
        try {
            const commandeEnveloppe = await agent.commandeAgent.list(this.axiosParams);
            const { commandes, commandesCount, commandesFiltreCount,commandesHTCount } = commandeEnveloppe;
            runInAction(() => {
                commandes.forEach((commande) => {
                    if (this.page == 0) {
                        this.commandes = commandes;
                    } else {
                        this.commandes.push(commande);
                    }
                })
                this.notificationCommande = commandes.filter(x => x.isNotRead).length;
                this.commandesCount = commandesCount;
                this.commandesFiltreCount = commandesFiltreCount;
                this.commandesHTCount = commandesHTCount;
                this.loadingInitial = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingInitial = false;
            })
            console.log(e);
        }
    }
    @action ListCommande = async () => {
        this.loadingCommandes = true;
        try {
            var commandesEnveloppe = await agent.commandeAgent.getCommandeList(this.axiosParams);
            const { commandesList, commandesListCount, totalHt,totalTtc } = commandesEnveloppe;
            runInAction(() => {
                this.loadingCommandes = false;
                commandesList.forEach((commande) => {
                    if (this.pageCmdAttente == 0) {
                        this.commandesList = commandesList;
                    } else {
                        this.commandesList.push(commande);
                    }
                })
                this.commandesListCount = commandesListCount;
                this.totalTtc = totalTtc;
                this.totalHt = totalHt;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingCommandes = false;
            })
            console.log(e);
        }
    }
    @action create = async (values: Partial<ICommande>) => {
        this.submitting = true;
        try {
            var idCommande = await agent.commandeAgent.create(values);
            runInAction(() => {
                this.submitting = false;
                toast.success("ajout effectué avec succès");
            })
            history.push(`/detailCommande/${idCommande}`);
        } catch (e) {
            toast.error("Problème d'ajout d'une commande!");
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    @action dupliquer = async (id: number) => {
        this.submitting = true;
        try {
            const idCommande = await agent.commandeAgent.duplicate(id);
            runInAction(() => {
                this.loadCommande(idCommande + "")
                this.submitting = false;
                toast.success("commande dupliquée avec succès");
            })
            history.push(`/detailCommande/${idCommande}`);
        } catch (e) {
            toast.error("Problème de duplication!");
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    @action loadCommande = async (id: string) => {
        this.loadingCommande = true;
        this.commande = null;
        try {
            let commande = await agent.commandeAgent.details(id);
            runInAction(() => {
                this.commande = commande;
                this.loadingCommande = false;
                this.editCommandeState = this.commande!.orderState == 'Validated';
            })
        } catch (e) {
            runInAction(() => {
                this.loadingCommande = false;
            })
            console.log(e);
        }
    }

    @action editCommande = async (values: Partial<ICommande>) => {
        this.submitting = true;
        try {
            var commande=await agent.commandeAgent.update(values);
            runInAction(() => {
                this.commandeRegestry.set(values.id, values);
                this.commande = { ...commande, ...values };
                this.submitting = false;
                this.changeButtonState(false);
                this.loadingInitial = true;
                toast.success("Commande modifié avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action editAdressesCommande = async (values: Partial<ICommande>) => {
        this.submitting = true;
        try {
            await agent.commandeAgent.updateAdresseCommande(values);
            runInAction(() => {
                this.commandeRegestry.set(values.id, values);
                this.commande = { ...this.commande!, ...values };
                this.submitting = false;
                this.changeButtonState(false);
                this.loadingInitial = true;
                this.loadingCommande = true;
                toast.success("Commande modifiée avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    };

    @action editInformationClientCommande = async (values: Partial<ICommande>) => {
        this.submitting = true;
        try {
            await agent.commandeAgent.updateInformationClientCommande(values);
            runInAction(() => {
                this.commandeRegestry.set(values.id, values);
                this.commande = { ...this.commande!, ...values };
                this.submitting = false;
                this.changeButtonState(false);
                this.loadingInitial = true;
                this.loadingCommande = true;
                toast.success("Commande modifiée avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    };

    @action clearActivity = () => {
        this.commande = null;
    };

    @action setActiveTab = async (activeIndex: string) => {
        runInAction(() => {
            this.activeTab = activeIndex;
        })
    }

    @action isRead = async (id: string) => {
        try {
            await agent.commandeAgent.isRead(id);
            runInAction(() => {
                this.commande!.isNotRead = false;
            })
        } catch (e) {
            console.log(e);
            runInAction(() => {
            })
        }
    }

    @action addArticle = async (orderLine: IOrderLine, idCommande: number, modeApro: string, belongToOpeningPack: boolean) => {
        this.addingArticle = true;
        try {
            const prices = await agent.commandeAgent.addOrderLine(orderLine, idCommande);
            runInAction(() => {
                this.articleWithModeAppro = modeApro == "Stock";
                this.editCommandeMode = false;
                prices.orderLines.forEach((item) => {
                    this.commande!.orderLines.push(item);
                });
                this.commande!.amountWithoutTaxes = prices.ht;
                this.commande!.amountWithTaxes = prices.ttc;
                this.commande!.deliveryCosts = prices.deliveryCost;
                this.commande!.tvaPrices = prices.tvaPrices;
                if (belongToOpeningPack) this.commande!.hasOpeningPackProduct++;
                this.addingArticle = false;
            })
        }
        catch (e) {
            console.log(e);
            toast.error("Problème de mise à jour article!");
            runInAction(() => {
                this.addingArticle = false;
            })
        }
    }

    @action deleteCommandeArticel = async (idOrder: number, OrderLine: IOrderLine) => {
        this.deletingCommandeArticle = true;
        try {
            const prices = await agent.commandeAgent.deleteCommandeArticle(OrderLine.id);
            runInAction(() => {
                this.articleWithModeAppro = prices.articleWithModeAppro;
                this.commande!.orderLines = this.commande!.orderLines.filter(x => x.id !== OrderLine.id);
                this.commande!.amountWithoutTaxes = prices.ht;
                this.commande!.amountWithTaxes = prices.ttc;
                this.commande!.deliveryCosts = prices.deliveryCost;
                this.commande!.tvaPrices = prices.tvaPrices;
                if (this.commande!.amountWithoutTaxes == 0)
                    this.commande!.orderState = "WaitingValidation";
                if (OrderLine.belongToOpeningPack) this.commande!.hasOpeningPackProduct--;
                this.deletingCommandeArticle = false;
                toast.success("ligne de commande supprimée avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Probléme de suppression!");
            runInAction(() => {
                this.deletingCommandeArticle = false;
            })
        }
    }

    @action addRemiseGlobal = async (values: Partial<ICommande>, typeRemise: string) => {
        this.submitting = true;
        try {
            await agent.commandeAgent.remiseGlobale(values, typeRemise);
            runInAction(() => {
                let commandeDiscount = values.discount!;
                if (typeRemise === 'pourcentage')
                    commandeDiscount = (values.discount! * values!.amountWithoutTaxes!) / 100;

                values!.amountWithoutTaxes! -= commandeDiscount;
                this.commande = { ...this.commande!, ...values };
                this.submitting = false;
                this.changeButtonState(false);
                this.loadingInitial = true;
                toast.success("Remise ajoutée avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    };

    @action editOrderLine = async (id: number, values: IOrderLine) => {
        this.submitting = true;
        this.progress = 80;
        try {
            const prices = await agent.commandeAgent.updateOrderLine(id, values);
            runInAction(() => {
                this.submitting = false;
                this.commande!.orderLines[this.commande!.orderLines.findIndex(x => x.id == values.id)] = values;
                this.commande!.amountWithoutTaxes = prices.ht;
                this.commande!.amountWithTaxes = prices.ttc;
                this.commande!.deliveryCosts = prices.deliveryCost;
                this.commande!.tvaPrices = prices.tvaPrices;
                this.commandeRegestry.set(values.id, values);
                toast.success("Ligne de commande modifié avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    //@action loadOrderLine = async (id: number) => {
    //    this.loadingOrderLine = true;
    //    let orderLine = this.getOrderLine(id);
    //    if (orderLine) {
    //        this.orderline = orderLine;
    //        this.loadingOrderLine = false;
    //        return toJS(orderLine);
    //    }
    //    else {
    //        try {
    //            orderLine = await agent.commandeAgent.detailOrderLine(id);
    //            runInAction(() => {
    //                this.orderline = orderLine;
    //                this.orderLineRegestry.set(orderLine.id, orderLine);
    //                this.loadingOrderLine = false;
    //            })
    //            return orderLine;
    //        } catch (e) {
    //            runInAction(() => {
    //                this.loadingOrderLine = false;
    //            })
    //            console.log(e);
    //        }
    //    }
    //}
    @action addAcompte = async (values: Partial<ICommande>) => {
        this.submitting = true;
        try {
            await agent.commandeAgent.acompte(values);
            runInAction(() => {
                this.commande = { ...this.commande!, ...values };
                this.submitting = false;
                this.changeButtonState(false);
                this.loadingInitial = true;
                toast.success("Montant Acompte ajouté avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    };

    @action addDevis = async (values: Partial<ICommande>) => {
        this.submitting = true;
        try {
            await agent.commandeAgent.createDevis(values);
            runInAction(() => {
                this.commandeRegestry.set(values.id, values)
                this.submitting = false;
                toast.success("ajout effectué avec succès");
            })
            history.push(`/devisDashboard`);
        } catch (e) {
            toast.error("Problème d'ajout d'un devis!");
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    @action ExportCommandes = async (obj: any) => {
        this.loadingExport = true;
        try {
            await agent.commandeAgent.exportExcel(obj);
            runInAction(() => {
                this.loadingExport = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingExport = false;
            })
            console.log(e);
        }
    }

    //@action loadCommandeArticleEnModeAppro = async (id: number) => {
    //    let result = false;
    //    try {
    //        result = await agent.commandeAgent.detailsCommandeByArticleEnModeAppro(id);
    //        runInAction(() => {
    //            this.articleWithModeAppro = result;
    //        })
    //        return result;
    //    } catch (e) {
    //        runInAction(() => {
    //            return result;
    //        })
    //        console.log(e);
    //    }
    //}
    @action generatePDF = async (id: string) => {
        try {
            await agent.commandeAgent.generatePDF(id);
        }
        catch (e) {
            console.log(e);
        }
    }
    @action enAttente = async (id: number) => {
        this.deletingCommandeArticle = true;
        try {
            await agent.commandeAgent.enAttente(id);
            runInAction(() => {
                this.deletingCommandeArticle = false;
                this.commande!.orderState = "WaitingValidation";
                this.editCommandeState = false;
                toast.success("Commande En Attente");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de mettre la commande en attente!");
            runInAction(() => {
                this.deletingCommandeArticle = false;
            })
        }
    }

    @action isValide = async (id: number) => {
        this.deletingCommandeArticle = true;
        try {
            var ref = await agent.commandeAgent.isValide(id);
            runInAction(() => {
                this.articleWithModeAppro = this.commande?.orderLines.filter(x => x.modeAppro == "Stock").length != 0;
                this.deletingCommandeArticle = false;
                this.commande!.orderState = "Validated";
                this.commande!.reference = ref + '';
                this.editCommandeState = true;
                toast.success("Commande Valide");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de validation du commande!");
            runInAction(() => {
                this.deletingCommandeArticle = false;
            })
        }
    };
    @action CommandeFacture = async (id: number) => {
        this.submitting = true;
        try {
            var factureId = await agent.commandeAgent.generateFacture(id);
            runInAction(() => {
                this.submitting = false;
                toast.success("transformation faite avec succès");
            })
            history.push(`/detailFacture/${factureId}`);
        } catch (e) {
            toast.error("Problème de generation de facture!");
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    @action getRelatedObjectForCommande = async (id: number) => {
        this.submitting = true;
        this.relatedElementsList = [];
        try {
            var result = await agent.factureAgent.relatedObject(id, "Commande");
            runInAction(() => {
                this.relatedElementsList = result;
                this.submitting = false;

                })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
                this.activeTab = '0';
            })
            toast.error("Aucun objet liés");
        }
    }

    @action deleteOrder = async (id: number) => {
        this.deletingOrder = true;
        try {
            await agent.commandeAgent.deleteOrder(id);
            runInAction(() => {
                this.deletingOrder = false;
                this.commandes = this.commandes.filter(a => a.id !== id);
                toast.success("suppression éffectuée avec succès");
            })
        } catch (e) {
            toast.error("Problème de suppresion !");
            runInAction(() => {
                this.deletingOrder = false;
            })
        }
    }

    @action loadArticleFournisseurList = async (id: number) => {
        this.loadingInitial = true;
        this.articleFournisseurs = [];
        try {
            const tarifFournisseur = await agent.commandeAgent.getFournisseurByArticle(id);
            runInAction(() => {
                this.articleFournisseurs = tarifFournisseur;
                this.loadingInitial = false;
            })
            return tarifFournisseur;
        } catch (e) {
            toast.error("Problem loading!");
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }

    @action createOrderForunisseur = async (values: Partial<ISupplier>) => {
        this.submitting = true;
        try {
            var id = await agent.commandeAgent.genererOrderFournisseur(values);
            runInAction(() => {
                this.submitting = false;
                toast.success("ajout effectué avec succès");
            })
            history.push(`/commandeFournisseurDashboard`);
        } catch (e) {
            toast.error("Problème d'ajout d'une commande fournisseur!");
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    @action generateOpeningPack = async (id: number) => {
        this.submitting = true;
        try {
            await agent.commandeAgent.generatePackOuverture(id);
            runInAction(() => {
                this.submitting = false;
                toast.success("Commande génerer avec succès");
            })
            history.push(`/commandeDashboard`);
        } catch (e) {
            toast.error("Problème d'ajout d'une commande fournisseur!");
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }
    @action canceled = async (id: number) => {
        this.loadBtnCancel = true;
        try {
            await agent.commandeAgent.cancel(id);
            runInAction(() => {

                this.commande!.isCanceled = true;
                toast.success("commande désactivé avec succès");
            })
            this.loadBtnCancel = false;

        } catch (e) {
            runInAction(() => {
                this.loadBtnCancel = false;
            })
        }
    }

    @action actived = async (id: number) => {
        this.loadBtnCancel = true;
        try {
            await agent.commandeAgent.cancel(id);
            runInAction(() => {
                this.commande!.isCanceled = false;
                toast.success("commande activé avec succès");
            })
            this.loadBtnCancel = false;

        } catch (e) {
            runInAction(() => {
                this.loadBtnCancel = false;
            })
        }
    }
    @action factureListOrders = async (ids: number[], type: string) => {
        try {
            await agent.commandeAgent.generateFactureByListOrders(ids, type);

        } catch (e) {
            toast.error("Problème d'ajouts de liste select  !");
        }
    }
    @action dispatchOrder = async (id: number) => {
        try {
            await agent.commandeAgent.dispatchOrder(id);
            toast.success("articles expédier avec succès");
            history.push(`/expeditionDashboard`)
        }
        catch (e) {
            console.log(e);
        }
    }
    @action editDeliveryCosts = async (obj: Partial<ICommande>) => {
        try {
            const prices = await agent.commandeAgent.editDeliveryCost(obj);
            runInAction(() => {
                this.commande!.amountWithoutTaxes = prices.ht;
                this.commande!.amountWithTaxes = prices.ttc;
                this.commande!.deliveryCosts = prices.deliveryCost;
                this.commande!.tvaPrices = prices.tvaPrices;
            })
            toast.success("Frais de port a été changé");
        }
        catch (e) {
            toast.error("Erreur de changement Frais de port")
        }
    }

    @action editModeAppro = async (obj: any) => {
        this.submitting = true;
        try {

            await agent.commandeAgent.updateModeApproProduct(obj);
            runInAction(() => {
                this.submitting = false;
                toast.success("Mode appro a été changé");
            })
        } catch (e) {
            toast.error("Erreur de changement de mode appro  !");
            runInAction(() => {
                this.submitting = false;
            })
        }
    }
    @action loadCommandesByCompany = async (id: number) => {
        this.loadingCommandeCompany = true;
        try {
            const commandes = await agent.commandeAgent.getCommandeByCompany(id);
            runInAction(() => {

                this.commandesCompany = commandes;
                commandes.filter(x => x.paymentState !="Payé").forEach((order) => {
                    let orders: ICommandeList = {
                        key: order.id,
                        text: order.reference,
                        value: order.id.toString(),
                    }
                    this.ItListCommande.push(orders)
                })
                this.loadingCommandeCompany = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingCommandeCompany = false;
            })
            console.log(e);
        }
    }

    @action GetCustomizedFileByOrder = async (id: number) => {
        this.customOrderlineList = [];
        try {
            var res = await agent.commandeAgent.getCustomizedORderLines(id);
            runInAction(() => {
                this.customOrderlineList = res;
            })
        } catch (e) {
            console.log(e);
        }
    }

    @action GetCustomizedFilePDFAzure = async (id: number, face: string) => {
        try {
            var res = await agent.commandeAgent.getCustomizedPDF(id, face);
        } catch (e) {
            console.log(e);
        }
    }

    @action loadCompanyOrders = async (id: number) => {
        this.loadingCommandeCompany = true;
        this.companyOrders = []
        try {
            const commandes = await agent.commandeAgent.getCompanyOrders(id);
            runInAction(() => {
                this.companyOrders = commandes;
                this.loadingCommandeCompany = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingCommandeCompany = false;
            })
            console.log(e);
        }
    }
   
    @action ExportCommandesAttente = async (obj: any) => {
        this.loadingExport = true;
        try {
            await agent.commandeAgent.exportExcelCmdAttente(obj);
            runInAction(() => {
                this.loadingExport = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingExport = false;
            })
            console.log(e);
        }
    }


    @action UploadProforma = async (id: number) => {
        this.loadingUploadProforma = true;
        try {
            await agent.commandeAgent.uploadProforma(id);
            runInAction(() => {
                this.loadingUploadProforma = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingUploadProforma = false;
            })
            console.log(e);
        }
    }
}