import { useContext, useEffect, useState } from 'react';
import { Button, Form, FormInput, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import AsyncSelect from 'react-select/async';
import Select, { components } from "react-select";
import { FaSort, FaSearch, FaFileExcel } from 'react-icons/fa';
import { ITListSort } from '../../../app/common/options/IAchat';
import { Form as FinalForm } from 'react-final-form';
import { MdCancel } from 'react-icons/md';

const style = {
    option: (provided: any, state: { isSelected: any; }) => ({
        ...provided,
    }),

}
const customStyles = {
    option: (provided: any, state: { isSelected: any; }) => ({
        ...provided,
        color: state.isSelected ? 'orange' : 'grey',
        backgroundColor: state.isSelected ? "#eee" : "white",
        borderBottom: '1px solid #eee',
    }),
    valueContainer: (props: any) => ({
        ...props,
        paddingTop: 0,
        height: '39.10px',
        minHeight: '39.10px',
    }),
    control: (baseStyles: any, state: any) => ({
        ...baseStyles,
        height: '39.10px',
        minHeight: '39.10px',
    }),
}
const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <FaSort color='green' />
        </components.DropdownIndicator>
    );
};
const ArticleServiceFilter = () => {
    const baseStore = useContext(BaseStoreContext);
    const { setPredicateService, ExportArticleService, loadingExportSer } = baseStore.articleStore;
    const { loadFournisseurs } = baseStore.fournisseurStore;
    const [searchResults, setSearchResults] = useState([]);
    const [selectedValue, setSelectedValue] = useState<any>([]);
    const [selectedLabelTri, setSelectedTri] = useState<any>('Trier');
    const [selectedSort, setSelectedSort] = useState<any>('');
    const [externalReference, setExternalReference] = useState<any>([]);
    const [codeComptable, setCodeComptable] = useState<any>([]);
    const [fournisseur, setFournisseur] = useState<any>(0);
    useEffect(() => {
        loadFournisseurs();
    }, [loadFournisseurs])

    const options = [
        { key: 'All', label: 'Tous' },
        { key: 'IsEnabled', label: 'Actif' },
        { key: 'IsDisabled', label: 'Non actif' },
        { key: 'IsPack', label: 'Pack' },
        { key: 'OnStock', label: 'En stock' },
    ];
    const handleChange = (event: any) => {
        setSearchResults(event.target.value);
    }
    const handleChangeExRef = (event: any) => {
        setExternalReference(event.target.value);
    }
        const handleChangeCodeComptable = (event: any) => {
            setCodeComptable(event.target.value);
    }


    const handleChangeTri = (value: any) => {
        setSelectedSort(value.value);
        let labelSelected: any = value.text;
        setSelectedTri(labelSelected);
    }
    const handleSearch = (event: any) => {
        if (event == 'clique') {
            let obj: any = {
                search: searchResults,
                sort: selectedSort,
                searchByFranchiseur: selectedValue,
                fournisseur: fournisseur,
                codeComptable: codeComptable,
                externalReference: externalReference
            }; ExportArticleService(obj);
        }
        else if (event == 'clear') {
            setSelectedValue('')
            setExternalReference([])
            setSelectedSort('')
            setSelectedTri('Trier')
            setSearchResults([])
            setCodeComptable([])
            setFournisseur(0)
            setPredicateService('all', 'true')
        }
        else if (event == 'submit') {
            var predicate = new Map()
            predicate.set('search', searchResults)
            predicate.set('searchByFranchiseur', selectedValue)
            predicate.set('sort', selectedSort)
            predicate.set('fournisseur', fournisseur)
            predicate.set('externalReference', externalReference)
            predicate.set('codeComptable', codeComptable)
            if (searchResults.length > 2 || selectedValue!.length > 2 || selectedSort != '' || fournisseur != 0 || externalReference!.length > 2 || codeComptable!.length > 2) {
                setPredicateService('searchMultiple', predicate)

            }
        }

    }
    return (
        <FinalForm
            onSubmit={handleSearch}
            render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit} error style={{ width: "100%", fontSize: 14 }}>
                    <Grid>
                        <Grid.Row columns={5} style={{ padding: 0, margin: 0 }}>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <FormInput
                                    icon='search'
                                    type="text"
                                    placeholder="Rechercher Articles"
                                    value={searchResults}
                                    onChange={handleChange}
                                    style={{ width: '100%' }}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <FormInput
                                    icon='search'
                                    type="text"
                                    placeholder="Référence externe"
                                    value={externalReference}
                                    onChange={handleChangeExRef}
                                    style={{ width: '100%' }}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <FormInput
                                    icon='search'
                                    type="text"
                                    placeholder="Code comptable"
                                    value={codeComptable}
                                    onChange={handleChangeCodeComptable}
                                    style={{ width: '100%' }}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <Select
                                    isSearchable={false}
                                    components={{ DropdownIndicator }}
                                    styles={customStyles}
                                    options={ITListSort}
                                    value={selectedSort}
                                    getOptionLabel={e => e.text}
                                    onChange={(e) => handleChangeTri(e)}
                                    placeholder={selectedLabelTri} />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <Button style={{ margin: 0, color: 'blue', background: "none" }} type="submit" onClick={(e: any) => handleSearch('submit')}>
                                    <FaSearch />
                                </Button>
                                <Button style={{ padding: 5, fontSize: 13, border: 'none', backgroundColor: 'inherit', color: 'green' }} >
                                    <MdCancel style={{ fontSize: 17 }} onClick={(e: any) => handleSearch('clear')} />
                                </Button>
                                <Button
                                    size="tiny"
                                    loading={loadingExportSer}
                                    style={{ height: 32 }}
                                    floated='right'
                                    content="Export"
                                    className="andi-btn filled success"
                                    icon={(<FaFileExcel style={{ marginRight: "10px" }} />)}
                                    onClick={(e: any) => handleSearch('clique')}

                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                </Form >
            )}
        />
    );
};
export default observer(ArticleServiceFilter);
