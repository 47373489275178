import { observer } from "mobx-react-lite";
import { Container, Table } from "semantic-ui-react";
import { ISpecialOffer } from "../../../app/models/ISpecialOffer";

interface IProps {
    offer: ISpecialOffer
}

const OfferDetailModal: React.FC<IProps> = ({ offer }) => {
    return (
        <Container>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Quantité</Table.HeaderCell>
                        <Table.HeaderCell>Prix</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {offer.specialPrices.map(item => (
                        <Table.Row key={item.id}>
                            <Table.Cell>{item.minimumQuantity}</Table.Cell>
                            <Table.Cell>{item.unitPrice} €</Table.Cell>
                        </Table.Row>
                        ))}
                </Table.Body>
            </Table >
        </Container>
        );
}

export default observer(OfferDetailModal);