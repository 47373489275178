import React, { useContext, useEffect } from 'react';
import { GridColumn, Grid,Label } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../app/store/baseStore';
import Breadcrumbs from '../dashboard/breadcrumbs';
import ListBLToBeInvoiced from './ListBLToBeInvoiced';
import ExpeditionAFFiltre from './ExpeditionAFFiltre';
import ListItemPlaceholder from '../../app/common/form/ListItemPlaceholder';
import { numberFormat } from '../../app/common/options/IStyle';

const BlAFacturerDashboard: React.FC = () => {

    const baseStore = useContext(BaseStoreContext);
    const { loadExpeditionAFacturerList, loadingInitialExpeditionAF, totalHt, totalTtc } = baseStore.expeditionStore;



    useEffect(() => {
        loadExpeditionAFacturerList(null);
    }, []);

    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Vente", root: "" },
                    { key: 1, name: "Expéditions a facturer", root: "/bLAFacturer" },
                ]}
            />

            <Grid>
                <GridColumn width={16}>

                    <Grid.Row style={{ padding: 0, margin: "10px 20px 0 20px" }}>
                        <ExpeditionAFFiltre />
                    </Grid.Row>

                </GridColumn>
                <GridColumn width={16} style={{marginLeft:"15px"}}>
                    <Label>Total HT : {numberFormat.format(totalHt)}</Label>
                    <Label>Total TTC : {numberFormat.format(totalTtc)}</Label>
                </GridColumn>
                <GridColumn width={16} style={{ marginTop: "-15px" }}>
                    <Grid style={{ marginTop: 2 }}>
                        <GridColumn width={16}>
                            {loadingInitialExpeditionAF ? <ListItemPlaceholder /> :
                                <ListBLToBeInvoiced />
                            }
                        </GridColumn>
                    </Grid>
                </GridColumn>
            </Grid>
        </div>
    );
}
export default observer(BlAFacturerDashboard);