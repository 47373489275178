import { BaseStore } from "./baseStore";
import { observable, action, runInAction } from 'mobx';
import agent from '../agent/agent';
import { toast } from "react-toastify";
import { IArticleCompose, IArticleComposeList, IArticlePack } from "../models/IArticleCompose";
import { setArticleComposeProps } from "../common/util/util";

export default class ArticleComposeStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
    }
    @observable loadingPacksAction = false;
    @observable loadingStock = false;
    @observable loadingInitial = false;
    @observable submitting = false;
    @observable deletingPack = false;
    @observable pack: IArticleCompose | null = null;
    @observable packs: IArticleCompose[] = [];
    @observable packRegestry = new Map();
    @observable editPack: boolean = false;
    @observable addPack: boolean = false;
    @observable inPack: IArticlePack | null = null;
    @observable ITListPack: IArticleComposeList[] = [];

    @action changeButtonStateEdit = async (newState: boolean) => {
        this.editPack = newState;
    }

    @action changeButtonStateAdd = async (newState: boolean) => {
        this.addPack = newState;
    }

    @action loadListe = async () => {
        this.loadingInitial = true;

        try {
            const packs = await agent.articleComposeAgent.list();
            runInAction(() => {
                this.packs = packs;
                packs.forEach((pack) => {
                    setArticleComposeProps(pack);
                    this.packRegestry.set(pack.articleComposeId, pack);
                    let PACKS: IArticleComposeList = {
                        key: pack.articleComposeId,
                        text: pack.articleComposeId,
                        value: pack.articleComposeId
                    }
                    this.ITListPack.push(PACKS)
                })
                this.loadingInitial = false;
            })
        } catch (e) {
            toast.error("Problem loading pack!");
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }

    getStock = (id: number) => {
        return this.packRegestry.get(id);
    }

    @action create = async (values: IArticleCompose) => {
        this.submitting = true;
        try {
            await agent.articleComposeAgent.create(values);
            const article = await agent.articleAgent.details(values.article);
            runInAction(() => {
                this.packRegestry.set(values.articleComposeId, values)
                values.articleLibelle = article.label;
                this.pack = values;
                this.packs.push(this.pack);
                this.submitting = false;
                toast.success("ajout éffectué avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    @action edit = async (values: Partial<IArticleCompose>) => {
        this.submitting = true;
        try {
            await agent.articleComposeAgent.update(values);
            let pack = await agent.articleComposeAgent.details(values.articleComposeId!);
            runInAction(() => {
                this.packs = this.packs.filter(a => a.articleComposeId != values.articleComposeId);
                this.packs.push(pack);
                toast.success("Modification éffectuée avec succès");
                this.submitting = false;
            })

        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action loadById = async (id: number) => {

        this.loadingStock = true;
        let stocks = this.getStock(id);
        if (stocks) {
            this.pack = stocks;
            this.loadingStock = false;
            return stocks;
        }
        else {
            try {
                stocks = await agent.articleComposeAgent.details(id);
                runInAction(() => {
                    this.packRegestry.set(stocks.articleComposeId, stocks);
                    this.pack = stocks;
                    this.loadingStock = false;
                })
                return stocks;
            } catch (e) {
                runInAction(() => {
                    this.loadingStock = false;
                })
                console.log(e);
            }
        }
    }

    @action deletePacks = async (id: number) =>
    {
            this.loadingPacksAction = true;
            try {
                await agent.articleComposeAgent.delete(id);
                runInAction(() => {
                    this.loadingPacksAction = false;
                    this.packs = this.packs.filter(a => a.articleComposeId !== id);
                    toast.success("suppression éffectuée avec succès");
                })
            } catch (e) {
                console.log(e);
                toast.error("Problème de suppréssion !");
                runInAction(() => {
                    this.loadingPacksAction = false;
                })
            }
    }
    @action isInPack = async (id: string) =>
    {
        this.loadingInitial = true;
        try {
            const inPacks= await agent.articleComposeAgent.isInPack(id);
            runInAction(() => {
                this.inPack = inPacks;
                this.loadingInitial = false;
                })
            } catch (e) {
            console.log(e);
            runInAction(() => {
                this.loadingInitial = false;
            })
                toast.error("Problème !");
               
            }
    }
}