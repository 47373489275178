import { action, computed, observable, runInAction,reaction } from "mobx";
import { toast } from "react-toastify";
import agent from "../agent/agent";
import { setFamilleProps } from "../common/util/util";
import { IFamille, ITreeSousFamille } from "../models/IFamille";
import { BaseStore } from "./baseStore";

const LIMIT = 10;
export default class FamilleStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0;
                this.pageSF = 0;
                this.loadFamilles();

            }
        )
        reaction(
            () => this.predicateSF.keys(),
            () => {
                this.page = 0;
                this.pageSF = 0;
                this.loadSous_Famille(this.familleId);

            }
        )
    }
    @observable loadingFamilleAction = false;
    @observable loadingFamille = false;
    @observable loadingInitial = false;
    @observable submitting = false;
    @observable famille: IFamille|null = null;
    @observable familles: IFamille[] = [];
    @observable sousFamilles: IFamille[] = [];
    @observable editFamille: boolean = false;
    @observable addFamille: boolean = false;
    @observable progress: number = 50;
    @observable ITreeSousFamille: ITreeSousFamille[] = [];
    @observable predicate = new Map();
    @observable predicateSF = new Map();
    @observable page = 0
    @observable pageSF = 0
    @observable famillesCount = 0;
    @observable famillesSfCount = 0;
    @observable familleId: string = "";




    @computed get axiosParams() {
        const params = new URLSearchParams();
        params.append('limit', String(LIMIT));
        params.append('offset', `${this.page ? this.page * LIMIT : 0}`);
        this.predicate.forEach((value, key) => {
            if (key === 'startDate') {
                params.append(key, value.toISOString())
            
            } else {
                params.append(key, value)
            }
        })
        this.predicateSF.forEach((value, key) => {
                params.append(key, value)
        })
        return params;
    }
    @computed get totalPages() {
        return Math.ceil(this.famillesCount / LIMIT);
    }
    @computed get totalSFPages() {
        return Math.ceil(this.famillesSfCount / LIMIT);
    }


    @action setPage = (page: number) => {
        this.page = page;
    }
    @action setPageSF = (page: number) => {
        this.pageSF = page;
    }
    @action setPredicate = (predicate: string, value: any) => {
        this.predicate.clear();
        if (predicate !== 'all') {
            this.predicate.set(predicate, value);
        }
    }  
    @action setPredicateSF = (predicate: string, value: any, familleId: string) => {
        this.familleId = familleId;
        this.predicateSF.clear();
        if (predicate !== 'all') {
            this.predicateSF.set(predicate, value);
        }
    }
    @action changeButtonStateEdit = async (newState: boolean) => {
        this.editFamille = newState;
    }

    @action changeButtonStateAdd = async (newState: boolean) => {
        this.addFamille = newState;
    }

    @action loadFamilles = async () => {
        this.loadingInitial = true;
        try {
            const famillesEnvelope = await agent.familleAgent.list(this.axiosParams);
            const { familles, familleCount } = famillesEnvelope;
            runInAction(() => {
                familles.forEach((famille) => {
                    setFamilleProps(famille);
                    if (this.page == 0) {
                        this.familles = familles;
                    } else {
                        this.familles.push(famille);
                    }
                })
                this.famillesCount = familleCount;
                this.loadingInitial = false;
            })
        } catch (e) {
            toast.error("Problem loading du famille!");
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }


    @action create = async (values: IFamille) => {
        this.submitting = true;
        try {
            await agent.familleAgent.create(values);
            runInAction(() => {
                this.familles.push(values);
                this.submitting = false;
                toast.success("ajout éffectué avec succès");
            })

        } catch (e) {
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    @action edit = async (values: Partial<IFamille>) => {
        this.submitting = true;
        this.progress = 80;
        try {
            var famille = await agent.familleAgent.update(values);
            runInAction(() => {
                this.familles = this.familles.filter(a => a.familleId != values.familleId);
                this.familles.push(famille);
                toast.success("Modification éffectuée avec succès");
                this.submitting = false;
            })

        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
        }
    }

    @action deleteFamille = async (id: string) => {
        this.loadingFamilleAction = true;    
        try {
            await agent.familleAgent.delete(id);
            runInAction(() => {
                this.loadingFamilleAction = false;
                this.familles = this.familles.filter(a => a.familleId !== id);
                toast.success("suppression éffectuée avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.loadingFamilleAction = false;
            })
        }
    }

    @action loadSousFamille = async (id: string) => {
        this.loadingFamille = true;
        this.ITreeSousFamille = [];
        try {
            const familles = await agent.familleAgent.listSousFamille(id);
            runInAction(() => {
                this.ITreeSousFamille = familles;
                this.loadingFamille = false;
            })
        } catch (e) {
            toast.error("Problem loading du famille!");
            runInAction(() => {
                this.loadingFamille = false;
            })
        }
    }
    @action loadSous_Famille = async (id: string) => {
        this.loadingFamille = true;
        try {       
            const famillesEnvelope = await agent.familleAgent.listSous_Famille(this.axiosParams, id);
            const { famille, familles, familleCount } = famillesEnvelope;
            runInAction(() => {
                this.famille = famille;
                if (familleCount == 0)
                    this.sousFamilles = [];
                familles.forEach((famille) => {
                    setFamilleProps(famille);
                    if (this.pageSF == 0) {
                        this.sousFamilles = familles;
                    } else {
                        this.sousFamilles.push(famille);
                    }
                    this.famillesSfCount = familleCount;
                    this.loadingFamille = false;
                })
            })
        }catch (e) {
            toast.error("Problem loading du famille!");
            runInAction(() => {
                this.loadingFamille = false;
            })
        }        
    }
    loadFamilleOptions = async (inputValue: string | null) => {
        if (!inputValue || !inputValue.trim() || inputValue.length < 0) {
            return await agent.familleAgent.listFamille(null).then(r => r.data);
        } else
            return await agent.familleAgent.listFamille(inputValue).then(r => r.data);
    };
}