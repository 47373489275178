import React, { useContext } from 'react';
import { Tab, Header, Button, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import NotFound from '../../../app/layout/NoFound';
import CommandeFournisseurAdressesEditForm from './CommandeFournisseurAdressesEditForm';
import FormGridColumn from '../../../app/common/form/FormGridColumn';

const CommandeFournisseurAdresses = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { commandeFournisseur, editAdressesCommandeFournisseur, submitting } = baseRepo.commandeFournisseurStore;
    if (!commandeFournisseur) {
        return <NotFound />
    }
    else {
        return (
            <Tab.Pane>
                <Grid>
                    <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                        <Header floated='left' icon='map marker alternate' content='Adresses du commande Fournisseur' />
                        {commandeFournisseur.orderFournisseurState == 'AttenteValidation' &&
                            <Button
                                onClick={() => { baseRepo.commandeFournisseurStore.changeButtonState(!baseRepo.commandeFournisseurStore.editCommandeFournisseurMode) }}
                                floated='right'
                                basic
                                content={baseRepo.commandeFournisseurStore.editCommandeFournisseurMode ? 'Annuler' : 'Modifier'}
                            />}
                    </Grid.Column>
                    <Grid.Column width={16}>
                        {baseRepo.commandeFournisseurStore.editCommandeFournisseurMode ? (
                            <CommandeFournisseurAdressesEditForm edit={editAdressesCommandeFournisseur} commandeFournisseur={commandeFournisseur!} submitting={submitting} />
                        ) : (
                            <Grid className="ui stackable">
                                <Grid.Row style={{ padding: 0, margin: 0 }}>
                                    <FormGridColumn header="Adresse de facturation" content={commandeFournisseur!.invoiceAddress1} />
                                    <FormGridColumn header="Complément d’adresse facturation" content={commandeFournisseur!.invoiceAddress2} />
                                    <FormGridColumn header="ville de facturation" content={commandeFournisseur!.invoiceCity} />
                                    <FormGridColumn header="Code postal facturation" content={commandeFournisseur!.invoicePostalCode} />
                                </Grid.Row>
                            </Grid>
                        )}
                    </Grid.Column>
                </Grid>
            </Tab.Pane>
        );
    }
}
export default observer(CommandeFournisseurAdresses);