import React, { useContext, useEffect, useState } from 'react';
import { Form, Item, Table, Button, Header, Progress, Grid, FormInput } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import Loading from '../../../app/layout/Loading';
import { observer } from 'mobx-react-lite';
import TextInput from '../../../app/common/form/inputText';
import DeleteSousFamilleModal from './DeleteSousFamilleModal';
import SousFamilleForm from './AddSousFamilleModal';
import { Link, RouteComponentProps } from 'react-router-dom';
import Breadcrumbs from '../../dashboard/breadcrumbs';
import InfiniteScroll from 'react-infinite-scroller';

interface RouteParams {
    id: string
}

interface IProps extends RouteComponentProps<RouteParams> { }

const ListsousFamille: React.FC<IProps> = ({ match }) => {
    const baseStore = useContext(BaseStoreContext);
    const { loadingInitial, sousFamilles, edit, progress,famille, submitting, loadingFamilleAction, loadSous_Famille, setPredicateSF, pageSF, totalSFPages, setPageSF } = baseStore.familleStore;
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    const [editTarget, setEditTarget] = useState<string | undefined>(undefined);
    const [editForm, setEditForm] = useState(false);
    const [libelle, setLibelle] = useState<string | undefined>(undefined);
    const [desc, setDesc] = useState<string | undefined>(undefined);
    const { openModal } = baseStore.modalStore;
    const [searchResults, setSearchResults] = useState([]);
    const [loadingNext, setLoadingNext] = useState(false);

    const handleFinalFormSubmit = () => {
        let newSousFamille = {
            libelle: libelle,
            description: desc,
            familleId: editTarget,
        };
        edit(newSousFamille);
        setEditForm(false)
    }
    useEffect(() => {
        loadSous_Famille(match.params.id);
    }, [match]);
    const handleGetNext = () => {
        setLoadingNext(true);
        setPageSF(pageSF + 1);
        loadSous_Famille(match.params.id).then(() => setLoadingNext(false));
    }
    const handleChange = (event: any) => {
        setSearchResults(event.target.value);
        if (event.target.value.length > 2) {
            setPredicateSF('searchSF', event.target.value, match.params.id)
        }
        if (event.target.value.length === 0)
            setPredicateSF('all', 'true', match.params.id)

    }
    if (loadingInitial) return <Loading content="Chargement des sous familles des articles ..." />
    return (
        <div >
            <Breadcrumbs
                items={[
                    { key: 0, name: "Articles", root: "" },
                    { key: 1, name: "Familles", root: "/dashboardFamille" },
                    { key: 2, name: "Sous familles", root: `/dashboardSousFamille/${match.params.id}` },
                ]}
            />
            <div style={{ margin: "2rem 1rem" }}>
                <Item.Group>
                    <Item>
                        <Item.Content>
                            <Header as='h2' icon='address card outline'></Header>
                            <Header as='h2'>Liste des sous familles de {famille?.libelle}</Header>
                            <Grid.Column width={4}>
                                <Grid.Column width={16}>
                                </Grid.Column>
                                <Button
                                    floated="right"
                                    content={baseStore.familleStore.addFamille ? 'Annuler' : "Ajouter une sous famille d'article"}
                                    color="orange"
                                    icon='arrow alternate circle left outline'
                                    onClick={() => { baseStore.familleStore.changeButtonStateAdd(!baseStore.familleStore.addFamille) }}
                                />
                                <Button
                                    floated="right"
                                    content="Retour vers famille"
                                    color="orange"
                                    icon='arrow alternate circle left outline'
                                    as={Link}
                                    to={famille?.parent == null ? "/dashboardFamille" : `/dashboardSousFamille/${famille?.parent}`}
                                />
                            </Grid.Column>
                        </Item.Content>
                    </Item>
                </Item.Group>
                {baseStore.familleStore.addFamille ? (
                    <div>
                        <SousFamilleForm Param={match.params.id} />
                        <br />
                    </div>
                ) : (
                    <Item.Group>
                        <Item>
                            <FormInput
                                icon='search'
                                type="text"
                                placeholder="Rechercher Famille"
                                value={searchResults}
                                onChange={handleChange}
                            />
                        </Item>
                    </Item.Group>)}
                {submitting &&
                    <Progress color='orange' percent={progress} size='tiny' />
                }
                <InfiniteScroll
                    pageStart={0}
                    loadMore={handleGetNext}
                    hasMore={!loadingNext && pageSF + 1 < totalSFPages}
                    initialLoad={false}
                >
                    <Table loading={false} celled textAlign="center" >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Libelle</Table.HeaderCell>
                            <Table.HeaderCell>Utilisation</Table.HeaderCell>
                            <Table.HeaderCell>Sous Famille</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                
                        <Table.Body>
                            {sousFamilles && sousFamilles.map((sousfamille: any) => (
                                <Table.Row key={sousfamille.sousFamilleId}>
                                    {editForm && editTarget === sousfamille.familleId ? (
                                        <>
                                            <Table.Cell selectable >
                                                <Form.Input name='libelle' component={TextInput} placeholder='Libelle' defaultValue={sousfamille.libelle} style={{ width: '100%', padding: '1%' }} onChange={event => setLibelle(event.target.value)} />
                                            </Table.Cell>                               
                                            <Table.Cell >
                                                <Button.Group fluid widths={2}>
                                                    <Button
                                                        type='button'
                                                        onClick={() => {
                                                            setEditForm(false);
                                                        }}>
                                                        Annuler
                                                    </Button>
                                                    <Button.Or />
                                                    <Button
                                                        name={sousfamille.FamilleId}
                                                        positive
                                                        type='submit'
                                                        onClick={(e) => {
                                                            handleFinalFormSubmit()
                                                            setEditTarget(e.currentTarget.name)
                                                        }}
                                                    >Confirmer</Button>
                                                </Button.Group>
                                            </Table.Cell>
                                        </>
                                    ) : (
                                        <>
                                            <Table.Cell>{sousfamille.libelle}</Table.Cell>
                                            <Table.Cell>{sousfamille.usedCount}</Table.Cell>
                                            <Table.Cell  >

                                                <Button.Group fluid widths={3}>
                                                    <Button
                                                        name={sousfamille.familleId}
                                                        content={"sous famille"}
                                                        basic
                                                        color='orange'
                                                        icon='arrow alternate circle left outline'
                                                        as={Link}
                                                        to={`/dashboardSousFamille/${sousfamille.familleId}`}
                                                    />
                                                </Button.Group>
                                            </Table.Cell>
                                            <Table.Cell >
                                                <Button.Group fluid widths={2}>
                                                    <Button
                                                        onClick={(e) => {
                                                            setEditForm(true);
                                                            setEditTarget(e.currentTarget.name)
                                                            setLibelle(sousfamille.libelle)
                                                            setDesc(sousfamille.description)
                                                        }}
                                                        color="blue"
                                                        icon='edit outline'
                                                        style={{ marginRight: '3%' }}
                                                        name={sousfamille.familleId}
                                                    />
                                                    <Button
                                                        name={sousfamille.familleId}
                                                        basic
                                                        color='red'
                                                        icon='trash'
                                                        onClick={(e) => {
                                                            openModal(<DeleteSousFamilleModal sousfamille={sousfamille} />)
                                                            setDeleteTarget(e.currentTarget.name)
                                                        }}
                                                        loading={loadingFamilleAction && deleteTarget === sousfamille.familleId}
                                                    />
                                                </Button.Group>
                                            </Table.Cell>
                                        </>
                                    )}
                                </Table.Row>
                            ))}
                        </Table.Body>

                    </Table>
                </InfiniteScroll>

            </div>
        </div>
    );
}
export default observer(ListsousFamille);