import { useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import { BaseStoreContext } from "../../../app/store/baseStore";
import { observer } from 'mobx-react-lite';
import Breadcrumbs from '../../dashboard/breadcrumbs';
import FactureHeader from "./FactureHeader";
import FactureContent from "./FactureContent";


interface RouteParams {
    id: string,
}
interface IProps extends RouteComponentProps<RouteParams> { }

const FacturePage: React.FC<IProps> = ({ match }) => {
    const baseStore = useContext(BaseStoreContext);
    const { setActiveTab, brouillon, valide, deletingFactureArticle, getRemiseAvoirListe, remiseAvoireListe, facture } = baseStore.factureStore;

    useEffect(() => {
        setActiveTab('1');
        getRemiseAvoirListe(parseInt(match.params.id));
    }, [getRemiseAvoirListe])

    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Vente", root: "" },
                    { key: 1, name: "Factures", root: "/factureDashboard" },
                    { key: 2, name: "" + facture?.reference , root: `/detailFacture/${match.params.id}` },
                ]}
            />
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Grid style={{ marginTop: 2 }}>
                            <Grid.Column width={16}>
                                <FactureHeader id={match.params.id} brouillon={brouillon} reference = {facture?.reference!} valide={valide} loading={deletingFactureArticle} remise={remiseAvoireListe!.length} />
                                <FactureContent setActiveTab={setActiveTab} Facture={facture!} />
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}

export default observer(FacturePage);