import { BaseStore } from "./baseStore";
import { observable, action, runInAction } from 'mobx';
import agent from '../agent/agent';
import { IAttributes, IItem, IItems, IProductAttributes } from "../models/IProductAttributes";
import { toast } from "react-toastify";

export default class ProductAttributesStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
    }
    @observable loadingAction = false;
    @observable loadingInitial = false;
    @observable submitting = false;
    @observable ITListProductAttributes: IProductAttributes[] = [];
    @observable editProductAttribute: boolean = false;
    @observable addProductAttribute: boolean = false;

    @action changeButtonState = async (newState: boolean) => {
        this.editProductAttribute = newState;
    }

    @action changeButtonStateAdd = async (newState: boolean) => {
        this.addProductAttribute = newState;
    }

    @action loadListProductAttribute = async (id: number) => {
        this.loadingInitial = true;
        this.ITListProductAttributes = [];
        var res: IItems[] = [];
        try {
            const productAttributes = await agent.productAttributesAgent.liste(id);
            runInAction(() => {
           
                productAttributes.forEach((item) => {
                    var x: IItem[] = [];
                    item.possibleValues.split(';').map((item) => {
                        x.push({ key: item, value: 0 });
                    });
                    res.push({ id: item.id, name: item.name, isMulti: item.displayAllWithDistinctQuantities, attributs: x });
                });
                this.ITListProductAttributes = productAttributes;
                this.loadingInitial = false;
            })
            return res;
        } catch (e) {

            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }

    @action create = async (values: IAttributes) => {
        this.submitting = true;
        try {
            var tarif = await agent.productAttributesAgent.create(values);
            runInAction(() => {
                this.ITListProductAttributes.push(tarif);
                this.submitting = false;
                toast.success("ajout éffectué avec succès");
            })

        } catch (e) {
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    @action update = async (values: Partial<IProductAttributes>) => {
        this.submitting = true;
        try {
            await agent.productAttributesAgent.update(values);
            let product = await agent.productAttributesAgent.details(values.id!);
            runInAction(() => {
                this.ITListProductAttributes = this.ITListProductAttributes.filter(a => a.id != values.id);
                this.ITListProductAttributes.push(product);
                toast.success("Modification éffectuée avec succès");
                this.submitting = false;
            })

        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action deleteAttribute = async (id: number) => {
        this.loadingAction = true;
        try {
            await agent.productAttributesAgent.delete(id);
            runInAction(() => {
                this.loadingAction = false;
                this.ITListProductAttributes = this.ITListProductAttributes.filter(a => a.id !== id);
                toast.success("suppression éffectuée avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.loadingAction = false;
            })
        }
    }
}