 import { useContext, useEffect } from 'react';
import { Tab, Header, Button, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import NotFound from '../../../app/layout/NoFound';
import ArticleEditForm from './ArticleEditform';
import FormGridColumn from '../../../app/common/form/FormGridColumn';
import { IArticlePack } from '../../../app/models/IArticleCompose';

interface IDetailParams {
    isInPack: IArticlePack
}
const ArticleDescription: React.FC<IDetailParams> = ({ isInPack }) => {
    const baseRepo = useContext(BaseStoreContext);
    const { article, editArticle } = baseRepo.articleStore;
    const { loadTvasActifs } = baseRepo.tvaStore;

    const getStatus = (status: string) => {
        switch (status) {
            case 'Online':
                return 'En ligne';
            case 'Offline':
                return 'Hors Ligne';
            case 'None':
                return 'Aucun';
            case 'VenteDirectePersonalise':
                return 'Prod interne';
            case 'Stock':
                return 'Stock';
            case 'VenteDirecte':
                return 'Vente Directe';
            default:
                return '';
        }
    }
    useEffect(() => {
        baseRepo.articleStore.changeButtonState(false);
        loadTvasActifs();
    }, []);

    if (!article) {
        return <NotFound />
    }
    else {
        return (
            <Tab.Pane>
                <Grid>
                    <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                        <Header floated='left' icon='qrcode' content={`Code: ${article!.reference}`} />
                        <Button
                            onClick={() => { baseRepo.articleStore.changeButtonState(!baseRepo.articleStore.editArticleMode) }}
                            floated='right'
                            basic
                            content={baseRepo.articleStore.editArticleMode ? 'Annuler' : 'Modifier article'}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        {baseRepo.articleStore.editArticleMode ? (
                            <ArticleEditForm editArticle={editArticle} article={article!} />
                        ) : (
                                <Grid className="ui stackable">
                                    <Grid.Row style={{ padding: 0, margin: 0 }}>
                                        <FormGridColumn header="Libelle" content={article!.label} />
                                        <FormGridColumn header="Client" content={article!.companyId == 0 ? 'aucun client' : article!.agencyName + ' - ' + article!.franchiseurLibelle } />
                                        <FormGridColumn header="Famille d'article" content={article!.familleLibelle} />
                                        <FormGridColumn header="Sous famille" content={article!.familleLibelle != article!.famille ? article!.famille : ''} />
                                        <FormGridColumn header="TVA" content={article!.tvaRate} />
                                        <FormGridColumn header="Référence Centrale" content={article!.externalReference} />
                                        <FormGridColumn header="Poids Unitaire" content={article!.weight} />
                                        <FormGridColumn header="Délai livraison" content={article!.deliveryDelay == null ? '0' : article!.deliveryDelay} />
                                        <FormGridColumn header="Mode Appro" content={getStatus(article!.modeAppro)} />
                                        <FormGridColumn header="Format" content={article!.paperFormat} />
                                        <FormGridColumn header="Support" content={article!.paperWeight} />
                                        <FormGridColumn header="Impression" content={article!.printing} />
                                        {article.modeAppro == "Stock" &&
                                            <FormGridColumn header="Seuil" content={article!.seuil} />
                                        }
                                        <FormGridColumn header="Type de produit" content={article!.productType == "Print" ? "Impression" : article!.productType} />
                                        <FormGridColumn header="Type de Personnalisation" content={getStatus(article!.customizationType)} />
                                        <FormGridColumn header="Côté" content={article!.side} />
                                        <FormGridColumn header="Descriptif" content={article.optionArticles?.length == 0 ? 'AUCUNE OPTION' : article!.optionArticles?.map(x => <>* {x}<br/></>  )} />
                                        <FormGridColumn header="Frais de port" content={article.deliveryCosts} />
                                        <FormGridColumn header="Avec commission" content={article.hasCommission} />
                                        <FormGridColumn header="Appartient au pack d'ouverture" content={article.belongToOpeningPack} />
                                        <FormGridColumn header="Masquer sur la boutique" content={article.isHideEco} />
                                        <FormGridColumn header="Format du document" content={article.docformat} />
                                        <FormGridColumn header="Code Comptable" content={article!.codeComptable} />
                                        {isInPack!.id != 0 && < FormGridColumn header="Appartient au produit composé" content={isInPack.id + "-" + isInPack.label} root={`/detailArticle/${isInPack.id}`} />}
                                    </Grid.Row>
                                </Grid>
                        )}
                    </Grid.Column>
                </Grid>
            </Tab.Pane>
        );
    }
}
export default observer(ArticleDescription);