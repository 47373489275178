import { Form as FinalForm } from 'react-final-form';
import { Button, Header, Container, Form, Segment } from 'semantic-ui-react';
import { useContext, useState } from 'react';
import { BaseStoreContext } from "../../../app/store/baseStore";
import InputSelect from '../../../app/common/form/inputSelect';
import { toast } from 'react-toastify';
import Select from 'react-select';

interface IProps {
    id: number;
}

const RemiseAvoirModal = () => {
    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    const { facture, selectremisAVList, affecteRemiseAvoirToFacture, getRemiseAvoirListe, setChangeRemaining } = baseStore.factureStore;

    const [remise, setRemise] = useState({idRemise:0,amountRemise:0});

    const handleChange = (e: any) => {
        setRemise({ idRemise: e.value.id, amountRemise: e.value.amountWithTaxes });
    }
    const handleFinalFormSubmit = () => {
        if (facture!.remainingPayment < remise!.amountRemise) {
            toast.warning("Montant avoir doit étre inférieur au montant du reste à payer");
        } else {
            affecteRemiseAvoirToFacture(remise!.idRemise, facture!.id).then(() => {
                setChangeRemaining(remise!.amountRemise);
                getRemiseAvoirListe(facture!.id);
                closeModal();
            });
        }
    }

    return (
        <Container textAlign='center' style={{ width: "100%" }}>
            <Segment style={{ backgroundColor: 'rgb(255,255,255)', padding: '20px' }}>
                <Header content={'Affecter remise/avoir pour la facture ' + facture?.reference} style={{ marginBottom: 50 }} />
                <FinalForm
                    onSubmit={handleFinalFormSubmit}
                    render={({ handleSubmit, submitting }) => (
                        <Form onSubmit={handleSubmit} error >
                           
                            <Form.Group unstackable widths={1}>
                                <div className="field" style={{ marginTop: 13 }}>
                                    <Select placeholder='Listes des avoirs dispo....' options={selectremisAVList} onChange={(e) => handleChange(e)} />
                                    </div>
                                </Form.Group>
                           
                            <Form.Group unstackable style={{ marginLeft: "40%" }} >
                                <Button disabled={submitting} floated='right'
                                    type='button'
                                    onClick={(e) => {
                                        closeModal()
                                    }}>Annuler
                                </Button>
                                <Button.Or />
                                <Button positive loading={submitting} floated='right' type='submit'>Confirmer</Button>
                            </Form.Group>
                        </Form>
                    )}
                />
            </Segment >
        </Container>
    );
};
export default RemiseAvoirModal;