import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { Button, Form, FormInput, Grid, Label } from "semantic-ui-react";
import { BaseStoreContext } from "../../app/store/baseStore";
import { Form as FinalForm } from 'react-final-form';
import Select, { components } from "react-select";
import { FaFileExcel, FaSearch, FaSort } from "react-icons/fa";
import { RiDeleteBin2Fill, RiFileExcel2Fill } from "react-icons/ri";
import AsyncSelect from 'react-select/async';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { ISearch } from "../../app/models/IFacture";
import { MdCancel } from "react-icons/md";
import { ITListPaymentMode } from "../../app/common/options/IAchat";
import { numberFormat } from "../../app/common/options/IStyle";

const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <FaSort color='green' />
        </components.DropdownIndicator>
    );
};

const customStyles = {
    option: (provided: any, state: { isSelected: any; }) => ({
        ...provided,
        color: state.isSelected ? 'orange' : 'grey',
        backgroundColor: state.isSelected ? "#eee" : "white",
        borderBottom: '1px solid #eee',
    }),
    valueContainer: (props: any) => ({
        ...props,
        paddingTop: 0,
        height: '39.201px',
        minHeight: '39.201px',
    }),
    control: (baseStyles: any, state: any) => ({
        ...baseStyles,
        height: '39.201px',
        minHeight: '39.201px',
    }),
}

const ExpeditionFilter = () => {
    const { RangePicker } = DatePicker;
    const baseStore = useContext(BaseStoreContext);
    const { loadListesFacturesExporte, ExportFacture, looading, totalHT, totalTTC, totalRemaining, loadingListFacture } = baseStore.factureStore;
    const [searchDateCommande, setSearchDateCommande] = useState<any>('');
    const [searchDateCommandeTo, setSearchDateCommandeTo] = useState<any>('');
    const [searchInvoiceResults, setSearchInvoiceResults] = useState([]);
    const [selectedClient, setSelectedClient] = useState<any>(0);
    const [SelectedLabelClient, setSelectedLabelClient] = useState<any>('Client');
    const [selectedAmount, setSelectedAmount] = useState<any>();
    const [selectedLabelAmount, setSelectedLabelAmount] = useState<any>('MontantHt');
    const [selectedAmountTtc, setSelectedAmountTtc] = useState<any>();
    const [selectedLabelAmountTtc, setSelectedLabelAmountTtc] = useState<any>('MontantTtc');
    const [searchResultsClientAll, setSearchResultsClientAll] = useState(false);
    const [searchNCC, setSearchNCC] = useState<any>('');
    const [modePaiement, setModePaiement] = useState<any>("");
    const [labelModePaiement, setLabelModePaiement] = useState("Mode de Paiement");

    const rangePresets: {
        label: string;
        value: [Dayjs, Dayjs];
    }[] = [
            { label: '7 derniers jours', value: [dayjs().add(-7, 'd'), dayjs()] },
            { label: '14 derniers jours', value: [dayjs().add(-14, 'd'), dayjs()] },
            { label: '30 derniers jours', value: [dayjs().add(-30, 'd'), dayjs()] },
            { label: '90 derniers jours', value: [dayjs().add(-90, 'd'), dayjs()] },
        ];

    const handleChange = (event: any) => {
        setSearchInvoiceResults(event.target.value);
    }

    const handleChangeAmount = (event: any) => {
        setSelectedAmount(event.target.value);
        setSelectedLabelAmount(event.target.value)
    }
    const handleChangeAmountTtc = (event: any) => {
        setSelectedAmountTtc(event.target.value);
        setSelectedLabelAmountTtc(event.target.value)
    }
    const handleChangeClient = (value: any) => {
        setSelectedClient(value.id);
        setSelectedLabelClient(value!.agencyName + "-" + value!.companyName + (value!.all ? "-Tous les réseaux" : ""));
        setSearchResultsClientAll(value.all);

    }

    const onRangeChange = (dates: null | (Dayjs | null)[]) => {
        if (dates) {
            setSearchDateCommande(dates[0]?.toISOString());
            setSearchDateCommandeTo(dates[1]?.toISOString());
        }
    };
    const handleSearch = (event: any) => {
        if (event == 'clear') {
            setSearchDateCommande('')
            setSearchDateCommandeTo('')
            setSearchNCC('')
            setSearchInvoiceResults([])
            setSelectedClient(0)
            setSearchResultsClientAll(false);
            loadListesFacturesExporte(null)

        } else if (event == 'submit') {
            let newSearch: ISearch = {
                searchDateCommande: searchDateCommande,
                searchDateCommandeTo: searchDateCommandeTo,
                searchInvoiceResults: searchInvoiceResults,
                selectedAmount: selectedAmount,
                selectedClient: selectedClient,
                searchResultsClientAll: searchResultsClientAll,
                searchNCC: searchNCC,
                selectedAmountTtc: selectedAmountTtc,
                modePaiement: modePaiement
            }; loadListesFacturesExporte(newSearch);
        } else if (event == 'clique') {
            let obj: any = {
                searchDateCommande: searchDateCommande,
                searchDateCommandeTo: searchDateCommandeTo,
                searchInvoiceResults: searchInvoiceResults,
                selectedAmount: selectedAmount,
                selectedAmountTtc: selectedAmountTtc,
                selectedClient: selectedClient,
                searchResultsClientAll: searchResultsClientAll,
                searchNCC: searchNCC,
                SelectedLabelClient: SelectedLabelClient,
                modePaiement: modePaiement
            }; ExportFacture(obj);

        }

    }

    return (
        <FinalForm
            onSubmit={handleSearch}
            render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit} error style={{ width: "100%", fontSize: 14 }}>
                    <Grid>
                        <Grid.Row columns={4} style={{ padding: 0, margin: 0 }}>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <FormInput
                                    icon='search'
                                    type="text"
                                    placeholder="Facture"
                                    value={searchInvoiceResults}
                                    onChange={handleChange}
                                    style={{ width: '100%' }}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <FormInput
                                    icon='search'
                                    type="text"
                                    placeholder="N°CC"
                                    value={searchNCC}
                                    onChange={(e) => { setSearchNCC(e.target.value) }}
                                    style={{ width: '100%' }}

                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <FormInput
                                    icon='euro'
                                    type="text"
                                    placeholder={selectedLabelAmount}
                                    value={selectedAmount}
                                    onChange={handleChangeAmount}
                                    style={{ height: '100%' }} />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <FormInput
                                    icon='euro'
                                    type="text"
                                    placeholder={selectedLabelAmountTtc}
                                    value={selectedAmountTtc}
                                    onChange={handleChangeAmountTtc}
                                    style={{ height: '100%' }} />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <AsyncSelect
                                    name="form-field-name"
                                    noOptionsMessage={() => "Code introuvable"}
                                    cacheOptions
                                    defaultOptions
                                    placeholder={SelectedLabelClient}
                                    value={selectedClient}
                                    getOptionLabel={e => e!.agencyName + "-" + e!.companyName + (e!.all ? "-Tous les réseaux" : "")}
                                    loadOptions={baseStore.clientStore.loadOptions}
                                    onChange={handleChangeClient}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <Select
                                    options={ITListPaymentMode}
                                    styles={customStyles}
                                    getOptionLabel={e => e!.text}
                                    value={modePaiement}
                                    onChange={e => { setLabelModePaiement(e!.text); setModePaiement(e!.value); }}
                                    placeholder={labelModePaiement}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>

                                <RangePicker
                                    presets={rangePresets}
                                    onChange={onRangeChange}
                                    format={'DD/MM/YYYY'}
                                    placeholder={["Début", "Fin"]}
                                    style={{ width: '100%', height: '39.201px' }}
                                />

                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0, display: 'flex' }}>
                                <Button style={{ padding: 5, margin: "0 0 0 10px", color: 'blue', background: "none" }} type='submit' onClick={(e: any) => handleSearch('submit')} >
                                    <FaSearch />
                                </Button>
                                <Button style={{ padding: 5, fontSize: 13, border: 'none', backgroundColor: 'inherit', color: 'green' }} >
                                    <MdCancel style={{ fontSize: 17 }} onClick={(e: any) => handleSearch('clear')} />
                                </Button>
                                <Button loading={looading} style={{ float: "right", padding: 5, margin: 0, color: 'green', background: "none" }}>
                                    <RiFileExcel2Fill style={{ fontSize: 17 }} onClick={(e: any) => handleSearch('clique')}/>
                                </Button>
                            </Grid.Column >

                        </Grid.Row>
                    </Grid>
                    {!loadingListFacture &&
                        <>
                            <Label>Total HT : {numberFormat.format(totalHT)}</Label>
                            <Label>Total TTC : {numberFormat.format(totalTTC)}</Label>
                            <Label>Total en attente de paiement : {numberFormat.format(totalRemaining)}</Label>
                        </>
                    }
                </Form >
            )}
        />
    );
};
export default observer(ExpeditionFilter);