import { useContext, useEffect } from 'react';
import { Tab, Header, Grid, Table, Message, Button, Form, Input, Modal, Checkbox } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import { TbShoppingCartDiscount } from 'react-icons/tb';
import { useParams } from 'react-router-dom';
import { Form as FinalForm, Field } from 'react-final-form';
import { useState } from 'react';
import DeleteReglementModal from '../../Reglement/dashboard/DeleteReglementModal';
import NoResult from '../../devis/devisDashboard/erreurNoResult';
import { AiTwotonePushpin } from 'react-icons/ai';
import { FaFileExcel, FaTrashAlt, FaUserEdit } from 'react-icons/fa';
import AffectReglement from './AffectReglement';
import { IReglement } from '../../../app/models/IReglement';
import Select from 'react-select';
import { numberFormat } from '../../../app/common/options/IStyle';
import EditReglement from '../../Reglement/dashboard/EditReglement';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';

interface UrlParams {
    id: string;
}
const setPaiementType = (status: string) => {
    switch (status) {
        case '31':
            return 'Carte Bancaire';
        case '03':
            return 'Chèque de banque';
        case '01':
            return 'Espèce';
        case '15':
            return 'Prélèvement Bancaire';
        case '11':
            return 'Virement Bancaire';
        default:
            return status;
    }
}

const FranchiseurReglement: React.FC = () => {
    const { RangePicker } = DatePicker;
    const baseStore = useContext(BaseStoreContext)
    const { ITListModePaiements, loadModePaiements, loadingInitial } = baseStore.modePaiementStore;
    const { ITListAccount, loadAccounts, loadingInitialAccount } = baseStore.accountStore;
    const [modePaiement, setModePaiement] = useState<string | undefined>("Prélèvement Bancaire");
    const [account, setAccount] = useState<string | undefined>("Société générale");
    const { loadReglementByFranchiseur, loadingReglementByFranchiseur, reglements, totalCredit, totalDebit, addMode, createCompanyReglement, submitting, changeButtonStateAdd, exportByCompany } = baseStore.reglementStore;
    const { id } = useParams<UrlParams>();
    const [dateDeposite, setDate] = useState<string>(new Date().toLocaleDateString("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" }));
    const [montant, setMontant] = useState<number | undefined>();
    const { openModal } = baseStore.modalStore;
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [reglement, setReglement] = useState<IReglement>();
    const [type, setType] = useState<any>("RGL");
    const [reference, setReference] = useState("");
    const [searchDateCommande, setSearchDateCommande] = useState<any>('');
    const [searchDateCommandeTo, setSearchDateCommandeTo] = useState<any>('');
    const [factureEnAttente, setFactureEnAttente] = useState<any>(false);

    const Options = [
        { key: 'RGL', text: 'Reglement', value: 'RGL' },
        { key: 'RBT', text: 'Remboursement', value: 'RBT' },
        { key: 'SLDC', text: 'Solde Créditeur', value: 'SLDC' },
        { key: 'SLDD', text: 'Solde Débiteur', value: 'SLDD' },

    ];
    const rangePresets: {
        label: string;
        value: [Dayjs, Dayjs];
    }[] = [
            { label: '7 derniers jours', value: [dayjs().add(-7, 'd'), dayjs()] },
            { label: '14 derniers jours', value: [dayjs().add(-14, 'd'), dayjs()] },
            { label: '30 derniers jours', value: [dayjs().add(-30, 'd'), dayjs()] },
            { label: '90 derniers jours', value: [dayjs().add(-90, 'd'), dayjs()] },
        ];
    const onRangeChange = (dates: null | (Dayjs | null)[]) => {
        if (dates) {
            setSearchDateCommande(dates[0]?.toISOString());
            setSearchDateCommandeTo(dates[1]?.toISOString());
            let obj = {
                dateCommande: dates[0]?.toISOString(),
                dateCommandeTo: dates[1]?.toISOString(),
                factureEnAttente: factureEnAttente
            };
            loadReglementByFranchiseur(parseInt(id), obj)
        }
        else {
            setSearchDateCommande('');
            setSearchDateCommandeTo('');
            let obj = {
                dateCommande: '',
                dateCommandeTo: '',
                factureEnAttente: factureEnAttente
            };
            loadReglementByFranchiseur(parseInt(id), obj)
        }
    };
    const handleChageFactureEnAttente = () => {
        setFactureEnAttente(!factureEnAttente);
        let obj = {
            dateCommande: searchDateCommande,
            dateCommandeTo: searchDateCommandeTo,
            factureEnAttente: !factureEnAttente,
            };
            loadReglementByFranchiseur(parseInt(id), obj)
       
    };
    const handleChangeMontant = (e: any, result: any) => {
        setMontant(parseFloat(result.value));
    }
    const handleChangeReference = (e: any, result: any) => {
        setReference(result.value);
    }

    const handleFinalFormSubmit = (values: any) => {
        let newReglement = {
            ...values,
            company: parseInt(id),
            creationDate: dateDeposite,
            montant: montant,
            modePaiement: modePaiement,
            account: account,
            invoice: 0,
            commande: 0,
            type: type,
            reference: (type == "SLDC" || type == "SLDD") ? reference : "",
        };
        createCompanyReglement(newReglement);

    }

    useEffect(() => {
        loadModePaiements();
        loadAccounts();
        loadReglementByFranchiseur(parseInt(id), null);
    }, [])
    return (

        <Tab.Pane >
            <Modal
                open={modalOpen}
                size='small'
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >
                <Modal.Header icon='add' >
                    Affectation
                    <Button
                        style={{ height: 35 }}
                        type='button' icon='x' color='red'
                        floated='right'
                        onClick={() => { setModalOpen(false) }}
                    >
                    </Button>
                </Modal.Header>
                <Modal.Content>
                    <AffectReglement idCompany={parseInt(id)} reglement={reglement!} setModalOpen={setModalOpen} />
                </Modal.Content>
            </Modal>
            <Modal
                open={modalEditOpen}
                size='small'
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >
                <Modal.Header icon='add' >
                    Modification
                    <Button
                        style={{ height: 35 }}
                        type='button' icon='x' color='red'
                        floated='right'
                        onClick={() => { setModalEditOpen(false) }}
                    >
                    </Button>
                </Modal.Header>
                <Modal.Content>
                    <EditReglement reglement={reglement!} setModalOpen={setModalEditOpen} />
                </Modal.Content>
            </Modal>
            <Grid style={{ marginLeft: '-20px' }}>
                <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                    <Header floated='left' icon={<TbShoppingCartDiscount style={{ marginRight: 10, marginTop: 4 }} />} content="Compte Client" />
                    <Button
                        onClick={() => { changeButtonStateAdd(!addMode) }}
                        floated='right'
                        basic
                        content={addMode ? 'Annuler' : 'Affecter Reglement'}
                    />
                </Grid.Column>

                <Grid.Column style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}>

                </Grid.Column >
                {addMode ?
                    <Grid.Column width={16}>
                        <FinalForm
                            onSubmit={handleFinalFormSubmit}
                            render={({ handleSubmit, invalid }) => (
                                <Form onSubmit={handleSubmit} error>
                                    <Grid columns={2}>
                                        <Grid.Column>

                                            <Select
                                                options={Options}
                                                getOptionLabel={e => e!.text}
                                                getOptionValue={e => e!.value}
                                                onChange={e => { setType(e?.value); }}
                                                placeholder="Reglement "
                                            />
                                        </Grid.Column>
                                        {(type == "SLDC" || type == "SLDD") &&
                                            <Grid.Column>
                                                <Input
                                                    placeholder="Reference"
                                                    type='text'
                                                    onChange={handleChangeReference}
                                                />
                                            </Grid.Column>
                                        }


                                    </Grid>
                                    <Grid columns={2}>
                                        <Grid.Column>
                                            <h5>Montant:</h5>
                                            <Input
                                                placeholder="Montant"
                                                type='number'
                                                step="0.01"
                                                onChange={handleChangeMontant}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <h5>Compte bancaire :</h5>
                                            <Select
                                                isLoading={loadingInitialAccount}
                                                options={ITListAccount}
                                                getOptionLabel={e => e!.text}
                                                getOptionValue={e => e!.value}
                                                onChange={e => { setAccount(e?.text); }}
                                                placeholder={account ?? "Séléctionnez ... "}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <h5>Mode de paiement:</h5>
                                            <Select
                                                isLoading={loadingInitial}
                                                options={ITListModePaiements}
                                                getOptionLabel={e => e!.text}
                                                getOptionValue={e => e!.value}
                                                onChange={e => { setModePaiement(e?.text); }}
                                                placeholder={modePaiement ?? "Séléctionnez ... "}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <h5>Date de paiement:</h5>
                                            <Input name='creationDate' value={dateDeposite } onChange={(e: any) => setDate(e.target.value)} type='date' />
                                        </Grid.Column>
                                    </Grid>
                                    <div style={{ marginTop: '20px', marginRight: '1px' }}>
                                        <Button.Group floated="right">
                                            <Button
                                                onClick={() => {
                                                    changeButtonStateAdd(!addMode)
                                                }}
                                                floated='right' type='button'>Annuler</Button>
                                            <Button.Or />
                                            <Button positive disabled={submitting || invalid} loading={submitting} floated='right' type='submit' >Confirmer</Button>
                                        </Button.Group>
                                    </div>
                                </Form>
                            )}
                        />
                    </Grid.Column> :
                    (
                        <>
                            <Grid.Row style={{marginBottom:'-35px'}} >
                                <Grid.Column width={6} >
                                    <RangePicker
                                        presets={rangePresets}
                                        onChange={onRangeChange}
                                        format={'DD/MM/YYYY'}
                                        placeholder={["Début", "Fin"]}
                                        style={{ width: '100%', height: '39.201px' }}
                                    />
                                </Grid.Column>
                                <Grid.Column width={4} >
                                    <label style={{ marginRight:'5px' }}>Paiement à régler</label>
                                    <Checkbox value="FactureEnAttente" style={{ marginTop: '5px' }} onChange={handleChageFactureEnAttente} />
                                </Grid.Column>
                                <Grid.Column width={4} >
                                    <Button
                                        size="tiny"
                                        style={{ height: 32, float: "right" }}
                                        content="Export Excel"
                                        className="andi-btn filled success"
                                        icon={(<FaFileExcel style={{ marginRight: "10px" }} />)}
                                        onClick={() => {
                                            let obj = {
                                                companyId: parseInt(id),
                                                dateCommande: searchDateCommande,
                                                dateCommandeTo: searchDateCommandeTo
                                            };
                                            exportByCompany(obj);
                                        }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Column width={16} >
                                {loadingReglementByFranchiseur ?
                                    <div style={{ marginTop: 50 }} className="ui segment">
                                        <div className="ui active inverted dimmer">
                                            <div className="ui medium text loader">Chargement ...</div>
                                        </div>
                                        <p></p>
                                        <p></p>
                                    </div>
                                    :
                                    (
                                        reglements?.length != 0 ?
                                            (
                                                <Grid.Column width={16} >
                                                    <Table loading={false} celled style={{ textAlign: "center", marginLeft: '-5px' }} size='small'>

                                                        <Table.Header >

                                                            <Table.Row>
                                                                <Table.HeaderCell>Date</Table.HeaderCell>
                                                                <Table.HeaderCell>Type</Table.HeaderCell>
                                                                <Table.HeaderCell>Référence</Table.HeaderCell>
                                                                <Table.HeaderCell>Montant Crédit</Table.HeaderCell>
                                                                <Table.HeaderCell>Montant Débit</Table.HeaderCell>
                                                                <Table.HeaderCell>Mode de réglement</Table.HeaderCell>
                                                                <Table.HeaderCell>Action</Table.HeaderCell>
                                                            </Table.Row>
                                                        </Table.Header>
                                                        {reglements &&
                                                            reglements.map(reglement => (
                                                                <Table.Body style={{ textAlign: 'center' }}>
                                                                    <Table.Cell>{new Date(reglement.creationDate).toLocaleDateString('en-GB')}</Table.Cell>
                                                                    <Table.Cell>{reglement.type} </Table.Cell>
                                                                    {reglement.rbtId != null ? <Table.Cell>{reglement.reference}</Table.Cell>:reglement.type == "SLDD" || reglement.type == "SLDC" ? <Table.Cell>{reglement.reference}</Table.Cell> : <Table.Cell><a href={reglement.commande != 0 ? `/detailCommande/${reglement.commande}` : `/detailFacture/${reglement.invoice}`}>{reglement.reference}</a></Table.Cell>}
                                                                    <Table.Cell>{reglement.type == "RGL" || reglement.type == "AV" || reglement.type == "SLDC" ? numberFormat.format(reglement.montant) : 0}</Table.Cell>
                                                                    <Table.Cell>{(reglement.type != "RGL" && reglement.type != "AV" && reglement.type != "SLDC") ? reglement.montant < 0 ? numberFormat.format(reglement.montant * -1) : numberFormat.format(reglement.montant) : 0}</Table.Cell>
                                                                    <Table.Cell>{setPaiementType(reglement.modePaiement)}</Table.Cell>
                                                                    <Table.Cell style={{ display: "flex" }}>
                                                                        {( reglement.type != "SLDD" && reglement.type != "SLDC"&& reglement.type != "RBT") &&
                                                                            <span className={reglement!.idReglement}
                                                                                onClick={(e) => {
                                                                                    setModalOpen(true)
                                                                                    setReglement(reglement)
                                                                                }}>
                                                                                <AiTwotonePushpin style={{ fontSize: 16, color: 'red' }} />
                                                                            </span>}
                                                                        {(reglement.type == "RGL" || reglement.type == "SLDD" || reglement.type == "SLDC" || reglement.type == "RBT") && reglement.hasConsumed == false &&
                                                                            <span className={reglement!.idReglement}
                                                                                onClick={(e) => {
                                                                                    openModal(<DeleteReglementModal idreglement={reglement!.idReglement} montant={reglement.montant} />)
                                                                                    setDeleteTarget(e.currentTarget.className)
                                                                                }}>
                                                                                <FaTrashAlt style={{ fontSize: 16 }} />
                                                                            </span>}
                                                                        {(reglement.type == "RGL" || reglement.type == "SLDD" || reglement.type == "SLDC" || reglement.type=="RBT") &&
                                                                            <span className={reglement!.idReglement}
                                                                                onClick={(e) => {
                                                                                    setModalEditOpen(true); setReglement(reglement)
                                                                                }}>
                                                                                <FaUserEdit style={{ fontSize: 16, color: 'green' }} />
                                                                            </span>}
                                                                    </Table.Cell>
                                                                </Table.Body>
                                                            ))}
                                                        <Table.Footer>
                                                            <Table.Row  >
                                                                <Table.HeaderCell colspan={3} style={{ border: 'none' }} ><b>Total</b></Table.HeaderCell>
                                                                <Table.Cell style={{ border: 'none' }}>{numberFormat.format(totalCredit)}</Table.Cell>
                                                                <Table.Cell style={{ border: 'none' }}>{numberFormat.format(totalDebit)} </Table.Cell>

                                                            </Table.Row>
                                                            <Table.Row >
                                                                <Table.HeaderCell colspan={3} style={{ border: 'none' }} ><b>Solde</b></Table.HeaderCell>
                                                                <Table.Cell style={{ border: 'none' }}>{totalCredit > totalDebit ? numberFormat.format(totalCredit - totalDebit) : ""}</Table.Cell>
                                                                <Table.Cell style={{ border: 'none' }}>{totalDebit > totalCredit ? numberFormat.format(totalDebit - totalCredit) : ""} </Table.Cell>

                                                            </Table.Row>
                                                        </Table.Footer>
                                                    </Table>

                                                </Grid.Column>
                                            ) : (
                                                <NoResult name='Reglement'
                                                />
                                            ))}
                            </Grid.Column>
                        </>
                    )}

            </Grid>
        </Tab.Pane>
    );
};
export default observer(FranchiseurReglement);
