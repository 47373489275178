import { MdOutlineRequestQuote } from "react-icons/md";
import { GridColumn } from "semantic-ui-react";
import { observer } from "mobx-react-lite";

interface IProps {
    colorStart: string,
    colorEnd: string,
    total: number,
    count: number,
    name:string
}
const StatisticsCount: React.FC<IProps> = ({colorStart, colorEnd, total, count, name }) => {
    return (
        <GridColumn style={{ margin: 0, height: "150px", width: "100%" }}>
            <div className="ui segment __restaurant-segment" style={{background: "linear-gradient(to right,"+colorStart+","+colorEnd+")",boxShadow: "10px 10px 20px grey"}}>
                <div className="segment-body">
                    <div className="ui grid">
                        <div className=" wide column">
                            <div className="ui statistic">
                                <div className="text value">
                                    <span style={{ fontSize: "28px" }} className="text-white counter" data-target={count}>{count} </span>
                                    <br />
                                    <span style={{ fontSize: "18px" }} className="text-white"> {name}</span>
                                    <MdOutlineRequestQuote style={{ float: "right", fontSize: "27px", margin: "10px 0 0 10px", color: "#FEBE39" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </GridColumn>
        )
}
export default observer(StatisticsCount);