import React, { useContext, useEffect, useState } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button, Grid, Checkbox } from 'semantic-ui-react';
import TextInput from '../../../app/common/form/inputText';
import { observer } from 'mobx-react-lite';
import { ITListSeuil, ITListSeuilFranco, ITListTypeRemise, ITListValeur } from '../../../../src/app/common/options/IAchat'
import { IFournisseur } from '../../../app/models/IFournisseur';
import { BaseStoreContext } from '../../../app/store/baseStore';
import Select from 'react-select/';
import { toast } from 'react-toastify';
import InputSelect from '../../../app/common/form/inputSelect';

interface IProps {
    editFournisseur: (profil: Partial<IFournisseur>) => void;
    fournisseur: IFournisseur;
}
const FournisseurAchatEditForme: React.FC<IProps> = ({ editFournisseur, fournisseur }) => {
    const baseStore = useContext(BaseStoreContext);
    const { ITListTypeMarche, loadTypeMarches } = baseStore.TypeMarcheStore;
    const { verificationUpdate } = baseStore.fournisseurStore;
    const [esCompte, setEsCompte] = useState(fournisseur.esCompte);
    const [seuilFranco, setSeuilFranco] = useState(fournisseur.seuilFranco);
    const [fraisPort, setFraisPort] = useState(fournisseur.valeur);
    const [franco, setFranco] = useState<string>(fournisseur.franco);
    const [fraisPortValeur, setFraisPortValeur] = useState<string>(fournisseur.fraisPort);
    useEffect(() => {
        loadTypeMarches();
    }, [loadTypeMarches])


    const handleChangeSeuilFranco = (e: any) => {
        setSeuilFranco(e);
    }
    const handleChangeFraisPort = (e: any) => {
        setFraisPort(e);
    }
 
    const handleChangeFranco = (e: any, result: any) => {
        seuilFranco != 'Aucun' ? setFranco(result.value) : setFranco('0');
    }
    const handleChangeFraisPortValeur = (e: any, result: any) => {
        fraisPort != 'Aucun' ? setFraisPortValeur(result.value) : setFraisPortValeur('0');
    }

    const handleFinalFormSubmit = (values: any) => {
        let newFournisseur: IFournisseur = {
            ...values,
     
            seuilFranco: seuilFranco,
            franco: seuilFranco == "Aucun" ? '0' : franco,
            valeur: fraisPort,
            fraisPort: fraisPort == "Aucun" ? '0' : fraisPortValeur,
            esCompte: esCompte,       
        };
        let result = verificationUpdate(newFournisseur, fournisseur);
        result ? toast.warning("Merci de changer au moins une modification") : editFournisseur(newFournisseur);
        baseStore.fournisseurStore.changeButtonState(false)
    }

    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            initialValues={fournisseur!}
            render={({ handleSubmit, invalid, submitting }) => (
                <Form onSubmit={handleSubmit} error>
                    <Grid columns={2} >
                        {/*<Grid.Column>*/}
                        {/*    <h5 >Seuil commande minimum</h5>*/}
                        {/*    <Select*/}
                        {/*        getOptionLabel={e => e.text}*/}
                        {/*        getOptionValue={e => e.value}*/}
                        {/*        options={ITListSeuil}*/}
                        {/*        placeholder={fournisseur!.seuil}*/}
                        {/*        name='seuil'*/}
                        {/*        onChange={e => handleChangeSeuil(e!.value)}*/}
                        {/*    />*/}
                        {/*</Grid.Column>*/}
                        {/*<Grid.Column>*/}
                        {/*    <h5 >Valeur</h5>*/}
                        {/*    <Form.Input name='miniCommande' placeholder='Commande minimum' type='number' defaultValue={fournisseur!.miniCommande} onChange={handleChangeMinCommande} disabled={seuil =='Aucun'} />*/}
                        {/*</Grid.Column>*/}
                        <Grid.Column>
                            <h5 >Seuil Franco</h5>
                            <Select
                                getOptionLabel={e => e.text}
                                getOptionValue={e => e.value}
                                options={ITListSeuilFranco}
                                placeholder={fournisseur!.seuilFranco}
                                name='seuilFranco'
                                onChange={e => handleChangeSeuilFranco(e!.value)}
                            />
                        </Grid.Column>
                        {seuilFranco!='Aucun'&&
                            <Grid.Column>
                                <h5>Valeur</h5>
                                <Form.Input name='franco' placeholder='Franco' type='number' defaultValue={fournisseur!.franco} onChange={handleChangeFranco} disabled={seuilFranco == 'Aucun'} />
                            </Grid.Column>}
                        <Grid.Column>
                            <h5>Frais de port</h5>
                            <Select
                                getOptionLabel={e => e.text}
                                getOptionValue={e => e.value}
                                options={ITListValeur}
                                placeholder={fournisseur!.valeur}
                                name='valeur'
                                onChange={e => handleChangeFraisPort(e!.value)}
                            />
                        </Grid.Column>
                        {fraisPort != 'Aucun'&& 
                        <Grid.Column>
                            <h5 >Valeur</h5>
                            <Form.Input name='fraisPort' defaultValue={fournisseur!.fraisPort} placeholder='Frais de port' type='number' onChange={handleChangeFraisPortValeur} disabled={fraisPort == 'Aucun'} />
                            </Grid.Column>}                
                        {/*<Grid.Column>*/}
                        {/*    <Checkbox  value="enCompte" defaultChecked={fournisseur!.esCompte} onChange={() => setEsCompte(!fournisseur.esCompte)} label="En Compte" />*/}
                        {/*</Grid.Column>*/}
                        <Grid.Column>
                            <h5>Type Marche</h5>
                            <Field name='typeMarcheId' component={InputSelect} options={ITListTypeMarche} placeholder={fournisseur!.typeMarche} value={fournisseur!.typeMarcheId} />
                        </Grid.Column>
                        {/*<Grid.Column>*/}
                        {/*    <h5>Reliquats</h5>*/}
                        {/*    <Checkbox toggle value="repliquats" defaultChecked={fournisseur!.repliquats} onChange={() => setRepliquats(!fournisseur.repliquats)} label="Repliquats" />*/}
                        {/*</Grid.Column>*/}
                        {/*<Grid.Column>*/}
                        {/*    <h5>Remise Fournisseur</h5>*/}
                        {/*    <Checkbox toggle value="remise" defaultChecked={fournisseur!.remise} onChange={() => setRemise(!fournisseur.remise)} label="Remise" />*/}
                        {/*</Grid.Column>*/}
                        {/*<Grid.Column>*/}
                        {/*    <h5>Type Remise</h5>*/}
                        {/*    <Select*/}
                        {/*        getOptionLabel={e => e.text}*/}
                        {/*        getOptionValue={e => e.value}*/}
                        {/*        options={ITListTypeRemise}*/}
                        {/*        placeholder={fournisseur!.typeRemise}*/}
                        {/*        name='typeRemise'*/}
                        {/*        onChange={e => handleChangeTypeRemise(e!.value)}*/}
                        {/*    />*/}
                        {/*</Grid.Column>*/}
                        {/*<Grid.Column>*/}
                        {/*    <h5>Montant Remise</h5>*/}
                        {/*    <Form.Input name='montantRemise' defaultValue={fournisseur!.montantRemise} placeholder='Frais de port' type='number' onChange={handleChangeMontantRemise} disabled={typeRemise == 'Aucun'} />*/}
                        {/*</Grid.Column>*/}
                        {/*<Grid.Column>*/}
                        {/*    <h5>Seuil Minimun</h5>*/}
                        {/*    <Field name='seuilMin' component={TextInput} value={fournisseur!.seuilMin} type='number' />*/}
                        {/*</Grid.Column>*/}
                    </Grid>
                    <div style={{ marginTop: '30px' }}>
                        <Button.Group floated="right">
                            <Button disabled={submitting} onClick={() => { baseStore.fournisseurStore.changeButtonState(!baseStore.fournisseurStore.editFournisseurMode) }} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive disabled={submitting || invalid } loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </div>
                </Form >
            )}
        />
    );
};

export default observer(FournisseurAchatEditForme);