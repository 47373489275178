import React, { useContext, useEffect, useState } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button, Grid, Checkbox } from 'semantic-ui-react';
import TextInput from '../../../app/common/form/inputText';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { IFranchiseur } from '../../../app/models/IFranchiseur';
import { combineValidators, composeValidators, createValidator, isRequired } from 'revalidate';
import Select from 'react-select';
import { ITListBillingType } from '../../../app/common/options/IAchat';

interface IProps {
    editFranchiseur: (profile: Partial<IFranchiseur>) => void;
    franchiseur: IFranchiseur;
}
const isPositive = createValidator(
    message => value => {
        if (value && !/^[1-9]+[0-9]*$/i.test(value)) {
            return message;
        }
    },
    'Invalid number'
);
const validate = combineValidators({
    valeurCommission: composeValidators(
        isPositive({ message: "Veuillez saisir un valeur positif " }))(
            {
                message: "Veuillez saisir un valeur de commision "
            }),
});

const FranchiseurGestionCommercialEditForm: React.FC<IProps> = ({ editFranchiseur, franchiseur }) => {

    const baseStore = useContext(BaseStoreContext);
    const [commissionType, setCommissionType] = useState(franchiseur!.commissionType);
    const [hasRum, setHasRum] = useState(franchiseur.hasRum);
    const [modePaiement, setModePaiement] = useState(franchiseur.modePaiement);
    const [modeReglement, setModeReglement] = useState(franchiseur.modeReglement);
    const { loadModePaiements, modePaiements, loadingInitial } = baseStore.modePaiementStore;
    const { loadModeReglements, modeReglements } = baseStore.modeReglementStore;
    const [commissionValue, setCommissionValue] = useState(franchiseur!.commissionValue);
    const [billedType, setBilledType] = useState<string>(franchiseur!.billingType);

    useEffect(() => {
        loadModePaiements();
        loadModeReglements();
    }, [])
    const handleChangeCommissionValue = (e: any, result: any) => {
        setCommissionValue(result.value);
    }
    const handleFinalFormSubmit = (values: any) => {
        var commission = commissionType ? commissionValue : 0;
        let newFranchiseur = {
            ...values,
            hasRum: hasRum,
            modePaiement: modePaiement,
            modeReglement: modeReglement,
            commissionType: commissionType,
            commissionValue: commission,
            billingType: billedType
        };
        editFranchiseur(newFranchiseur);
    }

    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            validate={validate}
            initialValues={franchiseur!}
            render={({ handleSubmit, invalid, submitting, pristine }) => (
                <Form onSubmit={handleSubmit} error>
                    <div>
                        <Grid columns={2} >
                            <Grid.Column>
                                <h5>Compte Collectif</h5>
                                <Field name='compteCollectif' component={TextInput} type='text' value={franchiseur!.compteCollectif} />
                            </Grid.Column>
                            <Grid.Column style={{ display: 'flex' }}>
                                <Checkbox style={{ marginTop: 20, marginRight: 20 }} name='commissionType' label='Commission' checked={commissionType} onClick={() =>
                                    setCommissionType(!commissionType)} />
                                {commissionType &&
                                    <div>
                                        <h5>Valeur commission</h5>
                                        <Form.Input component={TextInput} name='commissionValue' placeholder='Valeur commission' type="number" defaultValue={franchiseur.commissionValue} onChange={handleChangeCommissionValue} />
                                    </div>
                                }
                            </Grid.Column>
                            <Grid.Column>
                                <h5>Mode de paiement </h5>
                                <Select
                                    isLoading={loadingInitial}
                                    options={modePaiements}
                                    getOptionLabel={e => e!.label.toString()}
                                    getOptionValue={e => e!.paiementId.toString()}
                                    onChange={e => { setHasRum(e?.hasRum!); setModePaiement(e?.label!); }}
                                    placeholder={franchiseur.modePaiement ?? "Séléctionnez ... "}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <h5>Echéance </h5>
                                <Select
                                    isLoading={baseStore.modeReglementStore.loadingInitial}
                                    options={modeReglements}
                                    getOptionLabel={e => e!.label}
                                    getOptionValue={e => e!.reglementId}
                                    onChange={e => { setModeReglement(e?.label!); }}
                                    placeholder={franchiseur.modeReglement ?? "Séléctionnez ... "}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <h5>Facturer par</h5>
                                <Select
                                    defaultValue={ITListBillingType.find(x => x.value == billedType)}
                                    options={ITListBillingType}
                                    getOptionLabel={e => e!.text}
                                    getOptionValue={e => e!.value}
                                    classNamePrefix="select"
                                    onChange={(e) => { setBilledType(e!.value) }}
                                />
                            </Grid.Column>
                            {hasRum &&
                                <Grid.Column>
                                    <h5>RUM</h5>
                                    <Field name='rum' component={TextInput} placeholder='RUM' value={franchiseur!.rum} />
                                </Grid.Column>
                            }
                        </Grid>
                        <Button.Group floated="right" style={{ marginTop: 15 }}>
                            <Button disabled={submitting} onClick={() => { baseStore.franchiseurStore.changeButtonState(!baseStore.franchiseurStore.editFranchiseurMode) }} floated='right' type='button'>Annuler</Button>
                            <Button positive disabled={submitting || invalid} loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </div>
                </Form>
            )}
        />
    );
};

export default observer(FranchiseurGestionCommercialEditForm);