import { useContext, useEffect, useState } from 'react';
import { Form, Button, Segment, Checkbox, Input } from 'semantic-ui-react';
import { Form as FinalForm, Field } from 'react-final-form';
import { combineValidators, composeValidators, createValidator, isRequired } from 'revalidate';
import { BaseStoreContext } from '../../../app/store/baseStore';
import TextInput from '../../../app/common/form/inputText';
import { ITListCustomizedType, ITListModeAppro, ITListProductType, ITListSide } from '../../../app/common/options/IAchat';
import { history } from '../../../index';
import { observer } from 'mobx-react-lite';
import InputNumber from '../../../app/common/form/inputNumber';
import TreeView from '@mui/lab/TreeView';
import { MdOutlineKeyboardArrowRight, MdOutlineKeyboardArrowUp } from 'react-icons/md'
import TreeItem from '@mui/lab/TreeItem';
import { IArticle, IListDeliveryDelay } from '../../../app/models/IArticle';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import { IImpressionList } from '../../../app/models/IImpression';
import { v4 as uuid } from 'uuid';
import { ISupportList } from '../../../app/models/ISupport';
import { IOptionList } from '../../../app/models/IOption';
import Select from 'react-select/';

interface RenderTree {
    id: string;
    parentId: string;
    name: string;
    children?: readonly RenderTree[];
}

const isFloat = createValidator(
    message => value => {
        if (value && !/^[+]?\d+(\.\d+)?$/i.test(value)) {
            return message;
        }
    },
    'Invalid number'
);

const validate = combineValidators({

    weight: composeValidators(
        isRequired, isFloat({ message: "Veuillez saisir un valeur positif " }))({
            message: 'Veuillez saisir le poids unitaire'
        }),
});

const AddArticleContent = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { create, submitting, submittingDeliveryDelay, IDeliveryDelayList, loadDeliveryDelay } = baseRepo.articleStore;
    const [fraisPort, setfraisPort] = useState(false);
    const [hasCommission, setHasCommission] = useState(true);
    const [belongToOpeningPack, setBelongToOpeningPack] = useState(false);
    const [codeFranchiseur, setCodeFranchiseur] = useState<string | undefined>(undefined);
    const { loadFranchiseurs } = baseRepo.franchiseurStore;
    const { loadingImpression, ITListImpression, loadImpressions, submittingImpression } = baseRepo.impressionStore;
    const { loadingOption, loadOptions, ITListOption, submittingOption } = baseRepo.optionStore;
    const { loadingSupport, ITListSupport, loadSupports, submittingSupport } = baseRepo.supportStore;
    const { loadingInitial, ITListTva, loadTvasActifs, defaultTva } = baseRepo.tvaStore;
    const { loadingFamille, loadFamilles, loadSousFamille, ITreeSousFamille } = baseRepo.familleStore;
    const [sousFamille, setSousFamille] = useState<string>();
    const [famille, setFamille] = useState<string>();
    const [validF, setValidF] = useState(true);
    const [validSF, setValidSF] = useState(false);
    const [tva, setTva] = useState<number | undefined>(undefined);
    const [option, setOption] = useState<Array<IOptionList>>([]);
    const [impression, setImpression] = useState<string | undefined>(undefined);
    const [side, setSide] = useState<string>('recto');
    const [productType, setProductType] = useState<string>('print');
    const [customisation, setCustomisation] = useState<string>('None');
    const [support, setSupport] = useState<string | undefined>(undefined);
    const [mode, setMode] = useState<string | undefined>(undefined);
    const [valueImpression, setValueImpression] = useState<IImpressionList | null>();
    const [valueDeliveryDelay, setValueDeliveryDelay] = useState<IListDeliveryDelay | null>();
    const [deliveryDelay, setDeliveryDelay] = useState('');
    const [valueSupport, setValueSupport] = useState<ISupportList | null>();
    const [valueOption, setValueOption] = useState<IOptionList | null>();
    const [libelle, setLibelle] = useState('');
    const [ref, setRef] = useState('');
    const [isHideEco, setIsHideEco] = useState(false);

    const renderTree = (nodes: RenderTree) => (
        <TreeItem style={{ fontSize: 25, paddingTop: 10, backgroundColor: nodes.id == sousFamille ? 'rgba(25, 118, 210, 0.08)' : 'inherit' }} key={nodes.id}
            onClick={() => {
                sousFamille == nodes.id ? setSousFamille(famille!)
                    : setSousFamille(nodes.id)
            }}
            nodeId={nodes.id} label={nodes.name} >
            {Array.isArray(nodes.children)
                ? nodes?.children.map((node: RenderTree) => renderTree(node))
                : null}
        </TreeItem>
    );

    useEffect(() => {
        loadFranchiseurs();
        loadFamilles();
        loadTvasActifs();
        loadImpressions();
        loadOptions();
        loadSupports();
        loadDeliveryDelay();
    }, [])

    const handleChangeFamille = (e: any) => {
        setFamille(e);
        setSousFamille(e);
        loadSousFamille(e);
        setValidSF(true);
        setValidF(false);

    }
    const handleChangeTva = (e: any) => {
        setTva(e);
    }
    const handleChangeOption = (inputValue: string) => {
        setTimeout(() => {
            var id = uuid();
            const newOption: IOptionList = {
                key: id,
                label: inputValue,
                value: inputValue
            }
            setOption((prev) => [...prev, newOption]);
            setValueOption(newOption);
        }, 1000);
    }
    const handleChangeCodeFranch = (e: any) => {
        setCodeFranchiseur(e);

    }
    const handleChangeDeliveryDelay = (inputValue: string) => {
        setTimeout(() => {
            const newOption: IListDeliveryDelay = {
                label: inputValue,
                value: inputValue
            }
            setDeliveryDelay(inputValue);
            setValueDeliveryDelay(newOption);
        }, 1000);

    }
    const handleChangeImpression = (inputValue: string) => {
        setTimeout(() => {
            const newOption: IImpressionList = {
                label: inputValue,
                value: inputValue
            }
            setImpression(inputValue);
            setValueImpression(newOption);
        }, 1000);

    }
    const handleChangeSupport = (inputValue: string) => {
        setTimeout(() => {
            const newOption: ISupportList = {
                label: inputValue,
                value: inputValue
            }
            setSupport(inputValue);
            setValueSupport(newOption);
        }, 1000);
    }
    const handleChangeMode = (e: any) => {
        setMode(e);
    }
    const handleChangeLibelle = (e: any) => {
        let libelle = e.target.value;
        setLibelle(libelle);
        let chaine = libelle.charAt(0);
        while (libelle.indexOf(" ") != -1) {
            chaine = chaine + libelle.charAt(libelle.indexOf(" ") + 1);
            libelle = libelle.substring(libelle.indexOf(" ") + 1);
        }
        setRef(chaine.toUpperCase());

    }

    const handleFinalFormSubmit = (values: any) => {
        var tab: string[] = [];
        option.map((item: any) => {
            tab.push(item.value)
        })
        let newArticle: IArticle = {

            ...values,
            productType: productType,
            customizationType: customisation,
            side: side,
            deliveryCosts: fraisPort,
            famille: sousFamille!,
            agencyName: codeFranchiseur!,
            tva: tva || defaultTva!.id,
            printing: impression,
            paperWeight: support!,
            optionArticles: tab,
            externalReference: ref,
            label: libelle,
            modeAppro: mode,
            deliveryDelay: deliveryDelay,
            belongToOpeningPack: belongToOpeningPack,
            hasCommission: hasCommission,
            isService: false,
            isHideEco: isHideEco,
        };
        create(newArticle);
    }
    return (
        <Segment style={{ backgroundColor: 'rgb(255,255,255)', padding: '20px' }}>
            <FinalForm
                onSubmit={handleFinalFormSubmit}
                validate={validate}
                render={({ handleSubmit, invalid }) => (
                    <Form onSubmit={handleSubmit} error >
                        <Form.Group unstackable widths={3}>
                            <div className="field">
                                <label>Libellé <a style={{ color: 'red', fontSize: 17 }}>*</a></label>
                                <Input name='libelle' placeholder="libelle" onChange={handleChangeLibelle} />
                            </div>
                            <div className="field">
                                <h5>Client  </h5>
                                <AsyncSelect
                                    name="form-field-name"
                                    noOptionsMessage={() => "Code introuvable"}
                                    cacheOptions
                                    defaultOptions
                                    getOptionLabel={e => e!.text}
                                    getOptionValue={e => e!.value}
                                    loadOptions={baseRepo.franchiseurStore.loadCompanyOptions}
                                    onChange={e => handleChangeCodeFranch(e!.value)}
                                />
                            </div>
                            <div className="field">
                                <label>Référence centrale <a style={{ color: 'red', fontSize: 17 }}>*</a></label>
                                <input name='refCentrale' defaultValue={ref} onChange={e => setRef(e.target.value)} />
                            </div>
                        </Form.Group>
                        <Form.Group unstackable widths={3}>
                            <div className="field">
                                <label>Format</label>
                                <Field name='paperFormat' component={TextInput} placeholder='Format' />
                            </div>
                            <div className="field">
                                <label>Poids unitaire <a style={{ color: 'red', fontSize: 17 }}>*</a></label>
                                <Field name='weight' component={InputNumber} type="number" placeholder="Poids unitaire" initialValue={0} />
                            </div>
                            <div className="field">
                                <label>Délai de livraison (Jours)<a style={{ color: 'red', fontSize: 17 }}>*</a></label>
                                <CreatableSelect name="delaiLivraison"
                                    isDisabled={submittingDeliveryDelay}
                                    isLoading={submittingDeliveryDelay}
                                    options={IDeliveryDelayList}
                                    value={valueDeliveryDelay}
                                    placeholder='Délai de livraison'
                                    onChange={(deliveryDelay: any) => {
                                        setDeliveryDelay(deliveryDelay.value);
                                        setValueDeliveryDelay(deliveryDelay);
                                    }}
                                    onCreateOption={handleChangeDeliveryDelay}
                                    formatCreateLabel={() => `Créer`}
                                />
                            </div>
                        </Form.Group>
                        <Form.Group unstackable widths={3}>
                            <div className="field">
                                <label>Impression</label>
                                <CreatableSelect name="impression"
                                    isDisabled={loadingImpression || submittingImpression}
                                    isLoading={loadingImpression || submittingImpression}
                                    options={ITListImpression}
                                    value={valueImpression}
                                    placeholder='Impression'
                                    onChange={(impression: any) => {
                                        setImpression(impression.value);
                                        setValueImpression(impression);
                                    }}
                                    onCreateOption={handleChangeImpression} />
                            </div>
                            <div className="field">
                                <label>Support</label>
                                <CreatableSelect name="support"
                                    isDisabled={loadingSupport || submittingSupport}
                                    isLoading={loadingSupport || submittingSupport}
                                    options={ITListSupport}
                                    value={valueSupport}
                                    placeholder='Support'
                                    onChange={(support: any) => {
                                        setSupport(support.value);
                                        setValueSupport(support);
                                    }}
                                    onCreateOption={handleChangeSupport} />
                            </div>
                            <div className="field">
                                <label> Finition / Option </label>
                                <CreatableSelect
                                    isMulti
                                    isDisabled={loadingOption || submittingOption}
                                    isLoading={loadingOption || submittingOption}
                                    options={ITListOption}
                                    value={valueOption}
                                    placeholder="Finition / Option"
                                    onChange={(option: any) => {
                                        var tab: IOptionList[] = [];
                                        option.map((item: any) => {
                                            tab.push(item)
                                        })
                                        setOption(tab);
                                        setValueOption(option);
                                    }}
                                    onCreateOption={handleChangeOption} />

                            </div>
                        </Form.Group>
                        <Form.Group unstackable widths={mode == "Stock" ? 4 : 3}>
                            <div className="field">
                                <label>Mode appro</label>
                                <Select
                                    getOptionLabel={e => e.text}
                                    getOptionValue={e => e.value}
                                    options={ITListModeAppro}
                                    name='modeAppro'
                                    placeholder='Mode appro'
                                    onChange={e => handleChangeMode(e!.value)}
                                />
                            </div>
                            {mode == "Stock" &&
                                <div className="field">
                                    <label>Seuil (QUANTITE)</label>
                                    <Field name='seuil' component={InputNumber} type="number" placeholder="Seuil" initialValue={0} />
                                </div>
                            }
                            <div className="field">
                                <label>Code barre </label>
                                <Field name='codeBarre' component={TextInput} placeholder='Code barre' />
                            </div>
                            <div className="field">
                                <label>TVA <a style={{ color: 'red', fontSize: 17 }}>*</a></label>
                                <Select name='tva'
                                    getOptionValue={e => e.value.toString()}
                                    getOptionLabel={e => e.text}
                                    isLoading={loadingInitial}
                                    options={ITListTva}
                                    placeholder={loadingInitial ? "TVA par défaut..." : defaultTva?.nom + ' - ' + defaultTva?.taux + '%'}
                                    onChange={(e) => handleChangeTva(e!.value)} />
                            </div>
                        </Form.Group>
                        <Form.Group unstackable widths={4} >
                            <div className="field">
                                <label>Type de Personnalisation</label>
                                <Select name='customizationType'
                                    getOptionLabel={e => e.text}
                                    getOptionValue={e => e.value}
                                    options={ITListCustomizedType}
                                    placeholder="Type de Personnalisation"
                                    defaultValue={ITListCustomizedType[0]}
                                    onChange={(e) => setCustomisation(e!.value)}
                                />
                            </div>
                            <div className="field">
                                <label>Face</label>
                                <Select name='side'
                                    getOptionLabel={e => e.text}
                                    getOptionValue={e => e.value}
                                    options={ITListSide}
                                    placeholder="Façade"
                                    defaultValue={ITListSide[0]}
                                    onChange={(e) => setSide(e!.value)}
                                />
                            </div>
                            <div className="field">
                                <label>Type de Produit</label>
                                <Select name='productType'
                                    getOptionLabel={e => e.text}
                                    getOptionValue={e => e.value}
                                    options={ITListProductType}
                                    placeholder="Type de produit"
                                    defaultValue={ITListProductType[0]}
                                    onChange={(e) => setProductType(e!.value)}
                                />
                            </div>
                            <div className="field">
                                <label>Code comptable </label>
                                <Field name='codeComptable' component={TextInput} type="number" placeholder="Code comptable"/>
                            </div>
                        </Form.Group>
                        <Form.Group unstackable widths={2} >
                            <div className="field">
                                <label>Frais de port </label>
                                <Checkbox value="deliveryCosts" style={{ marginTop: '10px' }} onChange={() => setfraisPort(!fraisPort)} />
                            </div>
                            <div className="field">
                                <label>Appartient au pack d'ouverture </label>
                                <Checkbox value="belongToOpeningPack" style={{ marginTop: '10px' }} onChange={() => setBelongToOpeningPack(!belongToOpeningPack)} />
                            </div>
                            <div className="field">
                                <label>Avec commission</label>
                                <Checkbox value="hasCommission" defaultChecked style={{ marginTop: '10px' }} onChange={() => setHasCommission(!hasCommission)} />
                            </div>
                            <div className="field">
                                <label>Masquer sur la boutique</label>
                                <Checkbox value="isHideEco" style={{ marginTop: '10px' }} onChange={() => setIsHideEco(!isHideEco)} />
                            </div>
                        </Form.Group>
                        <Form.Group unstackable widths={2}>
                            <div className="field">
                                <h5>Famille <a style={{ color: 'red', fontSize: 17 }}>*</a></h5>
                                <AsyncSelect
                                    name="form-field-name"
                                    noOptionsMessage={() => "Code introuvable"}
                                    cacheOptions
                                    defaultOptions
                                    getOptionLabel={e => e!.text}
                                    getOptionValue={e => e!.value}
                                    loadOptions={baseRepo.familleStore.loadFamilleOptions}
                                    onChange={e => handleChangeFamille(e!.value)}
                                />
                            </div>
                            <div className="field" >
                                <h5>Sous famille </h5>
                                <TreeView
                                    aria-label="file system navigator"
                                    defaultCollapseIcon={< MdOutlineKeyboardArrowUp />}
                                    defaultExpandIcon={< MdOutlineKeyboardArrowRight />}
                                    sx={{ height: 150, flexGrow: 1, width: 400, overflowY: 'auto' }}
                                >
                                    {(!loadingFamille && validSF) && ITreeSousFamille.map((item: any) => renderTree(item))}
                                </TreeView>
                            </div>
                        </Form.Group>
                        <Form.Group unstackable style={{ marginLeft: "80%" }} >
                            <Button.Group floated="right">
                                <Button disabled={submitting} onClick={() => history.push('/articles')} floated='right' type='button'>Annuler</Button>
                                <Button.Or />
                                <Button positive disabled={submitting || invalid || validF || deliveryDelay == '' || libelle == "" || ref == ""} loading={submitting} floated='right' type='submit'>Confirmer</Button>
                            </Button.Group>
                        </Form.Group>
                    </Form>
                )}
            />
        </Segment >
    );
}
export default observer(AddArticleContent);
