import React, { useContext } from 'react';
import { Button, Header, Icon, Container } from 'semantic-ui-react';
import { IDispatchOrder } from '../../app/models/IExpedition';
import { BaseStoreContext } from '../../app/store/baseStore';

interface IProps {
    BL: IDispatchOrder;
}

const CancelBL: React.FC<IProps> = ({ BL }) => {
    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    const { canceled, actived } = baseStore.expeditionStore;

    return (
        <>
            {BL.isCanceled ? (
                <Container textAlign='center'>
                    <Header icon='check' content='Activation du BL' style={{ marginLeft: 20 }} />
                    <p>
                        Voulez vous Activer BL N° :  {BL.reference} ?
                    </p>
                    <Button.Group fluid>
                        <Button onClick={() => closeModal()}>
                            <Icon name='cancel' /> Annuler
                        </Button>
                        <Button.Or text='Ou' />
                        <Button
                            name={BL.id}
                            color='green'
                            onClick={(e) => {
                                actived(BL.id)
                                closeModal()
                            }}>
                            <Icon name='checkmark' /> Confirmer
                        </Button>
                    </Button.Group>
                </Container>
            ) : (
                <Container textAlign='center'>
                    <Header icon='cancel' content='Annulation du BL' style={{ marginLeft: 20 }} />
                    <p>
                            Voulez vous Annuler BL N°: {BL.reference} ?
                    </p>
                    <Button.Group fluid>
                        <Button onClick={() => closeModal()}>
                            <Icon name='cancel' /> Annuler
                        </Button>
                        <Button.Or text='Ou' />
                        <Button
                                name={BL.id}
                            color='green'
                            onClick={(e) => {
                                canceled(BL.id)
                                closeModal()
                            }}>
                            <Icon name='checkmark' /> Confirmer
                        </Button>
                    </Button.Group>
                </Container>
            )
            }

        </>

    )
}
export default CancelBL;
