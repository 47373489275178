import React, { useContext, useEffect} from 'react';
import { Table,Message } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { IArticle, IProduct } from '../../../app/models/IArticle';

const ArticleServiceList: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { articlesServices } = baseStore.articleStore;

    if (articlesServices.length < 1) return <Message error content="aucun service pour le moment" />
    else
        return (
            <>
                <Table compact='very' style={{ marginTop: '0em' }} textAlign='center' >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell>Référence</Table.HeaderCell>
                            <Table.HeaderCell>Libellé</Table.HeaderCell>
                            <Table.HeaderCell>Référence externe</Table.HeaderCell>
                            <Table.HeaderCell>TVA</Table.HeaderCell>
                            <Table.HeaderCell>Délai de livraison</Table.HeaderCell>
                            <Table.HeaderCell>Code comptable</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {articlesServices && articlesServices.map((articlesServices: IArticle, key) => (
                            <>
                                <Table.Row key={articlesServices.productId}  >
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell><a href={`/detailService/${articlesServices.productId}`}>{articlesServices.reference}</a></Table.Cell>
                                    <Table.Cell>{articlesServices.label}</Table.Cell>
                                    <Table.Cell>{articlesServices.externalReference}</Table.Cell>
                                    <Table.Cell>{articlesServices.tva}</Table.Cell>
                                    <Table.Cell>{articlesServices.deliveryDelay}</Table.Cell>
                                    <Table.Cell><span style={{ color: "green" }}>{articlesServices.codeComptable}</span></Table.Cell>
                                </Table.Row>

                            </>
                        ))}
                    </Table.Body>
                </Table>
            </>
        );
}

export default observer(ArticleServiceList);