import React from 'react'
import { Tab } from 'semantic-ui-react';
import { IArticle } from '../../../app/models/IArticle';
import ArticleStockDescription from '../../articleStock/articleStockDetails/ArticleStockDescription';
import ArticleDescription from './ArticleDescription'
import ArticlePhoto from './ArticlePhoto';
import TarifFranchiseurDescription from './TarifFranchiseurDescription';
import TarifFournisseurDescription from './TarifFournisseurDescription';
import ArticleComposeDescription from '../../articleCompose/ArticleComposeDescription';
import ProductsAttributesDescription from './ProductsAttributesDescription';
import ArticleFichier from './ArticleFichier';
import ListCF from './ListCF';
import { IArticlePack } from '../../../app/models/IArticleCompose';

interface IProps {
    setActiveTab: (activeIndex: any) => void;
    article: IArticle,
    loading: boolean,
    isInPack: IArticlePack,
    id: string,
}

const ArticleContent: React.FC<IProps> = ({ setActiveTab, isInPack, id }) => {
    const panes = [
        { menuItem: 'Detail article', render: () => <ArticleDescription isInPack={isInPack} /> },
        { menuItem: 'Photos', render: () => <ArticlePhoto /> },
        { menuItem: 'Stock(s)', render: () => <ArticleStockDescription /> },
        { menuItem: 'Tarif de vente', render: () => <TarifFranchiseurDescription /> },
        { menuItem: "Tarif d'achat", render: () => <TarifFournisseurDescription /> },
        isInPack?.id==0 ? { menuItem: 'Produit Composé', render: () => <ArticleComposeDescription /> } : {},
        { menuItem: 'Attributs', render: () => <ProductsAttributesDescription /> },
        { menuItem: 'Commandes Fournisseurs', render: () => <ListCF id={parseInt(id)} /> },
        { menuItem: 'Fichier(s) à télécharger', render: () => <ArticleFichier /> },

    ]
    return (
        <Tab
            menu={{ fluid: true, vertical: true }}
            menuPosition='right'
            panes={panes}
            onTabChange={(e, data) => setActiveTab(data.activeIndex)}
        />
    )
}

export default ArticleContent