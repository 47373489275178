import React, { useContext, useState, useEffect, useRef } from 'react';
import { Table, Header, Label, Button, Reveal } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { Link, useParams } from 'react-router-dom';
import _ from 'lodash';
import { OrderCompanyDto } from '../../../app/models/ICommande';
import NoResult from '../../commande/commandeDashboard/ErrorNoResult';
import Loading from '../../../app/layout/Loading';

const numberFormat = new Intl.NumberFormat('FR-fr', {
    style: 'currency',
    currency: 'EUR',
    useGrouping: true,
});


const getStatus = (status: string) => {
    switch (status) {
        case 'Validated':
            return 'Validée';
        case 'Delivered':
            return 'Livrée';
        case 'Cancelled':
            return 'Annulée';
        case 'WaitingValidation':
            return 'En Attente';
        case 'WaitingToBeSentToCrm':
            return 'Ecommerce';
        case 'SentToCrm':
            return 'Ecommerce';
        case 'Prepared':
            return 'Preparée';
        case 'Rejected':
            return 'Rejetée';
        case 'Dispatched':
            return 'Expediée';
        case 'Facture':
            return 'Facturée';
        case 'Partially_Prepared':
            return 'Preparée partiellement';
        case 'Preparing':
            return 'Préparation EnCours';
        case 'Delivering':
            return 'Livraison EnCours';
        case 'WaitingForOnlinePayment':
            return 'En attente de paiement';
        case 'OnlinePaymentAccepted':
            return 'Paiement en ligne accepté';
        default:
            return '';
    }
}

const getColor = (status: string) => {
    switch (status) {
        case 'Payé':
            return 'green';
        case 'Impayé':
            return 'red';
        case 'Partiellement payé':
            return 'blue';
        default:
            return 'black';
    }
}
interface UrlParams {
    id: string,
}

const CommandesClient: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { commandesCompany, loadCommandesByCompany, loadingCommandeCompany } = baseStore.commandeStore;
    const { id } = useParams<UrlParams>();

    useEffect(() => {
        loadCommandesByCompany(parseInt(id));
    }, []);

        return (
            <>{loadingCommandeCompany ?
                <div style={{ marginTop: 50 }} className="ui segment">
                    <div className="ui active inverted dimmer">
                        <div className="ui medium text loader">Chargement ...</div>
                    </div>
                    <p></p>
                    <p></p>
                </div>
                :
                (
                    commandesCompany?.length != 0 ?
                        (
                    <Table compact='very' style={{ marginTop: '0em' }} textAlign='center' >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Référence</Table.HeaderCell>
                                <Table.HeaderCell>Montant HT</Table.HeaderCell>
                                <Table.HeaderCell>Montant TTC</Table.HeaderCell>
                                <Table.HeaderCell>Etat de paiement</Table.HeaderCell>
                                <Table.HeaderCell>Date</Table.HeaderCell>
                                <Table.HeaderCell>Etat</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                                    {commandesCompany && commandesCompany.map((commande: OrderCompanyDto, key) => (
                                <>
                                    <Table.Row key={commande.id} >
                                        <Table.Cell>
                                            <Header size='small'><Label style={{ marginLeft: '1px', marginTop: '5px', marginBottom: '5px' }} as={Link} to={`/detailCommande/${commande.id}`}>{commande!.reference}</Label></Header>
                                        </Table.Cell>
                                        <Table.Cell>{numberFormat.format(commande.amountWithoutTaxes)}</Table.Cell>
                                        <Table.Cell>{numberFormat.format(commande.amountWithTaxes)}</Table.Cell>
                                        <Table.Cell><span style={{ color: getColor(commande.paymentState) }}>{commande.paymentState}</span></Table.Cell>
                                        <Table.Cell>{new Date(commande.orderDate).toLocaleDateString('en-GB')}</Table.Cell>
                                        <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }} >{getStatus(commande.orderState)}</Table.Cell>
                                    </Table.Row>
                                </>
                            ))}
                        </Table.Body>
                            </Table>) : (
                            <NoResult name='Commande'
                            />
                            )
                )}
            </>
        );
}

export default observer(CommandesClient);