import React, { useContext, useState } from 'react';
import { Button, Header, Icon, Container, Table, Input, Checkbox } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../app/store/baseStore';
import { IDetailExpedition } from '../../app/models/IExpedition';
import { toast } from 'react-toastify';

interface IProps {
    id: number;
    modeAppro: string;
    lines: IDetailExpedition[];
}

const UpdateOrderLineDatesModal: React.FC<IProps> = ({ id, lines, modeAppro }) => {
    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    const { editDispatchLineDate } = baseStore.expeditionStore;
    const [newDate, setNewDate] = useState<string>(new Date().toISOString());
    const [ids, setIds] = useState<number[]>([]);

    const handleChangeSelectedItem = (id: number) => {
        var tab: number[] = ids;
        if (tab.includes(id))
            tab = tab.filter(x => x != id);
        else
            tab.push(id);
        setIds(tab);
    }

    const handleSubmit = () => {
        if (ids.length == 0)
            toast.warning('Selectionnez au moins un article');
        else {
            let newObj: any = {
                id: id,
                date: newDate,
                lines: ids,
                modeAppro: modeAppro
            };
            editDispatchLineDate(newObj);
            closeModal();
        } 
    }

    return (
        <Container textAlign='center'>
            <Header icon='edit' content='Configuration les dates de livraison' style={{ margin: "10px 0 40px 20px" }} />
            <div style={{ display: "flex", marginBottom: 10 }}>
                <Input name='creationDate' onChange={(e: any) => setNewDate(e.target.value)} value={newDate} type='date' />
            </div>
            <Table style={{ border: "none" }}>
                <Table.Body style={{ border: "none" }}>
                    {lines.map(item => (
                        <Table.Row key={item.id}>
                            <Table.Cell>
                                <Checkbox style={{ float: 'right', padding: '0 10px', paddingTop: 10 }} onClick={() => { handleChangeSelectedItem(item.id) }} />
                            </Table.Cell>
                            <Table.Cell>{item.id}</Table.Cell>
                            <Table.Cell>{item.articleLibelle}</Table.Cell>
                        </Table.Row>
                    ))}

                </Table.Body>
            </Table>
            <Button.Group style={{ marginTop: 30, marginBottom: 20 }}>
                <Button onClick={() => { closeModal(); }}>
                    <Icon name='remove' /> Annuler
                </Button>
                <Button
                    color='green'
                    onClick={(e) => {
                        handleSubmit();
                    }}>
                    <Icon name='checkmark' /> Confirmer
                </Button>
            </Button.Group>

        </Container>
        );
}
export default observer(UpdateOrderLineDatesModal);