import React from 'react';
import { Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import AddArticleHeader from './AddArticlesHeader';
import Breadcrumbs from '../../dashboard/breadcrumbs';
import AddArticleService from './AddArticleService';


const AddArticleServicePage: React.FC = ({ }) => {
    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Articles", root: "" },
                    { key: 1, name: "Liste articles", root: "/articles" },
                    { key: 2, name: "Ajouter article service", root: "/addArticles" },
                ]}
            />
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Grid style={{ marginTop: 2 }}>
                            <Grid.Column width={16}>
                                <AddArticleHeader />
                                <AddArticleService />
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>

                </Grid.Row>
            </Grid>
        </div>
    );
}

export default observer(AddArticleServicePage);