import React, { useContext, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Header, Segment, Icon, Container, Form } from 'semantic-ui-react';
import { Form as FinalForm, Field } from 'react-final-form';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import agent from '../../app/agent/agent';
import LoginForm from './LoginForm';
import { BaseStoreContext } from '../../app/store/baseStore';
import TextInput from '../../app/common/form/inputText';
import { combineValidators, isRequired } from 'revalidate';

var r = require('revalidate');
var matchesField = r.matchesField;
const validate = combineValidators({
    password: isRequired('password'),
    confirmPassword: matchesField('password')(
        {
            message: 'Les mots de passe ne sont pas compatibles'
        }
    ),
})

const VerifyEmail: React.FC<RouteComponentProps> = ({ location }) => {

    const Status = {
        Verifying: "Verifying",
        Success: "Success",
        Failed: "Failed"
    }
    const baseStore = useContext(BaseStoreContext);
    const { openModal } = baseStore.modalStore;
    const { token, email } = queryString.parse(location.search);
    const [status, setStatus] = useState(Status.Verifying);

    const resensendEmailHandler = () => {
        agent.userAgent.resendEmailConfirm(email as string).then(() => {
            toast.success('please check you email');
        }).catch((e) => console.log(e));
    }

    const handleFinalSubmit = (values: any) => {
       agent.userAgent.verifyEmail(token as string, email as string, values.password as string).then(() => setStatus(Status.Success)).catch(() => setStatus(Status.Failed))
    }

    const getBody = () => {
        switch (status) {
            case (Status.Verifying):
                return (
                    <FinalForm
                        onSubmit={handleFinalSubmit}
                        validate={validate}
                        render={({ handleSubmit, invalid, pristine, submitting }) => (
                            <Form onSubmit={handleSubmit} >
                                <div className="center"> Vérification ...
                                    <p>Vous pouvez saisir votre nouveau mot de passe</p>
                                    <Field
                                        type='password'
                                        name='password'
                                        component={TextInput}
                                        placeholder='Nouveau mot de passe'
                                    />
                                    <Field
                                        type='password'
                                        name='confirmPassword'
                                        component={TextInput}
                                        placeholder='Confirmez votre mot de passe '

                                    />
                                    <Button
                                        loading={submitting}
                                        floated='right'
                                        disabled={invalid || pristine || submitting}
                                        primary
                                        content="Confirmer Password"
                                        size="small"
                                    />

                                </div>
                            </Form>
                        )}
                    />
                );
            case (Status.Failed):
                return (
                    <div className="center">
                        Vérification ...
                        <p>La vérification a échoué, vous pouvez essayer de renvoyer l'e-mail</p>
                        <Button
                            onClick={resensendEmailHandler}
                            primary
                            content="Resend email"
                            size="small"
                        />
                    </div>
                );
            case (Status.Success):
                return (
                    <div className="center">Success ...
                        <p>Vérification du succès, vous pouvez vous connecter</p>
                        <Button onClick={() => openModal(<LoginForm />)}
                            primary
                            content="Login"
                            size="small"
                        />
                    </div>
                );
        }
    };
    return (
        <Container>
            <Segment placeholder>
                <Header icon>
                    <Icon name="envelope" />
                    Vérification Email
                </Header>
                <Segment.Inline>
                    {getBody()}
                </Segment.Inline>
            </Segment>
        </Container>

    );
}
export default VerifyEmail;