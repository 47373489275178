import { useContext, useEffect, useState } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button, Grid, Radio } from 'semantic-ui-react';
import TextInput from '../../../app/common/form/inputText';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { combineValidators, composeValidators, createValidator, isRequired } from 'revalidate';
import AsyncSelect from 'react-select/async';

const isPositive = createValidator(
    message => value => {
        if (value && !/^[1-9][0-9]*$/i.test(value)) {
            return message;
        }
    },
    'Invalid number'
);
const isFloat = createValidator(
    message => value => {
        if (value && !/^[+]?\d+(\.\d+)?$/i.test(value)) {
            return message;
        }
    },
    'Invalid number'
);
const validate = combineValidators({
    prix: composeValidators(
        isRequired, isFloat({ message: "Veuillez saisir un valeur positif " }))({
            message: 'Veuillez saisir le prix'
        }),
    quantite: composeValidators(
        isRequired, isPositive({ message: "Veuillez saisir un valeur valid " }))({
            message: 'Veuillez saisir la quantité'
        }),
});

const AffectTarifFranchiseurToArticle = () => {
    const baseStore = useContext(BaseStoreContext);
    const { article } = baseStore.articleStore;
    const { create } = baseStore.tarifFournisseurStore;
    const { loadFournisseurs } = baseStore.fournisseurStore;
    const [valeur, setValeur] = useState<string>("Fournisseur");
    const [showText, setShowText] = useState(true);
    const [showText1, setShowText1] = useState(false);
    const [valid, setValid] = useState(true);
    const [franchiseur, setFranchiseur] = useState<string>('');
    const [fournisseur, setFournisseur] = useState<string>('');

    const handleChange = (e: any, result: any) => {
        setValeur(result.value);
    }

    const handleFinalFormSubmit = (values: any) => {
        let newTarifFournisseurArticle = {
            ...values,
            franchiseur: showText1 ? franchiseur : null,
            fournisseur: showText ? fournisseur : 0,
            article: article?.productId,
            type: valeur,
        };
        create(newTarifFournisseurArticle);
        baseStore.articleStore.changeButtonState(false)
    }
    useEffect(() => {
        loadFournisseurs();
    }, [loadFournisseurs])

    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            validate={validate}
            render={({ handleSubmit, invalid, pristine, submitting }) => (
                <Form onSubmit={handleSubmit} error>
                    <Grid style={{ marginTop: 20, marginBottom: 10 }}>
                        <h5>Type : </h5>
                        <Radio
                            label='Fournisseur'
                            name='radioGroup'
                            value='Fournisseur'
                            onChange={handleChange}
                            checked={valeur == 'Fournisseur'}
                            onClick={() => { setShowText(true); setShowText1(false); setValid(true) }}
                        />
                        &nbsp;
                        &nbsp;
                        <Radio
                            label='Franchiseur'
                            name='radioGroup'
                            value='Franchiseur'
                            checked={valeur == 'Franchiseur'}
                            onChange={handleChange}
                            onClick={() => { setShowText1(true); setShowText(false); setValid(true) }}
                        />
                    </Grid>
                    <Grid columns={4} >
                        <Grid.Column width={7}>
                            {showText &&
                                <Grid.Column>
                                    <h5 >Fournisseur : </h5>
                                    <AsyncSelect
                                        name="form-field-name"
                                        placeholder="Fournisseur"
                                        noOptionsMessage={() => "Code introuvable"}
                                        cacheOptions
                                        defaultOptions
                                        getOptionLabel={e => e!.text}
                                        getOptionValue={e => e!.value}
                                        loadOptions={baseStore.fournisseurStore.loadFournisseurOptions}
                                        onChange={(e) => { setFournisseur(e!.value); setValid(false) }} />
                                </Grid.Column>
                            }
                            {showText1 &&
                                <Grid.Column>
                                    <h5 >Franchiseur :</h5>
                                    <AsyncSelect
                                        name="form-field-name"
                                        placeholder="Franchiseur"
                                        noOptionsMessage={() => "Code introuvable"}
                                        cacheOptions
                                        defaultOptions
                                        getOptionLabel={e => e!.text}
                                        getOptionValue={e => e!.value}
                                        loadOptions={baseStore.franchiseurStore.loadCompanyOptions}
                                        onChange={e => { setFranchiseur(e!.value); setValid(false) }}
                                    />
                                </Grid.Column>
                            }
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <h5 >Quantité</h5>
                            <Field name='quantite' component={TextInput} type='number' />
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <h5>Prix</h5>
                            <Field name='prix' component={TextInput} type='number' />
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <h5>Réf. Article</h5>
                            <Field name='productReference' component={TextInput} type='text' />
                        </Grid.Column>
                    </Grid>
                    <br/>
                    <div >
                        <Button.Group floated="right">
                            <Button disabled={submitting} onClick={() => { baseStore.articleStore.changeButtonState(!baseStore.articleStore.editArticleMode) }} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive disabled={submitting || invalid || pristine || valid} loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </div>
                </Form >
            )}
        />
    );
};
export default observer(AffectTarifFranchiseurToArticle);