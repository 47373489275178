import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Header, Item } from 'semantic-ui-react';


const AddFactureHeader: React.FC = () => {
   

    return (
        <Grid>
            <Grid.Column width={16}>
                <Item.Group>

                    <Item>
                        <Item.Content>
                            <Header as='h2' icon='address card outline'></Header>
                            <Header as='h2'>{'Ajouter une Facture'}</Header>
                            <Button
                                floated="right"
                                content="Retour vers les factures"
                                color="orange"
                                icon='arrow alternate circle left outline'
                                basic
                                as={Link}
                                to="/factureDashboard"
                            />
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Grid.Column>
        </Grid>
        );
}

export default observer(AddFactureHeader);