import React, { useContext, useEffect, useState, useRef } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { Form, Button, Grid, Input, Checkbox, Table } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { v4 as uuid } from 'uuid';
import { BsArrowReturnRight } from 'react-icons/bs'
import Select from 'react-select';
import { useParams } from 'react-router-dom';
const setPaiementType = (status: string) => {
    switch (status) {
        case '31':
            return 'Carte Bancaire';
        case '03':
            return 'Chèque de banque';
        case '01':
            return 'Espèce';
        case '15':
            return 'Prélèvement Bancaire';
        case '11':
            return 'Virement Bancaire';
        default:
            return status;
    }
}
interface UrlParams {
    id: string;
}
const AddReglementInvoice: React.FC = () => {
    const { id } = useParams<UrlParams>();
    const baseRepo = useContext(BaseStoreContext);
    const { ITListModePaiements, loadModePaiements, loadingInitial } = baseRepo.modePaiementStore;
    const { ITListAccount, loadAccounts, loadingInitialAccount } = baseRepo.accountStore;
    const { reglement, create, submitting, reglementsNotConsumedCount, reglementsNotConsumed, affectReglementFacture } = baseRepo.reglementStore;
    const { facture } = baseRepo.factureStore;
    const [montant, setMontant] = useState<number | undefined>();
    const [valeurRadio, setValeurRadio] = useState(false);
    const [modePaiement, setModePaiement] = useState<string | undefined>("Prélèvement Bancaire");
    const [account, setAccount] = useState<string | undefined>("Société générale");

    useEffect(() => {
        loadModePaiements();
        loadAccounts();
    }, []);

    let defaultDate = new Date()
    defaultDate.setDate(defaultDate.getDate())
    const [dateDeposite, setDate] = useState<string>(new Date().toLocaleDateString("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" }));
    const handleFinalFormSubmit = (values: any) => {
        let newReglement = {
            ...values,
            idReglement: uuid(),
            commande: 0,
            creationDate: dateDeposite,
            invoice: id,
            montant: montant,
            franchiseurPayment: valeurRadio,
            modePaiement: modePaiement,
            account: account,
            type: "RGL",
        };
        create(newReglement);
        baseRepo.reglementStore.changeButtonStateAdd(!baseRepo.reglementStore.addMode)
    }
    const handleFinalForm = (values: any) => {
        let newReglement = {
            idReglement: values.idReglement,
            modePaiement: values.modePaiement,
            creationDate: values.creationDate,
            account: values.account,
            montant: values.montant,
            type: values.type,
            commande: 0,
            facture: id,
        };
        affectReglementFacture( newReglement);
    }
    const inputRef = useRef<Input>(null);
    const handleChangeButton = () => {
        if (facture?.amountWithTaxes != 0) {
            var x = facture?.remainingPayment != 0 ? facture!.remainingPayment : facture!.amountWithTaxes;
            inputRef.current?.focus();
            setMontant(parseFloat(x.toFixed(2)));
        }
    }
    const handleChangeMontant = (e: any, result: any) => {
        setMontant(parseFloat(result.value));

    }
    const verification = () => {
        if (facture!.idCompany != 0)
            return true;

    }

    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            initialValues={reglement!}
            render={({ handleSubmit, invalid }) => (
                <Form onSubmit={handleSubmit} error>
                    <Grid columns={2} className="WithoutSpace">
                        {reglementsNotConsumedCount != 0 &&
                            <Grid.Row>
                                <h5>Choisir une reglement:</h5>
                                <Table basic>

                                    {reglementsNotConsumed &&
                                        reglementsNotConsumed.map(reglement => (
                                            <Table.Body>
                                                <Table.Cell><a>{reglement.reference}</a></Table.Cell>
                                                <Table.Cell>{reglement.type == "RGL" ? reglement.montant : 0}</Table.Cell>
                                                <Table.Cell>{setPaiementType(reglement.modePaiement)}</Table.Cell>
                                                <Table.Cell>
                                                    <Button
                                                        onClick={() => {
                                                            handleFinalForm(reglement)
                                                        }}
                                                        icon='check'
                                                        basic
                                                        color='green'
                                                    />

                                                </Table.Cell>

                                            </Table.Body>

                                        ))}
                                </Table>
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                <h5>-----------------------------Ou vous pouvez ajouter un nouveau reglement---------------------------------</h5>
                            </Grid.Row>

                        }


                        <Grid.Row>
                            <Grid.Column>
                                <h5>Montant:</h5>
                                <Input label={<Button icon={<BsArrowReturnRight />} type='button' onClick={handleChangeButton} basic />}
                                    ref={inputRef}
                                    placeholder="Montant"
                                    type='number'
                                    step="0.1"
                                    style={{ width: '283px' }}
                                    onChange={handleChangeMontant}
                                    value={montant}
                                    max={facture?.remainingPayment} />
                            </Grid.Column>
                            <Grid.Column>
                                <h5>Compte bancaire :</h5>
                                <Select
                                    isLoading={loadingInitialAccount}
                                    options={ITListAccount}
                                    getOptionLabel={e => e!.text}
                                    getOptionValue={e => e!.value}
                                    onChange={e => { setAccount(e?.text); }}
                                    placeholder={account ?? "Séléctionnez ... "}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <h5>Mode de paiement:</h5>
                                <Select
                                    isLoading={loadingInitial}
                                    options={ITListModePaiements}
                                    getOptionLabel={e => e!.text}
                                    getOptionValue={e => e!.value}
                                    onChange={e => { setModePaiement(e?.text); }}
                                    placeholder={modePaiement ?? "Séléctionnez ... "}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <h5>Date de paiement:</h5>
                                <Input name='creationDate' value={dateDeposite} onChange={(e: any) => setDate(e.target.value)} placeholder='date de paiement' type='date' />
                            </Grid.Column>
                        </Grid.Row>
                        {verification() &&
                            <Grid.Row>
                                <Grid.Column>
                                    <h5>Paiement franchiseur:</h5>
                                    <Checkbox
                                        name='radioGroup'
                                        value='franchiseurPayment'
                                        onChange={() => setValeurRadio(!valeurRadio)}
                                    />
                                </Grid.Column>
                            </Grid.Row>}
                    </Grid>
                    <div style={{ marginTop: '20px', marginRight: '1px' }}>
                        <Button.Group floated="right">
                            <Button
                                onClick={() => {
                                    baseRepo.reglementStore.changeButtonStateAdd(!baseRepo.reglementStore.addMode)
                                }}
                                floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive disabled={submitting || invalid} loading={submitting} floated='right' type='submit' >Confirmer</Button>
                        </Button.Group>
                    </div>
                </Form>
            )}
        />
    );
};
export default observer(AddReglementInvoice);


