import { BaseStore } from "./baseStore";
import { observable, action, runInAction, reaction, computed } from 'mobx';
import agent from '../agent/agent';
import { toast } from "react-toastify";
import { IAdresse, IAdresseList } from "../models/IAdresse";
import { setAdressesProps } from "../common/util/util";

export default class AdresseStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
    }
    @observable loadingAdresseAction = false;
    @observable loadingIsMain = false;
    @observable loadingAdresse = false;
    @observable loadingInitial = false;
    @observable submitting = false;
    @observable deletingAdresse = false;
    @observable adresse: IAdresse | null = null;
    @observable adresses: IAdresse[] = [];
    @observable ITListAdresse: IAdresseList[] = [];
    @observable adresseRegestry = new Map();
    @observable editAdresse: boolean = false;
    @observable addAdresse: boolean = false;

    @action changeButtonStateEdit = async (newState: boolean) => {
        this.editAdresse = newState;
    }

    @action changeButtonStateAdd = async (newState: boolean) => {
        this.addAdresse = newState;
    }
    getAdresse = (id: string) => {
        return this.adresseRegestry.get(id);
    }

    @action create = async (values: IAdresse) => {
        this.submitting = true;
        try {
            var id=await agent.adresseAgent.create(values);
            runInAction(() => {
                this.adresseRegestry.set(values.id, values)
                this.adresse = values;
                this.adresse.id = id;
                this.adresses.push(this.adresse);
                this.submitting = false;
                toast.success("ajout éffectué avec succès");
            })

        } catch (e) {
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    @action loadAdresses = async () => {
        this.loadingInitial = true;
        try {
            const adresses = await agent.adresseAgent.list();
            runInAction(() => {
                this.adresses = adresses;
                adresses.forEach((adresse) => {
                    setAdressesProps(adresse);
                })
                this.loadingInitial = false;
            })
        } catch (e) {
            toast.error("Problem loading !");
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }

    @action loadAdressesByClient = async (client: number) => {
        this.loadingInitial = true;
        try {
            const adresses = await agent.adresseAgent.getAdresseBycompany(client);
            runInAction(() => {
                this.adresses = adresses;
                adresses.forEach((adresse) => {
                    setAdressesProps(adresse);
                    this.adresseRegestry.set(adresse.id, adresse);
                    let adresses: IAdresseList = {
                        key: adresse.id,
                        text: adresse.address + ", " + adresse.city + ", " + adresse.postalCode,
                        value: adresse.id,
                    }
                    this.ITListAdresse.push(adresses)
                })
                this.loadingInitial = false;
            })
        } catch (e) {
            toast.error("Problem loading !");
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }

    @action edit = async (values: IAdresse,type:string) => {
        this.submitting = true;
        try {
            await agent.adresseAgent.update(values);
            runInAction(() => {
                var index = this.adresses.findIndex(x => x.id == values.id)
                this.adresses[index] = values;
                type == "franchiseur" ? this._baseStore.franchiseurStore.updateAdress(values) : this._baseStore.clientStore.updateAdress(values);
                toast.success("Modification éffectuée avec succès");
                this.submitting = false;
            })

        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action deleteAdresse = async (id: string) => {
        this.loadingAdresseAction = true;
        try {
            await agent.adresseAgent.delete(id);
            runInAction(() => {
                this.loadingAdresseAction = false;
                this.adresses = this.adresses.filter(a => a.id !== id);
                toast.success("suppression éffectuée avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de suppréssion !");
            runInAction(() => {
                this.loadingAdresseAction = false;
            })
        }

    }

    @action isMain = async (id: string,isMain:string,type:string) => {
        this.loadingIsMain = true;
        try {
          
           await agent.adresseAgent.setMainAdress(id, isMain);
            runInAction(() => {
                var company = this.adresses.find(x => x.id == id)?.company;
                const index = this.adresses.findIndex(x => x.id == id)
                if (isMain == "Invoice") {
                    this.adresses[this.adresses.findIndex(x => x.isMainInvoiceAddress && x.company === company)].isMainInvoiceAddress = false;
                    this.adresses[index].isMainInvoiceAddress = true;
                }
                else {
                    this.adresses[this.adresses.findIndex(x => x.isMainDeliveryAddress && x.company === company)].isMainDeliveryAddress = false;
                    this.adresses[index].isMainDeliveryAddress = true;
                }
                type == "franchiseur" ? this._baseStore.franchiseurStore.setInfo(this.adresses[index], isMain): this._baseStore.clientStore.setInfo(this.adresses[index], isMain);
                this.loadingIsMain = false;
                toast.success("adresse par default effectuée avec succès");
            })
        } catch (e) {
            console.log(e);
            runInAction(() => {
                this.loadingIsMain = false;
            })
        }
    };

}