import { useContext, useEffect, useState } from 'react';
import { Form, Button, Segment, Accordion, Grid, Checkbox } from 'semantic-ui-react';
import { Form as FinalForm, Field } from 'react-final-form';
import { combineValidators, composeValidators, isRequired, createValidator } from 'revalidate';
import { BaseStoreContext } from '../../../app/store/baseStore';
import TextInput from '../../../app/common/form/inputText';
import { v4 as uuid } from 'uuid';
import { Link } from 'react-router-dom';
import InputNumber from '../../../app/common/form/inputNumber';
import InputSelect from '../../../app/common/form/inputSelect';
import { ITListBillingType, ITListJoursFermeture, ITListTypeCivilite } from '../../../app/common/options/IAchat';
import { observer } from 'mobx-react-lite';
import { IFranchiseur } from '../../../app/models/IFranchiseur';
import { toast } from 'react-toastify';
import Select from 'react-select';
import AsyncSelect from "react-select/async";

const isFloat = createValidator(
    message => value => {
        if (value && !/^[+]?\d+(\.\d+)?$/i.test(value)) {
            return message;
        }
    },
    'Invalid number'
);

const isValidEmail = createValidator(
    message => value => {
        if (value && !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,4}$/i.test(value)) {
            return message;
        }
    },
    'Invalid email address'
);

const isValidtelephone = createValidator(
    message => value => {
        if (value && !/^((\+||00)33||0)*[1-9]([ .-]*[0-9]{2}){4}$/i.test(value)) {
            return message;
        }
    },
    'Invalid number'
);

const isValidSiret = createValidator(
    message => value => {
        if (value && !/^[0-9]{1}[0-9]{13}$/i.test(value)) {
            return message;
        }
    },
    'Invalid Siret'
);


const isValidCodePostal = createValidator(
    message => value => {
        if (value && !/^\d{5}(?:[-\s]\d{4})?$/i.test(value)) {
            return message;
        }
    },
    'Code postal invalid'
);

const validate = combineValidators({

    mobile: composeValidators(isValidtelephone({
        message: 'Entrer un numéro de tel valide'
    }))
        ({
            message: "Veuillez saisir le telephone de l'agence"
        }),
    commissionValue: composeValidators(isFloat)
        ({ message: "Veuillez saisir valeur positive " }),
    siretNumber: composeValidators(isValidSiret)
        ({ message: "Veuillez saisir 14 chiffres " }),
    capital: composeValidators(isFloat)
        ({ message: "Veuillez saisir une valeur correcte " }),
    deliveryPostalCode: composeValidators(isValidCodePostal)
        ({ message: "Veuillez saisir un valid code postal" }),
    invoicePostalCode: composeValidators(isValidCodePostal)
        ({ message: "Veuillez saisir un valid code postal" }),

});

const AddFranchiseurContent = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { create } = baseRepo.franchiseurStore;
    const { loadFormeJuridiques, ITListFormeJuridique } = baseRepo.formeJuridiqueStore;


    const [state, setState] = useState({ activeIndex: 0 });
    const handleClick = (e: any, titleProps: any) => {
        const { index } = titleProps
        const { activeIndex } = state
        const newIndex = activeIndex === index ? -1 : index
        setState({ activeIndex: newIndex })
    }
    const handleClickNext = () => {
        const { activeIndex } = state
        const newIndex = activeIndex + 1
        setState({ activeIndex: newIndex })
    }
    const handleClickPrev = () => {
        const { activeIndex } = state
        const newIndex = activeIndex - 1
        setState({ activeIndex: newIndex })
    }
    const baseStore = useContext(BaseStoreContext);
    const { loadModePaiements, modePaiements } = baseStore.modePaiementStore;
    const { loadModeReglements, modeReglements } = baseStore.modeReglementStore;
    const { loadFranchiseurs, getName } = baseStore.franchiseurStore;
    const { getLastClient, countClient } = baseStore.clientStore;
    const [deliveryAddress1, setdeliveryAddress1] = useState();
    const [deliveryAddress2, setdeliveryAddress22] = useState();
    const [deliveryCity, setdeliveryCity] = useState();
    const [deliveryCountry, setdeliveryCountry] = useState<any>("FR");
    const [deliveryPhone, setdeliveryPhone] = useState();
    const [invoiceAddress1, setinvoiceAddress1] = useState();
    const [invoiceAddress2, setinvoiceAddress2] = useState();
    const [deliveryEmails, setdeliveryEmails] = useState();
    const [invoiceCity, setinvoiceCity] = useState();
    const [invoicePhone, setInvoicePhone] = useState();
    const [invoiceCountry, setinvoiceCountry] = useState<any>("FR");
    const [invoiceEmails, setinvoiceEmails] = useState();
    const [typeCommission, setTypeCommission] = useState(false);
    const [mAdresse, setMAdresse] = useState(false);
    const [deliveryCompany, setdeliveryCompany] = useState();
    const [invoiceCompany, setinvoiceCompany] = useState();
    const [hasRum, setHasRum] = useState(false);
    const [modePaiement, setModePaiement] = useState("");
    const [modeReglement, setModeReglement] = useState("");
    const [commissionValue, setCommissionValue] = useState("");
    const [codePostalLiv, setCodePostalLiv] = useState(null);
    const [codePostalFac, setCodePostalFac] = useState(null);
    const [days, setDays] = useState<string[]>([]);
    const [socialName, setSocialName] = useState<string>();
    const [companyName, setCompanyName] = useState<string>();
    const [billedType, setBilledType] = useState<string>("BillByCompany");
    const [labelModePaiement, setLabelModePaiement] = useState("Mode de Paiement");
    const [labelModeReglement, setLabelModeReglement] = useState("Echéance");

    const handleChange = (e: any, result: any) => {
        var days: string[] = [];
        e.map((item: any) => (
            days.push(item.value)
        ))
        setDays(days);
    }

    const handleChangeDeliveryAddress1 = (e: any, result: any) => {
        setdeliveryAddress1(result.value);
    }
    const handleChangedeliveryAddress2 = (e: any, result: any) => {
        setdeliveryAddress22(result.value);
    }
    const handleChangedeliveryCity = (e: any, result: any) => {
        setdeliveryCity(result.value);
    }
    const handleChangedeliveryCountry = (value: any) => {
        setdeliveryCountry(value.iso);
    }
    const handleChangedeliveryEmail = (e: any, result: any) => {
        setdeliveryEmails(result.value);
    }
    const handleChangedeliveryPhone = (e: any, result: any) => {
        setdeliveryPhone(result.value);
    }
    const handleChangeinvoiceAddress1 = (e: any, result: any) => {
        setinvoiceAddress1(result.value);
    }
    const handleChangeinvoiceAddress2 = (e: any, result: any) => {
        setinvoiceAddress2(result.value);
    }
    const handleChangeinvoicePostalCode = (value: any) => {
        setCodePostalFac(value.codePostal);
        setinvoiceCity(value.city);
    }
    const handleChangePostalCodeinvoice = (e: any, result: any) => {
        setCodePostalFac(result.value);
    }
    const handleChangedeliveryPostalCode = (value: any) => {
        setCodePostalLiv(value.codePostal);
        setdeliveryCity(value.city);
    }
    const handleChangePostalCode = (e: any, result: any) => {
        setCodePostalLiv(result.value);
    }
    const handleChangeinvoiceCity = (e: any, result: any) => {
        setinvoiceCity(result.value);
    }
    const handleChangeinvoiceCountry = (value: any) => {
        setinvoiceCountry(value.iso);
    }
    const handleChangeinvoiceEmails = (e: any, result: any) => {
        setinvoiceEmails(result.value);
    }
    const handleChangeinvoicePhone = (e: any, result: any) => {
        setInvoicePhone(result.value);
    }
    const handleChangeinvoiceCompany = (e: any, result: any) => {
        setinvoiceCompany(result.value);
    }
    const handleChangeDeliveryCompany = (e: any, result: any) => {
        setdeliveryCompany(result.value);
    }
    const handleChangeCommissionValue = (e: any, result: any) => {
        setCommissionValue(result.value);
    }
    const handleChangeSocialName = (e: any, result: any) => {
        setSocialName(result.value);
    }
    const handleChangeCompanyName = (e: any, result: any) => {
        setCompanyName(result.value);
    }
    const handleFinalFormSubmit = async (values: any) => {
        var stringDays = "";
        if (days.length == 0)
            stringDays = "NR";
        else {
            for (let i = 0; i < days.length; i++) {
                var text = days[i];
                if (i == days.length - 1 && days.length > 1)
                    text = " et " + text;
                if (i > 0 && i < days.length - 1)
                    text = " , " + text;
                stringDays += text;
            }
        }
        var commission = typeCommission ? commissionValue : 0;
        let newFranchiseur: IFranchiseur = {
            ...values,
            franchiseurId: uuid(),
            typeCommission: typeCommission,
            commissionValue: commission,
            isActive: true,
            mAdresse: mAdresse,
            deliveryAddress1: deliveryAddress1,
            deliveryAddress2: deliveryAddress2,
            deliveryCity: deliveryCity,
            deliveryPostalCode: codePostalLiv,
            invoiceAddress1: mAdresse ? deliveryAddress1 : invoiceAddress1,
            invoiceAddress2: mAdresse ? deliveryAddress2 : invoiceAddress2,
            invoiceCity: mAdresse ? deliveryCity : invoiceCity,
            invoicePostalCode: mAdresse ? codePostalLiv : codePostalFac,
            compteAuxilliere: countClient,
            deliveryCountry: deliveryCountry,
            invoiceCountry: mAdresse ? deliveryCountry : invoiceCountry,
            deliveryEmails: deliveryEmails,
            deliveryPhone: deliveryPhone,
            invoiceEmails: mAdresse ? deliveryEmails : invoiceEmails,
            invoicePhone: mAdresse ? deliveryPhone : invoicePhone,
            deliveryCompany: deliveryCompany ?? companyName,
            invoiceCompany: (mAdresse && invoiceCompany==null)  ? deliveryCompany : invoiceCompany,
            hasRum: hasRum,
            modePaiement: modePaiement,
            modeReglement: modePaiement == 'Carte Bancaire' ? 'Comptant' : modeReglement,
            deliveryClosingDays: stringDays,
            socialName: socialName,
            companyName: companyName,
            billingType: billedType,
        };
        let test = await getName(values.companyName, values.socialName)
        if (companyName == "" || socialName == "" || values.nomResponsable == undefined || values.prenomResponsable == undefined || values.email == undefined || values.mobile == undefined) {
            toast.warning("Vérifier vos champs");
        }
        else {
            test ? toast.warning('Ce réseau existe déja') : create(newFranchiseur);
        }
    }

    useEffect(() => {
        loadModePaiements();
        loadFormeJuridiques()
        loadModeReglements();
        loadFranchiseurs();
        getLastClient(0, "Franchiseur")
    }, [])

    const { activeIndex } = state
    return (
        <Segment raised style={{ padding: 25 }}>
            <FinalForm
                onSubmit={handleFinalFormSubmit}
                validate={validate}
                render={({ handleSubmit, invalid, pristine, submitting }) => (
                    <Form onSubmit={handleSubmit}>
                        <Accordion fluid styled>
                            <Accordion.Title
                                active={activeIndex === 0}
                                index={0}
                                onClick={handleClick}
                                style={{ fontSize: 'large', color: 'black' }}>
                                <i className="dropdown icon"></i>
                                Informations Agence
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 0}>
                                <Form.Group unstackable widths={2}>
                                    <div className="field">
                                        <label>Enseigne/Réseau * </label>
                                        <Form.Input name='companyName' component={TextInput} placeholder="Enseigne/Réseau" onChange={handleChangeCompanyName}
                                        />
                                    </div>
                                    <div className="field">
                                        <label>Raison Social * </label>
                                        <Form.Input name='socialName' component={TextInput} placeholder='Raison Social ' onChange={handleChangeSocialName} />
                                    </div>
                                </Form.Group>
                                <Form.Group unstackable widths={3}>
                                    <div className="field">
                                        <label>Nom du responsable * </label>
                                        <Field name='nomResponsable' component={TextInput} placeholder='Nom du responsable' />
                                    </div>
                                    <div className="field">
                                        <label>Prenom du responsable</label>
                                        <Field name='prenomResponsable' component={TextInput} placeholder='Prenom du responsable' />
                                    </div>
                                    <div className="field">
                                        <label>Civilite </label>
                                        <Field component={InputSelect} options={ITListTypeCivilite} name='civilite' defaultValue="Monsieur" placeholder="Choisir civilite" />
                                    </div>
                                </Form.Group>
                                <Form.Group unstackable widths={3}>
                                    <div className="field">
                                        <label>Téléphone Mobile *</label>
                                        <Field name='mobile' component={InputNumber} placeholder="Telephone mobile" />
                                    </div>
                                    <div className="field">
                                        <label>Téléphone</label>
                                        <Field name='telephone' component={InputNumber} placeholder="Telephone" maxLength={4} />
                                    </div>
                                    <div className="field">
                                        <label>Email *</label>
                                        <Field name='email' component={TextInput} placeholder="E-mail" type='email' />
                                    </div>
                                </Form.Group>
                                <Button
                                    onClick={handleClickNext}
                                    color='orange'
                                    content='suivant'
                                    style={{ width: '20%', marginLeft: '80%' }}
                                    fluid
                                    as={Link}
                                />
                            </Accordion.Content>
                        </Accordion>
                        <Accordion fluid styled>
                            <Accordion.Title
                                onClick={handleClick}
                                active={activeIndex === 1}
                                index={1}
                                style={{ fontSize: 'large', color: 'black' }}>
                                <i className="dropdown icon"></i>
                                Informations Adresses

                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 1}>
                                <Grid columns={2}>
                                    <div className="field" style={{ marginLeft: '90%' }}>
                                        <label>Même adresse </label>
                                        <Checkbox toggle value="mAdresse" onChange={() => setMAdresse(!mAdresse)} />
                                    </div>
                                    <Grid.Row>

                                        <Grid.Column>
                                            <div className="field">
                                                <label>Société de livraison </label>
                                                <Form.Input name='deliveryComapany' placeholder='Société de livraison' defaultValue={companyName} onChange={handleChangeDeliveryCompany} />
                                            </div>
                                            <div className="field">
                                                <label>Adresse de livraison </label>
                                                <Form.Input name='deliveryAddress1' placeholder='Adresse de livraison' onChange={handleChangeDeliveryAddress1} />
                                            </div>
                                            <div className="field">
                                                <label>Complément d'adresse livraison</label>
                                                <Form.Input name='deliveryAddress2' placeholder='Complément d adresse livraison' onChange={handleChangedeliveryAddress2} />
                                            </div>
                                            <div className="field">
                                                <label>Pays de livraison </label>
                                                <AsyncSelect
                                                    name="form-field-name"
                                                    noOptionsMessage={() => "Code introuvable"}
                                                    cacheOptions
                                                    defaultOptions
                                                    placeholder={deliveryCountry}
                                                    value={deliveryCountry}
                                                    getOptionLabel={e => e!.pays + '(' + e!.iso + ')'}
                                                    getOptionValue={e => e!.iso}
                                                    loadOptions={baseRepo.countryCodeStore.getCountryCodes}
                                                    onChange={handleChangedeliveryCountry}
                                                />
                                            </div>
                                            {deliveryCountry == "FR" ?
                                                <div className="field">
                                                    <label>Code postal livraison</label>
                                                    <AsyncSelect
                                                        name="form-field-name"
                                                        noOptionsMessage={() => "Code introuvable"}
                                                        cacheOptions
                                                        defaultOptions
                                                        placeholder={codePostalLiv ?? "Code postal livraison"}
                                                        value={codePostalLiv}
                                                        getOptionLabel={e => e!.city + '(' + e!.codePostal + ')'}
                                                        getOptionValue={e => e!.codePostal}
                                                        loadOptions={baseRepo.cityCodeStore.getCityCodes}
                                                        onChange={handleChangedeliveryPostalCode}
                                                    />
                                                </div>
                                                :
                                                <Grid.Column>
                                                    <label>Code postal livraison</label>
                                                    <Form.Input name='postalCode' component={TextInput} type='text' onChange={handleChangePostalCode} placeholder="Code postal facturation" />
                                                </Grid.Column>
                                            }

                                            <div className="field">
                                                <label>Ville de livraison </label>
                                                <Form.Input name='deliveryCity' value={deliveryCity} placeholder="Ville de Livraison" onChange={handleChangedeliveryCity} />
                                            </div>

                                            <div className="field">
                                                <label>Email de livraison </label>
                                                <Form.Input name='deliveryEmails' placeholder="Email de Livraison" onChange={handleChangedeliveryEmail} />
                                            </div>
                                            <div className="field">
                                                <label>Téléphone de livraison </label>
                                                <Form.Input name='deliveryPhone' placeholder="Téléphone de Livraison" onChange={handleChangedeliveryPhone} />
                                            </div>
                                            <div className="field">
                                                <label>Zone d'intervention</label>
                                                <Field name='interventionZone' component={TextInput} placeholder="Zone d'intervention" />
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <div className="field">
                                                <label>Société de facturation </label>
                                                <Form.Input name='invoiceCompany' placeholder='Société de Facturation' disabled={mAdresse && invoiceCompany == null} defaultValue={(mAdresse && invoiceCompany == null) ? deliveryCompany : invoiceCompany} onChange={handleChangeinvoiceCompany} />
                                            </div>
                                            <div className="field">
                                                <label>Adresse de facturation </label>
                                                <Form.Input name='invoiceAddress1' placeholder='adresse de Facturation' disabled={mAdresse} defaultValue={mAdresse ? deliveryAddress1 : null} onChange={handleChangeinvoiceAddress1} />
                                            </div>
                                            <div className="field">
                                                <label>Complément d'adresse facturation</label>
                                                <Form.Input name='invoiceAddress2' placeholder='Complément d adresse facturation' disabled={mAdresse} defaultValue={mAdresse ? deliveryAddress2 : null} onChange={handleChangeinvoiceAddress2} />
                                            </div>
                                            <div className="field">
                                                <label>Pays de facturation </label>
                                                <AsyncSelect
                                                    name="form-field-name"
                                                    noOptionsMessage={() => "Code introuvable"}
                                                    cacheOptions
                                                    defaultOptions
                                                    isDisabled={mAdresse}
                                                    defaultValue={mAdresse ? deliveryCountry : null}
                                                    placeholder={mAdresse ? deliveryCountry : invoiceCountry}
                                                    value={mAdresse ? deliveryCountry : invoiceCountry}
                                                    getOptionLabel={e => e!.pays + '(' + e!.iso + ')'}
                                                    getOptionValue={e => e!.iso}
                                                    loadOptions={baseRepo.countryCodeStore.getCountryCodes}
                                                    onChange={handleChangeinvoiceCountry}
                                                />
                                            </div>
                                            {invoiceCountry == "FR" ?
                                                <div className="field">
                                                    <label>Code postal facturation </label>
                                                    <AsyncSelect
                                                        name="form-field-name"
                                                        noOptionsMessage={() => "Code introuvable"}
                                                        cacheOptions
                                                        defaultOptions
                                                        isDisabled={mAdresse}
                                                        defaultValue={mAdresse ? codePostalLiv : null}
                                                        placeholder={(mAdresse ? codePostalLiv : codePostalFac) ?? "Code postal facturation"}
                                                        value={mAdresse ? codePostalLiv : codePostalFac}
                                                        getOptionLabel={e => e!.city + '(' + e!.codePostal + ')'}
                                                        getOptionValue={e => e!.codePostal}
                                                        loadOptions={baseRepo.cityCodeStore.getCityCodes}
                                                        onChange={handleChangeinvoicePostalCode}
                                                    />
                                                </div> :
                                                <div className="field">
                                                    <label>Code postal facturation</label>
                                                    <Form.Input name='postalCode' component={TextInput} type='text' onChange={handleChangePostalCodeinvoice} placeholder="Code postal facturation" />
                                                </div>
                                            }

                                            <div className="field">
                                                <label>Ville de facturation </label>
                                                <Form.Input name='invoiceCity' placeholder='Ville de Facturation' disabled={mAdresse} defaultValue={mAdresse ? deliveryCity : invoiceCity} onChange={handleChangeinvoiceCity} />
                                            </div>

                                            <div className="field">
                                                <label>Email de facturation </label>
                                                <Form.Input name='invoiceEmails' placeholder='Email de Facturation' disabled={mAdresse} defaultValue={mAdresse ? deliveryEmails : null} onChange={handleChangeinvoiceEmails} />
                                            </div>
                                            <div className="field">
                                                <label>Téléphone de facturation </label>
                                                <Form.Input name='invoicePhone' placeholder='Téléphone de Facturation' disabled={mAdresse} defaultValue={mAdresse ? deliveryPhone : null} onChange={handleChangeinvoicePhone} />
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <div style={{ width: '100%', marginTop: '20px' }}>
                                    <Button
                                        onClick={handleClickPrev}
                                        color='orange'
                                        content='Retour'
                                        style={{ width: '20%', display: 'inline-block' }}
                                        fluid
                                        basic
                                        as={Link}
                                    />
                                    <Button
                                        onClick={handleClickNext}
                                        color='orange'
                                        content='Suivant'
                                        style={{ width: '20%', display: 'inline-block', marginLeft: '59.3%' }}
                                        fluid
                                        as={Link}
                                    />
                                </div>
                            </Accordion.Content>
                        </Accordion>
                        <Accordion fluid styled>
                            <Accordion.Title
                                onClick={handleClick}
                                active={activeIndex === 2}
                                index={2}
                                style={{ fontSize: 'large', color: 'black' }}>
                                <i className="dropdown icon"></i>
                                Spécification
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 2}>
                                <Grid columns={2}>
                                    <Grid.Column>
                                        <h5>Jours de fermeture</h5>
                                        <Select
                                            isMulti
                                            placeholder={'Choisisez les jours ..'}
                                            options={ITListJoursFermeture}
                                            getOptionLabel={e => e!.text}
                                            getOptionValue={e => e!.value}
                                            classNamePrefix="select"
                                            onChange={handleChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <h5>Horaires d'ouverture</h5>
                                        <Field name='deliveryOpeningHours' component={TextInput} placeholder="Horaires d'ouverture" />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <h5>Digicode</h5>
                                        <Field name='deliveryDigiCode' component={TextInput} placeholder='Digicode' type='text' />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <h5>Interphone</h5>
                                        <Field name='deliveryInterphone' component={TextInput} placeholder='Interphone' type='text' />
                                    </Grid.Column>
                                </Grid>
                                <div style={{ width: '100%', marginTop: '20px' }}>
                                    <Button
                                        onClick={handleClickPrev}
                                        color='orange'
                                        content='Retour'
                                        style={{ width: '20%', display: 'inline-block' }}
                                        fluid
                                        basic
                                        as={Link}
                                    />
                                    <Button
                                        onClick={handleClickNext}
                                        color='orange'
                                        content='Suivant'
                                        style={{ width: '20%', display: 'inline-block', marginLeft: '59.3%' }}
                                        fluid
                                        as={Link}
                                    />
                                </div>
                            </Accordion.Content>
                        </Accordion>
                        <Accordion fluid styled>
                            <Accordion.Title
                                onClick={handleClick}
                                active={activeIndex === 3}
                                index={3}
                                style={{ fontSize: 'large', color: 'black' }}>
                                <i className="dropdown icon"></i>
                                Informations Commerciales
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 3}>
                                <Grid columns={2} >
                                    <Grid.Column unstackable>
                                        <div className="field">
                                            <label>Compte Collectif </label>
                                            <Field component={TextInput} name='compteCollectif' defaultValue='411000' placeholder='Compte Collectif ' />
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column style={{ marginBottom: 0 }}>
                                        <div className="field">
                                            <label>Compte Auxiliere </label>
                                            <Field component={TextInput} name='compteAuxiliere' placeholder={countClient} />
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column style={{ marginBottom: 0 }}>
                                        <h5>Mode de paiement </h5>
                                        <Select
                                            isLoading={baseStore.modePaiementStore.loadingInitial}
                                            options={modePaiements}
                                            getOptionLabel={e => e!.label.toString()}
                                            getOptionValue={e => e!.paiementId.toString()}
                                            onChange={e => { setHasRum(e?.hasRum!); setModePaiement(e?.label!); setLabelModePaiement(e!.label); }}
                                            placeholder={labelModePaiement}
                                        />
                                    </Grid.Column>
                                    <Grid.Column style={{ marginBottom: 0 }}>
                                        <h5>Echéance </h5>
                                        <Select
                                            isLoading={baseStore.modeReglementStore.loadingInitial}
                                            options={modeReglements}
                                            getOptionLabel={e => e!.label}
                                            getOptionValue={e => e!.reglementId}
                                            onChange={e => { modePaiement == 'Carte Bancaire' ? setModeReglement('comptant') : setLabelModeReglement(e!.label); setModeReglement(e!.label); }}
                                            placeholder={modePaiement == 'Carte Bancaire' ? 'comptant' : labelModeReglement}
                                            isDisabled={modePaiement == 'Carte Bancaire'}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <h5>Facturer par</h5>
                                        <Select
                                            placeholder={'Choisisez un choix ..'}
                                            options={ITListBillingType}
                                            getOptionLabel={e => e!.text}
                                            getOptionValue={e => e!.value}
                                            classNamePrefix="select"
                                            onChange={(e) => { setBilledType(e!.value) }}
                                        />
                                    </Grid.Column>
                                    {hasRum &&
                                        <Grid.Column style={{ marginBottom: 0 }}>
                                            <h5>RUM</h5>
                                            <Field name='rUM' component={TextInput} placeholder='RUM' />
                                        </Grid.Column>
                                    }
                                    <Grid.Column style={{ marginBottom: 0 }}>
                                        <Grid>
                                            <Grid.Row style={{ padding: 0 }} columns={2}>
                                                <Grid.Column style={{ padding: 0, margin:0 }}>
                                                    <div className="field">
                                                        <label>Commission</label>
                                                        <Checkbox value="commissionType" onClick={() => setTypeCommission(!typeCommission)} style={{ marginBottom: 0 }} />
                                                    </div>
                                                </Grid.Column>
                                                <Grid.Column style={{ padding: 0, margin: 0 }}>
                                                    {typeCommission &&
                                                        <div className="field">
                                                            <label>Valeur commission</label>
                                                            <Form.Input component={TextInput} name='commissionValue' placeholder='Valeur commission' type="number" defaultValue="0" onChange={handleChangeCommissionValue} />
                                                        </div>
                                                    }
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Grid.Column>

                                </Grid>
                                <div style={{ width: '100%', marginTop: '20px' }}>
                                    <Button
                                        onClick={handleClickPrev}
                                        color='orange'
                                        content='Retour'
                                        style={{ width: '20%', display: 'inline-block' }}
                                        fluid
                                        basic
                                        as={Link}
                                    />
                                    <Button
                                        onClick={handleClickNext}
                                        color='orange'
                                        content='Suivant'
                                        style={{ width: '20%', display: 'inline-block', marginLeft: '59.3%' }}
                                        fluid
                                        as={Link}
                                    />
                                </div>
                            </Accordion.Content>
                        </Accordion>
                        <Accordion fluid styled>
                            <Accordion.Title
                                onClick={handleClick}
                                active={activeIndex === 4}
                                index={4}
                                style={{ fontSize: 'large', color: 'black' }}>
                                <i className="dropdown icon"></i>
                                Informations Juridiques
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 4}>
                                <Form.Group unstackable widths={3}>
                                    <div className="field">
                                        <h5>Forme juridique</h5>
                                        <Field component={InputSelect} options={ITListFormeJuridique} name='formeJuridique' />
                                    </div>
                                    <div className="field">
                                        <label>Capital</label>
                                        <Field name='capitalValue' component={InputNumber} type="number" placeholder='Capital' />
                                    </div>
                                    <div className="field">
                                        <label>Montant pack Ouverture</label>
                                        <Field name='OpeningPackAmount' component={TextInput} type="number" placeholder="Montant pack Ouverture" />
                                    </div>
                                </Form.Group>
                                <Form.Group unstackable widths={2}>
                                    <div className="field">
                                        <label>RCS</label>
                                        <Field name='rcs' component={TextInput} placeholder='RCS' />
                                    </div>
                                    <div className="field">
                                        <label>Siret </label>
                                        <Field name='siretNumber' component={TextInput} placeholder='Siret' />
                                    </div>
                                </Form.Group>
                                <Form.Group unstackable widths={2}>
                                    <div className="field">
                                        <label>Tva IntraCommunautaire</label>
                                        <Field name='europeanTvaNumber' component={TextInput} placeholder='Tva IntraCommunautaire' />
                                    </div>
                                    <div className="field">
                                        <label>Code Ape</label>
                                        <Field name='apeCode' component={TextInput} placeholder='Code Ape' />
                                    </div>
                                </Form.Group>
                                <Form.Group unstackable widths={2}>
                                    <div className="field">
                                        <label>Site Web </label>
                                        <Field name='webSite' component={TextInput} placeholder='Site Web' />
                                    </div>
                                    <div className="field">
                                        <label>Numéro d'Agrement</label>
                                        <Field name='agreementNumber' component={TextInput} placeholder="Numéro d'Agrement" />
                                    </div>
                                </Form.Group>
                                <div style={{ width: '100%' }}>
                                    <Button
                                        onClick={handleClickPrev}
                                        color='orange'
                                        content='Retour'
                                        style={{ width: '20%', display: 'inline-block' }}
                                        fluid
                                        basic
                                        as={Link}
                                    />
                                    <Button
                                        disabled={submitting || invalid || pristine}
                                        loading={submitting}
                                        color='orange'
                                        content='Sauvegarder'
                                        style={{ width: '20%', display: 'inline-block', marginLeft: '59.3%' }}
                                        fluid
                                    />
                                </div>
                            </Accordion.Content>
                        </Accordion>
                    </Form>
                )}
            />
        </Segment >
    );
}
export default observer(AddFranchiseurContent);
