
export const Permission = "Permissions";

export enum Action {
    Create,
    Edit,
    Delete,
    View
}

export enum Module {
    Articles,
    Stock,
    Commandes,
    Devis,
    Factures,
    Roles,
    Users,
    Params,
    Compta,
    CommandesFournisseur,
    FacturesFournisseur,
    Tiers
}