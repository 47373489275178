import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { Button, Form, FormInput, Grid, Label } from "semantic-ui-react";
import { BaseStoreContext } from "../../app/store/baseStore";
import { Form as FinalForm } from 'react-final-form';
import Select, { components } from "react-select";
import { FaFileExcel, FaSearch, FaSort } from "react-icons/fa";
import AsyncSelect from 'react-select/async';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { MdCancel } from "react-icons/md";
import { numberFormat } from "../../app/common/options/IStyle";

const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <FaSort color='green' />
        </components.DropdownIndicator>
    );
};

const customStyles = {
    option: (provided: any, state: { isSelected: any; }) => ({
        ...provided,
        color: state.isSelected ? 'orange' : 'grey',
        backgroundColor: state.isSelected ? "#eee" : "white",
        borderBottom: '1px solid #eee',
    }),
    valueContainer: (props: any) => ({
        ...props,
        paddingTop: 0,
        height: '39.201px',
        minHeight: '39.201px',
    }),
    control: (baseStyles: any, state: any) => ({
        ...baseStyles,
        height: '39.201px',
        minHeight: '39.201px',
    }),
}

const ExpeditionFilter = () => {
    const { RangePicker } = DatePicker;
    const baseStore = useContext(BaseStoreContext);
    const { setPredicate, predicate, ExportExpeditions, loadingExportEx, totalHt, totalTtc, loadingInitialExpedition } = baseStore.expeditionStore;
    const { loadModePaiements, modePaiements } = baseStore.modePaiementStore;
    const [searchResults, setSearchResults] = useState([]);
    const [selectedLabelSort, setSelectedLabelSort] = useState<any>('Filtrer...');
    const [selectedKey, setSelectedKey] = useState<any>('');
    const [selectedSort, setSelectedSort] = useState<any>('');
    const [selectedType, setSelectedType] = useState<any>('');
    const [selectedLabelType, setSelectedLabelType] = useState<any>('Type');
    const [selectedLabelTri, setSelectedTri] = useState<any>('Trier...');
    const [searchDateCommande, setSearchDateCommande] = useState<any>('');
    const [searchDateCommandeTo, setSearchDateCommandeTo] = useState<any>('');
    const [searchResultsClient, setSearchResultsClient] = useState<any>('');
    const [selectedLabelClient, setSelectedLabelClient] = useState<any>('Client');
    const [searchCommande, setSearchCommande] = useState<any>('');
    const [selectedModeAppro, setSelectedModeAppro] = useState<any>('');
    const [selectedLabelModeAppro, setSelectedLabelModeAppro] = useState<any>('Mode Appro');
    const [selectedModePaiement, setModePaiement] = useState<any>('');
    const [selectedLabelModePaiement, setLabelModePaiement] = useState<any>('Mode Paiement');
    const [searchArticle, setSearchArticle] = useState<any>('');
    const [searchResultsClientAll, setSearchResultsClientAll] = useState(false);

    useEffect(() => {
        loadModePaiements();
        //if (predicate.get('searchMultiple') != null) {
        //    setSelectedKey('Expedie');
        //    setSelectedLabelSort("Expediée")
        //}
    }, [])
    const options = [
        { key: 'All', label: 'Tous' },
        { key: 'EnAttente', label: 'En Attente' },
        { key: 'Termine', label: 'Terminée' },
        { key: 'Expedie', label: 'Expediée' },
        { key: 'Facture', label: 'Facturée' },
        { key: 'Annule', label: 'Annulée' },
    ];
    const optionsTri = [
        { key: 'DateUp', text: 'Plus recent', value: 'DateUp' },
        { key: 'DateDown', text: 'Plus ancien', value: 'DateDown' },
        ,
    ];
    const optionsType = [
        { key: 'BR', text: 'Bon de retour', value: 'BR' },
        { key: 'BL', text: 'Bon de livraison', value: 'BL' },
        ,
    ];
    const optionsModeAppro = [
        { key: 'Stock', text: 'En Stock', value: 'Stock' },
        { key: 'Direct', text: 'Vente direct', value: 'Direct' },
        ,
    ];
    const rangePresets: {
        label: string;
        value: [Dayjs, Dayjs];
    }[] = [
            { label: '7 derniers jours', value: [dayjs().add(-7, 'd'), dayjs()] },
            { label: '14 derniers jours', value: [dayjs().add(-14, 'd'), dayjs()] },
            { label: '30 derniers jours', value: [dayjs().add(-30, 'd'), dayjs()] },
            { label: '90 derniers jours', value: [dayjs().add(-90, 'd'), dayjs()] },
        ];

    const handleChangeCommande = (event: any) => {
        setSearchCommande(event.target.value);
    }
    const handleChange = (event: any) => {
        setSearchResults(event.target.value);
    }

    const handleFilter = (key: string, value: string) => {
        setSelectedKey(key);
        setSelectedLabelSort(value)
    }
    const handleChangeClient = (value: any) => {
        setSearchResultsClient(value.id);
        setSearchResultsClientAll(value.all);
        setSelectedLabelClient(value!.agencyName + "-" + value!.companyName + (value!.all ? "-Tous les réseaux" : ""));
    }
    const handleChangeTri = (value: any) => {
        setSelectedSort(value.value);
        let labelSelected: any = value.text;
        setSelectedTri(labelSelected);
    }
    const handleChangeType = (value: any) => {
        setSelectedType(value.value);
        let labelSelected: any = value.text;
        setSelectedLabelType(labelSelected);
    }
    const handleChangeModeAppro = (value: any) => {
        setSelectedModeAppro(value.value);
        let labelSelected: any = value.text;
        setSelectedLabelModeAppro(labelSelected);
    }
    const handleInputChangeArticle = (value: any) => {
        setSearchArticle(value.target.value);
    }
    const onRangeChange = (dates: null | (Dayjs | null)[]) => {
        if (dates) {
            setSearchDateCommande(dates[0]?.toISOString());
            setSearchDateCommandeTo(dates[1]?.toISOString());
        }
    };
    const handleSearch = (event: any) => {
        if (event == 'clique') {
            let obj: any = {
                searchClient: searchResultsClient,
                search: searchResults,
                key: selectedKey,
                sort: selectedSort,
                dateCommande: searchDateCommande,
                dateCommandeTo: searchDateCommandeTo,
                searchCommande: searchCommande,
                type: selectedType,
                modeAppro: selectedModeAppro,
                modePaiement: selectedModePaiement,
                article: searchArticle,
                searchClientAll: searchResultsClientAll,
                selectedLabelClient: selectedLabelClient,
            }; ExportExpeditions(obj);
        }
        else if (event == 'clear') {
            setSelectedKey('');
            setSearchCommande('')
            setSelectedSort('')
            setSelectedType('')
            setSelectedLabelSort('Filtrer ...')
            setSelectedLabelType('Type')
            setSelectedTri('Trier ...')
            setSearchDateCommande('')
            setSearchDateCommandeTo('')
            setSearchResults([])
            setPredicate('all', 'true')
            setSearchResultsClient('')
            setSelectedModeAppro('')
            setSelectedLabelModeAppro('Mode Appro')
            setLabelModePaiement('Mode Paiement')
            setSearchArticle('')
            setModePaiement('')
            setSelectedLabelClient('Client')
            setSearchResultsClientAll(false);
        }
        else if (event == 'submit') {
            var predicate = new Map()
            predicate.set('search', searchResults)
            predicate.set('searchClient', searchResultsClient)
            predicate.set('searchCommande', searchCommande)
            predicate.set('key', selectedKey)
            predicate.set('sort', selectedSort)
            predicate.set("dateCommande", searchDateCommande);
            predicate.set("dateCommandeTo", searchDateCommandeTo);
            predicate.set("type", selectedType);
            predicate.set("modeAppro", selectedModeAppro);
            predicate.set("modePaiement", selectedModePaiement);
            predicate.set("article", searchArticle);
            predicate.set('searchClientAll', searchResultsClientAll)
            if (searchResults.length >= 2 || searchResultsClient != "" || searchCommande != "" || selectedKey != "" || selectedSort != ""
                || searchDateCommande != "" || searchDateCommandeTo != "" || selectedType != ""
                || selectedModeAppro != "" || selectedModePaiement != "" || searchArticle != "")
                setPredicate('searchMultiple', predicate)
            else if (searchResults!.length == 0 && searchResultsClient.length == 0 && selectedKey.length == 0 && selectedSort.length == 0
                && searchDateCommande.length == 0 && searchDateCommandeTo.length == 0 && selectedType.length == 0 && selectedModeAppro.length == 0 && selectedModePaiement.length == 0 && searchArticle.length == 0)
                setPredicate('all', 'true')
        }
    }
    return (
        <FinalForm
            onSubmit={handleSearch}
            render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit} error style={{ width: "100%", fontSize: 14 }}>
                    <Grid>
                        <Grid.Row columns={5} style={{ padding: 0, margin: 0 }}>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <FormInput
                                    icon='search'
                                    type="text"
                                    placeholder="Expédition"
                                    value={searchResults}
                                    onChange={handleChange}
                                    style={{ width: '100%' }}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <FormInput
                                    icon='search'
                                    type="text"
                                    placeholder="Commande"
                                    value={searchCommande}
                                    onChange={handleChangeCommande}
                                    style={{ height: '100%' }} />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <AsyncSelect
                                    name="form-field-name"
                                    noOptionsMessage={() => "Code introuvable"}
                                    cacheOptions
                                    defaultOptions
                                    placeholder={selectedLabelClient}
                                    value={searchResultsClient}
                                    getOptionLabel={e => e!.agencyName + "-" + e!.companyName + (e!.all ? "-Tous les réseaux" : "")}
                                    loadOptions={baseStore.clientStore.loadOptions}
                                    onChange={handleChangeClient}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable={false}
                                    components={{ DropdownIndicator }}
                                    styles={customStyles}
                                    options={options}
                                    value={selectedKey}
                                    getOptionLabel={e => e.label}
                                    onChange={(e) => handleFilter(e.key, e.label)}
                                    placeholder={selectedLabelSort ?? 'Filtrer les articles'}
                                    isClearable={true}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <Select
                                    components={{ DropdownIndicator }}
                                    styles={customStyles}
                                    options={optionsType}
                                    value={selectedType}
                                    getOptionLabel={e => e.text}
                                    onChange={(e) => handleChangeType(e)}
                                    placeholder={selectedLabelType}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <FormInput
                                    icon='search'
                                    type="text"
                                    placeholder="Article"
                                    name="article"
                                    value={searchArticle}
                                    style={{ height: '100%' }}
                                    onChange={handleInputChangeArticle}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <Select
                                    components={{ DropdownIndicator }}
                                    styles={customStyles}
                                    options={optionsModeAppro}
                                    value={selectedModeAppro}
                                    getOptionLabel={e => e.text}
                                    onChange={(e) => handleChangeModeAppro(e)}
                                    placeholder={selectedLabelModeAppro}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <Select
                                    isLoading={baseStore.modePaiementStore.loadingInitial}
                                    options={modePaiements}
                                    value={selectedModePaiement}
                                    getOptionValue={e => e!.paiementId.toString()}
                                    onChange={e => { setModePaiement(e?.label!); setLabelModePaiement(e?.label!) }}
                                    placeholder={selectedLabelModePaiement}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>

                                <RangePicker
                                    presets={rangePresets}
                                    onChange={onRangeChange}
                                    format={'DD/MM/YYYY'}
                                    placeholder={["Début", "Fin"]}
                                    style={{ width: '100%', height: '39.201px' }}
                                />

                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0, display: 'flex' }}>
                                <Select
                                    isSearchable={false}
                                    components={{ DropdownIndicator }}
                                    styles={customStyles}
                                    options={optionsTri}
                                    value={selectedSort}
                                    getOptionLabel={e => e.text}
                                    onChange={(e) => handleChangeTri(e)}
                                    placeholder={selectedLabelTri} />
                                <Button style={{ padding: 5, margin: "0 0 0 10px", color: 'blue', background: "none" }} type='submit' onClick={(e: any) => handleSearch('submit')} >
                                    <FaSearch />
                                </Button>
                                <Button style={{ float: "right", padding: 5, margin: 0, color: 'green', background: "none" }} >
                                    <MdCancel style={{ fontSize: 17 }} onClick={(e: any) => handleSearch('clear')} />
                                </Button>
                            </Grid.Column >
                        </Grid.Row>
                    </Grid>
                    {!loadingInitialExpedition &&
                        <>
                            <Label>Total HT : {numberFormat.format(totalHt)}</Label>
                            <Label>Total TTC : {numberFormat.format(totalTtc)}</Label>
                        </>
                    }
                    <Button
                        size="tiny"
                        loading={loadingExportEx}
                        style={{ height: 32 }}
                        floated='right'
                        content="Export Excel"
                        className="andi-btn filled success"
                        icon={(<FaFileExcel style={{ marginRight: "10px" }} />)}
                        onClick={(e: any) => handleSearch('clique')}

                    />
                </Form >
            )}
        />
    );
};
export default observer(ExpeditionFilter);