import { observer } from "mobx-react-lite"
import { useContext } from "react";
import { Button, Container, Header, Icon, Segment } from "semantic-ui-react";
import Loading from "../../app/layout/Loading";
import { BaseStoreContext } from "../../app/store/baseStore";

interface IProps {
    role: string;
}

const DeleteRoleModal: React.FC<IProps>  = ({ role }) => {
    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    const { listeUsersByRole, deleteRole } = baseStore.usersRoleStore;

    if (listeUsersByRole.length == 0) { <Loading content="Chargement..." />}
    return (
        <Container textAlign='center'>
            <Header icon='trash' content={'Supprimer le role ' + role} style={{ marginLeft: 20 }} />
            <Segment>
            {listeUsersByRole.length > 0 ?
                (
                    <p>
                        {listeUsersByRole.join(' et ')} {listeUsersByRole.length > 1 ? ' ont ' : ' a '}  le role : {role}
                    </p>
                ) : (
                    <p>
                        Voulez vous supprimer ce tarif ?
                    </p>
                    )
                }
                </Segment>

            <Button.Group fluid>
                <Button onClick={() => closeModal()}>
                    <Icon name='remove' /> Annuler
                </Button>
                <Button.Or text='Ou' />
                <Button
                    color='green'
                    onClick={(e) => {
                        deleteRole(role);
                        closeModal()
                    }}>
                    <Icon name='checkmark' /> Confirmer
                </Button>
            </Button.Group>
        </Container>
        );
}
export default observer(DeleteRoleModal);