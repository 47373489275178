import { BaseStore } from "./baseStore";
import { observable, action, runInAction } from 'mobx';
import agent from '../agent/agent';
import { toast } from "react-toastify";
import { ICountPaiement, IModePaiement, IModePaiementList } from "../models/IModePaiement";
import { setModePaiementProps } from "../common/util/util";

export default class ModePaiementStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
    }
    @observable loadingModePaiementAction = false;
    @observable loadingModePaiement = false;
    @observable loadingInitial = false;
    @observable submitting = false;
    @observable deletingModePaiement = false;
    @observable modePaiement: IModePaiement | null = null;
    @observable modePaiements: IModePaiement[] = [];
    @observable ITListModePaiements: IModePaiementList[] = [];
    @observable modePaiementRegestry = new Map();
    @observable editModePaiement: boolean = false;
    @observable addMode: boolean = false;
    @observable progress: number = 50;
    @observable countPaiement: ICountPaiement | null = null;

    @action changeButtonStateEdit = async (newState: boolean) => {
        this.editModePaiement = newState;
    }

    @action changeButtonStateAdd = async (newState: boolean) => {
        this.addMode = newState;
    }

    @action loadModePaiements = async () => {
        this.loadingInitial = true;
        try {
            const modePaiements = await agent.modePaiementAgent.list();
            runInAction(() => {
                this.ITListModePaiements = []
                this.modePaiements = modePaiements;
                modePaiements.forEach((item) => {
                    this.ITListModePaiements.push({ key: item.paiementId, text: item.label, value: item.paiementId, hasRum: item.hasRum })
                });
                this.loadingInitial = false;
            })
        } catch (e) {
            toast.error("Problem loading mode paiements!");
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }

    @action loadModePaiement = async (id: string) => {

        this.loadingModePaiement = true;
        let modePaiement = this.getModePaiement(id);
        if (modePaiement) {
            this.modePaiement = modePaiement;
            this.loadingModePaiement = false;
            return modePaiement;
        }
        else {
            try {
                modePaiement = await agent.modePaiementAgent.details(id);
                runInAction(() => {
                    modePaiement.dateCreation = modePaiement.dateCreation.split('T')[0];
                    this.modePaiementRegestry.set(modePaiement.formeJuridiqueId, modePaiement);
                    this.modePaiement = modePaiement;
                    this.loadingModePaiement = false;
                })
                return modePaiement;
            } catch (e) {
                runInAction(() => {
                    this.loadingModePaiement = false;
                })
                console.log(e);
            }
        }
    }

    getModePaiement = (id: string) => {
        return this.modePaiementRegestry.get(id);
    }

    @action create = async (values: IModePaiement) => {
        this.submitting = true;
        const user = await agent.userAgent.current();
        try {
            await agent.modePaiementAgent.create(values);
            runInAction(() => {
                this.modePaiementRegestry.set(values.paiementId, values)
                this.modePaiement = values;
                this.modePaiement.owner = user.username;
                this.modePaiements.push(this.modePaiement);
                this.submitting = false;
                toast.success("ajout éffectué avec succès");
            })

        } catch (e) {
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    @action edit = async (values: Partial<IModePaiement>) => {
        this.submitting = true;
        this.progress = 80;
        try {
            await agent.modePaiementAgent.update(values);
            let modePaiement = await agent.modePaiementAgent.details(values.paiementId!);
            runInAction(() => {
                this.modePaiements = this.modePaiements.filter(a => a.paiementId != values.paiementId);
                this.modePaiements.push(modePaiement);
                toast.success("Modification éffectuée avec succès");  
                this.submitting = false;
            })

        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action deleteModePaiement = async (id: string) => {
        this.loadingModePaiementAction = true;
        var mode = await agent.modePaiementAgent.details(id);
        if (mode.label == "Carte bleu") {
            runInAction(() => {
                toast.warning("Ce mode de paiement est par defaut et ne peut pas étre supprimer");
                this.loadingModePaiementAction = false;
            })
        }
        else {
            try {
                await agent.modePaiementAgent.delete(id);
                runInAction(() => {
                    this.loadingModePaiementAction = false;
                    this.modePaiements = this.modePaiements.filter(a => a.paiementId !== id);
                    toast.success("suppression éffectuée avec succès");
                })
            } catch (e) {
                console.log(e);
                toast.error("Problème de suppréssion du mode de paiement!");
                runInAction(() => {
                    this.loadingModePaiementAction = false;
                })
            }
        }
    }

    @action loadCountPaiement = async (id: string) => {
        this.loadingModePaiement = true;
        try {
            let countmodePaiement = await agent.modePaiementAgent.countPaiement(id);
            runInAction(() => {
                this.countPaiement = countmodePaiement;
                this.loadingModePaiement = false;
            })
            return this.countPaiement;
        } catch (e) {
            runInAction(() => {
                this.loadingModePaiement = false;
            })
        }
    }
}