import { BaseStore } from "./baseStore";
import { observable, action, runInAction, computed, reaction } from 'mobx';
import agent from '../agent/agent';
import { toast } from "react-toastify";
import { IArticleStock, IArticleStockList } from "../models/IArticleStock";
import { setAlertStockProps } from "../common/util/util";

const LIMIT = 20;
export default class ArticleStockStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0;
                this.stockRegestry.clear();
                this.loadAlertStockList();
            }
        )
        reaction(
            () => this.activeTab,
            activeTab => {
                if (activeTab != '')
                    return true;
            }
        )
    }
    @observable loadingEntrepotAction = false;
    @observable loadingStock = false;
    @observable updating = false;
    @observable loadingNotification = false;
    @observable loadingInitial = false;
    @observable submitting = false;
    @observable deletingStock = false;
    @observable stock: IArticleStock | null = null;
    @observable stocks: IArticleStock[] = [];
    @observable stockRegestry = new Map();
    @observable editStock: boolean = false;
    @observable addStock: boolean = false;
    @observable ITListArticleStockEntrepot: IArticleStockList[] = [];
    @observable INotificationList: IArticleStock[] = [];
    @observable NotificationCount = 0;
    @observable page = 0;
    @observable stocksCount: number = 0;
    @observable predicate = new Map();
    @observable activeTab: string = "0";



    @computed get axiosParams() {
        const params = new URLSearchParams();
        params.append('limit', String(LIMIT));
        params.append('offset', `${this.page ? this.page * LIMIT : 0}`);
        this.predicate.forEach((value, key) => {
            if (key === 'startDate') {
                params.append(key, value.toISOString())
            } if (key === 'searchMultiple') {
                var refArticle = [...value][0];
                var libelle = [...value][1];
                params.append(refArticle[0], refArticle[1])
                params.append(libelle[0], libelle[1])
            }
            if (key === 'searchMultiple1') {
                var search = [...value][0];
                var Key = [...value][2];
                params.append(search[0], search[1])
                params.append(Key[0], Key[1])
            } else {
                params.append(key, value)
            }
        })
        return params;
    }

    @computed get totalPages() {
        return Math.ceil(this.NotificationCount / LIMIT);
    }

    @action setPredicate = (predicate: string, value: any) => {
        if (predicate != 'all')
            this.INotificationList = [];
        this.predicate.clear();
        if (predicate !== 'searchMultiple') {
            this.predicate.clear();
        }
        if (predicate !== 'all') {
            this.predicate.set(predicate, value);
        }
    }

    @action setPage = (page: number) => {
        this.page = page;
    }


    @action changeButtonStateEdit = async (newState: boolean) => {
        this.editStock = newState;
    }

    @action changeButtonStateAdd = async (newState: boolean) => {
        this.addStock = newState;
    }

    @action loadListeStock = async () => {
        this.loadingInitial = true;

        try {
            const packs = await agent.articleStockAgent.list();
            runInAction(() => {
                this.stocks = packs;
                this.loadingInitial = false;
            })
        } catch (e) {
            toast.error("Problem loading stock!");
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }

    @action loadArticleStockByArticle = async (id: number) => {
        this.loadingInitial = true;
        try {
            const stock = await agent.articleStockAgent.listarticle(id);
            runInAction(() => {
                this.stocks = stock;
                this.loadingInitial = false;
            })
        } catch (e) {
            toast.error("Problem loading forme stock!");
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }

    @action loadArticleStockByEntrepot = async (id: number) => {
        this.loadingInitial = true;
        this.ITListArticleStockEntrepot = [];
        try {
            const stock = await agent.articleStockAgent.listentrepot(id);
            runInAction(() => {
                this.stocks = stock;
                stock.forEach((artStock) => {
                    let stocks: IArticleStockList = {
                        key: artStock.articleStockId,
                        text: artStock.articleStockId,
                        value: artStock.articleStockId
                    }
                    this.ITListArticleStockEntrepot.push(stocks);
                })
                this.stocks = stock;
                this.loadingInitial = false;
            })
        } catch (e) {
            toast.error("Problem loading forme stock!");
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }
    
    getStock = (id: number) => {
        return this.stockRegestry.get(id);
    }

    @action create = async (values: any) => {
        this.submitting = true;
        try {
            let stock = await agent.mouvementStockAgent.create(values);
            runInAction(() => {
                this.stock = stock;
                if (this.stocks.find(x => x.articleStockId == stock.articleStockId) == null)
                    this.stocks.push(this.stock)
                else {
                    var x = parseInt(stock.quantite.toString());
                    this.stocks.find(x => x.entrepots == stock.entrepots)!.quantite += x ;
                    this.stocks.find(x => x.entrepots == stock.entrepots)!.quantiteVirt += x ;
                }              
                this.submitting = false;
                toast.success("ajout éffectué avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
        }
    }

    @action edit = async (values: any) => {
        this.updating = true;
        try {
            let stock = await agent.articleStockAgent.update(values);
            runInAction(() => {
                this.stocks[this.stocks.findIndex(x => x.articleStockId == values.articleStockId)] = stock;
                toast.success("Modification éffectuée avec succès");
                this.updating = false;
            })
        } catch (e) {
            runInAction(() => {
                this.updating = false;
            })
        }
    }

    @action loadStockById = async (id: number) => {

        this.loadingStock = true;
        let stocks = this.getStock(id);
        if (stocks) {
            this.stock = stocks;
            this.loadingStock = false;
            return stocks;
        }
        else {
            try {
                stocks = await agent.articleStockAgent.details(id);
                runInAction(() => {
                    this.stockRegestry.set(stocks.articleStockId, stocks);
                    this.stock = stocks;
                    this.loadingStock = false;
                })
                return stocks;
            } catch (e) {
                runInAction(() => {
                    this.loadingStock = false;
                })
                console.log(e);
            }
        }
    }

    @action loadAlertStockList = async () => {
        this.loadingNotification = true;
        try {
            const alertEnveloppe = await agent.articleStockAgent.alertStocks(this.axiosParams);
            const { alertStocks, alertStocksCount } = alertEnveloppe;
            runInAction(() => {
                alertStocks.forEach((commande) => {
                    setAlertStockProps(commande);
                    this.stockRegestry.set(commande.articleStockId, commande);
                    if (this.page == 0) {
                        this.INotificationList = alertStocks;
                    } else {
                        this.INotificationList.push(commande);
                    }
                })
                this.NotificationCount = alertStocksCount;
                this.loadingNotification = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingNotification = false;
            })
        }
    }
}