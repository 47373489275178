import React, { useContext } from 'react';
import { Button, Header, Icon, Container, Form } from 'semantic-ui-react';
import { IFranchisor } from '../../../app/models/IFranchiseur';
import { BaseStoreContext } from '../../../app/store/baseStore';

interface IProps {
    franchiseur: IFranchisor;
}

const AddCommandeFranchiseurModal: React.FC<IProps> = ({ franchiseur }) => {
    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    const { create } = baseStore.commandeStore;
    let newCommande = {
        agencyName: franchiseur.agencyName
        };    
    return (
        <Container textAlign='center'>
            <Header icon='add' content='Ajouter une Commande' style={{ marginLeft: 20 }} />
            <p>
                Voulez vous ajouter une commande a ce franchiseur ?
            </p>
                <Button.Group fluid>
                    <Button onClick={() => closeModal()}>
                        <Icon name='remove' /> Annuler
                    </Button>
                    <Button.Or text='Ou' />
                    <Button
                        color='green'
                    onClick={(e) => {
                            create(newCommande);
                            closeModal();
                        }}>
                        <Icon name='checkmark' /> Confirmer
                    </Button>
                </Button.Group>
        </Container>
    )
}
export default AddCommandeFranchiseurModal;
