import React from 'react';
import { Header, Grid, Segment, Item, Reveal, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import NotFound from '../../../app/layout/NoFound';
import { IArticle } from '../../../app/models/IArticle';

interface IProps {
    article: IArticle,
}
const ArticleServiceHeader: React.FC<IProps> = ({ article}) => {
    if (!article) return <NotFound />;
    return (
        <Segment>
            <Grid>
                <Grid.Column width={14}>
                    <Item.Group>
                        <Item>
                            <Item.Image style={{ display: 'flex', height: 150 }} src={article.uri} />
                            <Item.Content verticalAlign='middle' style={{ marginLeft: 30 }}>
                                <Header as='h2'>{article.reference} {article!.label}</Header>
                            </Item.Content>
                        </Item>
                    </Item.Group>
                </Grid.Column>
            </Grid>
        </Segment >
    );
};
export default observer(ArticleServiceHeader);