import React, { useContext, useState } from 'react';
import { Table, Header, Label, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../app/store/baseStore';
import NoResult from '../commande/commandeDashboard/ErrorNoResult';
import { IReception } from '../../app/models/IReception';
import _ from 'lodash';
import { Link } from 'react-router-dom';

const getStatus = (status: string) => {
    switch (status) {
        case 'EnAttente':
            return 'En Attente';
        case 'Receptionnee':
            return 'Réceptionnée';
        case 'ReceptionnePartielle':
            return 'Réception Parteille';
        case 'Facturee':
            return 'Facturée';
        default:
            return '';
    }
}
const numberFormat = new Intl.NumberFormat('FR-fr', {
    style: 'currency',
    currency: 'EUR',
    useGrouping: true,
});
const ListReception: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { receptions, Facturation, factureListReceptions} = baseStore.receptionStore;
    const [ids, setIds] = useState<number[]>([]);

    const handleCheck = (key: number, value: any) => {
        if (value.target.checked) {
            setIds([...ids, key]);
        } else {
            setIds(_.without(ids, key));
        }
    }

    const handleCheckAll = (value: any) => {
        var tab: number[] = [];
        if (value.target.checked) {
            receptions.map((stock) => {
                if (stock.receptionState != "Facturee" )
                    tab.push(stock.id);
            });
            setIds(tab);
        } else {
            setIds([]);
        }
    }

    const getBackgroundColor = (key: number) => {
        return ids.includes(key) ? 'darkgrey' : 'white';
    }

    const Footer = () => {
        return <footer className='footer footer.collapse' style={{
            background: 'gray', textAlign: 'center', left: 0, right: 0, opacity: 0.8
        }}>
            <Button positive type='submit' style={{ margin: '5px' }} disabled={Facturation} loading={Facturation} onClick={(e) => {
                factureListReceptions(ids)
            }}  >Facturer</Button>
        </footer>;
    }
    if (receptions.length < 1) return < NoResult name="Réception" />
    else
        return (
            <>
                <Table compact='very' style={{ marginTop: '1em' }} >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell><input type="checkbox" onChange={(value) => handleCheckAll(value)} /></Table.HeaderCell>
                            <Table.HeaderCell>Référence</Table.HeaderCell>
                            <Table.HeaderCell>N° Commande</Table.HeaderCell>
                            <Table.HeaderCell>Ref interne</Table.HeaderCell>
                            <Table.HeaderCell>Ref Fournisseur</Table.HeaderCell>
                            <Table.HeaderCell>Fournisseur</Table.HeaderCell>
                            <Table.HeaderCell>Entrepôt</Table.HeaderCell>
                            <Table.HeaderCell>TTC</Table.HeaderCell>
                            <Table.HeaderCell>HT</Table.HeaderCell>
                            <Table.HeaderCell>Date Reception</Table.HeaderCell>
                            <Table.HeaderCell>Etat</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {receptions && receptions.map((item: IReception) => (
                            <Table.Row key={item.id} style={{ backgroundColor: getBackgroundColor(item.id), textAlign: 'center' }}>
                                <Table.Cell> {item.receptionState != "Facturee" ? (<input type="checkbox" checked={ids.includes(item.id)} onChange={(value) => handleCheck(item.id, value)} />) : ("")}  </Table.Cell>
                                <Table.Cell>
                                    <Header size='small'><Label style={{ marginLeft: '1px', backgroundColor: 'unset', color: 'red', display: 'ruby' }} as={Link} to={`/detailReception/${item.id}`}>{item.reference}</Label></Header>
                                </Table.Cell>
                                <Table.Cell>
                                    <a href={`/detailCommandeFournisseur/${item.orderFournisseur.id}`}>{item.orderFournisseur.reference}</a>
                                </Table.Cell>
                                <Table.Cell>{item.orderFournisseur.internalRef}</Table.Cell>
                                <Table.Cell>{item.orderFournisseur.numSupplierOrder}</Table.Cell>
                                <Table.Cell>{item.orderFournisseur.fabricant}</Table.Cell>
                                <Table.Cell>{item.entrepots?.libelle}</Table.Cell>
                                <Table.Cell>{numberFormat.format(item.amountWithTaxe)}</Table.Cell>
                                <Table.Cell>{numberFormat.format(item.amountWithoutTaxe)}</Table.Cell>
                                <Table.Cell>{new Date(item.dateReception).toLocaleDateString('en-GB')}</Table.Cell>
                                <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }}>{getStatus(item.receptionState)}</Table.Cell>
                               
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
                {ids.length > 0 ? (<Footer />) : (<br />)}
            </>
        );
}
export default observer(ListReception);