import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";
import { Button, Form, FormInput, Grid } from "semantic-ui-react";
import { BaseStoreContext } from "../../../app/store/baseStore";
import AsyncSelect from 'react-select/async';
import Select, { components } from "react-select";
import { FaSearch, FaSort } from 'react-icons/fa';
import { Form as FinalForm } from 'react-final-form';
import { MdCancel } from "react-icons/md";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
const style = {
    option: (provided: any, state: { isSelected: any; }) => ({
        ...provided,
        fontSize: 13,
    }),

}
const customStyles = {
    option: (provided: any, state: { isSelected: any; }) => ({
        ...provided,
        color: state.isSelected ? 'orange' : 'grey',
        backgroundColor: state.isSelected ? "#eee" : "white",
        borderBottom: '1px solid #eee',

    }),
    valueContainer: (props: any) => ({
        ...props,
        paddingTop: 0,
        height: '39.201px',
        minHeight: '39.201px',
    }),
    control: (baseStyles: any, state: any) => ({
        ...baseStyles,
        height: '39.201px',
        minHeight: '39.201px',
    }),
}

const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <FaSort color='green' />
        </components.DropdownIndicator>
    );
};
const FactureFilter = () => {
    const { RangePicker } = DatePicker;
    const baseStore = useContext(BaseStoreContext);
    const { setPredicate, ExportPDF } = baseStore.factureStore;
    const [searchResults, setSearchResults] = useState([]);
    const [searchResultsClient, setSearchResultsClient] = useState<any>(0);
    const [selectedLabel, setSelectedLabel] = useState<any>("Client");
    const [selectedKey, setSelectedKey] = useState<any>('');
    const [selectedLabelSort, setSelectedLabelSort] = useState<any>('Filtrer...');
    const [selectedSort, setSelectedSort] = useState<any>('');
    const [selectedLabelTri, setSelectedTri] = useState<any>('Trier...');
    const [searchDateCommande, setSearchDateCommande] = useState<any>('');
    const [searchDateCommandeTo, setSearchDateCommandeTo] = useState<any>('');
    const [searchArticle, setSearchArticle] = useState<any>('');
    const [searchNCC, setSearchNCC] = useState<any>('');
    const [searchResultsClientAll, setSearchResultsClientAll] = useState(false);

    const rangePresets: {
        label: string;
        value: [Dayjs, Dayjs];
    }[] = [
            { label: '7 derniers jours', value: [dayjs().add(-7, 'd'), dayjs()] },
            { label: '14 derniers jours', value: [dayjs().add(-14, 'd'), dayjs()] },
            { label: '30 derniers jours', value: [dayjs().add(-30, 'd'), dayjs()] },
            { label: '90 derniers jours', value: [dayjs().add(-90, 'd'), dayjs()] },
        ];
    const options = [
        { key: 'All', label: 'Tous' },
        { key: 'Brouillon', label: 'Brouillon' },
        { key: 'Valider', label: 'Validée' },
        { key: 'Payer', label: 'Payée' },
        { key: 'Impayer', label: 'En attente de paiement' },
    ];
    const optionsTri = [
        { key: 'DateUp', text: 'Plus recent', value: 'DateUp' },
        { key: 'DateDown', text: 'Plus ancien', value: 'DateDown' },
        ,
    ];
    const handleChange = (event: any) => {
        setSearchResults(event.target.value);
    }
    const handleFilter = (key: string, value: string) => {
        setSelectedKey(key);
        setSelectedLabelSort(value)
    }
    const handleChangeClient = (value: any) => {
        setSearchResultsClient(value.id);
        setSearchResultsClientAll(value.all);
        setSelectedLabel(value!.agencyName + "-" + value!.companyName + (value!.all ? "-Tous les réseaux" : ""));
    }
    const handleChangeTri = (value: any) => {
        setSelectedSort(value.value);
        let labelSelected: any = value.text;
        setSelectedTri(labelSelected);
    }

    const onRangeChange = (dates: null | (Dayjs | null)[]) => {
        if (dates) {
            setSearchDateCommande(dates[0]?.toISOString());
            setSearchDateCommandeTo(dates[1]?.toISOString());
        }
    };
    const handleInputChangeArticle = (value: any) => {
        setSearchArticle(value.target.value);
    }


    const handleSearch = (event: any) => {
        if (event == 'clique') {
            let obj: any = {
                dateCommande: searchDateCommande,
                dateCommandeTo: searchDateCommandeTo,
                searchClient: searchResultsClient,
                search: searchResults,
                article: searchArticle,
                key: selectedKey,
                sort: selectedSort,
                searchResultsClientAll: searchResultsClientAll,
            }; ExportPDF(obj);
        }
        else if (event == 'clear') {
            setSearchResultsClient(0)
            setSelectedSort(null)
            setSelectedLabel("Client")
            setSelectedLabelSort('Filtrer ...')
            setSelectedTri('Trier ...')
            setSearchDateCommande('')
            setSearchDateCommandeTo('')
            setSearchArticle('')
            setSearchNCC('')
            setSearchResultsClientAll(false);
            setSearchResults([])
            setPredicate('all', 'true')
        }
        else if (event == 'submit') {
            var predicate = new Map()
            predicate.set('searchClient', searchResultsClient)
            predicate.set("article", searchArticle);
            predicate.set('search', searchResults)
            predicate.set('key', selectedKey)
            predicate.set('sort', selectedSort)
            predicate.set("dateCommande", searchDateCommande);
            predicate.set("dateCommandeTo", searchDateCommandeTo);
            predicate.set('searchClientAll', searchResultsClientAll);
            predicate.set('searchNCC', searchNCC);
            if (searchResults!.length > 2 || searchResultsClient > 0 || selectedKey != undefined || selectedSort != undefined
                || searchDateCommande != undefined || searchDateCommandeTo != undefined || searchArticle != undefined || searchNCC!=undefined)
                setPredicate('searchMultiple', predicate)
            if (searchResults!.length == 0 && searchResultsClient == null && selectedKey == '' && selectedSort == ''
                && searchDateCommande == '' && searchDateCommandeTo == '' && searchArticle == '' && searchNCC== '')
                setPredicate('all', 'true')


        }

    }
    return (

        <FinalForm
            onSubmit={handleSearch}
            render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit} error style={{ width: "100%", fontSize: 14 }}>
                    <Grid>
                        <Grid.Row columns={7} style={{ padding: 0, margin: 0 }}>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <FormInput
                                    icon='search'
                                    type="text"
                                    placeholder="Facture"
                                    value={searchResults}
                                    onChange={handleChange}
                                    style={{ width: '100%' }}

                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <FormInput
                                    icon='search'
                                    type="text"
                                    placeholder="N°CC"
                                    value={searchNCC}
                                    onChange={(e) => { setSearchNCC(e.target.value) }}
                                    style={{ width: '100%' }}

                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <FormInput
                                    icon='search'
                                    type="text"
                                    placeholder="Article"
                                    name="article"
                                    value={searchArticle}
                                    style={{ height: '100%' }}
                                    onChange={handleInputChangeArticle}

                                />
                            </Grid.Column>

                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <AsyncSelect
                                    name="form-field-name"
                                    noOptionsMessage={() => "Code introuvable"}
                                    cacheOptions
                                    defaultOptions
                                    placeholder={selectedLabel}
                                    value={searchResultsClient}
                                    getOptionLabel={e => e!.agencyName + "-" + e!.companyName + (e!.all ? "-Tous les réseaux" : "")}
                                    loadOptions={baseStore.clientStore.loadOptions}
                                    onChange={handleChangeClient}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>

                                <RangePicker
                                    presets={rangePresets}
                                    onChange={onRangeChange}
                                    format={'DD/MM/YYYY'}
                                    placeholder={["Début", "Fin"]}
                                    style={{ width: '100%', height: '39.201px' }}
                                />
                            </Grid.Column>

                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable={false}
                                    components={{ DropdownIndicator }}
                                    styles={customStyles}
                                    options={options}
                                    value={selectedKey}
                                    getOptionLabel={e => e.label}
                                    onChange={(e) => handleFilter(e.key, e.label)}
                                    placeholder={selectedLabelSort}
                                    isClearable={true}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0, display: 'flex' }}>
                                <Select
                                    isSearchable={false}
                                    components={{ DropdownIndicator }}
                                    styles={customStyles}
                                    options={optionsTri}
                                    value={selectedSort}
                                    getOptionLabel={e => e.text}
                                    onChange={(e) => handleChangeTri(e)}
                                    placeholder={selectedLabelTri} />
                                <Button style={{ padding: 5, margin: "0 0 0 10px", color: 'blue', background: "none" }} type="submit" onClick={(e: any) => handleSearch('submit')} >
                                    <FaSearch />
                                </Button>
                                <Button style={{ float: "right", padding: 5, margin: 0, color: 'green', background: "none" }} >
                                    <MdCancel style={{ fontSize: 17 }} onClick={(e: any) => handleSearch('clear')} />
                                </Button>
                                {/*<VscFilePdf*/}
                                {/*    title="Télécharger PDF"*/}
                                {/*    color={baseStore.expeditionStore.generatePdfMode ? 'blue' : 'red'}*/}
                                {/*    onMouseEnter={() => {*/}
                                {/*        baseStore.expeditionStore.changeButtonPdfState(!baseStore.expeditionStore.generatePdfMode);*/}
                                {/*    }}*/}
                                {/*    onMouseLeave={() => {*/}
                                {/*        baseStore.expeditionStore.changeButtonPdfState(!baseStore.expeditionStore.generatePdfMode);*/}
                                {/*    }}*/}
                                {/*    style={{ paddingTop: 0, paddingBottom: 0, height: 32, fontSize: "80px", cursor: 'pointer', content: "dddd" }}*/}
                                {/*    onClick={(e: any) => handleSearch('clique')}*/}
                                {/*/>*/}
                            </Grid.Column >
                        </Grid.Row>
                    </Grid>

                </Form >
            )}
        />
    );
};
export default observer(FactureFilter);