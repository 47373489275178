import React, { useContext } from 'react';
import { Button, Container, Header, Icon } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';

interface IProps {
    OfferId: number;
}

const DeleteOfferModal: React.FC<IProps> = ({ OfferId }) => {

    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    const { deleteSpecialOffer } = baseStore.specialOfferStore;

    return (
        <Container textAlign='center'>
            <Header icon='trash' content='Supprimer Offre' style={{ marginLeft: 20 }} />
            <p>
                Voulez vous supprimer cette offre ?
            </p>

            <Button.Group fluid>
                <Button onClick={() => closeModal()}>
                    <Icon name='remove' /> Annuler
                </Button>
                <Button.Or text='Ou' />
                <Button
                    color='green'
                    onClick={(e) => {
                        deleteSpecialOffer(OfferId);
                        closeModal()
                    }}>
                    <Icon name='checkmark' /> Confirmer
                </Button>
            </Button.Group>
        </Container>
        );
}

export default DeleteOfferModal;