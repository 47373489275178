import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { Form, Button, Checkbox } from 'semantic-ui-react';
import { Field, Form as FinalForm } from 'react-final-form';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../app/store/baseStore';
import { IResponsable, IUserCompany } from '../../app/models/IUser';
import TextInput from '../../app/common/form/inputText';
import InputSelect from '../../app/common/form/inputSelect';
import { ITListTypeCivilite } from '../../app/common/options/IAchat';

interface IProps {
    user: IUserCompany | undefined;
    setModalOpen: Dispatch<SetStateAction<boolean>>;
}

const EditUserModal: React.FC<IProps> = ({ user, setModalOpen }) => {

    const baseStore = useContext(BaseStoreContext);
    const { submitting, editUser } = baseStore.userStore;
    const [isMainContact, setMainContact] = useState(user!.isMainContact);
    const [isAdmin, setAdmin] = useState(user!.user.isAdministrator);
    const [isValidator, setValidator] = useState(user!.user.isOrderValidator);
    const handleFinalFormSubmit = (values: any) => {
        let newUser: IResponsable = {
            ...values,
            currentCompanyId: user?.user.currentCompanyId,
            isAdministrator: isAdmin,
            isOrderValidator: isValidator,
        }
        let newUserCompany: IUserCompany = {
            id: user!.id,
            user: newUser,
            isMainContact: isMainContact,
            companyId: user!.user.currentCompanyId ,
        };
        editUser(newUserCompany);
        setModalOpen(false);
    }

    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            render={({ handleSubmit, invalid }) => (
                <Form onSubmit={handleSubmit} error >
                    <Form.Group unstackable widths={3} >
                        <div className="field">
                            <h5>Identifiant</h5>
                            <Field name='login' component={TextInput} initialValue={user?.user.login} />
                        </div>
                        <div className="field">
                            <h5>Nom</h5>
                            <Field name='lastName' component={TextInput} initialValue={user?.user.lastName} />
                        </div>
                        <div className="field">
                            <h5>Prénom</h5>
                            <Field name='firstName' component={TextInput}  initialValue={user?.user.firstName} />
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={3}>
                        <div className="field">
                            <label>Civilité </label>
                            <Field component={InputSelect} options={ITListTypeCivilite} name='salutations' initialValue={user?.user.salutations} />
                        </div>
                        <div  className="field">
                            <label>Email</label>
                            <Field name='email' component={TextInput} initialValue={user?.user.email} type='email' />
                        </div>
                        <div className="field">
                            <label>Fonction</label>
                            <Field name='function' component={TextInput} placeholder="Fonction" initialValue={user?.user.function} />
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={2}>
                        <div className="field">
                            <label>Téléphone</label>
                            <Field name='phone' component={TextInput} placeholder="Téléphone" initialValue={user?.user.phone} />
                        </div>
                        <div className="field">
                            <label>Téléphone Mobile</label>
                            <Field name='mobile' component={TextInput} placeholder="Téléphone Mobile" initialValue={user?.user.mobile} />
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={3}>
                        <div className="field">
                            <label>Contact principal </label>
                            <Checkbox toggle value="isMainContact" style={{ marginTop: '10px' }} onChange={() => setMainContact(!isMainContact)} defaultChecked={user?.isMainContact} />
                        </div>
                        <div className="field">
                            <label>Administrateur </label>
                            <Checkbox toggle value="isAdministrator" style={{ marginTop: '10px' }} onChange={() => setAdmin(!isAdmin)} defaultChecked={user?.user.isAdministrator} />
                        </div>
                        <div className="field">
                            <label>Validateur Commande</label>
                            <Checkbox toggle value="isOrderValidator" style={{ marginTop: '10px' }} onChange={() => setValidator(!isValidator)} defaultChecked={user?.user.isOrderValidator} />
                        </div>
                    </Form.Group>
                    <Form.Group unstackable style={{ marginLeft: "70%" }} >
                        <Button.Group floated="right">
                            <Button onClick={() => { setModalOpen(false) }} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive disabled={submitting || invalid} loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </Form.Group>
                </Form>
            )}
        />
    );
}
export default observer(EditUserModal);
