import { useContext, useEffect } from 'react';
import { Tab, Header, Button, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import NotFound from '../../../app/layout/NoFound';
import FournisseurComptabiliteEditForm from './FournisseurComptabiliteEditForm';
import FormGridColumn from '../../../app/common/form/FormGridColumn';

const FournisseurComptabiliteDescription = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { fournisseur, editFournisseurComptabilite } = baseRepo.fournisseurStore;
    useEffect(() => {
        baseRepo.fournisseurStore.changeButtonState(false)
    }, [])
    if (!fournisseur) {
        return <NotFound />
    }
    else {
        return (
            <Tab.Pane>
                <Grid>
                    <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                        <Header floated='left' icon='address card' content={`Comptabilité`} />
                        <Button
                            onClick={() => { baseRepo.fournisseurStore.changeButtonState(!baseRepo.fournisseurStore.editFournisseurMode) }}
                            floated='right'
                            basic
                            content={baseRepo.fournisseurStore.editFournisseurMode ? 'Annuler' : 'Modifier'}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        {baseRepo.fournisseurStore.editFournisseurMode ? (
                            <FournisseurComptabiliteEditForm editFournisseur={editFournisseurComptabilite} fournisseur={fournisseur!} />
                        ) : (
                            <Grid className="ui stackable">
                                <Grid.Row>
                                    <FormGridColumn icon="calendar times" header="Compte Collectif" content={fournisseur!.compteCollectif} />
                                    <FormGridColumn icon="calendar times" header="Compte Auxiliere" content={fournisseur!.compteAuxiliere} />
                                    {/*<FormGridColumn icon="truck" header="Status Compte" content={fournisseur!.statusCompte} />*/}
                                    <FormGridColumn header="Mode de paiement" icon="money" content={fournisseur!.modePaiement} />
                                        <FormGridColumn header="Mode Reglement" icon="money" content={fournisseur!.modeReglement} />
                                        <FormGridColumn header="Devise" content={fournisseur!.devise} />
                                        <FormGridColumn icon="qrcode" header="Societe" content={fournisseur!.societe} />
                                </Grid.Row>
                            </Grid>
                        )}
                    </Grid.Column>
                </Grid>
            </Tab.Pane>
        );
    }
}
export default observer(FournisseurComptabiliteDescription);