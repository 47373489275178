import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { BaseStoreContext } from "../../../app/store/baseStore";
import { Tab, Header, Button, Grid, Table, Progress, Modal, Input, ButtonGroup } from 'semantic-ui-react';
import AddFactureArticle from "./AddFactureArticle";
import AucunProduit from "../../commande/detailCommande/AucunProduit";
import DeleteInvoiceLineModal from "./DeleteInvoiceLineModal";
import EditFactureArticle from "./EditFactureArticle";
import { IGroupInvoiceLine, IInvoiceLine } from "../../../app/models/IFacture";


const FactureArticle = () => {
    const baseStore = useContext(BaseStoreContext)
    const { facture, submitting, progress, deletingFactureArticle, updateLine } = baseStore.factureStore;
    const { packs, loadListe } = baseStore.articleComposeStore;
    const [addArticleMode, setAddArticleMode] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    const { openModal } = baseStore.modalStore;
    const [modalOpen, setModalOpen] = useState(false);
    const [updateOpen, setupdateOpen] = useState(false);
    const [unitPrice, setUnitPrice] = useState(0);
    const [priceWithTxes, setPriceWithTaxes] = useState(0);
    const [tva, setTva] = useState(0);
    const [invoiceLine, setInvoiceLine] = useState<IInvoiceLine>();
    useEffect(() => {
        loadListe();
    }, [])

    const setChangePrice = (value: string) => {
        const price = parseFloat(value);
        setUnitPrice(price);
        setPriceWithTaxes(price + (price * tva / 100))
    }
    const setChangePriceWithTaxes = (value: string) => {
        const price = parseFloat(value);
        setPriceWithTaxes(price);
        setUnitPrice(price * 100 / (100 + tva))
    }

    const getHideLines = (lines: IGroupInvoiceLine[], invoiceLines: IInvoiceLine[]) => {
        var ids: number[] = [];
        lines.map(item => (
            item.invoiceLines.map(line =>
            (ids.push(line.id!)
            ))
        ));

        var otherLines = invoiceLines.filter(x => !ids.includes(x.id!));

        return otherLines
    }

    const HandleUpdateLine = (line: any) => {
        let newLine: IInvoiceLine = {
            ...invoiceLine,
            id: line.id,
            unitPrice: unitPrice,
            unitPriceWithTaxes: priceWithTxes,
            totalPrice: unitPrice,
            totalPriceWithTaxes: priceWithTxes,
            label: line.libelle,
            libelle: line.libelle
        };
        updateLine(line.id, newLine);
    }
    return (
        <Tab.Pane>
            <Modal
                open={modalOpen}
                size='large'
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >
                <Modal.Header icon='add' >
                    Modifier l'article
                    <Button
                        style={{ height: 35 }}
                        type='button' icon='x' color='red'
                        floated='right'
                        onClick={() => { setModalOpen(false) }}
                    >
                    </Button>
                </Modal.Header>
                <Modal.Content>
                    <EditFactureArticle invoiceLine={invoiceLine!} facture={facture!} setModalOpen={setModalOpen} />
                </Modal.Content>
            </Modal>
            <Grid>
                <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                    <Header floated='left' icon='shopping basket' content='Produits' />
                    {facture?.invoiceStatus == 'Brouillon' && facture.invoiceType != "Facture_Acompte" &&
                        <Button
                            onClick={() => setAddArticleMode(!addArticleMode)}
                            floated='right'
                            basic
                            content={addArticleMode ? 'Annuler' : 'Ajouter produit'}
                        />}
                </Grid.Column>
                <Grid.Column width={16}>
                    {addArticleMode ? (
                        <AddFactureArticle
                            factureId={facture!.id}
                            setAddArticleMode={setAddArticleMode}
                        />
                    ) : (
                        <>
                            {submitting &&
                                <Progress color='orange' percent={progress} size='tiny' />
                            }
                            {facture?.invoiceLines.length == 0 ? (
                                <AucunProduit />
                            ) :
                                (

                                    <Table loading={false} celled>
                                        <Table.Header>
                                            <Table.Row>
                                                {facture?.invoiceType != "Facture_Acompte" ? (
                                                    <>
                                                        <Table.HeaderCell>Article</Table.HeaderCell>
                                                        <Table.HeaderCell>Quantité</Table.HeaderCell>
                                                        <Table.HeaderCell>PU HT.</Table.HeaderCell>
                                                        <Table.HeaderCell>Remise</Table.HeaderCell>
                                                        <Table.HeaderCell>Montant HT</Table.HeaderCell>
                                                        {facture?.invoiceStatus == 'Brouillon' &&
                                                            <Table.HeaderCell style={{ textAlign: "center" }}>Actions</Table.HeaderCell>}
                                                    </>
                                                ) : (
                                                    <>
                                                        <Table.HeaderCell>Detail Acompte</Table.HeaderCell>
                                                        {facture!.invoiceLines[0].totalPrice! != 0 &&
                                                            <Table.HeaderCell>  HT   </Table.HeaderCell>
                                                        }
                                                        <Table.HeaderCell>TTC TVA </Table.HeaderCell>
                                                        <Table.HeaderCell></Table.HeaderCell>
                                                    </>
                                                )}

                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {facture &&
                                                <>
                                                    {facture?.invoiceType != "Facture_Acompte" ?
                                                        (<>
                                                            {facture.lines.map(line => (
                                                                <>
                                                                    <Table.Row key={line.refBL}>
                                                                        <Table.Cell colSpan={5}>
                                                                            Bon de livraison N° {line.refBL} du {new Date(line.dispatchDate).toLocaleDateString('en-GB')}     Commande: {line.refCM}
                                                                        </Table.Cell>

                                                                    </Table.Row>
                                                                    {line.invoiceLines.map(factureLine => (
                                                                        <>
                                                                            <Table.Row key={factureLine.productId}>
                                                                                <Table.Cell rowspan={factureLine.comment == "" || factureLine.comment == null ? 1 : 2}>
                                                                                    <a style={{ backgroundColor: 'none' }} href={`/detailArticle/${factureLine.productId}`}>
                                                                                        {factureLine.reference ? factureLine.reference + ' - ' : ''}{factureLine.libelle}
                                                                                    </a>
                                                                                    {packs &&
                                                                                        packs.map(pack => (
                                                                                            <>
                                                                                                {pack.idPack == factureLine.reference &&
                                                                                                    <>
                                                                                                        <br />
                                                                                                        <span style={{ marginLeft: "8px", color: 'royalblue', cursor: 'none' }}>{pack.article}-{pack.articleLibelle}</span>
                                                                                                    </>
                                                                                                }
                                                                                            </>
                                                                                        ))}
                                                                                    {factureLine.productAttributeValues!.map(productAttributeValue => (
                                                                                        <>
                                                                                            < br />
                                                                                            <p><b>{productAttributeValue.productAttributeName}: </b>
                                                                                                {productAttributeValue.value}</p>
                                                                                        </>
                                                                                    ))
                                                                                    }
                                                                                </Table.Cell>
                                                                                <Table.Cell>{factureLine.quantity}</Table.Cell>
                                                                                <Table.Cell>{factureLine.unitPrice!.toFixed(4)} €</Table.Cell>
                                                                                <Table.Cell>{factureLine.remise} %</Table.Cell>
                                                                                <Table.Cell>{factureLine!.totalPrice!.toFixed(2)} €</Table.Cell>
                                                                                {facture?.invoiceStatus == 'Brouillon' &&
                                                                                    <Table.Cell>
                                                                                        <Button.Group fluid widths={2}>
                                                                                            <Button
                                                                                                onClick={(e) => {
                                                                                                    setModalOpen(true);
                                                                                                    setInvoiceLine(factureLine);
                                                                                                }}
                                                                                                basic
                                                                                                color="blue"
                                                                                                icon='edit outline'
                                                                                                name={factureLine.id}
                                                                                                style={{ marginRight: '2%' }}
                                                                                            />
                                                                                            <Button
                                                                                                name={factureLine.productId}
                                                                                                onClick={(e) => {
                                                                                                    openModal(<DeleteInvoiceLineModal factureId={factureLine!.id!} invoiceLine={factureLine} />)
                                                                                                    setDeleteTarget(e.currentTarget.name)
                                                                                                }}
                                                                                                loading={deletingFactureArticle && deleteTarget === factureLine.productId!.toString()}
                                                                                                basic
                                                                                                negative
                                                                                                icon='trash'
                                                                                            />
                                                                                        </Button.Group>
                                                                                    </Table.Cell>
                                                                                }
                                                                            </Table.Row>
                                                                            {
                                                                                factureLine.comment != "" && factureLine.comment != null &&
                                                                                <Table.Row style={{ marginLeft: 5, }}>
                                                                                    <Table.Cell colspan={5} style={{ borderTop: 'none', padding: 0, fontSize: 12, fontWeight: 700 }}>
                                                                                        <div style={{ padding: '3px 0 0 10px', borderLeft: '1px solid rgba(34,36,38,.1)', borderTop: '1px solid rgba(34,36,38,.1)', }}>
                                                                                            {factureLine.comment}
                                                                                        </div>
                                                                                    </Table.Cell>
                                                                                </Table.Row>
                                                                            }
                                                                        </>
                                                                    ))}
                                                                </>
                                                            ))}
                                                            {facture.lines.length != 0 && getHideLines(facture.lines, facture.invoiceLines).length != 0 &&
                                                                getHideLines(facture.lines, facture.invoiceLines).map(line => (
                                                                    <>
                                                                        <Table.Row key={line.id}>
                                                                            <Table.Cell rowspan={line.comment == "" || line.comment == null ? 1 : 2}>{line.reference} - {line.libelle}</Table.Cell>
                                                                            <Table.Cell>{line.quantity}</Table.Cell>
                                                                            <Table.Cell>{line.unitPrice!.toFixed(4)} €</Table.Cell>
                                                                            <Table.Cell>{line.remise} %</Table.Cell>
                                                                            <Table.Cell>{line!.totalPrice!.toFixed(2)} €</Table.Cell>
                                                                            {facture?.invoiceStatus == 'Brouillon' &&
                                                                                <Table.Cell>
                                                                                    <Button.Group fluid widths={2}>
                                                                                        <Button
                                                                                            onClick={(e) => {
                                                                                                setModalOpen(true);
                                                                                                setInvoiceLine(line);
                                                                                            }}
                                                                                            basic
                                                                                            color="blue"
                                                                                            icon='edit outline'
                                                                                            name={line.id}
                                                                                            style={{ marginRight: '2%' }}
                                                                                        />
                                                                                        <Button
                                                                                            name={line.id}
                                                                                            onClick={(e) => {
                                                                                                openModal(<DeleteInvoiceLineModal factureId={line!.id!} invoiceLine={line} />)
                                                                                                setDeleteTarget(e.currentTarget.name)
                                                                                            }}
                                                                                            loading={deletingFactureArticle && deleteTarget === line.productId!.toString()}
                                                                                            basic
                                                                                            negative
                                                                                            icon='trash'
                                                                                        />
                                                                                    </Button.Group>
                                                                                </Table.Cell>
                                                                            }
                                                                        </Table.Row>
                                                                        {
                                                                            line.comment != "" && line.comment != null &&
                                                                            <Table.Row style={{ marginLeft: 5, }}>
                                                                                <Table.Cell colspan={5} style={{ borderTop: 'none', padding: 0, fontSize: 12, fontWeight: 700 }}>
                                                                                    <div style={{ padding: '3px 0 0 10px', borderLeft: '1px solid rgba(34,36,38,.1)', borderTop: '1px solid rgba(34,36,38,.1)', }}>
                                                                                        {line.comment}
                                                                                    </div>
                                                                                </Table.Cell>
                                                                            </Table.Row>
                                                                        }
                                                                    </>
                                                                ))

                                                            }
                                                            {facture.lines.length == 0 && facture.invoiceLines.map(line => (
                                                                <>
                                                                    <Table.Row key={line.id}>
                                                                        <Table.Cell rowspan={line.comment == "" || line.comment == null ? 1 : 2}>
                                                                            {line.reference} - {line.libelle}
                                                                        </Table.Cell>
                                                                        <Table.Cell>{line.quantity}</Table.Cell>
                                                                        <Table.Cell>{line.unitPrice!.toFixed(4)} €</Table.Cell>
                                                                        <Table.Cell>{line.remise} %</Table.Cell>
                                                                        <Table.Cell>{line!.totalPrice!.toFixed(2)} €</Table.Cell>
                                                                        {facture?.invoiceStatus == 'Brouillon' &&
                                                                            <Table.Cell>
                                                                                <Button.Group fluid widths={2}>
                                                                                    <Button
                                                                                        onClick={(e) => {
                                                                                            setModalOpen(true);
                                                                                            setInvoiceLine(line);
                                                                                        }}
                                                                                        basic
                                                                                        color="blue"
                                                                                        icon='edit outline'
                                                                                        name={line.id}
                                                                                        style={{ marginRight: '2%' }}
                                                                                    />
                                                                                    <Button
                                                                                        name={line.id}
                                                                                        onClick={(e) => {
                                                                                            openModal(<DeleteInvoiceLineModal factureId={line!.id!} invoiceLine={line} />)
                                                                                            setDeleteTarget(e.currentTarget.name)
                                                                                        }}
                                                                                        loading={deletingFactureArticle && deleteTarget === line.productId!.toString()}
                                                                                        basic
                                                                                        negative
                                                                                        icon='trash'
                                                                                    />
                                                                                </Button.Group>
                                                                            </Table.Cell>
                                                                        }
                                                                    </Table.Row>
                                                                    {
                                                                        line.comment != "" && line.comment != null &&
                                                                        <Table.Row style={{ marginLeft: 5, }}>
                                                                            <Table.Cell colspan={5} style={{ borderTop: 'none', padding: 0, fontSize: 12, fontWeight: 700 }}>
                                                                                <div style={{ padding: '3px 0 0 10px', borderLeft: '1px solid rgba(34,36,38,.1)', borderTop: '1px solid rgba(34,36,38,.1)', }}>
                                                                                    {line.comment}
                                                                                </div>
                                                                            </Table.Cell>
                                                                        </Table.Row>
                                                                    }
                                                                </>
                                                            ))}
                                                        </>
                                                        ) : (
                                                            <>
                                                                {facture.invoiceLines.map(ivLine =>
                                                                (
                                                                    <Table.Row >
                                                                        {updateOpen ?
                                                                            <>
                                                                                <Table.Cell> {ivLine.libelle} </Table.Cell>
                                                                                <Table.Cell><Input type="number" style={{ width: 150 }} value={unitPrice.toFixed(2)} step="0.01" onChange={(e) => setChangePrice(e.target.value)} /> </Table.Cell>
                                                                                <Table.Cell><Input type="number" style={{ width: 150 }} value={priceWithTxes.toFixed(2)} step="0.01" onChange={(e) => setChangePriceWithTaxes(e.target.value)} /> </Table.Cell>
                                                                                <Table.Cell>
                                                                                    <ButtonGroup>
                                                                                        <Button
                                                                                            onClick={(e) => {
                                                                                                HandleUpdateLine(ivLine);
                                                                                                setupdateOpen(false);
                                                                                            }}
                                                                                            basic
                                                                                            size="tiny"
                                                                                            color="green"
                                                                                            icon='check'
                                                                                            name={ivLine.id}
                                                                                            style={{ marginRight: '2%' }}
                                                                                        />
                                                                                        <Button
                                                                                            onClick={(e) => {
                                                                                                setupdateOpen(false);
                                                                                            }}
                                                                                            basic
                                                                                            size="tiny"
                                                                                            color="red"
                                                                                            icon='cancel'
                                                                                            style={{}}
                                                                                        />
                                                                                    </ButtonGroup>
                                                                                </Table.Cell>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <Table.Cell> {ivLine.libelle} </Table.Cell>
                                                                                {ivLine.totalPrice != 0 &&
                                                                                    <Table.Cell> {ivLine.totalPrice!.toFixed(2)} € </Table.Cell>
                                                                                }
                                                                                <Table.Cell> {ivLine.totalPriceWithTaxes!.toFixed(2)} € </Table.Cell>
                                                                                {facture?.invoiceStatus == 'Brouillon' &&
                                                                                    <Table.Cell>
                                                                                        <Button
                                                                                            onClick={(e) => {
                                                                                                setUnitPrice(ivLine.totalPrice!);
                                                                                                setPriceWithTaxes(ivLine.totalPriceWithTaxes!);
                                                                                                setTva((ivLine.totalPriceWithTaxes! - ivLine.totalPrice!) * 100 / ivLine.totalPrice!);
                                                                                                setupdateOpen(true);
                                                                                            }}
                                                                                            basic
                                                                                            color="blue"
                                                                                            icon='edit outline'
                                                                                            name={ivLine.id}
                                                                                            style={{ marginRight: '2%' }}
                                                                                        />
                                                                                    </Table.Cell>
                                                                                }
                                                                            </>

                                                                        }

                                                                    </Table.Row>
                                                                )
                                                                )}

                                                            </>
                                                        )}
                                                </>
                                            }

                                        </Table.Body>
                                    </Table>
                                )}
                        </>
                    )}
                </Grid.Column>
            </Grid >
        </Tab.Pane >
    );
};
export default observer(FactureArticle);