import { action, computed, observable, reaction, runInAction } from 'mobx';
import { toast } from "react-toastify";
import { history } from '../../index';
import agent from '../agent/agent';
import { IArticle, ICFList, IListDeliveryDelay, IProduct, IProductFournisseur, IProductItem } from '../models/IArticle';
import { IArticleList, IArticleListByFranchiseur } from "../models/IClient";
import { IFichier } from '../models/IFranchiseur';
import { IPhoto } from "../models/IProfil";
import { ITarifFournisseur } from "../models/ITarifFournisseur";
import { BaseStore } from "./baseStore";

const LIMIT = 16;

export default class ArticleStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0;
                this.articleRegestry.clear();
                this.loadArticles();
            }
        )
        reaction(
            () => this.predicateService.keys(),
            () => {
                this.pageService = 0;
                this.loadServices();
            }
        )
        reaction(
            () => this.activeTab,
            activeTab => {
                if (activeTab != '')
                    return true;
            }
        )
    }
    @observable loadingPhotoAction = false;
    @observable loadingInitial = false;
    @observable loadingArticle = false;
    @observable uploadingFile = false;
    @observable deletingFile = false;
    @observable loadingService = false;
    @observable loadingExport = false;
    @observable loadingExportSer = false;
    @observable submitting = false;
    @observable loadingArticleProfil = false;
    @observable uploadingPhoto = false;
    @observable articles: IProductItem[] = [];
    @observable articlesServices: IArticle[] = [];
    @observable articleCommande: IArticle[] = [];
    @observable ITListArticle: IArticleList[] = [];
    @observable ITListArticleByFranchiseur: IArticleListByFranchiseur[] = [];
    @observable article: IArticle | null = null;
    @observable articleRegestry = new Map();
    @observable articlesCount: number = 0;
    @observable articleServiceCount: number = 0;
    @observable predicate = new Map();
    @observable predicateService = new Map();
    @observable page = 0
    @observable pageService = 0
    @observable activeTab: string = "";
    @observable editArticleMode: boolean = false;
    @observable deletingArticle = false;
    @observable loadingIncrementation = false;
    @observable articlesFournisseur: IProductFournisseur[] = [];
    @observable articlesFranchiseur: IProduct[] = [];
    @observable loadingFournisseur = true;
    @observable IFournisseurList: ITarifFournisseur[] = [];
    @observable IDeliveryDelayList: IListDeliveryDelay[] = [];
    @observable submittingDeliveryDelay = false;
    @observable ListCF: ICFList[] = [];
    @observable loadingCF = false;

    @observable verificationUpdate(newArticle: IArticle, oldArticle: IArticle) {
        var duplication = true;
        if (newArticle.label != oldArticle.label) return false;
        if ((oldArticle.companyId == 0 ? 999 : oldArticle.companyId).toString() + newArticle.externalReference != oldArticle.externalReference) return false;
        if (newArticle.paperFormat != oldArticle.paperFormat) return false;
        if (newArticle.weight != oldArticle.weight) return false;
        if (newArticle.deliveryDelay != oldArticle.deliveryDelay) return false;
        if (newArticle.printing != oldArticle.printing) return false;
        if (newArticle.optionArticles != oldArticle.optionArticles) return false;
        if (newArticle.paperWeight != oldArticle.paperWeight) return false;
        if (newArticle.modeAppro != oldArticle.modeAppro) return false;
        if (newArticle.tva != oldArticle.tva) return false;
        if (newArticle.famille != oldArticle.famille) return false;
        if (newArticle.companyId != oldArticle.companyId) return false;
        if (newArticle.barCode != oldArticle.barCode) return false;
        return (duplication);
    }
    @computed get axiosParams() {
        const params = new URLSearchParams();
        params.append('limit', String(LIMIT));
        params.append('offset', `${this.page ? this.page * LIMIT : 0}`);
        params.append('offsetService', `${this.page ? this.page * LIMIT : 0}`);
        this.predicate.forEach((value, key) => {
            if (key === 'startDate') {
                params.append(key, value.toISOString())
            } else if (key === 'searchMultiple') {
                var search = [...value][0];
                var searchByFranchiseur = [...value][1];
                var Key = [...value][2];
                var sort = [...value][3];
                var fournisseur = [...value][4];
                var franchiseur = [...value][5];
                params.append(search[0], search[1])
                params.append(searchByFranchiseur[0], searchByFranchiseur[1])
                params.append(Key[0], Key[1] )
                params.append(sort[0], sort[1])
                params.append(fournisseur[0], fournisseur[1])
                params.append(franchiseur[0], franchiseur[1])
            } 
          else {
                params.append(key, value)
            }
        })
        this.predicateService.forEach((value, key) => {
            if (key === 'startDate') {
                params.append(key, value.toISOString())
            } else if (key === 'searchMultiple') {
                var search = [...value][0];
                var searchByFranchiseur = [...value][1];
                var sort = [...value][2];
                var fournisseur = [...value][3];
                var externalReference = [...value][4];
                var codeComptable = [...value][5];
                params.append(search[0], search[1])
                params.append(searchByFranchiseur[0], searchByFranchiseur[1])
                params.append(sort[0], sort[1])
                params.append(fournisseur[0], fournisseur[1])
                params.append(externalReference[0], externalReference[1])
                params.append(codeComptable[0], codeComptable[1])
            } 
          else {
                params.append(key, value)
            }
        })
        return params;
    }



    @computed get articleById() {
        return this.sortById(Array.from(this.articleRegestry.values()));
    }
    @computed get totalPages() {
        return Math.ceil(this.articlesCount / LIMIT);
    }


    @action setPage = (page: number) => {
        this.page = page;
    }
    @computed get totalPageService() {
        return Math.ceil(this.articlesCount / LIMIT);
    }


    @action setPageService = (page: number) => {
        this.page = page;
    }

    @action setPredicate = (predicate: string, value: any) => {
        if (this.predicate.get(predicate)) {
            this.articles = [];
            this.predicate.clear();

        }
        if (predicate == 'all') {
            this.predicate.clear();
        }
        else {
            this.predicate.set(predicate, value);
        }

    }
    @action setPredicateService = (predicate: string, value: any) => {
        if (this.predicateService.get(predicate)) {
            this.articles = [];
            this.predicateService.clear();

        }
        if (predicate == 'all') {
            this.predicateService.clear();
        }
        else {
            this.predicateService.set(predicate, value);
        }

    }

    sortById(articles: IArticle[]) {
        const sortedAticles = articles.sort(
            (a, b) => new Date(a.dateCreation).getTime() - new Date(b.dateCreation).getTime());
        const sotedByCate = sortedAticles.sort((a, b) => a.productId.toString().localeCompare(b.productId.toString()));

        return Object.entries(
            sotedByCate.reduce(
                (articles, article) => {

                    const cate = article.productId;
                    articles[cate] = articles[cate]
                        ? [...articles[cate], article]
                        : [article];
                    return articles;
                },
                {} as { [key: string]: IArticle[] }
            )
        );
    }
    @action changeButtonState = async (newState: boolean) => {
        this.editArticleMode = newState;
    }

    @action loadServices = async () => {
        this.loadingService = true;
        try {
            const articleServiceEnvelope = await agent.articleAgent.getService(this.axiosParams);
            const { articleServiceCount,articleServices } = articleServiceEnvelope;
            runInAction(() => {
                     if (this.page == 0)
                         this.articlesServices = [];
                articleServices.forEach((article: IArticle) => {
                    this.articlesServices.push(article);
                })
                this.articleServiceCount = articleServiceCount;
                this.loadingService = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingService = false;
            })
        }
    }
    @action loadArticles = async () => {
        this.loadingInitial = true;
        try {
            const articleEnveloppe = await agent.articleAgent.list(this.axiosParams);
            const { articles, articleCount } = articleEnveloppe;
            runInAction(() => {
                if (this.page == 0)
                    this.articles = [];
                articles.forEach((article: IProductItem) => {
                    this.articles.push(article);
                    this.articleRegestry.set(article.productId, article);
                })
                this.articlesCount = articleCount;
                this.loadingInitial = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }

    @action loadArticlesForPack = async (id: string) => {
        this.ITListArticle = [];
        this.loadingInitial = true;
        try {
            const articles = await agent.articleAgent.listForPack(id);
            runInAction(() => {
                articles.forEach((article) => {
                    let articles: IArticleList = {
                        key: article.productId,
                        text: article.reference.toString() + ' , ' + article.label,
                        value: article.productId,
                        refCentrale: article.externalReference,
                    }
                    this.ITListArticle.push(articles)
                })
                this.loadingInitial = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }

    @action clearActivity = () => {
        this.article = null;
    };

    @action setActiveTab = async (activeIndex: string) => {
        runInAction(() => {
            this.activeTab = activeIndex;
        })
    }

    @action loadArticle = async (id: number) => {
        this.loadingArticle = true;
        let article: any = null;
        try {
            article = await agent.articleAgent.details(id);
            runInAction(() => {
                var optionList: string[] = [];
                article.optionArticles.forEach((option: any) => {
                    optionList.push(option.option.libelle)
                })
                article.optionArticles = optionList;
                this.article = article;
                this.articleRegestry.set(article.productId, article);
                this.loadingArticle = false;
            })
            return article;
        } catch (e) {
            runInAction(() => {
                this.loadingArticle = false;
                history.push('/menuPage');
            })
        }
    }


    @action create = async (values: IArticle) => {
        this.submitting = true;
        try {
            const id = await agent.articleAgent.create(values);
            runInAction(() => {
                this.articleRegestry.set(values.productId, values)
                this.article = values;
                this.submitting = false;
                toast.success("ajout effectué avec succès");
            })
            values.isService ? history.push(`/detailService/${id}`):
            history.push(`/detailArticle/${id}`);
        } catch (e) {
            toast.error("Problème d'ajout d'article!");
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    @action editArticle = async (values: IArticle) => {
        this.submitting = true;
        try {
            let article = await agent.articleAgent.update(values);
            runInAction(() => {
                this.articleRegestry.set(values.productId, values);
                this.article = article;
                this.article!.optionArticles = values.optionArticles;
                this.submitting = false;
                this.changeButtonState(false);
                this.loadingInitial = true;
                toast.success("Article modifié avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action editIncrementationArticle = async (values: Partial<IArticle>) => {
        this.loadingIncrementation = true;
        try {
            await agent.articleAgent.updateIncrementation(values);
            runInAction(() => {
                this.article!.incrementation = values.incrementation!;
                this.loadingIncrementation = false;
                toast.success("Article modifié avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.loadingIncrementation = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action uploadPhoto = async (file: Blob, id: number) => {
        this.uploadingPhoto = true;
        try {
            const photo = await agent.articleAgent.uploadPhoto(file, id);
            runInAction(() => {
                if (this.article) {
                    this.article.articlePhotos.push(photo);
                    this.article.uri = photo.url;
                }
                this.uploadingPhoto = false;
                toast.success("Chargement de photo effectué avec succès");
            })
        }
        catch (e) {
            console.log(e);
            toast.error("Problème de mise à jour photo!");
            runInAction(() => {
                this.uploadingPhoto = false;
            })
        }
    }

    @action updatePhoto = async (file: Blob, id: number, idPhoto: string) => {
        this.uploadingPhoto = true;
        try {
            const photo = await agent.articleAgent.updatePhoto(file, id, idPhoto);
            runInAction(() => {
                if (this.article) {
                    this.article.uri = photo.url;
                }
                this.uploadingPhoto = false;
                toast.success("Chargement de photo effectué avec succès");
            })
        }
        catch (e) {
            toast.error("Problème de mise à jour photo!");
            runInAction(() => {
                this.uploadingPhoto = false;
            })
        }
    }


    @action setMainPhoto = async (photo: IPhoto, id: number) => {
        this.loadingPhotoAction = true;
        try {
            await agent.articleAgent.setMainPhoto(photo.id, id);
            runInAction(() => {
                // this._baseStore.userStore.user!.image = photo.url;
                this.article!.articlePhotos.find(a => a.isMain)!.isMain = false;
                this.article!.articlePhotos.find(a => a.id === photo.id)!.isMain = true;
                //this.article!.image = photo.url;
                this.loadingPhotoAction = false;
                toast.success("Photo affectée avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de mise à jour photo de cet article!");
            runInAction(() => {
                this.loadingPhotoAction = false;
            })
        }
    };

    @action deletePhoto = async (photo: IPhoto, id: number) => {
        this.loadingPhotoAction = true;
        try {
            await agent.articleAgent.deletePhoto(photo.id, id);
            runInAction(() => {
                this.article!.articlePhotos = this.article!.articlePhotos.filter(a => a.id !== photo.id);
                if (this.article?.articlePhotos.length == 0)
                    this.article.uri = "/assets/img/DefaultImg.png";
                this.loadingPhotoAction = false;
                toast.success("Photo d'article supprimée avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Probléme de suppression!");
            runInAction(() => {
                this.loadingPhotoAction = false;
            })
        }
    }


    @action disable = async (id: number) => {
        this.deletingArticle = true;
        try {
            await agent.articleAgent.disable(id);
            runInAction(() => {
                this.deletingArticle = false;
                this.article!.isActivated = false;
            })
            toast.success("Article désactivé avec succés");

        } catch (e) {
            console.log(e);
            toast.error("Problème désactivation article!");
            runInAction(() => {
                this.deletingArticle = false;
            })
        }
    }

    @action enable = async (id: number) => {
        this.deletingArticle = true;
        try {
            await agent.articleAgent.enable(id);
            runInAction(() => {
                this.deletingArticle = false;
                this.article!.isActivated = true;
            })
            toast.success("Article activé avec succés");
        } catch (e) {
            console.log(e);
            toast.error("Problème d'activation article!");
            runInAction(() => {
                this.deletingArticle = false;
            })
        }
    }


    @action loadArticleFranchiseurs = async (id: number, type: string) => {
        this.ITListArticleByFranchiseur = [];
        this.loadingInitial = true;
        try {
            const articlesByFranchiseurs = await agent.articleAgent.getArticleFranchiseur(id, type);
            runInAction(() => {
                this.articlesFranchiseur = articlesByFranchiseurs;
                articlesByFranchiseurs.forEach((article) => {
                    let articles: IArticleListByFranchiseur = {
                        key: article.productId,
                        text: article.reference.toString() + ' , ' + article.label,
                        value: article.productId,
                        belongToOpeningPack: article.belongToOpeningPack,
                        inStock: article.inStock,
                        isPack: article.isPack,
                        quantity: article.quantity,
                        packProducts: article.packProducts,
                        modeAppro: article.modeAppro,
                        isService: article.isService,
                        tvaRate: article.tvaRate
                    }
                    this.ITListArticleByFranchiseur.push(articles)
                })
                this.loadingInitial = false;
            })
            return this.ITListArticleByFranchiseur;
        } catch (e) {
            toast.error("Problem loading !");
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }

    @action loadArticleByCode = async (code: number) => {
        this.loadingArticle = true;
        try {
            const article = await agent.articleAgent.detailsByCode(code);
            runInAction(() => {
                this.article = article;
                this.loadingArticle = false;
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de loading article!");
            runInAction(() => {
                this.loadingArticle = false;
            })
        }
    }

    @action loadArticlesFournisseur = async (id: number, type: string) => {
        this.loadingArticle = true;
        try {
            const articles = type == "Fournisseur" ? await agent.articleAgent.listArticlesFournisseur(id) : await agent.articleAgent.listArticlesFranchiseur(id);
            runInAction(() => {
                this.articlesFournisseur = articles;
                this.loadingArticle = false;
            })
        } catch (e) {
            toast.error("Problème de loading article!");
            runInAction(() => {
                this.loadingArticle = false;
            })
        }
    }

    @action loadDeliveryDelay = async () => {
        this.submittingDeliveryDelay = true;
        this.IDeliveryDelayList = [];
        try {
            const DeliveryDelay = await agent.articleAgent.listDeliveryDelay();
            runInAction(() => {
                DeliveryDelay.forEach((DeliveryDelay) => {
                    let supportt: IListDeliveryDelay = {
                        label: DeliveryDelay,
                        value: DeliveryDelay
                    }
                    this.IDeliveryDelayList.push(supportt)
                })
                this.submittingDeliveryDelay = false;
            })
        } catch (e) {
            toast.error("Problem loading delais de livraison!");
            runInAction(() => {
                this.submittingDeliveryDelay = false;
            })
        }
    }


    @action duplicate = async (Product: IArticle) => {
        this.submitting = true;
        try {
            const id = await agent.articleAgent.duplicate(Product);
            runInAction(() => {
                this.submitting = false;
                toast.success("duplication effectué avec succès");
            })
            history.push(`/detailArticle/${id}`);
        } catch (e) {
            toast.error("Problème d'ajout d'article!");
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    loadArticleFranchiseurOptions = async (id: number) => {
        return await agent.articleAgent.getArticleFranchiseur(id, "Franchiseur");

    };
    loadArticleOptions = async (id:number,inputValue: string | null) => {
        if (!inputValue || !inputValue.trim() || inputValue.length < 2) {
            return await agent.articleAgent.getArticleCompany(id,null).then(r => r.data);
        } else
            return await agent.articleAgent.getArticleCompany(id,inputValue).then(r => r.data);
    };
    loadSearchArticleOptions = async ( inputValue: string | null) => {
        if (!inputValue || !inputValue.trim() || inputValue.length < 2) {
            return await agent.articleAgent.getSearchArticle(null).then(r => r.data);
        } else
            return await agent.articleAgent.getSearchArticle(inputValue).then(r => r.data);
    };

    @action ExportArticle = async (obj: any) => {
        this.loadingExport = true;
        try {
            await agent.articleAgent.exportArticle(obj);
            runInAction(() => {
                this.loadingExport = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingExport = false;
            })
            console.log(e);
        }
    }
    @action ExportArticleService = async (obj: any) => {
        this.loadingExportSer = true;
        try {
            await agent.articleAgent.exportService(obj);
            runInAction(() => {
                this.loadingExportSer = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingExportSer = false;
            })
            console.log(e);
        }
    }

    @action loadCommandeFournisseur = async (id: number) => {
        this.loadingCF = true;
        try {
            const CFs = await agent.articleAgent.listCF(id);
            runInAction(() => {
                this.ListCF = CFs;
                this.loadingCF = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingCF = false;
                history.push('/articles');
            })
        }
    }

    @action uploadFile = async (file: Blob, id: number) => {
        this.uploadingFile = true;
        try {
            const fichier = await agent.articleAgent.uploadFichier(file, id);
            runInAction(() => {
                if (this.article) {
                    this.article.fichiers.push(fichier);
                }
                this.uploadingFile = false;
                toast.success("Chargement de fichier est effectué avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.uploadingFile = false;
            })
        }
    }

    @action deleteFile = async (file: IFichier, id: number) => {
        this.deletingFile = true;
        try {
            await agent.articleAgent.deleteFichier(file.id, id);
            runInAction(() => {
                this.article!.fichiers = this.article!.fichiers.filter(a => a.id !== file.id);
                this.deletingFile = false;
                toast.success("Suppression de fichier est effectué avec succès");
            })
        } catch (e) {
            toast.error("Problème de suppresion du fichier!");
            runInAction(() => {
                this.deletingFile = false;
            })
        }
    }
}