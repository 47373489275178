import React, { useContext, useEffect } from 'react';
import ArticleHeader from './ArticleHeader';
import ArticleContent from './ArticleContent';
import { Grid, Header } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router-dom';
import Loading from '../../../app/layout/Loading';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import Breadcrumbs from '../../dashboard/breadcrumbs';

interface RouteParams {
    id: string,
}

interface IProps extends RouteComponentProps<RouteParams> { }
const ArticlePage: React.FC<IProps> = ({ match }) => {
    const baseStore = useContext(BaseStoreContext);
    const { loadArticle, setActiveTab, loadingArticle, article, enable, disable, deletingArticle, duplicate, submitting } = baseStore.articleStore;
    const { isLoggedIn } = baseStore.userStore;
    const { loadOptionsArticle } = baseStore.optionStore;
    const { inPack, isInPack, loadingInitial } = baseStore.articleComposeStore;
    const { loadArticleStockByArticle } = baseStore.articleStockStore;
    const { loadEntrepots } = baseStore.entrepotStore;

    useEffect(() => {
        loadArticle(parseInt(match.params.id));
        isInPack(match.params.id);
        loadOptionsArticle(match.params.id)
        loadEntrepots();
        loadArticleStockByArticle(parseInt(match.params.id));
    }, [])
    if (loadingArticle) return <Loading content="Chargement de l'article ..." />
    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Articles", root: "" },
                    { key: 1, name: "Liste articles", root: "/articles" },
                    { key: 2, name: "" + article?.label, root: `/detailArticle/${article?.productId}` },
                ]}
            />
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Grid style={{ marginTop: 2 }}>
                            <Grid.Column width={16}>
                                <ArticleHeader article={article!} disable={disable} enable={enable} isLoggedIn={isLoggedIn} loading={deletingArticle} duplicate={duplicate} submitting={submitting} />
                                <ArticleContent setActiveTab={setActiveTab} article={article!} loading={loadingInitial} isInPack={inPack!} id={match.params.id} />
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}

export default observer(ArticlePage);