import React, { useContext, useEffect, useState } from 'react';
import { GridColumn, Grid, Loader } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import InfiniteScroll from 'react-infinite-scroller';
import { BaseStoreContext } from '../../app/store/baseStore';
import Loading from '../../app/layout/Loading';
import ListItemPlaceholder from '../../app/common/form/ListItemPlaceholder';
import Breadcrumbs from '../dashboard/breadcrumbs';
import ListAlertStock from './ListAlertStock';
import AlertStockFilter from './AlertStockFilter';

const AlertStockDashboard: React.FC = () => {

    const baseStore = useContext(BaseStoreContext);
    const {  loadingNotification, page, totalPages, setPage, loadAlertStockList } = baseStore.articleStockStore;
    const [loadingNext, setLoadingNext] = useState(false);

    const handleGetNext = () => {
        setLoadingNext(true);
        setPage(page + 1);
        loadAlertStockList().then(() => setLoadingNext(false));
    }

    useEffect(() => {
        loadAlertStockList();
    }, [loadAlertStockList]);

    if (loadingNotification) return <Loading content="Chargement des alert ..." />

    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Stock", root: "" },
                    { key: 1, name: "Alert de stock", root: "/alertStockDashboard" },
                ]}
            />
            <Grid>
                <GridColumn width={12} style={{ marginTop: 2 }}>
                    <Grid style={{ marginTop: 2 }}>
                        <GridColumn width={16}>
                            {loadingNotification && page === 0 ? <ListItemPlaceholder /> : (
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={handleGetNext}
                                    hasMore={!loadingNext && page + 1 < totalPages}
                                    initialLoad={false}
                                >
                                    <ListAlertStock />
                                </InfiniteScroll>
                            )}
                        </GridColumn>
                    </Grid>
                </GridColumn>
                <Grid.Column width={4} style={{ marginTop: 67 }} >
                    <Grid.Column width={16}>
                    </Grid.Column>
                    <AlertStockFilter />
                </Grid.Column>
                <Grid.Column width={10}>
                    <Loader active={loadingNext} />
                </Grid.Column>
            </Grid>
        </div>
    );
}
export default observer(AlertStockDashboard);