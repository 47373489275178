import React, { useContext, useEffect, useState } from 'react';
import { Form, Item, Table, Button, Header } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import Loading from '../../../app/layout/Loading';
import FormeJuridiqueForm from './AddFormeJuridiqueModal';
import { observer } from 'mobx-react-lite';
import TextInput from '../../../app/common/form/inputText';
import DeleteFormeJuridiqueModal from './DeleteFormeJuridiqueModal';
import Breadcrumbs from '../../dashboard/breadcrumbs';

const ListFormeJuridique: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { loadFormeJuridiques, loadingInitial, edit, formeJuridiques, loadingFormeJuridiqueAction} = baseStore.formeJuridiqueStore;
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    const [editTarget, setEditTarget] = useState<string | undefined>(undefined);
    const [editForm, setEditForm] = useState(false);
    const [label, setLabel] = useState('');
    const [desc, setDesc] = useState('');
    const { openModal } = baseStore.modalStore;

    const handleFinalFormSubmit = () => {

        let newMode = {
            label: label,
            description: desc,
            formeJuridiqueId: editTarget,
        };
        edit(newMode);
        setEditForm(false)
    }
    useEffect(() => {
        loadFormeJuridiques();
    }, [])

    if (loadingInitial) return <Loading content="Chargement des formes juridiques..." />
    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0,name: "Paramétres", root: "" },
                    { key: 1,name: "Forme Juridique", root: "/dashboardFormeJuridique" },
                ]}
            />
            <div style={{ margin: '2rem 1rem' }}>
            <Item.Group>
                <Item>
                    <Item.Content>
                        <Header as='h2' icon='address card outline'></Header>
                        <Header as='h2'>{'Forme Juridique'}</Header>
                        <Button
                            floated="right"
                            content={baseStore.formeJuridiqueStore.addModeFormeJuridique ? 'Annuler' : 'Ajouter une forme juridique'}
                            color="orange"
                            icon='arrow alternate circle left outline'
                            basic
                            onClick={() => { baseStore.formeJuridiqueStore.changeButtonStateAdd(!baseStore.formeJuridiqueStore.addModeFormeJuridique) }}
                        />
                    </Item.Content>
                </Item>
            </Item.Group>
            {baseStore.formeJuridiqueStore.addModeFormeJuridique &&
                <div>
                    <FormeJuridiqueForm/>
                    <br />
                </div>
            }
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Label</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        <Table.HeaderCell>Crée par</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {formeJuridiques && formeJuridiques.map((formeJuridique: any) => (
                        <Table.Row key={formeJuridique.formeJuridiqueId}>
                            {editForm && editTarget === formeJuridique.formeJuridiqueId ? (
                                <>
                                    <Table.Cell selectable>
                                        <Form.Input name='label' component={TextInput} placeholder='Label' defaultValue={formeJuridique.label} style={{ width: '100%', padding: '1%' }} onChange={(e) => setLabel(e.target.value)} />
                                    </Table.Cell>
                                    <Table.Cell selectable>
                                        <Form.Input name='description' component={TextInput} placeholder='Description' defaultValue={formeJuridique.description} style={{ width: '100%', padding: '1%' }} onChange={(e) => setDesc(e.target.value)} />
                                    </Table.Cell>
                                    <Table.Cell >{formeJuridique.owner}</Table.Cell>
                                    <Table.Cell >
                                        <Button.Group fluid widths={2}>
                                            <Button
                                                type='button'
                                                onClick={() => {
                                                    setEditForm(false);
                                                }}>
                                                Annuler
                                            </Button>
                                            <Button.Or text='Ou' />
                                            <Button
                                                name={formeJuridique.formeJuridiqueId}
                                                positive
                                                type='submit'
                                                onClick={(e) => {
                                                    handleFinalFormSubmit()
                                                    setEditTarget(e.currentTarget.name)
                                                }}
                                            >Confirmer
                                            </Button>
                                        </Button.Group>
                                    </Table.Cell>
                                </>
                            ) : (
                                <>
                                    <Table.Cell>{formeJuridique.label}</Table.Cell>
                                    <Table.Cell>{formeJuridique.description}</Table.Cell>
                                    <Table.Cell >{formeJuridique.owner}</Table.Cell>
                                    <Table.Cell >
                                        <Button.Group fluid widths={2}>
                                            <Button
                                                onClick={(e) => {
                                                    setEditForm(true);
                                                        setEditTarget(e.currentTarget.name)
                                                        setLabel(formeJuridique.label)
                                                        setDesc(formeJuridique.description)
                                                }}
                                                color="blue"
                                                icon='edit outline'
                                                style={{ marginRight: '3%' }}
                                                name={formeJuridique.formeJuridiqueId}
                                            />
                                            <Button
                                                name={formeJuridique.formeJuridiqueId}
                                                basic
                                                color='red'
                                                icon='trash'
                                                onClick={(e) => {
                                                    openModal(<DeleteFormeJuridiqueModal formeJuridique={formeJuridique} />)
                                                    setDeleteTarget(e.currentTarget.name)
                                                }}
                                                loading={loadingFormeJuridiqueAction && deleteTarget === formeJuridique.formeJuridiqueId}
                                            />
                                        </Button.Group>
                                    </Table.Cell>
                                </>
                            )}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            </div>
        </div>
    );
}
export default observer(ListFormeJuridique);