import React from 'react'
import { Message } from 'semantic-ui-react'

const AucunArticle = () => (
    <div style={{ marginTop: '50px' }}>
        <Message
            error
            list={[
                "Aucun article pour le moment",

            ]}
        />
    </div>
)

export default AucunArticle