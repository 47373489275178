import { useContext, useState } from 'react';
import { Tab, Header, Button, Grid, Table, Progress, Modal } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import AddCommandeFournisseurArticle from './AddCommandeFournisseurArticle';
import EditCommandeFournisseurArticle from './EditCommandeFournisseurArticle';
import DeleteOrderFournisseurLineModal from './DeleteOrderFournisseurLineModal';
import AucunProduit from '../../commande/detailCommande/AucunProduit';
import { IOrderFournisseurLine } from '../../../app/models/ICommandeFournisseur';

const CommandeFournisseurArticle = () => {
    const baseStore = useContext(BaseStoreContext)
    const { commandeFournisseur, deletingcommandeFournisseurArticle, progress, submitting } = baseStore.commandeFournisseurStore;
    const [addArticleMode, setAddArticleMode] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    const { openModal } = baseStore.modalStore;
    const [modalOpen, setModalOpen] = useState(false);
    const [orderLine, setOrderLine] = useState<IOrderFournisseurLine>();

    return (
        <Tab.Pane>
            <Modal
                open={modalOpen}
                size='large'
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >
                <Modal.Header icon='add' >
                    Modifier l'article
                    <Button
                        style={{ height: 35 }}
                        type='button' icon='x' color='red'
                        floated='right'
                        onClick={() => { setModalOpen(false) }}
                    >
                    </Button>
                </Modal.Header>
                <Modal.Content>
                    <EditCommandeFournisseurArticle orderLine={orderLine!} order={commandeFournisseur!} setModalOpen={setModalOpen} />
                </Modal.Content>
            </Modal>
            <Grid>
                <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                    <Header floated='left' icon='shopping basket' content='Produits' />
                    {commandeFournisseur!.orderFournisseurState! == 'AttenteValidation' &&
                        <Button
                            onClick={() => setAddArticleMode(!addArticleMode)}
                            floated='right'
                            basic
                            content={addArticleMode ? 'Annuler' : 'Ajouter produit'}
                        />}
                </Grid.Column>
                <Grid.Column width={16}>
                    {addArticleMode ? (
                        <AddCommandeFournisseurArticle setMode={setAddArticleMode} order={commandeFournisseur!} />
                    ) : (
                        <>
                            {submitting &&
                                <Progress color='orange' percent={progress} size='tiny' />
                            }
                            {commandeFournisseur!.orderFournisseurLines.length == 0 ? (
                                <AucunProduit />
                            ) : (
                                <Table celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Article</Table.HeaderCell>
                                            <Table.HeaderCell>Réf.</Table.HeaderCell>
                                            <Table.HeaderCell>Qtt</Table.HeaderCell>
                                            <Table.HeaderCell>PU HT.</Table.HeaderCell>
                                            <Table.HeaderCell>TVA</Table.HeaderCell>
                                            <Table.HeaderCell>REMISE</Table.HeaderCell>
                                            <Table.HeaderCell>HT</Table.HeaderCell>
                                            {commandeFournisseur!.orderFournisseurState == 'AttenteValidation' &&
                                                <Table.HeaderCell style={{ textAlign: "center" }}>Actions</Table.HeaderCell>}
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {commandeFournisseur &&
                                            commandeFournisseur.orderFournisseurLines.map(orderLine => (
                                                <>
                                                    <Table.Row key={orderLine.id} >
                                                        <Table.Cell rowspan={orderLine.comment == "" || orderLine.comment == null ? 1 : 2}>
                                                            <a style={{ backgroundColor: 'none' }} href={`/detailArticle/${orderLine.articleId}`}>
                                                                {orderLine.codeArticle} - {orderLine.article}
                                                                <ul className="ui list" style={{ fontSize: 12, fontWeight: 700 }}>
                                                                    {orderLine.printing && <li>Impression:{orderLine.printing}</li>}
                                                                    {orderLine.paperFormat && <li>Format:{orderLine.paperFormat}</li>}
                                                                    {orderLine.paperWeight && <li>Support:{orderLine.paperWeight}</li>}
                                                                    <li>Poids unitaire:{orderLine.weight} Kg</li>
                                                                    {orderLine.deliveryDelay && <li>Délais de livraison:{orderLine.deliveryDelay}</li>}
                                                                    {orderLine.productAttributeValues && orderLine.productAttributeValues.map((item) => (
                                                                        <li>{item.productAttributeName}:{item.value}</li>
                                                                    ))
                                                                    }
                                                                </ul>
                                                            </a>
                                                        </Table.Cell>
                                                        <Table.Cell>{orderLine.internalRef}</Table.Cell>
                                                        <Table.Cell>{orderLine.quantity}</Table.Cell>
                                                        <Table.Cell>{orderLine.unitPrice.toFixed(4)} €</Table.Cell>
                                                        <Table.Cell>{orderLine.tvaRate.toFixed(2)} %</Table.Cell>
                                                        <Table.Cell>{orderLine!.remise} %</Table.Cell>
                                                        <Table.Cell>{(orderLine.quantity * orderLine.unitPrice - (orderLine.quantity * orderLine.unitPrice) * orderLine.remise / 100).toFixed(2)} €</Table.Cell>
                                                        {commandeFournisseur!.orderFournisseurState == 'AttenteValidation' &&
                                                            <Table.Cell>
                                                                <Button.Group fluid widths={2}>
                                                                    <Button
                                                                        onClick={(e) => {
                                                                            setOrderLine(orderLine)
                                                                            setModalOpen(true);
                                                                        }}
                                                                        basic
                                                                        color="blue"
                                                                        icon='edit outline'
                                                                        name={orderLine.id}
                                                                        style={{ marginRight: '2%' }}
                                                                    />
                                                                    <Button
                                                                        name={commandeFournisseur.id}
                                                                        onClick={(e) => {
                                                                            openModal(<DeleteOrderFournisseurLineModal orderLine={orderLine} />)
                                                                            setDeleteTarget(e.currentTarget.name)
                                                                        }}
                                                                        loading={deletingcommandeFournisseurArticle && deleteTarget === orderLine.id.toString()}
                                                                        basic
                                                                        negative
                                                                        icon='trash'
                                                                    />
                                                                </Button.Group>
                                                            </Table.Cell>}
                                                    </Table.Row>
                                                    {orderLine.comment &&
                                                        <Table.Row >
                                                            <Table.Cell colspan={7} style={{ fontSize: 12, fontWeight: 700,  borderLeft: '1px solid rgba(34,36,38,.1)' }}>{orderLine.comment}</Table.Cell>
                                                        </Table.Row>
                                                    }
                                                </>
                                            ))}
                                    </Table.Body>
                                </Table>
                            )}
                        </>
                    )}
                </Grid.Column>
            </Grid>
        </Tab.Pane>
    );
};
export default observer(CommandeFournisseurArticle);
