import { useContext } from 'react';
import { Tab, Grid, Header, Button, Item, ItemContent } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import NotFound from '../../../app/layout/NoFound';
import CommandeFournisseurDuplicationInformations from './CommandeFournisseurDuplicationInformations';
import CommandeFournisseurEditForm from './CommandeFournisseurEditForm';
import FormGridColumn from '../../../app/common/form/FormGridColumn';
import { FiEdit } from 'react-icons/fi';
import UpdateDeliveryDateModal from './updateDeliveryDateModal';
import { numberFormat } from '../../../app/common/options/IStyle';

const CommandeFournisseurDescription = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { openModal } = baseRepo.modalStore;
    const { commandeFournisseur, editCommandeFournisseur, submitting, editCommandeFournisseurMode, duplicationMode } = baseRepo.commandeFournisseurStore;
    if (!commandeFournisseur) {
        return <NotFound />
    }
    else {
        return (
            <Tab.Pane>
                <Grid>
                    <Grid.Column width={16}>
                        <Header floated='left' icon='qrcode' content={`Code: ${commandeFournisseur!.id}`} />
                        {commandeFournisseur!.orderFournisseurState == 'AttenteValidation' &&
                            <Button
                                onClick={() => { baseRepo.commandeFournisseurStore.changeButtonState(!baseRepo.commandeFournisseurStore.editCommandeFournisseurMode) }}
                                floated='right'
                                basic
                                disabled={duplicationMode}
                                content={editCommandeFournisseurMode ? 'Annuler' : 'Modifier'}
                            />
                        }
                    </Grid.Column>
                    <Grid.Column width={16}>
                        {baseRepo.commandeFournisseurStore.duplicationMode ? (
                            <CommandeFournisseurDuplicationInformations />
                        ) : (
                            baseRepo.commandeFournisseurStore.editCommandeFournisseurMode ? (
                                <CommandeFournisseurEditForm editCommandeFournisseur={editCommandeFournisseur} commandeFournisseur={commandeFournisseur!} submitting={submitting} />
                            ) : (
                                <Grid className="ui stackable">
                                    <Grid.Row style={{ padding: 0, margin: 0 }}>
                                        <FormGridColumn header="Fournisseur" content={commandeFournisseur!.nomAgence ?? commandeFournisseur!.fabricant} root={commandeFournisseur!.codeFranchiseur != 0 ? `/detailFranchiseur/${commandeFournisseur.franchiseur}` : `/detailFournisseur/${commandeFournisseur.fournisseur}`} />
                                        <FormGridColumn header="N° Commande Fournisseur" content={commandeFournisseur!.numSupplierOrder} />
                                        <FormGridColumn header="Réseau" content={commandeFournisseur.network} />
                                        <FormGridColumn header="Date Commande" content={new Date(commandeFournisseur!.orderDate).toLocaleDateString('en-GB')} />
                                        <FormGridColumn header="Transport" content={commandeFournisseur!.deliveryCompany} />
                                        <FormGridColumn header="Livraison" content={commandeFournisseur!.singleDelivery} />
                                        <FormGridColumn header="Montant HT" content={numberFormat.format(commandeFournisseur!.amountWithoutTaxes)} />
                                        <FormGridColumn header="Montant TTC" content={numberFormat.format(commandeFournisseur!.amountWithTaxes)} />
                                        <FormGridColumn header="Transporteur" content={commandeFournisseur!.deliveryService} />
                                        <FormGridColumn header="Commentaires" content={commandeFournisseur!.comment} />
                                        <FormGridColumn header="Référence Interne" content={commandeFournisseur!.internalRef} />
                                        <Grid.Column width={8} style={{ padding: 0, margin: 0, fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif' }} >
                                            <Item style={{ borderTop: "1px solid #eee", marginRight: 10 }}>
                                                <div style={{ fontWeight: 'bold', float: "left", width: "50%", fontSize: 12, padding: 10 }}>Date de livraison
                                                </div>
                                                <ItemContent style={{ display: "flex", float: "left", width: "50%", fontSize: 12, padding: 10 }}>{commandeFournisseur!.orderDeliveries.length > 0 ?
                                                    commandeFournisseur.orderDeliveries.map(x => <>{new Date(x.orderDate).toLocaleDateString('en-GB')}<br /></>) : "non introduite"}
                                                    <FiEdit className="editBtn" onClick={(e) => { openModal(<UpdateDeliveryDateModal id={commandeFournisseur.id} orderDeliveris={commandeFournisseur.orderDeliveries} />) }} />
                                                </ItemContent>
                                            </Item>
                                        </Grid.Column>

                                    </Grid.Row>
                                </Grid>
                            )
                        )}
                    </Grid.Column>
                </Grid>
            </Tab.Pane>
        );
    }
}
export default observer(CommandeFournisseurDescription);