import React, { useState, useContext } from 'react';
import { Button, Header, Icon, Container } from 'semantic-ui-react';
import { IOption } from '../../../app/models/IOption';
import { ISupport } from '../../../app/models/ISupport';
import { BaseStoreContext } from '../../../app/store/baseStore';

interface IProps {
    support: ISupport;
}

const DeleteSupportModal: React.FC<IProps> = ({ support }) => {
    const baseStore = useContext(BaseStoreContext);
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    const { closeModal } = baseStore.modalStore;
    const { deleteSupport } = baseStore.supportStore;

    return (
        <Container textAlign='center'>
            <Header icon='trash' content='Supprimer support' style={{ marginLeft: 20 }} />
            <p>
                Voulez vous supprimer cet impression ?
            </p>

            <Button.Group fluid>
                <Button onClick={() => closeModal()}>
                    <Icon name='remove' /> Annuler
                </Button>
                <Button.Or text='Ou' />
                <Button
                    name={support.supportId}
                    color='green'
                    onClick={(e) => {
                        deleteSupport(support.supportId)
                        setDeleteTarget(e.currentTarget.name)
                        closeModal()
                    }}>
                    <Icon name='checkmark' /> Confirmer
                </Button>
            </Button.Group>

        </Container>
    )
}
export default DeleteSupportModal;
