import React, { useContext } from 'react';
import { Table, Button, Message } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../app/store/baseStore';
import { numberFormat } from '../../app/common/options/IStyle';




const ListSoldes: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { soldes } = baseStore.clientStore;
    return (
        <>
            {soldes?.length != 0 ?
                (
                    <Table loading={false} celled style={{ textAlign: "center" }}>
                        <Table.Header >
                            <Table.Row>
                                <Table.HeaderCell>Client</Table.HeaderCell>
                                <Table.HeaderCell>Debit</Table.HeaderCell>
                                <Table.HeaderCell>Credit</Table.HeaderCell>
                                <Table.HeaderCell>SoldeClient</Table.HeaderCell>                             
                                <Table.HeaderCell>REF Facture En Attente</Table.HeaderCell>
                                <Table.HeaderCell>Date d'échéance</Table.HeaderCell>
                                <Table.HeaderCell>Date de facturation</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        {soldes &&
                            soldes.map(solde => (
                                <Table.Body>
                                    <Table.Cell>{solde.agencyName}-{solde.companyName}</Table.Cell>
                                    <Table.Cell>{numberFormat.format(solde.debit)}</Table.Cell>
                                    <Table.Cell>{numberFormat.format(solde.credit)}</Table.Cell>
                                    <Table.Cell>{numberFormat.format(solde.solde)}</Table.Cell>
                                    <Table.Cell>{solde.refFacEnAttente}</Table.Cell>
                                    <Table.Cell>{solde.dueDate ? new Date(solde.dueDate)?.toLocaleDateString("en-GB"):""}</Table.Cell>
                                    <Table.Cell>{solde.invoiceDate ? new Date(solde.invoiceDate)?.toLocaleDateString("en-GB"):""}</Table.Cell>
                                </Table.Body>
                            ))}
                    </Table>
                ) : (
                    <Message
                        style={{ padding: '20px 40px', margin: '40px 40px' }}
                        error
                        list={[
                            "Aucun Reglement pour le moment",

                        ]}
                    />
                )}
        </>
    );
}

export default observer(ListSoldes);