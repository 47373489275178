import { List, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import React from 'react';
import { IOrderLine } from '../../../app/models/ICommande';

interface IProps {
    quote: IOrderLine;
}
const DetailQuoteLine: React.FC<IProps> = ({ quote }) => {
    return (
        <Grid>
            <Grid.Column width={16}>
                <Grid columns={2} divided>
                    <Grid.Row>
                        <Grid.Column>
                            <List>
                                <List.Item>
                                    <List.Icon name='sidebar' />
                                    <List.Content as={Link} to={`/detailArticle/${quote.productId}`}><h5>Article: &nbsp;{quote!.articleCode}</h5></List.Content>
                                </List.Item>
                            </List>
                        </Grid.Column>
                        <Grid.Column>
                            <List>
                                <List.Item>
                                    <List.Icon name='sidebar' />
                                    <List.Content><h5>TVA: &nbsp;{quote!.tvaRate}</h5></List.Content>
                                </List.Item>
                            </List>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <List>
                                <List.Item>
                                    <List.Icon name='sidebar' />
                                    <List.Content><h5>Prix unitaire: &nbsp;{quote!.unitPrice}</h5></List.Content>
                                </List.Item>
                            </List>
                        </Grid.Column>
                        <Grid.Column>
                            <List>
                                <List.Item>
                                    <List.Icon name='sidebar' />
                                    <List.Content><h5>Quantité: &nbsp;{quote!.quantity}</h5></List.Content>
                                </List.Item>
                            </List>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <List>
                                <List.Item>
                                    <List.Icon name='sidebar' />
                                    <List.Content><h5>Format: &nbsp;{quote!.paperFormat}</h5></List.Content>
                                </List.Item>
                            </List>
                        </Grid.Column>
                        <Grid.Column>
                            <List>
                                <List.Item>
                                    <List.Icon name='sidebar' />
                                    <List.Content><h5>poids: &nbsp;{quote!.paperWeight}</h5></List.Content>
                                </List.Item>
                            </List>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <List>
                                <List.Item>
                                    <List.Icon name='sidebar' />
                                    <List.Content><h5>Frais de livraison: &nbsp;{quote!.deliveryCosts}</h5></List.Content>
                                </List.Item>
                            </List>
                        </Grid.Column>
                        <Grid.Column>
                            <List>
                                <List.Item>
                                    <List.Icon name='sidebar' />
                                    <List.Content><h5>Delai de livraison: &nbsp;{quote!.deliveryDelay}</h5></List.Content>
                                </List.Item>
                            </List>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Column>
        </Grid>
    );
}
export default observer(DetailQuoteLine);