import React, { useContext, Fragment } from 'react';
import { Item, Label } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import ClientListItem from './ClientListItem';
import ErrorAucunFranchise from './aucunFranchise';
import { IClient } from '../../../app/models/IClient';


const ListClient: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const {clients} = baseStore.clientStore;
    if (clients.length < 1) return < ErrorAucunFranchise />
    else
        return (
            <Item.Group divided>
                <Fragment>
                    <Item.Group>
                        {clients.map((client: IClient) => (
                                    <ClientListItem key={client.id} client={client} />
                                ))}
                            </Item.Group>
                </Fragment>
            </Item.Group>
        );
}

export default observer(ListClient);