import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Header, Label, Table } from "semantic-ui-react";
import { BaseStoreContext } from "../../../app/store/baseStore";
import { observer } from 'mobx-react-lite';
import NoResult from "../../devis/devisDashboard/erreurNoResult";
import DeleteFactureModal from "./DeleteFactureModal";

import _ from "lodash";
import { IInvoiceDto } from "../../../app/models/IFacture";
const getStatus = (status: string) => {
    switch (status) {
        case 'Valider':
            return 'Validée';
        case 'Brouillon':
            return 'Brouillon';
        default:
            return '';
    }
}

const numberFormat = new Intl.NumberFormat('FR-fr', {
    style: 'currency',
    currency: 'EUR',
    useGrouping: true,
});

const ListeFactures: React.FC = () => {

    const baseStore = useContext(BaseStoreContext);
    const { factures, FusionFactureList, submitting, sending, SendMails, deletingFacture } = baseStore.factureStore;
    const [ids, setIds] = useState<number[]>([]);
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    const { openModal } = baseStore.modalStore;

    const handleCheck = (key: number, value: any) => {
        if (value.target.checked) {
            setIds([...ids, key]);
        } else {
            setIds(_.without(ids, key));
        }
    }

    
    const handleCheckAll = (value: any) => {
        var tab: number[] = [];
        if (value.target.checked) {
            factures.map((facture) => {
                if (facture.invoiceStatus === "Valider"  )
                    tab.push(facture.id);
            });
            setIds(tab);
        } else {
            setIds([]);
        }
    }

    const getBackgroundColor = (key: number) => {
        return ids.includes(key) ? 'darkgrey' : 'white';
    }

    const Footer = () => {
        return <footer className='footer footer.collapse' style={{
            background: 'gray', textAlign: 'center', left: 0, right: 0, opacity: 0.8
        }}>
            <Button positive type='submit' style={{ margin: '5px' }} disabled={submitting} loading={submitting} onClick={(e) => {
                FusionFactureList(ids)
            }}  >Générer PDF</Button>
            <Button color="blue" type='submit' style={{ margin: '5px' }} disabled={sending} loading={sending} onClick={(e) => {
                SendMails(ids)
            }}  >Envoyer Emails</Button>
        </footer>;
    }
    if (factures.length < 1) return < NoResult name="Facture" />
    else
    return (
        <>
            <Table compact='very' style={{ marginTop: '0em' }} textAlign='center'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell><input type="checkbox" onChange={(value) => handleCheckAll(value)} /></Table.HeaderCell>
                        <Table.HeaderCell>Référence</Table.HeaderCell>
                        <Table.HeaderCell>N°CC</Table.HeaderCell>
                        <Table.HeaderCell>Client</Table.HeaderCell>
                        <Table.HeaderCell>Date Facture</Table.HeaderCell>
                        <Table.HeaderCell>Montant HT</Table.HeaderCell>
                        <Table.HeaderCell>TVA</Table.HeaderCell>
                        <Table.HeaderCell>Montant TTC</Table.HeaderCell>
                        <Table.HeaderCell>Etat</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {factures && factures.map((facture: any) => (
                        <Table.Row key={facture.id} style={{ backgroundColor: getBackgroundColor(facture.id), textAlign: 'center' }}>
                            <Table.Cell> {facture.invoiceStatus === "Valider"  ? (<input type="checkbox" checked={ids.includes(facture.id)} onChange={(value) => handleCheck(facture.id, value)} />) : ("")}  </Table.Cell>
                            <Table.Cell>
                                <Header size='small'><Label style={{ marginLeft: '1px', marginTop: '5px', marginBottom: '5px' }} as={Link} to={`/detailFacture/${facture.id}`}>{facture.reference}</Label></Header>
                            </Table.Cell>
                            <Table.Cell>{facture.orderRefs}</Table.Cell>
                            <Table.Cell>{facture.agencyName}-{facture.companyName}</Table.Cell>
                            <Table.Cell>{new Date(facture.invoiceDate).toLocaleDateString('en-GB')}</Table.Cell>
                            <Table.Cell>{numberFormat.format(facture.amountWithoutTaxes)}</Table.Cell>
                            <Table.Cell>{numberFormat.format((facture.amountWithTaxes - facture.amountWithoutTaxes))}</Table.Cell>
                            <Table.Cell>{numberFormat.format(facture.amountWithTaxes)}</Table.Cell>
                            <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }} >{facture.isCanceled ? "Annulée" : facture.remainingPayment == 0 ? "Payée" : getStatus(facture.invoiceStatus)}</Table.Cell>
                            {facture.reference?.startsWith('PROV',0) &&
                                <Table.Cell>

                                    <Button.Group fluid widths={3}>
                                        <Button
                                            style={{ marginRight: '3%' }}
                                            name={facture.id}
                                            basic
                                            color='red'
                                            icon='trash'
                                            onClick={(e) => {
                                                openModal(<DeleteFactureModal facture={facture} />)
                                                setDeleteTarget(e.currentTarget.name)
                                            }}
                                            loading={deletingFacture && deleteTarget === facture.id}
                                        />
                                    </Button.Group>
                                </Table.Cell>
                            }
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            {ids.length > 0 ? (<Footer />) : (<br />)}
        </>
    );
};
export default observer(ListeFactures);