import React, { useContext, useEffect, useState } from 'react';
import { GridColumn, Grid, Loader, Button, Label } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import ListItemPlaceholder from '../../../app/common/form/ListItemPlaceholder';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';
import { FaFileExcel, FaPlusCircle } from 'react-icons/fa';
import ListCommandeFournisseur from './ListCommandeFournisseur';
import CommandeFournisseurFilter from './CommandeFournisseurFilter';
import Breadcrumbs from '../../dashboard/breadcrumbs';
import { numberFormat } from '../../../app/common/options/IStyle';

const CommandeFournisseurDashboard: React.FC = () => {

    const baseStore = useContext(BaseStoreContext);
    const { loadListCommandeFournisseur, loadingInitial, page, totalPages, setPage, totalHt, totalTtc } = baseStore.commandeFournisseurStore;
    const [loadingNext, setLoadingNext] = useState(false);
    useEffect(() => {
        loadListCommandeFournisseur();     
    }, []);

    const handleGetNext = () => {
        setLoadingNext(true);
        setPage(page + 1);
        loadListCommandeFournisseur().then(() => setLoadingNext(false));
    }

    //if (loadingInitial) return <Loading content="Chargement des commandes fournisseur ..." />

    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Achats", root: "" },
                    { key: 1, name: "commandes Fournisseurs", root: "/commandeFournisseurDashboard" },
                ]}
            />
            <Grid>
                <Grid.Row style={{ width: "100%" }}>
                    <Grid.Column style={{ padding: 0, margin: 0, marginRight: 20 }}>
                        <Button
                            size="small"
                            style={{ paddingTop: 0, paddingBottom: 0, height: 32 }}
                            floated="right"
                            content="Ajouter Commande"
                            className="andi-btn filled warning"
                            icon={(<FaPlusCircle style={{ marginRight: "10px" }} />)}
                            as={Link}
                            to="/addCommandeFournisseur"
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ padding: 0, margin: "0 20px 0 20px" }}>
                    <CommandeFournisseurFilter />
                </Grid.Row>
                <GridColumn width={16} style={{ marginTop: 2 }}>
                    <Label>Total HT : {numberFormat.format(totalHt)}</Label>
                    <Label>Total TTC : {numberFormat.format(totalTtc)}</Label>
                    <Grid style={{ marginTop: 2 }}>
                        <GridColumn width={16}>
                            {loadingInitial && page === 0 ? <ListItemPlaceholder /> : (
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={handleGetNext}
                                    hasMore={!loadingNext && page + 1 < totalPages}
                                    initialLoad={false}
                                >
                                    <ListCommandeFournisseur />
                                </InfiniteScroll>
                            )}
                        </GridColumn>
                    </Grid>
                </GridColumn>

                <Grid.Column width={16}>
                    <Loader active={loadingNext} />
                </Grid.Column>
            </Grid>
        </div>
    );
}
export default observer(CommandeFournisseurDashboard);