import { useEffect, useContext, useState } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button, Grid } from 'semantic-ui-react';
import TextInput from '../../../app/common/form/inputText';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { combineValidators, composeValidators, createValidator, isRequired } from 'revalidate';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { ITarifFranchiseur } from '../../../app/models/ITarifFranchiseur';
import { IArticle } from '../../../app/models/IArticle';
import { ICompanyList } from '../../../app/models/IClient';

const isPositive = createValidator(
    message => value => {
        if (value && !/^[1-9][0-9]*$/.test(value)) {
            return message;
        }
    },
    'Invalid number'
);
const isFloat = createValidator(
    message => value => {
        if (value && !/^[+]?\d+(\.\d+)?$/i.test(value)) {
            return message;
        }
    },
    'Invalid number'
);
const validate = combineValidators({
    unitPrice: composeValidators(
        isRequired, isFloat({ message: "Veuillez saisir un valeur positif " }))({
            message: 'Veuillez saisir le prix'
        }),
    quantite: composeValidators(
        isRequired, isPositive({ message: "Veuillez saisir un valeur valid " }))({
            message: 'Veuillez saisir la quantité'
        }),
    unitQuantity: composeValidators(
        isRequired, isPositive({ message: "Veuillez saisir un valeur valid " }))({
            message: "Veuillez saisir l'unité de vente"
        }),
});
interface IProps {
    article: IArticle
}
const AffectTarifFranchiseurToArticle: React.FC<IProps> = ({ article }) => {
    const baseRepo = useContext(BaseStoreContext);
    const { create, tarifFranchiseurs } = baseRepo.tarifFranchiseurStore;
    const { loadCompaniesList, companies, loadingInitial } = baseRepo.clientStore;
    const [company, setCompany] = useState<ICompanyList | null>(null);
    const [CompanyList, setCompanyList] = useState<ICompanyList[]>([]);

    useEffect(() => {
        if (article.companyId == 0)
            loadCompaniesList(0, 'Franchiseur');
    },[])
    const handleChangeTableClient = () => {
        if (company != null && CompanyList.indexOf(company) == -1) {
            var list = CompanyList;
            list.push(company);
            setCompanyList(list);
            setCompany(null);
        }
    }

    const handleFinalFormSubmit = (values: any) => {
        if (values.quantite % values.unitQuantity != 0) {
            toast.warning("Unité de vente doit être multiple de quanitié maximum...");
            return;
        }
        if (tarifFranchiseurs.find(x => x.quantite == values.quantite && x.typeFranchiseur == "Franchiseur" && x.companyId == article.companyId)) {
            toast.warning("Quantite déja existe pour Type client selectionné");
        }
        else if (article.companyId == 0 && CompanyList.length==0) {
            toast.warning("SVP, séléctionnez au moins un client");  
        }
        else {

            let newTraif: ITarifFranchiseur = {
                ...values,
                article: article?.productId,
                companies: CompanyList,
                agencyName: article.agencyName,
                companyName: article.franchiseurLibelle,
                hasClient: article.companyId == 0
            };
            create(newTraif);
            baseRepo.articleStore.changeButtonState(false)
        }
    }
    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            validate={validate}
            render={({ handleSubmit, invalid, pristine, submitting }) => (
                <Form onSubmit={handleSubmit} error>
                    {article.companyId == 0 ? (
                        <Grid style={{ marginBottom: 10 }}>
                            {/*<Checkbox*/}
                            {/*    onClick={() => setHasClient(!hasClient)}*/}
                            {/*    label='Spécifiez un réseau'*/}
                            {/*    style={{ marginLeft: '10px' }}*/}
                            {/*    defaultChecked={hasClient}*/}
                            {/*/>*/}
                            <Grid.Column width={8}>
                                {/*{hasClient && (*/}
                                    <Select
                                        isSearchable
                                        isLoading={loadingInitial}
                                        name="form-field-name"
                                        noOptionsMessage={() => "Code introuvable"}
                                        getOptionLabel={e => e!.agencyName + ' - ' + e!.companyName}
                                        getOptionValue={e => e!.id.toString()}
                                        value={company}
                                        options={companies}
                                        onChange={e => setCompany(e)}
                                    />
                            {/*    )}*/}
                            </Grid.Column>
                            <Grid.Column width={4}>
                                {/*{hasClient && (*/}
                                    <Button style={{ margin: 0 }} color="blue" loading={submitting} type='button'
                                        onClick={handleChangeTableClient}
                                    >Ajouter</Button>
                                {/*)}*/}
                            </Grid.Column>
                        </Grid>
                    ) : (
                            <></>
                        //typeClient.value != 'Franchiseur' && (
                        //    <Grid columns={3} style={{ marginBottom: 10 }}>
                        //        <Grid.Column width={4}>
                        //                <Checkbox
                        //                    onClick={() => { setHasClient(!hasClient); setCompanyList([]) }}
                        //                label='Spécifiez les clients'
                        //                style={{ paddingLeft: '10px' }}
                        //                defaultChecked={hasClient}
                        //            />
                        //        </Grid.Column>
                        //        <Grid.Column width={8}>
                        //            {hasClient && (
                        //                <Select
                        //                    isSearchable
                        //                    isLoading={loadingInitial}
                        //                    name="form-field-name"
                        //                    noOptionsMessage={() => "Code introuvable"}
                        //                    getOptionLabel={e => e!.agencyName + ' - ' + e!.companyName}
                        //                        getOptionValue={e => e!.id.toString()}
                        //                        value={company}
                        //                    options={companies}
                        //                    onChange={e => setCompany(e)}
                        //                />
                        //            )}
                        //        </Grid.Column>
                        //        <Grid.Column width={4}>
                        //            {hasClient && (
                        //                <Button style={{ margin: 0 }} color="blue" loading={submitting} type='button'
                        //                    onClick={handleChangeTableClient}
                        //                >Ajouter</Button>
                        //            )}
                        //        </Grid.Column>
                        //        </Grid>
                        //    )
                    )}
                    <Grid>
                        {CompanyList && CompanyList.map((item) => (
                            <span style={{paddingTop:5}}>{item.agencyName} - {item.companyName} </span>
                        ))}                    
                    </Grid>
                    <hr />
                    <Grid columns={3} >
                        {/*<Grid.Column>*/}
                        {/*    <h5 >Type Client</h5>*/}
                        {/*    <Select*/}
                        {/*        options={ITListTypeFranchiseur}*/}
                        {/*        getOptionLabel={e => e!.text}*/}
                        {/*        getOptionValue={e => e!.value}*/}
                        {/*        defaultValue={typeClient}*/}
                        {/*        onChange={(e) => {*/}
                        {/*            handleChange(e); setCompanyList([]); article.companyId != 0 && loadCompaniesList(article.companyId, e!.value); article.companyId !=0 && e!.value == "Franchiseur" && setHasClient(false)}}*/}
                        {/*    />*/}
                        {/*</Grid.Column>*/}
                        <Grid.Column>
                            <h5 >Tarif unitaire</h5>
                            <Field name='unitPrice' component={TextInput} type='number' />
                        </Grid.Column>
                        <Grid.Column>
                            <h5 >Unité de vente</h5>
                            <Field name='unitQuantity' component={TextInput} type='number' />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>Quantité maxi.</h5>
                            <Field name='quantite' component={TextInput} type='number' />
                        </Grid.Column>
                    </Grid>
                    <div style={{ marginTop: '30px' }}>
                        <Button.Group floated="right">
                            <Button disabled={submitting} onClick={() => { baseRepo.articleStore.changeButtonState(!baseRepo.articleStore.editArticleMode) }} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive disabled={submitting || invalid || pristine || (article.companyId == 0 && CompanyList.length == 0)} loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </div>
                </Form >
            )}
        />
    );
};

export default observer(AffectTarifFranchiseurToArticle);