import React, { useState, useContext } from 'react';
import { Button, Header, Icon, Container } from 'semantic-ui-react';
import { IOption } from '../../../app/models/IOption';
import { BaseStoreContext } from '../../../app/store/baseStore';

interface IProps {
    option: IOption;
}

const DeleteOptionModal: React.FC<IProps> = ({ option }) => {
    const baseStore = useContext(BaseStoreContext);
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    const { closeModal } = baseStore.modalStore;
    const { deleteOption } = baseStore.optionStore;

    return (
        <Container textAlign='center'>
            <Header icon='trash' content='Supprimer option' style={{ marginLeft: 20 }} />
            <p>
                Voulez vous supprimer cet impression ?
            </p>

            <Button.Group fluid>
                <Button onClick={() => closeModal()}>
                    <Icon name='remove' /> Annuler
                </Button>
                <Button.Or text='Ou' />
                <Button
                    name={option.optionId}
                    color='green'
                    onClick={(e) => {
                        deleteOption(option.optionId)
                        setDeleteTarget(e.currentTarget.name)
                        closeModal()
                    }}>
                    <Icon name='checkmark' /> Confirmer
                </Button>
            </Button.Group>

        </Container>
    )
}
export default DeleteOptionModal;
