import { useContext, useEffect, useState } from 'react';
import { Tab, Header, Button, Grid, Form, Checkbox, Container, Icon, Message, Table, Accordion } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import TextInput from '../../../app/common/form/inputText';
import DeleteTarifFranchiseurModal from './DeleteTarifFranchiseurModal';
import AffectTarifFranchiseurToArticle from './AffectTarifFranchiseurToArticle';
import { IProductPrice } from '../../../app/models/ITarifFranchiseur';
import AddProductPriceModal from './AddProductPriceModal';

const TarifFranchiseurDescription = () => {
    const baseStore = useContext(BaseStoreContext)
    const { deletingtarifFranchiseur, edit, submitting, productPrices, loadingtarifFranchiseur, loadTarifs, deleteSelectedProductPrices } = baseStore.tarifFranchiseurStore;
    const { article, editIncrementationArticle, loadingIncrementation } = baseStore.articleStore;
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    const [editTarget, setEditTarget] = useState<string | undefined>(undefined);
    const [editForm, setEditForm] = useState(false);
    const [prix, setPrix] = useState<number>();
    const [quantite, setQuantite] = useState<number>();
    const [index, setIndex] = useState<number>();
    const [selectAll, setSelectAll] = useState(false);
    const [selected, setSelected] = useState<string[]>([]);
    const { openModal, closeModal } = baseStore.modalStore;

    useEffect(() => {
        loadTarifs(article!.productId);
        baseStore.articleStore.changeButtonState(false);
    }, [loadTarifs])

    const handleClick = (e: number) => {
        index != e ? setIndex(e) : setIndex(-1);
    }
    const handleChangeQuantite = (e: any, result: any) => {
        setQuantite(result.value);
    }
    const handleChangePrix = (e: any, result: any) => {
        setPrix(result.value);
    }
    const handleChangeIncrementation = (e: any, result: any) => {
        let newArticle = {
            productId: article!.productId,
            incrementation: !article!.incrementation
        }
        editIncrementationArticle(newArticle)
    }
    const handleChangeSelectedItems = () => {
        var tab: string[] = [];
        productPrices.map((tarif, key) => (
            tarif.groupProductPrices.map((group) => {
                group.productPrices.map(productPrice => (
                    tab.push(productPrice.id))
                )
            })
        ));
        setSelected(tab)
    }

    const handleChangeSelectedItem = (id: string) => {
        var tab: string[] = [];
        selected.map(item => (
            tab.push(item)));
        if (tab.includes(id))
            tab = tab.filter(x => x != id);
        else
            tab.push(id);
        setSelected(tab);
    }
    const handleFinalFormSubmit = (key: number, keyT: number, tarif: IProductPrice) => {
        let newTarif = {
            ...tarif,
            quantity: quantite!,
            price: prix!,
            id: editTarget!
        };
        edit(key, keyT, newTarif);
        setEditForm(false)
    }

    return (
        <Tab.Pane>
            <Grid>
                <Grid.Column width={16} style={{ paddingBottom: 0 }}>

                    <Header floated='left' icon='shopping basket' content={baseStore.articleStore.editArticleMode ? 'Ajout Tarif' : ' Tarif Vente'} />
                    <Button
                        onClick={() => baseStore.articleStore.changeButtonState(!baseStore.articleStore.editArticleMode)}
                        floated='right'
                        basic
                        content={baseStore.articleStore.editArticleMode ? 'Annuler' : 'Ajouter tarif'}
                    />
                </Grid.Column>
                {!baseStore.articleStore.editArticleMode &&
                    <Grid.Column width={16} >
                        {!loadingIncrementation ?
                            <Checkbox toggle
                                onClick={handleChangeIncrementation}
                                name='incrementation'
                                label='Incrémentation unitaire'
                                style={{ marginLeft: '10px', paddingTop: 10 }}
                                defaultChecked={article!.incrementation}
                            /> :
                            <span style={{ marginLeft: 20, paddingTop: 10 }}>chargement ...</span>
                        }
                        {!loadingtarifFranchiseur && productPrices.length > 0 &&
                            <>
                                <Button
                                    negative
                                    size="small"
                                    type='button'
                                    style={{ float: 'right', }}
                                    onClick={() =>
                                        openModal(
                                            <Container textAlign='center'>
                                                <Header icon='trash' content='Supprimer les tarifs sélectionnés ' style={{ marginLeft: 20 }} />
                                                <p>
                                                    Voulez vous supprimer ces tarifs ?
                                                </p>
                                                <Button.Group fluid>
                                                    <Button onClick={() => closeModal()}>
                                                        <Icon name='remove' /> Annuler
                                                    </Button>
                                                    <Button.Or text='Ou' />
                                                    <Button
                                                        color='green'
                                                        onClick={(e) => {
                                                            deleteSelectedProductPrices(article!.productId, selected);
                                                            setSelected([]);
                                                            setSelectAll(false);
                                                            closeModal();
                                                        }}>
                                                        <Icon name='checkmark' /> Confirmer
                                                    </Button>
                                                </Button.Group>

                                            </Container>)
                                    }
                                    loading={deletingtarifFranchiseur}
                                    content='Supprimer'
                                    disabled={selected.length == 0}
                                />
                                <Checkbox checked={selectAll} label="tout sélectionner" style={{ float: 'right', padding: '0 10px', paddingTop: 10 }} onChange={() => { setSelectAll(!selectAll); selectAll ? setSelected([]) : handleChangeSelectedItems() }} />
                            </>
                        }

                        <hr />
                    </Grid.Column>
                }
                <Grid.Column width={16}>
                    {loadingtarifFranchiseur ?
                        <div style={{ marginTop: 50 }} className="ui segment">
                            <div className="ui active inverted dimmer">
                                <div className="ui medium text loader">Chargement ...</div>
                            </div>
                            <p></p>
                            <p></p>
                        </div>
                        : (
                            baseStore.articleStore.editArticleMode ? (
                                <AffectTarifFranchiseurToArticle article={article!} />
                            ) : (
                                productPrices.length != 0 ?
                                    (
                                        <>
                                            {productPrices && productPrices.map((tarif, key) => (
                                                <Accordion fluid styled style={{ marginBottom: 10 }}>
                                                    <Accordion.Title
                                                        active={index === key}
                                                        index={key}
                                                        onClick={(e) => handleClick(key)}
                                                        style={{ fontSize: 'large', color: 'black' }}>
                                                        <i className="dropdown icon"></i>
                                                        {tarif.agencyName} - {tarif.companyName}
                                                    </Accordion.Title>
                                                    <Accordion.Content active={index === key}>
                                                        <Button
                                                            style={{ width: 30, height: 30, padding: 0, paddingTop: 2, margin: 0 }}
                                                            size="tiny"
                                                            floated="right"
                                                            icon="add"
                                                            positive
                                                            onClick={() => openModal(<AddProductPriceModal agencyName={tarif.agencyName} companyName={tarif.companyName} product={article!.productId} />)}
                                                        />
                                                        {tarif.groupProductPrices.map((productPrices, keyT) => (
                                                            <>
                                                                {tarif.agencyName.length < 6 && productPrices.companyType != '0' ? (
                                                                    <legend>{tarif.agencyName} - Tarif pour les {productPrices.companyType == '1' ? 'franchisés' : 'succurssale'} de {tarif.companyName}</legend>
                                                                ) : (
                                                                    <legend>{tarif.agencyName} - {tarif.companyName}</legend>
                                                                )}
                                                                <Table celled>
                                                                    <Table.Header>
                                                                        <Table.Row>
                                                                            <Table.HeaderCell>Quantité</Table.HeaderCell>
                                                                            <Table.HeaderCell>Prix unitaire</Table.HeaderCell>
                                                                            <Table.HeaderCell>Total HT</Table.HeaderCell>
                                                                            <Table.HeaderCell>Date d'application</Table.HeaderCell>
                                                                            <Table.HeaderCell></Table.HeaderCell>
                                                                        </Table.Row>
                                                                    </Table.Header>
                                                                    <Table.Body>
                                                                        {productPrices.productPrices.map(productPricesItem => (
                                                                            <Table.Row key={productPricesItem.id}>
                                                                                {editForm && editTarget === productPricesItem.id.toString() ? (
                                                                                    <>
                                                                                        <Table.Cell>
                                                                                            <Form.Input style={{ height: '25px', width: '120px' }} name='quantite' component={TextInput} defaultValue={productPricesItem!.quantity} type='number' onChange={handleChangeQuantite} />
                                                                                        </Table.Cell>
                                                                                        <Table.Cell>
                                                                                            <Form.Input style={{ height: '25px', width: '120px' }} name='prix' component={TextInput} defaultValue={productPricesItem!.price} type='number' onChange={handleChangePrix} />
                                                                                        </Table.Cell>
                                                                                        <Table.Cell>{(productPricesItem.price * productPricesItem.quantity).toFixed(2)} €</Table.Cell>
                                                                                        <Table.Cell>{new Date(productPricesItem.startingDate).toLocaleDateString('en-GB')}</Table.Cell>
                                                                                        <Table.Cell>
                                                                                            <Button.Group widths={2} style={{ width: 42, height: 20, fontSize: 10, margin: 0, padding: 0, float: 'right' }}>
                                                                                                <Button
                                                                                                    style={{ marginRight: '2%', padding: "0px 0px" }}
                                                                                                    type='button'
                                                                                                    size="tiny"
                                                                                                    onClick={() => {
                                                                                                        setEditForm(false);
                                                                                                    }}
                                                                                                    icon='cancel'
                                                                                                />
                                                                                                <Button
                                                                                                    name={productPricesItem.id}
                                                                                                    positive
                                                                                                    size="tiny"
                                                                                                    type='submit'
                                                                                                    onClick={(e) => {
                                                                                                        handleFinalFormSubmit(key, keyT, productPricesItem)
                                                                                                        setEditTarget(e.currentTarget.name)
                                                                                                    }}
                                                                                                    style={{ padding: "0px 0px" }}
                                                                                                    loading={submitting}
                                                                                                    icon='check'
                                                                                                />
                                                                                            </Button.Group>
                                                                                        </Table.Cell>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <Table.Cell>{productPricesItem.quantity}</Table.Cell>
                                                                                        <Table.Cell>{productPricesItem.price.toFixed(4)} €</Table.Cell>
                                                                                            <Table.Cell>{(productPricesItem.price * productPricesItem.quantity).toFixed(2)} €</Table.Cell>
                                                                                            <Table.Cell>{new Date(productPricesItem.startingDate).toLocaleDateString('en-GB')}</Table.Cell>
                                                                                        <Table.Cell style={{ width: 91 }}>
                                                                                            <Checkbox style={{ paddingTop: 4, marginRight: 5 }} checked={selected.includes(productPricesItem.id)} onChange={() => handleChangeSelectedItem(productPricesItem.id)} />
                                                                                            <Button.Group widths={2} style={{ width: 42, height: 20, fontSize: 10, margin: 0, padding: 0 }}>
                                                                                                <Button
                                                                                                    onClick={(e) => {
                                                                                                        setEditForm(true);
                                                                                                        setEditTarget(e.currentTarget.name)
                                                                                                        setQuantite(productPricesItem.quantity)
                                                                                                        setPrix(productPricesItem.price)
                                                                                                    }}
                                                                                                    size="tiny"
                                                                                                    basic
                                                                                                    color="blue"
                                                                                                    icon='edit outline'
                                                                                                    name={productPricesItem.id}
                                                                                                    style={{ marginRight: '2%', padding: "0px 0px", float: 'right' }}
                                                                                                />
                                                                                                <Button
                                                                                                    name={productPricesItem.id}
                                                                                                    onClick={(e) => {
                                                                                                        openModal(<DeleteTarifFranchiseurModal keyP={key} keyT={keyT} TarifId={productPricesItem!.id} agencyName={tarif.agencyName} type={productPrices.companyType} qtt={productPricesItem.quantity} />)
                                                                                                        setDeleteTarget(e.currentTarget.name)
                                                                                                    }}
                                                                                                    loading={deletingtarifFranchiseur && deleteTarget === productPricesItem.id.toString()}
                                                                                                    basic
                                                                                                    size="tiny"
                                                                                                    negative
                                                                                                    icon='trash'
                                                                                                    style={{ padding: "0px 0px" }}
                                                                                                />
                                                                                            </Button.Group>
                                                                                        </Table.Cell>
                                                                                    </>
                                                                                )}
                                                                            </Table.Row>
                                                                        ))}
                                                                    </Table.Body>
                                                                </Table>
                                                            </>
                                                        ))}

                                                    </Accordion.Content>
                                                </Accordion>
                                            ))}
                                        </>
                                    ) : (
                                        <Grid style={{ marginTop: 20, justifyContent: 'center' }}>
                                            <Message
                                                error
                                                list={[
                                                    "Aucun tarif pour le moment",
                                                    "Veuillez ajouter des tarifs pour accèder à la liste",
                                                ]}
                                            />
                                        </Grid>
                                    )
                            )
                        )}
                </Grid.Column>
            </Grid>
        </Tab.Pane>
    );
};
export default observer(TarifFranchiseurDescription);
