import { useContext, useEffect, useState } from 'react';
import { Tab, Header, Button, Grid, Table } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { useParams } from 'react-router-dom';
import NoResult from '../../commande/commandeDashboard/ErrorNoResult';
import AddReglementInvoice from './AddReglementInvoice';
import DeletePayment from './DeletePayment';
const numberFormat = new Intl.NumberFormat('FR-fr', {
    style: 'currency',
    currency: 'EUR',
    useGrouping: true,
});
interface UrlParams {
    id: string;
}
const ReglementInformation: React.FC = () => {
    const { id } = useParams<UrlParams>();
    const baseStore = useContext(BaseStoreContext);
    const { facture } = baseStore.factureStore;
    const { reglements, loadReglementByInvoice, loadingReglementByInvoice, loadReglementOrderNotConsumed, deletingReglement } = baseStore.reglementStore;
    const { openModal } = baseStore.modalStore;
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);

    useEffect(() => {
        loadReglementByInvoice(parseInt(id));
        loadReglementOrderNotConsumed(parseInt(id));
    }, []);

    return (
        <Tab.Pane  >
            {/*<Modal*/}
            {/*    open={modalOpen}*/}
            {/*    size='large'*/}
            {/*    closeOnEscape={true}*/}
            {/*    closeOnRootNodeClick={true}*/}
            {/*>*/}
            {/*    <Modal.Header icon='add' >*/}
            {/*        Modifier reglement*/}
            {/*        <Button*/}
            {/*            style={{ height: 35 }}*/}
            {/*            type='button' icon='x' color='red'*/}
            {/*            floated='right'*/}
            {/*            onClick={() => { setModalOpen(false) }}*/}
            {/*        >*/}
            {/*        </Button>*/}
            {/*    </Modal.Header>*/}
            {/*    <Modal.Content>*/}
            {/*        <EditReglementInvoice reglement={reglement!} setModalOpen={setModalOpen} />*/}
            {/*    </Modal.Content>*/}
            {/*</Modal>*/}
            <Grid>
                <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                    <Header floated='left' icon='file' content={`Reglement pour la facture : ${facture?.reference}`} />
                    {facture?.invoiceLines.length != 0 &&
                        <Button
                            onClick={() => { baseStore.reglementStore.changeButtonStateAdd(!baseStore.reglementStore.addMode) }}
                            floated='right'
                            basic
                            style={{ display: facture?.remainingPayment == 0 ? 'none' : 'block' }}
                            content={baseStore.reglementStore.addMode ? 'Annuler' : 'Affecter Reglement'}
                        />}
                </Grid.Column>
                <Grid.Column width={16}>
                    {baseStore.reglementStore.addMode ? (
                        <AddReglementInvoice />
                    ) : (
                        <>
                            {loadingReglementByInvoice ? (
                                <div style={{ marginTop: 50 }} className="ui segment">
                                    <div className="ui active inverted dimmer">
                                        <div className="ui medium text loader">Chargement ...</div>
                                    </div>
                                    <p></p>
                                    <p></p>
                                </div>
                            ) : (
                                reglements.length != 0 ?
                                    (
                                        <Table loading={false} celled >

                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>Montant</Table.HeaderCell>
                                                    <Table.HeaderCell>Date de paiement</Table.HeaderCell>
                                                    <Table.HeaderCell>Mode de paiement</Table.HeaderCell>
                                                    <Table.HeaderCell>Compte bancaire</Table.HeaderCell>
                                                    {!facture?.isClosed &&
                                                        <Table.HeaderCell></Table.HeaderCell>
                                                    }
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>
                                                {reglements &&
                                                    reglements.map(reglement => (
                                                        <Table.Row key={reglement.idReglement} >
                                                            <>
                                                                <Table.Cell>{numberFormat.format(reglement.montant)}</Table.Cell>
                                                                <Table.Cell>{new Date(reglement.creationDate).toLocaleDateString('en-GB')}</Table.Cell>
                                                                <Table.Cell>{reglement.modePaiement}</Table.Cell>
                                                                <Table.Cell>{reglement.account}</Table.Cell>
                                                                {!facture?.isClosed &&

                                                                    <Table.Cell>
                                                                        <Button
                                                                            style={{}}
                                                                            name={reglement.idReglement}
                                                                            basic
                                                                            size="small"
                                                                            color='red'
                                                                            icon='trash'
                                                                            onClick={(e) => {
                                                                                openModal(<DeletePayment reglement={reglement} />)
                                                                                setDeleteTarget(e.currentTarget.name)
                                                                            }}
                                                                            loading={deletingReglement && deleteTarget === reglement.idReglement}
                                                                        />
                                                                    </Table.Cell>
                                                                }
                                                            </>
                                                        </Table.Row>
                                                    ))}
                                            </Table.Body>
                                        </Table>
                                    ) : (
                                        <NoResult name='Reglement' />
                                    ))}
                        </>
                    )}
                </Grid.Column>
            </Grid>
        </Tab.Pane >
    );
}
export default observer(ReglementInformation);