import { observer } from "mobx-react-lite";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Button, Checkbox, Container, Form, Table } from "semantic-ui-react";
import { IInventoryProduct } from "../../app/models/IInventory";


interface IProps {

    setModalOpen: Dispatch<SetStateAction<boolean>>;
    inventoryProducts: IInventoryProduct[];

}

const VerifyProductsModal: React.FC<IProps> = ({ setModalOpen, inventoryProducts}) => {


        return (

            <Container textAlign='center'>
                <Table loading={false} celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Article</Table.HeaderCell>
                            <Table.HeaderCell>Fournisseur</Table.HeaderCell>
                            <Table.HeaderCell>Qte S. Théorique</Table.HeaderCell>
                            <Table.HeaderCell>Qte S. Réel</Table.HeaderCell>
                            <Table.HeaderCell>Valeur Unitaire</Table.HeaderCell>
                            <Table.HeaderCell>Prix Vente</Table.HeaderCell>
                            <Table.HeaderCell>Commentaire</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>

                        {inventoryProducts &&
                            inventoryProducts.map(inventoryProduct => (

                                <Table.Row key={inventoryProduct.id}>
                                    <Table.Cell>{inventoryProduct.productId}-{inventoryProduct.productName}</Table.Cell>
                                    <Table.Cell>{inventoryProduct.fournisseur}</Table.Cell>
                                    <Table.Cell>{inventoryProduct.stock_theorique}</Table.Cell>
                                    <Table.Cell>{inventoryProduct.stock_reel}</Table.Cell>
                                    <Table.Cell>{inventoryProduct.prix_vente.toFixed(4)} €</Table.Cell>
                                    <Table.Cell>{inventoryProduct.valeur.toFixed(4)} €</Table.Cell>
                                    <Table.Cell>{inventoryProduct.commentaire}</Table.Cell>
                                    <Table.Cell>{inventoryProduct.isNotExist ? "Inexistant" : inventoryProduct.isDirectSale ? "Vente directe" : ""}</Table.Cell>
                                   
                                </Table.Row>
                            ))}

                    </Table.Body>
                </Table>
                <Form.Group unstackable style={{ marginLeft: "70%" }} >
                    <Button.Group floated="right">
                        <Button onClick={() => { setModalOpen(false); }} floated='right' type='button'>Annuler</Button>
                        <Button.Or />
                        <Button positive  floated='right' type='submit'>Confirmer</Button>
                    </Button.Group>
                </Form.Group>
            </Container>
        );
    }

export default observer(VerifyProductsModal);