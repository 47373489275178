import React, { useContext, useEffect, useState } from 'react';
import { Form, Item, Table, Button, Header, Progress, Reveal, Checkbox } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import Loading from '../../../app/layout/Loading';
import { observer } from 'mobx-react-lite';
import TextInput from '../../../app/common/form/inputText';
import TvaForm from './AddTvaModal';
import Breadcrumbs from '../../dashboard/breadcrumbs';

const ListTva: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { loadTvas, loadingInitial, edit, tvas, deletingTva, progress, submitting, disable, enable, isDefault, loadingTvaAction } = baseStore.tvaStore;
    const [editTarget, setEditTarget] = useState<number | undefined>(undefined);
    const [target, setTarget] = useState<string | undefined>(undefined);
    const [editForm, setEditForm] = useState(false);
    const [nom, setNom] = useState<string | undefined>(undefined);
    const [taux, setTaux] = useState<number>();
    const handleChangeTaux = (e: any, result: any) => {
        setTaux(result.value);
    }
    const handleFinalFormSubmit = () => {

        let newTva = {
            nom: nom,
            taux: taux,
            id: editTarget,
        };
        edit(newTva);
        setEditForm(false)
    }
    useEffect(() => {
        loadTvas();
    }, [loadTvas]);

    if (loadingInitial) return <Loading content="Chargement des Tva ..." />

    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0,name: "Paramétres", root: "" },
                    { key: 1,name: "TVA", root: "/dashboardTva" },
                ]}
            />
            <div style={{ margin: '2rem 1rem' }}>
            <Item.Group>
                <Item>
                    <Item.Content>
                        <Header as='h2' icon='address card outline'></Header>
                        <Header as='h2'>{'TVA'}</Header>
                        <Button
                            floated="right"
                            content={baseStore.tvaStore.addMode ? 'Annuler' : 'Ajouter TVA'}
                            color="orange"
                            icon='arrow alternate circle left outline'
                            basic
                            onClick={() => { baseStore.tvaStore.changeButtonStateAdd(!baseStore.tvaStore.addMode) }}
                        />
                    </Item.Content>
                </Item>
            </Item.Group>
            {baseStore.tvaStore.addMode &&
                <div>
                    <TvaForm />
                    <br />
                </div>
            }
            {submitting &&
                <Progress color='orange' percent={progress} size='tiny' />
            }
            <Table loading={false} celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Nom</Table.HeaderCell>
                        <Table.HeaderCell>Taux</Table.HeaderCell>
                        <Table.HeaderCell>Par default</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {tvas && tvas.map((tva: any) => (
                        <Table.Row key={tva.id}>
                            {editForm && editTarget === tva.id ? (
                                <>
                                    <Table.Cell selectable>
                                        <Form.Input name='nom' component={TextInput} placeholder='Nom' defaultValue={tva.nom} style={{ width: '100%', padding: '1%' }} onChange={event => setNom(event.target.value)} />
                                    </Table.Cell>
                                    <Table.Cell selectable>
                                        <Form.Input name='taux' component={TextInput} placeholder='Taux' defaultValue={tva.taux} style={{ width: '100%', padding: '1%' }} type='number' onChange={handleChangeTaux} />
                                    </Table.Cell>
                                    <Table.Cell >
                                        <Button.Group fluid widths={2}>
                                            <Button
                                                type='button'
                                                onClick={() => {
                                                    setEditForm(false);
                                                }}>
                                                Annuler
                                            </Button>
                                            <Button.Or />
                                            <Button
                                                name={tva.id}
                                                positive
                                                type='submit'
                                                onClick={(e) => {
                                                    handleFinalFormSubmit()
                                                    setEditTarget(parseInt(e.currentTarget.name))
                                                }}
                                            >Confirmer</Button>
                                        </Button.Group>
                                    </Table.Cell>
                                </>
                            ) : (
                                <>
                                    <Table.Cell>{tva.nom}</Table.Cell>
                                    <Table.Cell>{tva.taux}%</Table.Cell>
                                        <Table.Cell>
                                            <Checkbox toggle
                                                onClick={e => {
                                                    isDefault(tva!.id);
                                                    setTarget(e.currentTarget.name);
                                                }}
                                                name={tva!.adresseId}
                                                loading={loadingTvaAction && target === tva.id}
                                                style={{ marginLeft: '10px' }}
                                                defaultChecked={tva!.isDefault}
                                                disabled={tva.isDefault}
                                                checked={tva.isDefault} />
                                        </Table.Cell>
                                    <Table.Cell>
                                        <Button.Group fluid widths={3}>
                                                <Button
                                                    size='medium'
                                                    basic
                                                    onClick={(e) => {
                                                        setEditForm(true);
                                                        setEditTarget(parseInt(e.currentTarget.name))
                                                        setNom(tva.nom)
                                                        setTaux(tva.taux)
                                                    }}
                                                    color="blue"
                                                    icon='edit outline'
                                                    style={{ marginRight: '3%' }}
                                                    name={tva.id}
                                                    />
                                                    <Reveal animated='move'>
                                                    <Reveal.Content visible style={{ width: '100%' }}>

                                                        <Button
                                                            fluid
                                                            color={tva.isActive ? 'green' : 'red'}
                                                            content={tva.isActive ? ' Active' : ' désactivé '}
                                                        />
                                                    </Reveal.Content>
                                                    <Reveal.Content hidden>
                                                        <Button
                                                            loading={deletingTva}
                                                            fluid
                                                            basic
                                                            color={tva.isActive ? 'red' : 'green'}
                                                            content={tva.isActive ? 'Désactiver' : 'Activer'}
                                                            onClick={
                                                                tva.isActive
                                                                    ? () => disable(tva.id)
                                                                    : () => enable(tva.id)
                                                            }
                                                        />
                                                    </Reveal.Content>
                                                </Reveal>

                                        </Button.Group>
                                    </Table.Cell>
                                </>
                            )}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            </div>
        </div>
    );
}
export default observer(ListTva);