import React, { useContext } from 'react';
import { Form, Button, Grid } from 'semantic-ui-react';
import { Form as FinalForm, Field } from 'react-final-form';
import { combineValidators, composeValidators, isRequired } from 'revalidate';
import { BaseStoreContext } from '../../../app/store/baseStore';
import TextInput from '../../../app/common/form/inputText';
import ErrorMessage from '../../../app/common/form/ErrorMessage';


const validate = combineValidators({
    nom: composeValidators(
        isRequired,
    )({
        message: 'Veuillez enter un label'
    }),
    taux: composeValidators(
        isRequired,
    )({
        message: 'Veuillez enter une description'
    }),

});

const TvaForm = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { create, tvas } = baseRepo.tvaStore;

    const matchTva = (value: any) => {
        if (tvas.find(x => x.taux.toString() == value)) {
            return `Taux Tva ${value} existe`;
        } else
            return undefined;
    }

    const handleFinalFormSubmit = (values: any) => {
        let newTva = {
           ...values,
        };
        create(newTva);
        baseRepo.tvaStore.changeButtonStateAdd(!baseRepo.tvaStore.addMode)
    }

    return (

        <FinalForm
            onSubmit={handleFinalFormSubmit}
            validate={validate}
            render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit }) => (

                <Form onSubmit={handleSubmit} error>
                    <Grid columns={3}>
                        <Grid.Row>
                            <Grid.Column>
                                <Field fluid name='nom' component={TextInput} placeholder='Nom' />
                            </Grid.Column>
                            <Grid.Column>
                                <Field fluid name='taux' component={TextInput} type='number' placeholder='Taux' validate={matchTva} />
                            </Grid.Column>
                            {submitError && !dirtySinceLastSubmit && (
                                <ErrorMessage
                                    error={submitError}
                                    text=' invalide'
                                />
                            )}
                            <Grid.Column>
                                <Button
                                    fluid size='large'
                                    disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                                    loading={submitting}
                                    color='orange'
                                    content='Ajouter'
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                </Form>

            )} />

    );
}
export default TvaForm;