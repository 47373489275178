import React, { useContext, useEffect, useState } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button, Grid, Checkbox } from 'semantic-ui-react';
import TextInput from '../../../app/common/form/inputText';
import { observer } from 'mobx-react-lite';
import { IClient } from '../../../app/models/IClient';
import { BaseStoreContext } from '../../../app/store/baseStore';
import Select from 'react-select';
import { ITListBillingType } from '../../../app/common/options/IAchat';

interface IProps {
    editClient: (profile: Partial<IClient>) => void;
    client: IClient;
}

const GestionCommercialEditForm: React.FC<IProps> = ({ editClient, client }) => {
    const baseStore = useContext(BaseStoreContext);
    const [hasRum, setHasRum] = useState(client.hasRum);
    const [modePaiement, setModePaiement] = useState(client.modePaiement);
    const [modeReglement, setModeReglement] = useState(client.modeReglement);
    const { loadModePaiements, modePaiements, loadingInitial } = baseStore.modePaiementStore;
    const { loadModeReglements, modeReglements } = baseStore.modeReglementStore;
    const [commissionType, setCommissionType] = useState(client!.commissionType);
    const [commissionValue, setCommissionValue] = useState(client!.commissionValue);
    const [billedType, setBilledType] = useState<string>(client!.billingType);

    useEffect(() => {
        loadModePaiements();
        loadModeReglements();
    }, [loadModePaiements, loadModeReglements])

    const handleFinalFormSubmit = (values: any) => {
        var commission = commissionType ? commissionValue : 0;
        let newFranchiseur = {
            ...values,
            hasRum: hasRum,
            modePaiement: modePaiement,
            modeReglement: modeReglement,
            commissionType: commissionType,
            commissionValue: commission,
            billingType: billedType
        };
        editClient(newFranchiseur);
    }
    const handleChangeCommissionValue = (e: any, result: any) => {
        setCommissionValue(result.value);
    }
    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            initialValues={client!}
            render={({ handleSubmit, invalid, submitting }) => (
                <Form onSubmit={handleSubmit} error>
                    <div>
                        <Grid columns={2}>
                            <Grid.Column>
                                <h5>Compte Collectif</h5>
                                <Field name='compteCollectif' component={TextInput} type='text' value={client!.compteCollectif} />
                            </Grid.Column>
                            {/*<Grid.Column style={{ display: 'flex' }}>*/}
                            {/*    <Checkbox style={{ marginTop: 20, marginRight: 20 }} name='commissionType' label='Commission' checked={commissionType} onClick={() =>*/}
                            {/*        setCommissionType(!commissionType)} />*/}
                            {/*    {commissionType &&*/}
                            {/*        <div>*/}
                            {/*            <h5>Valeur commission</h5>*/}
                            {/*            <Form.Input component={TextInput} name='commissionValue' placeholder='Valeur commission' type="number" defaultValue={client.commissionValue} onChange={handleChangeCommissionValue} />*/}
                            {/*        </div>*/}
                            {/*    }*/}
                            {/*</Grid.Column>*/}
                            <Grid.Column>
                                <h5>Mode de paiement </h5>
                                <Select
                                    isLoading={loadingInitial}
                                    options={modePaiements}
                                    getOptionLabel={e => e!.label.toString()}
                                    getOptionValue={e => e!.paiementId.toString()}
                                    onChange={e => { setHasRum(e?.hasRum!); setModePaiement(e?.label!); }}
                                    placeholder={client.modePaiement ?? "Séléctionnez ... "}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <h5>Echéance </h5>
                                <Select
                                    isLoading={baseStore.modeReglementStore.loadingInitial}
                                    options={modeReglements}
                                    getOptionLabel={e => e!.label}
                                    getOptionValue={e => e!.reglementId}
                                    onChange={e => { setModeReglement(e?.label!); }}
                                    placeholder={client.modeReglement ?? "Séléctionnez ... "}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <h5>Facturer par</h5>
                                <Select
                                    defaultValue={ITListBillingType.find(x => x.value == billedType)}
                                    options={ITListBillingType}
                                    getOptionLabel={e => e!.text}
                                    getOptionValue={e => e!.value}
                                    classNamePrefix="select"
                                    onChange={(e) => { setBilledType(e!.value) }}
                                />
                            </Grid.Column>
                            {hasRum &&
                                <Grid.Column>
                                    <h5>RUM</h5>
                                    <Field name='rum' component={TextInput} placeholder='RUM' value={client!.rum} />
                                </Grid.Column>
                            }

                        </Grid>
                        <Button.Group floated="right" style={{marginTop:10}}>
                            <Button disabled={submitting} onClick={() => { baseStore.clientStore.changeButtonState(!baseStore.clientStore.editClientMode) }} floated='right' type='button'>Annuler</Button>
                            <Button positive disabled={submitting || invalid} loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </div>
                </Form>
            )}
        />
    );
};

export default observer(GestionCommercialEditForm);