import { Container, Table, Form, Button, Grid  } from "semantic-ui-react";
import { Form as FinalForm, Field } from 'react-final-form';
import { combineValidators, composeValidators, isRequired } from 'revalidate';
import TextInput from "../../../app/common/form/inputText";
import ErrorMessage from '../../../app/common/form/ErrorMessage';
import { ISpecialPrices } from "../../../app/models/ISpecialOffer";
import { MutableRefObject, useContext, useRef, useState } from "react";
import { BaseStoreContext } from "../../../app/store/baseStore";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";

const validate = combineValidators({
    minimumQuantity: composeValidators(
        isRequired,
    )({
        message: 'Veuillez enter une quantité'
    }),
    unitPrice: composeValidators(
        isRequired,
    )({
        message: 'Veuillez enter un prix'
    }),

});

interface IProps {
    modalButton: any;
    setModalOpen: Function;
    setModalButton: Function;
}

const AchatGroupeeModal: React.FC<IProps> = ({ modalButton, setModalOpen, setModalButton }) => {

    const baseStore = useContext(BaseStoreContext);
    const { listPrices, setPriceToList, deletePriceFromList } = baseStore.specialOfferStore;
    const [first, setFirst] = useState <number>(0);
    const [last, setLast] = useState<number>(0);

    const handleFinalFormSubmit = (values: any) => {
        if (first > 0 && last > 0) {
            let newPrice = {
                ...values,
                minimumQuantity: first,
                unitPrice: last
            };
            let specialP: ISpecialPrices = newPrice;
            setPriceToList(specialP);
            setFirst(0);
            setLast(0);
        } else {
            toast.warning("Prix et Quantite doit étre supérieure structement à zero");
        }
    }

    let euro = Intl.NumberFormat('en-DE', {
        style: 'currency',
        currency: 'EUR',
    });


    return (
        <Container textAlign='center'>

            <FinalForm 
                onSubmit={handleFinalFormSubmit}
                //validate={validate}
                render={({ handleSubmit, submitting }) => (

                    <Form onSubmit={handleSubmit} error>
                        <Grid columns={3}>
                            <Grid.Row>
                                <Grid.Column>
                                    <h5>Quantité</h5>
                                    <input  type='number' placeholder='quantité' value={first} onChange={(event:any) => setFirst(event.target.value)} />
                                </Grid.Column>
                                <Grid.Column>
                                    <h5>Prix</h5>
                                    <input  type='number' placeholder='prix' value={last} onChange={(event: any) => setLast(event.target.value)}/>
                                </Grid.Column>
                                
                                <Grid.Column>
                                    <Button
                                        fluid size='large'
                                        style={{marginTop:24}}
                                        loading={submitting}
                                        color='orange'
                                        content='Ajouter'
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                    </Form>

                )} />

            <Table loading={false} celled>

                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Quantité</Table.HeaderCell>
                        <Table.HeaderCell>Prix</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {listPrices.map((prix: any) => (
                        <Table.Row >
                            <Table.Cell>{prix.minimumQuantity}</Table.Cell>
                            <Table.Cell>{euro.format(prix.unitPrice)}</Table.Cell>
                            <Table.Cell>
                                <Button
                                    onClick={(e) => {
                                        deletePriceFromList(prix.minimumQuantity, prix.unitPrice)
                                    }}
                                    basic
                                    negative
                                    icon='trash'
                                />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>

        </Container>
        );
}

export default observer(AchatGroupeeModal);