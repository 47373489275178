import React, { useContext } from 'react';
import { Grid } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import DevisContent from './DevisContent';
import DevisHeader from './DevisHeader';
import Breadcrumbs from '../../dashboard/breadcrumbs';
import { useEffect } from 'react';
import Loading from '../../../app/layout/Loading';

interface RouteParams {
    id: string,
}
interface IProps extends RouteComponentProps<RouteParams> { }
const DevisPage: React.FC<IProps> = ({ match }) => {
    const baseStore = useContext(BaseStoreContext);
    const { setActiveTab, deletingDevisArticle, enAttente, valide, devis, loadingDevis, loadDevis } = baseStore.devisStore;

    useEffect(() => {
        setActiveTab('1');
        baseStore.devisStore.changeButtonState(false)
        loadDevis(match.params.id);
    }, [loadDevis, match.params.id])
    if (devis?.id.toString() != match.params.id) return <Loading content="Chargement du devi ..." />;
    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Commandes", root: "" },
                    { key: 1, name: "Devis", root: "/devisDashboard" },
                    { key: 2, name: "" + devis?.reference, root: `/detailDevis/${match.params.id}` },
                ]}
            />          
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Grid style={{ marginTop: 2 }}>
                            <Grid.Column width={16}>
                                <DevisHeader devis={devis!} loading={deletingDevisArticle} enAttente={enAttente} valide={valide} />
                                <DevisContent setActiveTab={setActiveTab} />
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}

export default observer(DevisPage);