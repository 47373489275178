import { ICommande } from "../../../app/models/ICommande";
import React, { useContext, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Button, Container, Grid, Icon, Reveal, Table } from "semantic-ui-react";
import { BaseStoreContext } from "../../../app/store/baseStore";
import { IInvoiceLine } from "../../../app/models/IFacture";
import { Modal } from 'semantic-ui-react'


interface IProps {
    Commande: ICommande;
    modalButton: any;
    setModalButton: Function;
    setModalOpen: Function;
}

const FactureAccompteModal: React.FC<IProps> = ({ Commande, modalButton, setModalButton, setModalOpen }) => {

    const baseRepo = useContext(BaseStoreContext);
    const { create } = baseRepo.factureStore;

    const [montant, setMontant] = useState<number>(5);
    const [percent, setPercent] = useState(true);
    const [validateMsg, setValidateMsg] = useState("");
    const [result, setResult] = useState<number>(Number(((montant / 100) * Commande.amountWithTaxes).toFixed(2)));
    const [resultHT, setResultHT] = useState<number>(Number(((montant / 100) * Commande.amountWithTaxes + Commande.deliveryCosts).toFixed(2)));

    const [check, setChek] = useState(false);

    useEffect(() => {

        if (modalButton == true) {
            setChek(true);
        }

    }, [modalButton])


    const handleFactureAcompte = () => {
        let ListeInvoiceLine: IInvoiceLine[] = [];
        let lab = 'Acompte pour la commande ' + Commande.reference;
        if (percent)
            lab = 'Acompte de ' + montant + ' % pour la commande ' + Commande.reference;

        let newLine: IInvoiceLine = {
            label: lab,
            libelle: lab,
            customLabel: lab,
            totalPrice: resultHT,
            totalPriceWithTaxes: result
        }
        ListeInvoiceLine.push(newLine);

        let newFactureAcompte = {
            idUser: Commande.companyId,
            invoiceType: 'Facture_Acompte',
            invoiceLines: ListeInvoiceLine,
            orderRefs: Commande.reference
        };
        create(newFactureAcompte);
        setModalButton(false);
        setModalOpen(false);
    }

    const handleChangeMontant = (e: any) => {
        setMontant(e.target.value);
        if (!percent)
            setResult(e.target.value);
    }


    const handleInitial = () => {
        setValidateMsg("");
        if (percent) {
            setMontant(1);
            setResult(1);
            setResultHT(0);
        } else {
            setMontant(5);
            const result = ((5 / 100) * Commande.amountWithTaxes).toFixed(2);
            setResult(Number(result));
            const resultHT = ((5 / 100) * (Commande.amountWithoutTaxes + Commande.deliveryCosts)).toFixed(2);
            setResultHT(Number(resultHT));
        }
        setPercent(!percent);
    }

    const handleCheckMontant = (e: any) => {
        let val = e.target.value;
        if (percent) {
            if (val < 5) {
                setValidateMsg("le % doit etre supérieur ou égal à 5 %");
                setMontant(5);
                let res = Number(((5 / 100) * Commande.amountWithTaxes).toFixed(2));
                let resHT = Number(((5 / 100) * (Commande.amountWithoutTaxes + Commande.deliveryCosts)).toFixed(2));
                setResult(res);
                setResultHT(resHT);
            } else if (val > 50) {
                setValidateMsg("le % doit etre inférieur ou égal à 50 %");
                setMontant(50);
                let res = Number(((50 / 100) * Commande.amountWithTaxes).toFixed(2));
                let resHT = Number(((50 / 100) * (Commande.amountWithoutTaxes + Commande.deliveryCosts)).toFixed(2));
                setResult(res);
                setResultHT(resHT);
            } else {
                setValidateMsg("");
                setMontant(val);
                let res = Number(((val / 100) * Commande.amountWithTaxes).toFixed(2));
                let resHT = Number(((val / 100) * (Commande.amountWithoutTaxes + Commande.deliveryCosts)).toFixed(2));
                setResult(res);
                setResultHT(resHT);
            }
        } else {
            if (val >= Commande.amountWithTaxes) {
                setValidateMsg("le montant Acompte doit etre inférieur au montant TTC de la commande");
                setMontant(Commande.amountWithTaxes / 2);
                setResult(Commande.amountWithTaxes / 2);
                setResultHT(0);
            } else if (val <= 0) {
                setValidateMsg("le montant Acompte ne doit pas etre inférieur ou égal à 0");
                setMontant(1);
                setResult(1);
                setResultHT(0);
            } else {
                setValidateMsg("");
                setResult(val);
                setResultHT(0);
            }
        }

    }

    return (
        <>
            <Container textAlign='center'>
                <Grid style={{ margin: 0, padding: 0 }}>
                    <Grid.Row columns={3} style={{padding:0}}>

                        <Grid.Column style={{padding:0, margin:0, float:"Left", display:"block"}}>

                    <h4>Commande : {Commande.id}</h4>
                    <b>Montant HT<span> {Commande.amountWithoutTaxes + Commande.deliveryCosts} € </span></b><br/>
                        <b>Montant TTC<span> {Commande.amountWithTaxes} € </span></b>
                        </Grid.Column>

                    <Grid.Column >
                        <Reveal animated='move'>
                            <Reveal.Content visible style={{ width: '250px' }}>
                                <Button
                                    size='small'
                                    fluid
                                    content={(percent) ? '% Value ' : 'Montant'}
                                />
                            </Reveal.Content>
                            <Reveal.Content hidden>
                                <Button
                                    size='small'
                                    fluid
                                    content={(percent) ? 'Montant' : '% Value '}
                                    onClick={() => handleInitial()}
                                />
                            </Reveal.Content>
                        </Reveal>
                    </Grid.Column>
                    <div className="field">
                        <input
                            style={{ width: 150, height: 33, textAlign: "center", marginTop: 15 }}
                            className="field"
                            type="number"
                            value={montant}
                            onChange={(e) => handleChangeMontant(e)}
                            onBlur={(e) => handleCheckMontant(e)}
                        />
                    </div>
                    &nbsp;
                    <div style={{ display: 'contents' }}>
                        <label style={{ color: 'red', marginTop: 20 }}> {validateMsg} </label>
                        </div>
                        </Grid.Row>
                </Grid>


                <Table loading={false} celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell> Acompte Infos </Table.HeaderCell>
                            <Table.HeaderCell>Valeur</Table.HeaderCell>
                            <Table.HeaderCell>Montant  HT </Table.HeaderCell>
                            <Table.HeaderCell>Montant  TTC  </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Cell> Acompte {(percent) ? "de " + montant + " %" : " €"} pour la commande {Commande.reference} </Table.Cell>
                        <Table.Cell> {montant} {(percent) ? " %" : " €"} </Table.Cell>
                        <Table.Cell> {resultHT} € </Table.Cell>
                        <Table.Cell> {result} € </Table.Cell>
                    </Table.Body>
                </Table>

            </Container>

            <Modal
                open={check}
                size='tiny'
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >
                <Modal.Header icon='add' style={{ marginLeft: 20 }} >
                    Verification Facture Acompte pour la commande {Commande.reference}
                </Modal.Header>
                <hr />
                <Modal.Content>
                    Montant TTC : {result}€
                </Modal.Content>
                <Modal.Actions>
                    <Button.Group fluid>
                        <Button onClick={() => setChek(false)}>
                            <Icon name='remove' /> Annuler
                        </Button>
                        <Button.Or text='Ou' />
                        <Button
                            color='green'
                            onClick={(e) => {
                                handleFactureAcompte();
                                setChek(false);
                            }}>
                            <Icon name='checkmark' /> Confirmer
                        </Button>
                    </Button.Group>
                </Modal.Actions>
            </Modal>
        </>
    );
}
export default observer(FactureAccompteModal);