import { StylesConfig } from "react-select";
import { IArticleListByFranchiseur } from "../../models/IClient";

export const columnStyle = {
    marginBottom: 0,
    paddingBottom: 0,
    paddingTop: 0
}

export const numberFormat = new Intl.NumberFormat('FR-fr', {
    style: 'currency',
    currency: 'EUR',
    useGrouping: true,
});


const dot = (isPack: boolean) => ({
    alignItems: 'center',
    display: 'flex',

    ':after': isPack ? {
        color: "blue",
        content: '"(Pack)"',
        marginLeft: 8,

    } : {},
});


const style = (isPack: boolean, belongToOpeningPack: boolean, inStock: boolean, qtt: number) => ({
    alignItems: 'center',
    display: 'block',
    borderBottom: '1px solid #eee',
    ':before':
        isPack ? {
            color: "blue",
            content: '"(Pack)"',
            marginRight: 8,

        } : {},
    ':after':
        inStock ? {
            display: 'flex',
            color: qtt == 0 ? "red" : "green",
            content: qtt == 0 ? '"Hors stock"' : isPack ? `'Quantité Disponible: ${qtt} Pack'` : `'Quantité Disponible: ${qtt}'`,
            marginLeft: 8,

        } : {},
});


export const colourStyles: StylesConfig<IArticleListByFranchiseur> = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isSelected
                ? "#ccc"
                : isFocused
                    ? "#eee"
                    : undefined,
            color: isSelected
                ? "black"
                : isFocused
                    ? "black"
                    : undefined,
            ...style(data.isPack, data.belongToOpeningPack, data.inStock, data.quantity)

        };
    },
    input: (styles) => ({ ...styles, ...dot(true) }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.isPack) }),
};
export const getMonth = (month: string) => {
    switch (month) {
        case '1':
            return "Janvier";
        case '2':
            return "Février";
        case '3':
            return "Mars";
        case '4':
            return "Avril";
        case '5':
            return "Mai";
        case '6':
            return "Juin";
        case '7':
            return "Juillet";
        case '8':
            return "Aout";
        case '9':
            return "Septembre";
        case '10':
            return "Octobre";
        case '11':
            return "Novembre";
        case '12':
            return "Décembre";
        default:
            return '';
    }
}