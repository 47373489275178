import React, { useContext, useEffect, useState } from 'react';
import { GridColumn, Grid, Loader } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import InfiniteScroll from 'react-infinite-scroller';
import { BaseStoreContext } from '../../app/store/baseStore';
import ListItemPlaceholder from '../../app/common/form/ListItemPlaceholder';
import ListCommandeExpedier from './ListCommandeExpedier';
import Breadcrumbs from '../dashboard/breadcrumbs';
import ExpeditionFilter from './ExpeditionFilter';

const ExpeditionCommandeDashboard: React.FC = () => {

    const baseStore = useContext(BaseStoreContext);
    const { loadExpeditionList, loadingInitialExpedition, page, totalPages, setPage } = baseStore.expeditionStore;
    const [loadingNext, setLoadingNext] = useState(false);

    const handleGetNext = () => {
        setLoadingNext(true);
        setPage(page + 1);
        loadExpeditionList().then(() => setLoadingNext(false));
    }

    useEffect(() => {
        loadExpeditionList();
    }, []);
    const [state, setState] = useState({ activeIndex: -1 });
    const handleClick = (e: any, titleProps: any) => {
        const { index } = titleProps
        const { activeIndex } = state
        const newIndex = activeIndex === index ? -1 : index
        setState({ activeIndex: newIndex })
    }
    const { activeIndex } = state
    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Expédition", root: "" },
                    { key: 1, name: "Expédition de commande", root: "/expeditionDashboard" },
                ]}
            />

            <Grid>
                <Grid.Row style={{ padding: 0, margin: "5px 20px 0 20px" }}>
                    <ExpeditionFilter />
                </Grid.Row>
                <GridColumn width={16}>
                    {loadingInitialExpedition && page === 0 ? <ListItemPlaceholder /> : (
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={handleGetNext}
                            hasMore={!loadingNext && page + 1 < totalPages}
                            initialLoad={false}
                        >
                            <ListCommandeExpedier />
                        </InfiniteScroll>
                    )}
                </GridColumn>
                <Grid.Column width={16}>
                    <Loader active={loadingNext} />
                </Grid.Column>
            </Grid>
        </div>
    );
}
export default observer(ExpeditionCommandeDashboard);