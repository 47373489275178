import React, { useContext, useEffect, useState } from 'react';
import { Form, Item, Table, Button, Header, Progress } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import Loading from '../../../app/layout/Loading';
import { observer } from 'mobx-react-lite';
import TextInput from '../../../app/common/form/inputText';
import AccountForm from './AddAccountModal';
import DeleteAccountModal from './DeleteAccountModal';
import Breadcrumbs from '../../dashboard/breadcrumbs';

const ListAccount: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { loadAccounts, loadingInitialAccount, edit, accounts, loadingAccountAction, progress, submitting } = baseStore.accountStore;
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    const [editTarget, setEditTarget] = useState<string | undefined>(undefined);
    const [editForm, setEditForm] = useState(false);
    const [name, setName] = useState<string | undefined>(undefined);
    const [type, setType] = useState<string | undefined>(undefined);
    const { openModal } = baseStore.modalStore;

    const handleFinalFormSubmit = () => {
        let newaccount = {
            name: name,
            type: type,
            id: editTarget,

        };
        edit(newaccount);
        setEditForm(false)
    }
    useEffect(() => {
        loadAccounts();
    }, []);

    if (loadingInitialAccount) return <Loading content="Chargement des comptes ..." />

    return (
        <div>
            <Breadcrumbs
                items={[
                    { key:0,name: "Paramétres", root: "" },
                    { key:1,name: "Compte bancaire", root: "/dashboardCompteBancaire" },
                ]}
            />
            <div style={{ margin: '2rem 1rem' }}>
            <Item.Group>
                <Item>
                    <Item.Content>
                        <Header as='h2'>{'Compte'}</Header>
                        <Button
                            floated="right"
                            content={baseStore.accountStore.addMode ? 'Annuler' : 'Ajouter compte'}
                            color="orange"
                            icon='arrow alternate circle left outline'
                            basic
                            onClick={() => { baseStore.accountStore.changeButtonStateAdd(!baseStore.accountStore.addMode) }}
                        />
                    </Item.Content>
                </Item>
            </Item.Group>
            {baseStore.accountStore.addMode &&
                <div>
                    <AccountForm />
                    <br />
                </div>
            }
            {submitting &&
                <Progress color='orange' percent={progress} size='tiny' />
            }
            <Table loading={false} celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Nom de compte</Table.HeaderCell>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {accounts && accounts.map((account: any) => (
                        <Table.Row key={account.accountId}>
                            {editForm && editTarget === account.id ? (
                                <>
                                    <Table.Cell selectable>
                                        <Form.Input name='name' component={TextInput} placeholder='nom de compte' defaultValue={account.name} style={{ width: '100%', padding: '1%' }} onChange={event => setName(event.target.value)} />
                                    </Table.Cell>
                                    <Table.Cell selectable>
                                        <Form.Input name='type' component={TextInput} placeholder='type' defaultValue={account.type} style={{ width: '100%', padding: '1%' }} onChange={event => setType(event.target.value)} />
                                    </Table.Cell>
                                    <Table.Cell selectable>
                                        <Button.Group fluid widths={2}>
                                            <Button
                                                type='button'
                                                onClick={() => {
                                                    setEditForm(false);
                                                }}>
                                                Annuler
                                            </Button>
                                            <Button.Or />
                                            <Button
                                                name={account.id}
                                                positive
                                                type='submit'
                                                onClick={(e) => {
                                                    handleFinalFormSubmit()
                                                    setEditTarget(e.currentTarget.name)
                                                }}
                                            >Confirmer</Button>
                                        </Button.Group>
                                    </Table.Cell>
                                </>
                            ) : (
                                <>
                                    <Table.Cell>{account.name}</Table.Cell>
                                    <Table.Cell>{account.type}</Table.Cell>
                                    <Table.Cell >
                                        <Button.Group fluid widths={2}>
                                            <Button
                                                onClick={(e) => {
                                                    setEditForm(true);
                                                    setEditTarget(e.currentTarget.name)
                                                    setName(account.name)
                                                    setType(account.type)
                                                }}
                                                color="blue"
                                                icon='edit outline'
                                                style={{ marginRight: '3%' }}
                                                name={account.id}
                                            />
                                            <Button
                                                name={account.id}
                                                basic
                                                color='red'
                                                icon='trash'
                                                onClick={(e) => {
                                                    openModal(<DeleteAccountModal account={account} />)
                                                    setDeleteTarget(e.currentTarget.name)
                                                }}
                                                loading={loadingAccountAction && deleteTarget === account.id}
                                            />
                                        </Button.Group>
                                    </Table.Cell>
                                </>
                            )}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
        </div>
    );
}
export default observer(ListAccount);
