import { useContext } from 'react';
import { Grid, Header, Tab } from 'semantic-ui-react';
import FormGridColumn from '../../../app/common/form/FormGridColumn';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { observer } from 'mobx-react-lite';

const FactureInfo = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { facture} = baseRepo.factureFournisseurStore;

    return (
        <Tab.Pane >
            <Grid>
                <Grid.Column width={16} style={{ paddingBottom: 20 }}>
                    <Header floated='left' icon='user' content={`Information de Fournisseur: ${facture!.company ? facture!.company!.agencyName : facture!.fournisseur!.codeFournisseur}`} />
                </Grid.Column>
                <Grid.Column width={16}>
                    <Grid className="ui stackable">
                        <Grid.Row style={{ padding: 0, margin: 0 }}>
                            <FormGridColumn header="Téléphone" content={facture!.company ? facture!.company?.invoicePhone : facture!.fournisseur?.telephone} />
                            <FormGridColumn header="Téléphone Mobile" content={facture!.company ? facture!.company?.invoiceFax : facture!.fournisseur?.telephone} />
                            <FormGridColumn header="Email" content={facture!.company ? facture!.company?.invoiceEmails : facture!.fournisseur?.email} />
                            <FormGridColumn header="Adresse" content={facture!.company ? facture!.company?.invoiceAddress1 : facture!.fournisseur?.adresse} />
                            <FormGridColumn header="Ville" content={facture!.company ? facture!.company?.invoiceCity : facture!.fournisseur?.ville} />
                            <FormGridColumn header="Code Postal" content={facture!.company ? facture!.company?.invoicePostalCode : facture!.fournisseur?.codePostal} />
                        </Grid.Row>
                    </Grid>
                </Grid.Column>

            </Grid>
        </Tab.Pane >
    );
}
export default observer(FactureInfo);