import { useContext, useEffect } from 'react';
import { Tab, Header, List, Button, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import NotFound from '../../../app/layout/NoFound';
import FournisseurEditForm from './FournisseurEditForm';
import FormGridColumn from '../../../app/common/form/FormGridColumn';

const FournisseurDescription = () => {

    const baseRepo = useContext(BaseStoreContext);
    const { fournisseur, editFournisseur } = baseRepo.fournisseurStore;
    useEffect(() => {
        baseRepo.fournisseurStore.changeButtonState(false)
    }, [])
    if (!fournisseur) {
        return <NotFound />
    }
    else {
        return (
            <Tab.Pane>
                <Grid>
                    <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                        <Header floated='left' icon='qrcode' content={`Code:${fournisseur!.codeFournisseur}`} />
                        <Button
                            onClick={() => { baseRepo.fournisseurStore.changeButtonState(!baseRepo.fournisseurStore.editFournisseurMode) }}
                            floated='right'
                            color='orange'
                            content={baseRepo.fournisseurStore.editFournisseurMode ? 'Annuler' : 'Modifier Fournisseur'}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        {baseRepo.fournisseurStore.editFournisseurMode ? (
                            <FournisseurEditForm editFournisseur={editFournisseur} fournisseur={fournisseur!} />
                        ) : (
                            <Grid className="ui stackable">
                                <Grid.Row style={{ padding: 0, margin: 0 }} >
                                    <FormGridColumn header="Société" content={fournisseur.fabricant} />
                                    <FormGridColumn header="Nom contact" content={fournisseur!.nomContact} />
                                    <FormGridColumn header="Email" content={fournisseur!.email} />
                                    <FormGridColumn header="Téléphone" content={fournisseur!.telephone} />
                                    <FormGridColumn header="Pays" content={fournisseur.country} />
                                    <FormGridColumn header="Ville" content={fournisseur!.ville} />
                                    <FormGridColumn header="Adresse" content={fournisseur.adresse} />
                                    <FormGridColumn header="Complement d'adresse" content={fournisseur.adresseComplement} />
                                    <FormGridColumn header="Code Postal" content={fournisseur.codePostal} />
                                    <FormGridColumn header="TVA Intracommunautaire" content={fournisseur!.europeanTvaNumber} />
                                    <FormGridColumn header="Siren" content={fournisseur!.siren} />
                                    <FormGridColumn header="Siret" content={fournisseur!.siret} />
                                    <FormGridColumn header="Cnuf" content={fournisseur!.cnuf} />
                                    <FormGridColumn header="Naf" content={fournisseur!.naf} />
                                    <FormGridColumn header="Site web" content={fournisseur!.siteWeb} />
                                   
                                </Grid.Row>
                            </Grid>
                        )}
                    </Grid.Column>
                </Grid>
            </Tab.Pane>
        );
    }
}
export default observer(FournisseurDescription);