import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useContext } from "react";
import { Form as FinalForm } from 'react-final-form';
import { Button, Form, Grid, Segment } from "semantic-ui-react";
import { BaseStoreContext } from "../../app/store/baseStore";
import Breadcrumbs from '../dashboard/breadcrumbs';
import AsyncSelect from "react-select/async";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';

const DashboardCommission: React.FC = () => {
    const { RangePicker } = DatePicker;
    const baseStore = useContext(BaseStoreContext);
    const [codeFranchiseur, setCodeFranchiseur] = useState<string | undefined>(undefined);
    const [companyName, setCompanyName] = useState<string>("");
    const { exporting, exportCommission } = baseStore.franchiseurStore;
    const [searchDateCommande, setSearchDateCommande] = useState<any>('');
    const [searchDateCommandeTo, setSearchDateCommandeTo] = useState<any>('');

    const rangePresets: {
        label: string;
        value: [Dayjs, Dayjs];
    }[] = [
            { label: '7 derniers jours', value: [dayjs().add(-7, 'd'), dayjs()] },
            { label: '14 derniers jours', value: [dayjs().add(-14, 'd'), dayjs()] },
            { label: '30 derniers jours', value: [dayjs().add(-30, 'd'), dayjs()] },
            { label: '90 derniers jours', value: [dayjs().add(-90, 'd'), dayjs()] },
        ];

    const onRangeChange = (dates: null | (Dayjs | null)[]) => {
        if (dates) {
            setSearchDateCommande(dates[0]?.toISOString());
            setSearchDateCommandeTo(dates[1]?.toISOString());
        }
        else {
            setSearchDateCommande('');
            setSearchDateCommandeTo('');
        }
    };
    const handleFinalFormSubmit = () => {
        var company = {
            companyId: parseInt(codeFranchiseur!),
            companyName: companyName,
            startDate: searchDateCommande,
            endDate: searchDateCommandeTo
        }
        exportCommission(company)
    }

    return (
        <>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Comptabilité", root: "" },
                    { key: 1, name: "Export commission", root: "/commission" },
                ]}
            />
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <FinalForm
                            onSubmit={handleFinalFormSubmit}

                            render={({ handleSubmit }) => (
                                <Form onSubmit={handleSubmit} error >
                                    <Segment>
                                        <Grid columns={16}>
                                            <Grid.Row style={{ paddingLeft: 50 }}>
                                                <span style={{ fontSize: 18, fontWeight: 700, color: "#db2828" }}>Veillez sélectionner le réseau</span>
                                            </Grid.Row >

                                            <Grid.Row style={{ textAlign: "center" }}  >
                                                <Grid.Column width={16} style={{ textAlign: "center", display: "flex", justifyContent: "center" }} className="ui grid stackable">
                                                    <div style={{ width: 300, marginRight: 40 }}>
                                                        <AsyncSelect
                                                            name="form-field-name"
                                                            noOptionsMessage={() => "Code introuvable"}
                                                            cacheOptions
                                                            defaultOptions
                                                            placeholder="Réseau"
                                                            getOptionLabel={e => e!.text}
                                                            getOptionValue={e => e!.value}
                                                            loadOptions={baseStore.franchiseurStore.loadFranchiseurOptions}
                                                            onChange={e => { setCodeFranchiseur(e!.key); setCompanyName(e!.text) }}
                                                        />
                                                    </div>
                                                    <RangePicker
                                                        presets={rangePresets}
                                                        onChange={onRangeChange}
                                                        format={'DD/MM/YYYY'}
                                                        placeholder={["Début", "Fin"]}
                                                        style={{ width: '30%', height: '39.201px' }}
                                                    />
                                                </Grid.Column>

                                                {exporting &&
                                                    <Grid.Column width={16}>
                                                        <span style={{ color: "red", fontWeight: 700 }}>Le fichier est en cours de création. Merci pour votre attente !!</span>
                                                    </Grid.Column>
                                                }
                                                <Grid.Column width={16}>
                                                    <Button type="submit" basic active color="green" content="Exporter" loading={exporting} disabled={codeFranchiseur == undefined || searchDateCommande == '' || searchDateCommandeTo == ''} />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Segment>

                                </Form>
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
}
export default observer(DashboardCommission);