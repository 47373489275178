import React, { useContext, useEffect } from 'react';
import ClientHeader from './ClientHeader';
import ClientContent from './ClientContent';
import { Button, Grid, Header } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Loading from '../../../app/layout/Loading';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import Breadcrumbs from '../../dashboard/breadcrumbs';

interface RouteParams {
    id: string
}

interface IProps extends RouteComponentProps<RouteParams> { }

const ClientPage: React.FC<IProps> = ({ match }) => {

    const baseStore = useContext(BaseStoreContext);
    const { loadClient, setActiveTab, loadingClient, client, disable, enable, deletingClient, generateAccountClient, changeType, transforming } = baseStore.clientStore;
    const { isLoggedIn } = baseStore.userStore;
    useEffect(() => {
        loadClient(parseInt(match.params.id));
    }, [])

    if (loadingClient) return <Loading content="Chargement du franchisé ..." />

    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Tiers", root: "" },
                    { key: 1, name: "Réseaux", root: "/franchiseurDashboard" },
                    { key: 2, name: "Liste franchisés", root: `/clients/${client?.franchisorId}` },
                    { key: 3, name: "" + client?.companyName, root: `/detailClient/${client?.id}` },
                ]}
            />
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Grid style={{ marginTop: 2 }}>
                            <Grid.Column width={16}>
                                <Header floated='left' content={'Mettre à jour le franchisé '} />
                                <Button
                                    floated="right"
                                    content="Retour vers franchisé"
                                    color="orange"
                                    icon='arrow alternate circle left outline'
                                    basic
                                    as={Link}
                                    to={`/clients/${client?.franchisorId}`}
                                />
                            </Grid.Column>
                            <Grid.Column width={16}>
                                <ClientHeader client={client!} disable={disable} enable={enable} isLoggedIn={isLoggedIn} changeType={changeType} transforming={transforming} loading={deletingClient} generateAccountClient={generateAccountClient} />
                                <ClientContent setActiveTab={setActiveTab} client={client!} loading={deletingClient} />
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}

export default observer(ClientPage);