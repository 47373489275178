import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Tab, Button, Grid, Checkbox, Icon, Message, Table, Item, ItemContent } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import { numberFormat, getMonth } from '../../../app/common/options/IStyle';
import _ from 'lodash';
interface IProps {
    setModalOpen: Dispatch<SetStateAction<boolean>>;
}
const CloturerByMonth: React.FC<IProps> = ({ setModalOpen }) => {
    const baseStore = useContext(BaseStoreContext)
    const { loadingFactureNotClosed, loadListesFacturesNotClosed, invoiceNotClosed, cloturerFacture, cloturable, cloturableMail } = baseStore.factureStore;
    useEffect(() => {
        loadListesFacturesNotClosed();
    }, [])

    return (
        <Tab.Pane>
            <Grid>

                <Grid.Row >
                    {loadingFactureNotClosed ?
                        <div style={{ margin: '90px 300px 0 300px' }} className="ui segment">
                            <div className="ui active inverted dimmer">
                                <div className="ui medium text loader">Chargement ...</div>
                            </div>
                            <p></p>
                            <p></p>
                        </div>
                        : (
                            <div>

                                {invoiceNotClosed.length != 0 ?
                                    (
                                        <>
                                            {invoiceNotClosed && invoiceNotClosed.map((item, key) => (



                                                <Grid>
                                                    <Grid.Column>
                                                        <Item style={{ borderRadius: 5, border: "1px solid grey" ,width:'630px'}}>
                                                            <ItemContent style={{ padding: 10, width: '100%' }}>
                                                                <div style={{ display: 'inline-block', width: '100%' }}>
                                                                <span >{getMonth(item.month.toString())} - {item.year}</span>

                                                                    <Button type='submit' basic color='orange' style={{ float: 'right' }} disabled={cloturable ? cloturable : cloturableMail} loading={cloturable}
                                                                        onClick={(e) => {
                                                                            let obj: any = {
                                                                                year: item.year,
                                                                                month: item.month,
                                                                                ids: item.ids,
                                                                            type: "cloturer"
                                                                        };
                                                                        cloturerFacture(obj);
                                                                    }}  >Clôturer
                                                                    </Button>
                                                                    {/*<Button positive type='submit' basic disabled={cloturable ? cloturable : cloturableMail} loading={cloturableMail} style={{ float: 'right' }}*/}
                                                                    {/*onClick={(e) => {*/}
                                                                    {/*    let obj: any = {*/}
                                                                    {/*        year: item.year,*/}
                                                                    {/*        month: item.month,*/}
                                                                    {/*        ids: item.ids,*/}
                                                                    {/*        type: "cloturerEnvoyer"*/}
                                                                    {/*    };*/}
                                                                    {/*    cloturerFacture(obj);*/}
                                                                    {/*}}  >Clôturer et envoyer un mail*/}
                                                                    {/*</Button>*/}
                                                                    </div>

                                                            </ItemContent>

                                                        </Item>
                                                    </Grid.Column>

                                                </Grid>
                                            ))
                                            }



                                        </>
                                    ) : (
                                        <Grid style={{ marginTop: 20, justifyContent: 'center' }}>
                                            <Message
                                                error
                                                list={[
                                                    "Aucun Facture pour le moment",
                                                   
                                                ]}
                                            />
                                        </Grid>
                                    )}
                            </div>
                        )}
                </Grid.Row>

            </Grid>
        </Tab.Pane>
    );
};
export default observer(CloturerByMonth);
