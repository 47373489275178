import React, { Fragment, useContext, useEffect } from 'react';
import './styles.css';
import NavBar from '../../features/nav/navBar';
import LeftNavBar from '../../features/nav/navBarLeft';
import ArticleDashboard from '../../features/article/dashboard/ArticleDashboard';
import { Route, Switch, withRouter, RouteComponentProps } from 'react-router-dom';
import { BaseStoreContext } from '../store/baseStore';
import { observer } from 'mobx-react-lite';
import ModalContainer from '../common/modal/ModalContainer';
import Loading from './Loading';
import RegisterFrom from '../../features/user/Register';
import HomePage from '../../features/home/homePage';
import ClientDashboard from '../../features/clients/clientDashboard/clientDashboard';
import ProfilPage from '../../features/profil/ProfilPage';
import { ToastContainer } from 'react-toastify';
import { Container } from 'semantic-ui-react';
import NotFound from './NoFound';
import PrivateRoute from './PivateRoute';
import Footer from './footer'
import ClientPage from '../../features/clients/clientDetails/ClientPage';
import ServerError from '../../features/error/ServerError';
import ArticlePage from '../../features/article/articleDetails/ArticlePage';
import AddClientPage from '../../features/clients/addClient/AddClientPage';
import FranchiseurDashboard from '../../features/franchiseur/franchiseurDashboard/FranchiseurDashboard';
import FranchiseurPage from '../../features/franchiseur/DetailFranchiseur/FranchiseurPage';
import AddFranchiseurPage from '../../features/franchiseur/addFranchiseur/AddFranchiseurPage';
import ListModePaiement from '../../features/modePaiement/dashboard/listModePaiement';
import ListModeReglement from '../../features/modeReglement/dashboard/listModeReglement';
import ListFormeJuridique from '../../features/formeJuridique/dashboard/listFormeJuridique';
import AddFournisseurPage from '../../features/fournisseur/addFournisseur/AddFournisseurPage';
import FournisseurDashbord from '../../features/fournisseur/dashbordFournisseur/FournisseurDashbord';
import FournisseurPage from '../../features/fournisseur/fournisseurDetail/FournisseurPage';
import listFamille from '../../features/famille/dashboard/listFamille';
import listSousFamille from '../../features/sousfamille/dashboard/listSousFamille';
import AddArticlePage from '../../features/article/addArticles/AddArticlePage';
import AddCommandePage from '../../features/commande/addCommande/AddCommandePage';
import CommandePage from '../../features/commande/detailCommande/CommandePage';
import CommandePageEnAttente from '../../features/commande/detailCommande/CommandeEnAttentePage';
import CommandeDashboard from '../../features/commande/commandeDashboard/CommandesDashboard';
import NouvellesCommandes from '../../features/commande/tableauCommandes/NouvellesCommandes';
import DevisDashboard from '../../features/devis/devisDashboard/DevisDashboard';
import AddDevisPage from '../../features/devis/addDevis/AddDevisPage';
import DevisPage from '../../features/devis/detailDevis/DevisPage';
import ListTva from '../../features/tva/tvaDashboard/ListTva';
import ListEntrepot from '../../features/entrepot/entrepotDashboard/ListEntrepot';
import ParametreArticlePage from '../../features/ParametreArticle/dashboard/ParametreArticlePage';
import mouvementStockDashboard from '../../features/mouvementStock/MouvementStockDashboard';
import listAccount from '../../features/Account/dashboard/listAccount';
import AddCommandeFournisseurPage from '../../features/commandeFournisseur/addCommandeFournisseur/AddCommandeFournisseurPage';
import AddFactureFournisseurPage from '../../features/factureFournisseur/addFacture/AddFacturePage';
import CommandeFournisseurDashboard from '../../features/commandeFournisseur/commandeFournisseurDashboard/CommandeFournisseurDashboard';
import FactureFournisseurDashboard from '../../features/factureFournisseur/factureDashboard/FactureDashboard';
import CommandeFournisseurPage from '../../features/commandeFournisseur/detailCommandeFournisseur/CommandeFournisseurPage';
import FactureFournisseurPage from '../../features/factureFournisseur/detailFacture/FacturePage';
import DashboardContent from '../../features/dashboard/DashboardContent';
import preparationDashboard from '../../features/preparationCommande/PreparationDashboard';
import listPreparationLines from '../../features/preparationCommande/ListPrparationLines';
import ListTypeMarche from '../../features/typeMarche/dashboard/listTypeMarche';
import addClient from '../../features/clients/franchiseurForm/AddFranchiseur';
import FactureDashboard from '../../features/facture/factureDashboard/FactureDashboard';
import AddFacturePage from '../../features/facture/addFacture/AddFacturePage';
import FacturePage from '../../features/facture/detailFacture/FacturePage';
import ExpeditionCommandeDashboard from '../../features/expeditionCommade/ExpeditionCommandeDashboard';
import ListExpeditionLines from '../../features/expeditionCommade/ListExpeditionLines';
import ReceptionDashboard from '../../features/receptionCommande/ReceptionDashboard';
import DetailsReceptionPage from '../../features/receptionCommande/detailsReception/DetailsReceptionPage';
import RegisterSuccess from '../../features/user/RegisterSuccess';
import VerifyEmail from '../../features/user/VerifyEmail';
import AlertStockDashboard from '../../features/alertStock/AlertStockDashboard';
import AddFranchisePage from '../../features/clients/addClient/AddFranchisePage';
import ExportList from '../../features/Export Facture/ExportList';
import Setting from './setting';
import AchatGroupeeDashboard from '../../features/achatGroupees/Dashboard/AchatGroupeeDashboard';
import AddAchatGPage from '../../features/achatGroupees/addAchatGroupees/AddAchatGPage';
import ListeUsers from '../../features/Admin/ListeUsers';
import ListeRoles from '../../features/Admin/ListeRoles';
import ListePermissions from '../../features/Admin/ListePermissions';
import ReglementDashboard from '../../features/Reglement/dashboard/ReglementDashboard';
import AddArticleServicePage from '../../features/article/addArticles/AddArticleServicePage';
import ArticleServicePage from '../../features/article/articleDetails/ArticleServicePage';
import ArticleServiceDashboard from '../../features/article/dashboard/ArticleServiceDashboard';
import BlAFacturerDashboard from '../../features/expeditionCommade/BlAFacturerDashboard';
import BrAFacturerDashboard from '../../features/expeditionCommade/retour/BrAFacturerDashboard';
import ReliquatsPage from '../../features/Reliquats/ReliquatsPage';
import ExportComptables from '../../features/export Comptables/DashboardComptables';
import SoldesDashboard from '../../features/ExportSolde/SoldesDashboard';
import CommandeAttenteDashboard from '../../features/commande/commandeDashboard/CommandeAttenteDashboard';
import ExportDataDashboard from '../../features/ExportDataClient/ExportDataDashboard';
import InventoryDashboard from '../../features/Inventory/InventoryDashboard';
import InventoryProductDashboard from '../../features/Inventory/InventoryProductDashboard';
import dashboardCommission from '../../features/ExportCommission/dashboardCommission';
import requestDashboard from '../../features/request/requestDashboard';

const App: React.FC<RouteComponentProps> = ({ location }) => {
    const _baseStore = useContext(BaseStoreContext);
    const { setAppLoaded, appLoaded, token } = _baseStore.commonStore;
    const { getUser } = _baseStore.userStore;

    useEffect(() => {
        if (token)
            getUser().finally(() => setAppLoaded());
        else {
            setAppLoaded();
        }
    }, [getUser, setAppLoaded, token])

    if (!appLoaded) return <Loading content='Chargement ...' />

    return (
        <Fragment>
            <ModalContainer />
            <ToastContainer  position='bottom-left'  />
            <Route exact path='/' component={HomePage} />
            <Route
                path={'/(.+)'}
                render={() => (
                    <div className="content-wrapper">
                        <Fragment>
                            <NavBar />
                            <LeftNavBar />
                            <Container style={{ padding: '30px 0 20px 0', width: "100%" }}>
                                <Switch>
                                    <PrivateRoute exact path='/franchiseurDashboard' component={FranchiseurDashboard} />
                                    <PrivateRoute exact path='/commandeDashboard' component={CommandeDashboard} />
                                    <PrivateRoute exact path='/devisDashboard' component={DevisDashboard} />
                                    <PrivateRoute exact path='/commandeFournisseurDashboard' component={CommandeFournisseurDashboard} />
                                    <PrivateRoute exact path='/factureFournisseurDashboard' component={FactureFournisseurDashboard} />
                                    <PrivateRoute exact path='/nouvellesCommandes' component={NouvellesCommandes} />
                                    <PrivateRoute exact path='/fournisseurDashboard' component={FournisseurDashbord} />
                                    <PrivateRoute path='/clients/:id' component={ClientDashboard} />
                                    <PrivateRoute path='/detailClient/:id' component={ClientPage} />
                                    <PrivateRoute path='/detailFranchiseur/:id' component={FranchiseurPage} />
                                    <PrivateRoute path='/detailFournisseur/:id' component={FournisseurPage} />
                                    <PrivateRoute path='/detailArticle/:id' component={ArticlePage} />
                                    <PrivateRoute path='/detailCommandeFournisseur/:id' component={CommandeFournisseurPage} />
                                    <PrivateRoute path='/detailFactureFournisseur/:id' component={FactureFournisseurPage} />
                                    <PrivateRoute path='/detailCommande/:id' component={CommandePage} />
                                    <PrivateRoute path='/detailCommandeEnAttente/:id' component={CommandePageEnAttente} />
                                    <PrivateRoute path='/detailDevis/:id' component={DevisPage} />
                                    <PrivateRoute path='/addClient/:id' component={AddClientPage} />
                                    <PrivateRoute path='/addFranchiseur' component={AddFranchiseurPage} />
                                    <PrivateRoute path='/addArticles' component={AddArticlePage} />
                                    <PrivateRoute exact path={'/addCommande'} component={AddCommandePage} />
                                    <PrivateRoute exact path={'/addDevis'} component={AddDevisPage} />
                                    <PrivateRoute path={'/addCommandeFournisseur'} component={AddCommandeFournisseurPage} />
                                    <PrivateRoute path={'/addFactureFournisseur'} component={AddFactureFournisseurPage} />
                                    <PrivateRoute path='/addFournisseur' component={AddFournisseurPage} />
                                    <PrivateRoute exact path='/menuPage' component={DashboardContent} />
                                    <PrivateRoute exact path='/dashboard' component={ListModePaiement} />
                                    <PrivateRoute exact path='/dashboardReglement' component={ListModeReglement} />
                                    <PrivateRoute exact path='/dashboardFormeJuridique' component={ListFormeJuridique} />
                                    <PrivateRoute exact path='/dashboardFamille' component={listFamille} />
                                    <PrivateRoute exact path='/dashboardTva' component={ListTva} />
                                    <PrivateRoute exact path='/dashboardEntrepot' component={ListEntrepot} />
                                    <PrivateRoute exact path='/dashboardSousFamille/:id' component={listSousFamille} />
                                    <PrivateRoute path='/articles' component={ArticleDashboard} />
                                    <PrivateRoute path='/register' component={RegisterFrom} />
                                    <PrivateRoute path='/profil/:username' component={ProfilPage} />
                                    <PrivateRoute path='/server-error' component={ServerError} />
                                    <PrivateRoute path='/dashboardParametreArticle' component={ParametreArticlePage} />
                                    <PrivateRoute exact path='/mouvementStockDashboard' component={mouvementStockDashboard} />
                                    <PrivateRoute exact path='/alertStockDashboard' component={AlertStockDashboard} />
                                    <PrivateRoute path='/dashboardCompteBancaire' component={listAccount} />
                                    <PrivateRoute exact path='/preparationDashboard' component={preparationDashboard} />
                                    <PrivateRoute exact path='/detailPreparation/:id' component={listPreparationLines} />
                                    <PrivateRoute exact path='/createClient/:id' component={addClient} />
                                    <PrivateRoute exact path='/generateClient/:id/:requestId' component={addClient} />
                                    <PrivateRoute exact path='/typeMarche' component={ListTypeMarche} />
                                    <PrivateRoute exact path='/factureDashboard' component={FactureDashboard} />
                                    <PrivateRoute exact path='/addFacture' component={AddFacturePage} />
                                    <PrivateRoute path='/detailFacture/:id' component={FacturePage} />
                                    <PrivateRoute exact path='/expeditionDashboard' component={ExpeditionCommandeDashboard} />
                                    <PrivateRoute exact path='/detailExpedition/:id' component={ListExpeditionLines} />
                                    <PrivateRoute exact path='/user/registerSuccess' component={RegisterSuccess} />
                                    <PrivateRoute exact path='/user/verifyEmail' component={VerifyEmail} />
                                    <PrivateRoute exact path='/receptionDashboard' component={ReceptionDashboard} />
                                    <PrivateRoute exact path='/detailReception/:id' component={DetailsReceptionPage} />
                                    <PrivateRoute path='/addFranchise' component={AddFranchisePage} />
                                    <PrivateRoute exact path='/exportList' component={ExportList} />
                                    <PrivateRoute exact path='/settings' component={Setting} />
                                    <PrivateRoute exact path='/offreDashboard' component={AchatGroupeeDashboard} />
                                    <PrivateRoute exact path='/addAchatGroupee' component={AddAchatGPage} />
                                    <PrivateRoute exact path='/dashboardUsers' component={ListeUsers} />
                                    <PrivateRoute exact path='/allRoles' component={ListeRoles} />
                                    <PrivateRoute path='/listePremissions/:id' component={ListePermissions} />
                                    <PrivateRoute path='/reglement' component={ReglementDashboard} />
                                    <PrivateRoute path='/addArticleService' component={AddArticleServicePage} />
                                    <PrivateRoute path='/articleService' component={ArticleServiceDashboard} />
                                    <PrivateRoute path='/detailService/:id' component={ArticleServicePage} />
                                    <PrivateRoute path='/bLAFacturer' component={BlAFacturerDashboard} />
                                    <PrivateRoute path='/brAFacturer' component={BrAFacturerDashboard} />
                                    <PrivateRoute path='/reliquats' component={ReliquatsPage} />
                                    <PrivateRoute path='/accounting' component={ExportComptables} />
                                    <PrivateRoute path='/soldes' component={SoldesDashboard} />
                                    <PrivateRoute path='/commandeEnAttente' component={CommandeAttenteDashboard} />
                                    <PrivateRoute path='/inventaire' component={InventoryDashboard} />
                                    <PrivateRoute path='/statics' component={ExportDataDashboard} />
                                    <PrivateRoute path='/commission' component={dashboardCommission} />
                                    <PrivateRoute path='/requests' component={requestDashboard} />

                                    <PrivateRoute exact path='/inventaireDetail/:id' component={InventoryProductDashboard} />
                                    <Route component={NotFound} />
                                </Switch>
                            </Container>
                            <Footer />
                        </Fragment>
                    </div>
                )}
            />
        </Fragment>
    );
};
export default withRouter(observer(App));