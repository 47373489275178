import React, { useContext } from 'react';
import { Form, Button, Grid } from 'semantic-ui-react';
import { Form as FinalForm, Field } from 'react-final-form';
import { combineValidators, composeValidators, isRequired } from 'revalidate';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { v4 as uuid } from 'uuid';
import TextInput from '../../../app/common/form/inputText';
import ErrorMessage from '../../../app/common/form/ErrorMessage';


const validate = combineValidators({
    label: composeValidators(
        isRequired,
    )({
        message: 'Veuillez enter un label'
    }),
    description: composeValidators(
        isRequired,
    )({
        message: 'Veuillez enter une description'
    }),

});

const ModePaiementForm = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { create } = baseRepo.modePaiementStore;
    const handleFinalFormSubmit = (values: any) => {
        let newMode = {
            ...values,
            paiementId: uuid(),
        };
        create(newMode);
        baseRepo.modePaiementStore.changeButtonStateAdd(!baseRepo.modePaiementStore.addMode)
    }

    return (

        <FinalForm
            onSubmit={handleFinalFormSubmit}
            validate={validate}
            render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit }) => (

                <Form onSubmit={handleSubmit} error>
                    <Grid columns={3}>
                        <Grid.Row>
                            <Grid.Column>
                                <Field fluid name='label' component={TextInput} placeholder='Label' />
                            </Grid.Column>
                            <Grid.Column>
                                <Field fluid name='description' component={TextInput} placeholder='Description' />
                            </Grid.Column>
                            {submitError && !dirtySinceLastSubmit && (
                                <ErrorMessage
                                    error={submitError}
                                    text='Label ou description invalide'
                                />
                            )}
                            <Grid.Column>
                                <Button
                                    fluid size='large'
                                    disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                                    loading={submitting}
                                    color='orange'
                                    content='Ajouter'
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                </Form>

            )} />

    );
}
export default ModePaiementForm;