import { Item, Segment, Icon, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import React from 'react';
import { IFournisseur } from '../../../app/models/IFournisseur';

const stylesNonactif = {
    border: 'solid',
    borderColor: 'red',
    borderWidth: 2
}

const FournisseurListItem: React.FC<{ fournisseur: IFournisseur }> = ({ fournisseur }) => {
    return (
        <Segment.Group>
            <Segment>
                <Item.Group>
                    <Item>
                        <Item.Image
                            size='tiny'
                            src={fournisseur.uri}
                            style={fournisseur.isActive ? null : stylesNonactif} />
                        <Item.Content >
                            <Item.Header as={Link} to={`/detailFournisseur/${fournisseur.fournisseurId}`}>
                                {fournisseur.fabricant}
                            </Item.Header>
                            <Segment style={{ marginTop: 0, paddingLeft: 0}}>
                                <Icon name='user' />{fournisseur.nomContact} &nbsp;
                                <Icon name='mail' />{fournisseur.email} &nbsp;
                                <Icon name='fax' />{fournisseur.telephone} &nbsp;
                            </Segment>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Segment>       
            <Segment>
                <Icon name='address card' />{fournisseur.adresse}, {fournisseur.codePostal}, {fournisseur.ville}
            </Segment>
            <Segment clearing>
                    <Button
                    as={Link}
                    to={`/detailFournisseur/${fournisseur.fournisseurId}`}
                        floated='right'
                        content='Détail'
                        color='blue'
                    />
            </Segment>
        </Segment.Group>);
}

export default FournisseurListItem;