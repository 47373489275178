import React, { useContext, useEffect, useState } from 'react';
import { Table, Label } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { Link, RouteComponentProps } from 'react-router-dom';
import NoResult from '../../commande/commandeDashboard/ErrorNoResult';
import { IOrderSupplier } from '../../../app/models/ICommandeFournisseur';
import { ICFList } from '../../../app/models/IArticle';
import ListItemPlaceholder from '../../../app/common/form/ListItemPlaceholder';

const numberFormat = new Intl.NumberFormat('FR-fr', {
    style: 'currency',
    currency: 'EUR',
    useGrouping: true,
});

interface IDetailParams {
    id: number
}
const ListCF: React.FC<IDetailParams> = ({ id }) => {
    const baseStore = useContext(BaseStoreContext);
    const { ListCF, loadingCF, loadCommandeFournisseur } = baseStore.articleStore;
    useEffect(() => {
        loadCommandeFournisseur(id);
    }, []);

    if (!loadingCF && ListCF.length < 1) return < NoResult name="Commandes Fournisseur" />

    else
        return (
            <>
                {loadingCF ? <ListItemPlaceholder /> : (

                    <Table compact='very' style={{ marginTop: '0em' }} >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Date CF</Table.HeaderCell>
                                <Table.HeaderCell>N° CF</Table.HeaderCell>
                                <Table.HeaderCell>Fournisseur</Table.HeaderCell>
                                <Table.HeaderCell>Ref Interne</Table.HeaderCell>
                                <Table.HeaderCell>Quantité</Table.HeaderCell>
                                <Table.HeaderCell>Montant HT</Table.HeaderCell>
                                <Table.HeaderCell>N° LF</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {ListCF && ListCF.map((commandeFournisseur: ICFList) => (
                                <Table.Row key={commandeFournisseur.idCF}>
                                    <Table.Cell>{new Date(commandeFournisseur.orderDate).toLocaleDateString('en-GB')}</Table.Cell>
                                    <Table.Cell>
                                        <Label style={{}} as={Link} to={`/detailCommandeFournisseur/${commandeFournisseur.idCF}`}>{commandeFournisseur.refCF}</Label>
                                    </Table.Cell>
                                    <Table.Cell>{commandeFournisseur.fournisseur}</Table.Cell>
                                    <Table.Cell>{commandeFournisseur.internalRef}</Table.Cell>
                                    <Table.Cell>{commandeFournisseur.quantity}</Table.Cell>
                                    <Table.Cell>{numberFormat.format(commandeFournisseur.amountWithoutTaxes)}</Table.Cell>
                                    <Table.Cell>
                                        {commandeFournisseur.idLF == 0 ?
                                            <Table.Cell></Table.Cell> :
                                            <Label style={{}} as={Link} to={`/detailReception/${commandeFournisseur.idLF}`}>{commandeFournisseur.refLF}</Label>
                                        }
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                )}
            </>
        );
}

export default observer(ListCF);