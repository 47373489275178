import { action, observable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../agent/agent";
import { IGroupProductPrice, IGroupProductPriceList, IProductPrice, IProductPriceAdd, ITarifFranchiseur } from "../models/ITarifFranchiseur";
import { BaseStore } from "./baseStore";


export default class TarifFranchiseurStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
    }
    @observable loadingtarifFranchiseurAction = false;
    @observable loadingtarifFranchiseur = false;
    @observable submitting = false;
    @observable deletingtarifFranchiseur = false;
    @observable tarifFranchiseur: ITarifFranchiseur | null = null;
    @observable tarifFranchiseurs: ITarifFranchiseur[] = [];
    @observable productPrices: IGroupProductPriceList[] = [];
    @observable edittarifFranchiseur: boolean = false;
    @observable addtarifFranchiseur: boolean = false;

    @action changeButtonStateEdit = async (newState: boolean) => {
        this.edittarifFranchiseur = newState;
    }

    @action changeButtonStateAdd = async (newState: boolean) => {
        this.addtarifFranchiseur = newState;
    }

    @action create = async (values: any) => {
        this.loadingtarifFranchiseur = true;
        try {
            const tarifFranchiseurs = await agent.tarifFranchiseurAgent.create(values);
            runInAction(() => {
                this.productPrices = tarifFranchiseurs;
                this.loadingtarifFranchiseur = false;
                toast.success("ajout éffectué avec succès");
            })

        } catch (e) {
            runInAction(() => {
                this.loadingtarifFranchiseur = false;
                toast.error("Problem d'ajout tarrif !");
            })
        }
    }

    @action add = async (values: IProductPriceAdd) => {
        this.loadingtarifFranchiseur = true;
        try {
            const tarifFranchiseurs = await agent.tarifFranchiseurAgent.add(values);
            runInAction(() => {
                this.productPrices = tarifFranchiseurs;
                this.loadingtarifFranchiseur = false;
                toast.success("ajout éffectué avec succès");
            })

        } catch (e) {
            runInAction(() => {
                this.loadingtarifFranchiseur = false;
                toast.error("Problem d'ajout tarrif !");
            })
        }
    }

    @action loadtarifFranchiseurs = async (id: number, company:number) => {
        this.loadingtarifFranchiseur = true;
        try {
            const tarifFranchiseurs = await agent.tarifFranchiseurAgent.list(id,company);
            runInAction(() => {
                this.tarifFranchiseurs = tarifFranchiseurs;
                this.loadingtarifFranchiseur = false;
            })
            return tarifFranchiseurs;
        } catch (e) {
            toast.error("Problem loading !");
            runInAction(() => {
                this.loadingtarifFranchiseur = false;
            })
        }
    }

    @action loadTarifs = async (id: number) => {
        this.loadingtarifFranchiseur = true;
        try {
            const tarifFranchiseurs = await agent.tarifFranchiseurAgent.listTarif(id);
            runInAction(() => {
                this.productPrices = tarifFranchiseurs;
                this.loadingtarifFranchiseur = false;
            })
            return tarifFranchiseurs;
        } catch (e) {
            toast.error("Problem loading !");
            runInAction(() => {
                this.loadingtarifFranchiseur = false;
            })
        }
    }

    @action edit = async (key: number, keyT: number, values: IProductPrice) => {
        this.submitting = true;
        this.loadingtarifFranchiseur = true
        try {
            const tarifFranchiseurs = await agent.tarifFranchiseurAgent.update(values);
            runInAction(() => {
                this.productPrices = tarifFranchiseurs;
                toast.success("Modification éffectuée avec succès");
                this.submitting = false;
                this.loadingtarifFranchiseur = false;
            })

        } catch (e) {
            runInAction(() => {
                this.submitting = false;
                this.loadingtarifFranchiseur = false;
            })
        }
    }

    @action deleteTarif = async (key: number, keyT: number, id: string, agencyName:string,type:string, qtt:number) => {
        this.loadingtarifFranchiseurAction = true;
        try {
            await agent.tarifFranchiseurAgent.delete(id);
            runInAction(() => {
                if (type == '0')
                    this.productPrices[key].groupProductPrices.map((item: IGroupProductPrice) => {
                        item.productPrices = item.productPrices.filter(x => x.quantity != qtt)
                        if (item.productPrices.length == 0)
                            this.productPrices[key].groupProductPrices = this.productPrices[key].groupProductPrices.filter(x => x.companyType != item.companyType);
                    })
                else {
                    this.productPrices[key].groupProductPrices[keyT].productPrices = this.productPrices[key].groupProductPrices[keyT].productPrices.filter(x => x.id != id);
                    if (this.productPrices[key].groupProductPrices[keyT].productPrices.length == 0)
                        this.productPrices[key].groupProductPrices = this.productPrices[key].groupProductPrices.filter(x => x.companyType != type);
                }
                if (this.productPrices[key].groupProductPrices.length == 0)
                    this.productPrices = this.productPrices.filter(x => x.agencyName != agencyName);
                this.loadingtarifFranchiseurAction = false;
                toast.success("suppression éffectuée avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de suppréssion !");
            runInAction(() => {
                this.loadingtarifFranchiseurAction = false;
            })
        }
    }

    @action deleteSelectedProductPrices = async (productId: number, selected: string[]) => {
        this.loadingtarifFranchiseur = true;
        this.deletingtarifFranchiseur = true;
        try {
            const tarifFranchiseurs = await agent.tarifFranchiseurAgent.deleteSelected(productId,selected);
            runInAction(() => {
                this.productPrices = tarifFranchiseurs;
                this.loadingtarifFranchiseur = false;
                this.deletingtarifFranchiseur = false;
                toast.success("suppression éffectuée avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de suppréssion !");
            runInAction(() => {
                this.deletingtarifFranchiseur = false;
                this.loadingtarifFranchiseur = false;
            })
        }
    }
}