import React, { useContext, useState } from 'react';
import { Header, Grid, Segment, Item, Button, Reveal, Label } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import NotFound from '../../../app/layout/NoFound';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { ICommandeFournisseur } from '../../../app/models/ICommandeFournisseur';
import AddReceptionModal from './AddReceptionModal';
import { VscFilePdf } from 'react-icons/vsc';
import DeleteCommandeFournisseurModal from '../commandeFournisseurDashboard/DeleteCommandeFournisseurModal';

const style = {
    margin: 0,
    padding: 0,
    paddingLeft: 5,
    textAlign: "right",
}
const styleTitle = {
    margin: 0,
    padding: 0,
    paddingLeft: 5,
    fontWeight: "bold",
    fontSize : 14,    
}
const numberFormat = new Intl.NumberFormat('FR-fr', {
    style: 'currency',
    currency: 'EUR',
    useGrouping: true,
});

interface IProps {
    commandeFournisseur: ICommandeFournisseur,
    loading: boolean,
    enAttente: (id: number) => Promise<void>;
    valide: (id: number) => Promise<void>;
    id: string,
}

const CommandeFournisseurHeader: React.FC<IProps> = ({ commandeFournisseur, loading, enAttente, valide, id }) => {
    const baseRepo = useContext(BaseStoreContext);
    const { changeDuplicationState, duplicationMode, setActiveTab, generatePDF, deletingOrder } = baseRepo.commandeFournisseurStore;
    const { openModal } = baseRepo.modalStore;
    const { facturerCommande } = baseRepo.factureFournisseurStore;
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    const handlePDF = () => {
        generatePDF(parseInt(id));
    }
    if (!commandeFournisseur) return <NotFound />;
    return (
        <Segment>
            <Grid>
                <Grid.Row style={{ padding: 0 }}>
                    <Grid.Column width={8} style={{ margin: 0 }}>
                        <Item.Group style={{ padding: '20px 0px 0 20px' }}>
                            <Item>
                                <Item.Content verticalAlign='middle'>

                                    <Header as='h3'>Commande : {commandeFournisseur!.reference}</Header><br />
                                    <Header style={{ paddingBottom: 10, fontSize: 16 }}><b style={{ color: 'red', marginRight: 50 }}>{commandeFournisseur!.companyName}</b></Header><br />
                                    <Header style={{ paddingBottom: 10, fontSize: 14 }}><b style={{ color: '#db2828', float: 'right' }}>{new Date(commandeFournisseur?.orderDate).toLocaleString()}</b></Header>
                                    {commandeFournisseur.orderFournisseurState == "Receptionnee" &&

                                            <Label as='a' basic color='green' style={{ cursor: 'none', position:'absolute',top: 80, left: 250}}>
                                                Réceptionnée
                                            </Label>

                                    }
                                    {commandeFournisseur.orderFournisseurState == "Commandee" &&

                                        <Label as='a' basic color='green' style={{ cursor: 'none', position: 'absolute', top: 80, left: 250 }}>
                                        Commandée
                                            </Label>

                                    }
                                    {commandeFournisseur.orderFournisseurState == "Facturee" &&
                                        <Label as='a' basic color='green' style={{ cursor: 'none', position: 'absolute', top: 80, left: 250 }}>
                                                Facturée
                                            </Label>
                                    }
                                    <br />
                                    <Header style={{ paddingBottom: 5, fontSize: 14 }}>Ref. Interne : {commandeFournisseur!.internalRef}</Header><br />
                                    <Header style={{ paddingBottom: 10, fontSize: 14 }}>Ref. Fournisseur : {commandeFournisseur!.numSupplierOrder}</Header><br />
                                    <Header style={{ paddingBottom: 10, fontSize: 16 }}><b style={{ color: 'red', marginRight: 50 }}>{commandeFournisseur!.fabricant}</b></Header><br />
                                </Item.Content>
                                <Item.Content style={{ width: 10 }}>

                                    <VscFilePdf
                                        title="Télécharger PDF"
                                        color={baseRepo.factureStore.generatePdfMode ? 'orange' : 'green'}
                                        onMouseEnter={() => {
                                            baseRepo.factureStore.changeButtonPdfState(!baseRepo.factureStore.generatePdfMode);
                                        }}
                                        onMouseLeave={() => {
                                            baseRepo.factureStore.changeButtonPdfState(!baseRepo.factureStore.generatePdfMode);
                                        }}
                                        style={{ marginLeft: 10, fontSize: "28px", cursor: 'pointer', content: "dddd", position: 'absolute', right: 0 }}
                                        onClick={handlePDF}
                                    />
                                </Item.Content>
                            </Item>
                        </Item.Group>  

                    </Grid.Column>
                    <Grid.Column width={4} style={{ margin: 0 }}>
                        <Grid style={{ margin: '10px 20px' }}>
                            <Grid.Row style={{ margin: 0, padding: 0 }}>
                                <Grid.Column width={8} style={styleTitle}>
                                    Montant HT
                                </Grid.Column>
                                <Grid.Column width={8} style={style}>
                                    {numberFormat.format(commandeFournisseur.amountWithoutTaxes)}
                                </Grid.Column>
                                <hr style={{ textAlign: "center", width: "100%" }} />
                                <Grid.Column width={8} style={styleTitle}>
                                    Montant TVA
                                </Grid.Column>
                                <Grid.Column width={8} style={style }>
                                    {numberFormat.format(commandeFournisseur.amountWithTaxes - commandeFournisseur.amountWithoutTaxes)}
                                </Grid.Column>
                                <hr style={{ textAlign: "center", width: "100%" }} />
                                <Grid.Column width={8} style={styleTitle}>
                                    Montant TTC
                                </Grid.Column>
                                <Grid.Column width={8} style={style}>
                                    {numberFormat.format(commandeFournisseur.amountWithTaxes)}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={4} style={{ margin: 0 }}>
                        {commandeFournisseur.orderFournisseurState == "AttenteValidation" &&
                            <Button.Group vertical style={{ width: '100%', marginTop: 4 }}>
                                <Button
                                    floated='right'
                                    style={{ height: 40 }}
                                    size="tiny"
                                    color={'red'}
                                    basic
                                    icon='trash'
                                    content={"Supprimer la commande"}
                                    onClick={(e) => {
                                        openModal(<DeleteCommandeFournisseurModal commande={commandeFournisseur} />)
                                        setDeleteTarget(e.currentTarget.name)
                                    }}
                                    loading={deletingOrder && deleteTarget === commandeFournisseur.id.toString()}
                                />
                            </Button.Group>
                        }
                        <Button.Group vertical style={{ width: '100%', marginTop: 4 }}>
                            <Button
                                style={{ height: 40 }}
                                size="tiny"
                                floated='right'
                                color={duplicationMode ? 'red' : 'orange'}
                                onClick={() => {
                                    changeDuplicationState(!duplicationMode);
                                    setActiveTab('0');
                                }}
                                content={duplicationMode ? 'Annuler' : 'Dupliquer'}
                            />
                        </Button.Group>
                        {commandeFournisseur.orderFournisseurState == "AttenteReception" &&
                            <Button.Group vertical style={{ width: '100%', marginTop: 4 }}>
                                <Button
                                    floated='right'
                                    style={{ height: 40 }}
                                    size="tiny"
                                    color={'blue'}
                                    content={"Réceptionner"}
                                    onClick={(e) => {
                                        openModal(<AddReceptionModal order={commandeFournisseur} />)
                                    }}
                                />
                            </Button.Group>
                        } 
                        {((commandeFournisseur.orderFournisseurState == "AttenteReception") || (commandeFournisseur.orderFournisseurState == "AttenteValidation")) &&
                            <div style={{ padding: 0 }}>
                                {commandeFournisseur.amountWithoutTaxes == 0 ? (
                                    <Reveal >
                                        <Reveal.Content visible style={{ width: '100%' }}>
                                            <Button
                                                size='small'
                                                fluid
                                                color={'red'}
                                                content={'En Attente '}
                                            />

                                        </Reveal.Content>
                                    </Reveal>
                                ) : (
                                    <Reveal animated='move' style={{ width: '100%' }}>
                                        <Reveal.Content visible style={{ width: '100%' }}>
                                            <Button
                                                size='small'
                                                fluid
                                                color={commandeFournisseur.orderFournisseurState == "AttenteValidation" ? 'red' : 'green'}
                                                content={commandeFournisseur.orderFournisseurState == "AttenteValidation" ? 'En Attente' : 'En attente de réception'}
                                            />
                                        </Reveal.Content>
                                        <Reveal.Content hidden>
                                            <Button
                                                size='small'
                                                loading={loading}
                                                fluid
                                                basic
                                                color={commandeFournisseur.orderFournisseurState == "AttenteValidation" ? 'green' : 'red'}
                                                content={commandeFournisseur.orderFournisseurState != "AttenteValidation" ? 'EnAttente' : 'Valider'}
                                                onClick={() => {
                                                    commandeFournisseur!.orderFournisseurState == "AttenteValidation" ? valide(commandeFournisseur.id) : enAttente(commandeFournisseur.id)
                                                }}
                                            />
                                        </Reveal.Content>
                                    </Reveal>
                                )}
                            </div>
                        }
                        {commandeFournisseur.orderFournisseurState == "Receptionnee" &&
                            <Button
                                floated='right'
                                style={{ height: 40 }}
                                color={'green'}
                                content={"Facturer"}
                                onClick={(e) => {
                                    facturerCommande(commandeFournisseur.id)
                                }}
                            />
                        }

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment >
    );
};
export default observer(CommandeFournisseurHeader);