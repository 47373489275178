import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Form, Button, Input } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import TextInput from '../../../app/common/form/inputText';
import { IAdresse } from '../../../app/models/IAdresse';
import AsyncSelect from 'react-select/async';

interface IProps {
    type: string;
    adresse: IAdresse;
    setModalOpen: Dispatch<SetStateAction<boolean>>;
}

const UpdateAddressModal: React.FC<IProps> = ({ adresse, setModalOpen, type }) => {

    const baseStore = useContext(BaseStoreContext);
    const { edit, submitting } = baseStore.adresseStore;
    const [codePostal, setCodePostal] = useState<any>(adresse.postalCode);
    const [adresseLivraison, setAdresseLivraison] = useState<string | undefined>(adresse.address);
    const [adresseComplementLivraison, setAdresseComplementLivraison] = useState<string | undefined>(adresse.additionalAddress);
    const [ville, setVille] = useState<string | undefined>(adresse.city);
    const [pays, setPays] = useState<any>(adresse.country);
    const [companyName, setCompanyName] = useState<string | undefined>(adresse.companyName);
    const [phone, setPhone] = useState<string | undefined>(adresse.phone);
    const [email, setEmail] = useState<string | undefined>(adresse.email);

    const handleChangeVille = (e: any, result: any) => {
        setVille(result.value);
    }
    const handleChangePays = (value:any) => {
        setPays(value.iso);
    }
    const handleChangeCompanyName = (e: any, result: any) => {
        setCompanyName(result.value);
    }
    const handleChangePhone = (e: any, result: any) => {
        setPhone(result.value);
    }
    const handleChangeEmail = (e: any, result: any) => {
        setEmail(result.value);
    }
    const handleChangeCodePostal = (e: any, result: any) => {
        setCodePostal(result.value);
    }
    const handleChangedeliveryPostalCode = (value: any) => {
        setCodePostal(value.codePostal);
        setVille(value.city);
    }
    const handleChangeAdresseLivraison = (e: any, result: any) => {
        setAdresseLivraison(result.value);
    }
    const handleChangeAdresseComplementLivraison = (e: any, result: any) => {
        setAdresseComplementLivraison(result.value);
    }

    const handleFinalFormSubmit = () => {
        let newAdresse: IAdresse = {
            ...adresse,
            postalCode: codePostal!,
            address: adresseLivraison!,
            additionalAddress: adresseComplementLivraison!,
            city: ville!,
            country: pays!,
            companyName: companyName!,
            phone: phone!,
            email: email!,
        };
        edit(newAdresse, type);
        setModalOpen(false)
    }

    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            render={({ handleSubmit, invalid }) => (
                <Form onSubmit={handleSubmit} error >
                    <Form.Group unstackable widths={3}>
                        <div className="field">
                            <label>Pays</label>
                            <AsyncSelect
                                name="form-field-name"
                                noOptionsMessage={() => "Code introuvable"}
                                cacheOptions
                                defaultOptions
                                placeholder={pays}
                                value={pays}
                                getOptionLabel={e => e!.pays + ' (' + e!.iso + ')'}
                                getOptionValue={e => e!.iso}
                                loadOptions={baseStore.countryCodeStore.getCountryCodes}
                                onChange={handleChangePays}

                            />
                        </div>
                        {pays == "FR" ?
                            <div className="field">
                                <label>Code postale </label>
                                <AsyncSelect
                                    name="form-field-name"
                                    noOptionsMessage={() => "Code introuvable"}
                                    cacheOptions
                                    defaultOptions
                                    placeholder={codePostal}
                                    value={codePostal}
                                    getOptionLabel={e => e!.city + '(' + e!.codePostal + ')'}
                                    getOptionValue={e => e!.codePostal}
                                    loadOptions={baseStore.cityCodeStore.getCityCodes}
                                    onChange={handleChangedeliveryPostalCode}
                                />
                            </div> :
                            <div className="field">
                                <label>Code postal </label>
                                <Input name='postalCode' component={TextInput} defaultValue={adresse!.postalCode} type='text' onChange={handleChangeCodePostal} />
                            </div>
                        }
                        <div className="field">
                            <label>Ville</label>
                            <Input name='city' component={TextInput} value={ville} onChange={handleChangeVille} />
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={2} >
                        <div className="field">
                            <label>adresse</label>
                            <Input name='address' component={TextInput} defaultValue={adresse!.address} onChange={handleChangeAdresseLivraison} />
                        </div>
                        <div className="field">
                            <label>adresse Complementaire</label>
                            <Input name='additionalAddress' component={TextInput} defaultValue={adresse!.additionalAddress} onChange={handleChangeAdresseComplementLivraison} />
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={2}>
                        <div className="field">
                            <label>Societé</label>
                            <Input name='companyName' component={TextInput} defaultValue={adresse!.companyName} onChange={handleChangeCompanyName} />
                        </div>
                        <div className="field">
                            <label>Téléphone</label>
                            <Input name='phone' component={TextInput} defaultValue={adresse!.phone} onChange={handleChangePhone} />
                        </div>
                        <div className="field">
                            <label>Email</label>
                            <Input name='email' component={TextInput} defaultValue={adresse!.email} onChange={handleChangeEmail} />
                        </div>
                    </Form.Group>
                    <Form.Group unstackable style={{ marginLeft: "70%" }} >
                        <Button.Group floated="right">
                            <Button onClick={() => { setModalOpen(false) }} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive disabled={submitting || invalid} loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </Form.Group>
                </Form>
            )}
        />
    );
}
export default observer(UpdateAddressModal);
