import React, { useContext } from 'react';
import { Button, Header, Icon, Container } from 'semantic-ui-react';
import { IDevis } from '../../../app/models/IDevis';
import { BaseStoreContext } from '../../../app/store/baseStore';

interface IProps {
    devis: IDevis;
}

const DeleteDevisModal: React.FC<IProps> = ({ devis }) => {
    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    const { deleteQuote } = baseStore.devisStore;

    return (
        <Container textAlign='center'>
            <Header icon='trash' content='Supprimer une Devis' style={{ marginLeft: 20 }} />
            <p>
                Voulez vous supprimer cette devis ?
            </p>
            <Button.Group fluid>
                <Button onClick={() => closeModal()}>
                    <Icon name='remove' /> Annuler
                </Button>
                <Button.Or text='Ou' />
                <Button
                    name={devis.id}
                    color='green'
                    onClick={(e) => {
                        deleteQuote(devis.id)
                        closeModal()
                    }}>
                    <Icon name='checkmark' /> Confirmer
                </Button>
            </Button.Group>
        </Container>
    )
}
export default DeleteDevisModal;
