import { BaseStore } from "./baseStore";
import { observable, action, runInAction } from 'mobx';
import agent from '../agent/agent';
import { toast } from "react-toastify";
import { setEntrepotProps } from "../common/util/util";
import { IEntrepot, IEntrepotList } from "../models/IEntrepot";
import { IDetailEntrepot, IEntrepotByAticleEnMode, IEntrepotListe } from "../models/IEntrepotByAticleEnModeAppro";

export default class EntrepotStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
    }
    @observable loadingEntrepotAction = false;
    @observable loadingEntrepot = false;
    @observable loadingSetMain = false;
    @observable loadingInitial = false;
    @observable submitting = false;
    @observable deletingEntrepot = false;
    @observable entrepot: IEntrepot | null = null;
    @observable entrepots: IEntrepot[] = [];
    @observable entrepotRegestry = new Map();
    @observable ITListEntrepot: IEntrepotList[] = [];
    @observable ITAllListEntrepot: IEntrepotList[] = [];
    @observable editEntrepot: boolean = false;
    @observable addMode: boolean = false;
    @observable progress: number = 50;
    @observable depots: IEntrepotByAticleEnMode[] = [];
    @observable articleCount: number =0;


    @action changeButtonStateEdit = async (newState: boolean) => {
        this.editEntrepot = newState;
    }

    @action changeButtonStateAdd = async (newState: boolean) => {
        this.addMode = newState;
    }

    @action loadEntrepots = async () => {
        this.loadingInitial = true;
        this.ITAllListEntrepot = [];
        try {
            const entrepots = await agent.entrepotAgent.list();
            runInAction(() => {
                this.entrepots = entrepots;
                entrepots.forEach((entrepot) => {
                    setEntrepotProps(entrepot);
                    this.entrepotRegestry.set(entrepot.entrepotsId, entrepot);
                    let ENTREPOT: IEntrepotList = {
                        key: entrepot.entrepotsId,
                        text: entrepot.libelle,
                        value: entrepot.entrepotsId
                    }
                    this.ITAllListEntrepot.push(ENTREPOT)
                })
                this.loadingInitial = false;
            })
            return this.entrepots;
        } catch (e) {
            toast.error("Problem loading entrepot!");
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }

    @action loadEntrepotActifsNonAffecte = async (id: number) => {
        this.loadingInitial = true;
        try {
            const entrepots = await agent.entrepotAgent.listEntrepotActiveNonAffecte(id);
            runInAction(() => {
                this.entrepots = entrepots;
                entrepots.forEach((entrepot) => {
                    setEntrepotProps(entrepot);
                    this.entrepotRegestry.set(entrepot.entrepotsId, entrepot);
                    let ENTREPOT: IEntrepotList = {
                        key: entrepot.entrepotsId,
                        text: entrepot.libelle,
                        value: entrepot.entrepotsId
                    }
                    this.ITListEntrepot.push(ENTREPOT)
                })
                this.loadingInitial = false;
            })
        } catch (e) {
            toast.error("Problem loading entrepot!");
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }

    getEntrepot = (id: number) => {
        return this.entrepotRegestry.get(id);
    }

    @action create = async (values: IEntrepot) => {
        this.submitting = true;
        try {
            var id = await agent.entrepotAgent.create(values);
            runInAction(() => {
                this.entrepotRegestry.set(values.entrepotsId, values)
                this.entrepot = values;
                this.entrepot.entrepotsId = parseInt(id + "");
                this.entrepot.active = true;
                this.entrepots.push(this.entrepot);
                this.submitting = false;
                toast.success("ajout éffectué avec succès");
            })

        } catch (e) {
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    @action edit = async (values: Partial<IEntrepot>) => {
        this.submitting = true;
        this.progress = 80;
        try {
            await agent.entrepotAgent.update(values);
            let entrepot = await agent.entrepotAgent.details(values.entrepotsId!);
            runInAction(() => {
                this.entrepots = this.entrepots.filter(a => a.entrepotsId != values.entrepotsId);
                this.entrepots.push(entrepot);
                toast.success("Modification éffectuée avec succès");
                this.submitting = false;
            })

        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action deleteEntrepot = async (id: number) => {
        this.loadingEntrepotAction = true;
        try {
            await agent.entrepotAgent.delete(id);
            runInAction(() => {
                this.loadingEntrepotAction = false;
                this.entrepots = this.entrepots.filter(a => a.entrepotsId !== id);
                toast.success("suppression éffectuée avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.loadingEntrepotAction = false;
            })
        }
    }

    @action disable = async (id: number) => {
        this.deletingEntrepot = true;
        try {
            var articleCount=await agent.entrepotAgent.disable(id);
            runInAction(() => {
                if (articleCount > 0) {
                    this.articleCount = articleCount;
                }
                else {
                   
                    let x = -1;
                    this.entrepots.forEach((item, index) => {
                        if (item.entrepotsId == id)
                            x = index;
                    });
                    if (x != -1)
                        this.entrepots[x]!.active = false;
                    toast.success("entrepot désactivé avec succès");
                }
                this.deletingEntrepot = false;
            })
            return articleCount;
        } catch (e) {
            console.log(e);
            runInAction(() => {
                this.deletingEntrepot = false;
            })
        }
    }

    @action enable = async (id: number) => {
        this.deletingEntrepot = true;
        try {
            await agent.entrepotAgent.enable(id);
            runInAction(() => {
                this.deletingEntrepot = false;
                let x = -1;
                this.entrepots.forEach((item, index) => {
                    if (item.entrepotsId == id)
                        x = index;
                });
                if (x != -1)
                    this.entrepots[x]!.active = true;
                toast.success("entrepot activé avec succès");
            })
        } catch (e) {
            console.log(e);
            runInAction(() => {
                this.deletingEntrepot = false;
            })
        }
    }

    @action setMain = async (id: number) => {
        this.loadingSetMain = true;
        try {  
            await agent.entrepotAgent.setMain(id);
            runInAction(() => {
                var x = this.entrepots.findIndex(x => x.entrepotsId == id);
                var y = this.entrepots.findIndex(x => x.isMain == true);
                this.entrepots[x].isMain = true;  
                if (y != x && y > -1)
                    this.entrepots[y].isMain = false;
                this.loadingSetMain = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingSetMain = false;
            })
        }
    }

    @action loadEntrepotByArticleModeApproByCommande = async (id: number) => {
        this.loadingInitial = true;
        this.depots = [];
        try {
            const depots = await agent.entrepotAgent.detailsEntrepotByArticleEnModeApproByCommande(id);
            runInAction(() => {
                depots.forEach((item: any) => {
                    let x: IEntrepotListe[] = [];
                    item.entrepots.forEach((obj: IDetailEntrepot) => {
                        let ENTREPOT: IEntrepotListe = {
                            key: obj.id,
                            text: obj.libelle + " ( " + obj.quantite + " piéces )",
                            value: obj.id
                        }
                        x.push(ENTREPOT)
                    })
                    let e: IEntrepotByAticleEnMode = {
                        articles : item.articles,
                        entrepots : x
                    }
                    this.depots.push(e)
                })
                this.loadingInitial = false;
            })
        } catch (e) {
            toast.error("Problem loading !");
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }
}