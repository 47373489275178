import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, GridColumn, Label, Loader, Segment } from 'semantic-ui-react';
import Breadcrumbs from '../../dashboard/breadcrumbs';
import { Link } from 'react-router-dom';
import { FaPlusCircle } from 'react-icons/fa';
import ListItemPlaceholder from '../../../app/common/form/ListItemPlaceholder';
import InfiniteScroll from 'react-infinite-scroller';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import ListFacture from './ListFacture';
import FactureFilter from './FactureFilter';
import { numberFormat } from '../../../app/common/options/IStyle';

const FactureDashboard: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { loadingInitial, page, totalPages, setPage, loadListesFactures, totalHT, totalTTC } = baseStore.factureFournisseurStore;
    const [loadingNext, setLoadingNext] = useState(false);

    useEffect(() => {
        loadListesFactures();
    }, []);

    const handleGetNext = () => {
        setLoadingNext(true);
        setPage(page + 1);
        loadListesFactures().then(() => setLoadingNext(false));
    }
    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Achat", root: "" },
                    { key: 1, name: "Factures Fournisseurs", root: "/factureFournisseurDashboard" },
                ]}
            />
            <Grid>
                <Grid.Row style={{ padding: 0, margin: "5px 20px 0 20px" }}>
                    <Grid.Column style={{ padding: 0, margin: 0 }}>
                        <Button
                            style={{ float: 'right' }}
                            content="Ajouter Facture"
                            className="andi-btn filled warning"
                            icon={(<FaPlusCircle style={{ marginRight: "10px", fontSize: "20px" }} />)}
                            as={Link}
                            to="/addFactureFournisseur"
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ padding: 0, margin: "0 10px 0 10px" }}>
                    <FactureFilter />
                </Grid.Row>
                <GridColumn width={16} style={{ marginTop: 2 }}>
                    <Label>Total HT : {numberFormat.format(totalHT)}</Label>
                    <Label>Total TTC : {numberFormat.format(totalTTC)}</Label>
                    <Grid style={{ marginTop: 2 }}>
                        <GridColumn width={16}>
                            {loadingInitial && page === 0 ? <ListItemPlaceholder /> : (
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={handleGetNext}
                                    hasMore={!loadingNext && page + 1 < totalPages}
                                    initialLoad={false}
                                >
                                    <ListFacture />
                                </InfiniteScroll>
                            )}
                        </GridColumn>
                    </Grid>
                </GridColumn>
                <Grid.Column width={16}>
                    <Loader active={loadingNext} />
                </Grid.Column>
            </Grid>
        </div>
    );
}
export default observer(FactureDashboard);