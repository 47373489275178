import React, { useContext } from 'react';
import { Table, Header, Label } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../app/store/baseStore';
import NoResult from '../commande/commandeDashboard/ErrorNoResult';
import StockMultipleSearch from './StockMultipleSearch';
import { Link } from 'react-router-dom';
import { IMouvementStock } from '../../app/models/IMouvementStock';

const ListMouvementStock: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { stocks } = baseStore.mouvementStockStore;
    if (stocks.length < 1) return < NoResult name="Stock" />
    else
        return (
            <>
                <Table compact='very' style={{ marginTop: '-1em' }} >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Articles</Table.HeaderCell>
                            <Table.HeaderCell>Quantite</Table.HeaderCell>
                            <Table.HeaderCell>Libelle</Table.HeaderCell>
                            <Table.HeaderCell>Date Creation</Table.HeaderCell>
                            <Table.HeaderCell>Entrepots</Table.HeaderCell>
                            <Table.HeaderCell>Préparation</Table.HeaderCell>
                            <Table.HeaderCell>Livraison</Table.HeaderCell>
                            <Table.HeaderCell>Réception</Table.HeaderCell>
                            <Table.HeaderCell>Commande</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {stocks && stocks.map((stock: any) => (
                            <Table.Row key={stock.mouvementsStockId}>
                                <Table.Cell><a href={`/detailArticle/${stock.article.productId}`}>{stock.article.reference}-{stock.article.label}</a></Table.Cell>
                                <Table.Cell>{stock.quantite}</Table.Cell>
                                <Table.Cell>{stock.libelle}</Table.Cell>
                                <Table.Cell>{new Date(stock.dateCreation).toLocaleDateString('en-GB')}</Table.Cell>
                                <Table.Cell>{stock.entrepots.libelle}</Table.Cell>
                                <Table.Cell>
                                    {stock.orderPrepareLineItems?.idPreparation != null ?
                                        <Header size='huge'><Label style={{ marginLeft: '2em', cursor: 'pointer' }} as={Link} to={`/detailPreparation/${stock.orderPrepareLineItems?.idPreparation}`}>N°{stock.orderPrepareLineItems?.idPreparation}</Label></Header>
                                        : <p></p>}
                                </Table.Cell>
                                <Table.Cell>
                                    {stock.orderDispatchLine?.id != null ?
                                        <Header size='huge'><Label style={{ marginLeft: '2em', cursor: 'pointer' }} as={Link} to={`/detailExpedition/${stock.orderDispatchLine?.orderDispatchs}`}>N°{stock.orderDispatchLine?.orderDispatchs}</Label></Header>
                                        : <p></p>}
                                </Table.Cell>
                                <Table.Cell>
                                    {stock.orderReceptions != null &&
                                        <Header size='huge'><Label style={{ marginLeft: '2em', cursor: 'pointer' }} as={Link} to={`/detailReception/${stock.orderReceptions?.id}`}>N°{stock.orderReceptions?.id}</Label></Header>
                                    }
                                </Table.Cell>
                                <Table.Cell>
                                    {stock.orderReceptions != null ? (
                                        <Header size='huge'><Label style={{ marginLeft: '2em', cursor: 'pointer' }} as={Link} to={`/detailCommandeFournisseur/${stock.orderReceptions?.orderFournisseur.id}`}>{stock.orderReceptions?.orderFournisseur.reference}</Label></Header>
                                    ) : (
                                        <>
                                            {stock.orderPrepareLineItems?.order != null ?
                                                <Header size='huge'><Label style={{ marginLeft: '2em', cursor: 'pointer' }} as={Link} to={`/detailCommande/${stock.orderPrepareLineItems?.order}`}>{stock.orderPrepareLineItems?.reference}</Label></Header>
                                                    : stock.orderDispatchLine?.order != null ?
                                                        <Header size='huge'><Label style={{ marginLeft: '2em', cursor: 'pointer' }} as={Link} to={`/detailCommande/${stock.orderDispatchLine?.order}`}>{stock.orderDispatchLine?.reference}</Label></Header>
                                                        : <p></p>}
                                        </>
                                    )}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </>
        );
}
export default observer(ListMouvementStock);