import { Fragment, useContext, useEffect, useState } from "react";
import { Button, Form, FormInput, Grid, Header, Menu } from "semantic-ui-react";
import { BaseStoreContext } from "../../../app/store/baseStore";
import { observer } from 'mobx-react-lite';
import { Form as FinalForm } from 'react-final-form';
import Select, { components } from "react-select";
import { FaFileExcel, FaSearch, FaSort } from 'react-icons/fa';
import AsyncSelect from 'react-select/async';
import { MdCancel } from "react-icons/md";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';

const customStyles = {
    option: (provided: any, state: { isSelected: any; }) => ({
        ...provided,
        color: state.isSelected ? 'orange' : 'grey',
        backgroundColor: state.isSelected ? "#eee" : "white",
        borderBottom: '1px solid #eee',
    }),
    valueContainer: (props: any) => ({
        ...props,
        paddingTop: 0,
        height: '39.201px',
        minHeight: '39.201px',
    }),
    control: (baseStyles: any, state: any) => ({
        ...baseStyles,
        height: '39.201px',
        minHeight: '39.201px',
    }),
}

const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <FaSort color='green' />
        </components.DropdownIndicator>
    );
};
const FactureFilter: React.FC = () => {
    const { RangePicker } = DatePicker;
    const baseStore = useContext(BaseStoreContext);
    const { setPredicate, ExportExcel, loadingExport} = baseStore.factureFournisseurStore;
    const { ITListFournisseur,  loadingListFournisseur, loadFournisseurByPurchasePrice } = baseStore.commandeFournisseurStore;
    const [searchResults, setSearchResults] = useState([]);
    const [searchResultsClient, setSearchResultsClient] = useState<any>('');
    const [searchResultsFournisseur, setSearchResultsFournisseur] = useState<any>('');
    const [selectedKey, setSelectedKey] = useState<any>('');
    const [selectedLabelSort, setSelectedLabelSort] = useState<any>('Filtrer...');
    const [selectedLabelClient, setSelectedLabelClient] = useState<any>('Franchiseur');
    const [selectedLabelFournisseur, setSelectedLabelFournisseur] = useState<any>('Fournisseur');
    const [selectedSort, setSelectedSort] = useState<any>('');
    const [selectedLabelTri, setSelectedTri] = useState<any>('Trier...');
    const [searchDateCommande, setSearchDateCommande] = useState<any>('');
    const [searchDateCommandeTo, setSearchDateCommandeTo] = useState<any>('');
    const [searchArticle, setSearchArticle] = useState<any>('');
    const [searchNFournisseur, setSearchNFournisseur] = useState<any>('');
    const [searchNCmd, setSearchNCmd] = useState<any>('');
    useEffect(() => {
        loadFournisseurByPurchasePrice("Search");
    }, []);
    const options = [
        { key: 'All', label: 'Tous' },
        { key: 'Valider', label: 'Validée' },
        { key: 'Brouillon', label: 'Brouillonne' },
    ];
    const optionsTri = [
        { key: 'DateUp', text: 'Plus recent', value: 'DateUp' },
        { key: 'DateDown', text: 'Plus ancien', value: 'DateDown' },
        ,
    ];
    const rangePresets: {
        label: string;
        value: [Dayjs, Dayjs];
    }[] = [
            { label: '7 derniers jours', value: [dayjs().add(-7, 'd'), dayjs()] },
            { label: '14 derniers jours', value: [dayjs().add(-14, 'd'), dayjs()] },
            { label: '30 derniers jours', value: [dayjs().add(-30, 'd'), dayjs()] },
            { label: '90 derniers jours', value: [dayjs().add(-90, 'd'), dayjs()] },
        ];
    const handleChange = (event: any) => {
        setSearchResults(event.target.value);
    }

    const handleChangeClient = (value: any) => {
        setSearchResultsClient(value.id);
        setSelectedLabelClient(value.companyName);
    }
    const handleChangeFournisseur = (value: any) => {
        setSearchResultsFournisseur(value.fournisseurId);
        setSelectedLabelFournisseur(value.fabricant);
    }

    const handleFilter = (key: string, value: string) => {
        setSelectedKey(key);
        setSelectedLabelSort(value)
    }
    const handleChangeNFournisseur = (event: any) => {
        setSearchNFournisseur(event.target.value);

    }
    const handleChangeNCmd = (event: any) => {
        setSearchNCmd(event.target.value);

    }

    const handleChangeTri = (value: any) => {
        setSelectedSort(value.value);
        let labelSelected: any = value.text;
        setSelectedTri(labelSelected);
    }

    const onRangeChange = (dates: null | (Dayjs | null)[]) => {
        if (dates) {
            setSearchDateCommande(dates[0]?.toISOString());
            setSearchDateCommandeTo(dates[1]?.toISOString());
        }
    };
    const handleInputChangeArticle = (event: any) => {
        setSearchArticle(event.target.value);
    }

    const handleSearch = (event: any) => {
        if (event == 'clique') {
            let obj: any = {
                dateCommande: searchDateCommande,
                dateCommandeTo: searchDateCommandeTo,
                codeArticle: searchArticle,
                CodeFournisseur: searchResultsFournisseur,
                CodeFranchiseur: searchResultsClient,
                SearchNFournisseur: searchNFournisseur,
                search: searchResults,
                key: selectedKey,
                sort: selectedSort,
            }; ExportExcel(obj);

        }else if (event == 'clear') {
            setSelectedKey(null)
            setSearchResultsClient('')
            setSearchResultsFournisseur("")
            setSearchArticle("")
            setSelectedSort(null)
            setSelectedLabelSort('Filtrer...')
            setSelectedLabelClient('Franchiseur')
            setSelectedLabelFournisseur('Fournisseur')
            setSelectedTri('Trier...')
            setSearchDateCommande('')
            setSearchDateCommandeTo('')
            setSearchResults([])
            setPredicate('all', 'true')
            setSearchNFournisseur('')
            setSearchNCmd('')
        }
        else if (event == 'submit') {
            var predicate = new Map()
            predicate.set('search', searchResults)
            predicate.set("codeArticle", searchArticle);
            predicate.set('codeFranchiseur', searchResultsClient)
            predicate.set('codeFournisseur', searchResultsFournisseur)
            predicate.set('dateCommande', searchDateCommande)
            predicate.set("dateCommandeTo", searchDateCommandeTo);
            predicate.set("key", selectedKey);
            predicate.set("tri", selectedSort);
            predicate.set("searchNFournisseur", searchNFournisseur);
            predicate.set("orderRefs", searchNCmd);
            if (searchResults!.length > 0 || searchResultsClient !='' || searchResultsFournisseur != '' || selectedKey != '' || selectedSort != ''
                || searchDateCommande != '' || searchDateCommandeTo != '' || searchArticle != '' || searchNFournisseur != ''|| searchNCmd != '' )
                setPredicate('searchMultiple', predicate)
            else if (searchResults!.length == 0 && searchResultsClient == "" && searchResultsFournisseur == "" && selectedKey == "" && selectedSort == ""
                && searchDateCommande == "" && searchDateCommandeTo == "" && searchArticle == "" && searchNFournisseur == ""&& searchNCmd == "" )
                setPredicate('all', 'true')
        }

    }
    return (
        <FinalForm
            onSubmit={handleSearch}
            render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit} error style={{ width: "100%", fontSize: 14 }}>
                    <Grid>
                        <Grid.Row columns={5} style={{ padding: 0, margin: 0 }}>

                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <FormInput
                                    icon='search'
                                    type="text"
                                    placeholder="Facture"
                                    value={searchResults}
                                    onChange={handleChange}
                                    style={{ width: '100%' }}
                                />
                            </Grid.Column>         
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <FormInput
                                    icon='search'
                                    type="text"
                                    placeholder="N° FF"
                                    value={searchNFournisseur}
                                    onChange={handleChangeNFournisseur}
                                    style={{ width: '100%' }}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <FormInput
                                    icon='search'
                                    type="text"
                                    placeholder="N°CF"
                                    value={searchNCmd}
                                    onChange={handleChangeNCmd}
                                    style={{ width: '100%' }}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <FormInput
                                    icon='search'
                                    type="text"
                                    placeholder="Article"
                                    name="article"
                                    style={{ height: '100%' }}
                                    value={searchArticle}
                                    onChange={handleInputChangeArticle}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <AsyncSelect
                                    name="form-field-name"
                                    placeholder={selectedLabelClient}
                                    noOptionsMessage={() => "Code introuvable"}
                                    cacheOptions
                                    defaultOptions
                                    getOptionLabel={e => e!.text}
                                    value={searchResultsClient}
                                    loadOptions={baseStore.franchiseurStore.loadCompanyOptions}
                                    onChange={(e) => { setSearchResultsClient(e?.value); setSelectedLabelClient(e?.text) }}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>

                                <Select
                                    isSearchable
                                    isLoading={loadingListFournisseur}
                                    options={ITListFournisseur}
                                    getOptionLabel={e => e!.codeFournisseur + "-" + e!.fabricant}
                                    value={searchResultsFournisseur}
                                    placeholder={selectedLabelFournisseur}
                                    onChange={handleChangeFournisseur} />

                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <RangePicker
                                    presets={rangePresets}
                                    onChange={onRangeChange}
                                    format={'DD/MM/YYYY'}
                                    placeholder={["Début", "Fin"]}
                                    style={{ width: '100%', height: '39.201px' }}
                                />

                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable={false}
                                    components={{ DropdownIndicator }}
                                    styles={customStyles}
                                    options={options}
                                    value={selectedKey}
                                    getOptionLabel={e => e.label}
                                    onChange={(e) => handleFilter(e.key, e.label)}
                                    placeholder={selectedLabelSort}
                                    isClearable={true}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0, display: 'flex' }}>
                                <Select
                                    isSearchable={false}
                                    components={{ DropdownIndicator }}
                                    styles={customStyles}
                                    options={optionsTri}
                                    value={selectedSort}
                                    getOptionLabel={e => e.text}
                                    onChange={(e) => handleChangeTri(e)}
                                    placeholder={selectedLabelTri} />
                                <Button style={{ padding: 5, margin: "0 0 0 10px", color: 'blue', background: "none" }} type="submit" onClick={(e: any) => handleSearch('submit')} >
                                    <FaSearch />
                                </Button>
                                <Button style={{ float: "right", padding: 5, margin: 0, color: 'green', background: "none" }} >
                                    <MdCancel style={{ fontSize: 17 }} onClick={(e: any) => handleSearch('clear')} />
                                </Button>

                              
                            </Grid.Column >
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <Button
                                    size="tiny"
                                    style={{ paddingTop: 0, paddingBottom: 0, height: 32, float: "right" }}
                                    content="Export Excel"
                                    className="andi-btn filled success"
                                    icon={(<FaFileExcel style={{ marginRight: "10px" }} />)}
                                    onClick={(e: any) => handleSearch('clique')}
                                    loading={loadingExport }

                                />
                            </Grid.Column >
                        </Grid.Row>
            
                    </Grid>

                </Form >
            )}
        />
    );
}
export default observer(FactureFilter);