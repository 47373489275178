import { useContext, useState } from 'react';
import { Tab, Header, Image, Button, Grid } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import FileUploadWidget from '../../../app/common/fileUpload/FileUploadWidget';

const ArticleFichier = () => {
    const baseStore = useContext(BaseStoreContext)
    const { article, uploadFile, uploadingFile, deleteFile, deletingFile } = baseStore.articleStore;

    const [addFileMode, setAddFileMode] = useState(false);

    const handleUploadFile = (file: Blob, id = article!.productId) => {
        uploadFile(file, id).then(() => setAddFileMode(false));
    }
    const FichierPdf = '/assets/pdf.jpg';
    const FichierExcel = '/assets/excel.png';
    const FichierWord = '/assets/word.png';
    const FichierImage = '/assets/photo.png';
    const DefaultFichier = "/assets/file.png";

    function getpathimg(files: any) {
        if (files) {
            var extension = files.toLowerCase().substring(files.lastIndexOf('.') + 1)
            switch (extension) {
                case 'xls':
                case 'xlsx':
                    return FichierExcel;
                case 'doc':
                case 'docx':
                    return FichierWord;
                case 'pdf':
                    return FichierPdf;
                case 'jpg':
                case 'png':
                    return FichierImage;
                default:
                    return DefaultFichier;
            }
        } else {
            return DefaultFichier;
        }
    };

    return (
        <Tab.Pane>
            <Grid>
                <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                    <Header floated='left' icon='image' content='Fichiers' />
                    <Button
                        onClick={() => setAddFileMode(!addFileMode)}
                        floated='right'
                        basic
                        content={addFileMode ? 'Annuler' : 'Ajouter fichier'}
                    />
                </Grid.Column>
                <Grid.Column width={16}>
                    {addFileMode ? (
                        <FileUploadWidget
                            uploadFile={handleUploadFile}
                            loading={uploadingFile}
                        />
                    ) : (
                        <>

                            {article && article.fichiers &&
                                article.fichiers.map((fichier, key) => (
                                    <Grid style={{ border: '1px solid rgba(12, 12, 14, .15)', borderRadius: '0.28571429rem', boxShadow: '0 1px 2px 0 rgb(34 36 38 / 15%)', marginBottom: 5 }} >
                                        <Grid.Column width={2}><Image style={{ width: 30 }} src={getpathimg(fichier.url)} href={fichier.url} /> </Grid.Column>
                                        <Grid.Column width={6} style={{ textAlign: 'center', fontSize: 'medium', width: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", marginTop: 3 }}><a href={fichier.url}>{fichier.titre}</a>  </Grid.Column>
                                        <Grid.Column width={6} style={{ marginTop: 3 }}>{new Date(fichier.dateImportation).toLocaleString()}</Grid.Column>
                                        <Grid.Column width={2} > <span >
                                            <Button
                                                loading={deletingFile}
                                                basic
                                                negative
                                                icon='trash'
                                                style={{ padding: "3px 10px" }}
                                                onClick={(e) => { deleteFile(fichier, article!.productId); }}
                                            />
                                        </span>
                                        </Grid.Column>
                                    </Grid>
                                ))}
                        </>
                    )}
                </Grid.Column>
            </Grid>
        </Tab.Pane>
    );
};

export default observer(ArticleFichier);
