import { useContext, useEffect, useState } from 'react';
import { Tab, Header, Button, Grid, Table, Modal } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import { TbShoppingCartDiscount } from 'react-icons/tb';
import { FcLock } from 'react-icons/fc';
import { useParams } from 'react-router-dom';
import { IUserCompany } from '../../../app/models/IUser';
import AdduserModal from '../../user/AdduserModal';
import EditUserModal from '../../user/EditUserModal';
import { FaTrash, FaUserEdit } from 'react-icons/fa';
import UpdatePassword from '../../user/UpdatePassword';
import DeleteUser from '../../user/DeleteUser';

interface UrlParams {
    id: string;
}

const Utilisateur: React.FC = () => {
    const baseStore = useContext(BaseStoreContext)
    const { id } = useParams<UrlParams>();
    const { loadUser, utilisateurs, loadingIsMain, setMainContactUser } = baseStore.userStore;
    const [userCompany, setUserCompany] = useState<IUserCompany>();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpenAdd, setModalOpenAdd] = useState(false);
    const [modalOpenPassword, setModalOpenPassword] = useState(false);
    const [modalOpenTrash, setModalOpenTrash] = useState(false);
    const handleChangeMain = (user: IUserCompany) => {
        let userCompany: IUserCompany = {
            id: user.id,
            isMainContact: !user.isMainContact,
            user: user.user,
            companyId: parseInt(id)
        }
        setMainContactUser(userCompany);
    }
    useEffect(() => {
        loadUser(parseInt(id))
    }, [])
    return (
        <Tab.Pane>
            <Modal
                open={modalOpenAdd}
                size='large'
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >
                <Modal.Header icon='add' >
                    Ajouter utilisateur
                    <Button
                        style={{ height: 35 }}
                        type='button' icon='x' color='red'
                        floated='right'
                        onClick={() => { setModalOpenAdd(false) }}
                    >
                    </Button>
                </Modal.Header>
                <Modal.Content>
                    <AdduserModal id={parseInt(id)} setModalOpen={setModalOpenAdd} />
                </Modal.Content>
            </Modal>
            <Modal
                open={modalOpenPassword}
                size='mini'
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >
                <Modal.Header icon='add' >
                    Modifier mot de passe
                    <Button
                        style={{ height: 35 }}
                        type='button' icon='x' color='red'
                        floated='right'
                        onClick={() => { setModalOpenPassword(false) }}
                    >
                    </Button>
                </Modal.Header>
                <Modal.Content>
                    <UpdatePassword user={userCompany!} setModalOpen={setModalOpenPassword} />
                </Modal.Content>
            </Modal>
            <Modal
                open={modalOpen}
                size='large'
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >
                <Modal.Header icon='add' >
                    Modifier l'utilisateur
                    <Button
                        style={{ height: 35 }}
                        type='button' icon='x' color='red'
                        floated='right'
                        onClick={() => { setModalOpen(false) }}
                    >
                    </Button>
                </Modal.Header>
                <Modal.Content>
                    <EditUserModal user={userCompany} setModalOpen={setModalOpen} />
                </Modal.Content>
            </Modal>
            <Modal
                open={modalOpenTrash}
                size='mini'
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >
                <Modal.Header icon='add' >
                    Supprimer utiliasteur
                    <Button
                        style={{ height: 35 }}
                        type='button' icon='x' color='red'
                        floated='right'
                        onClick={() => { setModalOpenTrash(false) }}
                    >
                    </Button>
                </Modal.Header>
                <Modal.Content>
                    <DeleteUser id={parseInt(id)} user={userCompany!} setModalOpen={setModalOpenTrash} />
                </Modal.Content>
            </Modal>
            <Grid>
                <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                    <Header floated='left' icon={<TbShoppingCartDiscount style={{ marginRight: 10, marginTop: 4 }} />} content="Utilisateurs" />
                    <Button
                        onClick={() =>
                            setModalOpenAdd(true)}
                        floated='right'
                        basic
                        content={'Ajouter utilisateur'}
                    />
                </Grid.Column>
                <Grid.Column width={16}>

                    <Table loading={false} celled style={{ textAlign: "center" }}>
                        <Table.Header >
                            <Table.Row>
                                <Table.HeaderCell>Nom et Prénom</Table.HeaderCell>
                                <Table.HeaderCell>Email</Table.HeaderCell>
                                <Table.HeaderCell>Tel. Fixe</Table.HeaderCell>
                                <Table.HeaderCell>Tel. Mobile</Table.HeaderCell>
                                <Table.HeaderCell>Principal</Table.HeaderCell>
                                <Table.HeaderCell style={{ textAlign: "center" }}>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        {utilisateurs &&
                            utilisateurs.map(user => (
                                <Table.Body>
                                    <Table.Cell>{user.user.lastName} {user.user.firstName}</Table.Cell>
                                    <Table.Cell>{user.user.email}</Table.Cell>
                                    <Table.Cell>{user.user.phone}</Table.Cell>
                                    <Table.Cell>{user.user.mobile}</Table.Cell>
                                    <Table.Cell><input type="checkbox" disabled={loadingIsMain} checked={user.isMainContact} onClick={() => handleChangeMain(user)} name="isMainContact" />
                                    </Table.Cell>
                                    <Table.Cell style={{ display: "flex" }}>
                                        <span onClick={(e) => { setUserCompany(user); setModalOpen(true); }}>
                                            <FaUserEdit color="#bc8f8f" style={{ fontSize: 20 }} />
                                        </span>
                                        <span onClick={(e) => { setUserCompany(user); setModalOpenPassword(true) }}>
                                            <FcLock style={{ fontSize: 20 }} />
                                        </span>
                                        <span >
                                            <FaTrash style={{ fontSize: 18 }} color="black" onClick={(e) => { setUserCompany(user); setModalOpenTrash(true) }} />
                                        </span>

                                    </Table.Cell>
                                </Table.Body>
                            ))}
                    </Table>

                </Grid.Column>
            </Grid>
        </Tab.Pane>
    );
};
export default observer(Utilisateur);
