import React, { useContext } from 'react'
import { Tab } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import CommandeFournisseurAdresses from './CommandeFournisseurAdresses';
import CommandeFournisseurArticle from './CommandeFournisseurArticle';
import CommandeFournisseurDescription from './CommandeFournisseurDescription';
import ObjetLier from './RelatedObject';

const panes = [
    { menuItem: 'Général', render: () => <CommandeFournisseurDescription /> },
    { menuItem: 'Article(s)', render: () => <CommandeFournisseurArticle /> },
    { menuItem: 'Adresses Fournisseur', render: () => <CommandeFournisseurAdresses /> },
    { menuItem: 'Objets Liés', render: () => <ObjetLier /> },
]
interface IProps {
    setActiveTab: (activeIndex: any) => void;
}
const CommandeFournisseurContent: React.FC<IProps> = ({ setActiveTab }) => {
    const baseRepo = useContext(BaseStoreContext);
    return (
        <Tab
            menu={{ fluid: true, vertical: true }}
            menuPosition='right'
            activeIndex={baseRepo.commandeFournisseurStore.activeTab}
            panes={panes}
            onTabChange={(e, data) => setActiveTab(data.activeIndex)}
        />
    )
}

export default observer(CommandeFournisseurContent)