import { BaseStore } from "./baseStore";
import agent from '../agent/agent';
import { observable, action, runInAction } from 'mobx';
import { ICountryCode } from "../models/ICountryCode";

export default class CountryCodeStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
    }

    getCountryCodes = async (search: string | null) => {
        if (!search || !search.trim() || search.length < 1) {
            return await agent.countryCodeAgent.liste("fr").then(r => r.data);
        } else {
            return await agent.countryCodeAgent.liste(search).then(r => r.data);;
        }
    };
}