import React, { useContext } from 'react';
import { Tab, Header, Button, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import NotFound from '../../../app/layout/NoFound';
import DevisAdressesEditForm from './DevisAdressesEditForm';
import FormGridColumn from '../../../app/common/form/FormGridColumn';

const DevisAdresses = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { devis, submitting } = baseRepo.devisStore;
    if (!devis) {
        return <NotFound />
    }
    else {
        return (
            <Tab.Pane>
                <Grid>
                    <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                        <Header floated='left' icon='map marker alternate' content='Adresses du Devis' />
                        {devis.quoteState == 'EnAttente' &&
                            <Button
                                onClick={() => { baseRepo.devisStore.changeButtonState(!baseRepo.devisStore.editDevisMode) }}
                                floated='right'
                                basic
                                content={baseRepo.devisStore.editDevisMode ? 'Annuler' : 'Modifier'}
                            />}
                    </Grid.Column>
                    <Grid.Column width={16}>
                        {baseRepo.devisStore.editDevisMode ? (
                            <DevisAdressesEditForm devis={devis!} submitting={submitting} />
                        ) : (
                            <Grid className="ui stackable">
                                <Grid.Row style={{ padding: 0, margin: 0 }}>
                                    <FormGridColumn header="Adresse de facturation" content={devis!.invoiceAddress1} />
                                    <FormGridColumn header="Adresse de livraison" content={devis!.deliveryAddress1} />
                                    <FormGridColumn header="Complément d’adresse facturation" content={devis!.invoiceAddress2} />
                                    <FormGridColumn header="Complément d’adresse livraison" content={devis!.deliveryAddress2} />
                                    <FormGridColumn header="ville de facturation" content={devis!.invoiceCity} />
                                    <FormGridColumn header="ville de livraison" content={devis!.deliveryCity} />
                                    <FormGridColumn header="Code postal facturation" content={devis!.invoicePostalCode} />
                                    <FormGridColumn header="Code postal livraison" content={devis!.deliveryPostalCode} />
                                </Grid.Row>
                            </Grid>
                        )}
                    </Grid.Column>
                </Grid>
            </Tab.Pane>
        );
    }
}
export default observer(DevisAdresses);