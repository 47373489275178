import { BaseStore } from "./baseStore";
import agent from '../agent/agent';
import { observable, action, runInAction } from 'mobx';
import { ICityCode } from "../models/ICityCode";

export default class CityCodeStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
    }

    getCityCodes = async (search: string | null) => {
        if (!search || !search.trim() || search.length < 3) {
            return await agent.cityCodeAgent.liste(null).then(r => r.data);
        } else
            return await agent.cityCodeAgent.liste(search).then(r => r.data);
    };
}