import { useContext, useEffect, useState } from 'react';
import { Tab, Header, Button, Grid, Table, Progress, Modal } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import DetailQuoteLine from './DetailQuoteLine';
import AddDevisArticle from './AddDevisArticle';
import DeleteQuoteLineModal from './DeleteQuoteLineModal';
import AucunProduit from '../../commande/detailCommande/AucunProduit';
import EditDevisArticle from './EditDevisArticle';
import { IOrderLine } from '../../../app/models/ICommande';

const DevisArticle = () => {
    const baseStore = useContext(BaseStoreContext)
    const { devis, deletingDevisArticle, progress, submitting, quoteLine } = baseStore.devisStore;
    const { packs, loadListe } = baseStore.articleComposeStore;
    const [addArticleMode, setAddArticleMode] = useState(false);
    const [detailArticleMode, setDetailArticleMode] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState<number | undefined>(undefined);
    const { openModal } = baseStore.modalStore;
    const [modalOpen, setModalOpen] = useState(false);
    const [quoteline, setQuoteLine] = useState<IOrderLine>();
    useEffect(() => {
        loadListe();
    }, [])
    return (
        <Tab.Pane>
            <Modal
                open={modalOpen}
                size='large'
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >
                <Modal.Header icon='add' >
                    Modifier l'article
                    <Button
                        style={{ height: 35 }}
                        type='button' icon='x' color='red'
                        floated='right'
                        onClick={() => { setModalOpen(false) }}
                    >
                    </Button>
                </Modal.Header>
                <Modal.Content>
                    <EditDevisArticle quoteLine={quoteline!} devis={devis!} setModalOpen={setModalOpen} />
                </Modal.Content>
            </Modal>
            {detailArticleMode ? (
                <Grid>
                    <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                        <Header floated='left' icon='file' content='Detail ligne de commande:' />
                        <Button
                            onClick={() => setDetailArticleMode(!detailArticleMode)}
                            floated='right'
                            basic
                            content={"Retour"}
                        />
                    </Grid.Column>
                    &nbsp;
                    &nbsp;
                    <DetailQuoteLine quote={quoteLine!} />
                </Grid>
            ) : (
                <Grid>
                    <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                        <Header floated='left' icon='shopping basket' content='Produits' />
                        {devis!.quoteState == 'EnAttente' &&
                            <Button
                                onClick={() => setAddArticleMode(!addArticleMode)}
                                floated='right'
                                basic
                                content={addArticleMode ? 'Annuler' : 'Ajouter produit'}
                            />}
                    </Grid.Column>
                    <Grid.Column width={16}>
                        {addArticleMode ? (
                            <AddDevisArticle
                                devisId={devis!.id}
                                setAddArticleMode={setAddArticleMode}
                            />
                        ) : (
                            <>
                                {submitting &&
                                    <Progress color='orange' percent={progress} size='tiny' />
                                }
                                {devis?.orderLines.length == 0 ? (
                                    <AucunProduit />
                                ) :
                                    (
                                        <Table loading={false} celled>
                                            <Table.Header>
                                                <Table.Row style={{ textAlign: "center" }}>
                                                    <Table.HeaderCell>Article</Table.HeaderCell>
                                                    <Table.HeaderCell>Quantité</Table.HeaderCell>
                                                    <Table.HeaderCell>PU HT.</Table.HeaderCell>
                                                    <Table.HeaderCell>Remise</Table.HeaderCell>
                                                    <Table.HeaderCell>Montant HT</Table.HeaderCell>
                                                    {devis!.quoteState == 'EnAttente' &&
                                                        <Table.HeaderCell style={{ textAlign: "center" }}>Actions</Table.HeaderCell>}
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {devis &&
                                                    devis.orderLines.map(quoteLine => (
                                                        <>
                                                            <Table.Row key={quoteLine.productId}>
                                                                <Table.Cell rowspan={quoteLine.comment == "" || quoteLine.comment == null ? 1 : 2}>
                                                                    <a style={{ backgroundColor: 'none' }} href={`/detailArticle/${quoteLine.productId}`}>
                                                                        {quoteLine.articleCode}-{quoteLine.articleName}
                                                                    </a>
                                                                    {packs &&
                                                                        packs.map(pack => (
                                                                            <>
                                                                                {pack.idPack == quoteLine.articleCode &&
                                                                                    <>
                                                                                        <br />
                                                                                        <span style={{ marginLeft: "8px", color: 'royalblue', cursor: 'none' }}>{pack.article}-{pack.articleLibelle}</span>
                                                                                    </>
                                                                                }
                                                                            </>
                                                                        ))}
                                                                    {quoteLine.productAttributeValues.map(productAttributeValue => (
                                                                        <>
                                                                            < br />
                                                                            <p><b>{productAttributeValue.productAttributeName} :</b>
                                                                                {productAttributeValue.value}</p>
                                                                        </>
                                                                    ))
                                                                    }
                                                                </Table.Cell>
                                                                <Table.Cell>{quoteLine.quantity}</Table.Cell>
                                                                <Table.Cell>{quoteLine.unitPrice.toFixed(2)} €</Table.Cell>
                                                                <Table.Cell>{quoteLine.remise} %</Table.Cell>
                                                                <Table.Cell>{quoteLine!.totalPrice.toFixed(2)} €</Table.Cell>
                                                                {devis.quoteState == 'EnAttente' &&
                                                                    <Table.Cell>
                                                                        <Button.Group fluid widths={2}>
                                                                            <Button
                                                                                onClick={(e) => {
                                                                                    setModalOpen(true);
                                                                                    setQuoteLine(quoteLine);
                                                                                }}
                                                                                basic
                                                                                color="blue"
                                                                                icon='edit outline'
                                                                                name={quoteLine.id}
                                                                                style={{ marginRight: '2%' }}
                                                                            />
                                                                            <Button
                                                                                name={quoteLine.productId}
                                                                                onClick={(e) => {
                                                                                    openModal(<DeleteQuoteLineModal devisId={quoteLine!.id} quoteLine={quoteLine!} />)
                                                                                    setDeleteTarget(parseInt(e.currentTarget.name))
                                                                                }}
                                                                                loading={deletingDevisArticle && deleteTarget === quoteLine.productId}
                                                                                basic
                                                                                negative
                                                                                icon='trash'
                                                                            />
                                                                        </Button.Group>
                                                                    </Table.Cell>
                                                                }

                                                            </Table.Row>
                                                            {quoteLine.comment != "" && quoteLine.comment != null &&
                                                                <Table.Row style={{ marginLeft: 5, }}>
                                                                    <Table.Cell colspan={5} style={{ borderTop: 'none', padding: 0, fontSize: 12, fontWeight: 700 }}>
                                                                        <div style={{ padding: '3px 0 0 10px', borderLeft: '1px solid rgba(34,36,38,.1)', borderTop: '1px solid rgba(34,36,38,.1)', }}>
                                                                            {quoteLine.comment}
                                                                        </div>
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            }
                                                        </>
                                                    ))}
                                            </Table.Body>
                                        </Table>)}
                            </>
                        )}
                    </Grid.Column>
                </Grid>
            )}
        </Tab.Pane>
    );
};
export default observer(DevisArticle);
