import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Grid, Radio, Segment } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import { history } from '../../../index';
import { BaseStoreContext } from '../../../app/store/baseStore';
import Select from "react-select";
import { observer } from 'mobx-react-lite';
import { IFactureFournisseurAdd } from '../../../app/models/IFactureFournisseur';
import AsyncSelect from 'react-select/async';

const AddFactureContent: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { create, submitting } = baseStore.factureFournisseurStore;
    const { loadFournisseurByPurchasePrice, ITListFournisseur, loadingInitial } = baseStore.commandeFournisseurStore;
    const [typeFacture, setTypeFacture] = useState<string>("Facture");
    const [fournisseurRadioOn, setFournisseurRadioOn] = useState(true);
    const [fournisseur, setFournisseur] = useState(0);
    const [franchiseurRadioOn, setFranchiseurRadioOn] = useState(false);
    const [franchiseur, setFranchiseur] = useState<any>(null);
    const [selectedLabel, setSelectedLabel] = useState<any>("Franchiseur");

    const handleFinalFormSubmit = () => {
        let newFacture: IFactureFournisseurAdd = {
            InvoiceType: typeFacture,
            FournisseurType: franchiseurRadioOn?'Franchiseur':'Fournisseur',
            Fournisseur: fournisseur! ,
            Company: franchiseur!

        };
        create(newFacture);
    }
    useEffect(() => {
        loadFournisseurByPurchasePrice("Add");
    }, [])
    return (
        <Segment style={{ backgroundColor: 'rgb(255,255,255)', padding: '20px' }} >
            <FinalForm
                onSubmit={handleFinalFormSubmit}
                render={({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit} error >

                        <Grid style={{ marginTop: 20, marginBottom: 10 }}>
                            <h5>Type Fournisseur: </h5>
                            <Radio
                                label='Fournisseur'
                                name='Fournisseur'
                                value='Fournisseur'
                                checked={fournisseurRadioOn}
                                onClick={() => { setFournisseurRadioOn(true); setFranchiseurRadioOn(false); setFranchiseur(null) }}
                            />
                            &nbsp;
                            &nbsp;
                            <Radio
                                label='Franchiseur'
                                name='Franchiseur'
                                value='Franchiseur'
                                checked={franchiseurRadioOn}
                                onClick={() => {setFournisseurRadioOn(false); setFranchiseurRadioOn(true); setFournisseur(0) }}
                            />
                        </Grid>
                        <Grid>
                            <Grid.Column width={10}>
                                {fournisseurRadioOn &&
                                    <div className="field">
                                        <h5>Fournisseur</h5>
                                        <Select
                                            isSearchable
                                            isLoading={loadingInitial}
                                            options={ITListFournisseur}
                                        getOptionLabel={e => e!.codeFournisseur + "-" + e!.fabricant}
                                        getOptionValue={e => e!.fournisseurId.toString()}
                                            placeholder="Fournisseur"
                                            onChange={(e) => { setFournisseur(e!.fournisseurId) }}
                                        />
                                    </div>}
                                {franchiseurRadioOn &&
                                    <div className="field">
                                         <h5>Franchiseur </h5>

                                    <AsyncSelect
                                        name="form-field-name"
                                        placeholder={selectedLabel}
                                        noOptionsMessage={() => "Code introuvable"}
                                        cacheOptions
                                        defaultOptions
                                        getOptionLabel={e => e!.text}
                                        value={franchiseur}
                                        loadOptions={baseStore.franchiseurStore.loadCompanyOptions}
                                        onChange={(e) => { setFranchiseur(e?.value); setSelectedLabel(e?.text) }}
                                    />
                                    </div>}
                                
                                <h5>Type Facture : </h5> <br />

                                <Form.Group>
                                    <Radio
                                        label='Facture'
                                        value='Facture'
                                        checked={typeFacture == 'Facture'}
                                        onChange={() => setTypeFacture("Facture")}
                                    />
                                </Form.Group>

                            </Grid.Column>
                            <Grid.Column width={6}>
                                <Form.Group style={{ paddingTop: '100px', paddingLeft: '120px' }}>
                                    <Button.Group floated="right">
                                        <Button onClick={() => history.push('/factureFournisseurDashboard')} floated='right' type='button'>Annuler</Button>
                                        <Button.Or />
                                        <Button positive disabled={fournisseurRadioOn ? fournisseur == 0 : franchiseur == null} loading={submitting} floated='right' type='submit'
                                            onClick={() => { }}
                                        >Confirmer
                                        </Button>
                                    </Button.Group>
                                </Form.Group>
                            </Grid.Column>
                        </Grid>
                    </Form>
                )}
            />
        </Segment>
    );
}
export default observer(AddFactureContent);