import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { Button, Header, Icon, Container, Grid, Loader, Dimmer } from 'semantic-ui-react';
import { getType } from '../../../app/common/util/util';
import { BaseStoreContext } from '../../../app/store/baseStore';

interface IProps {
    Id: number;
}

const DeleteConfimationModal: React.FC<IProps> = ({ Id }) => {
    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    const { deleteReception } = baseStore.receptionStore;
    const { loadRelatedElements, loadingRelatedElements, relatedElements } = baseStore.commandeFournisseurStore;

    useEffect(() => {
        loadRelatedElements(Id, "Reception");
    }, []);

    return (
        <Container textAlign='center'>

            <Grid>
                <Grid.Row>
                    <Header icon='trash' content='Suppression de la réception' style={{ marginLeft: 20 }} />
                </Grid.Row>
                <Grid.Row>
                    {loadingRelatedElements ?
                        <div style={{ padding: "20px 0" }}>
                            <Dimmer active inverted >
                                <Loader inverted ></Loader>
                            </Dimmer>
                        </div>
                        :
                        (
                            <>
                                {relatedElements.length != 0 &&
                                    <>
                                        <h6 style={{ color: "#db2828" }}>Les objets liès qui seront supprimé avec cette réception</h6>
                                        <br />
                                        {relatedElements && relatedElements.map((item) =>
                                            <span>{getType(item.type)} : <b style={{ color: "#db2828" }}>{item.reference}</b></span>
                                        )}
                                        <br />
                                        <br />
                                    </>
                                }
                                <p>
                                    Voulez vous supprimer cette réception ?
                                </p>
                                <br />
                                <br />

                                <Button.Group fluid>
                                    <Button onClick={() => closeModal()}>
                                        <Icon name='remove' /> Annuler
                                    </Button>
                                    <Button.Or text='Ou' />
                                    <Button
                                        color='green'
                                        onClick={(e) => {
                                            deleteReception(Id);
                                            closeModal()
                                        }}>
                                        <Icon name='checkmark' /> Confirmer
                                    </Button>
                                </Button.Group>
                            </>
                        )
                    }
                </Grid.Row>
            </Grid>
        </Container>
    )
}
export default observer(DeleteConfimationModal);
