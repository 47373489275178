import React, { useContext, useEffect, useState } from 'react';
import { Form, Item, Table, Button, Header, Progress } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import Loading from '../../../app/layout/Loading';
import { observer } from 'mobx-react-lite';
import TextInput from '../../../app/common/form/inputText';
import OptionForm from './AddOptionModal';
import DeleteOptionModal from './DeleteOptionModal';

const ListOption: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { loadOptions, loadingInitial, edit, options, loadingOptionAction, progress, submittingOption } = baseStore.optionStore;
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    const [editTarget, setEditTarget] = useState<string | undefined>(undefined);
    const [editForm, setEditForm] = useState(false);
    const [libelle, setLibelle] = useState<string | undefined>(undefined);
    const { openModal } = baseStore.modalStore;

    const handleFinalFormSubmit = () => {

        let newOption = {
            libelle: libelle,
            optionId: editTarget,

        };
        edit(newOption);
        setEditForm(false)
    }
    useEffect(() => {
        loadOptions();
    }, []);

    if (loadingInitial) return <Loading content="Chargement des options ..." />

    return (
        <div>
            <Item.Group>
                <Item>
                    <Item.Content>
                        <Header as='h2'>{'Options'}</Header>
                        <Button
                            floated="right"
                            content={baseStore.optionStore.addMode ? 'Annuler' : 'Ajouter options'}
                            color="orange"
                            icon='arrow alternate circle left outline'
                            basic
                            onClick={() => { baseStore.optionStore.changeButtonStateAdd(!baseStore.optionStore.addMode) }}
                        />
                    </Item.Content>
                </Item>
            </Item.Group>
            {baseStore.optionStore.addMode &&
                <div>
                    <OptionForm />
                    <br />
                </div>
            }
            {submittingOption &&
                <Progress color='orange' percent={progress} size='tiny' />
            }
            <Table loading={false} celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Libelle</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {options && options.map((option: any) => (
                        <Table.Row key={option.optionId}>
                            {editForm && editTarget === option.optionId ? (
                                <>
                                    <Table.Cell selectable>
                                        <Form.Input name='libelle' component={TextInput} placeholder='libelle' defaultValue={option.libelle} style={{ width: '100%', padding: '1%' }} onChange={event => setLibelle(event.target.value)} />
                                    </Table.Cell>
                                    <Table.Cell selectable>
                                        <Button.Group fluid widths={2}>
                                            <Button
                                                type='button'
                                                onClick={() => {
                                                    setEditForm(false);
                                                }}>
                                                Annuler
                                            </Button>
                                            <Button.Or />
                                            <Button
                                                name={option.optionId}
                                                positive
                                                type='submit'
                                                onClick={(e) => {
                                                    handleFinalFormSubmit()
                                                    setEditTarget(e.currentTarget.name)
                                                }}
                                            >Confirmer</Button>
                                        </Button.Group>
                                    </Table.Cell>
                                </>
                            ) : (
                                <>
                                        <Table.Cell>{option.libelle}</Table.Cell>
                                    <Table.Cell >
                                        <Button.Group fluid widths={2}>
                                            <Button
                                                onClick={(e) => {
                                                    setEditForm(true);
                                                    setEditTarget(e.currentTarget.name)
                                                        setLibelle(option.libelle)
                                                }}
                                                color="blue"
                                                icon='edit outline'
                                                style={{ marginRight: '3%' }}
                                                    name={option.optionId}
                                            />
                                            <Button
                                                    name={option.optionId}
                                                basic
                                                color='red'
                                                icon='trash'
                                                onClick={(e) => {
                                                    openModal(<DeleteOptionModal option={option} />)
                                                    setDeleteTarget(e.currentTarget.name)
                                                }}
                                                    loading={loadingOptionAction && deleteTarget === option.optionId}
                                            />
                                        </Button.Group>
                                    </Table.Cell>
                                </>
                            )}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
}
export default observer(ListOption);