import React, { } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { Form, Button, Grid, Input, Select } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { history } from '../../../index';
import { ITListDeliveryService } from '../../../app/common/options/IAchat';
import { ICommandeFournisseur } from '../../../app/models/ICommandeFournisseur';
import { useState } from 'react';

interface IProps {
    editCommandeFournisseur: (commandeFournisseur: Partial<ICommandeFournisseur>) => void;
    commandeFournisseur: ICommandeFournisseur;
    submitting: boolean;
}

const columnStyle = {
    marginBottom: 0,
    paddingBottom: 0,
    paddingTop: 0
}
const CommandeFournisseurEditForm: React.FC<IProps> = ({ editCommandeFournisseur, commandeFournisseur, submitting }) => {
    const [numCF, setNumCF] = useState<string>(commandeFournisseur.numSupplierOrder);
    const [service, setService] = useState<string>(commandeFournisseur.deliveryService);
    const [delivery, setDelivery] = useState<string>(commandeFournisseur.deliveryCompany);
    const [comment, setComment] = useState<string>(commandeFournisseur.comment);
    const [refInternal, setRefInternal] = useState<string>(commandeFournisseur.internalRef);
    const [dateCommande, setDateCommande] = useState<any>(new Date(commandeFournisseur.orderDate).toISOString().split('T')[0]);
    const [amountWithoutTaxes, setAmountWithoutTaxes] = useState<number>(commandeFournisseur.amountWithoutTaxes);
    const [amountWithTaxes, setAmountWithTaxes] = useState<number>(commandeFournisseur.amountWithTaxes);
    const [network, setNetWork] = useState<string>(commandeFournisseur.network);

    const handleFinalFormSubmit = (values: any) => {
        let newCommande: ICommandeFournisseur = {
            ...values,
            numSupplierOrder: numCF,
            deliveryService: service,
            deliveryCompany: delivery,
            comment: comment,
            internalRef: refInternal,
            orderDate: dateCommande,
            amountWithoutTaxes: amountWithoutTaxes,
            amountWithTaxes: amountWithTaxes,
            network: network
        };
        editCommandeFournisseur(newCommande);
    }
    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            initialValues={commandeFournisseur!}
            render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit} error>
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column style={columnStyle}>
                                <h5>N° commande fournisseur</h5>
                                <Input name='numSupplierOrder' placeholder='N° commande fournisseur' defaultValue={commandeFournisseur.numSupplierOrder} onChange={(e) => setNumCF(e.target.value)} />
                            </Grid.Column>
                            <Grid.Column style={columnStyle}>
                                <h5>Référence interne</h5>
                                <Input name='internalRef' placeholder='Référence interne' defaultValue={commandeFournisseur.internalRef} onChange={(e) => setRefInternal(e.target.value)} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column style={columnStyle}>
                                <h5>Transporteur</h5>
                                <Select fluid name='deliveryService' options={ITListDeliveryService} value={service} onChange={(e: any) => setService(e.target.innerText)} />
                            </Grid.Column>
                            <Grid.Column style={columnStyle}>
                                <h5>Livraison</h5>
                                <Input name='deliveryCompany' placeholder='Livraison' defaultValue={commandeFournisseur.deliveryCompany} onChange={(e) => setDelivery(e.target.value)} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column style={columnStyle}>
                                <h5>Commentaire</h5>
                                <Input name='comment' placeholder='Commentaire' defaultValue={commandeFournisseur.comment} onChange={(e) => setComment(e.target.value)} />
                            </Grid.Column>
                            <Grid.Column style={columnStyle}>
                                <h5>Date Commande</h5>
                                <Input
                                    type="date"
                                    name="orderDate"
                                    defaultValue={new Date(commandeFournisseur.orderDate).toISOString().split('T')[0]}
                                    onChange={(e) => setDateCommande(e.target.value)}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column style={columnStyle}>
                                <h5>Montant Ht</h5>
                                <Input name='amountWithoutTaxes' type='number' min={0} step="0.01" defaultValue={commandeFournisseur.amountWithoutTaxes} onChange={(e) => setAmountWithoutTaxes(parseFloat(e.target.value))} />
                            </Grid.Column>
                            <Grid.Column style={columnStyle}>
                                <h5>Montant TTC</h5>
                                <Input name='amountWithTaxes' type='number' min={0} step="0.01" defaultValue={commandeFournisseur.amountWithTaxes} onChange={(e) => setAmountWithTaxes(parseFloat(e.target.value))} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column style={columnStyle}>
                                <h5>Réseau</h5>
                                <Input name='network' placeholder='Réseau' defaultValue={commandeFournisseur.network} onChange={(e) => setNetWork(e.target.value)} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <div style={{ marginTop: '20px' }}>
                        <Button.Group floated="right">
                            <Button disabled={submitting} onClick={() => history.push('/commandeFournisseurDashboard')} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </div>
                </Form>
            )}
        />
    );
};

export default observer(CommandeFournisseurEditForm);