import React from 'react';
import { Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import AddArticleContent from './AddArticleContent';
import AddArticleHeader from './AddArticlesHeader';
import Breadcrumbs from '../../dashboard/breadcrumbs';


const AddArticlePage: React.FC = ({ }) => {
    return (
        <div>
            <Breadcrumbs
                items={[
                    { key:0,name: "Articles", root: "" },
                    { key:1,name: "Liste articles", root: "/articles" },
                    { key:2,name: "Ajouter article", root: "/addArticles" },
                ]}
            />
        <Grid>
            <Grid.Row>           
                <Grid.Column width={16}>
                    <Grid style={{ marginTop: 2 }}>
                        <Grid.Column width={16}>
                            <AddArticleHeader />
                            <AddArticleContent />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
         
            </Grid.Row>
        </Grid>
        </div>
    );
}

export default observer(AddArticlePage);