import React, { useContext } from 'react';
import { Button, GridRow, GridColumn, Table } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../app/store/baseStore';
import { Grid } from '@mui/material';
import ExportListFiltre from './ExportListFiltre';
import { useEffect } from 'react';
import { IInvoiceDto, ISearch } from '../../app/models/IFacture';

const numberFormat = new Intl.NumberFormat('FR-fr', {
    style: 'currency',
    currency: 'EUR',
    useGrouping: true,
});

const ExportList: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { invoices } = baseStore.factureStore;
    const { loadListesFacturesExporte } = baseStore.factureStore;

    useEffect(() => {
        var today = new Date();
        var priorDate = new Date(new Date().setDate(today.getDate() - 30));
        let newSearch: ISearch = {
            searchDateCommande: priorDate.toLocaleString(),
            searchDateCommandeTo: new Date().toLocaleString(),
            searchInvoiceResults: [],
            selectedAmount: 0,
            selectedClient: 0,
            searchResultsClientAll: false,
            searchNCC: '',
            selectedAmountTtc: 0,
            modePaiement:''
        };
        loadListesFacturesExporte(newSearch);
    }, [])
    return (
        <>
            <Grid style={{ padding: '20px' }}>
                {/*<GridRow style={{ width: "100%" }}>*/}
                {/*    <GridColumn style={{ padding: 0, margin: 0, marginRight: 20 }}>*/}
                {/*        <DownloadTableExcel*/}
                {/*            filename="users table"*/}
                {/*            sheet="users"*/}
                {/*            currentTableRef={tableRef.current}*/}
                {/*        >*/}
                {/*            <Button*/}
                {/*                style={{ textAlign: 'right', justifyContent: 'center', height: '100%', marginBottom: "5px" }}*/}
                {/*                content="Export excel"*/}
                {/*                floated="right"*/}
                {/*                disabled={invoices.length == 0}*/}
                {/*                className="andi-btn filled success"*/}
                {/*                icon={(<FaFileExcel style={{ marginRight: "10px", fontSize: "20px" }} />)}*/}
                {/*            />*/}


                {/*        </DownloadTableExcel>*/}
                {/*    </GridColumn>*/}
                {/*</GridRow>*/}
                <GridRow style={{ padding: 0, margin: "0 20px 0 30px" }}>
                    <ExportListFiltre />
                </GridRow>

                <GridColumn width={16} style={{ marginTop: 2 }}>
                    <Grid style={{ marginTop: 2, display: 'flex', flexWrap: 'nowrap', overflowX: 'scroll' }} >
                        <GridColumn width={16}>

                            <Table compact='very' style={{ marginTop: '0em' }} textAlign='center'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Type</Table.HeaderCell>
                                        <Table.HeaderCell>Date</Table.HeaderCell>
                                        <Table.HeaderCell>Réf</Table.HeaderCell>
                                        <Table.HeaderCell>N°CC</Table.HeaderCell>
                                        <Table.HeaderCell>En Attente De Paiement</Table.HeaderCell>
                                        <Table.HeaderCell>Total HT</Table.HeaderCell>
                                        <Table.HeaderCell>Frais port</Table.HeaderCell>
                                        <Table.HeaderCell>Total TVA</Table.HeaderCell>
                                        <Table.HeaderCell>Total TTC</Table.HeaderCell>
                                        <Table.HeaderCell>Code Tiers</Table.HeaderCell>
                                        <Table.HeaderCell>Tiers</Table.HeaderCell>
                                        <Table.HeaderCell>Pays</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {invoices && invoices.map((facture: IInvoiceDto) => (
                                        <Table.Row key={facture.id} style={{ padding: '8px', textAlign: 'center', color: 'black' }}>
                                            <Table.Cell>{"Facture"}</Table.Cell>
                                            <Table.Cell>{new Date(facture.invoiceDate).toLocaleDateString('en-GB')}</Table.Cell>
                                            <Table.Cell>
                                                <span><a style={{ background: "none", cursor: 'pointer' }} href={`/detailFacture/${facture.id}`}><b>{facture.reference}</b></a></span>
                                            </Table.Cell>
                                            <Table.Cell>{facture.orderRefs}</Table.Cell>
                                            <Table.Cell>{facture.reference.startsWith("AV") ? numberFormat.format(0) : numberFormat.format(facture.remainingPayment)}</Table.Cell>
                                            <Table.Cell>{facture.reference.startsWith("AV") ? (facture.amountWithoutTaxes*-1).toFixed(2): facture.amountWithoutTaxes.toFixed(2)}</Table.Cell>
                                            <Table.Cell>{facture.deliveryCosts}</Table.Cell>
                                            <Table.Cell>{(facture.amountWithTaxes - facture.amountWithoutTaxes).toFixed(2)}</Table.Cell>
                                            <Table.Cell>{facture.reference.startsWith("AV") ? (facture.amountWithTaxes * -1).toFixed(2) : facture.amountWithTaxes.toFixed(2)}</Table.Cell>
                                            <Table.Cell>{facture.agencyName}</Table.Cell>
                                            <Table.Cell>{facture.companyName}</Table.Cell>
                                            <Table.Cell>{facture.deliveryCountry}</Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        </GridColumn>
                    </Grid>
                </GridColumn>
            </Grid>
        </>
    );
}
export default observer(ExportList);