import React from 'react'
import { Message } from 'semantic-ui-react'

const AucunFournisseur = () => (
    <div style={{ marginTop: '50px' }}>
        <Message
            error
            list={[
                "Aucun fournisseur pour le moment",
                "Veuillez ajouter des fournisseur pour accèder à la liste",
            ]}
        />
    </div>
)

export default AucunFournisseur
