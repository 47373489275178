import { BaseStore } from "./baseStore";
import { observable, action, runInAction, computed, reaction } from 'mobx';
import agent from '../agent/agent';
import { toast } from "react-toastify";
import { IReglement, IReglementList } from "../models/IReglement";
const LIMIT = 20;

export default class ReglementStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0;
                this.loadReglements();
            }
        )
    }
    @observable loadingReglementByFranchiseur = false;
    @observable loadingReglementByOrder = false;
    @observable loadingReglementByInvoice = false;
    @observable loadingReglementNotConsumed = false;
    @observable loadingReglementNotConsumedByClient = false;
    @observable loading = false;
    @observable loadingExportClient = false;
    @observable loadingInitial = false;
    @observable loadingExport = false;
    @observable submitting = false;
    @observable affectLoad = false;
    @observable deletingReglement = false;
    @observable reglement: IReglement | null = null;
    @observable reglements: IReglement[] = [];
    @observable reglementsNotConsumed: IReglement[] = [];
    @observable reglementsNotConsumedByClient: IReglement[] = [];
    @observable remboursement: IReglement[] = [];
    @observable reglementRegestry = new Map();
    @observable ITListReglement: IReglementList[] = [];
    @observable ITListReglementNotConsumedByClient: IReglementList[] = [];
    @observable editReglement: boolean = false;
    @observable addMode: boolean = false;
    @observable progress: number = 50;
    @observable page = 0
    @observable totalMontant = 0
    @observable reglementsCount: number = 0;
    @observable reglementsNotConsumedCount: number = 0;
    @observable totalCredit: number = 0;
    @observable totalDebit: number = 0;
    @observable totalRBT: number = 0;
    @observable totalReste: number = 0;
    @observable predicate = new Map();


    @computed get axiosParams() {
        const params = new URLSearchParams();
        params.append('limit', String(LIMIT));
        params.append('offset', `${this.page ? this.page * LIMIT : 0}`);
        this.predicate.forEach((value, key) => {
            if (key === 'startDate') {
                params.append(key, value.toISOString())
            } if (key === 'searchMultiple') {
                var searchClient = [...value][0];
                var sort = [...value][1];
                var dateCommande = [...value][2];
                var dateCommandeTo = [...value][3];
                var modePaiement = [...value][4];
                var searchResultsClientAll = [...value][5];
                var compteBancaire = [...value][6];
                params.append(searchClient[0], searchClient[1])
                params.append(sort[0], sort[1])
                params.append(dateCommande[0], dateCommande[1])
                params.append(dateCommandeTo[0], dateCommandeTo[1])
                params.append(modePaiement[0], modePaiement[1])
                params.append(compteBancaire[0], compteBancaire[1])
                params.append(searchResultsClientAll[0], searchResultsClientAll[1])

            }
            else {
                params.append(key, value)
            }
        })
        return params;
    }
    @action setPredicate = (predicate: string, value: any) => {

        if (predicate != 'all')
            this.reglements = [];
        this.predicate.clear();
        if (predicate !== 'searchMultiple') {
            this.predicate.clear();
        }
        if (predicate !== 'all') {
            this.predicate.set(predicate, value);
        }
    }
    @action changeButtonStateEdit = async (newState: boolean) => {
        this.editReglement = newState;
    }

    @action changeButtonStateAdd = async (newState: boolean) => {
        this.addMode = newState;
    }
    @computed get totalPages() {
        return Math.ceil(this.reglementsCount / LIMIT);
    }


    @action setPage = (page: number) => {
        this.page = page;
    }

    @action loadReglements = async () => {
        this.loadingInitial = true;
        try {
            const reglementsEnveloppe = await agent.reglementAgent.list(this.axiosParams);
            const { reglements, reglementsCount, totalMontant } = reglementsEnveloppe;

            runInAction(() => {
                reglements.forEach((reglement) => {
                    if (this.page == 0) {
                        this.reglements = reglements;
                    } else {
                        this.reglements.push(reglement);
                    }
                })
                this.totalMontant = totalMontant
                this.reglementsCount = reglementsCount;
                this.loadingInitial = false;
            })
        } catch (e) {
            toast.error("Problem loading reglements!");
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }

    @action loadReglementByFranchiseur = async (id: number, obj: any) => {
        this.loadingReglementByFranchiseur = true;
        try {
            var reglementsEnveloppe = await agent.reglementAgent.getReglementByFranchiseur(id, obj);
            const { reglements, totalCredit, totalDebit } = reglementsEnveloppe;
            runInAction(() => {
                this.reglements = reglements;
                this.totalDebit = totalDebit;
                this.totalCredit = totalCredit;
                this.loadingReglementByFranchiseur = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingReglementByFranchiseur = false;
            })
            console.log(e);
        }
    }

    @action loadReglementByOrder = async (id: number) => {
        this.loadingReglementByOrder = true;
        try {
            var reglements = await agent.reglementAgent.getReglementByOrder(id);
            runInAction(() => {
                this.reglements = reglements;
                this.loadingReglementByOrder = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingReglementByOrder = false;
            })
            console.log(e);
        }
    }
    @action loadReglementByInvoice = async (id: number) => {
        this.loadingReglementByInvoice = true;
        try {
            var reglements = await agent.reglementAgent.getReglementByInvoice(id);
            runInAction(() => {
                this.reglements = reglements;
                this.loadingReglementByInvoice = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingReglementByInvoice = false;
            })
            console.log(e);
        }
    }

    getReglement = (id: string) => {
        return this.reglementRegestry.get(id);
    }

    @action create = async (values: IReglement) => {
        this.submitting = true;
        try {
            const id = await agent.reglementAgent.create(values);
            runInAction(() => {
                values.idReglement = id;
                this.reglements.push(values);
                if (values.invoice != 0)
                    this._baseStore.factureStore.setChangeRemaining(values.montant);
                this.submitting = false;
                toast.success("ajout éffectué avec succès");
            })

        } catch (e) {
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    @action createCompanyReglement = async (values: IReglement) => {
        this.submitting = true;
        try {
            const id = await agent.reglementAgent.createCompanyReglement(values);
            runInAction(() => {
                values.idReglement = id;
                values.hasConsumed = false;
                (values.type == 'RGL' || values.type == 'SLDC') ? this.totalCredit += values.montant : this.totalDebit += values.montant;
                if (values.type == 'RBT' || values.type == 'SLDD')
                    values.montant = values.montant * -1;
                this.reglements.push(values);
                this.submitting = false;
                this.changeButtonStateAdd(false);
                toast.success("ajout éffectué avec succès");
            })

        } catch (e) {
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    @action edit = async (montant: number, values: Partial<IReglement>) => {
        this.submitting = true;
        this.progress = 80;
        try {
            await agent.reglementAgent.update(values);
            let account = await agent.reglementAgent.details(values.idReglement!);
            runInAction(() => {
                this.reglements = this.reglements.filter(a => a.idReglement != values.idReglement);
                //if (values.invoice!=0)
                //     this._baseStore.factureStore.editRemaining1(montant, account.montant);
                this.reglements.push(account);
                toast.success("Modification éffectuée avec succès");
                this.submitting = false;
            })

        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }
    @action affect = async (values: Partial<IReglement>) => {
        this.submitting = true;
        this.progress = 80;
        try {
            await agent.reglementAgent.affectReglement(values);
            runInAction(() => {
                toast.success("Affectation éffectuée avec succès");
                this.submitting = false;
            })

        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action deleteReglement = async (id: string, montant: number) => {
        this.deletingReglement = true;
        try {
            await agent.reglementAgent.delete(id);
            runInAction(() => {
                if (this.reglements[this.reglements.findIndex(x => x.idReglement == id)].invoice != 0) {
                    this._baseStore.factureStore.setChangeRemaining1(montant);
                }
                var type = this.reglements[this.reglements.findIndex(x => x.idReglement == id)].type;
                if (type != 'RGL' && type != 'SLDC') {
                    if (type == 'RBT' || type == 'SLDD')
                        this.totalDebit -= (montant * -1)
                    else
                        this.totalDebit -= montant

                }
                if (type == 'RGL' || type == 'SLDC')
                    this.totalCredit -= montant;
                this.reglements = this.reglements.filter(a => a.idReglement !== id);
                this.deletingReglement = false;
                toast.success("suppression éffectuée avec succès");
            })
        } catch (e) {
            toast.error("Problème de suppréssion de compte!");
            runInAction(() => {
                this.deletingReglement = false;
            })
        }

    }
    @action loadReglementOrderNotConsumed = async (id: number) => {
        this.loadingReglementNotConsumed = true;
        this.reglementsNotConsumed = [];
        try {
            var IReglementNotConsumedEnvelope = await agent.reglementAgent.getReglementNotConsumed(id);
            const { reglementsNotConsumed, reglementsNotConsumedCount } = IReglementNotConsumedEnvelope;
            runInAction(() => {
                this.reglementsNotConsumed = reglementsNotConsumed;
                this.reglementsNotConsumedCount = reglementsNotConsumedCount;
                this.loadingReglementNotConsumed = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingReglementNotConsumed = false;
            })
            console.log(e);
        }
    }
    @action ExportRglt = async (obj: any) => {
        this.loadingExport = true;
        try {
            await agent.reglementAgent.exportExcel(obj);
            runInAction(() => {
                this.loadingExport = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingExport = false;
            })
            console.log(e);
        }
    }
    @action exportRGLTComptables = async (obj: any) => {
        this.loading = true;
        try {
            await agent.reglementAgent.exportComptabilité(obj);
            runInAction(() => {
                this.loading = false;
            })
            toast.success("Le fichier a été exporté avec succès!");
        } catch (e) {
            toast.error("Problème d'export fichier  !");
            runInAction(() => {
                this.loading = false;
            })
        }
    }
    @action exportByCompany = async (obj: any) => {
        this.loadingExportClient = true;
        try {
            await agent.reglementAgent.exportByCompany(obj);
            runInAction(() => {
                this.loadingExportClient = false;
            })
            toast.success("Le fichier a été exporté avec succès!");
        } catch (e) {
            toast.error("Problème d'export fichier  !");
            runInAction(() => {
                this.loadingExportClient = false;
            })
        }
    }

    @action affectReglementFacture = async (values: Partial<IReglement>) => {
        this.loadingReglementByInvoice = true;
        this.progress = 80;
        try {
            var id = await agent.reglementAgent.affectReglementFacture(values);
            let account = await agent.reglementAgent.details(id);
            runInAction(() => {
                this._baseStore.factureStore.facture!.remainingPayment -= account.montant;
                this.reglements.push(account);
                toast.success("Modification éffectuée avec succès");
                this.loadingReglementByInvoice = false;
            })

        } catch (e) {
            runInAction(() => {
                this.loadingReglementByInvoice = false;
            })
            toast.error("Error submitting Data");
        }
    }
    @action reglementNotConsumedByClient = async (obj: any) => {
        this.loadingReglementNotConsumedByClient = true;
        this.reglementsNotConsumedByClient = [];
        this.ITListReglementNotConsumedByClient = [];
        try {
            var reglementNotConsumedByClientEnveloppe = await agent.reglementAgent.reglementNotConsumedByClient(obj);
            const { reglementsNotConsumedByClient, remboursements, totalRBT,totalReste } = reglementNotConsumedByClientEnveloppe;

            runInAction(() => {
                this.reglementsNotConsumedByClient = reglementsNotConsumedByClient;
                this.remboursement = remboursements;
                this.totalRBT = totalRBT;
                this.totalReste = totalReste;
                this.reglementsNotConsumedByClient.forEach((rgl) => {
                    let reglement: IReglementList = {
                        key: rgl.idReglement,
                        text: rgl.type + "_" + new Date(rgl.creationDate).toLocaleDateString('en-GB')+ "|" + (rgl.montant*-1).toFixed(2) ,
                        value: rgl.idReglement,
                    }
                    this.ITListReglementNotConsumedByClient.push(reglement)
                }
                )
                this.loadingReglementNotConsumedByClient = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingReglementNotConsumedByClient = false;
            })
            console.log(e);
        }
    }
   
}