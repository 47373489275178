import { FC, useContext, useEffect, useState } from 'react';
import { Tab, Header, Button, Grid, Table, Modal } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import AffectProductAttributeToArticle from './AffectProductAttributeToArticle';
import DeleteProductAttributesModal from './DeleteProductAttributesModal';
import UpdaeProductAttributesModal from './UpdaeProductAttributesModal';
import { IProductAttributes } from '../../../app/models/IProductAttributes';

const ProductsAttributesDescription = () => {
    const baseStore = useContext(BaseStoreContext)
    const { loadListProductAttribute, ITListProductAttributes, loadingAction } = baseStore.productAttributesStore;
    const { article } = baseStore.articleStore;
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    const [editTarget, setEditTarget] = useState<string | undefined>(undefined);
    const [editForm, setEditForm] = useState(false);
    const [produitAttribute, setProduitAttribute] = useState<IProductAttributes>();
    const [modalOpen, setModalOpen] = useState(false);
    const { openModal } = baseStore.modalStore;

    useEffect(() => {
        loadListProductAttribute(article!.productId);
        baseStore.articleStore.changeButtonState(false);
    }, [loadListProductAttribute])

    return (
        <Tab.Pane>
            <Grid>
                <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                    <Header floated='left' icon='shopping basket' content={baseStore.articleStore.editArticleMode ? 'Ajouter un nouvel attribut au produit' : "Attributs du produit"} />
                    <Button
                        onClick={() => { baseStore.articleStore.changeButtonState(!baseStore.articleStore.editArticleMode) }}
                        floated='right'
                        basic
                        content={baseStore.articleStore.editArticleMode ? 'Annuler' : 'Ajout Attribut'}
                    />
                </Grid.Column>
                <Grid.Column width={16}>
                    {baseStore.articleStore.editArticleMode ? (
                        <AffectProductAttributeToArticle id={article!.productId}
                        />
                    ) : (
                        <>
                            <Table loading={false} celled>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Produit</Table.HeaderCell>
                                        <Table.HeaderCell>Nom</Table.HeaderCell>
                                        <Table.HeaderCell>Valeurs possibles</Table.HeaderCell>
                                        <Table.HeaderCell>Valuer par défaut</Table.HeaderCell>
                                        <Table.HeaderCell style={{ textAlign: "center" }}>Actions</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {ITListProductAttributes &&
                                        ITListProductAttributes.map(attributes => (
                                            <Table.Row key={attributes.id}>

                                                <Table.Cell>{attributes.product.label}</Table.Cell>
                                                <Table.Cell>{attributes.name}</Table.Cell>
                                                <Table.Cell>{attributes.possibleValues}</Table.Cell>
                                                <Table.Cell>{attributes.defaultValue}</Table.Cell>
                                                <Table.Cell>
                                                    <Button.Group fluid widths={3}>
                                                        <Button
                                                            onClick={(e) => {
                                                                setProduitAttribute(attributes);
                                                                setEditForm(true);
                                                                setEditTarget(e.currentTarget.name)
                                                                setModalOpen(true);
                                                            }}
                                                            basic
                                                            color="blue"
                                                            icon='edit outline'
                                                            name={attributes.id}
                                                            style={{ marginRight: '2%' }}
                                                        />

                                                        <Modal
                                                            open={modalOpen}
                                                            size='large'
                                                            closeOnEscape={true}
                                                            closeOnRootNodeClick={true}
                                                        >
                                                            <Modal.Content>
                                                                <UpdaeProductAttributesModal productAttributes={produitAttribute!} setModalOpen={setModalOpen} />
                                                            </Modal.Content>
                                                        </Modal>
                                                        <Button
                                                            name={attributes.id}
                                                            onClick={(e) => {
                                                                openModal(<DeleteProductAttributesModal TarifId={attributes!.id} />)
                                                                setDeleteTarget(e.currentTarget.name)
                                                            }}
                                                            loading={loadingAction && deleteTarget === attributes.id.toString()}
                                                            basic
                                                            negative
                                                            icon='trash'
                                                        />
                                                    </Button.Group>
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                </Table.Body>
                            </Table>
                        </>
                    )}
                </Grid.Column>
            </Grid>
        </Tab.Pane>
    );
};
export default observer(ProductsAttributesDescription);
