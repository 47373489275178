import React, { useContext, useState } from 'react';
import { Form, Button, Grid, Input, Checkbox } from 'semantic-ui-react';
import { Form as FinalForm, Field } from 'react-final-form';
import { combineValidators, composeValidators, isRequired } from 'revalidate';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { v4 as uuid } from 'uuid';
import TextInput from '../../../app/common/form/inputText';
import ErrorMessage from '../../../app/common/form/ErrorMessage';


const validate = combineValidators({
    label: composeValidators(
        isRequired,
    )({
        message: 'Veuillez enter un label'
    }),

});
const ModeReglementForm = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { create } = baseRepo.modeReglementStore;
    const [dayss,setDayss]=useState<any>(0)
    const [monthEnd,setMonthEnd]=useState<any>(false)
    const [forceDay,setForceDay]=useState<any>(0)
    const handleChangeDayss = (event: any) => {
        setDayss(event.target.value);
    }
    const handleChangeForceDays = (event: any) => {
        setForceDay(event.target.value);
    }
  
    const handleFinalFormSubmit = (values: any) => {
        let newMode = {
            ...values,
            reglementId: uuid(),
            dayss: dayss,
            forceDay: forceDay,
            month_End:monthEnd,
        };
        create(newMode);
        baseRepo.modeReglementStore.changeButtonStateAdd(!baseRepo.modeReglementStore.addMode)
    }
    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            validate={validate}
            render={({ handleSubmit, submitting, invalid, pristine, dirtySinceLastSubmit }) => (
                <Form onSubmit={handleSubmit} error>
                    <Grid columns={5}>
                        <Grid.Row>
                            <Grid.Column  >
                                <Field fluid name='label' component={TextInput} placeholder='Label' />
                            </Grid.Column>
                            <Grid.Column  >
                                <Input fluid name='dayss' placeholder='Jours'  type='number' min={0} onChange={handleChangeDayss} />
                            </Grid.Column>
                            <Grid.Column  >
                                <Input fluid name='forceDay' placeholder='Jours de mois' max={31} type='number' min={0} onChange={handleChangeForceDays} />
                            </Grid.Column>
                            <Grid.Column style={{ margin: "10px 0 0 0" }}>
                                Fin de mois
                                <input type='checkbox' style={{ margin: "5px 0 0 10px" }} onClick={(event:any)=>setMonthEnd(event.target.checked)} />
                            </Grid.Column>
                            <Grid.Column >
                                <Button fluid size='small'
                                    disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                                    loading={submitting}
                                    color='orange'
                                    content='Ajouter'
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>

            )} />
    );
}
export default ModeReglementForm;