import React from 'react';
import { Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import AddFournisseurHeader from './AddFournisseurHeader';
import AddFournisseurContent from './AddFournisseurContent';
import Breadcrumbs from '../../dashboard/breadcrumbs';


const AddFournisseurPage: React.FC = ({ }) => {
    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0,name: "Tiers", root: "" },
                    { key: 1,name: "Fournisseurs", root: "/fournisseurDashboard" },
                    { key: 2,name: "Ajouter fournisseur" , root: "/addFournisseur" },
                ]}
            />
        <Grid>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Grid style={{ marginTop: 2 }}>
                        <Grid.Column width={16}>
                            <AddFournisseurHeader />
                            <AddFournisseurContent />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>

            </Grid.Row>
        </Grid>
        </div>
    );
}

export default observer(AddFournisseurPage);