import React, { Fragment, useState, useEffect } from 'react';
import { Header, Grid, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import FileWidgetDropzone from './FileWidgetDropzone';

interface IProps {
    loading: boolean;
    uploadFile: (file: Blob) => void;
}
const FileUploadWidget: React.FC<IProps> = ({ loading, uploadFile }) => {

    const [files, setFiles] = useState<any[]>([]);

    useEffect(() => {
        return () => {
            files.forEach(file => URL.revokeObjectURL(file.preview));
        };
    });
    const FichierPdf = '/assets/pdf.jpg';
    const FichierExcel = '/assets/excel.png';
    const FichierWord = '/assets/word.png';
    const FichierImage = '/assets/photo.png';
    const DefaultFichier = "/assets/file.png";

    function getpathimg() {
        if (files) {
            var extension = files[0].path.toLowerCase().substring(files[0].path.lastIndexOf('.') + 1)
            switch (extension) {
                case 'xls':
                case 'xlsx':
                    return FichierExcel;
                case 'doc':
                case 'docx':
                    return FichierWord;
                case 'pdf':
                    return FichierPdf;
                case 'jpg':
                case 'png':
                    return FichierImage;
                default:
                    return DefaultFichier;
            }
        } else {
            return DefaultFichier;
        }
    };

    return (
        <Fragment>
            <Grid>
                <Grid.Column width={8}>
                    <Header color='orange' sub content='Step 1 - Ajouter votre fichier' style={{ textAlign: 'center' }} />
                    <FileWidgetDropzone setFiles={setFiles} />
                </Grid.Column>
                <Grid.Column width={8}>
                    <Header sub color='orange' content='Step 2 - Charger' style={{ textAlign: 'center' }} />
                    {files.length > 0 &&
                        <Fragment>

                            <img className='img-preview' style={{ maxHeight: '110px', overflow: 'hidden', marginLeft: '40%', marginBottom: '1%' }} src={getpathimg()} />
                            <div style={{ textAlign: 'center', fontSize: 'medium', fontWeight: 'bold', width: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                                <label >{files[0].name!}</label>
                            </div>
                            <br />
                            <Button.Group widths={2} style={{ width: '40%', marginLeft: '30%', marginTop: '1%' }}>
                                <Button positive icon='check' loading={loading} disabled={loading} onClick={() => uploadFile(files[0]!)} />
                                <Button negative icon='close' disabled={loading} onClick={() => setFiles([])} />
                            </Button.Group>
                        </Fragment>
                    }
                </Grid.Column>
                <Grid.Column width={6} />
            </Grid>
        </Fragment>
    );
};

export default observer(FileUploadWidget);
