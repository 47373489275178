import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Container, Header, Icon } from 'semantic-ui-react';
import { BaseStoreContext } from '../../app/store/baseStore';

interface IProps {
    id: number,
    inventoryId: number
}
const FichierExcel = '/assets/excel.png';
const dropzoneStyles = {
    border: 'dashed 3px',
    borderColor: '#eee',
    borderRadius: '5px',
    paddingTop: '30px',
    textAlign: 'center' as 'center',
    height: '200px'
};

const dropzoneActive = {
    borderColor: 'green'
};
const UploadExcelCompanyProduct: React.FC<IProps> = ({ id, inventoryId }) => {
    const baseRepo = useContext(BaseStoreContext);
    const { closeModal } = baseRepo.modalStore;
    const [file, setFile] = useState<any>();

    function getpathimg() {
        if (file) {
            var extension = file.path.toLowerCase().substring(file.path.lastIndexOf('.') + 1)
            switch (extension) {
                case 'xls':
                case 'xlsx':
                    return FichierExcel;

            }
        }
    };
    const onDrop = useCallback(acceptedFiles => {
        var filesAccepted = acceptedFiles.map((file: Blob) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file)
            })
        )

        setFile(filesAccepted[0]);
    }, [setFile]
    );
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    const { uploadInventoryCompanies, uploading } = baseRepo.inventoryStore;

    return (
        <Container textAlign='center'>
            <Header icon='upload' content='Séléctionnez le ficher Excel' style={{ marginLeft: 20 }} />
            <div
                {...getRootProps()}
                style={
                    isDragActive ? { ...dropzoneStyles, ...dropzoneActive } : dropzoneStyles
                }
            >
                {file ?
                    <>
                        <img className='img-preview' style={{ maxHeight: '50px', overflow: 'hidden', marginLeft: '40%', marginBottom: '1%' }} src={getpathimg()} /><br />
                        <label >{file.name!}</label>
                    </>
                    :
                    <>
                        <input {...getInputProps()} />
                        <Icon name='upload' size='huge' style={{ cursor: 'pointer' }} />
                        <Header content='Charger votre fichier ici' style={{ cursor: 'pointer' }} />
                    </>
                }

            </div>
            <Button.Group fluid>
                <Button onClick={() => closeModal()}>

                    <Icon name='remove' /> Annuler
                </Button>
                <Button.Or text='Ou' />
                <Button
                    name={id}
                    uploading={uploading}
                    color='green'
                    onClick={(e) => {
                        uploadInventoryCompanies(id,inventoryId,file)
                        closeModal()
                    }}>
                    <Icon name='checkmark' /> Confirmer
                </Button>
            </Button.Group>
        </Container>
    );
}
export default observer(UploadExcelCompanyProduct);
