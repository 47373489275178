import React, { useContext } from 'react'
import { Tab } from 'semantic-ui-react';
import CommandeDescription from './CommandeDescription'
import CommandeAticle from './CommandeAticle';
import CommandeAdresses from './CommandeAdresses';
import CommandeClient from './CommandeClient';
import CommandeReglements from './CommandeReglements';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import ObjetLierCommande from './ObjetLierCommande';
import CustomizedOrderLine from './CustomizedOrderLine';

interface IProps {
    setActiveTab: (activeIndex: any) => void;
    orderId: number;
}
const CommandeContent: React.FC<IProps> = ({ setActiveTab, orderId }) => {
    const baseRepo = useContext(BaseStoreContext);
    const panes = [
        { menuItem: 'Général', render: () => <CommandeDescription /> },
        { menuItem: 'Article(s)', render: () => <CommandeAticle /> },
        { menuItem: 'Informations', render: () => <CommandeClient /> },
        { menuItem: 'Adresses', render: () => <CommandeAdresses /> },
        //{ menuItem: 'Ajouter une remise globale', render: () => <CommandeRemiseGlobaleInformation /> },
        {
            menuItem: 'Reglement', render: () => <CommandeReglements orderId={orderId}/> },
        { menuItem: 'Objets liés', render: () => <ObjetLierCommande /> },
        { menuItem: 'Fichiers Personnalisés', render: () => <CustomizedOrderLine orderId={orderId} /> },
    ]

    return (
        <div>
            <Tab
                menu={{ fluid: true, vertical: true }}
                menuPosition='right'
                activeIndex={baseRepo.commandeStore.activeTab}
                panes={ panes }
                onTabChange={(e, data) => setActiveTab(data.activeIndex)}
            />
        </div>
    )
}
export default observer(CommandeContent)