import React, { useContext, useEffect, useState } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button, Checkbox } from 'semantic-ui-react';
import TextInput from '../../../app/common/form/inputText';
import { observer } from 'mobx-react-lite';
import { IArticle } from '../../../app/models/IArticle';
import { ITListCustomizedType, ITListModeAppro, ITListProductType, ITListSide } from '../../../app/common/options/IAchat';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import { MdOutlineKeyboardArrowRight, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import InputNumber from '../../../app/common/form/inputNumber';
import AsyncSelect from 'react-select/async';
import Select from 'react-select/';
import CreatableSelect from 'react-select/creatable';
import { IOptionList } from '../../../app/models/IOption';

interface IProps {
    editArticle: (article: IArticle) => void;
    article: IArticle;
}
interface RenderTree {
    id: string;
    parentId: string;
    name: string;
    children?: readonly RenderTree[];
}
const ArticleEditForm: React.FC<IProps> = ({ editArticle, article }) => {
    const baseStore = useContext(BaseStoreContext);
    const { loadingFamille, loadFamilles, loadSousFamille, ITreeSousFamille } = baseStore.familleStore;
    const { loadFranchiseurs } = baseStore.franchiseurStore;
    const { ITListTva, defaultTva } = baseStore.tvaStore;
    const { ITListImpression, loadImpressions, loadingImpression, submittingImpression } = baseStore.impressionStore;
    const { ITListOption, loadOptions, ITListOptionArticle, loadingOption, submittingOption } = baseStore.optionStore;
    const { ITListSupport, loadSupports, loadingSupport, submittingSupport } = baseStore.supportStore;
    const { verificationUpdate, submittingDeliveryDelay, IDeliveryDelayList, loadDeliveryDelay } = baseStore.articleStore;
    const [sousFamille, setSousFamille] = useState<string>(article.familleId);
    const [validF, setValidF] = useState(true);
    const [famille, setFamille] = useState<string>(article.parentId);

    const renderTree = (nodes: RenderTree) => (
        <TreeItem style={{ fontSize: 25, paddingTop: 10, backgroundColor: nodes.id == sousFamille ? 'rgba(25, 118, 210, 0.08)' : 'inherit' }} key={nodes.id}
            onClick={() => {
                sousFamille == nodes.id ? setSousFamille(famille!)
                    : setSousFamille(nodes.id)
            }}
            nodeId={nodes.id} label={nodes.name} >
            {Array.isArray(nodes.children)
                ? nodes?.children.map((node: RenderTree) => renderTree(node))
                : null}
        </TreeItem>
    );

    useEffect(() => {
        loadImpressions();
        loadOptions();
        loadSupports();
        loadFamilles();
        loadSousFamille(article.parentId);
        loadFranchiseurs();
        loadDeliveryDelay();
    }, [])

    const [tva, setTva] = useState<number | undefined>(article.tva);
    const [client, setClient] = useState<string>(article.companyId?.toString());
    const [ref, setRef] = useState(article!.companyId == 0
        ? article!.externalReference.substring(3, article!.externalReference.indexOf(article.reference))
        : article!.externalReference.substring(article!.agencyName.toString().length, article!.externalReference.indexOf(article.reference)));
    const [impression, setImpression] = useState<string>(article!.printing!);
    const [support, setSupport] = useState<string>(article!.paperWeight);
    const [side, setSide] = useState<string>(article.side);
    const [productType, setProductType] = useState<string>(article.productType);
    const [customisation, setCustomisation] = useState<string>(article.customizationType);
    const [familleLibelle, setFamilleLibelle] = useState<string>(article!.familleLibelle);
    const [valueOption, setValueOption] = useState<IOptionList[]>(ITListOptionArticle);
    const [deliveryDelay, setDeliveryDelay] = useState<string>(article.deliveryDelay);
    const [mode, setMode] = useState<string>(article!.modeAppro);
    const [fraisPort, setfraisPort] = useState(article.deliveryCosts);
    const [hasCommission, setHasCommission] = useState(article.hasCommission);
    const [belongToOpeningPack, setBelongToOpeningPack] = useState(article.belongToOpeningPack);
    const [isHideEco, setIsHideEco] = useState(article.isHideEco);
    const handleChangeFamille = (e: any) => {
        setFamille(e);
        setSousFamille(e);
        loadSousFamille(e)
        setValidF(true);
    }
    const handleChangeImpression = (inputValue: string) => {
        setTimeout(() => {
            setImpression(inputValue);
        }, 1000);

    }
    const handleChangeSupport = (inputValue: string) => {
        setTimeout(() => {
            setSupport(inputValue);
        }, 1000);
    }
    const handleChangeRef = (e: any) => {
        setRef(e.target.value)
    }
    const handleChangeCodeFranch = (e: any) => {
        setClient(e);
    }
    const handleChangeMode = (e: any) => {
        setMode(e);
    }
    const handleChangeTva = (e: any) => {
        setTva(e);
    }
    const handleChangeOption = (inputValue: string) => {
        setTimeout(() => {
            var id = uuid();
            const newOption: IOptionList = {
                key: id,
                label: inputValue,
                value: inputValue
            }
            setValueOption((prev) => [...prev, newOption]);
        }, 1000);
    }
    const handleChangeDeliveryDelay = (inputValue: string) => {
        setTimeout(() => {
            setDeliveryDelay(inputValue);
        }, 1000);

    }
    const handleFinalFormSubmit = (values: any) => {
        var tab: string[] = [];
        valueOption.map((item: any) => {
            tab.push(item.label)
        })
        let newArticle: IArticle = {
            ...values,
            ExternalReference: ref,
            familleId: sousFamille,
            famille: sousFamille!,
            familleLibelle: familleLibelle,
            tva: tva || defaultTva!.id,
            franchiseur: client,
            optionArticles: tab,
            printing: impression!,
            paperWeight: support!,
            deliveryDelay: deliveryDelay,
            modeAppro: mode,
            deliveryCosts: fraisPort,
            productType: productType,
            customizationType: customisation,
            side: side,
            hasCommission: hasCommission,
            belongToOpeningPack: belongToOpeningPack,
            isHideEco: isHideEco
        };
        let result = verificationUpdate(newArticle, article);
        result ? toast.warning("Merci de changer au moins une modification") : editArticle(newArticle);
        ;
    }

    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            initialValues={article!}
            render={({ handleSubmit, submitting }) => (
                <Form onSubmit={handleSubmit} error>
                    <Form.Group unstackable widths={3}>
                        <div className="field">
                            <label>Libellé </label>
                            <Field name='label' component={TextInput} placeholder="libelle" initialValue={article!.label} />
                        </div>
                        <div className="field">
                            <label>Client  </label>
                            <AsyncSelect
                                name="form-field-name"
                                noOptionsMessage={() => "Code introuvable"}
                                cacheOptions
                                defaultOptions
                                getOptionLabel={e => e!.text}
                                getOptionValue={e => e!.value}
                                placeholder={article.companyId != 0 ? article.agencyName + " - " + article.franchiseurLibelle : "aucun client"}
                                loadOptions={baseStore.franchiseurStore.loadCompanyOptions}
                                onChange={e => handleChangeCodeFranch(e!.value)}
                            />
                        </div>
                        <div className="field">
                            <label>Référence centrale </label>
                            <input type="text" defaultValue={ref}
                                onChange={handleChangeRef} required />
                        </div>
                    </Form.Group>

                    <Form.Group unstackable widths={3}>
                        <div className="field">
                            <label>Format </label>
                            <Field name='paperFormat' component={TextInput} placeholder={article!.paperFormat} value={article!.paperFormat} />
                        </div>
                        <div className="field">
                            <label>Poids unitaire <a style={{ color: 'red', fontSize: 17 }}>*</a></label>
                            <Field name='weight' component={InputNumber} type="number" initialValue={article.weight} />
                        </div>
                        <div className="field">
                            <label>Délai de livraison (Jours) <a style={{ color: 'red', fontSize: 17 }}>*</a></label>
                            <CreatableSelect name="delaiLivraison"
                                isDisabled={submittingDeliveryDelay}
                                isLoading={submittingDeliveryDelay}
                                options={IDeliveryDelayList}
                                value={{ label: deliveryDelay, value: deliveryDelay }}
                                placeholder='Délai de livraison'
                                onChange={(deliveryDelay: any) => {
                                    setDeliveryDelay(deliveryDelay.value);
                                }}
                                onCreateOption={handleChangeDeliveryDelay}
                                formatCreateLabel={() => `Créer`}
                            />
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={3}>
                        <div className="field">
                            <label>Impression</label>
                            <CreatableSelect name="impression"
                                isDisabled={loadingImpression || submittingImpression}
                                isLoading={loadingImpression || submittingImpression}
                                options={ITListImpression}
                                value={{ label: impression, value: impression } }
                                placeholder='Impression'
                                onChange={(impression: any) => {
                                    setImpression(impression.value);
                                }}
                                onCreateOption={handleChangeImpression}
                                defaultValue={ITListImpression.find(x => x.label == article.printing)} />
                        </div>
                        <div className="field">
                            <label>Support</label>
                            <CreatableSelect name="support"
                                isDisabled={loadingSupport || submittingSupport}
                                isLoading={loadingSupport || submittingSupport}
                                options={ITListSupport}
                                value={{ label: support, value: support }}
                                placeholder='Support'
                                onChange={(support: any) => {
                                    setSupport(support.value);
                                }}
                                onCreateOption={handleChangeSupport}
                                defaultValue={ITListSupport.find(x => x.label == article.paperWeight)}
                            />
                        </div>
                        <div className="field">
                            <label> Descriptif </label>
                            <CreatableSelect
                                isMulti
                                isDisabled={loadingOption || submittingOption}
                                isLoading={loadingOption || submittingOption}
                                options={ITListOption}
                                value={valueOption}
                                placeholder="Finition / Option"
                                onChange={(option: any) => {
                                    setValueOption(option);
                                }}
                                onCreateOption={handleChangeOption}
                                defaultValue={ITListOptionArticle} />

                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={3}>
                        <div className="field">
                            <label>Mode appro</label>
                            <Select
                                getOptionLabel={e=>e.text}
                                getOptionValue={e=>e.value}
                                options={ITListModeAppro}
                                name='modeAppro'
                                placeholder='Mode appro'
                                onChange={(e)=>handleChangeMode(e!.value)}
                                defaultValue={ITListModeAppro.find(x=>x.value ==  mode)}
                            />
                        </div>
                        {mode == "Stock" &&
                            <div className="field">
                            <label>Seuil (QUANTITE)</label>
                            <Field name='seuil' component={InputNumber} type="number" placeholder="Seuil"  defaultValue={article!.seuil} />
                            </div>
                        }
                        <div className="field">
                            <label>Code Barre</label>
                            <Field name='barCode' component={TextInput} placeholder={article!.barCode} value={article!.barCode} />
                        </div>
                        <div className="field">
                            <label>Tva</label>
                            <Select
                                getOptionLabel={e => e.text}
                                getOptionValue={e => e.value.toString()}
                                options={ITListTva}
                                name='tva'
                                placeholder={defaultTva?.nom + ' - ' + defaultTva?.taux + '%'}
                                onChange={(e) => handleChangeTva(e!.value)}
                                defaultValue={ITListTva.find(x => x.value == article.tva)}
                            />
                        </div>
                    </Form.Group>
                    <Form.Group unstackablec widths={4}>
                        <div className="field">
                            <label>Type de Personnalisation</label>
                            <Select
                                getOptionLabel={e => e.text}
                                getOptionValue={e => e.value}
                                options={ITListCustomizedType}
                                name='customizationType'
                                placeholder='Type de Personnalisation'
                                onChange={(e) => setCustomisation(e!.value)}
                                defaultValue={ITListCustomizedType.find(x => x.value == customisation)}
                            />
                        </div>
                        <div className="field">
                            <label>Face</label>
                            <Select
                                getOptionLabel={e => e.text}
                                getOptionValue={e => e.value}
                                options={ITListSide}
                                name='side'
                                placeholder='Façade'
                                onChange={(e) => setSide(e!.value)}
                                defaultValue={ITListSide.find(x => x.value == side)}
                            />
                        </div>
                        <div className="field">
                            <label>Type de Produit</label>
                            <Select
                                getOptionLabel={e => e.text}
                                getOptionValue={e => e.value}
                                options={ITListProductType}
                                name='productType'
                                placeholder='Type de produit'
                                onChange={(e) => setProductType(e!.value)}
                                defaultValue={ITListProductType.find(x => x.value == productType)}
                            />
                        </div>
                        <div className="field">
                            <label>Code comptable </label>
                            <Field name='codeComptable' component={TextInput} type="number" placeholder="Code comptable" initialValue={article!.codeComptable} />
                        </div>

                    </Form.Group>
                    <Form.Group unstackable widths={4} >
                        <div className="field">
                            <label>Frais de port </label>
                            <Checkbox value="deliveryCosts" style={{ marginTop: '10px' }} defaultChecked={article.deliveryCosts} onChange={() => setfraisPort(!fraisPort)} />
                        </div>
                        <div className="field">
                            <label>Appartient au pack d'ouverture </label>
                            <Checkbox value="belongToOpeningPack" style={{ marginTop: '10px' }} defaultChecked={article.belongToOpeningPack} onChange={() => setBelongToOpeningPack(!belongToOpeningPack)} />
                        </div>
                        <div className="field">
                            <label>Avec commission</label>
                            <Checkbox value="hasCommission" defaultChecked={article.hasCommission} style={{ marginTop: '10px' }} onChange={() => setHasCommission(!hasCommission)} />
                        </div>
                        <div className="field">
                            <label>Masquer sur la boutique</label>
                            <Checkbox value="isHideEco" defaultChecked={article.isHideEco} style={{ marginTop: '10px' }} onChange={() => setIsHideEco(!isHideEco)} />
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={2}>
                        <div className="field">
                            <label>Famille</label>
                            <AsyncSelect
                                name="form-field-name"
                                noOptionsMessage={() => "Code introuvable"}
                                cacheOptions
                                defaultOptions
                                getOptionLabel={e => e!.text}
                                getOptionValue={e => e!.value}
                                defaultInputValue={article.familleLibelle}
                                loadOptions={baseStore.familleStore.loadFamilleOptions}
                                onChange={e => { setFamilleLibelle(e!.text); handleChangeFamille(e!.value); }}
                            />
                        </div>
                        <div className="field">
                            <h5>Sous famille <a style={{ color: 'red', fontSize: 17 }}>*</a></h5>
                            <TreeView
                                aria-label="file system navigator"
                                defaultCollapseIcon={< MdOutlineKeyboardArrowUp />}
                                defaultExpandIcon={< MdOutlineKeyboardArrowRight />}
                                sx={{ height: 150, flexGrow: 1, width: 400, overflowY: 'auto' }}
                            >
                                {(!loadingFamille && validF) && ITreeSousFamille.map((item: any) => renderTree(item))}
                            </TreeView>
                        </div>
                    </Form.Group>
                    <div style={{ marginTop: '20px' }}>
                        <Button.Group floated="right">
                            <Button disabled={submitting} onClick={() => { baseStore.articleStore.changeButtonState(!baseStore.articleStore.editArticleMode) }} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive disabled={submitting} loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </div>
                </Form>
            )}
        />
    );
};

export default observer(ArticleEditForm);