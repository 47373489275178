import { observer } from 'mobx-react-lite';
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Button, Header, Form, Table, Grid } from 'semantic-ui-react';
import TextInput from '../../../app/common/form/inputText';
import { IAttributeItems, IAttributes, IProductAttributes } from '../../../app/models/IProductAttributes';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { Field, Form as FinalForm } from 'react-final-form';


interface IProps {
    productAttributes: IProductAttributes;
    setModalOpen: Dispatch<SetStateAction<boolean>>;
}

const UpdaeProductAttributesModal: React.FC<IProps> = ({ productAttributes, setModalOpen }) => {
    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    const {  update } = baseStore.productAttributesStore;
    const [editTarget, setEditTarget] = useState<string | undefined>(undefined);
    const [name, setName] = useState<string>(productAttributes.name);
    const [elements, setElement] = useState<IAttributeItems[]>([]);
    const [checked, setChecked] = useState<boolean>(productAttributes.displayAllWithDistinctQuantities);
    const [valid, setvalid] = useState(false);
    useEffect(() => {
        var tab = [];
        var id = 0;
        var posibleValue = productAttributes.possibleValues
        while (posibleValue.indexOf(';') != -1)
        {
            var chaine = posibleValue.substring(0, posibleValue.indexOf(';'));
            posibleValue = posibleValue.substring(posibleValue.indexOf(';') + 1, posibleValue.length);
            var attribute: IAttributeItems = {

                id: id,
                item: chaine,
                isMain: chaine == productAttributes.defaultValue,
            }
            id++;
            tab.push(attribute);

        }
        var attribute: IAttributeItems = {

            id: id,
            item: posibleValue,
            isMain: posibleValue == productAttributes.defaultValue,
        }
        tab.push(attribute);
        setElement(tab);
        
    }, []);
    const handleChangeName = (e: any, result: any) => {
        setName(result.value.toUpperCase());
    }
    const handleChangeMain = (id: number) => {
        var tab = elements;
        tab[tab.findIndex(x => x.isMain == true)].isMain = false;
        tab[id].isMain = true;
        setElement(tab.slice(0, elements.length));
    }

    const handleDelete = (id: number) => {
        var tab = elements;
        tab = tab.filter(x => x.id != id);
        setElement(tab);
    }
    const handleChange = () => {
        setChecked(!checked);
    };

    const handleFinalFormSubmit = (values: any) => {
        if (valid) {
            var valeurs = "";
            elements.forEach((option, index) => {
                valeurs += (option.item);
                if (index != elements.length - 1)
                    valeurs += ';';
            })
            let newPro: IAttributes = {
                id: productAttributes.id,
                name: name.toUpperCase(),
                defaultValue: elements[elements.findIndex(x => x.isMain == true)].item,
                possibleValues: valeurs,
                displayAllWithDistinctQuantities: checked,
            };
            update(newPro);
            setModalOpen(false);
            closeModal();
        } else {
            if (values.possibleValues != "") {
                const tab = elements;
                var element: IAttributeItems = {
                    id: elements.length,
                    item: values.possibleValues,
                    isMain: false
                }
                if (elements.length == 0)
                    element.isMain = true;
                tab.push(element);
                setElement(tab);
                values.possibleValues = '';
            }
        }
    }   
    return (
            <FinalForm
                onSubmit={handleFinalFormSubmit}
                render={({ handleSubmit, submitting }) => (
                    <Form onSubmit={handleSubmit} error>
                    <Header icon='add' content='Modification Attribute' style={{ margin: "10px 0 40px 20px" }} />
                        <Grid>
                            <Grid.Column width={7}>
                                <h5 >Libellé de l'attribut</h5>
                                <Form.Input name='name' value={name} component={TextInput} type='text' onChange={handleChangeName} />
                            </Grid.Column>
                            <Grid.Column width={9}>
                                <h5>Valeurs possibles</h5>
                                <div style={{ display: "flex", flexWrap: 'wrap', width: '100%' }}>
                                    <Field name='possibleValues' component={TextInput} />
                                    <Button primary type='submit'>+</Button>
                                </div>
                            </Grid.Column>
                        </Grid>
                        <Grid  >
                            <Table style={{ height: 150 }} loading={false} celled>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Valeur</Table.HeaderCell>
                                        <Table.HeaderCell>Par Défaut</Table.HeaderCell>
                                        <Table.HeaderCell></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {elements &&
                                        elements.map((element) =>
                                        (
                                            <Table.Row>
                                                <Table.Cell>{element.item}</Table.Cell>
                                                <Table.Cell>
                                                    <input type="checkbox" disabled={element.isMain} checked={element.isMain} onClick={() => handleChangeMain(element.id)} />
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Button
                                                        disabled={element.isMain}
                                                        onClick={(e) => {
                                                            handleDelete(element.id)
                                                        }}
                                                        basic
                                                        negative
                                                        icon='trash'
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                </Table.Body>
                            </Table>
                        </Grid>
                        <Grid columns={2} >
                            <Grid.Column>
                                <h5>Permettre l'affichage de toutes les options pour commander simultanément les variations</h5>
                                <input type="checkbox" checked={checked} onChange={handleChange} name="displayAllWithDistinctQuantities" />
                            </Grid.Column>
                        </Grid>
                        <div >
                        <Button.Group fluid widths={2}>
                            <Button
                                type='button'
                                onClick={() => {
                                    setModalOpen(false);
                                    closeModal()
                                }}>
                                Annuler
                            </Button>
                            <Button.Or />
                            <Button
                                    name={productAttributes.id}
                                positive
                                type='submit'
                                onClick={(e) => {
                                    setvalid(true)
                                    setEditTarget(e.currentTarget.name)
                                }}
                                loading={submitting}
                            >Confirmer</Button>
                            </Button.Group>
                        </div>
                    </Form >
                )}
            />
    )
}
export default observer(UpdaeProductAttributesModal);
