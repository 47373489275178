import { useContext, useState } from 'react';
import { Tab, Header, Button, Grid, Table, Progress, Modal } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import AddFactureArticle from './AddFactureArticle';
import EditFactureArticle from './EditFactureArticle';
import DeleteFactureLineModal from './DeleteFactureLineModal';
import AucunProduit from '../../commande/detailCommande/AucunProduit';
import { IFactureFournisseurLine } from '../../../app/models/IFactureFournisseur';

const FactureArticle = () => {
    const baseStore = useContext(BaseStoreContext)
    const { facture, deletingArticle, progress } = baseStore.factureFournisseurStore;
    const [addArticleMode, setAddArticleMode] = useState(false);
    const { openModal } = baseStore.modalStore;
    const [modalOpen, setModalOpen] = useState(false);
    const [line, setLine] = useState<IFactureFournisseurLine>();

    return (
        <Tab.Pane>
            <Modal
                open={modalOpen}
                size='large'
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >
                <Modal.Header icon='add' >
                    Modifier l'article
                    <Button
                        style={{ height: 35 }}
                        type='button' icon='x' color='red'
                        floated='right'
                        onClick={() => { setModalOpen(false) }}
                    >
                    </Button>
                </Modal.Header>
                <Modal.Content>
                    <EditFactureArticle Line={line!} setModalOpen={setModalOpen} facture={facture!} />
                </Modal.Content>
            </Modal>
            <Grid>
                <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                    <Header floated='left' icon='shopping basket' content='Produits' />
                    {facture!.invoiceState == 'Brouillon' &&
                        <Button
                            onClick={() => setAddArticleMode(!addArticleMode)}
                            floated='right'
                            basic
                            content={addArticleMode ? 'Annuler' : 'Ajouter produit'}
                        />}
                </Grid.Column>
                <Grid.Column width={16}>
                    {addArticleMode ? (
                        <AddFactureArticle setAddArticleMode={setAddArticleMode} facture={facture!} />
                    ) : (
                        <>
                            {deletingArticle &&
                                <Progress color='orange' percent={progress} size='tiny' />
                            }
                            {facture!.purchaseInvoiceLines.length == 0 ? (
                                <AucunProduit />
                            ) : (
                                <Table celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Article</Table.HeaderCell>
                                            <Table.HeaderCell>QTT</Table.HeaderCell>
                                            <Table.HeaderCell>PU HT.</Table.HeaderCell>
                                            <Table.HeaderCell>TVA</Table.HeaderCell>
                                            <Table.HeaderCell>REMISE</Table.HeaderCell>
                                            <Table.HeaderCell>HT</Table.HeaderCell>
                                            {facture!.invoiceState == 'Brouillon' &&
                                                <Table.HeaderCell style={{ textAlign: "center" }}>Actions</Table.HeaderCell>}
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {facture &&
                                            facture.purchaseInvoiceLines.map(InvoiceLine => (
                                                <>
                                                    <Table.Row key={InvoiceLine.id}>
                                                        <Table.Cell rowspan={InvoiceLine.comment == "" || InvoiceLine.comment == null ? 1 : 2}>
                                                            <a style={{ backgroundColor: 'none' }} href={`/detailArticle/${InvoiceLine.product.productId}`}>
                                                                {InvoiceLine.product.reference} - {InvoiceLine.product.label}
                                                                <ul className="ui list" style={{ fontSize: 12, fontWeight: 700 }}>
                                                                    {InvoiceLine.printing && <li>Impression:{InvoiceLine.printing}</li>}
                                                                    {InvoiceLine.paperFormat && <li>Format:{InvoiceLine.paperFormat}</li>}
                                                                    {InvoiceLine.paperWeight && <li>Support:{InvoiceLine.paperWeight}</li>}
                                                                    <li>Poids unitaire:{InvoiceLine.weight} Kg</li>
                                                                    {InvoiceLine.deliveryDelay && <li>Délais de livraison:{InvoiceLine.deliveryDelay}</li>}
                                                                    {InvoiceLine.productAttributeValues && InvoiceLine.productAttributeValues.map((item) => (
                                                                        <li>{item.productAttributeName}:{item.value}</li>
                                                                    ))
                                                                    }
                                                                </ul>
                                                            </a>
                                                        </Table.Cell>
                                                        <Table.Cell>{InvoiceLine.quantity}</Table.Cell>
                                                        <Table.Cell>{InvoiceLine.unitPrice.toFixed(4)} €</Table.Cell>
                                                        <Table.Cell>{InvoiceLine.tvaRate} %</Table.Cell>
                                                        <Table.Cell>{InvoiceLine!.discount} %</Table.Cell>
                                                        <Table.Cell>{(InvoiceLine.quantity * InvoiceLine.unitPrice - (InvoiceLine.quantity * InvoiceLine.unitPrice) * InvoiceLine.discount / 100).toFixed(2)} €</Table.Cell>
                                                        {facture!.invoiceState == 'Brouillon' &&
                                                            <Table.Cell>
                                                                <Button.Group fluid widths={2}>
                                                                    <Button
                                                                        onClick={(e) => {
                                                                            setLine(InvoiceLine)
                                                                            setModalOpen(true);
                                                                        }}
                                                                        basic
                                                                        color="blue"
                                                                        icon='edit outline'
                                                                        name={InvoiceLine.id}
                                                                        style={{ marginRight: '2%' }}
                                                                    />
                                                                    <Button
                                                                        name={facture.id}
                                                                        onClick={(e) => {
                                                                            openModal(<DeleteFactureLineModal invoiceLine={InvoiceLine} />)
                                                                        }}
                                                                        loading={deletingArticle}
                                                                        basic
                                                                        negative
                                                                        icon='trash'
                                                                    />
                                                                </Button.Group>
                                                            </Table.Cell>}
                                                    </Table.Row>
                                                    {InvoiceLine.comment &&
                                                        <Table.Row >
                                                            <Table.Cell colspan={6} style={{ fontSize: 12, fontWeight: 700, borderLeft: '1px solid rgba(34,36,38,.1)' }}>{InvoiceLine.comment}</Table.Cell>
                                                        </Table.Row>
                                                    }
                                                </>
                                            ))}
                                    </Table.Body>
                                </Table>
                            )}
                        </>
                    )}
                </Grid.Column>
            </Grid >
        </Tab.Pane >
    );
};
export default observer(FactureArticle);
