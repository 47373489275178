import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { Form, Button, Checkbox, Input } from 'semantic-ui-react';
import { Field, Form as FinalForm } from 'react-final-form';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../app/store/baseStore';
import { IUserCompany, IResponsable } from '../../app/models/IUser';
import TextInput from '../../app/common/form/inputText';
import { ITListTypeCivilite } from '../../app/common/options/IAchat';
import InputSelect from '../../app/common/form/inputSelect';
import { v4 as uuid } from 'uuid';

interface IProps {
    id: number;
    setModalOpen: Dispatch<SetStateAction<boolean>>;
}

const AdduserModal: React.FC<IProps> = ({ id, setModalOpen})=> {

    const baseStore = useContext(BaseStoreContext);
    const { createUser, submitting } = baseStore.userStore;
    const [isMainContact, setMainContact] = useState(false);
    const [isAdmin, setAdmin] = useState(false);
    const [isValidator, setValidator] = useState(false);
    const [password, setpassword] = useState();
    const [confirmPassword, setconfirmPassword] = useState();
    const [label, setLabel] = useState<string>();
    const handleChangePassword = (e: any) => {
        setpassword(e.target.value);
        if (e.target.value != confirmPassword ) {
            setLabel("Vérifier votre mot de passe");
        } else {
            setLabel("Mot de passe correcte");
            setconfirmPassword(e.target.value);
        }
    }
    const handleChangeConfirmPasswd = (e: any, result: any) => {
        if (result.value != password) {
            setLabel("Vérifier votre mot de passe");
            setconfirmPassword(result.value);
        } else {
            setLabel("Mot de passe correcte");
            setconfirmPassword(result.value);
        }
    }

    const handleFinalFormSubmit = (values: any) => {
        let newUser: IResponsable = {
            ...values,
            currentCompanyId: id,
            isAdministrator: isAdmin,
            isOrderValidator: isValidator,
        }
        let newUserCompany: IUserCompany = {
            user: newUser,
            isMainContact: isMainContact,
            id: uuid(),
            companyId: id,
        };
        createUser(newUserCompany);
        setModalOpen(false);
    }

    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            render={({ handleSubmit, invalid }) => (
                <Form onSubmit={handleSubmit} error >
                    <Form.Group unstackable widths={3}>
                    <div className="field">
                            <h5>Identifiant</h5>
                            <Field name='login' component={TextInput} placeholder="Identifiant" />
                        </div>
                        <div className="field">
                            <h5>Nom</h5>
                            <Field name='lastName' component={TextInput} placeholder="Nom"  />
                        </div>
                        <div className="field">
                            <h5>Prénom</h5>
                            <Field name='firstName' component={TextInput} placeholder="Prénom" />
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={2}>
                        <div className="field">
                            <label>Civilité </label>
                            <Field component={InputSelect} options={ITListTypeCivilite} name='salutations' placeholder="Civilité" />
                        </div>
                        <div className="field">
                            <label>Email</label>
                            <Field name='email' component={TextInput} type='email' placeholder="Email"/>
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={2}>
                        <div className="field">
                            <label>Téléphone</label>
                            <Field name='phone' component={TextInput} placeholder="Téléphone"/>
                        </div>
                        <div className="field">
                            <label>Téléphone Mobile</label>
                            <Field name='mobile' component={TextInput} placeholder="Téléphone Mobile" />
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={2}>
                        <div className="field">
                            <label>Fonction</label>
                            <Field name='function' component={TextInput} placeholder="Fonction"  />
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={2}>
                    <div className="field">
                            <h5>Nouveau mot de passe</h5>
                            <Input name='password' placeholder="Nouveau mot de passe" onChange={handleChangePassword} type='password'/>
                        </div>
                        <div className="field">
                            <h5>Confirmation du nouveau mot de passe</h5>
                            <Input placeholder="Confirmation du nouveau mot de passe" onChange={handleChangeConfirmPasswd} type='password' />
                            {confirmPassword && ((confirmPassword != password) ? <p className="ui pointing above prompt label " style={{ width: 500 }}>{label}</p> : <p style={{ width: 500 }} className="ui pointing green basic label" >{label}</p>)}
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={3}>
                        <div className="field">
                            <label>Contact principal </label>
                            <Checkbox  value="isMainContact" style={{ marginTop: '10px' }} onChange={() => setMainContact(!isMainContact)}  />
                        </div>
                        <div className="field">
                            <label>Administrateur </label>
                            <Checkbox  value="isAdministrator" style={{ marginTop: '10px' }} onChange={() => setAdmin(!isAdmin)} />
                        </div>
                        <div className="field">
                            <label>Validateur </label>
                            <Checkbox  value="isOrderValidator" style={{ marginTop: '10px' }} onChange={() => setValidator(!isValidator)}  />
                        </div>
                    </Form.Group>
                    <Form.Group unstackable style={{ marginLeft: "70%" }} >
                        <Button.Group floated="right">
                            <Button onClick={() => { setModalOpen(false) }} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive disabled={submitting || invalid || confirmPassword != password } loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </Form.Group>
                </Form>
            )}
        />
    );
}
export default observer(AdduserModal);
