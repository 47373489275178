import { observer } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';
import { Button, Grid, Header, Item, Label, Reveal, Segment } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import Loading from '../../../app/layout/Loading';
import { VscFilePdf } from 'react-icons/vsc';
import { toast } from 'react-toastify';
import { FaPlusCircle } from 'react-icons/fa';
import RemiseAvoirModal from './RemiseAvoirModal';
import { ITVA } from '../../../app/models/ICommande';


const GetDate = (date: Date) => {
    var x = new Date(Date.now());
    var diff = date.getTime() - x.getTime();
    var r = (diff / (1000 * 3600 * 24)).toFixed(0)
    return r;
}

const echeance = (jours: any, remainingPayment: any, dueDate: any) => {
    if (jours < 1 && remainingPayment > 0) {
        return <p style={{ marginLeft: 0, color: "red" }}>Date échéance : {dueDate} <strong> (Retard de Paiement)</strong></p>;
    } else {
        return <p style={{ marginLeft: 0 }}>Date échéance : {dueDate} </p>;
    }


}


const style = {
    margin: 0,
    padding: 0,
    paddingLeft: 5,
}

const styleTitle = {
    margin: 0,
    padding: 0,
    paddingLeft: 5,
    fontWeight: "bold"
}

const numberFormat = new Intl.NumberFormat('FR-fr', {
    style: 'currency',
    currency: 'EUR',
    useGrouping: true,
});

interface IProps {
    id: string,
    reference: string,
    loading: boolean,
    brouillon: (id: number) => Promise<void>;
    valide: (id: number) => Promise<void>;
    remise: number;
}

const FactureHeader: React.FC<IProps> = ({ id, reference, loading, brouillon, valide, remise }) => {

    const baseRepo = useContext(BaseStoreContext);
    const { loadFacture, facture, generatePDF, selectedRemiseAvoir, loadRemiseAffected, sending, SendMail } = baseRepo.factureStore;
    const { openModal } = baseRepo.modalStore;

    useEffect(() => {
        loadFacture(id);
        loadRemiseAffected(parseInt(id));
    }, []);

    const handlePDF = () => {
        generatePDF(id, reference);
    }



    if (!facture)
        return <Loading content="Chargement de la facture ..." />;
    return (
        <Segment>
            <Grid columns={16}>
                <Grid.Column width={9}>
                    <Item.Group style={{ padding: 20 }}>
                        <Item>
                            <Item.Content verticalAlign='middle'>
                                <Header style={{ paddingBottom: 10, fontSize: 18 }}>{facture?.invoiceType} : {facture!.reference}</Header><br />
                                <Header style={{ paddingBottom: 10, fontSize: 14 }}>{facture?.agencyName}-{facture?.companyName}</Header>
                            </Item.Content>
                            <VscFilePdf
                                title="Télécharger PDF"
                                color={baseRepo.factureStore.generatePdfMode ? 'orange' : 'green'}
                                onMouseEnter={() => {
                                    baseRepo.factureStore.changeButtonPdfState(!baseRepo.factureStore.generatePdfMode);
                                }}
                                onMouseLeave={() => {
                                    baseRepo.factureStore.changeButtonPdfState(!baseRepo.factureStore.generatePdfMode);
                                }}
                                style={{ marginLeft: 20, fontSize: "30px", cursor: 'pointer', content: "dddd" }}
                                onClick={handlePDF} />
                        </Item>


                        <Item>
                            <Item.Content verticalAlign='middle' style={{ marginTop: 0 }}>
                                <p>Date facture : {new Date(facture!.invoiceDate).toLocaleDateString('fr-FR')}</p>
                                {facture.invoiceType == 'Facture_Avoir' ? null : echeance(GetDate(new Date(facture.dueDate)), facture.remainingPayment, new Date(facture!.dueDate).toLocaleDateString('fr-FR'))}

                                <span><b>Facturation :</b> {facture!.invoiceCompany}, {facture!.invoiceAddress1} {facture!.invoicePostalCode} {facture!.invoiceCity}, {facture!.invoiceCountry}</span>
                                <br />
                                <span><b>Livraison :</b> {facture!.deliveryCompany}, {facture!.deliveryAddress1} {facture!.deliveryPostalCode} {facture!.deliveryCity}, {facture!.deliveryCountry}</span>
                            </Item.Content>
                            {facture.invoiceType == "Facture" && remise > 0 && facture.remainingPayment > 0 &&
                                <Item.Content verticalAlign='middle' >
                                    <Header as='h3'><a style={{ color: 'red', cursor: 'none' }}>Vous avez {remise} avoirs/remises disponible </a></Header>
                                </Item.Content>
                            }
                        </Item>
                    </Item.Group>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Grid style={{ margin: 10 }}>
                        <Grid.Row style={{ margin: 0, padding: 0 }}>
                            <Grid.Column width={8} style={styleTitle}>
                                Montant HT
                            </Grid.Column>
                            <Grid.Column width={8} style={style}>
                                {numberFormat.format(facture.amountWithoutTaxes)}
                            </Grid.Column>
                            {/*{facture.isManual &&*/}
                            {/*    <>*/}
                            {/*    <hr style={{ textAlign: "center", width: "100%" }} />*/}
                            {/*        <Grid.Column width={8} style={styleTitle}>*/}
                            {/*            Frais Port*/}
                            {/*        </Grid.Column>*/}
                            {/*        <Grid.Column width={8} style={style}>*/}
                            {/*            {numberFormat.format(facture.deliveryCosts)}*/}
                            {/*        </Grid.Column>*/}
                            {/*    </>*/}
                            {/*}*/}

                            {facture.tvaPrices && facture.tvaPrices.map((value: ITVA) => (
                                <>
                                    <hr style={{ textAlign: "center", width: "100%" }} />
                                    <Grid.Column width={8} style={styleTitle}>
                                        TVA {value.key} %
                                    </Grid.Column>
                                    <Grid.Column width={8} style={style}>
                                        {numberFormat.format(value.value)}
                                    </Grid.Column>
                                </>
                            ))}
                            <hr style={{ textAlign: "center", width: "100%" }} />
                            <Grid.Column width={8} style={styleTitle}>
                                Montant TTC
                            </Grid.Column>
                            <Grid.Column width={8} style={style}>
                                {numberFormat.format(facture.amountWithTaxes)}
                            </Grid.Column>
                            {facture.invoiceType != "Facture_Avoir" && selectedRemiseAvoir.length > 0 &&
                                <>
                                    <hr style={{ textAlign: "center", width: "100%" }} />
                                    <Grid.Column width={8} style={styleTitle}>
                                        Avoir
                                    </Grid.Column>
                                    <Grid.Column width={8} style={style}>
                                        {numberFormat.format(selectedRemiseAvoir.map(s => s.amountWithTaxes).reduce((a, b) => a + b, 0))}
                                    </Grid.Column>
                                </>
                            }
                            {facture.invoiceType == "Facture" &&
                                <>
                                    <hr style={{ textAlign: "center", width: "100%" }} />
                                    <Grid.Column width={8} style={styleTitle}>
                                        A payer
                                    </Grid.Column>
                                    <Grid.Column width={8} style={style}>
                                        {numberFormat.format(facture.remainingPayment)}
                                    </Grid.Column>
                                </>}
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
                <Grid.Column width={3} >
                    <Button color="blue" type='submit' style={{ margin: '5px', display: facture.invoiceStatus != 'Brouillon' ? "Block" : "none" }} disabled={sending} loading={sending} onClick={(e) => {
                        SendMail(facture.id)
                    }}  >Envoyer Email</Button>
                    <Reveal animated='move'>
                        <Reveal.Content visible style={{ width: '100%' }}>
                            <Button
                                fluid
                                style={{ display: facture?.isClosed ? 'none' : 'block' }}
                                color={facture?.invoiceStatus == "Valider" ? 'green' : 'red'}
                                content={facture?.invoiceStatus == "Valider" ? 'validée' : 'Brouillon'}
                            />
                        </Reveal.Content>
                        <Reveal.Content hidden>
                            <Button
                                loading={loading}
                                fluid
                                basic
                                style={{ display: facture?.isClosed ? 'none' : 'block' }}
                                color={facture?.invoiceStatus == "Valider" ? 'red' : 'green'}
                                content={facture?.invoiceStatus != "Valider" ? 'Valider' : 'Brouillon'}
                                onClick={() => {
                                    facture?.invoiceStatus == "Valider"
                                        ? brouillon(facture.id)
                                        : facture.invoiceLines.length > 0
                                            ? valide(facture.id)
                                            : toast.warning("veillez ajouter au moins un article")
                                }}
                            />
                        </Reveal.Content>
                    </Reveal>
                    {facture.invoiceType == "Facture" && facture?.invoiceStatus == "Valider" && facture.remainingPayment > 0 && remise > 0 &&
                        <Button
                            style={{ width: "100%" }}
                            content={"Affecter avoirs ou acompte (" + remise + ")"}
                            className="andi-btn filled warning"
                            icon={(<FaPlusCircle style={{ marginRight: "10px", fontSize: "20px" }} />)}
                            onClick={(e) => {
                                openModal(<RemiseAvoirModal />)
                            }}
                        />
                    }
                </Grid.Column>
                {facture!.comment &&
                    <Grid.Column width={16}>
                        <hr />
                        <span style={{ color: 'green' }}>{facture!.comment}</span>
                    </Grid.Column>
                }
            </Grid>
        </Segment >
    );
}
export default observer(FactureHeader);
