import { useContext } from 'react';
import { Button, Grid, Item, } from 'semantic-ui-react'
import { BaseStoreContext } from '../store/baseStore';

const Setting = () => {

    const baseRepo = useContext(BaseStoreContext);
    const { checkMvtExpeditions } = baseRepo.expeditionStore;
    return (
        <Grid style={{margin:50}}>
                <Grid.Column width={6}>
                <Button onClick={() => checkMvtExpeditions()} floated='right' color='green' type='button'>Vérifier les Mouvements</Button>


                </Grid.Column>
            </Grid>
    )
}
export default Setting;