import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Form, Button, Input, Grid } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import { IReglement } from '../../../app/models/IReglement';
import TextInput from '../../../app/common/form/inputText';
import Select from 'react-select';

interface IProps {
    reglement: IReglement;
    setModalOpen: Dispatch<SetStateAction<boolean>>;
}





const EditReglementCommande: React.FC<IProps> = ({ reglement, setModalOpen }) => {

    const baseStore = useContext(BaseStoreContext);
    const { submitting, edit } = baseStore.reglementStore;
    const { ITListModePaiements, loadModePaiements, loadingInitial } = baseStore.modePaiementStore;
    const { ITListAccount, loadAccounts, loadingInitialAccount } = baseStore.accountStore;
    const [modePaiement, setModePaiement] = useState<string | undefined>(reglement.modePaiement);
    const [account, setAccount] = useState<string | undefined>(reglement.account);
    const [creationDate, setCreationDate] = useState<string | undefined>(reglement.creationDate);
    const [montant, setMontant] = useState<number | undefined>(reglement.montant);
    useEffect(() => {
        loadModePaiements();
        loadAccounts();
    }, []);
    const handleChangeMontant = (e: any, result: any) => {
        setMontant(result.value);
    }
    const handleChangeDate = (e: any, result: any) => {
        setCreationDate(result.value);
    }
    const handleFinalFormSubmit = () => {
        let newReglement = {
            idReglement: reglement.idReglement,
            modePaiement: modePaiement,
            creationDate: creationDate,
            account: account,
            montant: montant,
            commande: reglement.commande,
            facture: reglement.invoice,
        };
        edit(reglement!.montant, newReglement);
    }
    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            render={({ handleSubmit, invalid }) => (
                <Form onSubmit={handleSubmit} error >               
                    <Form.Group unstackable widths={2}>
                        <div className="field">
                            <label>Montant</label>
                            <Input name='montant' component={TextInput} placeholder='Montant' defaultValue={montant} type='number' onChange={handleChangeMontant} />
                        </div>
                        <div className="field">
                            <label>Date de paiement</label>
                            <Input name='creationDate' component={TextInput} defaultValue={new Date(reglement.creationDate).toLocaleDateString("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" })} type='date' onChange={handleChangeDate} />
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={2}>
                        <div className="field">
                            <label>Mode de paiement</label>
                            <Select
                                isLoading={loadingInitial}
                                options={ITListModePaiements}
                                getOptionLabel={e => e!.text}
                                getOptionValue={e => e!.value}
                                onChange={e => { setModePaiement(e?.text); }}
                                placeholder={modePaiement ?? "Séléctionnez ... "}
                            />
                        </div>
                        <div className="field">
                            <label>Compte bancaire</label>
                            <Select
                                isLoading={loadingInitialAccount}
                                options={ITListAccount}
                                getOptionLabel={e => e!.text}
                                getOptionValue={e => e!.value}
                                onChange={e => { setAccount(e?.text); }}
                                placeholder={account ?? "Séléctionnez ... "}
                            />
                        </div>
                    </Form.Group>
                    <Form.Group unstackable style={{ marginLeft: "70%" }} >
                        <Button.Group floated="right">
                            <Button onClick={() => { setModalOpen(false) }} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive disabled={submitting || invalid} loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </Form.Group>
                </Form>
            )}
        />
    );
}
export default observer(EditReglementCommande);
