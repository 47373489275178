import { observer } from 'mobx-react-lite';
import React, {useContext, SetStateAction, Dispatch } from 'react';
import { Button, Header, Icon, Container } from 'semantic-ui-react';
import { IUserCompany } from '../../app/models/IUser';
import { BaseStoreContext } from '../../app/store/baseStore';


interface IProps {
    id: number;
    user: IUserCompany;
    setModalOpen: Dispatch<SetStateAction<boolean>>;
}

const DeleteUser: React.FC<IProps> = ({ id, user, setModalOpen }) => {
    const baseStore = useContext(BaseStoreContext);
    const { deleteUser } = baseStore.userStore;

    return (
        <Container textAlign='center'>
            <p>
                Voulez vous supprimer cet utilisateur ?
            </p>

            <Button.Group fluid>
                <Button onClick={() => setModalOpen(false)}>
                    <Icon name='remove' /> Annuler
                </Button>
                <Button.Or text='Ou' />
                <Button
                    name={user.id}
                    color='green'
                    onClick={(e) => {
                        deleteUser(id, user.id)
                        setModalOpen(false)
                    }}>
                    <Icon name='checkmark' /> Confirmer
                </Button>
            </Button.Group>

        </Container>
    )
}
export default observer(DeleteUser);
