import { createContext } from 'react';
import { configure } from 'mobx';
import ArticleStore from './articleStore';
import ModalStore from './modalStore';
import CommonStore from './commonStore';
import UserStore from './userStore';
import ClientStore from './clientStore';
import ProfilStore from './profilStore';
import FranchiseurStore from './franchiseurStore';
import AdresseStore from './adresseStore';
import ModePaiementStore from './modePaiementStore';
import ModeReglementStore from './modeReglementStore';
import FormeJuridiqueStore from './formeJuridiqueStore';
import FournisseurStore from './fournisseurStore';
import FamilleStore from './familleStore';
import CommandeStore from './commandeStore';
import DevisStore from './devisStore';
import TarifFournisseurStore from './tarifFournisseurStore';
import TvaStore from './tvaStore';
import EntrepotStore from './entrepotStore';
import ArticleStockStore from './articleStockStore';
import TarifFranchiseurStore from './tarifFranchiseurStore';
import CommandeFournisseurStore from './commandeFournisseurStore';
import MouvementStockStore from './mouvementStockStore';
import ImpressionStore from './impressionStore';
import AccountStore from './accountStore';
import OptionStore from './OptionStore';
import SupportStore from './SupportStore';
import ReglementStore from './reglementStore';
import StatisticStore from './statisticStore';
import ArticleComposeStore from './articleComposeStore';
import PreparationStore from './preparationStore';
import TypeMarcheStore from './TypeMarcheStore';
import FactureStore from './factureStore';
import ExpeditionStore from './expeditionStore';
import ReceptionStore from './receptionStore';
import FactureFournisseurStore from './factureFounisseurStore';
import ProductAttributesStore from './productAttributesStore';
import CityCodeStore from './cityCodeStore';
import CountryCodeStore from './CountryCodeStore';
import SpecialOfferStore from './specialOfferStore';
import UsersRoleStore from './usersRoleStore';
import InvetoryStore from './inventoryStore';

configure({ enforceActions: "always" });

export class BaseStore {
    articleStore: ArticleStore;
    modalStore: ModalStore;
    commonStore: CommonStore;
    userStore: UserStore;
    clientStore: ClientStore;
    profilStore: ProfilStore;
    franchiseurStore: FranchiseurStore;
    adresseStore: AdresseStore;
    modePaiementStore: ModePaiementStore;
    modeReglementStore: ModeReglementStore;
    formeJuridiqueStore: FormeJuridiqueStore;
    fournisseurStore: FournisseurStore;
    familleStore: FamilleStore;
    commandeStore: CommandeStore;
    devisStore: DevisStore;
    tvaStore: TvaStore;
    tarifFournisseurStore: TarifFournisseurStore
    tarifFranchiseurStore: TarifFranchiseurStore;
    entrepotStore: EntrepotStore;
    articleStockStore: ArticleStockStore;
    mouvementStockStore: MouvementStockStore;
    impressionStore: ImpressionStore;
    optionStore: OptionStore;
    supportStore: SupportStore;
    commandeFournisseurStore: CommandeFournisseurStore;
    statisticStore: StatisticStore;
    accountStore: AccountStore;
    reglementStore: ReglementStore;
    articleComposeStore: ArticleComposeStore;
    preparationStore: PreparationStore;
    TypeMarcheStore: TypeMarcheStore;
    factureStore: FactureStore;
    expeditionStore: ExpeditionStore;
    receptionStore: ReceptionStore;
    factureFournisseurStore: FactureFournisseurStore;
    productAttributesStore: ProductAttributesStore;
    cityCodeStore: CityCodeStore;
    countryCodeStore: CountryCodeStore;
    specialOfferStore: SpecialOfferStore;
    usersRoleStore: UsersRoleStore;
    inventoryStore: InvetoryStore;

    constructor() {
        this.articleStore = new ArticleStore(this);
        this.modalStore = new ModalStore(this);
        this.commonStore = new CommonStore(this);
        this.userStore = new UserStore(this);
        this.clientStore = new ClientStore(this);
        this.profilStore = new ProfilStore(this);
        this.franchiseurStore = new FranchiseurStore(this)
        this.adresseStore = new AdresseStore(this)
        this.modePaiementStore = new ModePaiementStore(this)
        this.modeReglementStore = new ModeReglementStore(this)
        this.formeJuridiqueStore = new FormeJuridiqueStore(this)
        this.fournisseurStore = new FournisseurStore(this)
        this.familleStore = new FamilleStore(this)
        this.commandeStore = new CommandeStore(this)
        this.devisStore = new DevisStore(this)
        this.tvaStore = new TvaStore(this)
        this.tarifFournisseurStore = new TarifFournisseurStore(this)
        this.entrepotStore = new EntrepotStore(this)
        this.articleStockStore = new ArticleStockStore(this)
        this.tarifFranchiseurStore = new TarifFranchiseurStore(this)
        this.mouvementStockStore = new MouvementStockStore(this)
        this.impressionStore = new ImpressionStore(this)
        this.optionStore = new OptionStore(this)
        this.supportStore = new SupportStore(this)
        this.statisticStore = new StatisticStore(this)
        this.commandeFournisseurStore = new CommandeFournisseurStore(this)
        this.accountStore = new AccountStore(this)
        this.reglementStore = new ReglementStore(this)
        this.articleComposeStore = new ArticleComposeStore(this)
        this.preparationStore = new PreparationStore(this)
        this.TypeMarcheStore = new TypeMarcheStore(this)
        this.factureStore = new FactureStore(this)
        this.expeditionStore = new ExpeditionStore(this)
        this.receptionStore = new ReceptionStore(this)
        this.factureFournisseurStore = new FactureFournisseurStore(this)
        this.productAttributesStore = new ProductAttributesStore(this)
        this.cityCodeStore = new CityCodeStore(this);
        this.countryCodeStore = new CountryCodeStore(this);
        this.specialOfferStore = new SpecialOfferStore(this);
        this.usersRoleStore = new UsersRoleStore(this);
        this.inventoryStore = new InvetoryStore(this);
    }
}

export const BaseStoreContext = createContext(new BaseStore());