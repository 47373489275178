import React, { useContext } from 'react';
import { Button, Header, Icon, Container } from 'semantic-ui-react';
import { IDevis } from '../../../app/models/IDevis';
import { BaseStoreContext } from '../../../app/store/baseStore';

interface IProps {
    devis: IDevis;
}

const DevisCommandeModal: React.FC<IProps> = ({ devis }) => {
    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    const { devisCommande, submitting } = baseStore.devisStore;
    return (
        <Container textAlign='center'>
            <Header icon='add' content='Transformer  Devis' style={{ marginLeft: 20 }} />
            <p>
                Voulez vous valider et transformer ce devis en une commande  ?
            </p>
            <Button.Group fluid>
                <Button onClick={() => closeModal()}>
                    <Icon name='remove' /> Annuler
                </Button>
                <Button.Or text='Ou' />
                <Button
                    color='green'
                    onClick={(e) => {
                        devisCommande(devis.id);
                        closeModal();
                    }} loading={submitting}>
                    <Icon name='checkmark' /> Confirmer
                </Button>
            </Button.Group>
        </Container>
    )
}
export default DevisCommandeModal;
