import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Icon, Item, ItemContent } from 'semantic-ui-react';
import { isBoolean } from 'util';

interface IProps {
    header?: string,
    content?: any,
    root?: string,
    icon?: any,
    iconColor?: any,
}
const FormGridColumn: React.FC<IProps> = ({ header, content, root, icon, iconColor }) => {
    return (
        <Grid.Column width={8} style={{ padding: 0, margin: 0, fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif' }} >
            <Item style={{ borderTop: "1px solid #eee", marginRight: 10 }}>
                {content == null ? (
                    <div style={{ fontWeight: 'bold', float: "left", fontSize: 12, padding: 10 }}>
                        {icon ? (<Icon color={iconColor} name={icon} />) : (null)}{header}
                    </div>
                ) : (
                    <div style={{ fontWeight: 'bold', float: "left", width: "50%", fontSize: 12, padding: 10 }}>
                        {icon ? (<Icon color={iconColor} name={icon} />) : (null)}{header}
                    </div>
                )}
                {isBoolean(content) ? (
                    <Icon name='circle' color={content ? "green" : "red"} style={{ float: "left", fontSize: 12, padding: 10 }} />
                ) : (content != null ?
                    (
                        root ? (
                            <ItemContent as={Link} to={root} style={{ float: "left", width: "50%", fontSize: 12, padding: 10 }}>{content}
                            </ItemContent>
                        ) : (
                            <ItemContent style={{ float: "left", width: "50%", fontSize: 12, padding: 10 }}>{content}
                            </ItemContent>)
                    ) : (null)
                )}
            </Item>
        </Grid.Column>
    );
}
export default FormGridColumn;