import { List, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { IOrderLine } from '../../../app/models/ICommande';
import { Link } from 'react-router-dom';
import React from 'react';

interface IProps {
    order: IOrderLine;
}
const DetailOrderLine: React.FC<IProps> = ({ order }) => {
    return (
        <Grid>
            <Grid.Column width={16}>
                    <Grid columns={2} divided>
                        <Grid.Row>
                            <Grid.Column>
                                <List>
                                    <List.Item>
                                    <List.Icon name='sidebar' />
                                    <List.Content as={Link} to={`/detailArticle/${order.productId}`}><h5>Article: &nbsp;{order!.articleName}</h5></List.Content>
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column>
                                <List>
                                    <List.Item>
                                    <List.Icon name='sidebar' />
                                    <List.Content><h5>TVA: &nbsp;{order!.tvaRate}</h5></List.Content>
                                    </List.Item>
                                </List>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <List>
                                    <List.Item>
                                    <List.Icon name='sidebar' />
                                    <List.Content><h5>Prix unitaire: &nbsp;{order!.unitPrice}</h5></List.Content>
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column>
                                <List>
                                    <List.Item>
                                    <List.Icon name='sidebar' />
                                    <List.Content><h5>Quantité: &nbsp;{order!.quantity}</h5></List.Content>
                                    </List.Item>
                                </List>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <List>
                                    <List.Item>
                                    <List.Icon name='sidebar' />
                                    <List.Content><h5>Format: &nbsp;{order!.paperFormat}</h5></List.Content>
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column>
                                <List>
                                    <List.Item>
                                    <List.Icon name='sidebar' />
                                    <List.Content><h5>poids: &nbsp;{order!.paperWeight}</h5></List.Content>
                                    </List.Item>
                                </List>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <List>
                                    <List.Item>
                                    <List.Icon name='sidebar' />
                                    <List.Content><h5>Frais de livraison: &nbsp;{order!.deliveryCosts}</h5></List.Content>
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column>
                                <List>
                                    <List.Item>
                                    <List.Icon name='sidebar' />
                                    <List.Content><h5>Delai de livraison: &nbsp;{order!.deliveryDelay}</h5></List.Content>
                                    </List.Item>
                                </List>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
            </Grid.Column>
        </Grid>
        );
}
export default observer(DetailOrderLine);