import React, { useContext, useEffect, useState } from 'react';
import { GridColumn, Grid, Loader, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import ListItemPlaceholder from '../../../app/common/form/ListItemPlaceholder';
import InfiniteScroll from 'react-infinite-scroller';
import Breadcrumbs from '../../dashboard/breadcrumbs';
import ReglementList from './ReglementList';
import ReglementFiltre from './ReglementFiltre';

const CommandesDashboard: React.FC = () => {

    const baseStore = useContext(BaseStoreContext);
    const { loadReglements, loadingInitial, page, totalPages, setPage } = baseStore.reglementStore;
    const [loadingNext, setLoadingNext] = useState(false);

    const handleGetNext = () => {
        setLoadingNext(true);
        setPage(page + 1);
        loadReglements().then(() => setLoadingNext(false));
    }


    useEffect(() => {
        loadReglements();
    }, []);

    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Reglements", root: "" },
                    { key: 1, name: "Reglements", root: "/reglement" },
                ]}
            />
            <Grid>

                <Grid.Row style={{ padding: 0, margin: "10px 0 0 10px" }}>
                    <ReglementFiltre />
                </Grid.Row>
                <GridColumn width={16} style={{ marginTop: 2 }}>
                    <Grid style={{ marginTop: 2 }}>
                        <GridColumn width={16}>
                            {loadingInitial && page === 0 ? <ListItemPlaceholder /> : (
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={handleGetNext}
                                    hasMore={!loadingNext && page + 1 < totalPages}
                                    initialLoad={false}
                                >
                                    <ReglementList />
                                </InfiniteScroll>
                            )}
                        </GridColumn>
                    </Grid>
                </GridColumn>

                <Grid.Column width={10}>
                    <Loader active={loadingNext} />
                </Grid.Column>
            </Grid>
        </div>
    );
}
export default observer(CommandesDashboard);