import React, { useContext, useEffect, useState } from 'react';
import { GridColumn, Grid, Loader, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';
import ListFournisseur from './ListFournisseur';
import FournisseurFilter from './FournisseurFilter';
import ListItemPlaceholder from '../../../app/common/form/ListItemPlaceholder';
import Breadcrumbs from '../../dashboard/breadcrumbs';
import { FaFileExcel } from 'react-icons/fa';

const FournisseurDashboard: React.FC = () => {

    const baseStore = useContext(BaseStoreContext);
    const { loadFournisseurs, loadingInitial, page, totalPages, setPage, loadingExport, ExportSuppliers } = baseStore.fournisseurStore;
    const [loadingNext, setLoadingNext] = useState(false);

    const handleGetNext = () => {
        setLoadingNext(true);
        setPage(page + 1);
        loadFournisseurs().then(() => setLoadingNext(false));
    }

    useEffect(() => {
        loadFournisseurs();
    }, [loadFournisseurs]);

    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0,name: "Tiers", root: "" },
                    { key: 1,name: "Fournisseurs", root: "/fournisseurDashboard" },
                ]}
            />
        <Grid>
            <GridColumn width={10} style={{ marginTop: 2 }}>
                <Grid style={{ marginTop: 2 }}>
                    <GridColumn width={16}>
                        {loadingInitial && page === 0 ? <ListItemPlaceholder /> : (
                            <InfiniteScroll
                                pageStart={0}
                                loadMore={handleGetNext}
                                hasMore={!loadingNext && page + 1 < totalPages}
                                initialLoad={false}
                            >
                                <ListFournisseur />
                            </InfiniteScroll>
                        )}
                    </GridColumn>
                </Grid>
            </GridColumn>
            <Grid.Column width={6}>
                <Grid.Column width={16}>
                </Grid.Column>
                    <Button
                        style={{ marginTop: 20 }}
                        content="Export"
                        icon={(<FaFileExcel />)}
                        loading={loadingExport}
                        onClick={() => ExportSuppliers()}
                        color="green"
                    />
                <Button
                    style={{ marginTop: 10 }}
                    floated="right"
                    content="Ajouter Fournisseur"
                    color="orange"
                    icon='add circle'
                    basic
                    as={Link}
                    to="/addFournisseur"
                />
                <FournisseurFilter />
            </Grid.Column>
            <Grid.Column width={16}>
                <Loader active={loadingNext} />
            </Grid.Column>
        </Grid>
        </div>
    );
}
export default observer(FournisseurDashboard);