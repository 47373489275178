import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Container, Grid, Icon, Loader, Modal, Table } from 'semantic-ui-react';
import { BaseStoreContext } from '../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import StatisticsCount from './StatisticsCount';
import TotalCount from './TotalCount';
import { MdArticle, MdOutlineArticle } from 'react-icons/md';
import { FaUsers, FaUserTie } from 'react-icons/fa';
import Breadcrumbs from './breadcrumbs';
import OfferDetailModal from '../franchiseur/DetailFranchiseur/OfferDetailModal';
import { ISpecialOffer } from '../../app/models/ISpecialOffer';
import { Link } from 'react-router-dom';
import StatisticsBarChart from './StatisticsBarChart';
import StatisticsLineChart from './StatisticsLineChart';

const DashboardContent: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { statistics, commandeCount, clientCount, clientInactifCount, blCountDelivered, blCountWait, prepareCountDelivered, fournisseurCount, fournisseurInactifCount, franchiseurCount, franchiseurInactifCount, produitESCount, produitHSCount, load, loadingInitial, blTodayCountDelivered, blTodayCountWait, commandeTodayCount, prepareTodayCountDelivered } = baseStore.statisticStore;
    const { getListCardOffer, listSpecialOffers } = baseStore.specialOfferStore;

    const [modalDetailsOpen, setModalDetailsOpen] = useState(false);
    const [offer, setOffer] = useState<ISpecialOffer>();

    useEffect(() => {
        getListCardOffer();
    }, []);

    if (loadingInitial)
        return (<Loader active={loadingInitial} content='Chargement ...' />)
    return (
        <div id="dashboardPage" >
            <Breadcrumbs
                items={[{ key: 0, name: "Dashborad", root: "/menuPage" }]}
            />
            <Grid style={{ margin: 20 }}>
                <Grid.Row columns={4} className="ui grid stackable" style={{ backgroundColor: "rgba(255,255,255,.7)", borderRadius: 10 }}>
                    <TotalCount backgroundIcon="#e32c26"
                        backgroundColor="#04AA6D"
                        total={fournisseurCount}
                        count={fournisseurCount}
                        minus={fournisseurInactifCount}
                        name="Fournisseurs"
                        isDashboard={true}
                        iconReact={FaUserTie} />
                    <TotalCount backgroundIcon="#ff9307"
                        backgroundColor="rgb(3, 169, 245)"
                        total={franchiseurCount}
                        count={franchiseurCount}
                        minus={franchiseurInactifCount}
                        name="Franchiseurs"
                        isDashboard={true}
                        iconReact={FaUsers} />
                    <TotalCount backgroundIcon="#1757b8"
                        backgroundColor="rgb(244, 96, 152)"
                        total={clientCount}
                        count={clientCount}
                        minus={clientInactifCount}
                        name="Clients"
                        isDashboard={true}
                        iconReact={FaUsers} />
                    <TotalCount backgroundIcon="#207834"
                        total={produitESCount}
                        backgroundColor="#4fd165"
                        count={produitHSCount}
                        minus={produitESCount}
                        isDashboard={true}
                        name="Produit Hors Stock"
                        iconReact={MdOutlineArticle} />
                </Grid.Row>
                <Grid.Row columns={5} className="ui grid stackable" style={{ marginTop: 0, paddingBottom:0 }}>
                    <StatisticsCount
                        colorStart="#17a2b8"
                        colorEnd="#1757b8"
                        total={commandeCount}
                        count={commandeTodayCount}
                        name=" Commandes" />
                    <StatisticsCount
                        colorStart="#28a745"
                        colorEnd="#207834"
                        total={blCountDelivered}
                        count={blTodayCountDelivered}
                        name=" BL expidiée" />
                    <StatisticsCount
                        colorStart="#e32c26"
                        colorEnd="#b51317"
                        total={blCountWait}
                        count={blTodayCountWait}
                        name=" Bl en cours " />
                    <StatisticsCount
                        colorStart="#ffc107"
                        colorEnd="#ff9307"
                        total={prepareCountDelivered}
                        count={prepareTodayCountDelivered}
                        name=" Preparations" />
                    <StatisticsCount
                        colorStart="#28a745"
                        colorEnd="#207834"
                        total={listSpecialOffers.length}
                        count={listSpecialOffers.length}
                        name=" Achats Groupés " />
                </Grid.Row>
                <Grid.Row columns={2} className="ui grid stackable" style={{ marginTop: 0 , padding : 0}}>
                    <StatisticsBarChart sales={statistics?.sales!} purchases={statistics?.purchases!} />
                    {/*<StatisticsLineChart />*/}
                    <Grid.Column>
                        <Card style={{ width: '100%', backgroundColor: "#ddd" }} >
                            <Card.Content>
                                <Button
                                    floated='right'
                                    color="linkedin"
                                    style={{ fontSize: 12 }}
                                    as={Link}
                                    to="/offreDashboard"
                                >
                                    <Icon name='eye' /> Voir tout
                                </Button>
                                <Grid.Row columns={2}>
                                    <Card.Header> <Icon name='object group outline' size='small' color="blue" /> <label style={{ fontSize: 25, color: 'DodgerBlue', fontWeight: 'bold', textDecoration: 'underline' }}> Achats Groupées </label> </Card.Header>
                                </Grid.Row>
                            </Card.Content>
                            <Card.Content style={{ padding: '10px 1px 10px 1px'}}>
                                <Table compact='very' celled striped stackable style={{}} textAlign='center' >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Réseaux</Table.HeaderCell>
                                            <Table.HeaderCell>Produit</Table.HeaderCell>
                                            <Table.HeaderCell>Date debut</Table.HeaderCell>
                                            <Table.HeaderCell>Date fin</Table.HeaderCell>
                                            <Table.HeaderCell>Quantite</Table.HeaderCell>
                                            <Table.HeaderCell>Details</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {listSpecialOffers.map((offer: any) => (

                                            <>
                                                <Table.Row key={offer.id}>
                                                    <Table.Cell>{offer.reseaux}</Table.Cell>
                                                    <Table.Cell>{offer.productLabel}</Table.Cell>
                                                    <Table.Cell>{new Date(offer.startDate).toLocaleDateString('en-GB')}</Table.Cell>
                                                    <Table.Cell>{new Date(offer.endDate).toLocaleDateString('en-GB')}</Table.Cell>
                                                    <Table.Cell>{offer.currentOrderedQuantity}</Table.Cell>
                                                    <Table.Cell textAlign='center'>
                                                        <Button
                                                            onClick={(e) => {
                                                                setModalDetailsOpen(true);
                                                                setOffer(offer);
                                                            }}
                                                            basic
                                                            color="blue"
                                                            icon='info'
                                                            style={{ borderRadius: '100%', height: 30, width: 30, fontSize: 10 }}
                                                        />
                                                    </Table.Cell>
                                                </Table.Row>
                                            </>

                                        ))}
                                    </Table.Body>
                                </Table>

                            </Card.Content>
                        </Card>
                    </Grid.Column>

                </Grid.Row>

            </Grid>

            <Modal
                open={modalDetailsOpen}
                size='small'
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >
                <Modal.Header icon='add' style={{ marginLeft: 20 }} >
                    Details de l'offre {offer?.promotionCode}
                </Modal.Header>

                <Modal.Content scrolling>
                    <OfferDetailModal offer={offer!} />
                </Modal.Content>

                <Modal.Actions>
                    <div style={{ margin: 15, display: 'inline-block' }}>
                        <Button
                            type='button'
                            floated='right'
                            onClick={() => {
                                setModalDetailsOpen(false);
                            }}
                        >
                            <Icon name='remove' /> Retour
                        </Button>
                    </div>
                </Modal.Actions>
            </Modal>

        </div>
    );
}
export default observer(DashboardContent);