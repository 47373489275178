import React from 'react';
import { Header, Grid, Item, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';


const AddArticleHeader: React.FC = ({ }) => {
    return (
        <Grid>
            <Grid.Column width={16}>
                <Item.Group>

                    <Item>
                        <Item.Content>
                            <Header as='h2' icon='address card outline'></Header>
                            <Header as='h2'>{'Ajouter Article'}</Header>
                            <Button
                                floated="right"
                                content="Retour vers articles"
                                color="orange"
                                icon='arrow alternate circle left outline'
                                basic
                                as={Link}
                                to="/articles"
                            />
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Grid.Column>
        </Grid>
    );
};
export default observer(AddArticleHeader);