import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { Button, Container, Form, Grid, Header, Segment, Tab, Table } from "semantic-ui-react";
import Loading from "../../app/layout/Loading";
import { BaseStoreContext } from "../../app/store/baseStore";
import Breadcrumbs from '../dashboard/breadcrumbs';
import { Field, Form as FinalForm } from 'react-final-form';
import TextInput from "../../app/common/form/inputText";
import { combineValidators, composeValidators, isRequired } from 'revalidate';
import { Link } from "react-router-dom";
import DeleteRoleModal from "./DeleteRoleModal";
import { Action, Module } from "../../app/common/ModuleClaims/ModulePermissions";
import { IUserClaims } from "../../app/models/IUsersRoles";


const validate = combineValidators({
    name: composeValidators(
        isRequired,
    )({
        message: 'Veuillez enter un nouveau role'
    })

});

const ListeRoles = () => {

    const baseStore = useContext(BaseStoreContext);
    const { listAllRoles, loadRoles, loadingInitialRoles, createRole } = baseStore.usersRoleStore;

    const { openModal } = baseStore.modalStore;

    const [DeleteClaim, setDeleteClaim] = useState(false);
    const [ManageClaim, setManageClaim] = useState(false);
    const [AddRoleDisable, setAddRoleDisable] = useState(false);

    useEffect(() => {
        var res = sessionStorage.getItem("userClaimsSession");
        var listeUC: IUserClaims[] = JSON.parse(res!);
        listeUC = listeUC.filter((i) => i.module == Module[Module.Roles]);
        
        if (listeUC.some(i => i.action == Action[Action.View]))
            loadRoles();

        if (listeUC.some(i => i.action == Action[Action.Delete]))
            setDeleteClaim(true);

        if (listeUC.some(i => i.action == Action[Action.Edit]))
            setManageClaim(true);

        if (listeUC.some(i => i.action == Action[Action.Create]))
            setAddRoleDisable(true);

    }, []);

    const matchRole = (value: any) => {
        if (listAllRoles.find(x => x.name == value)) {
            return `Le Role ${value} existe`;
        } else
            return undefined;
    }

    const handleFinalFormSubmit = (values: any) => {
        let newRole = {
            ...values,
        };
        createRole(newRole).then(() => loadRoles());
    }

    if (loadingInitialRoles) return <Loading content="Chargement des roles ..." />
    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Admin", root: "" },
                    { key: 1, name: "Liste_Roles", root: "/allRoles" },
                ]}
            />

            <Grid>
                <Grid.Column width={14} style={{ paddingBottom: 0 }} >
                    {AddRoleDisable && (
                    <Segment>
                        <FinalForm
                            onSubmit={handleFinalFormSubmit}
                            validate={validate}
                            render={({ handleSubmit, invalid }) => (
                                <Form onSubmit={handleSubmit} error >
                                    <Grid columns={2}>
                                       
                                        <Grid.Column width={10}>
                                            <Field fluid name='name' component={TextInput} placeholder='Nom du role' validate={matchRole} />
                                        </Grid.Column>

                                        <Grid.Column width={4}>
                                            
                                                <Button fluid size='large'
                                                    positive type='submit'
                                                disabled={invalid}
                                                >Ajouter role</Button>
                                           
                                        </Grid.Column>
                                       

                                    </Grid>

                                </Form>
                            )}
                        />
                    </Segment>
                    )}
                    <Tab.Pane>
                        <Table loading={false} celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Id</Table.HeaderCell>
                                    <Table.HeaderCell>Role </Table.HeaderCell>
                                    {ManageClaim && (
                                        <Table.HeaderCell style={{ textAlign: "center" }}>Actions</Table.HeaderCell>
                                    )}
                                    {DeleteClaim && (
                                        <Table.HeaderCell style={{ textAlign: "center" }}>Suppression</Table.HeaderCell>
                                        )}
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {listAllRoles && listAllRoles.map((role: any) => (
                                    <Table.Row key={role.id}>
                                        <Table.Cell>{role.id}</Table.Cell>
                                        <Table.Cell>{role.name}</Table.Cell>
                                        {ManageClaim && (
                                        <Table.Cell style={{ textAlign: "center" }}>
                                            <Button
                                                as={Link}
                                                to={`/listePremissions/${role.id}`}
                                                basic
                                                color="blue"
                                                icon='edit outline'
                                                style={{ marginRight: '2%' }}

                                            > Manage Permissions</Button>
                                            </Table.Cell>
                                        )}
                                        {DeleteClaim  && (
                                            <Table.Cell style={{ textAlign: "center" }}>
                                                <Button
                                                    negative
                                                    basic
                                                    size="small"
                                                    type='button'
                                                    style={{ marginRight: '2%' }}
                                                    onClick={() => {
                                                        baseStore.usersRoleStore.getUsersByRole(role.name).then(() =>
                                                            openModal(
                                                                <DeleteRoleModal role={role.name} />
                                                            ))
                                                    }
                                                    }
                                                > Supprimer</Button>
                                            </Table.Cell>
                                            )}
                                        
                                    </Table.Row>
                                )
                                )}
                            </Table.Body>
                        </Table>
                    </Tab.Pane>
                </Grid.Column>
            </Grid>
        </div>
    );
}

export default observer(ListeRoles);