import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';
import { Button, Grid, Header, Item, Label, Reveal, Segment } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { VscFilePdf } from 'react-icons/vsc';
import { IFactureFournisseur } from '../../../app/models/IFactureFournisseur';
import DeleteFactureFournisseurModal from '../factureDashboard/DeleteFactureFournisseur';

const style = {
    margin: 0,
    padding: 0,
    paddingLeft: 5,
}

const styleTitle = {
    margin: 0,
    padding: 0,
    paddingLeft: 5,
    fontWeight: "bold"
}

const numberFormat = new Intl.NumberFormat('FR-fr', {
    style: 'currency',
    currency: 'EUR',
    useGrouping: true,
});

interface IProps {
    facture: IFactureFournisseur,
    id: string,
}

const FactureHeader: React.FC<IProps> = ({facture ,id}) => {

    const baseRepo = useContext(BaseStoreContext);
    const { submitting, brouillon, valide, generatePDF, deletingOrder } = baseRepo.factureFournisseurStore;
    const { openModal } = baseRepo.modalStore;
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    const handlePDF = () => {
        generatePDF(parseInt(id));
    }
    return (
        <Segment>
            <Grid columns={16}>
                <Grid.Column width={12}>
                    <Item.Group style={{ padding: 20 }}>
                        <Item>
                            <Item.Content verticalAlign='middle'>
                                <Header as='h1'>Facture : {facture!.reference}</Header>
                            </Item.Content>
                            <VscFilePdf
                                title="Télécharger PDF"
                                color={baseRepo.factureStore.generatePdfMode ? 'orange' : 'green'}
                                onMouseEnter={() => {
                                    baseRepo.factureStore.changeButtonPdfState(!baseRepo.factureStore.generatePdfMode);
                                }}
                                onMouseLeave={() => {
                                    baseRepo.factureStore.changeButtonPdfState(!baseRepo.factureStore.generatePdfMode);
                                }}
                                style={{ marginLeft: 20, fontSize: "30px", cursor: 'pointer', content: "dddd" }}
                                onClick={handlePDF} 
                            />
                        </Item>
                        <div>
                            <Label as='a' basic color='orange'>
                                {facture?.invoiceType}
                            </Label>
                        </div>
                       
                    </Item.Group>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Grid style={{ margin: 10 }}>
                        <Grid.Row style={{ margin: 0, padding: 0 }}>
                            <Grid.Column width={8} style={styleTitle}>
                                Montant HT
                            </Grid.Column>
                            <Grid.Column width={8} style={style}>
                                {numberFormat.format(facture.amountWithoutTaxes)}
                            </Grid.Column>
                            <hr style={{ textAlign: "center", width: "100%" }} />
                            <Grid.Column width={8} style={styleTitle}>
                                Montant TVA
                            </Grid.Column>
                            <Grid.Column width={8} style={style}>
                                {numberFormat.format(facture.amountWithTaxes - facture.amountWithoutTaxes)}
                            </Grid.Column>
                            <hr style={{ textAlign: "center", width: "100%" }} />
                            <Grid.Column width={8} style={styleTitle}>
                                Montant TTC
                            </Grid.Column>
                            <Grid.Column width={8} style={style}>
                                {numberFormat.format(facture.amountWithTaxes)}
                            </Grid.Column>
                            
                        </Grid.Row>
                    </Grid>
                    {facture.invoiceState == "Brouillon" &&
                        <Button.Group vertical style={{ width: '100%', marginTop: 4 }}>
                            <Button
                                floated='right'
                                style={{ height: 40 }}
                                size="small"
                                color={'red'}
                                basic
                                icon='trash'
                                content={"Supprimer la commande"}
                                onClick={(e) => {
                                    openModal(<DeleteFactureFournisseurModal commande={facture} />)
                                    setDeleteTarget(e.currentTarget.name)
                                }}
                                loading={deletingOrder && deleteTarget === facture.id.toString()}
                            />
                        </Button.Group>
                    }
                    {facture.amountWithoutTaxes == 0 ? (
                        <Reveal >
                            <Reveal.Content visible style={{ width: '100%' }}>
                                <Button
                                    size='small'
                                    fluid
                                    color={'red'}
                                    content={'En Attente '}
                                />

                            </Reveal.Content>
                        </Reveal>
                    ) : (
                        <Reveal animated='move'>
                            <Reveal.Content visible style={{ width: '100%' }}>
                                <Button
                                    fluid
                                    color={facture?.invoiceState == "Valider" ? 'green' : 'red'}
                                    content={facture?.invoiceState == "Valider" ? 'validée' : 'Brouillon'}
                                />
                            </Reveal.Content>
                            <Reveal.Content hidden>
                                <Button
                                    loading={submitting}
                                    fluid
                                    basic
                                    color={facture?.invoiceState == "Valider" ? 'red' : 'green'}
                                    content={facture?.invoiceState != "Valider" ? 'Valider' : 'Brouillon'}
                                    onClick={() => {
                                        facture?.invoiceState == "Valider" ? brouillon(facture.id) : valide(facture.id)
                                    }}
                                />
                            </Reveal.Content>
                        </Reveal>
                    )}
                </Grid.Column>
            </Grid>
        </Segment >
    );
}
export default observer(FactureHeader);