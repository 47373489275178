import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { Button, Grid, Header, Tab } from 'semantic-ui-react';
import FormGridColumn from '../../../app/common/form/FormGridColumn';
import Loading from '../../../app/layout/Loading';
import { BaseStoreContext } from '../../../app/store/baseStore';
import FactureClientEditForm from './FactureClientEditForm';

const FactureClient = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { facture, editInformationClientFacture, submitting } = baseRepo.factureStore;
    const { loadClient, client, loadingClient } = baseRepo.clientStore;
    const { loadFranchiseur, franchiseur, loadingFranchiseur } = baseRepo.franchiseurStore;
    const [isClient] = useState(facture!.typeClient == "Franchiseur");

    useEffect(() => {
        isClient ? loadFranchiseur(facture!.idCompany!) : loadClient(facture!.idCompany!);
    }, []);

    if (isClient ? loadingFranchiseur : loadingClient) return <Loading content="Chargement des informations ..." />
    else {
        return (
            <Tab.Pane >
                <Grid>
                    <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                        <Header floated='left' icon='user' content={`Information de client: ${facture!.agencyName}` + '-' + `${facture!.companyName}`} />
                        {facture!.invoiceStatus == 'Brouillon' &&
                            <Button
                                onClick={() => { baseRepo.factureStore.changeButtonState(!baseRepo.factureStore.editDFactureMode) }}
                                floated='right'
                                basic
                                content={baseRepo.factureStore.editDFactureMode ? 'Annuler' : 'Modifier'}
                            />
                        }
                    </Grid.Column>

                    <Grid.Column width={16}>
                        {baseRepo.factureStore.editDFactureMode ? (
                            <FactureClientEditForm editFacture={editInformationClientFacture} facture={facture!} submitting={submitting} />
                        ) : (
                            <Grid className="ui stackable">
                                <Grid.Row style={{ padding: 0, margin: 0 }}>
                                        <FormGridColumn header="Téléphone" content={isClient ? franchiseur?.invoicePhone : client?.invoicePhone} />
                                        <FormGridColumn header="Téléphone Mobile" content={isClient ? franchiseur?.invoiceFax : client?.invoiceFax} />
                                        <FormGridColumn header="Email" content={isClient ? franchiseur?.invoiceEmails : client?.invoiceEmails} />
                                        <FormGridColumn header="Adresse" content={isClient ? franchiseur?.invoiceAddress1 : client?.invoiceAddress1} />
                                        <FormGridColumn header="Interphone" content={isClient ? franchiseur?.deliveryInterphone : client?.deliveryInterphone} />
                                        <FormGridColumn header="Ville" content={isClient ? franchiseur?.invoiceCity : client?.invoiceCity} />
                                        <FormGridColumn header="Code Postal" content={isClient ? franchiseur?.invoicePostalCode : client?.invoicePostalCode} />

                                        <FormGridColumn header="Horaires d'ouverture" content={isClient ? franchiseur?.deliveryOpeningHours : client?.deliveryOpeningHours} />
                                        <FormGridColumn header="Jours de fermeture" content={isClient ? franchiseur?.deliveryClosingDays : client?.deliveryClosingDays} />
                                </Grid.Row>
                            </Grid>
                        )}
                    </Grid.Column>

                </Grid>
            </Tab.Pane >
        );
    }

}
export default observer(FactureClient);