import React, { useState, useContext } from 'react';
import { Button, Header, Icon, Container } from 'semantic-ui-react';
import { IEntrepot } from '../../../app/models/IEntrepot';
import { BaseStoreContext } from '../../../app/store/baseStore';

interface IProps {
    count: number
}

const DisableEntrepotModal: React.FC<IProps> = ({count }) => {
    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    return (
        <Container textAlign='center'>
            <Button
                type='button'
                onClick={() => {
                    closeModal()
                }}

                icon='x'
                color='red'
                style={{ top:5,right:5, position:'absolute'}}
                basic
            ></Button>
            <Header icon='trash' content='Désactiver entrepot' style={{ marginLeft: 20 }} />
            <p> Vous ne pouvez pas désactiver cet entrepot car il a {count} articles           </p>
            
        </Container>
    )
}
export default DisableEntrepotModal;
