import React, { useContext, useEffect, useState } from 'react';
import { Form, Item, Table, Button, Header, Progress } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import Loading from '../../../app/layout/Loading';
import ModeReglementForm from './AddModeReglementModal';
import { observer } from 'mobx-react-lite';
import TextInput from '../../../app/common/form/inputText';
import DeleteModeReglementModal from './DeleteModeReglementModal';
import Breadcrumbs from '../../dashboard/breadcrumbs';
import { TiInputChecked } from 'react-icons/ti';

const ListModeReglement: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { loadModeReglements, loadingInitial, edit, modeReglements, loadingModeReglementAction, progress, submitting } = baseStore.modeReglementStore;
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    const [editTarget, setEditTarget] = useState<string | undefined>(undefined);
    const [editForm, setEditForm] = useState(false);
    const [label, setLabel] = useState<string | undefined>(undefined);
    const { openModal } = baseStore.modalStore;
    const [dayss, setDayss] = useState<any>(0)
    const [monthEnd, setMonthEnd] = useState<any>(false)
    const [forceDay, setForceDay] = useState<any>(0)
    const handleChangeDayss = (event: any) => {
        setDayss(event.target.value);
    }
    const handleChangeForceDays = (event: any) => {
        setForceDay(event.target.value);
    }
    const handleFinalFormSubmit = () => {
        
        let newMode = {
            label: label,
            reglementId: editTarget,
            dayss: dayss,
            forceDay: forceDay,
            month_End: monthEnd,
        };
        edit(newMode);
        setEditForm(false)
    }
    useEffect(() => {
        loadModeReglements();
    }, []);

    if (loadingInitial) return <Loading content="Chargement des échéances ..." />

    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0,name: "Paramétres", root: "" },
                    { key: 1,name: "Echéance", root: "/dashboardReglement" },
                ]}
            />
            <div style={{ margin: '2rem 1rem' }}>
            <Item.Group>
                <Item>
                    <Item.Content>
                        <Header as='h2' icon='address card outline'></Header>
                            <Header as='h2'>{'Echéance'}</Header>
                        <Button
                            floated="right"
                                content={baseStore.modeReglementStore.addMode ? 'Annuler' : 'Ajouter un échéance'}
                            color="orange"
                            icon='arrow alternate circle left outline'
                            basic
                            onClick={() => { baseStore.modeReglementStore.changeButtonStateAdd(!baseStore.modeReglementStore.addMode) }}
                        />
                    </Item.Content>
                </Item>
            </Item.Group>
            {baseStore.modeReglementStore.addMode &&
                <div>
                    <ModeReglementForm />
                    <br />
                </div>
            }
            {submitting &&
                <Progress color='orange' percent={progress} size='tiny' />
            }
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Label</Table.HeaderCell>
                        <Table.HeaderCell>Jours</Table.HeaderCell>
                        <Table.HeaderCell>Jours de mois</Table.HeaderCell>
                        <Table.HeaderCell>Fin de mois</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {modeReglements && modeReglements.map((modeReglement: any) => (
                        <Table.Row key={modeReglement.reglementId}>
                            {editForm && editTarget === modeReglement.reglementId ? (
                                <>
                                    <Table.Cell selectable>
                                        <Form.Input name='label' component={TextInput} placeholder='Label' defaultValue={modeReglement.label} style={{ width: '100%', padding: '1%' }} onChange={event => setLabel(event.target.value)} />
                                    </Table.Cell>
                                    <Table.Cell selectable>
                                        <Form.Input fluid name='dayss' placeholder='Jours' max={31} type='number' min={0} onChange={(event) => setDayss(event.target.value)} defaultValue={modeReglement.dayss} />
                                    </Table.Cell>
                                    <Table.Cell selectable>
                                        <Form.Input fluid name='forceDay' placeholder='Jours de mois' max={31} type='number' min={0} onChange={(event: any) => setForceDay(event.target.value == '' ? 0 : event.target.value)} defaultValue={modeReglement.forceDay }/>
                                    </Table.Cell>
                                    <Table.Cell >
                                        <input type='checkbox' style={{ margin: "5px 0 0 10px" }} onClick={(event: any) => setMonthEnd(event.target.checked)} defaultChecked={modeReglement.month_End} />
                                    </Table.Cell>
                                    <Table.Cell >
                                        <Button.Group fluid widths={2}>
                                            <Button
                                                type='button'
                                                onClick={() => {
                                                    setEditForm(false);
                                                }}>
                                                Annuler
                                            </Button>
                                            <Button.Or />
                                            <Button
                                                name={modeReglement.reglementId}
                                                positive
                                                type='submit'
                                                onClick={(e) => {
                                                    handleFinalFormSubmit()
                                                    setEditTarget(e.currentTarget.name)
                                                }}
                                            >Confirmer</Button>
                                        </Button.Group>
                                    </Table.Cell>
                                </>
                            ) : (
                                <>
                                    <Table.Cell>{modeReglement.label}</Table.Cell>
                                    <Table.Cell>{modeReglement.dayss}</Table.Cell>
                                        <Table.Cell >{modeReglement.forceDay}</Table.Cell>
                                        <Table.Cell >{modeReglement.month_End == true ? <TiInputChecked style={{ margin: "0 0 0 50px" }} size={30 }/> : null}</Table.Cell>
                                    <Table.Cell >
                                        <Button.Group fluid widths={2}>
                                            <Button
                                                onClick={(e) => {
                                                    setEditForm(true);
                                                    setEditTarget(e.currentTarget.name)
                                                        setLabel(modeReglement.label)
                                                        setDayss(modeReglement.dayss)
                                                        setForceDay(modeReglement.forceDay)
                                                        setMonthEnd(modeReglement.month_End)
                                                }}
                                                color="blue"
                                                icon='edit outline'
                                                style={{ marginRight: '3%' }}
                                                name={modeReglement.reglementId}
                                            />
                                            <Button
                                                name={modeReglement.reglementId}
                                                basic
                                                color='red'
                                                icon='trash'
                                                onClick={(e) => {
                                                    openModal(<DeleteModeReglementModal modeReglement={modeReglement} />)
                                                    setDeleteTarget(e.currentTarget.name)
                                                }}
                                                loading={loadingModeReglementAction && deleteTarget === modeReglement.reglementId}
                                            />
                                        </Button.Group>
                                    </Table.Cell>
                                </>
                            )}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            </div>
        </div>
    );
}
export default observer(ListModeReglement);