import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Grid, Header, Label, Tab, Table } from "semantic-ui-react";
import { numberFormat } from "../../../app/common/options/IStyle";
import { getTypeObjetLie } from "../../../app/common/util/util";
import { IListRelatedElements } from "../../../app/models/IFacture";
import { BaseStoreContext } from "../../../app/store/baseStore";

interface UrlParams {
    id: string;
}

const ObjetLierCommande = () => {
    const baseStore = useContext(BaseStoreContext);
    const { getRelatedObjectForCommande, relatedElementsList, submitting } = baseStore.commandeStore;
    const { id } = useParams<UrlParams>();

    useEffect(() => {
        getRelatedObjectForCommande(parseInt(id));
    }, []);


    return (
        <Tab.Pane >
            <Grid style={{display:"grid",height:"100%"}}>
                {submitting ?
                    <div className="ui segment">
                        <div className="ui active inverted dimmer">
                            <div className="ui medium text loader">Chargement des relations...</div>
                        </div>
                    </div>
                    : (
                        <Table celled style={{ padding: 0 }}>
                            <Table.Header style={{ fontSize: 14 }}>
                                <Table.Row>
                                    <Table.HeaderCell>REF</Table.HeaderCell>
                                    <Table.HeaderCell>TYPE</Table.HeaderCell>
                                    <Table.HeaderCell>NOM</Table.HeaderCell>
                                    <Table.HeaderCell>TOTAL HT</Table.HeaderCell>
                                    <Table.HeaderCell>TOTAL TTC</Table.HeaderCell>
                                    <Table.HeaderCell>DATE</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body style={{ fontSize: 12, fontWeight: 600 }}>
                                {relatedElementsList && relatedElementsList.map((relatedElement: IListRelatedElements) => (
                                    <Table.Row key={relatedElement.referenceObject}>
                                        <Table.Cell>
                                            <Header size='medium'><Label style={{ margin: 0, padding: 0, background: "none" }} as={Link} to={`/detail${relatedElement.typeObject.startsWith('Facture') ? relatedElement.typeObject.startsWith('FactureFournisseur') ? "FactureFournisseur" : "Facture" : relatedElement.typeObject}/${relatedElement.idObject}`}>{relatedElement.referenceObject}</Label></Header>
                                        </Table.Cell>
                                        <Table.Cell>{getTypeObjetLie(relatedElement.typeObject)}</Table.Cell>
                                        <Table.Cell>{relatedElement.name}</Table.Cell>
                                        <Table.Cell>{numberFormat.format(relatedElement.ht)}</Table.Cell>
                                        <Table.Cell>{numberFormat.format(relatedElement.ttc)}</Table.Cell>
                                        <Table.Cell>{new Date(relatedElement.date).toLocaleDateString('en-GB')}</Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>

                        </Table>
                    )}
            </Grid>
        </Tab.Pane >
    );

}
export default observer(ObjetLierCommande);
