import { useContext } from 'react';
import { Button, Grid, Table } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import NotFound from '../../../app/layout/NoFound';
import DevisDuplicationModal from './DevisDuplicationModal';
import AucunProduit from '../../commande/detailCommande/AucunProduit';
import FormGridColumn from '../../../app/common/form/FormGridColumn';

const DevisDuplicationInformations = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { openModal } = baseRepo.modalStore;
    const { devis } = baseRepo.devisStore;
    if (!devis) {
        return <NotFound />
    }
    else {
        return (
            <div>
                <Grid className="ui stackable">
                    <h3>Informations du Commande:</h3>
                    <Grid.Row style={{ padding: 0, margin: 0 }}>
                        <FormGridColumn header="Client" content={devis!.companyName} root={devis!.companyType == "Franchisseur" ? `/detailFranchiseur/${devis.companyId}` : `/detailClient/${devis.companyId}`} />
                    </Grid.Row>
                    <Grid.Row style={{ padding: 0, margin: 0 }}>
                        <FormGridColumn header="Numéro Devis" content={devis!.id} />
                        <FormGridColumn header="Référence Devis" content={devis!.id} />
                        <FormGridColumn header="Date devis" content={new Date(devis!.quoteDate).toLocaleDateString('en-GB')} />
                        <FormGridColumn header="Date exp devis" content={devis!.validationDate ? new Date(devis!.validationDate).toLocaleDateString('en-GB') : "non introduite"} />
                        <FormGridColumn header="Transport" content={devis!.deliveryCompany} />
                        <FormGridColumn header="Livraison" content={devis!.singleDelivery} />
                        <FormGridColumn header="Montant HT" content={devis!.amountWithoutTaxes} />
                        <FormGridColumn header="Montant TTC" content={devis!.amountWithTaxes} />
                        <FormGridColumn header="Commentaires" content={devis!.comment} />s
                        <FormGridColumn header="Transporteur" content={devis!.deliveryService} />
                    </Grid.Row>
                    <h3>Adresses Devis:</h3>
                    <Grid.Row style={{ padding: 0, margin: 0 }}>
                        <FormGridColumn header="Adresse de facturation" content={devis!.invoiceAddress1} />
                        <FormGridColumn header="Adresse de livraison" content={devis!.deliveryAddress1} />
                        <FormGridColumn header="Complément d’adresse facturation" content={devis!.invoiceAddress2} />
                        <FormGridColumn header="Complément d’adresse livraison" content={devis!.deliveryAddress2} />
                        <FormGridColumn header="Ville de facturation" content={devis!.invoiceCity} />
                        <FormGridColumn header="Ville de livraison" content={devis!.deliveryCity} />
                        <FormGridColumn header="Code postal facturation" content={devis!.invoicePostalCode} />
                        <FormGridColumn header="Code postal livraison" content={devis!.deliveryPostalCode} />
                    </Grid.Row>
                    <h3>Article(s):</h3>
                    {devis?.orderLines.length == 0 ? (
                        <AucunProduit />
                    ) : (
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Code Article</Table.HeaderCell>
                                    <Table.HeaderCell>Article</Table.HeaderCell>
                                    <Table.HeaderCell>Quantité</Table.HeaderCell>
                                    <Table.HeaderCell>Prix unitaire</Table.HeaderCell>
                                    <Table.HeaderCell>Total</Table.HeaderCell>
                                    <Table.HeaderCell>TVA</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {devis &&
                                    devis.orderLines.map(devis => (
                                        <Table.Row key={devis.productId}>
                                            <Table.Cell>{devis.articleCode}</Table.Cell>
                                            <Table.Cell>{devis.articleName}</Table.Cell>
                                            <Table.Cell>{devis.quantity}</Table.Cell>
                                            <Table.Cell>{devis.unitPrice}</Table.Cell>
                                            <Table.Cell>00,00</Table.Cell>
                                            <Table.Cell>{devis.tvaRate}</Table.Cell>
                                        </Table.Row>
                                    ))}
                            </Table.Body>
                        </Table>
                    )}
                </Grid>
                <div style={{ marginTop: '20px' }}>
                    <Button.Group floated="right">
                        <Button onClick={() =>
                            baseRepo.devisStore.changeDuplicationState(!baseRepo.devisStore.duplicationMode)}
                            floated='right' type='button'>Annuler</Button>
                        <Button.Or />
                        <Button positive onClick={(e) => {
                            openModal(<DevisDuplicationModal devis={devis} />)
                        }} floated='right' type='submit'>Dupliquer</Button>
                    </Button.Group>
                </div>
            </div>
        );
    }
}
export default observer(DevisDuplicationInformations);