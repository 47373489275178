import React, { SetStateAction, useContext, useState } from 'react';
import { Button, Modal, Input, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { Dispatch } from 'react';
import { BaseStoreContext } from '../../app/store/baseStore';
import TextInput from '../../app/common/form/inputText';
import { IDetailExpedition, IExpeditionLines, IOrderDispatch } from '../../app/models/IExpedition';
import { values } from 'mobx';


interface IProps {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>;
    id: string;
}
const BonDeRetour: React.FC<IProps> = ({ open, setOpen, id }) => {
    const baseStore = useContext(BaseStoreContext);
    const { ITList, Retour } = baseStore.expeditionStore;
    const [orderDispatchLines, setOrderDispatchLines] = useState<IDetailExpedition[]>([]);


    const handleSetQuantite = (e: any, stock: IDetailExpedition) => {
        let newMode: any = {
            ...values,
            id: stock.id,
            articleId: stock.articleId,
            articleLibelle: stock.articleLibelle,
            entrepotsLibelle: stock.entrepotsLibelle,
            entrepotId: stock.entrepotId,
            dispatchLineState: stock.dispatchLineState,
            idOrderLine: stock.idOrderLine,
            idPack: stock.idPack,
            orderDispatchs: stock.orderDispatchs,
            quantiteALivree: stock.quantiteALivree,
            quantiteResteLivre: stock.quantiteResteLivre,
            quantiteLivree: e.target.value,
            orderPrepareLineItemIdPreparationLines: stock.orderPrepareLineItemIdPreparationLines
        };
        var table = orderDispatchLines;
        var index = table.findIndex(x => x.idOrderLine == stock.idOrderLine);
        if (index != -1) {
            table[index].quantiteLivree = e.target.value;
        } else {
            table.push(newMode);
        }
        setOrderDispatchLines(table);
    }
    const handleFinalFormSubmit = (values: any) => {
        let newOrder: IOrderDispatch = {
            id: parseInt(id),
            orderDis: [...orderDispatchLines, ...ITList.filter(x => orderDispatchLines.findIndex(y => y.id == x.id) == -1)],
        }
        Retour(parseInt(id), newOrder);
        setOpen(false)
    }

    return (
        <Modal
            size={"large"}
            open={open}
            onClose={() => setOpen(false)}
        >
            <Modal.Header>Bon de retour</Modal.Header>
            <Modal.Content >
                {ITList && ITList.map((stock: IDetailExpedition) => (
                    <Grid>
                        <Grid.Row >
                            <label style={{ marginLeft: "20px", paddingTop: "8px" }} >Article :</label>
                            <label style={{ marginLeft: "20px", paddingTop: "8px", width: '150px' }} >{stock?.articleLibelle}</label>
                            <label style={{ marginLeft: "20px", paddingTop: "8px" }} >Quantite :</label>
                            <Input style={{ height: '37px', width: '150px', marginLeft: "10px" }} defaultValue={stock?.quantiteLivree} component={TextInput} type='number' max={stock?.quantiteLivree} min={1} onChange={(e) => handleSetQuantite(e, stock)} />
                        </Grid.Row>

                    </Grid>
                ))}
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={() => setOpen(false)}>
                    Quitter
                </Button>
                <Button positive
                    onClick={handleFinalFormSubmit}
                >
                    Enregistrer
                </Button>
            </Modal.Actions>
        </Modal>


    )
}
export default observer(BonDeRetour);