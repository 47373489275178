import React, { useContext, useEffect, useState } from 'react';
import { GridColumn, Grid, Button, Container } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../app/store/baseStore';
import Breadcrumbs from '../dashboard/breadcrumbs';
import { IInventory } from '../../app/models/IInventory';
import { MdReadMore } from 'react-icons/md';
import { FcDownload } from 'react-icons/fc';
import Select from 'react-select';
import { IEntrepot } from '../../app/models/IEntrepot';

const InventoryDashboard: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { InventoryList, loadInventories, adding, addInventory, exportInventory, exporting } = baseStore.inventoryStore;
    const { loadingInitial, loadEntrepots, entrepots } = baseStore.entrepotStore;
    const [entrepot, setEntrepot] = useState<IEntrepot>(entrepots[entrepots.findIndex(x => x.isMain == true)]);

    const date = new Date();

    const [year, setYear] = useState<number>(0);
    const [month, setMonth] = useState<number>(0);
    const [label, setLabel] = useState<string>("Inventaire de " + date.toLocaleString('en-EN', { month: "long" }) + " " + date.getFullYear());


    const handleAdd = () => {
        var inv: Partial<IInventory> = {
            name: label,
            entrepotId: entrepot.entrepotsId,
            entrepotLabel: entrepot.libelle
        }
        addInventory(inv)
    }
    const customStyles = {
        control: (baseStyles: any, state: any) => ({
            ...baseStyles,
            width: 300,
            marginRight: 20,
            height:50
        }),
    }

    useEffect(() => {
        loadEntrepots().then((result: IEntrepot[] | undefined) => {
            setEntrepot(result![result!.findIndex(x => x.isMain == true)]);
        });
        loadInventories();
    }, []);

    return (

        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Inventaires", root: "" },
                    { key: 1, name: "Liste des inventaires", root: "/inventaire" },
                ]}
            />
            <Grid>
                <Container style={{ padding: 20, background: 'white', marginTop: 10, borderRadius: 5 }}>
                    <div style={{ display: "flex" }}>

                        <span style={{ fontSize: 18, fontWeight: 700, color: "#db2828" }}>Ajouter un nouveau inventaire</span>

                        <input style={{ height: 50, borderRadius: 4, color: "green", padding: "12px 20px", margin: "0 30px", width: 250, border: "1px solid #ccc" }}
                            type="text" value={label} onChange={(e) => setLabel(e.target.value)} />
                        <Select
                            name='entrepots'
                            placeholder={entrepots[entrepots.findIndex(x => x.isMain == true)]?.libelle ?? "Séléctionnez un entrepôt.."}
                            isLoading={loadingInitial}
                            options={entrepots}
                            getOptionLabel={e => e!.libelle}
                            getOptionValue={e => e!.entrepotsId.toString()}
                            onChange={e => { setEntrepot(e!); }}
                            styles={customStyles}
                        />
                        <Button content="Ajouter" style={{margin:0}} onClick={handleAdd} disabled={entrepot == undefined} positive loading={adding} />

                    </div>
                </Container>

                <GridColumn width={16} style={{ marginTop: 2 }}>

                    <Grid style={{ marginTop: 2 }}>
                        <Grid.Row columns={4} className="ui grid stackable" >
                            {InventoryList.map((inv) => (
                                <GridColumn style={{ height: "150px", width: "100%" }}>
                                    <div className="ui segment __restaurant-segment" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
                                        <div className="segment-body">
                                            <div className="ui grid">
                                                <div className=" wide column">
                                                    <div className="ui statistic">
                                                        <div className="text value" >
                                                            <span style={{ fontSize: "14px", color: 'blue' }}>{inv.name}</span>
                                                            <br />
                                                            <span style={{ fontSize: "14px", color:"gray" }} > {inv.entrepotLabel}-{new Date(inv.startdate).toLocaleDateString('en-GB', { month: "long", year: "numeric" })} </span>
                                                            <a href={`/inventaireDetail/${inv.id}`} >
                                                                <MdReadMore style={{ float: "right", fontSize: "27px", margin: "10px 0 0 10px", color: "#CE2B1A", cursor: "pointer" }}
                                                                />
                                                            </a>
                                                            <FcDownload style={{ float: "right", fontSize: "22px", margin: "10px 0 0 10px", color: "green", cursor: "pointer" }} onClick={() => { exportInventory(inv.id) }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </GridColumn>
                            ))}

                        </Grid.Row>
                    </Grid>
                </GridColumn>
            </Grid>
        </div>
    );
}
export default observer(InventoryDashboard);