import { useContext, useEffect } from 'react';
import { Tab, Header, Button, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import NotFound from '../../../app/layout/NoFound';
import FournisseurAchatEditForme from './FournisseurAchatEditForme';
import FormGridColumn from '../../../app/common/form/FormGridColumn';

const FournisseurAchatDescription = () => {

    const baseRepo = useContext(BaseStoreContext);
    const { fournisseur, editFournisseurAchat } = baseRepo.fournisseurStore;
    useEffect(() => {
        baseRepo.fournisseurStore.changeButtonState(false)
    }, [])
    if (!fournisseur) {
        return <NotFound />
    }
    else {
        return (
            <Tab.Pane>
                <Grid>
                    <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                        <Header floated='left' icon='address card' content={`Achat`} />
                        <Button
                            onClick={() => { baseRepo.fournisseurStore.changeButtonState(!baseRepo.fournisseurStore.editFournisseurMode) }}
                            floated='right'
                            basic
                            content={baseRepo.fournisseurStore.editFournisseurMode ? 'Annuler' : 'Modifier Fournisseur'}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        {baseRepo.fournisseurStore.editFournisseurMode ? (
                            <FournisseurAchatEditForme editFournisseur={editFournisseurAchat} fournisseur={fournisseur!} />
                        ) : (
                            <Grid className="ui stackable">
                                <Grid.Row>
                                    {/*<FormGridColumn icon="building" header="Min. Commande" content={fournisseur!.seuil == 'Poids' ? fournisseur!.miniCommande + " Kg" : fournisseur!.seuil == "Montant" ? fournisseur!.miniCommande + " €" : fournisseur!.seuil == "Quantite" ? fournisseur!.miniCommande : "0"} />*/}
                                    {/*<FormGridColumn icon="user" header="Seuil" content={fournisseur!.seuil} />*/}
                                    <FormGridColumn icon="users" header="Seuil Franco" content={fournisseur!.seuilFranco} />
                                    <FormGridColumn icon="user outline" header="Franco" content={fournisseur!.seuilFranco == 'Poids' ? fournisseur!.franco ?? "0" + " Kg" : fournisseur!.seuilFranco == "Montant" ? fournisseur!.franco ?? "0" + " €" : fournisseur!.seuilFranco == "Quantite" ? fournisseur!.franco : "0"} />
                                    <FormGridColumn icon="truck" header="Frais de port" content={fournisseur!.valeur} />
                                    <FormGridColumn icon="percent" header="Valeur" content={fournisseur!.valeur == 'Poucentage' ? fournisseur!.fraisPort + " %" : fournisseur!.valeur == "Forfait" ? fournisseur!.fraisPort + " €" : "0"} />
                                    <FormGridColumn header="Type Marché" content={fournisseur!.typeMarche} />
                                    {/*<FormGridColumn icon="phone volume" iconColor={fournisseur.esCompte ? "green" : "red"} header={fournisseur!.esCompte ? "Avec EsCompte" : "Sans EsCompte"} />*/}
                                    {/*<FormGridColumn icon="phone volume" iconColor={fournisseur.repliquats ? "green" : "red"} header={fournisseur!.repliquats ? "Avec Reliquats" : "Sans Reliquats"} />*/}
                                    {/*<FormGridColumn icon="phone volume" iconColor={fournisseur.remise ? "green" : "red"} header={fournisseur!.remise ? "Avec Remise" : "Sans Remise"} />*/}
                                    {/*<FormGridColumn icon="home" header="Montant Remise" content={fournisseur!.typeRemise == 'Poucentage' ? fournisseur!.montantRemise + " %" : fournisseur!.typeRemise == "Fixe" ? fournisseur!.montantRemise + "" : "0"} />*/}
                                    {/*<FormGridColumn icon="address book" header="Type Remise" content={fournisseur!.typeRemise} />*/}
                                    {/*<FormGridColumn icon="address book" header="Seuil Minimum" content={fournisseur!.seuilMin} />*/}

                                </Grid.Row>
                            </Grid>
                        )}
                    </Grid.Column>
                </Grid>
            </Tab.Pane>
        );
    }
}
export default observer(FournisseurAchatDescription);