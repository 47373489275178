import { BaseStore } from "./baseStore";
import { observable, action, runInAction, computed, reaction } from 'mobx';
import agent from '../agent/agent';
import { toast } from "react-toastify";
import { history } from '../../index';
import { IBonDePreparation, IDetailPreparation, IPreparation, IPreparationCommande, IPreparationLines, IPreparationList } from "../models/IPreparation";
import { setPreparationProps } from "../common/util/util";
import { IEntrepot, IEntrepotList } from "../models/IEntrepot";
import { IArticle } from "../models/IArticle";
const LIMIT = 20;

export default class PreparationStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0;
                this.loadPreparationList();
            }
        )
        reaction(
            () => this.activeTab,
            activeTab => {
                if (activeTab != '')
                    return true;
            }
        )
    }
    @observable submitting = false;
    @observable validate = false;
    @observable actualiser = false;
    @observable loadingInitial = false;
    @observable loadingExport = false;
    @observable loadingStock = false;
    @observable preparation: IPreparationCommande | null = null;
    @observable preparations: IPreparationCommande[] = [];
    @observable ListEntrepot: IEntrepot[] = [];
    @observable ITAllListEntrepot: IEntrepotList[] = [];
    @observable ListArticle: IArticle[] = [];
    @observable ITList: IDetailPreparation[] = [];
    @observable preparationslines: IDetailPreparation[] = [];
    @observable editPraparation: boolean = false;
    @observable isTerminated: boolean = false;
    @observable addPraparation: boolean = false;
    @observable preparationCount: number = 0;
    @observable totalMontant: number = 0;
    @observable page = 0
    @observable predicate = new Map();
    @observable ITListArticle: IArticle[] = [];
    @observable generatePdfMode = false;
    @observable deletingOrder = false;
    @observable activeTab: string = "0";



    @computed get axiosParams() {
        const params = new URLSearchParams();
        params.append('limit', String(LIMIT));
        params.append('offset', `${this.page ? this.page * LIMIT : 0}`);
        this.predicate.forEach((value, key) => {
            if (key === 'startDate') {
                params.append(key, value.toISOString())
            } if (key === 'searchMultiple') {
                var search = [...value][0];
                var searchCommande = [...value][1];
                var searchClient = [...value][2];
                var Key = [...value][3];
                var sort = [...value][4];
                var dateCommande = [...value][5];
                var dateCommandeTo = [...value][6];
                var searchClientAll = [...value][7];      
                params.append(search[0], search[1])
                params.append(searchCommande[0], searchCommande[1])
                params.append(searchClient[0], searchClient[1])
                params.append(Key[0], Key[1])
                params.append(sort[0], sort[1])
                params.append(dateCommande[0], dateCommande[1])
                params.append(dateCommandeTo[0], dateCommandeTo[1])
                params.append(searchClientAll[0], searchClientAll[1])
            } else {
                params.append(key, value)
            }
        })
        return params;
    }

    @computed get totalPages() {
        return Math.ceil(this.preparationCount / LIMIT);
    }

    @action setPage = (page: number) => {
        this.page = page;
    }

    @action setPredicate = (predicate: string, value: any) => {
        if (this.predicate.get(predicate))
            this.predicate.delete(predicate);
        if (predicate == 'all') {
            this.predicate.clear();
        }
        else {
            this.predicate.set(predicate, value);
        }
    }

    @action changeButtonStateEdit = async (newState: boolean) => {
        this.editPraparation = newState;
    }

    @action changeButtonStateAdd = async (newState: boolean) => {
        this.addPraparation = newState;
    }
    @action changeButtonPdfState = async (newState: boolean) => {
        this.generatePdfMode = newState;
    }

    @action loadPreparationList = async () => {
        this.loadingInitial = true;
        try {
            const stockEnveloppe = await agent.preparationAgent.list(this.axiosParams);
            const { preparations, preparationsCount,totalMontant } = stockEnveloppe;
            runInAction(() => {
                preparations.forEach((commande) => {
                    setPreparationProps(commande);
                    if (this.page == 0) {
                        this.preparations = preparations;
                    } else {
                        this.preparations.push(commande);
                    }
                })
                this.preparationCount = preparationsCount;
                this.totalMontant = totalMontant;
                this.loadingInitial = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }


    @action loadPreparationById = async (id: number) => {
        this.loadingStock = true;
            try {
                var stocks = await agent.preparationAgent.details(id);
                runInAction(() => {
                    this.preparation = stocks;
                    this.preparationslines = stocks.preparationLines;
                    this.loadingStock = false;
                })
                return stocks;
            } catch (e) {
                runInAction(() => {
                    this.loadingStock = false;
                })
                console.log(e);
        }
    }

    @action create = async (values: IPreparation) => {
        this.submitting = true;
        try {
            var id= await agent.preparationAgent.create(values);
            runInAction(() => {
                this.submitting = false;
                toast.success("ajout éffectué avec succès");
                history.push(`/detailPreparation/${id}`)
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
        }
    }
    @action save = async (values: IBonDePreparation) => {
        this.submitting = true;
        try {
            var id = await agent.preparationAgent.save(values);
            runInAction(() => {
                this.submitting = false;
                toast.success("ajout éffectué avec succès");
                history.push(`/detailPreparation/${id}`)
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
        }
    }

    //@action loadPreparationLineByPreparation = async (id: number) => {
    //    this.loadingInitial = true;
    //    let status = true;
    //    this.ITList = [];
    //    try {
    //        const stock = await agent.preparationAgent.detailsPreparationLines(id);
    //        runInAction(() => {
    //            this.preparationslines = stock;
    //            stock.forEach((artStock) => {
    //                this.ITList.push(artStock);
    //                if (artStock.prepareLineState != "Termine")
    //                    status = false;
    //            })
    //            this.isTerminated = status;
    //            this.loadingInitial = false;
    //        })
    //    } catch (e) {
    //        toast.error("Problem loading forme stock!");
    //        runInAction(() => {
    //            this.loadingInitial = false;
    //        })
    //    }
    //}

    @action loadEntrepotByPreparationLine = async (id: number) => {
        this.loadingInitial = true;
        this.ITAllListEntrepot = [];
        try {
            const entrepot = await agent.preparationAgent.listEntrepotByPreparationLines(id);
            runInAction(() => {
                this.ListEntrepot = entrepot;
                entrepot.forEach((depot) => {
                    let ENTREPOT: IEntrepotList = {
                        key: depot.entrepotsId,
                        text: depot.libelle,
                        value: depot.entrepotsId
                    }
                    this.ITAllListEntrepot.push(ENTREPOT)
                })
                this.loadingInitial = false;
            })
        } catch (e) {
            toast.error("Problem loading forme stock!");
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }

    @action generatePDF = async (id: number, idEntrepot: number) => {
        try {
            await agent.preparationAgent.generatePDF(id, idEntrepot);
            toast.success("téléchargement avec succès")
        }
        catch (e) {
            console.log(e);
        }
    }

    @action edit = async (values: Partial<IDetailPreparation>) => {
        this.submitting = true;
        var status= true;
        try {
            await agent.preparationAgent.update(values);
            runInAction(() => {
                this.preparationslines.map((item, index) => {
                    if (item.idPreparationLines == values.idPreparationLines) {
                        this.preparationslines[index].quantitePrepare = values.quantitePrepare!;
                        //(this.ITList[index].quantite <= this.ITList[index].quantiteDisponible ? this.ITList[index].quantite : this.ITList[index].quantiteDisponible) == values.quantitePrepare! ?
                        //    this.ITList[index].prepareLineState = "Termine" :
                        //    this.ITList[index].prepareLineState = "EnCours";

                        if (values.quantitePrepare == this.preparationslines[index].quantite) {
                            this.preparationslines[index].prepareLineState = "Termine"
                        }
                        if (this.preparationslines[index].quantite > values.quantitePrepare!) {
                            this.preparationslines[index].prepareLineState = "EnCours"
                        }

                    }
                });
                this.preparationslines.map((item) => {
                    if (item.prepareLineState != "Termine")
                        status = false;
                });
                this.isTerminated = status;
                if(status)
                    this.preparation!.prepareState = 'Termine';
                toast.success("Modification éffectuée avec succès");
                this.submitting = false;
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }

    }

    @action editStatus = async (values: number) => {
        this.validate = true;
        try {
            await agent.preparationAgent.updateStatus(values);
            runInAction(() => {
                this.preparation!.prepareState = "Cloture";
            })
            toast.success("Validation éffectuée avec succès");
            this.validate = false;
        } catch (e) {
            runInAction(() => {
                this.validate = false;
            })
        }
    }

    @action canceled = async (id: number) => {
        this.actualiser = true;
        try {
            await agent.preparationAgent.cancel(id);
            runInAction(() => {
                this.preparation!.isCanceled = true;
                toast.success("préparation désactivé avec succès");
            })
            this.actualiser = false;

        } catch (e) {
            runInAction(() => {
                this.actualiser = false;
            })
        }
    }

    @action actived = async (id: number) => {
        this.actualiser = true;
        try {
            await agent.preparationAgent.cancel(id);
            runInAction(() => {
                this.preparation!.isCanceled = false;
                toast.success("préparation activé avec succès");
            })
            this.actualiser = false;

        } catch (e) {
            runInAction(() => {
                this.actualiser = false;
            })
        }
    }
    @action valideTous = async (values: number) => {
        this.submitting = true;
        try {
            await agent.preparationAgent.valideTous(values);
            let prepa = await agent.preparationAgent.details(values);
            runInAction(() => {
                this.preparation = prepa;
                this.preparationslines.filter(x => x.idPreparation == values).forEach(item => {
                    item.prepareLineState = "Termine"
                    item.quantitePrepare = item.quantite!;
                })
                this.preparation!.prepareState = 'Termine';
                this.submitting = false;
            })
            toast.success("préparation validée avec succès");

        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
        }
    }

    @action deleteOrderPrepare = async (id: number) => {
        this.deletingOrder = true;
        try {
            await agent.preparationAgent.deleteOrderPrepare(id);
            runInAction(() => {
                this.deletingOrder = false;
                this.preparations = this.preparations.filter(a => a.idPreparation !== id);
                history.push(`/preparationDashboard`)
                toast.success("suppression éffectuée avec succès");
            })
        } catch (e) {
            toast.error("Problème de suppresion !");
            runInAction(() => {
                this.deletingOrder = false;
            })
        }
    }
    @action exportPreparations = async (obj: any) => {
        this.loadingExport = true;
        try {
            await agent.preparationAgent.exportExcel(obj);
            runInAction(() => {
                this.loadingExport = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingExport = false;
            })
            console.log(e);
        }
    }
}