import { useContext } from "react";
import { Button, Container, Header, Icon } from "semantic-ui-react";
import { IInvoiceLine } from "../../../app/models/IFacture";
import { BaseStoreContext } from "../../../app/store/baseStore";


interface IProps {
    factureId: number;
    invoiceLine: IInvoiceLine
}
const DeleteInvoiceLineModal: React.FC<IProps> = ({ factureId, invoiceLine }) => {
    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    const { deleteInvoiceArticle } = baseStore.factureStore;
    return (
        <Container textAlign='center'>
            <Header icon='trash' content='Supprimer ligne de facture' style={{ marginLeft: 20 }} />
            <p>
                Voulez vous supprimer cette ligne de facture ?
            </p>
            <Button.Group fluid>
                <Button onClick={() => closeModal()}>
                    <Icon name='remove' /> Annuler
                </Button>
                <Button.Or text='Ou' />
                <Button
                    color='green'
                    onClick={(e) => {
                        deleteInvoiceArticle(factureId, invoiceLine);
                        closeModal()
                    }}>
                    <Icon name='checkmark' /> Confirmer
                </Button>
            </Button.Group>

        </Container>
    )
}
export default DeleteInvoiceLineModal;
