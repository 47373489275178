import React, { useContext } from 'react';
import { Button, Header, Icon, Container } from 'semantic-ui-react';
import { ITypeMarche } from '../../../app/models/ITypeMarche';
import { BaseStoreContext } from '../../../app/store/baseStore';

interface IProps {
    typeMarche: ITypeMarche;
}

const DeleteTypeMarcheModal: React.FC<IProps> = ({ typeMarche }) => {
    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    const { deleteTypeMarche } = baseStore.TypeMarcheStore;

    return (
        <Container textAlign='center'>
            <Header icon='trash' content='Suppression du type de marché' style={{ marginLeft: 20 }} />
            <p>
                Voulez vous supprimer ce mode de paiement ?
            </p>

            <Button.Group fluid>
                <Button onClick={() => closeModal()}>
                    <Icon name='remove' /> Annuler
                </Button>
                <Button.Or text='Ou' />
                <Button
                    name={typeMarche.typeMarcheId}
                    color='green'
                    onClick={(e) => {
                        deleteTypeMarche(typeMarche.typeMarcheId)
                        closeModal()
                    }}>
                    <Icon name='checkmark' /> Confirmer
                </Button>
            </Button.Group>
        </Container>
    )
}
export default DeleteTypeMarcheModal;
