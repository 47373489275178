import React, { useContext, useEffect, useState } from 'react';
import { Form, Table, Header, Button, Modal, Segment, Grid, Item } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../app/store/baseStore';
import { IDetailPreparation } from '../../app/models/IPreparation';
import { RouteComponentProps } from 'react-router-dom';
import Breadcrumbs from '../dashboard/breadcrumbs';
import { toast } from 'react-toastify';
import TextInput from '../../app/common/form/inputText';
import { values } from 'mobx';
import { VscFilePdf } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import CancelPreparationModal from './CancelPreparationModal';
import Select from 'react-select';
import DeleteBonDePreparationModal from './DeleteBonDePreparationModal';
import ListItemPlaceholder from '../../app/common/form/ListItemPlaceholder';

const getStatus = (status: string) => {
    switch (status) {
        case 'EnAttente':
            return 'En Attente';
        case 'EnCours':
            return 'Reliquat';
        case 'Termine':
            return 'Termine';
        case 'Expedie':
            return 'Expediée';
        default:
            return '';
    }
}
function exampleReducer(state: any, action: any) {
    switch (action.type) {
        case 'close':
            return { open: false }
        case 'open':
            return { open: true, size: action.size }
        default:
            throw new Error('Unsupported action...')
    }
}
interface RouteParams {
    id: string
}

interface IProps extends RouteComponentProps<RouteParams> { }
const ListPreparationLines: React.FC<IProps> = ({ match }) => {
    const [state, dispatch] = React.useReducer(exampleReducer, {
        open: false,
        size: undefined,
    })
    const { open, size } = state
    const baseStore = useContext(BaseStoreContext);
    const { openModal } = baseStore.modalStore;
    const { loadingInitial, loadEntrepots, entrepots } = baseStore.entrepotStore;
    const { loadPreparationById, preparation, generatePDF, edit, actualiser, preparationslines, loadingStock, deletingOrder, validate } = baseStore.preparationStore;
    const [depot, setDepot] = useState<number>(0)
    const [editForm, setEditForm] = useState(false);
    const [editTarget, setEditTarget] = useState<number | undefined>(undefined);
    const [quantitePrepare, setQuantitePrepare] = useState<number>(0);
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);

    const handleFinalFormSubmit = () => {
        let newMode = {
            ...values,
            quantitePrepare: quantitePrepare,
            idPreparationLines: editTarget
        };
        quantitePrepare >= 0 && quantitePrepare <= (preparationslines.find(a => a.idPreparationLines == editTarget)!.quantite)
            ? edit(newMode) :
            toast.warning("invalid number");
        setEditForm(false)
    }

    useEffect(() => {
        loadPreparationById(parseInt(match.params.id));
        loadEntrepots();
    }, [])


    const handlePDF = () => {
        if (depot == 0)
            generatePDF(parseInt(match.params.id), entrepots[entrepots.findIndex(x => x.isMain == true)]?.entrepotsId!)
        else
            generatePDF(parseInt(match.params.id), depot);
        dispatch({ type: 'close' });
    }

    return (
        <>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Preparation", root: "" },
                    { key: 1, name: "Preparation de commande", root: "/preparationDashboard" },
                    { key: 1, name: `Préparation N°${match.params.id}`, root: `/detailPreparation/${match.params.id}` },
                ]}
            />
            <Grid>
                <Grid.Column width={16}>
                    <Button
                        floated="right"
                        color="orange"
                        icon='arrow alternate circle left outline'
                        content="Retour"
                        basic
                        as={Link}
                        to="/preparationDashboard"
                    />

                    {preparation?.isCanceled == false && preparation?.prepareState != "Expedie" &&
                        <Button
                            floated='right'
                            size="tiny"
                            color={'red'}
                            icon='trash'
                            content={"Supprimer"}
                            onClick={(e) => {
                                openModal(<DeleteBonDePreparationModal BP={preparation!} />)
                                setDeleteTarget(e.currentTarget.name)
                            }}
                            loading={deletingOrder && deleteTarget === preparation?.idPreparation.toString()}
                        />
                    }
                    <Button
                        style={{ display: preparation?.prepareState !== "Expedie" && preparation?.isCanceled == false ? 'block' : 'none' }}
                        floated="right"
                        content="Annuler le bon de préparation"
                        loading={actualiser}
                        color='red'
                        size="tiny"
                        cursor="pointer"
                        onClick={(e) => {
                            openModal(<CancelPreparationModal preparation={preparation!} />)
                        }} />


                    <Button
                        style={{ display: preparation?.prepareState !== "Expedie" && preparation?.isCanceled == true ? 'block' : 'none' }}
                        floated="right"
                        content="Activer le bon de préparation"
                        loading={actualiser}
                        color='green'
                        size="tiny"
                        cursor="pointer"
                        onClick={(e) => {
                            openModal(<CancelPreparationModal preparation={preparation!} />)
                        }} />

                </Grid.Column>

            </Grid>
            {(loadingStock || loadingInitial) ?
                <ListItemPlaceholder />
                :
                <>
                    <Segment style={{ margin: "20px" }}>
                        <Grid columns={16}>
                            <Grid.Column width={12}>
                                <Item.Group style={{ padding: 20 }}>
                                    <Item>
                                        <Item.Image
                                            size='small'
                                            src={preparation?.uri}
                                            style={{ marginRight: 20 }}
                                        />
                                        {preparation &&
                                            <>
                                                <div>
                                                    <Header as='h3'>Bon De Préparation: {preparation?.idPreparation} </Header>
                                                    <Header as='h5'>Date préparation: {preparation?.dateCreation ? new Date(preparation?.dateCreation!).toLocaleDateString('en-GB') : null}</Header>
                                                </div>
                                                <div style={{ borderRadius: 5, marginLeft: 50, padding: 5, textAlign: 'center', width: 240, border: '1px solid grey' }}>
                                                    <div>
                                                        <Header as='h3' style={{ margin: 0 }}>{preparation?.refOrder}</Header>
                                                        <Header as='h4' style={{ margin: 0 }}>{preparation?.codeClient} - {preparation?.companyName}</Header>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <VscFilePdf
                                            title="Télécharger PDF"
                                            color={baseStore.preparationStore.generatePdfMode ? 'orange' : 'green'}
                                            onMouseEnter={() => {
                                                baseStore.preparationStore.changeButtonPdfState(!baseStore.preparationStore.generatePdfMode);
                                            }}
                                            onMouseLeave={() => {
                                                baseStore.preparationStore.changeButtonPdfState(!baseStore.preparationStore.generatePdfMode);
                                            }}
                                            style={{ right: 0, position: "absolute", fontSize: "30px", cursor: 'pointer', content: "dddd" }}
                                            onClick={() => dispatch({ type: 'open', size: 'tiny' })}
                                        />
                                    </Item>
                                </Item.Group>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <Button
                                    color="orange"
                                    cursor="pointer"
                                    floated="right"
                                    content='Préparer tous'
                                    style={{ width: 100, paddingRight: 5, paddingLeft: 5, fontSize: 11, display: preparation?.isCanceled == false && (preparation?.prepareState! === "EnAttente" || preparation?.prepareState! === "EnCours") ? 'block' : 'none' }}
                                    onClick={
                                        () => baseStore.preparationStore.valideTous(parseInt(match.params.id))
                                    }
                                />
                                <Button
                                    style={{ display: preparation?.isCanceled == false && preparation?.prepareState! === "Cloture" ? 'block' : 'none', fontSize: "15px" }}
                                    floated="right"
                                    content="Expédier"
                                    color='yellow'
                                    cursor="pointer"
                                    loading={baseStore.expeditionStore.submitting}
                                    icon='truck'
                                    onClick={() => baseStore.expeditionStore.create(parseInt(match.params.id))}
                                />

                            </Grid.Column>
                        </Grid>
                    </Segment >


                    <div style={{ margin: "20px" }}>
                        <Table compact='very'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Code Article</Table.HeaderCell>
                                    <Table.HeaderCell>Article</Table.HeaderCell>
                                    <Table.HeaderCell>Entrepot</Table.HeaderCell>
                                    <Table.HeaderCell>Quantite commandée</Table.HeaderCell>
                                    <Table.HeaderCell>Quantite Disponible</Table.HeaderCell>
                                    <Table.HeaderCell>Quantite Prépare</Table.HeaderCell>
                                    <Table.HeaderCell>Etat</Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {preparationslines && preparationslines.map((preparationLine: IDetailPreparation) => (
                                    <>
                                        <Table.Row key={preparationLine.idPreparationLines}>
                                            {editForm && editTarget === preparationLine.idPreparationLines ? (
                                                <>
                                                    <Table.Cell>{preparationLine.articleId}</Table.Cell>
                                                    <Table.Cell>
                                                        <span>
                                                            <div >
                                                                <a style={{ backgroundColor: 'none' }} href={`/detailArticle/${preparationLine.articleId}`}>
                                                                    {preparationLine.articleLibelle}
                                                                </a>
                                                            </div>
                                                        </span>
                                                    </Table.Cell>
                                                    <Table.Cell>{preparationLine.entrepotsLibelle}</Table.Cell>
                                                    <Table.Cell>{preparationLine.quantite}</Table.Cell>
                                                    <Table.Cell>{preparationLine.quantiteDisponible}</Table.Cell>
                                                    <Table.Cell selectable>
                                                        <Form.Input name='quantitePrepare' type='number' component={TextInput} placeholder='quantitePrepare' defaultValue={preparationLine.quantitePrepare != 0 ? preparationLine.quantitePrepare : (preparationLine.quantite < preparationLine.quantiteDisponible ? preparationLine.quantite : preparationLine.quantiteDisponible)} style={{ width: '100%', padding: '1%' }} onChange={(e) => setQuantitePrepare(parseInt(e.target.value))} />
                                                    </Table.Cell>
                                                    <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }}>{getStatus(preparationLine.prepareLineState)}</Table.Cell>
                                                    {preparation?.prepareState != 'Expedie' &&
                                                        <Table.Cell >
                                                            <Button.Group fluid widths={2}>
                                                                <Button
                                                                    type='button'
                                                                    size='tiny'
                                                                    onClick={() => {
                                                                        setEditForm(false);
                                                                    }}>
                                                                    Annuler
                                                                </Button>
                                                                <Button.Or text='Ou' />
                                                                <Button
                                                                    name={preparationLine.idPreparationLines}
                                                                    positive
                                                                    type='submit'
                                                                    size='tiny'
                                                                    onClick={(e) => {
                                                                        handleFinalFormSubmit()
                                                                        setEditTarget(parseInt(e.currentTarget.name))
                                                                    }}
                                                                >Confirmer
                                                                </Button>
                                                            </Button.Group>
                                                        </Table.Cell>
                                                    }
                                                </>
                                            ) : (
                                                <>
                                                    <Table.Cell>{preparationLine.articleId}</Table.Cell>

                                                    <Table.Cell>
                                                        <span>
                                                            <div >
                                                                <a style={{ backgroundColor: 'none' }} href={`/detailArticle/${preparationLine.articleId}`}>
                                                                    {preparationLine.articleLibelle}
                                                                </a>
                                                                <div>
                                                                    {preparationLine.productAttributeValues.map(productAttributeValue => (
                                                                        <>
                                                                            <label style={{ fontSize: 12, paddingLeft: 30 }}>
                                                                                <b>{productAttributeValue.productAttributeName} :</b>
                                                                                {productAttributeValue.value}</label>
                                                                        </>
                                                                    ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </Table.Cell>
                                                    <Table.Cell>{preparationLine.entrepotsLibelle}</Table.Cell>
                                                    <Table.Cell>{preparationLine.quantite}</Table.Cell>
                                                    <Table.Cell>{preparationLine.quantiteDisponible}</Table.Cell>
                                                    <Table.Cell>{preparationLine.quantitePrepare}</Table.Cell>
                                                    <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }}>{getStatus(preparationLine.prepareLineState)}</Table.Cell>
                                                    {preparation?.prepareState != 'Expedie' && preparation?.isCanceled == false &&
                                                        <Table.Cell >
                                                            <Button.Group fluid widths={2}>
                                                                <Button
                                                                    onClick={(e) => {
                                                                        setEditForm(true);
                                                                        setEditTarget(parseInt(e.currentTarget.name))
                                                                        setQuantitePrepare(preparationLine.quantitePrepare != 0 ? preparationLine.quantitePrepare : (preparationLine.quantite < preparationLine.quantiteDisponible ? preparationLine.quantite : preparationLine.quantiteDisponible))
                                                                    }}
                                                                    color="blue"
                                                                    size='tiny'
                                                                    icon='edit outline'
                                                                    style={{ marginRight: '3%' }}
                                                                    name={preparationLine.idPreparationLines}
                                                                />
                                                            </Button.Group>
                                                        </Table.Cell>
                                                    }
                                                </>
                                            )}
                                        </Table.Row>
                                    </>
                                ))}
                            </Table.Body>
                        </Table>
                        <div style={{ paddingBottom: "60px" }}>
                            {preparation?.isCanceled == false ? (
                                <Button positive
                                    style={{ display: preparation?.prepareState == 'Termine' || preparation?.prepareState == 'EnCours' ? "block" : "none" }}
                                    floated='right'
                                    loading={validate}
                                    onClick={() => baseStore.preparationStore.editStatus(parseInt(match.params.id))}>
                                    Valider
                                </Button>) : (<></>)}
                        </div>
                    </div>
                </>
            }
            <Modal
                size={size}
                open={open}
                onClose={() => dispatch({ type: 'close' })}
            >
                <Modal.Header>Selectionne une Entrepot</Modal.Header>
                <Modal.Content>
                    <div className="field" style={{ marginTop: 13 }}>
                        <Select
                            name='entrepots'
                            placeholder={entrepots[entrepots.findIndex(x => x.isMain == true)]?.libelle ?? "Séléctionnez un entrepôt.."}
                            isLoading={loadingInitial}
                            options={entrepots}
                            getOptionLabel={e => e!.libelle}
                            getOptionValue={e => e!.entrepotsId.toString()}
                            onChange={e => { setDepot(e!.entrepotsId); }}
                        />
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={() => dispatch({ type: 'close' })}>
                        Quitter
                    </Button>
                    <Button positive
                        onClick={handlePDF}>
                        Télécharger
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
}
export default observer(ListPreparationLines);