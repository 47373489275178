import React, { useContext, useState } from 'react';
import { Button, Form, FormInput, Grid, Input, Label } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import AsyncSelect from 'react-select/async';
import Select, { components } from "react-select";
import { FaFileExcel, FaSearch, FaSort } from 'react-icons/fa';
import { ITListPayment, ITListPaymentMode, ITListSort } from '../../../app/common/options/IAchat';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { Form as FinalForm } from 'react-final-form';
import { MdCancel } from 'react-icons/md';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { numberFormat } from '../../../app/common/options/IStyle';


const style = {
    option: (provided: any, state: { isSelected: any; }) => ({
        ...provided,
        fontSize: 13,
    }),

}
const customStyles = {
    option: (provided: any, state: { isSelected: any; }) => ({
        ...provided,
        color: state.isSelected ? 'orange' : 'grey',
        backgroundColor: state.isSelected ? "#eee" : "white",
        borderBottom: '1px solid #eee',
    }),
    valueContainer: (props: any) => ({
        ...props,
        paddingTop: 0,
        height: '39.201px',
        minHeight: '39.201px',
    }),
    control: (baseStyles: any, state: any) => ({
        ...baseStyles,
        height: '39.201px',
        minHeight: '39.201px',
    }),
}

const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <FaSort color='green' />
        </components.DropdownIndicator>
    );
};

const CommandeAttenteFiltre = () => {
    const { RangePicker } = DatePicker;
    const baseStore = useContext(BaseStoreContext);
    const { ExportCommandesAttente, setPredicateCmdAtt, loadingExport, loadingCommandes, totalHt, totalTtc } = baseStore.commandeStore;
    const [searchResults, setSearchResults] = useState([]);
    const [searchResultsClient, setSearchResultsClient] = useState<any>('');
    const [searchResultsClientAll, setSearchResultsClientAll] = useState(false);
    const [selectedLabel, setSelectedLabel] = useState<any>("Client");
    const [selectedKey, setSelectedKey] = useState<any>('');
    const [selectedLabelSort, setSelectedLabelSort] = useState<any>('ETAT');
    const [selectedSort, setSelectedSort] = useState<any>('');
    const [selectedLabelTri, setSelectedTri] = useState<any>('Trier...');
    const [searchDateCommande, setSearchDateCommande] = useState<any>('');
    const [searchDateCommandeTo, setSearchDateCommandeTo] = useState<any>('');
    const [searchArticle, setSearchArticle] = useState<any>('');
    const [searchMontant, setSearchMontant] = useState<any>(0);
    const [modePaiement, setModePaiement] = useState<any>("");
    const [labelModePaiement, setLabelModePaiement] = useState("Mode de Paiement");
    const [payment, setPayment] = useState<any>("");

    const options = [
        { key: 'All', label: 'Tous' },
        { key: 'WaitingValidation', label: 'Attente de validation' },
        { key: 'Cancelled', label: 'Annulée' },
        { key: 'Rejected', label: 'Rejetée' },
        { key: 'WaitingForOnlinePayment', label: 'En attente de paiement' },
    ];
    const rangePresets: {
        label: string;
        value: [Dayjs, Dayjs];
    }[] = [
            { label: '7 derniers jours', value: [dayjs().add(-7, 'd'), dayjs()] },
            { label: '14 derniers jours', value: [dayjs().add(-14, 'd'), dayjs()] },
            { label: '30 derniers jours', value: [dayjs().add(-30, 'd'), dayjs()] },
            { label: '90 derniers jours', value: [dayjs().add(-90, 'd'), dayjs()] },
        ];
    const handleChange = (event: any) => {
        setSearchResults(event.target.value);
    }

    const handleChangeClient = (value: any) => {
        setSearchResultsClient(value.id);
        setSearchResultsClientAll(value.all);
        setSelectedLabel(value!.agencyName + "-" + value!.companyName + (value!.all ? "-Tous les réseaux" : ""));
    }
    const handleFilter = (key: string, value: string) => {
        setSelectedKey(key);
        setSelectedLabelSort(value)
    }

    const handleChangeTri = (value: any) => {
        setSelectedSort(value.value);
        let labelSelected: any = value.text;
        setSelectedTri(labelSelected);
    }

    const onRangeChange = (dates: null | (Dayjs | null)[]) => {
        if (dates) {
            setSearchDateCommande(dates[0]?.toISOString());
            setSearchDateCommandeTo(dates[1]?.toISOString());
        }
    };
    const handleInputChangeArticle = (value: any) => {
        setSearchArticle(value.target.value);
    }
    const handleInputChangeMontant = (value: any) => {
        setSearchMontant(value.target.value);
    }


    const handleSearch = (event: any) => {
        if (event == 'clique') {
            let obj: any = {
                searchClient: searchResultsClient,
                article: searchArticle,
                search: searchResults,
                key: selectedKey,
                sort: selectedSort,
                dateCommande: searchDateCommande,
                dateCommandeTo: searchDateCommandeTo,
                montant: searchMontant,
                modePaiement: modePaiement,
                payment: payment,
                searchClientAll: searchResultsClientAll,
                selectedLabel: selectedLabel
            }; ExportCommandesAttente(obj);
        }
        else if (event == 'clear') {
            setSearchResultsClient('')
            setSelectedSort('')
            setSelectedLabel("Rechercher client")
            setSelectedLabelSort('ETAT')
            setSelectedTri('Trier ...')
            setSearchDateCommande('')
            setSearchDateCommandeTo('')
            setSearchArticle('');
            setLabelModePaiement('Mode de Paiement');
            setModePaiement('');
            setSearchResults([]);
            setPayment('');
            setSelectedKey('');
            setSearchResultsClientAll(false);
            setPredicateCmdAtt('all', 'true')

        }
        else if (event == 'submit') {
            var predicate = new Map()
            predicate.set('searchClient', searchResultsClient)
            predicate.set("article", searchArticle);
            predicate.set("search", searchResults)
            predicate.set('key', selectedKey)
            predicate.set('sort', selectedSort)
            predicate.set("dateCommande", searchDateCommande);
            predicate.set("dateCommandeTo", searchDateCommandeTo);
            predicate.set("montant", searchMontant);
            predicate.set("modePaiement", modePaiement);
            predicate.set("payment", payment);
            predicate.set('searchClientAll', searchResultsClientAll)
            if (searchResults!.length > 2 || searchResultsClient != 0 || selectedKey != "" || selectedSort != ""
                || searchDateCommande != "" || searchDateCommandeTo != "" || searchArticle != "" || searchMontant != 0 || modePaiement != "" || payment != "")
                setPredicateCmdAtt('searchMultiple', predicate)
            else if (searchResults!.length == 0 && searchResultsClient.lenght == 0 && selectedKey == "" && selectedSort == ""
                && searchDateCommande == "" && searchDateCommandeTo == "" && searchArticle == "" && searchMontant == 0 && modePaiement == "" && payment == "")
                setPredicateCmdAtt('all', 'true')
        }
    }

    return (

        <FinalForm
            onSubmit={handleSearch}
            render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit} error style={{ width: "100%", fontSize: 14 }}>
                    <Grid>
                        <Grid.Row columns={5} style={{ padding: 0, margin: 0 }}>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <FormInput
                                    icon='search'
                                    type="text"
                                    placeholder="Commande"
                                    value={searchResults}
                                    onChange={handleChange}
                                    style={{ width: '100%' }}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <FormInput
                                    icon='search'
                                    type="text"
                                    placeholder="Article"
                                    name="article"
                                    value={searchArticle}
                                    style={{ height: '100%' }}
                                    onChange={handleInputChangeArticle}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <AsyncSelect
                                    name="form-field-name"
                                    noOptionsMessage={() => "Code introuvable"}
                                    cacheOptions
                                    defaultOptions
                                    placeholder={selectedLabel}
                                    value={searchResultsClient}
                                    getOptionLabel={e => e!.agencyName + "-" + e!.companyName + (e!.all ? "-Tous les réseaux" : "")}
                                    loadOptions={baseStore.clientStore.loadOptions}
                                    onChange={handleChangeClient}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <RangePicker
                                    presets={rangePresets}
                                    onChange={onRangeChange}
                                    format={'DD/MM/YYYY'}
                                    placeholder={["Début", "Fin"]}
                                    style={{ width: '100%', height: '39.201px' }}
                                />

                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <Input
                                    type="number"
                                    step={0.01}
                                    name="montant"
                                    placeholder="Montant TTC"
                                    style={{ height: 40 }}
                                    onChange={handleInputChangeMontant}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <Select
                                    isSearchable={false}
                                    components={{ DropdownIndicator }}
                                    styles={customStyles}
                                    options={options}
                                    value={selectedKey}
                                    getOptionLabel={e => e.label}
                                    onChange={(e) => handleFilter(e.key, e.label)}
                                    placeholder={selectedLabelSort}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <Select
                                    options={ITListPaymentMode}
                                    styles={customStyles}
                                    getOptionLabel={e => e!.text}
                                    value={modePaiement}
                                    onChange={e => { setLabelModePaiement(e!.text); setModePaiement(e!.value); }}
                                    placeholder={labelModePaiement}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0, display: 'flex' }}>
                                <Select
                                    isSearchable={false}
                                    components={{ DropdownIndicator }}
                                    styles={customStyles}
                                    options={ITListSort}
                                    value={selectedSort}
                                    getOptionLabel={e => e.text}
                                    onChange={(e) => handleChangeTri(e)}
                                    placeholder={selectedLabelTri} />
                                <Button style={{ padding: 5, margin: "0 0 0 10px", color: 'blue', background: "none" }} type='submit' onClick={(e: any) => handleSearch('submit')} >
                                    <FaSearch />
                                </Button>
                                <Button style={{ float: "right", padding: 5, margin: 0, color: 'green', background: "none" }} >
                                    <MdCancel style={{ fontSize: 17 }} onClick={(e: any) => handleSearch('clear')} />
                                </Button>
                             
                               
                            </Grid.Column >

                        </Grid.Row>
                    </Grid>
                    <div style={{ marginBottom:'9px',marginTop:'5px' }}>
                    {!loadingCommandes &&
                        <>
                          <Label>Total HT : {numberFormat.format(totalHt)}</Label>
                        <Label>Total TTC : {numberFormat.format(totalTtc)}</Label>
                        </>
                     }
                    <Button
                        size="tiny"
                        loading={loadingExport}
                        style={{ height: 32, float: "right" }}
                        content="Export Excel"
                        className="andi-btn filled success"
                        icon={(<FaFileExcel style={{ marginRight: "10px" }} />)}
                        onClick={(e: any) => handleSearch('clique')}
                        />
                    </div>

                </Form >
            )}
        />
    );
};

export default observer(CommandeAttenteFiltre);
