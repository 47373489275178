import React, { useContext, useEffect, useState } from 'react';
import { Form, Item, Table, Button, Header, Progress } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import Loading from '../../../app/layout/Loading';
import { observer } from 'mobx-react-lite';
import TextInput from '../../../app/common/form/inputText';
import SupportForm from './AddSupportModal';
import DeleteSupportModal from './DeleteSupportModal';


const ListSupport: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { loadSupports, loadingInitial, edit, supports, loadingSupportAction, progress, submittingSupport } = baseStore.supportStore;
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    const [editTarget, setEditTarget] = useState<string | undefined>(undefined);
    const [editForm, setEditForm] = useState(false);
    const [libelle, setLibelle] = useState<string | undefined>(undefined);
    const { openModal } = baseStore.modalStore;

    const handleFinalFormSubmit = () => {

        let newSupport = {
            libelle: libelle,
            supportId: editTarget,

        };
        edit(newSupport);
        setEditForm(false)
    }
    useEffect(() => {
        loadSupports();
    }, []);

    if (loadingInitial) return <Loading content="Chargement des supports ..." />

    return (
        <div>
            <Item.Group>
                <Item>
                    <Item.Content>
                        <Header as='h2'>{'Support'}</Header>
                        <Button
                            floated="right"
                            content={baseStore.supportStore.addMode ? 'Annuler' : 'Ajouter supports'}
                            color="orange"
                            icon='arrow alternate circle left outline'
                            basic
                            onClick={() => { baseStore.supportStore.changeButtonStateAdd(!baseStore.supportStore.addMode) }}
                        />
                    </Item.Content>
                </Item>
            </Item.Group>
            {baseStore.supportStore.addMode &&
                <div>
                    <SupportForm />
                    <br />
                </div>
            }
            {submittingSupport &&
                <Progress color='orange' percent={progress} size='tiny' />
            }
            <Table loading={false} celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Libelle</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {supports && supports.map((support: any) => (
                        <Table.Row key={support.supportId}>
                            {editForm && editTarget === support.supportId ? (
                                <>
                                    <Table.Cell selectable>
                                        <Form.Input name='libelle' component={TextInput} placeholder='libelle' defaultValue={support.libelle} style={{ width: '100%', padding: '1%' }} onChange={event => setLibelle(event.target.value)} />
                                    </Table.Cell>
                                    <Table.Cell selectable>
                                        <Button.Group fluid widths={2}>
                                            <Button
                                                type='button'
                                                onClick={() => {
                                                    setEditForm(false);
                                                }}>
                                                Annuler
                                            </Button>
                                            <Button.Or />
                                            <Button
                                                name={support.supportId}
                                                positive
                                                type='submit'
                                                onClick={(e) => {
                                                    handleFinalFormSubmit()
                                                    setEditTarget(e.currentTarget.name)
                                                }}
                                            >Confirmer</Button>
                                        </Button.Group>
                                    </Table.Cell>
                                </>
                            ) : (
                                <>
                                        <Table.Cell>{support.libelle}</Table.Cell>
                                    <Table.Cell >
                                        <Button.Group fluid widths={2}>
                                            <Button
                                                onClick={(e) => {
                                                    setEditForm(true);
                                                    setEditTarget(e.currentTarget.name)
                                                        setLibelle(support.libelle)
                                                }}
                                                color="blue"
                                                icon='edit outline'
                                                style={{ marginRight: '3%' }}
                                                    name={support.supportId}
                                            />
                                            <Button
                                                    name={support.supportId}
                                                basic
                                                color='red'
                                                icon='trash'
                                                onClick={(e) => {
                                                    openModal(<DeleteSupportModal support={support} />)
                                                    setDeleteTarget(e.currentTarget.name)
                                                }}
                                                    loading={loadingSupportAction && deleteTarget === support.supportId}
                                            />
                                        </Button.Group>
                                    </Table.Cell>
                                </>
                            )}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
}
export default observer(ListSupport);