import { useContext, useEffect } from 'react';
import { Tab, Header, Button, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import NotFound from '../../../app/layout/NoFound';
import FranchiseurGestionCommercialEditForm from './FranchiseurGestionCommercialEditForm';
import FormGridColumn from '../../../app/common/form/FormGridColumn';

const FranchiseurGestionCommercialDescription = () => {
    const getType = (type: string) => {
        switch (type) {
            case 'BillByCompany':
                return 'Une seule facture';
            case 'BillByCm':
                return 'Commande';
            case 'BillByBl':
                return 'Bon de livraison';
            default:
                return '';
        }
    }
    const baseRepo = useContext(BaseStoreContext);
    const { franchiseur, editFranchiseurGestionCommercial } = baseRepo.franchiseurStore;
    useEffect(() => {
        baseRepo.franchiseurStore.changeButtonState(false)
    }, [])
    if (!franchiseur) {
        return <NotFound />
    }
    else {
        return (
            <Tab.Pane>
                <Grid>
                    <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                        <Header floated='left' icon='address card' content={`Gestion commerciale`} />
                        <Button
                            onClick={() => { baseRepo.franchiseurStore.changeButtonState(!baseRepo.franchiseurStore.editFranchiseurMode) }}
                            floated='right'
                            basic
                            content={baseRepo.franchiseurStore.editFranchiseurMode ? 'Annuler' : 'Modifier'}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        {baseRepo.franchiseurStore.editFranchiseurMode ? (
                            <FranchiseurGestionCommercialEditForm editFranchiseur={editFranchiseurGestionCommercial} franchiseur={franchiseur!} />
                        ) : (
                            <Grid className="ui stackable">
                                <Grid.Row>
                                    <FormGridColumn icon="calendar times" header="Compte Collectif" content={franchiseur!.compteCollectif} />
                                    <FormGridColumn icon="calendar times" header="Compte Auxiliere" content={franchiseur!.compteAuxilliere} />
                                    <FormGridColumn icon="payment" header="Mode de paiement" content={franchiseur!.modePaiement} />
                                    <FormGridColumn icon="handshake" header="Echéance" content={franchiseur!.modeReglement} />
                                    <FormGridColumn icon="send" iconColor={franchiseur!.commissionType ? "green" : "red"} header="commissionType" content={franchiseur!.commissionType ? "Avec Commission" : "Sans Commission"} />
                                    <FormGridColumn icon="send" header="Valeur commission" content={franchiseur!.commissionValue} />
                                    <FormGridColumn icon="file alternate" header="Facturer par" content={getType(franchiseur!.billingType)} />
                                    {franchiseur!.modePaiement &&
                                        franchiseur.hasRum &&
                                        < FormGridColumn icon="payment" header="RUM" content={franchiseur!.rum} />
                                    }
                                </Grid.Row>
                            </Grid>
                        )}
                    </Grid.Column>
                </Grid>
            </Tab.Pane>
        );
    }
}
export default observer(FranchiseurGestionCommercialDescription);