import { useContext, useEffect } from 'react';
import { Tab, Header, Button, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import NotFound from '../../../app/layout/NoFound';
import FormGridColumn from '../../../app/common/form/FormGridColumn';
import ArticleServiceEdit from './ArticleServiceEdit';

const ArticleServiceDescription = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { article, editArticle } = baseRepo.articleStore;

    const getStatus = (status: string) => {
        switch (status) {
            case 'Online':
                return 'En ligne';
            case 'Offline':
                return 'Hors Ligne';
            case 'None':
                return 'Aucun';
            case 'VenteDirectePersonalise':
                return 'Prod interne';
            case 'Stock':
                return 'Stock';
            case 'VenteDirecte':
                return 'Vente Directe';
            default:
                return '';
        }
    }
    useEffect(() => {
        baseRepo.articleStore.changeButtonState(false);
    }, []);
    if (!article) {
        return <NotFound />
    }
    else {
        return (
            <Tab.Pane>
                <Grid>
                    <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                        <Header floated='left' icon='qrcode' content={`Code: ${article!.reference}`} />
                        <Button
                            onClick={() => { baseRepo.articleStore.changeButtonState(!baseRepo.articleStore.editArticleMode) }}
                            floated='right'
                            basic
                            content={baseRepo.articleStore.editArticleMode ? 'Annuler' : 'Modifier article'}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        {baseRepo.articleStore.editArticleMode ? (
                            <ArticleServiceEdit editArticle={editArticle} article={article!} />
                        ) : (
                            <Grid className="ui stackable">
                                <Grid.Row style={{ padding: 0, margin: 0 }}>
                                    <FormGridColumn header="Libelle" content={article!.label} />
                                    <FormGridColumn header="TVA" content={article!.tvaRate} />
                                    <FormGridColumn header="Référence Centrale" content={article!.externalReference} />
                                    <FormGridColumn header="Délai livraison" content={article!.deliveryDelay == null ? '0' : article!.deliveryDelay} />
                                    <FormGridColumn header="Mode Appro" content={getStatus(article!.modeAppro)} />
                                    <FormGridColumn header="Code Comptable" content={article!.codeComptable} />
                                </Grid.Row>
                            </Grid>
                        )}
                    </Grid.Column>
                </Grid>
            </Tab.Pane>
        );
    }
}
export default observer(ArticleServiceDescription);