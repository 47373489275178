import React, { useContext } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import NoResult from '../../commande/commandeDashboard/ErrorNoResult';
import { IDispatch } from '../../../app/models/IExpedition';
import _ from 'lodash';
import { FaFileInvoice } from 'react-icons/fa';


const numberFormat = new Intl.NumberFormat('FR-fr', {
    style: 'currency',
    currency: 'EUR',
    useGrouping: true,
});
const ListBRToBeInvoiced: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { retourAF, submitting, OrderDispatchFacture } = baseStore.expeditionStore;

    if (retourAF.length < 1) return < NoResult name="Retour" />
    else
        return (
            <>
                <Table compact='very' style={{ marginTop: '0em' }} >
                    <Table.Header>
                        <Table.Row style={{ textAlign: 'center' }}>
                            <Table.HeaderCell>Réference</Table.HeaderCell>
                            <Table.HeaderCell>Ref Commande</Table.HeaderCell>
                            <Table.HeaderCell>Montant HT</Table.HeaderCell>
                            <Table.HeaderCell>Montant TTC</Table.HeaderCell>
                            <Table.HeaderCell>Client</Table.HeaderCell>
                            <Table.HeaderCell>Date Expédition</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {retourAF && retourAF.map((stock: IDispatch, key) => (
                            <Table.Row key={stock.id} style={{ textAlign: 'center' }}>
                                <Table.Cell>
                                    <span><a style={{ background: "none", cursor: 'pointer' }} href={`/detailExpedition/${stock.id}`}><b>{stock.reference}</b></a></span>
                                </Table.Cell>
                                <Table.Cell><a href={`/detailCommande/${stock.refOrder.substring(7)}`}>{stock.refOrder}</a></Table.Cell>
                                <Table.Cell>{numberFormat.format(stock.amountWithoutTaxes)}</Table.Cell>
                                <Table.Cell>{numberFormat.format(stock.amountWithTaxes)}</Table.Cell>
                                <Table.Cell>{stock.agencyName}-{stock.companyName}</Table.Cell>
                                <Table.Cell>{new Date(stock.dispatchDate).toLocaleDateString('en-GB')}</Table.Cell>
                                <Table.Cell >
                                    <Button
                                        color="orange"
                                        cursor="pointer"
                                        loading={submitting}
                                        content=' Facturer'
                                        style={{ width: 70, paddingRight: 5, paddingLeft: 5, fontSize: 11 }}
                                        icon={<FaFileInvoice style={{ fontSize: 10 }} />}
                                        onClick={
                                            () => OrderDispatchFacture(stock.id)}
                                    />
                                </Table.Cell>

                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </>

        );
}
export default observer(ListBRToBeInvoiced);