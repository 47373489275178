import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { Checkbox, Container, Table } from "semantic-ui-react";
import Loading from "../../../app/layout/Loading";
import { IFacture, IInvoiceLine } from "../../../app/models/IFacture";
import { BaseStoreContext } from "../../../app/store/baseStore";


interface IProps {
    Facture: IFacture;
    modalButton: any;
    setModalOpen: Function;
    setModalButton: Function;
    setIsValid: Function;
}

const FactureAvoireModal: React.FC<IProps> = ({ Facture, modalButton, setModalOpen, setModalButton, setIsValid })  => {

    const baseRepo = useContext(BaseStoreContext);
    const { create } = baseRepo.factureStore;
    const [value, setValue] = useState<Map<number, number>>();
    const [quantite, setQuantite] = useState<Map<number, number>>();
    const [montantHT, setMontantHT] = useState<Map<number, number>>();
    const [montantTTC, setMontantTTC] = useState<Map<number, number>>();
    const [price, setUnitPrice] = useState<Map<number, number>>();
    const min = 0;
    const max = 100;
    var QMap = new Map<number, number>();
    var HTMap = new Map<number, number>();
    var TTCMap = new Map<number, number>();
    var UPMap = new Map<number, number>();
    var mvalue = new Map<number, number>();
    const [theFinalList, setTheFinalList] = useState<IInvoiceLine[]>([]);

    const [selectAll, setSelectAll] = useState(false);
    const [selected, setSelected] = useState<string[]>([]);

    useEffect(() => {
        if (Facture) {
        Facture!.invoiceLines.map(item => {
            setValue(mvalue.set(item.id! , 100))
            setQuantite(QMap.set(item.id!, item.quantity!));
            setMontantHT(HTMap.set(item.id!, item.totalPrice!));
            setMontantTTC(TTCMap.set(item.id!, item.totalPriceWithTaxes!));
            setUnitPrice(UPMap.set(item.id!, item.unitPrice!));
        })
            const newList = Facture.invoiceLines.map(obj => { return { ...obj, remise: 100 }; });
            setTheFinalList(newList);

            if (modalButton == true) {
                handleFactureAvoir();
                setModalButton(false);
                setModalOpen(false);
            }
        }
    }, [modalButton])

    const handleChangePercent = (key: number, event: any) => {
        let percent = Number(event.target.value);
        percent = Math.max(min, Math.min(max, percent));

        mvalue.set(key, percent);
        setValue(mvalue);
        let getPrixArticle = Facture.invoiceLines.find(e => e.id == key)?.unitPrice;
        const newPrice = (getPrixArticle! * percent) / 100;

        const qte = quantite?.get(key);
        const total = qte! * newPrice;
        montantHT?.set(key, total);
        let getTauxTva = Facture.invoiceLines.find(e => e.id == key)?.tvaRate;
        const totalTaxe = (total * (100 + getTauxTva!)) / 100;
        montantTTC?.set(key, totalTaxe);
        const newliste = theFinalList.map(obj => {
            if (obj.id == key && selected.includes(obj.id.toString())) {
                return { ...obj, totalPrice: total, totalPriceWithTaxes: totalTaxe, remise: percent };
            }
            return obj;
        });
        setTheFinalList(newliste);
    }

    const handleChangeQte = (key: number, event: any) => {

        let getquantite = Facture.invoiceLines.find(e => e.id == key)?.quantity;
        const newQuantiteTyped = Number(event.target.value);
        const newQuantiteInt = Math.max(min, Math.min(getquantite!, newQuantiteTyped));

        quantite?.set(key, newQuantiteInt);
        console.log(quantite?.get(key));

        let getPrixArticle = price?.get(key);
        const total = newQuantiteInt * getPrixArticle!;
        montantHT?.set(key, total);
        let getTauxTva = Facture.invoiceLines.find(e => e.id == key)?.tvaRate;
        const totalTaxe = (total * (100 + getTauxTva!)) / 100;
        montantTTC?.set(key, totalTaxe);

        const newliste = theFinalList.map(obj => {
            if (obj.id == key && selected.includes(obj.id.toString())) {
                return { ...obj, quantity: newQuantiteInt, totalPrice: total, totalPriceWithTaxes: totalTaxe };
            }
            return obj;
        })
        const filterlist = newliste.filter(item => {
            return item.quantity !== 0;
        });
        setTheFinalList(filterlist);
    };

    const handleFactureAvoir = () => {
        let selectedListe: IInvoiceLine[] = [];
        theFinalList.map(obj => {
            if (selected.includes(obj.id!.toString()))
                selectedListe.push(obj);
        })

        let newFactureAvoir = {
            idUser: Facture.idCompany,
            invoiceType: 'Facture_Avoir',
            idFactureOrigin: Facture.id,
            invoiceLines: selectedListe,
        };
        create(newFactureAvoir);
    }

    const handleChangeSelectedItems = () => {
        var tab: string[] = [];
        console.log("tab length : " + tab.length);
        Facture!.invoiceLines.map(item => {
            tab.push(item.id!.toString());
        });
        if (tab.length > 0)
            setIsValid(false);

        setSelected(tab)
    }
    const handleChangeSelectedItem = (id: string) => {
        var tab: string[] = [];
        selected.map(item => (
            tab.push(item)));
        if (tab.includes(id))
            tab = tab.filter(x => x != id);
        else
            tab.push(id);

        if (tab.length > 0)
            setIsValid(false);
        else
            setIsValid(true);

        setSelected(tab);
    }

    const deselect = () => {
        setSelected([]);
        setIsValid(true);
    }

    const getBackgroundColor = (key: string) => {
        return selected.includes(key) ? 'darkgrey' : 'white';
    }
    

    if (!Facture) return <Loading content="Chargement du Facture ..." />
    else {
    return (
        
        <Container textAlign='center'>
            <Checkbox checked={selectAll} label="tout sélectionner" style={{ float: 'right', padding: '0 10px', paddingTop: 10 }} onChange={() => { setSelectAll(!selectAll); selectAll ? deselect() : handleChangeSelectedItems() }} />
            <Table loading={false} celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell>Article</Table.HeaderCell>
                        <Table.HeaderCell>Quantité</Table.HeaderCell>
                        <Table.HeaderCell>PU HT.</Table.HeaderCell>
                        <Table.HeaderCell>Montant HT</Table.HeaderCell>
                        <Table.HeaderCell>Montant TTC</Table.HeaderCell>

                        <Table.HeaderCell style={{ textAlign: "center" }}>Remise % </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>

                    {Facture &&
                        Facture.invoiceLines.map(factureLine => (

                            <Table.Row key={factureLine.id} style={{ backgroundColor: getBackgroundColor(factureLine.id!.toString()) }}>

                                <Table.Cell>
                                    <Checkbox style={{ paddingTop: 4, marginRight: 5 }} checked={selected.includes(factureLine.id!.toString())} onChange={() => handleChangeSelectedItem(factureLine.id!.toString())} />
                                </Table.Cell>
                                <Table.Cell>{factureLine.productId}-{factureLine.libelle}</Table.Cell>
                                <Table.Cell style={{ textAlign: "center" }}>
                                    <input
                                        disabled={selected.includes(factureLine.id!.toString()) ? false : true}
                                        style={{ width: 70, textAlign: "center" }}
                                        className="field"
                                        type="number"
                                        value={quantite?.get(factureLine.id!)}
                                        onChange={(e) => handleChangeQte(factureLine.id!, e)}
                                    />
                                    
                                </Table.Cell>
                                <Table.Cell>{price?.get(factureLine.id!)!.toFixed(4)} €</Table.Cell>
                                <Table.Cell>{montantHT?.get(factureLine.id!)!.toFixed(2)} €</Table.Cell>
                                <Table.Cell>{montantTTC?.get(factureLine.id!)!.toFixed(2)} €</Table.Cell>
                                <Table.Cell style={{ textAlign: "center" }}>
                                    
                                    <input
                                        disabled={selected.includes(factureLine.id!.toString()) ? false : true}
                                            style={{ width: 70 }}
                                                className="field"
                                                type="number"
                                                placeholder="  ...%"
                                        value={value?.get(factureLine.id!)}
                                        onChange={(e) => handleChangePercent(factureLine.id!, e)} /> %
                                    
                                </Table.Cell>
                            </Table.Row>
                            ))}
                           
                </Table.Body>
           </Table>
        </Container>
        );
    }
}

export default observer(FactureAvoireModal);