import { BaseStore } from "./baseStore";
import { observable, action, runInAction, computed, reaction } from 'mobx';
import agent from '../agent/agent';
import { toast } from "react-toastify";
import { IReception, IReceptionLine, IPartialReception, IReceptionLineUpdated, IReceptionPartial } from "../models/IReception";
import { history } from "../..";
const LIMIT = 20;

export default class ReceptionStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0;
                this.loadReceptionList();
            }
        )
      
    }

    @observable submitting = false;
    @observable Facturation = false;
    @observable loadingInitial = false;
    @observable loadingExport = false;
    @observable loading = false;
    @observable deleting = false;
    @observable receptionLines: IReceptionLine[] = [];
    @observable receptionRegestry = new Map();
    @observable receptions: IReception[] = [];
    @observable reception: IReception|null = null;
    @observable receptionCount: number = 0;
    @observable page: number = 0;
    @observable totalHt: number = 0;
    @observable totalTtc: number = 0;
    @observable predicate = new Map();
    @observable generatePdfMode = false;

    @computed get axiosParams() {
        const params = new URLSearchParams();
        params.append('limit', String(LIMIT));
        params.append('offset', `${this.page ? this.page * LIMIT : 0}`);
        this.predicate.forEach((value, key) => {
            if (key === 'startDate') {
                params.append(key, value.toISOString())
            } if (key === 'searchMultiple') {
                var search = [...value][0];
                var searchCommande = [...value][1];
                var codeArticle = [...value][2];
                var codeFranchiseur = [...value][3];
                var codeFournisseur = [...value][4];
                var dateCommande = [...value][5];
                var dateCommandeTo = [...value][6];
                var key = [...value][7];
                var tri = [...value][8];
                var SearchNFournisseur = [...value][9];
                var InternalRef = [...value][10];
                params.append(search[0], search[1])
                params.append(searchCommande[0], searchCommande[1])
                params.append(codeArticle[0], codeArticle[1])
                params.append(codeFranchiseur[0], codeFranchiseur[1])
                params.append(codeFournisseur[0], codeFournisseur[1])
                params.append(dateCommande[0], dateCommande[1])
                params.append(dateCommandeTo[0], dateCommandeTo[1])
                params.append(key[0], key[1])
                params.append(tri[0], tri[1])
                params.append(SearchNFournisseur[0], SearchNFournisseur[1])
                params.append(InternalRef[0], InternalRef[1])
            } else {
                params.append(key, value)
            }
        })
        return params;
    }

    @computed get totalPages() {
        return Math.ceil(this.receptionCount / LIMIT);
    }

    @action setPage = (page: number) => {
        this.page = page;
    }

    @action setPredicate = (predicate: string, value: any) => {
        this.receptions = [];
        this.predicate.clear();
        if (predicate !== 'searchMultiple') {
            this.predicate.clear();
        }
        if (predicate !== 'all') {
            this.predicate.set(predicate, value);
        }
    }

    @action create = async (reception: Partial<IPartialReception>) => {
        this.submitting = true;
        try {
            var id = await agent.receptionAgent.create(reception);
            runInAction(() => {
                this.submitting = false;
                history.push(`/detailReception/${id}`);
                toast.success("Réception ajoutée avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
        }
    }

    @action changeButtonPdfState = async (newState: boolean) => {
        this.generatePdfMode = newState;
    }

    @action addReceptionPartiel = async (reception: Partial<IReceptionPartial>) => {
        this.submitting = true;
        try {
            var id = await agent.receptionAgent.addReceptionPartiel(reception);
            runInAction(() => {
                this.submitting = false;
                history.push(`/detailFactureFournisseur/${id}`);
                toast.success("Facture fournisseur créé avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
        }
    }

    @action loadReceptionLines = async (id: number) => {
        this.loadingInitial = true;
        try {
            let result = await agent.receptionAgent.detailsReceptionLines(id);
            runInAction(() => {
                this.receptionLines = result.receptionLines;
                this.reception = result.reception;
                this.loadingInitial = false;
            })
        } catch (e) {
            toast.error("Problem loading reception lignes!");
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }

    @action loadReceptionList = async () => {
        this.loadingInitial = true;
        try {
            const enveloppe = await agent.receptionAgent.list(this.axiosParams);
            const { receptions, receptionCount, totalHt,totalTtc } = enveloppe;
            runInAction(() => {
                receptions.forEach((item) => {
                    this.receptionRegestry.set(item.id, item);
                    if (this.page == 0) {
                        this.receptions = receptions;
                    } else {
                        this.receptions.push(item);
                    }
                })
                this.receptionCount = receptionCount;
                this.totalTtc = totalTtc;
                this.totalHt = totalHt;
                this.loadingInitial = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }

    @action generatePDF = async (id: number) => {
        try {
            await agent.receptionAgent.generatePDF(id);
            toast.success("téléchargement avec succès")
        }
        catch (e) {
            console.log(e);
        }
    }

    @action changeStateItems = async (data: IReceptionLineUpdated) => {
        this.loading = true;
        try {
            var count = await agent.receptionAgent.addReceivedQuantity(data);
            runInAction(() => {
                if (data.requiredQuantity == data.receivedQuantity + data.addedQuantity)
                    this.receptionLines[this.receptionLines.findIndex(x => x.id == data.idOrderLine)].receptionState = "Receptionnee";
                else if (data.addedQuantity != 0)
                    this.receptionLines[this.receptionLines.findIndex(x => x.id == data.idOrderLine)].receptionState = "ReceptionnePartielle";

                this.receptionLines[this.receptionLines.findIndex(x => x.id == data.idOrderLine)].receivedQuantity = data.receivedQuantity + data.addedQuantity;
                this._baseStore.articleStockStore.loadAlertStockList();
                this._baseStore.statisticStore.produitHSCount = count;
                this.loading = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    @action updateReceptionDate = async (data: any) => {
        try {
            await agent.receptionAgent.updateDate(data);
            runInAction(() => {
                this.reception!.dateReception = data.date;
                toast.success("La date a été changée avec succès..");
            })
        } catch (e) {
            console.log(e);
        }
    }

    @action deleteReception = async (id: number) => {
        this.deleting = true;
        try {
            await agent.receptionAgent.delete(id);
            runInAction(() => {
                toast.success("La réception a été supprimée avec succès..");
                this.deleting = false;
                history.push("/receptionDashboard")
            })
        } catch (e) {
            runInAction(() => {
                this.deleting = false;
            })
            console.log(e);
        }
    }

    @action receptionnerTous = async (values: number) => {
        this.submitting = true;
        try {
            await agent.receptionAgent.receptionnerTous(values);
            let recep = await agent.receptionAgent.details(values);
            runInAction(() => {
                this.reception = recep;
                this.receptionLines.filter(x => x.orderReception.id == values).forEach(item => {

                    item.receptionState = "Receptionnee"
                    item.receivedQuantity = item.requieredQuantity!;
                })
                this.reception!.receptionState = 'Receptionnee';
                this.submitting = false;
            })
            toast.success("Reception valider avec succès");

        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
        }
    }
    @action factureListReceptions = async (ids: number[]) => {
        this.Facturation = true;
        try {
             await agent.receptionAgent.generateFactureByReception(ids);           
            runInAction(() => {
                this.Facturation = false;
            })
            history.push('/factureFournisseurDashboard')
        } catch (e) {
            toast.error("Problème d'ajouts de liste select  !");
            runInAction(() => {
                this.Facturation = false;
            })
        }
    }
    @action editReceptionLineDate = async (obj: any) => {
        this.submitting = true;
        try {
            var id = await agent.receptionAgent.updateReceptionLineDate(obj);
            runInAction(() => {
                this.submitting = false;
                
                history.push(`/detailReception/${id}`);
                this.loadReceptionLines(id);
                toast.success("Les dates sont modifiés avec succées");
            })
        } catch (e) {
            toast.error("Problème d'ajouts de liste select  !");
            runInAction(() => {
                this.submitting = false;
            })
        }
    }

    @action ExportReceptions = async (obj: any) => {
        this.loadingExport = true;
        try {
            await agent.receptionAgent.exportReception(obj);
            runInAction(() => {
                this.loadingExport = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingExport = false;
            })
            console.log(e);
        }
    }
}