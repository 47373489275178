import React, { useContext, useEffect, useState} from 'react';
import { Button, Grid, Header, Modal } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Loading from '../../../app/layout/Loading';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import FranchiseurContent from './FranchiseurContent';
import FranchiseurHeader from './FranchiseurHeader';
import Breadcrumbs from '../../dashboard/breadcrumbs';
import ListPacksModal from './ListPacksModal';
import SendLoginInfoModal from './SendLoginInfoModal';

interface RouteParams {
    id: string,

}

interface IProps extends RouteComponentProps<RouteParams> { }
const FranchiseurPage: React.FC<IProps> = ({ match }) => {
    const baseStore = useContext(BaseStoreContext);
    const { loadFranchiseur, setActiveTab, loadingFranchiseur, franchiseur, enable, disable, deletingFranchiseur } = baseStore.franchiseurStore;
    const { sending } = baseStore.userStore;
    const { isLoggedIn } = baseStore.userStore;
    const [ openModal, setOpen ] = useState(false);
    const [openModalSendLogin, setOpenSendLogin ] = useState(false);

    useEffect(() => {
        loadFranchiseur(parseInt(match.params.id));
    }, [])

    if (loadingFranchiseur) return <Loading content="Chargement des Franchiseurs ..." />
    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Tiers", root: "" },
                    { key: 1, name: "Franchiseurs", root: "/franchiseurDashboard" },
                    { key: 2, name: "" + franchiseur?.companyName, root: `/detailFranchiseur/${franchiseur?.id}` },
                ]}
            />
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Grid style={{ marginTop: 2 }}>
                            <Grid.Column width={16}>
                                <Header floated='left' content={'Mettre à jour le Franchiseurs '} />

                                <Button
                                    floated="right"
                                    color="red"
                                    basic
                                    content="Retour"
                                    size='tiny'
                                    icon='arrow alternate circle left outline'
                                    as={Link}
                                    to="/franchiseurDashboard"
                                />
                                <Button
                                    floated="right"
                                    content="Accèder aux clients"
                                    color="orange"
                                    size="tiny"
                                    as={Link}
                                    to={`/clients/${parseInt(match.params.id)}`}
                                />
                                <Button
                                    floated="right"
                                    icon="download"
                                    content="SOLDE PACK OUVERTURE"
                                    color="green"
                                    size="tiny"
                                    onClick={(e) => {
                                        setOpen(!openModal);
                                    }}
                                />
                                <Button
                                    floated="right"
                                    icon="send"
                                    content="Envoyer les infos"
                                    color="blue"
                                    size="tiny"
                                    loading={sending}
                                    onClick={(e) => {
                                        setOpenSendLogin(!openModalSendLogin);
                                    }}
                                />
                            </Grid.Column>
                            <Grid.Column width={16} >
                                <FranchiseurHeader franchiseur={franchiseur!} disable={disable} enable={enable} isLoggedIn={isLoggedIn} loading={deletingFranchiseur} />
                                <FranchiseurContent setActiveTab={setActiveTab} />
                            </Grid.Column>
                            <Modal open={openModalSendLogin}
                                style={{ width: 500 }}
                                closeOnEscape={true}
                                closeOnRootNodeClick={true}>
                                <SendLoginInfoModal id={parseInt(match.params.id)} agencyName={franchiseur?.agencyName!+ "-" + franchiseur?.companyName!} setModalOpen={setOpenSendLogin} />
                            </Modal>
                            <Modal open={openModal}
                                style={{width:1000}}
                                closeOnEscape={true}
                                closeOnRootNodeClick={true}>
                                <ListPacksModal id={parseInt(match.params.id)} agencyName={franchiseur?.agencyName!} setModalOpen={setOpen} />
                            </Modal>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}

export default observer(FranchiseurPage);