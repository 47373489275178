import { BaseStore } from "./baseStore";
import { observable, action, runInAction } from 'mobx';
import agent from '../agent/agent';
import { toast } from "react-toastify";
import { ITypeMarche, ITypeMarcheList } from "../models/ITypeMarche";
import { setTypeMarcheProps } from "../common/util/util";

export default class typeMarchestore {

    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
    }

    @observable loadingTypeMarcheAction = false;
    @observable loadingTypeMarche = false;
    @observable loadingInitial = false;
    @observable submitting = false;
    @observable deletingTypeMarche = false;
    @observable typeMarche: ITypeMarche | null = null;
    @observable typeMarches: ITypeMarche[] = [];
    @observable typeMarcheRegestry = new Map();
    @observable ITListTypeMarche: ITypeMarcheList[] = [];
    @observable editTypeMarche: boolean = false;
    @observable addMode: boolean = false;
    @observable progress: number = 50;

    @action changeButtonStateEdit = async (newState: boolean) => {
        this.editTypeMarche = newState;
    }

    @action changeButtonStateAdd = async (newState: boolean) => {
        this.addMode = newState;
    }

    @action loadTypeMarches = async () => {
        this.loadingInitial = true;
        this.ITListTypeMarche = [];
        try {
            const typeMarches = await agent.typeMarcheAgent.list();
            runInAction(() => {
                this.typeMarches = typeMarches;
                typeMarches.forEach((typeMarches) => {
                    setTypeMarcheProps(typeMarches);
                    this.typeMarcheRegestry.set(typeMarches.typeMarcheId, typeMarches);
                    let marche: ITypeMarcheList = {
                        key: typeMarches.typeMarcheId,
                        text: typeMarches.label,
                        value: typeMarches.typeMarcheId,
                    }
                    this.ITListTypeMarche.push(marche)
                })
                this.loadingInitial = false;
            })
        } catch (e) {
            toast.error("Problème de chargement des types de marché !");
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }

    @action loadTypeMarche = async (id: string) => {

        this.loadingTypeMarche = true;
        let TypeMarche = this.getTypeMarche(id);
        if (TypeMarche) {
            this.typeMarche = TypeMarche;
            this.loadingTypeMarche = false;
            return TypeMarche;
        }
        else {
            try {
                TypeMarche = await agent.typeMarcheAgent.details(id);
                runInAction(() => {
                    TypeMarche.dateCreation = TypeMarche.dateCreation.split('T')[0];
                    this.typeMarcheRegestry.set(TypeMarche.formeJuridiqueId, TypeMarche);
                    this.typeMarche = TypeMarche;
                    this.loadingTypeMarche = false;
                })
                return TypeMarche;
            } catch (e) {
                runInAction(() => {
                    this.loadingTypeMarche = false;
                    console.log(e);
                })
            }
        }
    }

    getTypeMarche = (id: string) => {
        return this.typeMarcheRegestry.get(id);
    }

    @action create = async (values: ITypeMarche) => {
        this.submitting = true;
        const user = await agent.userAgent.current();
        try {
            await agent.typeMarcheAgent.create(values);
            runInAction(() => {
                this.typeMarcheRegestry.set(values.typeMarcheId, values)
                this.typeMarche = values;
                this.typeMarches.push(this.typeMarche);
                this.submitting = false;
                toast.success("ajout éffectué avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    @action edit = async (values: Partial<ITypeMarche>) => {
        this.submitting = true;
        this.progress = 80;
        try {
            await agent.typeMarcheAgent.update(values);
            let TypeMarche = await agent.typeMarcheAgent.details(values.typeMarcheId!);
            runInAction(() => {
                this.typeMarches = this.typeMarches.filter(a => a.typeMarcheId != values.typeMarcheId);
                this.typeMarches.push(TypeMarche);
                toast.success("Modification éffectuée avec succès");
                this.submitting = false;
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action deleteTypeMarche = async (id: string) => {
        this.loadingTypeMarcheAction = true;
        try {
            await agent.typeMarcheAgent.delete(id);
            runInAction(() => {
                this.loadingTypeMarcheAction = false;
                this.typeMarches = this.typeMarches.filter(a => a.typeMarcheId !== id);
                toast.success("suppression effectuée avec succès");
            })
        } catch (e) {
            runInAction(() => {
                console.log(e);
                toast.error("Problème de suppréssion du type de marché");
                this.loadingTypeMarcheAction = false;
            })
        }
    }
}

