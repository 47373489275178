import React, { useContext } from 'react';
import { Button, Header, Icon, Container } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';

interface IProps {
    client: any;
}

const AddCommandeClientModal: React.FC<IProps> = ({ client }) => {
    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    const { create } = baseStore.commandeStore;
    let newCommande = {
        agencyName: client.agencyName
    };
    return (
        <Container textAlign='center'>
            <Header icon='add' content='Ajouter une Commande' style={{ marginLeft: 20 }} />
            <p>
                Voulez vous ajouter une commande à ce franchisé ?
            </p>    
                <Button.Group fluid>
                    <Button onClick={() => closeModal()}>
                        <Icon name='remove' /> Annuler
                    </Button>
                    <Button.Or text='Ou' />
                    <Button
                        color='green'
                        onClick={(e) => {
                            create(newCommande);
                            closeModal()
                        }}>
                        <Icon name='checkmark' /> Confirmer
                    </Button>
                </Button.Group>
        </Container>
    )
}
export default AddCommandeClientModal;
