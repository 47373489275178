import React, { useContext } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button, Grid } from 'semantic-ui-react';
import TextInput from '../../../app/common/form/inputText';
import { observer } from 'mobx-react-lite';
import { history } from '../../../index';
import { ICommande } from '../../../app/models/ICommande';
import { combineValidators, composeValidators, isRequired } from 'revalidate';
import { BaseStoreContext } from '../../../app/store/baseStore';




interface IProps {
    editCommande: (commande: Partial<ICommande>) => void;
    commande: ICommande;
    submitting: boolean;
}
const CommandeClientEditForm: React.FC<IProps> = ({ editCommande, commande, submitting }) => {
    const baseStore = useContext(BaseStoreContext);
    const handleFinalFormSubmit = (values: any) => {
        let newCommande = {
            ...values,
        };
        editCommande(newCommande);
    }
    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            initialValues={commande!}
            render={({ handleSubmit, invalid }) => (
                <Form onSubmit={handleSubmit} error>
                    <Grid columns={2} divided className='WithoutSpace'>
                        <Grid.Row>
                            <Grid.Column>
                                <h5>Email *</h5>
                                <Field name='deliveryEmail' component={TextInput} placeholder='Email' value={commande!.deliveryEmail} />
                            </Grid.Column>
                            <Grid.Column>
                                <h5>Digit code *</h5>
                                <Field name='deliveryDigiCode' component={TextInput} placeholder='Digit code' value={commande!.deliveryDigiCode} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <h5>Interphone *</h5>
                                <Field name='deliveryInterphone' component={TextInput} placeholder='Interphone' value={commande!.deliveryInterphone} />
                            </Grid.Column>
                            <Grid.Column>
                                <h5>Horaires d'ouverture *</h5>
                                <Field name='openHours' component={TextInput} placeholder='Horaires douverture' value={commande!.openHours} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <h5>Jours de fermeture *</h5>
                                <Field name='closingDays' component={TextInput} placeholder='Jours de fermeture' value={commande!.closingDays} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <div style={{ marginTop: '20px' }}>
                        <Button.Group floated="right">
                            <Button onClick={() => baseStore.commandeStore.changeButtonState(false)} floated='right' type='button'>Annuler</Button>
                        <Button.Or />
                        <Button positive disabled={submitting || invalid} loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </div>
                </Form>
            )}
        />
    );
};

export default observer(CommandeClientEditForm);