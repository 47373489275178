import { useContext, useState } from 'react';
import { Tab, Header, Card, Image, Button, Grid } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import PhotoUploadWidget from '../../../app/common/photoUpload/PhotoUploadWidget';
import { observer } from 'mobx-react-lite';

const FournisseurPhoto = () => {
    const baseStore = useContext(BaseStoreContext)
    const { fournisseur, uploadPhoto, uploadingPhoto, setMainPhoto, deletePhoto, loadingPhotoAction } = baseStore.fournisseurStore;

    const [addPhotoMode, setAddPhotoMode] = useState(false);
    const [target, setTarget] = useState<string | undefined>(undefined);
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    const handleUploadImage = (photo: Blob, id = fournisseur!.fournisseurId) => {
        uploadPhoto(photo, id).then(() => setAddPhotoMode(false));
    }


    return (
        <Tab.Pane>
            <Grid>
                <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                    <Header floated='left' icon='image' content='Logo' />
                    {fournisseur!.photos!.length < 2 && (
                        <Button
                            onClick={() => setAddPhotoMode(!addPhotoMode)}
                            floated='right'
                            basic
                            disabled={fournisseur!.photos.length == 1}
                            content={addPhotoMode ? 'Annuler' : 'Ajouter logo'}
                        />
                    )}
                </Grid.Column>
                <Grid.Column width={16}>
                    {addPhotoMode ? (
                        <PhotoUploadWidget
                            uploadPhoto={handleUploadImage}
                            loading={uploadingPhoto}
                        />
                    ) : (
                        <Card.Group itemsPerRow={5}>
                                {fournisseur &&
                                    fournisseur.photos.map(photo => (
                                    <Card key={photo.id}>
                                        <Image src={photo.url} />
                                        {(
                                            <Button.Group fluid widths={2}>
                                                <Button
                                                        onClick={e => {
                                                            setMainPhoto(photo, fournisseur!.fournisseurId);
                                                        setTarget(e.currentTarget.name);
                                                    }}
                                                    name={photo.id}
                                                    disabled={photo.isMain}
                                                    loading={loadingPhotoAction && target === photo.id}
                                                    basic
                                                    positive
                                                    content='Main'
                                                />
                                                <Button
                                                    name={photo.id}
                                                        onClick={(e) => {
                                                            deletePhoto(photo, fournisseur!.fournisseurId);
                                                        setDeleteTarget(e.currentTarget.name)
                                                    }}
                                                    loading={loadingPhotoAction && deleteTarget === photo.id}
                                                    basic
                                                    negative
                                                    icon='trash'
                                                />
                                            </Button.Group>
                                        )}
                                    </Card>
                                ))}
                        </Card.Group>
                    )}
                </Grid.Column>
            </Grid>
        </Tab.Pane>
    );
};

export default observer(FournisseurPhoto);
