import { Tab } from "semantic-ui-react"
import { useContext } from "react"
import { BaseStoreContext } from "../../../app/store/baseStore"
import { observer } from "mobx-react-lite"
import FactureDescription from "./FactureDescription"
import FactureInfo from "./FactureInfo"
import FactureArticle from "./FactureArticle"
import ObjetLier from "./RelatedObject"


const panes = [
    { menuItem: 'Général', render: () => <FactureDescription /> },
    { menuItem: 'Article(s)', render: () => <FactureArticle /> },
    { menuItem: 'Informations', render: () => <FactureInfo /> },
    { menuItem: 'Objets Liés', render: () => <ObjetLier /> },
]
interface IProps {
    setActiveTab: (activeIndex: any) => void;
}
const FactureContent: React.FC<IProps> = ({ setActiveTab }) => {
    const baseStore = useContext(BaseStoreContext);
    return (
        <Tab
            menu={{ fluid: true, vertical: true }}
            menuPosition='right'
            activeIndex={baseStore.factureFournisseurStore.activeTab}
            panes={panes}
            onTabChange={(e, data) => setActiveTab(data.activeIndex)}
        />
    )
}

export default observer(FactureContent);