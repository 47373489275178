import { Item, Segment, Icon, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import React, { useContext } from 'react';
import { IFranchiseur } from '../../../app/models/IFranchiseur';
import { BaseStoreContext } from '../../../app/store/baseStore';
import AddCommandeFranchiseurModal from '../../commande/addCommande/AddCommandeFranchiseurModal';
import AddCommandeClientModal from '../../commande/addCommande/AddCommandeClientModal';

const stylesNonactif = {
    border: 'solid',
    borderColor: 'red',
    borderWidth: 2
}

const FranchiseurListItem: React.FC<{ franchiseur: IFranchiseur }> = ({ franchiseur }) => {
    const baseStore = useContext(BaseStoreContext);
    const { openModal } = baseStore.modalStore;

    return (
        <>
            {
                franchiseur.franchisorId == 0 ?
                    (
                        <Segment.Group>
                            <Segment>
                                <Item.Group>
                                    <Item>
                                        <Item.Image
                                            size='small'
                                            src={franchiseur.uri}
                                            style={franchiseur.isActive ? null : stylesNonactif} />
                                        <Item.Content>
                                            <Item.Header as={Link} to={`/detailFranchiseur/${franchiseur.id}`}>
                                                <h2> {franchiseur.companyName}</h2>
                                            </Item.Header>
                                            <Item.Description>
                                                <div>Code : <span style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }}>{franchiseur.agencyName}</span></div>
                                                <div>

                                                    <div>
                                                        Valeur Commission : <span style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }}>{franchiseur.commissionValue}</span>
                                                    </div>

                                                </div>
                                            </Item.Description>
                                        </Item.Content>
                                    </Item>
                                </Item.Group>
                            </Segment>
                            <Segment>
                                <Icon name='user' />{franchiseur.companyName} <span>{franchiseur.socialName}</span> &nbsp;
                                <Icon name='mail' />{franchiseur.invoiceEmails?.split(';').map(x => x + "  ")} &nbsp;
                                <Icon name='fax' />{franchiseur.invoiceFax} &nbsp;
                                <Icon name='mobile alternate' />{franchiseur.invoicePhone}
                            </Segment>
                            <Segment>
                                <Icon name='address card' />Adresse de facturation : {franchiseur.invoiceAddress1}, {franchiseur.invoicePostalCode}, {franchiseur.invoiceCity}
                            </Segment>
                            <Segment>
                                <Icon name='address card outline' />Adresse de livraison : {franchiseur.deliveryAddress1}, {franchiseur.deliveryPostalCode}, {franchiseur.deliveryCity}
                            </Segment>
                            <Segment>
                                <Icon name='payment' />Mode de paiement: {franchiseur.modePaiement}
                            </Segment>
                            <Segment clearing>
                                <div style={{ textAlign: 'center' }}>
                                    <Button
                                        content="Ajouter client"
                                        color="orange"
                                        size="small"
                                        as={Link}
                                        to={`/createClient/${franchiseur.id}`}
                                    />
                                    {/*<Button*/}
                                    {/*    content="Importer clients"*/}
                                    {/*    color="orange"*/}
                                    {/*    size="small"*/}
                                    {/*    as={Link}*/}
                                    {/*    to={`/addClient/${franchiseur.id}`}*/}
                                    {/*/>*/}
                                  
                                    <Button
                                        content="Accèder aux clients"
                                        color="orange"
                                        size="small"
                                        as={Link}
                                        to={`/clients/${franchiseur.id}`}
                                    />
                                    <Button
                                        name={franchiseur.id}
                                        content="Ajouter Commande"
                                        color="green"
                                        size="small"
                                        as={Link}
                                        onClick={(e) => {
                                            openModal(<AddCommandeFranchiseurModal franchiseur={franchiseur} />)
                                        }}
                                    />
                                    <Button
                                        as={Link}
                                        to={`/detailFranchiseur/${franchiseur.id}`}
                                        content='Détail'
                                        size="small"
                                        color='blue'
                                    />
                                </div>
                            </Segment>
                        </Segment.Group>
                    ) : (
                        <Segment.Group>
                            <Segment>
                                <Item.Group>
                                    <Item>
                                        <Item.Image
                                            size='small'
                                            src={franchiseur.uri}
                                            style={franchiseur.isActive ? null : stylesNonactif} />
                                        <Item.Content>
                                            <Item.Header as={Link} to={`/detailClient/${franchiseur.id}`}>
                                                {franchiseur.companyName}
                                            </Item.Header>
                                            <Item.Description>
                                                <div>Code : <span style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }}>{franchiseur.agencyName}</span></div>
                                                Type Client : <span style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }}>{franchiseur.companyType}</span>
                                            </Item.Description>
                                        </Item.Content>
                                    </Item>
                                </Item.Group>
                            </Segment>
                            <Segment>
                                <Icon name='user' />{franchiseur.companyName} <span>{franchiseur.socialName}</span> &nbsp;
                                <Icon name='mail' />{franchiseur.invoiceEmails?.split(';').map(x => x + "  ")} &nbsp;
                                <Icon name='fax' />{franchiseur.invoicePhone} &nbsp;
                                <Icon name='mobile alternate' />{franchiseur.invoiceFax}
                            </Segment>
                            <Segment>
                                <Icon name='address card' /> Adresse de facturation : {franchiseur.invoiceAddress1}, {franchiseur.invoicePostalCode}, {franchiseur.invoiceCity}
                            </Segment>
                            <Segment>
                                <Icon name='address card outline' /> Adresse de livraison : {franchiseur.deliveryAddress1}, {franchiseur.deliveryPostalCode}, {franchiseur.deliveryCity}
                            </Segment>
                            <Segment clearing>
                                <Button
                                    as={Link}
                                    to={`/detailClient/${franchiseur.id}`}
                                    floated='right'
                                    content='Détail'
                                    color='blue'
                                />
                                <Button
                                    name={franchiseur.id}
                                    content="Ajouter Commande"
                                    color="orange"
                                    floated='right'
                                    as={Link}
                                    onClick={(e) => {
                                        openModal(<AddCommandeClientModal client={franchiseur} />)
                                    }}
                                />
                            </Segment>
                        </Segment.Group>
                    )
            }
        </>
    );
}
export default FranchiseurListItem;


