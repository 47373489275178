import { action, computed, observable, runInAction } from "mobx";
import { toast } from "react-toastify";
import { bool } from "yup";
import agent from "../agent/agent";
import { Module, Action, Permission } from "../common/ModuleClaims/ModulePermissions";
import { IRole, ICheckBoxModel, IUserModel, IUserRoleModel, IPermissionsRoleModel } from "../models/IUsersRoles";
import { BaseStore } from "./baseStore";


export default class UsersRoleStore {

    _baseStore: BaseStore;

    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
    }

    @observable userModel: IUserModel | null = null;
    @observable users: IUserModel[] = [];
    @observable userRoleModel: IUserRoleModel | null = null;
    @observable loadingInitial = false;
    @observable loadingInitialRoles = false;
    @observable listRoles: ICheckBoxModel[] = [];
    @observable listAllRoles: IRole[] = [];
    @observable permissionRoleModel: IPermissionsRoleModel | null = null;
    @observable listeUsersByRole: string[] = [];
    @observable deletingRole: boolean = false;

    @action allUsers = async () => {
        this.loadingInitial = true;
        try {
            var returnedList = await agent.usersRolesAgent.listeUsers().then(r => r.data);
            runInAction(() => {
                this.users = returnedList;
                this.loadingInitial = false;
            });
        } catch (e) {
            console.log(e);
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }

    @action getManageRoles = async (id: string) => {
        this.userRoleModel = null;
        this.listRoles = [];
        try {
            var res = await agent.usersRolesAgent.listeManageRoles(id).then(r => r.data);
            runInAction(() => {
                this.userRoleModel = res;
                this.listRoles = res.roles;
            })
        } catch (e) {
            console.log(e);
        }
    }

    @action changeRoles = async (roleName: string) => {
        this.userRoleModel?.roles.map(role =>
            role.displayValue == roleName ? role.isSelected = !role.isSelected : role
            );
    }

    @action changePermissions = async (roleName: string) => {
        this.permissionRoleModel?.roleClaims.map(permission =>
            permission.displayValue == roleName ? permission.isSelected = !permission.isSelected : permission
        );
    }

    @action uncheckAll = async () => {
        this.permissionRoleModel?.roleClaims.map(permission =>
            permission.isSelected = false
            );
    }

    @action checkAll = async () => {
        this.permissionRoleModel?.roleClaims.map(permission =>
            permission.isSelected = true
        );
    }

    @action updateUserRole = async () => {
        console.log(this.userRoleModel?.userId);
        try {
            await agent.usersRolesAgent.updateRroles(this.userRoleModel!);
            runInAction(() => {
                this.users.map(user => {
                    console.log("***" + user.id)
                    if (user.id == this.userRoleModel?.userId) {
                        console.log("Condition Validé !!!!")
                        user.roles = this.userRoleModel.roles.filter(c => c.isSelected).map(s => s.displayValue);
                        console.log("result roles : " + user.roles)
                    }

                });
            });
        } catch (e) {
            console.log(e);
        }
    }

    @action loadRoles = async () => {
        this.loadingInitialRoles = true;
        try {
            var res = await agent.usersRolesAgent.listeRoles().then(r => r.data);
            runInAction(() => {
                this.listAllRoles = res;
                this.loadingInitialRoles = false;
            })
        } catch (e) {
            console.log(e);
            runInAction(() => {
                this.loadingInitialRoles = false;
            })
        }
    }

    @action createRole = async (roleName: string) => {
        try {
            await agent.usersRolesAgent.createRole(roleName);
            runInAction(() => {
                toast.success("Ajout éffectué avec succès");
            })
        } catch (e) {
            console.log(e);
            runInAction(() => {
                toast.error("Probleme lors de l'ajout");
            })
        }
    }

    @action loadPermissionsByRole = async (roleId: string) => {
        this.permissionRoleModel = null;
        try {
            var res = await agent.usersRolesAgent.getPermissions(roleId);
            runInAction(() => {
                this.permissionRoleModel = res;
            })
        } catch (e) {
            console.log(e);
        }
    }

    @action updateRolePermissions = async () => {
        console.log(this.permissionRoleModel?.roleId);
        try {
            await agent.usersRolesAgent.updatePermissions(this.permissionRoleModel!);
            runInAction(() => {
                this.loadPermissionsByRole(this.permissionRoleModel?.roleId!);
            });
        } catch (e) {
            console.log(e);
        }
    }

    @action getUsersByRole = async (role: string) => {
        
        this.listeUsersByRole = [];
        try {
            var res = await agent.usersRolesAgent.getUsersByRole(role);
            runInAction(() => {
                this.listeUsersByRole = res;
            });
        } catch (e) {
            console.log(e);
        }
    }

    @action deleteRole = async (role: string) => {
        this.deletingRole = true;
        try {
            var res = await agent.usersRolesAgent.deleteRole(role);
            runInAction(() => {
                this.listAllRoles = this.listAllRoles.filter(x => x.name != role);
                this.deletingRole = false;
                toast.success("Role supprimée avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Probléme de suppression!");
            runInAction(() => {
                this.deletingRole = false;
            })
        }
    }

     checkClaimByRole = async (module: string, action: string) => {
        
        var res ;
        let claim = Permission.concat(".", module).concat(".", action);
        console.log("the claim " + claim);;

        try {
             res = await agent.usersRolesAgent.checkClaimsByRole(claim);
        } catch (e) {
            console.log(e);
         }
         
         if (res == true) {
             return true;
         }
         else {
             return false;
         }
    }
}