import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { Button, Grid, Header, Tab } from "semantic-ui-react";
import FormGridColumn from "../../../app/common/form/FormGridColumn";
import { numberFormat } from "../../../app/common/options/IStyle";
import NotFound from "../../../app/layout/NoFound";
import { BaseStoreContext } from "../../../app/store/baseStore";
import EditForm from "./EditForm";

const FactureDescription = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { facture, editBtn, submitting, changeButtonEditState, editFactureFournisseur } = baseRepo.factureFournisseurStore;

    if (!facture) {
        return <NotFound />
    }
    else {
        return (
            <Tab.Pane >
                <Grid>
                    <Grid.Column width={16} style={{ paddingBottom: 20 }}>
                        <Header floated='left' icon='user' content={`Information Facture Fournisseur:`} />
                        <Button
                            onClick={() => { changeButtonEditState(!editBtn) }}
                            floated='right'
                            basic
                            content={editBtn ? 'Annuler' : 'Modifier'}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        {editBtn ? (
                            <EditForm editFactureFournisseur={editFactureFournisseur} PurchaseInvoice={facture} submitting={submitting} />
                        ) : (
                            <Grid className="ui stackable">
                                <Grid.Row style={{ padding: 0, margin: 0 }}>
                                    <FormGridColumn header="Client" content={facture.fournisseur ? (facture.fournisseur.codeFournisseur) : (facture.company.agencyName + "-" + facture.company.companyName)}
                                        root={facture!.fournisseur ? `/detailFournisseur/${facture.fournisseur.fournisseurId}` : `/detailFranchiseur/${facture.company.id}`} />
                                    <FormGridColumn header="N° Facture Fournisseur" content={facture!.numSupplierInvoice} />
                                    <FormGridColumn header="Date Facture" content={new Date(facture!.invoiceDate).toLocaleDateString('en-GB')} />
                                    <FormGridColumn header="Date de paiement" content={facture!.dueDate ? new Date(facture!.dueDate).toLocaleDateString('en-GB') : "non introduite"} />
                                    <FormGridColumn header="Créer Par" content={facture!.userCreator} />
                                    <FormGridColumn header="Valider Par" content={facture!.userValidator} />
                                    <FormGridColumn header="Montant HT" content={numberFormat.format(facture!.amountWithoutTaxes)} />
                                    <FormGridColumn header="Montant TTC" content={numberFormat.format(facture!.amountWithTaxes)} />
                                    <FormGridColumn header="Commentaires" content={facture!.comment} />
                                </Grid.Row>
                            </Grid>
                        )}

                    </Grid.Column>
                </Grid>
            </Tab.Pane >
        )
    }
}
export default observer(FactureDescription);