import React, { useState } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button, Grid, Input } from 'semantic-ui-react';
import TextInput from '../../../app/common/form/inputText';
import { observer } from 'mobx-react-lite';
import { history } from '../../../index';
import InputSelect from '../../../app/common/form/inputSelect';
import { ITListDeliveryService, ITListPaymentMode, ITListStatutDevis } from '../../../app/common/options/IAchat';
import { IDevis, IDeviCFList } from '../../../app/models/IDevis';

interface IProps {
    editDevis: (devis: Partial<IDevis>) => void;
    devis: IDevis;
    submitting: boolean;
}
const DevisEditform: React.FC<IProps> = ({ editDevis, devis, submitting }) => {
    const [date, setDate] = useState<any>(new Date(devis.quoteDate).toISOString().split('T')[0]);


    const handleFinalFormSubmit = (values: any) => {

        let newDevis = {
            ...values,
            quoteDate:date,
            //company: ITListClient.find(x => x.key == codeClient.toString())?.text.split(',')[1],
            //franchiseur: ITListFranchiseur.find(x => x.key == codeFranchiseur.toString())?.text.split(',')[1]
        };
        editDevis(newDevis);
    }
    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            initialValues={devis!}
            render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit} error>
                    <Grid columns={2} divided>
                        <Grid.Row>
                            <Grid.Column>
                                <h5>Mode de paiement</h5>
                                <Field name='paymentMode' component={InputSelect} options={ITListPaymentMode} value={devis!.paymentMode} />
                            </Grid.Column>
                            <Grid.Column>
                                <h5>Date devis</h5>
                                <Input
                                    type="date"
                                    name="orderDate"
                                    defaultValue={new Date(devis.quoteDate).toISOString().split('T')[0]}
                                    onChange={(e) => setDate(e.target.value)}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <h5>Transporteur</h5>
                                <Field name='deliveryService' component={InputSelect} options={ITListDeliveryService} value={devis!.deliveryService} />
                            </Grid.Column>
                            <Grid.Column>
                                <h5>Commentaire</h5>
                                <Field name='comment' component={TextInput} placeholder='Commentaire' value={devis!.comment} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <div style={{ marginTop: '20px' }}>
                        <Button.Group floated="right">
                            <Button disabled={submitting} onClick={() => history.push('/devisDashboard')} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </div>
                </Form>
            )}
        />
    );
};

export default observer(DevisEditform);