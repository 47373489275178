import { useContext, useEffect } from 'react';
import { Tab, Header, Button, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import NotFound from '../../../app/layout/NoFound';
import GestionCommercialEditForm from './GestionCommercialEditForm';
import FormGridColumn from '../../../app/common/form/FormGridColumn';
const GestionCommercialDescription = () => {
    const getType = (type: string) => {
        switch (type) {
            case 'BillByCompany':
                return 'Une seule facture';
            case 'BillByCm':
                return 'Commande';
            case 'BillByBl':
                return 'Bon de livraison';
            default:
                return '';
        }
    }
    const baseStore = useContext(BaseStoreContext);
    const { client, editClientGestionCommercial } = baseStore.clientStore;
    const { loadModePaiements } = baseStore.modePaiementStore;
    const { loadModeReglements } = baseStore.modeReglementStore;

    useEffect(() => {
        loadModePaiements();
        loadModeReglements();
        baseStore.clientStore.changeButtonState(false);
    }, [loadModePaiements, loadModeReglements])

    if (!client) {
        return <NotFound />
    }
    else {
        return (
            <Tab.Pane >
                <Grid>
                    <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                        <Header floated='left' icon='address card' content={`Gestion Commerciale`} />
                        <Button
                            onClick={() => { baseStore.clientStore.changeButtonState(!baseStore.clientStore.editClientMode) }}
                            floated='right'
                            basic
                            content={baseStore.clientStore.editClientMode ? 'Annuler' : 'Modifier'}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        {baseStore.clientStore.editClientMode ? (
                            <GestionCommercialEditForm editClient={editClientGestionCommercial} client={client!} />
                        ) : (
                            <Grid className="ui stackable">
                                <Grid.Row>
                                    <FormGridColumn icon="calendar times" header="Compte Collectif" content={client!.compteCollectif} />
                                    <FormGridColumn icon="calendar times" header="Compte Auxiliere" content={client!.compteAuxilliere} />
                                    <FormGridColumn icon="payment" header="Mode paiement" content={client!.modePaiement} />
                                    <FormGridColumn icon="handshake" header="Echéance" content={client!.modeReglement} />
                                    {/*<FormGridColumn icon="send" iconColor={client!.commissionType ? "green" : "red"} header="Type commission" content={client!.commissionType ? "Avec Commission" : "Sans Commission"} />*/}
                                    {/*<FormGridColumn icon="send" header="Valeur commission" content={client!.commissionValue} />*/}
                                        <FormGridColumn icon="file alternate" header="Facturer par" content={getType(client!.billingType)} />
                                        {client!.modePaiement &&
                                        client.hasRum &&
                                        < FormGridColumn icon="payment" header="RUM" content={client!.rum} />
                                    }
                                </Grid.Row>
                            </Grid>
                        )}
                    </Grid.Column>
                </Grid>
            </Tab.Pane>
        );
    }
}
export default observer(GestionCommercialDescription);