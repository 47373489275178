import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { Button, Grid, Tab } from "semantic-ui-react";
import FormGridColumn from "../../../app/common/form/FormGridColumn";
import NotFound from "../../../app/layout/NoFound";
import { BaseStoreContext } from "../../../app/store/baseStore";
import FactureEditForm from "./FactureEditForm";

interface UrlParams {
    id: string;
}


const FactureDescription = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { facture, editFacture, submitting } = baseRepo.factureStore;
    const { id } = useParams<UrlParams>();

    if (!facture) {
        return <NotFound />
    }
    else {
        return (
            <Tab.Pane >
                <Grid>
                    <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                        {facture!.invoiceStatus == 'Brouillon' &&
                            <Button
                                onClick={() => { baseRepo.factureStore.changeButtonState(!baseRepo.factureStore.editDFactureMode) }}
                                floated='right'
                                basic
                                content={baseRepo.factureStore.editDFactureMode ? 'Annuler' : 'Modifier'}
                                style={{ display: facture?.isClosed ? 'none' : 'block' }}

                            />
                        }
                    </Grid.Column>
                    <Grid.Column width={16}>
                        {
                            baseRepo.factureStore.editDFactureMode ? (
                                <FactureEditForm editFacture={editFacture} facture={facture!} submitting={submitting} />
                            ) : (
                                <Grid className="ui stackable">
                                    <Grid.Row style={{ padding: 0, margin: 0 }}>
                                        <FormGridColumn header="Client" content={facture!.companyName}
                                            root={facture!.typeClient == "Franchiseur" ? `/detailFranchiseur/${facture.idCompany}` : `/detailClient/${facture.idCompany}`} />
                                        <FormGridColumn header="Date Facture" content={new Date(facture!.invoiceDate).toLocaleDateString('en-GB')} />
                                        <FormGridColumn header="Date d'échéance" content={facture!.dueDate ? new Date(facture!.dueDate).toLocaleDateString('en-GB') : "non introduite"} />
                                        <FormGridColumn header="Montant HT" content={facture!.amountWithoutTaxes} />
                                        <FormGridColumn header="Montant TTC" content={facture!.amountWithTaxes} />
                                        <FormGridColumn header="Commentaires" content={facture!.comment} />
                                        <FormGridColumn header="Mode de paiement" content={facture!.paymentMode} />
                                            <FormGridColumn header="Délai de paiement" content={facture!.paymentDelay} />
                                            {((facture!.deliveryCountry.toUpperCase().startsWith("FR") && facture!.deliveryPostalCode.startsWith("97")) || !facture!.deliveryCountry.toUpperCase().startsWith("FR")) &&
                                                <Grid.Column width={16} style={{ padding: 0, margin: 0 }}>
                                                    <FormGridColumn header="Code général des impots" content={facture!.taxCode} />
                                                </Grid.Column>
                                            }
                                    </Grid.Row>
                                </Grid>
                            )}
                    </Grid.Column>
                </Grid>
            </Tab.Pane >
        )
    }
}
export default observer(FactureDescription);