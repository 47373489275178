import React, { useContext, useState } from 'react';
import { Form, Button, Segment, Grid } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { history } from '../../../index';
import { observer } from 'mobx-react-lite';
import AsyncSelect from 'react-select/async';

const AddCommandeContent: React.FC = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { create, submitting } = baseRepo.commandeStore;
    const [selectedValue, setSelectedValue] = useState(undefined);
    const [type, setType] = useState("");
    const [selectedLabel, setSelectedLabel] = useState(undefined);

    const handleChangeSelect = (value: any) => {
        setType(value.companyType)
        setSelectedValue(value.agencyName);
        let labelSelected: any = value.agencyName + '  ' + value.companyName;
        setSelectedLabel(labelSelected);
    }

    const handleFinalFormSubmit = (values: any) => {
        let newCommande = { agencyName: selectedValue };
        create(newCommande)
    }

    return (
        <Segment style={{ backgroundColor: 'rgb(255,255,255)', padding: '20px' }} >
            <FinalForm
                onSubmit={handleFinalFormSubmit}
                render={({ handleSubmit, invalid }) => (
                    <Form onSubmit={handleSubmit} error >
                        <Grid>
                            <Grid.Column width={10}>
                                <Form.Group>
                                    <div className="field" style={{ width: '100%', paddingLeft: "5%" }}>
                                        <h5>Franchiseur Ou Client</h5>
                                        <AsyncSelect
                                            name="form-field-name"
                                            noOptionsMessage={() => "Code introuvable"}
                                            cacheOptions
                                            defaultOptions
                                            placeholder={selectedLabel}
                                            value={selectedValue}
                                            getOptionLabel={e => e!.agencyName + '  ' + e!.companyName}
                                            loadOptions={baseRepo.clientStore.loadClientOptions}
                                            onChange={handleChangeSelect}
                                        />
                                    </div>
                                </Form.Group>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <Form.Group  >
                                    <Button.Group floated="left" style={{ marginTop: 15 }}>
                                        <Button disabled={submitting} onClick={() => history.push('/commandeDashboard')} type='button'>Annuler</Button>
                                        <Button.Or />
                                        <Button positive disabled={submitting || invalid || selectedValue == undefined} loading={submitting} type='submit'>Confirmer</Button>
                                    </Button.Group>
                                </Form.Group>
                            </Grid.Column>
                        </Grid>
                    </Form>
                )}
            />
        </Segment >
    );
}
export default observer(AddCommandeContent);
