import React, { useContext, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router-dom';
import Loading from '../../../app/layout/Loading';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import CommandeFournisseurHeader from './CommandeFournisseurHeader';
import CommandeFournisseurContent from './CommandeFournisseurContent';
import Breadcrumbs from '../../dashboard/breadcrumbs';

interface RouteParams {
    id: string,
}
interface IProps extends RouteComponentProps<RouteParams> { }
const CommandeFournisseurPage: React.FC<IProps> = ({ match }) => {
    const baseStore = useContext(BaseStoreContext);
    const { loadCommandeFournisseur, setActiveTab, loadingCommandeFournisseur, loadingOrderState, commandeFournisseur, setValid, setEnAttente } = baseStore.commandeFournisseurStore;
    useEffect(() => {
        setActiveTab('1');
        loadCommandeFournisseur(match.params.id);
    }, [])

    if (loadingCommandeFournisseur) return <Loading content="Chargement du commande ..." />
    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Achats", root: "" },
                    { key: 1, name: "Commande Fournisseurs", root: "/commandeFournisseurDashboard" },
                    { key: 2, name: "" + commandeFournisseur?.reference, root: `/detailCommande/${commandeFournisseur?.id}` },
                ]}
            />
        <Grid>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Grid style={{ marginTop: 2 }}>
                            <Grid.Column width={16}>
                                <CommandeFournisseurHeader commandeFournisseur={commandeFournisseur!} loading={loadingOrderState} valide={setValid} enAttente={setEnAttente} id={match.params.id}/>
                            <CommandeFournisseurContent setActiveTab={setActiveTab} />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column width={2}></Grid.Column>
            </Grid.Row>
        </Grid>
        </div>
    );
}

export default observer(CommandeFournisseurPage);