import { BaseStore } from "./baseStore";
import { observable, action, runInAction, computed, reaction } from 'mobx';
import agent from '../agent/agent';
import { toast } from "react-toastify";
import { history } from "../..";
import { IFactureFournisseur, IFactureFournisseurAdd, IFactureFournisseurLine } from "../models/IFactureFournisseur";
import { IListRelatedElements } from "../models/IFacture";
const LIMIT = 20;

export default class FactureFournisseurStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0;
                this.loadListesFactures();
            }
        )
        reaction(
            () => this.activeTab,
            activeTab => {
                if (activeTab != '')
                    return true;
            }
        )
    }

    @observable submitting = false;
    @observable loadingInitial = false;
    @observable looadingAchat = false;
    @observable loadingFacture = false;
    @observable loadingExport = false;
    @observable deletingArticle = false;
    @observable addingArticle = false;
    @observable loading = false;
    @observable editBtn = false;
    @observable facturesCount: number = 0;
    @observable facture: IFactureFournisseur | null = null;
    @observable factures: IFactureFournisseur[] = [];
    @observable invoiceLine: IFactureFournisseurLine | null = null;
    @observable relatedElementsList: IListRelatedElements[] = [];
    @observable page: number = 0;
    @observable totalHT: number = 0;
    @observable totalTTC: number = 0;
    @observable activeTab: string = "0";
    @observable progress: number = 50;
    @observable predicate = new Map();
    @observable generatePdfMode = false;
    @observable deletingOrder = false;

    @computed get axiosParams() {
        const params = new URLSearchParams();
        params.append('limit', String(LIMIT));
        params.append('offset', `${this.page ? this.page * LIMIT : 0}`);
        this.predicate.forEach((value, key) => {
            if (key === 'startDate') {
                params.append(key, value.toISOString())
            } if (key === 'searchMultiple') {
                var search = [...value][0];
                var codeArticle = [...value][1];
                var codeFranchiseur = [...value][2];
                var codeFournisseur = [...value][3];
                var dateCommande = [...value][4];
                var dateCommandeTo = [...value][5];
                var key = [...value][6];
                var tri = [...value][7];
                var searchNFournisseur = [...value][8];
                var orderRefs = [...value][9];
                params.append(search[0], search[1])
                params.append(codeArticle[0], codeArticle[1])
                params.append(codeFranchiseur[0], codeFranchiseur[1])
                params.append(codeFournisseur[0], codeFournisseur[1])
                params.append(dateCommande[0], dateCommande[1])
                params.append(dateCommandeTo[0], dateCommandeTo[1])
                params.append(key[0], key[1])
                params.append(tri[0], tri[1])
                params.append(searchNFournisseur[0], searchNFournisseur[1])
                params.append(orderRefs[0], orderRefs[1])
            } else {
                params.append(key, value)
            }
        })
        return params;
    }

    @computed get totalPages() {
        return Math.ceil(this.facturesCount / LIMIT);
    }
    @action changeButtonPdfState = async (newState: boolean) => {
        this.generatePdfMode = newState;
    }

    @action setPage = (page: number) => {
        this.page = page;
    }

    @action setPredicate = (predicate: string, value: any) => {
        this.factures = [];
        this.predicate.clear();
        if (predicate !== 'searchMultiple') {
            this.predicate.clear();
        }
        if (predicate !== 'all') {
            this.predicate.set(predicate, value);
        }
    }

    @action setActiveTab = async (activeIndex: string) => {
        runInAction(() => {
            this.activeTab = activeIndex;
        })
    }

    @action changeButtonEditState = async (newState: boolean) => {
        this.editBtn = newState;
    }

    @action create = async (facture: Partial<IFactureFournisseurAdd>) => {
        this.submitting = true;
        try {
            var id = await agent.factureFournisseurAgent.create(facture);
            runInAction(() => {
                this.submitting = false;
                history.push(`/detailFactureFournisseur/${id}`);
                toast.success("Facture ajoutée avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
        }
    }
    @action loadFacture = async (id: string) => {
        this.facture = null;
        this.loadingFacture = true;
        try {
            var facture = await agent.factureFournisseurAgent.details(id);
            runInAction(() => {
                this.facture = facture;
                this.loadingFacture = false;
            });
        } catch (e) {
            runInAction(() => {
                this.loadingFacture = false;
            });
            console.log(e);
        }
    }
    @action loadListesFactures = async () => {

        this.loadingInitial = true;
        try { 
            const factureEnveloppe = await agent.factureFournisseurAgent.list(this.axiosParams);
            const { invoices, invoicesCount, totalHT,totalTTC } = factureEnveloppe;

            runInAction(() => {
                invoices.forEach((facture) => {
                    if (this.page == 0) {
                        this.factures = invoices;
                    } else {
                        this.factures.push(facture);
                    }
                })
                this.facturesCount = invoicesCount;
                this.totalTTC = totalTTC;
                this.totalHT = totalHT;
                this.loadingInitial = false;
            });

        } catch (e) {
            runInAction(() => {
                this.loadingInitial = false;
            })
            console.log(e);
        }
    }
    @action brouillon = async (id: number) => {
        this.submitting = true;
        try {
            await agent.factureFournisseurAgent.brouillon(id);
            runInAction(() => {
                this.submitting = false;
                this.facture!.invoiceState = "Brouillon";
                toast.success("facture passe en Brouillon avec succées");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de mettre la facture en brouillon!");
            runInAction(() => {
                this.submitting = false;
            })
        }
    }

    @action valide = async (id: number) => {
        this.submitting = true;
        try {
            var ref = await agent.factureFournisseurAgent.valide(id);
            runInAction(() => {
                this.submitting = false;
                this.facture!.userValidator = this._baseStore.userStore.user!.username;
                this.facture!.invoiceState = "Valider";
                this.facture!.reference = ref + '';
                toast.success("Facture Valider avec succées");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de validation de la facture!");
            runInAction(() => {
                this.submitting = false;
            })
        }
    };

    @action facturerCommande = async (id: number) => {
        this.submitting = true;
        try {
            var  idInvoice= await agent.factureFournisseurAgent.facturerCommande(id);
            runInAction(() => {
                this.submitting = false;
                history.push(`/detailFactureFournisseur/${idInvoice}`);
                toast.success("Facture ajoutée avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème d'ajout de la facture!");
            runInAction(() => {
                this.submitting = false;
            })
        }
    };

    @action addArticle = async (invoiceLine: any) => {
        this.addingArticle = true;
        this.invoiceLine = invoiceLine;
        try {
            const id = await agent.factureFournisseurAgent.addInvoiceLine(invoiceLine, invoiceLine.purchaseInvoice);
            runInAction(() => {
                this.invoiceLine!.id = id;
                this.facture!.purchaseInvoiceLines.push(this.invoiceLine!);
                this.facture!.amountWithoutTaxes += invoiceLine.totalPrice;
                this.facture!.amountWithTaxes += invoiceLine.totalPriceWithTaxes;
                this.addingArticle = false;
                toast.success("ajout effectué avec succès");
            })
        }
        catch (e) {
            toast.error("Problème d'ajout produit!");
            runInAction(() => {
                this.addingArticle = false;
            })
        }
    }

    @action editInvoiceLine = async (id: number, values: Partial<IFactureFournisseurLine>) => {
        this.submitting = true;
        try {
            const invoice = await agent.factureFournisseurAgent.updateInvoiceLine(id, values);
            runInAction(() => {
                this.facture = invoice;
                this.submitting = false;
                toast.success("Ligne de facture modifié avec succès");
            })
        } catch (e) {
            toast.error("Problème de mise à jour article!");
            runInAction(() => {
                this.submitting = false;
            })
        }
    }

    @action deleteInvoiceArticle = async (invoiceLine: IFactureFournisseurLine) => {
        this.deletingArticle = true;
        try {
            await agent.factureFournisseurAgent.deleteInvoiceArticle(invoiceLine.id);
            runInAction(() => {
                this.facture!.purchaseInvoiceLines = this.facture!.purchaseInvoiceLines.filter(a => a.id !== invoiceLine.id);
                this.facture!.amountWithoutTaxes -= invoiceLine.totalPrice;
                this.facture!.amountWithTaxes -= invoiceLine.totalPriceWithTaxes;
                if (this.facture!.amountWithoutTaxes == 0)
                    this.facture!.invoiceState = "Brouillon";
                this.deletingArticle = false;
                toast.success("ligne de facture supprimée avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Probléme de suppression!");
            runInAction(() => {
                this.deletingArticle = false;
            })
        }
    }
    @action generatePDF = async (id: number) => {
        try {
            await agent.factureFournisseurAgent.generatePdf(id);
            toast.success("téléchargement avec succès")
        }
        catch (e) {
            console.log(e);
        }
    }

    @action getRelatedObject = async (id: number) => {
        this.submitting = true;
        this.relatedElementsList = [];
        try {
            var result = await agent.factureAgent.relatedObject(id, "FactureFournisseur");
            runInAction(() => {
                result.forEach((item: IListRelatedElements) => {
                    if (["Expedition", "Devis", "Preparation", "Facture-Avoir", "Facture","Facture_Acompte"].includes(item.typeObject) == false) {
                        let objt: IListRelatedElements = {
                            idObject: item.idObject,
                            typeObject: item.typeObject,
                            referenceObject: item.referenceObject,
                            date: item.date,
                            ht: item.ht,
                            ttc: item.ttc,
                            name: item.name
                        };
                            this.relatedElementsList.push(objt);
                    }
                })
                this.submitting = false;
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Aucun objet liés");
        }
    }

    @action deleteFactureFournisseur = async (id: number) => {
        this.deletingOrder = true;
        try {
            await agent.factureFournisseurAgent.deleteFactureFournisseur(id);
            runInAction(() => {
                this.deletingOrder = false;
                this.factures = this.factures.filter(a => a.id !== id);
                toast.success("suppression éffectuée avec succès");
            })
        } catch (e) {
            toast.error("Problème de suppresion !");
            runInAction(() => {
                this.deletingOrder = false;
            })
        }
    }

    @action editFactureFournisseur = async (PurchaseInvoice: Partial<IFactureFournisseur>) => {
        this.submitting = true;
        try {
            await agent.factureFournisseurAgent.update(PurchaseInvoice);
            runInAction(() => {
                this.facture = { ...this.facture!, ...PurchaseInvoice };
                this.submitting = false;
                this.changeButtonEditState(false);
                toast.success("Les données ont été modifiées");
            })
        } catch (e) {
            toast.error("Problème de modification !");
            runInAction(() => {
                this.submitting = false;
            })
        }
    }

    @action ExportExcel = async (obj: any) => {
        this.loadingExport = true;
        try {
            await agent.factureFournisseurAgent.exportExcel(obj);
            runInAction(() => {
                this.loadingExport = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingExport = false;
            })
            console.log(e);
        }
    }
    @action exportAchat = async (obj: any) => {
        this.looadingAchat = true;
        try {
            await agent.factureFournisseurAgent.exportAchat(obj);
            runInAction(() => {
                this.looadingAchat = false;
            })
            toast.success("Le fichier a été exporté avec succès!");
        } catch (e) {
            toast.error("Problème d'export fichier  !");
            runInAction(() => {
                this.looadingAchat = false;
            })
        }
    }

}