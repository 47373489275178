import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Menu, FormInput, Button, Grid, Form } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../app/store/baseStore';
import Select, { components } from 'react-select';
import { FaFileExcel, FaSearch, FaSort } from 'react-icons/fa';
import { Form as FinalForm } from 'react-final-form';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { MdCancel } from 'react-icons/md';

const customStyles = {
    option: (provided: any, state: { isSelected: any; }) => ({
        ...provided,
        color: state.isSelected ? 'orange' : 'grey',
        backgroundColor: state.isSelected ? "#eee" : "white",
        borderBottom: '1px solid #eee',
    }),
    valueContainer: (props: any) => ({
        ...props,
        paddingTop: 0,
        height: '39.201px',
        minHeight: '39.201px',
    }),
    control: (baseStyles: any, state: any) => ({
        ...baseStyles,
        height: '39.201px',
        minHeight: '39.201px',
    }),
};
const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <FaSort color='green' />
        </components.DropdownIndicator>
    );
};
const StockMultipleSearch = () => {
    const { RangePicker } = DatePicker;
    const baseStore = useContext(BaseStoreContext);
    const { setPredicate, predicate, Export, loadingExport } = baseStore.mouvementStockStore;
    const { loadArticleByCode, article } = baseStore.articleStore;
    const { loadEntrepots, ITAllListEntrepot } = baseStore.entrepotStore;
    const [searchEntrepot, setSearchEntrepot] = useState<any>(0);
    const [selectedEntrepot, setSelectedEntrepot] = useState<any>('Entrepot');
    const [searchArticle, setSearchArticle] = useState<any>('');
    const [searchDateCreation, setSearchDateCreation] = useState<any>('');
    const [searchDateCreationTo, setSearchDateCreationTo] = useState<any>('');
    const [selectedSort, setSelectedSort] = useState<any>('');
    const [selectedLabelTri, setSelectedTri] = useState<any>('Trier...');
    useEffect(() => {
        loadEntrepots();
        if (predicate.get('search') != null)
            loadArticleByCode(predicate.get('search')._data.get("article").value)
    }, [])
   
    const optionsTri = [
        { key: 'DateUp', text: 'Plus recent', value: 'DateUp' },
        { key: 'DateDown', text: 'Plus ancien', value: 'DateDown' },
        ,
    ];
    const rangePresets: {
        label: string;
        value: [Dayjs, Dayjs];
    }[] = [
            { label: '7 derniers jours', value: [dayjs().add(-7, 'd'), dayjs()] },
            { label: '14 derniers jours', value: [dayjs().add(-14, 'd'), dayjs()] },
            { label: '30 derniers jours', value: [dayjs().add(-30, 'd'), dayjs()] },
            { label: '90 derniers jours', value: [dayjs().add(-90, 'd'), dayjs()] },
        ];
    const handleInputChangeEntrepot = (value: any) => {
        setSearchEntrepot(value.value);
        setSelectedEntrepot(value.text);
    }
    const handleInputChangeArticle = (event: any) => {
        setSearchArticle(event.target.value)
    }
    const onRangeChange = (dates: null | (Dayjs | null)[]) => {
        if (dates) {
            setSearchDateCreation(dates[0]?.toISOString());
            setSearchDateCreationTo(dates[1]?.toISOString());
        }
    };
    const handleChangeTri = (value: any) => {
        setSelectedSort(value.value);
        let labelSelected: any = value.text;
        setSelectedTri(labelSelected);
    }
    const handleSearch = (event: any) => {
        if (event == 'clique') {
            let obj: any = {
                entrepots: searchEntrepot,
                article: searchArticle,
                dateCreation: searchDateCreation,
                dateCreationTo: searchDateCreationTo,
                tri: selectedSort,
            }; Export(obj);
        }
        else if (event == 'clear') {
            setSearchEntrepot(0)
            setSearchArticle("")
            setSelectedSort(null)
            setSearchDateCreation('')
            setSearchDateCreationTo('')
            setSelectedTri('Trier ...')
            setSelectedEntrepot('Entrepot')
            setPredicate('all', 'true')
        }
        else if (event == 'submit') {
            var predicate = new Map()
            predicate.set("entrepots", searchEntrepot);
            predicate.set("article", searchArticle);
            predicate.set("dateCreation", searchDateCreation);
            predicate.set("dateCreationTo", searchDateCreationTo);
            predicate.set("tri", selectedSort);
            if (searchEntrepot.toString().length > 0 || searchArticle.length > 0 || searchDateCreation.length > 2 || searchDateCreationTo.length > 2 || selectedSort.lenght >2)
                setPredicate('searchMultiple', predicate)
            if (searchEntrepot.length === 0 && searchArticle.length === 0 && searchDateCreation.length === 0 && searchDateCreationTo.length === 0 && selectedSort.lenght ===0)
                setPredicate('all', 'true')
        }
    }

    return (
        <FinalForm
            onSubmit={handleSearch}
            render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit} error style={{ width: "100%", marginTop:6, fontSize: 14 }}>
                    <Grid>
                        <Grid.Row columns={6} style={{ padding: 0, margin: 0 }}>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <FormInput
                                    icon='search'
                                    type="text"
                                    placeholder="Article"
                                    name="article"
                                    style={{ height: '100%' }}
                                    value={predicate.get('search') == null ? searchArticle : article?.label}
                                    onChange={handleInputChangeArticle}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <RangePicker
                                    presets={rangePresets}
                                    onChange={onRangeChange}
                                    format={'DD/MM/YYYY'}
                                    placeholder={["Début", "Fin"]}
                                    style={{ width: '100%', height: '39.201px' }}
                                />

                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <Select
                                    isSearchable={false}
                                    components={{ DropdownIndicator }}
                                    styles={customStyles}
                                    options={ITAllListEntrepot}
                                    value={searchEntrepot}
                                    getOptionLabel={e => e.text}
                                    onChange={(e) => handleInputChangeEntrepot(e)}
                                    placeholder={selectedEntrepot} />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0, display: 'flex' }}>
                                <Select
                                    isSearchable={false}
                                    components={{ DropdownIndicator }}
                                    styles={customStyles}
                                    options={optionsTri}
                                    value={selectedSort}
                                    getOptionLabel={e => e.text}
                                    onChange={(e) => handleChangeTri(e)}
                                    placeholder={selectedLabelTri} />
                                <Button style={{ padding: 5, margin: "0 0 0 10px", color: 'blue', background: "none" }} type="submit" onClick={(e: any) => handleSearch('submit')}>
                                    <FaSearch  />
                                </Button>
                                <Button style={{ float: "right", padding: 5, margin: 0, color: 'green', background: "none" }} >
                                    <MdCancel onClick={() => handleSearch('clear')} />
                                </Button>
                            </Grid.Column >
                            <Grid.Column >
                            </Grid.Column >
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                        <Button
                            size="tiny"
                            loading={loadingExport}
                            style={{ height: 32 }}
                            floated='right'
                            content="Export Excel"
                            className="andi-btn filled success"
                            icon={(<FaFileExcel style={{ marginRight: "10px" }} />)}
                            onClick={(e: any) => handleSearch('clique')}

                        />
                            </Grid.Column >
                        </Grid.Row>
                    </Grid>
                   
                </Form >
            )}
        />
    );
};

export default observer(StockMultipleSearch);
