import React, { useContext, useEffect, useState } from 'react';
import { GridColumn, Grid, Loader, Button, Label } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../app/store/baseStore';
import Breadcrumbs from '../dashboard/breadcrumbs';
import ListSoldes from './ListSoldes';
import SoldesFiltre from './SoldesFiltre';
import ListItemPlaceholder from '../../app/common/form/ListItemPlaceholder';
import InfiniteScroll from 'react-infinite-scroller';
import { numberFormat } from '../../app/common/options/IStyle';



const SoldesDashboard: React.FC = () => {

    const baseStore = useContext(BaseStoreContext);
    const { loadSoldes, loadingSoldes, setPageSolde, pageSolde, totalPagesSolde, totalSolde, totalCredit, totalDebit } = baseStore.clientStore;
    const [loadingNext, setLoadingNext] = useState(false);

    const handleGetNext = () => {
        setLoadingNext(true);
        setPageSolde(pageSolde + 1);
        loadSoldes().then(() => setLoadingNext(false));
    }

    useEffect(() => {
        loadSoldes();
    }, []);

    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Soldes", root: "" },
                    { key: 1, name: "Soldes", root: "/soldes" },
                ]}
            />
            <Grid>

                <Grid.Row style={{ padding: 0, margin: "10px 0 0 10px" }}>
                    <SoldesFiltre />
                </Grid.Row>
                <GridColumn width={16} style={{ marginTop: 2 }}>
                    <Label>Total solde : {numberFormat.format(totalSolde)}</Label>
                    <Label>Total débit : {numberFormat.format(totalDebit)}</Label>
                    <Label>Total crédit : {numberFormat.format(totalCredit)}</Label>
                        {loadingSoldes && pageSolde === 0 ? <ListItemPlaceholder /> : (
                            <InfiniteScroll
                                pageStart={0}
                                loadMore={handleGetNext}
                                hasMore={!loadingNext && pageSolde + 1 < totalPagesSolde}
                                initialLoad={false}
                            >
                                < ListSoldes />
                            </InfiniteScroll>
                        )}
                </GridColumn>
                <Grid.Column width={16} style={{ margin: 20 }}>
                    <Loader active={loadingNext} />
                </Grid.Column>
            </Grid>
        </div>
    );
}
export default observer(SoldesDashboard);