import React, { useContext, useState } from 'react';
import { Table, Header, Label, Button} from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { Link } from 'react-router-dom';
import NoResult from '../../commande/commandeDashboard/ErrorNoResult';
import { IOrderSupplier } from '../../../app/models/ICommandeFournisseur';
import DeleteCommandeFournisseurModal from './DeleteCommandeFournisseurModal';

export const getStatus = (status: string) => {
    switch (status) {
        case 'AttenteValidation':
            return 'Attente de validation';
        case 'AttenteReception':
            return 'Attente de réception';
        case 'ReceptionnePartielle':
            return 'réception partielle';
        case 'Receptionnee':
            return 'Réceptionnée';
        case 'Facturee':
            return 'Facturée';
        case 'Annulee':
            return 'Annulee';
        case 'Commandee':
            return 'Commandée';
        default:
            return '';
    }
}

const numberFormat = new Intl.NumberFormat('FR-fr', {
    style: 'currency',
    currency: 'EUR',
    useGrouping: true,
});

const ListCommandeFournisseur: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { commandesFournisseur, deletingOrder } = baseStore.commandeFournisseurStore;
    const { openModal } = baseStore.modalStore;
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    if (commandesFournisseur.length < 1) return < NoResult name="Commandes Fournisseur" />
    
    else
        return (
            <>
                <Table compact='very' style={{ marginTop: '0em' }} >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Numéro CF</Table.HeaderCell>
                            <Table.HeaderCell>Fournisseur</Table.HeaderCell>
                            <Table.HeaderCell>Ref Interne</Table.HeaderCell>
                            <Table.HeaderCell>Ref Fournisseur</Table.HeaderCell>
                            <Table.HeaderCell>Date Commande</Table.HeaderCell>
                            <Table.HeaderCell>Date Livraison</Table.HeaderCell>
                            <Table.HeaderCell>Montant HT</Table.HeaderCell>
                            <Table.HeaderCell>Montant TTC</Table.HeaderCell>
                            <Table.HeaderCell>Circuit appro</Table.HeaderCell>
                            <Table.HeaderCell>Réseau</Table.HeaderCell>
                            <Table.HeaderCell>Etat</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {commandesFournisseur && commandesFournisseur.map((commandeFournisseur: IOrderSupplier) => (
                            <Table.Row key={commandeFournisseur.id}>
                                <Table.Cell>
                                    <Header size='huge'><Label style={{}} as={Link} to={`/detailCommandeFournisseur/${commandeFournisseur.id}`}>{commandeFournisseur.reference}</Label></Header>
                                </Table.Cell>
                                <Table.Cell>{commandeFournisseur.fabricant}</Table.Cell>
                                <Table.Cell>{commandeFournisseur.internalRef}</Table.Cell>
                                <Table.Cell>{commandeFournisseur.numSupplierOrder}</Table.Cell>
                                <Table.Cell>{new Date(commandeFournisseur.orderDate).toLocaleDateString('en-GB')}</Table.Cell>
                                <Table.Cell>{commandeFournisseur.orderDeliveries.length > 0 ?
                                    new Date(commandeFournisseur.orderDeliveries.sort((a, b) => new Date(a.orderDate).getTime() - new Date(b.orderDate).getTime())[0].orderDate).toLocaleDateString('en-GB') : ""}</Table.Cell>
                                <Table.Cell>{numberFormat.format(commandeFournisseur.amountWithoutTaxes)}</Table.Cell>
                                <Table.Cell>{numberFormat.format(commandeFournisseur.amountWithTaxes)}</Table.Cell>
                                <Table.Cell>{commandeFournisseur.modeAppro}</Table.Cell>
                                <Table.Cell>{commandeFournisseur.network}</Table.Cell>
                                <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }} >{getStatus(commandeFournisseur.orderFournisseurState)}</Table.Cell>
                                <Table.Cell>
                                    {commandeFournisseur.orderFournisseurState === "AttenteValidation"  ? (
                                        <Button.Group fluid widths={3}>
                                            <Button
                                                style={{ marginRight: '3%' }}
                                                name={commandeFournisseur.id}
                                                basic
                                                color='red'
                                                size="small"
                                                icon='trash'
                                                onClick={(e) => {
                                                    openModal(<DeleteCommandeFournisseurModal commande={commandeFournisseur} />)
                                                    setDeleteTarget(e.currentTarget.name)
                                                }}
                                                loading={deletingOrder && deleteTarget === commandeFournisseur.id.toString()}
                                            />
                                        </Button.Group>
                                    ) : (<></>
                                    )}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </>
        );
}

export default observer(ListCommandeFournisseur);