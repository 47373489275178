import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import NoResult from '../../commande/commandeDashboard/ErrorNoResult';
import { Button, Dropdown, Grid, Header, Icon, Label, Input, Table, Form } from 'semantic-ui-react';
import { format } from 'date-fns'
import { Modal } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import { ISpecialOffer } from '../../../app/models/ISpecialOffer';
import DeleteOfferModal from '../../franchiseur/DetailFranchiseur/DeleteOfferModal';
import Select from 'react-select/dist/declarations/src/Select';
import OfferDetailModal from '../../franchiseur/DetailFranchiseur/OfferDetailModal';
import { string } from 'yup';
import TextInput from '../../../app/common/form/inputText';
import { Form as FinalForm, Field } from 'react-final-form';
import { combineValidators, composeValidators, isRequired } from 'revalidate';
import ErrorMessage from '../../../app/common/form/ErrorMessage';



const validate = combineValidators({
    minimumQuantity: composeValidators(
        isRequired,
    )({
        message: 'Veuillez enter la quantité'
    }),
    unitPrice: composeValidators(
        isRequired,
    )({
        message: 'Veuillez enter le prix'
    }),

});


const ListeAchatGroupees: React.FC = () => {

    const baseStore = useContext(BaseStoreContext);
    const { listSpecialOffers, updateDateFinSpecialOffre, loadSpecialOffers, addSpecialPrice, specialOffer, setSpecialOffer } = baseStore.specialOfferStore;
    const { openModal } = baseStore.modalStore;
    // Details Modal
    const [modalDetailsOpen, setModalDetailsOpen] = useState(false);
    const [modalDateOpen, setModalDateOpen] = useState(false);
    const [modalPalierOpen, setModalPalierOpen] = useState(false);
    const [endDate, setEndDate] = useState(new Date());
    const [chosenDate, setChosenDate] = useState('');
    const [idOffre, setIdOffre] = useState();


    const handleChangeDateFin = (e: any) => {
        let selecteDate = e.target.value;
        let today = new Date();
        let stringtoday = formattedDate(today);
        setChosenDate(selecteDate);

        if (selecteDate <= stringtoday) {
            alert("date fin doit etre superieur ou égal a la date d'aujourd'hui ");
            let newdate = new Date(new Date().setDate(new Date(stringtoday).getDate() + 3));
            let x = formattedDate(newdate);
            setChosenDate(x);
        }

    }

    const formattedDate = (date: Date) => {
        var year = date.toLocaleString("default", { year: "numeric" });
        var month = date.toLocaleString("default", { month: "2-digit" });
        var day = date.toLocaleString("default", { day: "2-digit" });
        console.log("year :" + year);
        console.log("month :" + month);
        console.log("day :" + day);
        // Generate yyyy-mm-dd date string
        var formattedDate = year + "-" + month + "-" + day;
        return formattedDate;
    }

    const handleUpdateDateSubmit = () => {
        console.log("chosen date in component : " + chosenDate);
        updateDateFinSpecialOffre(idOffre!, chosenDate).then(() => {
            setModalDateOpen(false);
            loadSpecialOffers();
        });
    }

    //validation to do in this function
    const checkQuantite = (value: any) => {

        let maxValue = Math.max.apply(null, specialOffer!.specialPrices.map(function (o) { return o.minimumQuantity }));
        if (value <= maxValue) {
            return `la quantitée doit étre superieur à ${maxValue}`;
        } else
            return undefined;
    }

    const checkPrix = (value: any) => {

        let minValue = Math.min.apply(null, specialOffer!.specialPrices.map(function (o) { return o.unitPrice }));
        if (value >= minValue) {
            return `le prix doit étre inférieur à ${minValue}`;
        } else
            return undefined;
    }

    const handleFinalFormSubmit = (values: any) => {
        let newPrice = {
            ...values,
            specialOfferId: specialOffer!.id
        };
        console.log(newPrice);
        addSpecialPrice(newPrice).then(() => setModalPalierOpen(false));
    }


    if (listSpecialOffers.length < 1) return < NoResult name="Achat Groupées" />
    else
        return (
            <>
                <Table loading={false} celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Promotion Code</Table.HeaderCell>
                            <Table.HeaderCell>Réseaux</Table.HeaderCell>
                            <Table.HeaderCell>Produit</Table.HeaderCell>
                            <Table.HeaderCell>Date debut</Table.HeaderCell>
                            <Table.HeaderCell>Date fin</Table.HeaderCell>
                            <Table.HeaderCell>Quantite</Table.HeaderCell>
                            <Table.HeaderCell>Details</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center'>X</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body >
                        {listSpecialOffers.length > 0 && listSpecialOffers.map((offer: any) => (
                            <Table.Row key={offer.id}>
                                <Table.Cell>
                                    <Header size='huge'>
                                        <Label style={{ marginLeft: '2em' }} className={offer.isClosed ? "disabled" : ""} as={Link} to={`#`}>
                                            {offer.promotionCode}
                                        </Label>

                                    </Header>
                                </Table.Cell>
                                <Table.Cell className={offer.isClosed ? "disabled" : ""}>{offer.reseaux}</Table.Cell>
                                <Table.Cell className={offer.isClosed ? "disabled" : ""}>{offer.productLabel}</Table.Cell>
                                <Table.Cell className={offer.isClosed ? "disabled" : ""}>{new Date(offer.startDate).toLocaleDateString('en-GB')}</Table.Cell>
                                <Table.Cell className={offer.isClosed ? "disabled" : ""}>
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        {new Date(offer.endDate).toLocaleDateString('en-GB')}
                                        &nbsp;
                                        {!offer.isClosed &&
                                            <Button
                                                onClick={() => {
                                                    setModalDateOpen(true);
                                                    setEndDate(offer.endDate);
                                                    setIdOffre(offer.id);
                                                    setChosenDate(offer.endDate.toString());
                                                }}
                                                basic
                                                color="green"
                                                icon='edit outline'
                                                style={{ borderRadius: '100%', height: 30, width: 30, fontSize: 10 }}
                                            />
                                        }
                                    </span>

                                </Table.Cell>
                                <Table.Cell className={offer.isClosed ? "disabled" : ""}>{offer.currentOrderedQuantity}</Table.Cell>
                                <Table.Cell textAlign='center'>
                                    <Button
                                        onClick={(e) => {
                                            setModalDetailsOpen(true);
                                            setSpecialOffer(offer);
                                        }}
                                        basic
                                        color="blue"
                                        icon='info'
                                        style={{ borderRadius: '100%', height: 30, width: 30, fontSize: 10 }}
                                    />


                                </Table.Cell>
                                
                                    <Table.Cell textAlign='center'>
                                       {!offer.isClosed && <Button
                                            onClick={(e) => {
                                                openModal(<DeleteOfferModal OfferId={offer.id} />);
                                            }}
                                            basic
                                            style={{ display: !offer.isClosed ? 'block' : 'none', fontSize: "10px", borderRadius: '100%' }}
                                            color='red'
                                            icon='trash'
                                        /> }
                                    </Table.Cell>
                               
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>

                <Modal
                    open={modalDetailsOpen}
                    size='small'
                    closeOnEscape={true}
                    closeOnRootNodeClick={true}
                >
                    <Modal.Header icon='add' style={{ marginLeft: 20 }} >
                        Details de l'offre {specialOffer?.promotionCode}
                    </Modal.Header>

                    <Modal.Content scrolling>
                        <OfferDetailModal offer={specialOffer!} />
                    </Modal.Content>

                    <Modal.Actions>
                        <div style={{ margin: 15, display: 'inline-block' }}>
                            <Button.Group floated="right">
                                <Button onClick={() => setModalDetailsOpen(false)} floated='right' type='button'>Retour</Button>
                                <Button.Or />
                                <Button
                                    onClick={() => setModalPalierOpen(true)}
                                    positive
                                    floated='right'
                                    type='submit'
                                >
                                    Ajouter Palier
                                </Button>
                            </Button.Group>
                        </div>
                    </Modal.Actions>
                </Modal>


                <Modal
                    open={modalDateOpen}
                    size='mini'
                    closeOnEscape={true}
                    closeOnRootNodeClick={true}
                >
                    <Modal.Header icon='add' style={{ marginLeft: 20 }} >
                        Modifier la date {new Date(endDate).toLocaleDateString('en-GB')}
                    </Modal.Header>

                    <Modal.Content>
                        <Grid>
                            <h5>Date fin </h5>
                            <input value={chosenDate} placeholder=' Date fin' type="date" onChange={(e) => handleChangeDateFin(e)} />
                        </Grid>
                    </Modal.Content>

                    <Modal.Actions>
                        <div style={{ margin: 15, display: 'inline-block' }}>

                            <Button.Group floated="right">
                                <Button onClick={() => setModalDateOpen(false)} floated='right' type='button'>Annuler</Button>
                                <Button.Or />
                                <Button
                                    onClick={() => { handleUpdateDateSubmit(); }}
                                    positive
                                    floated='right'
                                    type='submit'
                                >
                                    Confirmer
                                </Button>
                            </Button.Group>

                        </div>
                    </Modal.Actions>
                </Modal>

                <Modal
                    open={modalPalierOpen}
                    size='mini'
                    closeOnEscape={true}
                    closeOnRootNodeClick={true}
                >
                    <Modal.Header icon='add' style={{ marginLeft: 20 }} >
                        Ajouter un nouveau Palier pour l'offre {specialOffer?.promotionCode}
                    </Modal.Header>

                    <Modal.Content>
                        <FinalForm
                            onSubmit={handleFinalFormSubmit}
                            validate={validate}
                            render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Grid columns={2}>
                                        <Grid.Row style={{padding: 0 }}>
                                            <Grid.Column style={{ display: "flex", margin: 0, width: '100%'}}>
                                                <h5 style={{ width: '50%' }}>Quantité </h5>
                                                {/* <Input style={{ width: '50%' }} value={first} type='number' placeholder='quantité'  onChange={(e) => checkQuantite(e)} />*/}
                                                <Field fluid name='minimumQuantity' component={TextInput} type='number' placeholder='Quantité' validate={checkQuantite}  />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row style={{ padding: 0 }}>
                                            <Grid.Column style={{ display: "flex", margin: 0, width:'100%' }}>
                                                <h5 style={{ width:'50%'}}>Prix </h5>
                                                {/*<Input style={{ width: '50%' }} value={last} type='number' placeholder='prix' onChange={(e) => handleChangeDateFin(e)} />*/}
                                                <Field fluid name='unitPrice' component={TextInput} type='number' placeholder='prix' validate={checkPrix} />
                                            </Grid.Column>
                                        </Grid.Row>
                                        {submitError && !dirtySinceLastSubmit && (
                                            <ErrorMessage
                                                error={submitError}
                                                text=' invalide'
                                            />
                                        )}
                                    </Grid>
                                    <div style={{ margin: 15, display: 'inline-block' }}>

                                        <Button.Group floated="right">
                                            <Button onClick={() => setModalPalierOpen(false)} floated='right' type='button'>Annuler</Button>
                                            <Button.Or />
                                            <Button
                                                disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                                                loading={submitting}
                                                positive
                                                floated='right'
                                                type='submit'
                                            >
                                                Confirmer
                                            </Button>
                                        </Button.Group>

                                    </div>
                                 </Form>
                         )} />
                    </Modal.Content>
                </Modal>

            </>
        );
}
export default observer(ListeAchatGroupees);