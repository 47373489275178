import React from 'react'
import { useContext } from 'react';
import { useEffect } from 'react';
import { Tab } from 'semantic-ui-react';
import ObjetLierDevis from './ObjetLierDevis';
import { BaseStoreContext } from '../../../app/store/baseStore';
import DevisAdresses from './DevisAdresses';
import DevisArticle from './DevisArticle';
import DevisClient from './DevisClient';
import DevisDescription from './DevisDescription';
import DevisRemiseInformation from './DevisRemiseInformation';
import { observer } from 'mobx-react-lite';


const panes = [
    { menuItem: 'Général', render: () => <DevisDescription /> },
    { menuItem: 'Article(s)', render: () => <DevisArticle /> },
    { menuItem: 'Informations', render: () => <DevisClient /> },
    { menuItem: 'Adresses', render: () => <DevisAdresses /> },
    //{ menuItem: 'Ajouter une remise globale', render: () => <DevisRemiseInformation /> },
    { menuItem: 'Objets Liés', render: () => <ObjetLierDevis /> },
]
interface IProps {
    setActiveTab: (activeIndex: any) => void;
}
const DevisContent: React.FC<IProps> = ({ setActiveTab }) => {
    const baseStore = useContext(BaseStoreContext);
    useEffect(() => {
        baseStore.devisStore.changeDuplicationState(false);
    })
    return (
        <Tab
            menu={{ fluid: true, vertical: true }}
            menuPosition='right'
            activeIndex={baseStore.devisStore.activeTab}
            panes={panes}
            onTabChange={(e, data) => setActiveTab(data.activeIndex)}
        />
    )
}

export default observer(DevisContent);