import React, { useContext,useEffect } from 'react';
import CommandeHeader from './CommandeHeader';
import CommandeContent from './CommandeContent';
import { Grid } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import Breadcrumbs from '../../dashboard/breadcrumbs';

interface RouteParams {
    id: string,
}

interface IProps extends RouteComponentProps<RouteParams> { }
const CommandePage: React.FC<IProps> = ({ match }) => {
    const baseStore = useContext(BaseStoreContext);
    const { loadEntrepotByArticleModeApproByCommande } = baseStore.entrepotStore;
    const { setActiveTab, deletingCommandeArticle, isValide, enAttente,commande } = baseStore.commandeStore;

    useEffect(() => {
        setActiveTab('1');
        loadEntrepotByArticleModeApproByCommande(parseInt(match.params.id));
    }, [match])
    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Vente", root: "" },
                    { key: 1, name: "Commande", root: "/commandeDashboard" },
                    { key: 2, name: "" + commande?.reference, root: `/detailCommande/${match.params.id}` },
                ]}
            />
        {/*    <Link to={`/commandeDashboard`}>Retour vers liste</Link>*/}
        <Grid>
                <Grid.Row style={{paddingTop:0}}>
                    <Grid.Column width={16} style={{ paddingTop: 0 }}>
                    <Grid>
                        <Grid.Column width={16}>
                                <CommandeHeader id={match.params.id} loading={deletingCommandeArticle} enAttente={enAttente} valide={isValide}/>
                                <CommandeContent setActiveTab={setActiveTab} orderId={parseInt(match.params.id)} />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column width={2}></Grid.Column>
            </Grid.Row>
        </Grid>
        </div>
    );
}

export default observer(CommandePage);