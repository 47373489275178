import React, { useContext, useEffect } from 'react';
import { Table, Header, Label, Button, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { Link, useParams } from 'react-router-dom';
import _ from 'lodash';
import NoResult from '../../commande/commandeDashboard/ErrorNoResult';
import { IOrderSupplier } from '../../../app/models/ICommandeFournisseur';
import { getStatus } from '../../commandeFournisseur/commandeFournisseurDashboard/ListCommandeFournisseur';

const numberFormat = new Intl.NumberFormat('FR-fr', {
    style: 'currency',
    currency: 'EUR',
    useGrouping: true,
});


interface UrlParams {
    id: string,
}

const CommandeFournisseur: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { loadSupplierOrders, supplierOrders, loadingCommandeFournisseur } = baseStore.commandeFournisseurStore;
    const { id } = useParams<UrlParams>();

    useEffect(() => {
        loadSupplierOrders(parseInt(id));
    }, []);



    return (
        <>
            {loadingCommandeFournisseur ?
                <div style={{ marginTop: 50 }} className="ui segment">
                    <div className="ui active inverted dimmer">
                        <div className="ui medium text loader">Chargement ...</div>
                    </div>
                    <p></p>
                    <p></p>
                </div>
                :
                (
                    supplierOrders?.length != 0 ?
                        (<div style={{ marginTop: '0em', overflowY: 'auto', scrollbarWidth: 'thin', height: 300 }}>
                            <Table compact='very' style={{ marginTop: '0em' }} textAlign='center' >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Référence</Table.HeaderCell>
                                        <Table.HeaderCell>Montant HT</Table.HeaderCell>
                                        <Table.HeaderCell>Montant TTC</Table.HeaderCell>
                                        <Table.HeaderCell>Ref Fournisseur</Table.HeaderCell>
                                        <Table.HeaderCell>Date</Table.HeaderCell>
                                        <Table.HeaderCell>Etat</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {supplierOrders && supplierOrders.map((commande: IOrderSupplier, key) => (
                                        <>
                                            <Table.Row key={commande.id} >
                                                <Table.Cell>
                                                    <Header size='small'><Label style={{ marginLeft: '1px', backgroundColor: 'unset', display: 'ruby' }} as={Link} to={`/detailCommande/${commande.id}`}>{commande!.reference}</Label></Header>
                                                </Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithoutTaxes)}</Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithTaxes)}</Table.Cell>
                                                <Table.Cell>{commande.numSupplierOrder}</Table.Cell>
                                                <Table.Cell>{new Date(commande.orderDate).toLocaleDateString('en-GB')}</Table.Cell>
                                                <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }} >{getStatus(commande.orderFournisseurState)}</Table.Cell>
                                            </Table.Row>
                                        </>
                                    ))}
                                    {supplierOrders && supplierOrders.map((commande: IOrderSupplier, key) => (
                                        <>
                                            <Table.Row key={commande.id} >
                                                <Table.Cell>
                                                    <Header size='small'><Label style={{ marginLeft: '1px', backgroundColor: 'unset', display: 'ruby' }} as={Link} to={`/detailCommande/${commande.id}`}>{commande!.reference}</Label></Header>
                                                </Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithoutTaxes)}</Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithTaxes)}</Table.Cell>
                                                <Table.Cell>{commande.numSupplierOrder}</Table.Cell>
                                                <Table.Cell>{new Date(commande.orderDate).toLocaleDateString('en-GB')}</Table.Cell>
                                                <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }} >{getStatus(commande.orderFournisseurState)}</Table.Cell>
                                            </Table.Row>
                                        </>
                                    ))}{supplierOrders && supplierOrders.map((commande: IOrderSupplier, key) => (
                                        <>
                                            <Table.Row key={commande.id} >
                                                <Table.Cell>
                                                    <Header size='small'><Label style={{ marginLeft: '1px', backgroundColor: 'unset', display: 'ruby' }} as={Link} to={`/detailCommande/${commande.id}`}>{commande!.reference}</Label></Header>
                                                </Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithoutTaxes)}</Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithTaxes)}</Table.Cell>
                                                <Table.Cell>{commande.numSupplierOrder}</Table.Cell>
                                                <Table.Cell>{new Date(commande.orderDate).toLocaleDateString('en-GB')}</Table.Cell>
                                                <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }} >{getStatus(commande.orderFournisseurState)}</Table.Cell>
                                            </Table.Row>
                                        </>
                                    ))}{supplierOrders && supplierOrders.map((commande: IOrderSupplier, key) => (
                                        <>
                                            <Table.Row key={commande.id} >
                                                <Table.Cell>
                                                    <Header size='small'><Label style={{ marginLeft: '1px', backgroundColor: 'unset', display: 'ruby' }} as={Link} to={`/detailCommande/${commande.id}`}>{commande!.reference}</Label></Header>
                                                </Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithoutTaxes)}</Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithTaxes)}</Table.Cell>
                                                <Table.Cell>{commande.numSupplierOrder}</Table.Cell>
                                                <Table.Cell>{new Date(commande.orderDate).toLocaleDateString('en-GB')}</Table.Cell>
                                                <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }} >{getStatus(commande.orderFournisseurState)}</Table.Cell>
                                            </Table.Row>
                                        </>
                                    ))}{supplierOrders && supplierOrders.map((commande: IOrderSupplier, key) => (
                                        <>
                                            <Table.Row key={commande.id} >
                                                <Table.Cell>
                                                    <Header size='small'><Label style={{ marginLeft: '1px', backgroundColor: 'unset', display: 'ruby' }} as={Link} to={`/detailCommande/${commande.id}`}>{commande!.reference}</Label></Header>
                                                </Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithoutTaxes)}</Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithTaxes)}</Table.Cell>
                                                <Table.Cell>{commande.numSupplierOrder}</Table.Cell>
                                                <Table.Cell>{new Date(commande.orderDate).toLocaleDateString('en-GB')}</Table.Cell>
                                                <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }} >{getStatus(commande.orderFournisseurState)}</Table.Cell>
                                            </Table.Row>
                                        </>
                                    ))}{supplierOrders && supplierOrders.map((commande: IOrderSupplier, key) => (
                                        <>
                                            <Table.Row key={commande.id} >
                                                <Table.Cell>
                                                    <Header size='small'><Label style={{ marginLeft: '1px', backgroundColor: 'unset', display: 'ruby' }} as={Link} to={`/detailCommande/${commande.id}`}>{commande!.reference}</Label></Header>
                                                </Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithoutTaxes)}</Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithTaxes)}</Table.Cell>
                                                <Table.Cell>{commande.numSupplierOrder}</Table.Cell>
                                                <Table.Cell>{new Date(commande.orderDate).toLocaleDateString('en-GB')}</Table.Cell>
                                                <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }} >{getStatus(commande.orderFournisseurState)}</Table.Cell>
                                            </Table.Row>
                                        </>
                                    ))}{supplierOrders && supplierOrders.map((commande: IOrderSupplier, key) => (
                                        <>
                                            <Table.Row key={commande.id} >
                                                <Table.Cell>
                                                    <Header size='small'><Label style={{ marginLeft: '1px', backgroundColor: 'unset', display: 'ruby' }} as={Link} to={`/detailCommande/${commande.id}`}>{commande!.reference}</Label></Header>
                                                </Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithoutTaxes)}</Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithTaxes)}</Table.Cell>
                                                <Table.Cell>{commande.numSupplierOrder}</Table.Cell>
                                                <Table.Cell>{new Date(commande.orderDate).toLocaleDateString('en-GB')}</Table.Cell>
                                                <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }} >{getStatus(commande.orderFournisseurState)}</Table.Cell>
                                            </Table.Row>
                                        </>
                                    ))}{supplierOrders && supplierOrders.map((commande: IOrderSupplier, key) => (
                                        <>
                                            <Table.Row key={commande.id} >
                                                <Table.Cell>
                                                    <Header size='small'><Label style={{ marginLeft: '1px', backgroundColor: 'unset', display: 'ruby' }} as={Link} to={`/detailCommande/${commande.id}`}>{commande!.reference}</Label></Header>
                                                </Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithoutTaxes)}</Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithTaxes)}</Table.Cell>
                                                <Table.Cell>{commande.numSupplierOrder}</Table.Cell>
                                                <Table.Cell>{new Date(commande.orderDate).toLocaleDateString('en-GB')}</Table.Cell>
                                                <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }} >{getStatus(commande.orderFournisseurState)}</Table.Cell>
                                            </Table.Row>
                                        </>
                                    ))}{supplierOrders && supplierOrders.map((commande: IOrderSupplier, key) => (
                                        <>
                                            <Table.Row key={commande.id} >
                                                <Table.Cell>
                                                    <Header size='small'><Label style={{ marginLeft: '1px', backgroundColor: 'unset', display: 'ruby' }} as={Link} to={`/detailCommande/${commande.id}`}>{commande!.reference}</Label></Header>
                                                </Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithoutTaxes)}</Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithTaxes)}</Table.Cell>
                                                <Table.Cell>{commande.numSupplierOrder}</Table.Cell>
                                                <Table.Cell>{new Date(commande.orderDate).toLocaleDateString('en-GB')}</Table.Cell>
                                                <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }} >{getStatus(commande.orderFournisseurState)}</Table.Cell>
                                            </Table.Row>
                                        </>
                                    ))}{supplierOrders && supplierOrders.map((commande: IOrderSupplier, key) => (
                                        <>
                                            <Table.Row key={commande.id} >
                                                <Table.Cell>
                                                    <Header size='small'><Label style={{ marginLeft: '1px', backgroundColor: 'unset', display: 'ruby' }} as={Link} to={`/detailCommande/${commande.id}`}>{commande!.reference}</Label></Header>
                                                </Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithoutTaxes)}</Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithTaxes)}</Table.Cell>
                                                <Table.Cell>{commande.numSupplierOrder}</Table.Cell>
                                                <Table.Cell>{new Date(commande.orderDate).toLocaleDateString('en-GB')}</Table.Cell>
                                                <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }} >{getStatus(commande.orderFournisseurState)}</Table.Cell>
                                            </Table.Row>
                                        </>
                                    ))}{supplierOrders && supplierOrders.map((commande: IOrderSupplier, key) => (
                                        <>
                                            <Table.Row key={commande.id} >
                                                <Table.Cell>
                                                    <Header size='small'><Label style={{ marginLeft: '1px', backgroundColor: 'unset', display: 'ruby' }} as={Link} to={`/detailCommande/${commande.id}`}>{commande!.reference}</Label></Header>
                                                </Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithoutTaxes)}</Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithTaxes)}</Table.Cell>
                                                <Table.Cell>{commande.numSupplierOrder}</Table.Cell>
                                                <Table.Cell>{new Date(commande.orderDate).toLocaleDateString('en-GB')}</Table.Cell>
                                                <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }} >{getStatus(commande.orderFournisseurState)}</Table.Cell>
                                            </Table.Row>
                                        </>
                                    ))}{supplierOrders && supplierOrders.map((commande: IOrderSupplier, key) => (
                                        <>
                                            <Table.Row key={commande.id} >
                                                <Table.Cell>
                                                    <Header size='small'><Label style={{ marginLeft: '1px', backgroundColor: 'unset', display: 'ruby' }} as={Link} to={`/detailCommande/${commande.id}`}>{commande!.reference}</Label></Header>
                                                </Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithoutTaxes)}</Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithTaxes)}</Table.Cell>
                                                <Table.Cell>{commande.numSupplierOrder}</Table.Cell>
                                                <Table.Cell>{new Date(commande.orderDate).toLocaleDateString('en-GB')}</Table.Cell>
                                                <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }} >{getStatus(commande.orderFournisseurState)}</Table.Cell>
                                            </Table.Row>
                                        </>
                                    ))}{supplierOrders && supplierOrders.map((commande: IOrderSupplier, key) => (
                                        <>
                                            <Table.Row key={commande.id} >
                                                <Table.Cell>
                                                    <Header size='small'><Label style={{ marginLeft: '1px', backgroundColor: 'unset', display: 'ruby' }} as={Link} to={`/detailCommande/${commande.id}`}>{commande!.reference}</Label></Header>
                                                </Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithoutTaxes)}</Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithTaxes)}</Table.Cell>
                                                <Table.Cell>{commande.numSupplierOrder}</Table.Cell>
                                                <Table.Cell>{new Date(commande.orderDate).toLocaleDateString('en-GB')}</Table.Cell>
                                                <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }} >{getStatus(commande.orderFournisseurState)}</Table.Cell>
                                            </Table.Row>
                                        </>
                                    ))}{supplierOrders && supplierOrders.map((commande: IOrderSupplier, key) => (
                                        <>
                                            <Table.Row key={commande.id} >
                                                <Table.Cell>
                                                    <Header size='small'><Label style={{ marginLeft: '1px', backgroundColor: 'unset', display: 'ruby' }} as={Link} to={`/detailCommande/${commande.id}`}>{commande!.reference}</Label></Header>
                                                </Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithoutTaxes)}</Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithTaxes)}</Table.Cell>
                                                <Table.Cell>{commande.numSupplierOrder}</Table.Cell>
                                                <Table.Cell>{new Date(commande.orderDate).toLocaleDateString('en-GB')}</Table.Cell>
                                                <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }} >{getStatus(commande.orderFournisseurState)}</Table.Cell>
                                            </Table.Row>
                                        </>
                                    ))}{supplierOrders && supplierOrders.map((commande: IOrderSupplier, key) => (
                                        <>
                                            <Table.Row key={commande.id} >
                                                <Table.Cell>
                                                    <Header size='small'><Label style={{ marginLeft: '1px', backgroundColor: 'unset', display: 'ruby' }} as={Link} to={`/detailCommande/${commande.id}`}>{commande!.reference}</Label></Header>
                                                </Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithoutTaxes)}</Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithTaxes)}</Table.Cell>
                                                <Table.Cell>{commande.numSupplierOrder}</Table.Cell>
                                                <Table.Cell>{new Date(commande.orderDate).toLocaleDateString('en-GB')}</Table.Cell>
                                                <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }} >{getStatus(commande.orderFournisseurState)}</Table.Cell>
                                            </Table.Row>
                                        </>
                                    ))}{supplierOrders && supplierOrders.map((commande: IOrderSupplier, key) => (
                                        <>
                                            <Table.Row key={commande.id} >
                                                <Table.Cell>
                                                    <Header size='small'><Label style={{ marginLeft: '1px', backgroundColor: 'unset', display: 'ruby' }} as={Link} to={`/detailCommande/${commande.id}`}>{commande!.reference}</Label></Header>
                                                </Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithoutTaxes)}</Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithTaxes)}</Table.Cell>
                                                <Table.Cell>{commande.numSupplierOrder}</Table.Cell>
                                                <Table.Cell>{new Date(commande.orderDate).toLocaleDateString('en-GB')}</Table.Cell>
                                                <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }} >{getStatus(commande.orderFournisseurState)}</Table.Cell>
                                            </Table.Row>
                                        </>
                                    ))}{supplierOrders && supplierOrders.map((commande: IOrderSupplier, key) => (
                                        <>
                                            <Table.Row key={commande.id} >
                                                <Table.Cell>
                                                    <Header size='small'><Label style={{ marginLeft: '1px', backgroundColor: 'unset', display: 'ruby' }} as={Link} to={`/detailCommande/${commande.id}`}>{commande!.reference}</Label></Header>
                                                </Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithoutTaxes)}</Table.Cell>
                                                <Table.Cell>{numberFormat.format(commande.amountWithTaxes)}</Table.Cell>
                                                <Table.Cell>{commande.numSupplierOrder}</Table.Cell>
                                                <Table.Cell>{new Date(commande.orderDate).toLocaleDateString('en-GB')}</Table.Cell>
                                                <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }} >{getStatus(commande.orderFournisseurState)}</Table.Cell>
                                            </Table.Row>
                                        </>
                                    ))}
                                </Table.Body>
                            </Table>
                        </div>
                        ) : (
                            <NoResult name='Commandes Fournisseur ' />
                        )
                )}

        </>
    );
}

export default observer(CommandeFournisseur);