import { useContext, useEffect, useState } from 'react';
import { Tab, Header, Button, Grid, Table, Form, Input } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import TextInput from '../../../app/common/form/inputText';
import DeleteTarifFournisseurModal from './DeleteTarifFournisseurModal';
import AffectTarifFournisseurToArticle from './AffectTarifFournisseurToArticle';

const TarifFournisseurDescription = () => {
    const baseStore = useContext(BaseStoreContext)
    const { tarifProductFournisseurs, loadTarifFournisseurByProduct, edit, submitting, deletingtarifFournisseur } = baseStore.tarifFournisseurStore;
    const { article } = baseStore.articleStore;
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    const [editTarget, setEditTarget] = useState<string | undefined>(undefined);
    const [editForm, setEditForm] = useState(false);
    const [prix, setPrix] = useState<number>();
    const [quantite, setQuantite] = useState<number>();
    const [productReference, setProductReference] = useState<string>("");
    const [date, setDate] = useState<string>(new Date().toISOString());
    const { openModal } = baseStore.modalStore;

    useEffect(() => {
        loadTarifFournisseurByProduct(article!.productId);
        baseStore.articleStore.changeButtonState(false);
    }, [])

    const handleChangeQuantite = (e: any, result: any) => {
        setQuantite(result.value);
    }
    const handleChangePrix = (e: any, result: any) => {
        setPrix(result.value);
    }
    const handleFinalFormSubmit = () => {
        let newTarif = {
            quantite: quantite,
            prix: prix,
            id: parseInt(editTarget!),
            productReference: productReference,
            dateCreation: date
        };
        edit(newTarif);
        setEditForm(false)
    }
    return (
        <Tab.Pane>
            <Grid>
                <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                    <Header floated='left' icon='shopping basket' content={baseStore.articleStore.editArticleMode ? 'Ajout Tarif' : " Tarif d'Achat "} />
                    <Button
                        onClick={() => { baseStore.articleStore.changeButtonState(!baseStore.articleStore.editArticleMode) }}
                        floated='right'
                        basic
                        content={baseStore.articleStore.editArticleMode ? 'Annuler' : 'Ajouter tarif'}
                    />
                </Grid.Column>
                <Grid.Column width={16}>
                    {baseStore.articleStore.editArticleMode ? (
                        <AffectTarifFournisseurToArticle
                        />
                    ) : (
                        <>
                            <Table loading={false} celled>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Quantité</Table.HeaderCell>
                                        <Table.HeaderCell>Prix</Table.HeaderCell>
                                        <Table.HeaderCell>Dénomination</Table.HeaderCell>
                                        <Table.HeaderCell>Réf. Article</Table.HeaderCell>
                                        <Table.HeaderCell>Date</Table.HeaderCell>
                                        <Table.HeaderCell style={{ textAlign: "center" }}>Actions</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {tarifProductFournisseurs &&
                                        tarifProductFournisseurs.map(tarif => (
                                            <Table.Row key={tarif.id}>
                                                {editForm && editTarget === tarif.id.toString() ? (
                                                    <>
                                                        <Table.Cell>
                                                            <Form.Input style={{ height: '37px', width: '80px' }} name='quantite' component={TextInput} placeholder='aa' defaultValue={tarif!.quantite} type='number' onChange={handleChangeQuantite} />
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <Form.Input style={{ height: '37px', width: '100px' }} name='prix' component={TextInput} placeholder='aa' defaultValue={tarif!.prix} type='number' onChange={handleChangePrix} />
                                                        </Table.Cell>
                                                        <Table.Cell>{tarif!.fabricant || tarif.companyName}</Table.Cell>
                                                        <Table.Cell>
                                                            <Input style={{ height: '37px', width: '100px' }} name='productReference' component={TextInput} placeholder='Réf. article' defaultValue={tarif!.productReference} onChange={(e) => setProductReference(e.target.value)} />
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <Input name='creationDate' onChange={(e: any) => setDate(e.target.value)} value={new Date(date).toLocaleDateString('sv-SE')} style={{ marginLeft: 10, height: 30, width: 150 }} type='date' />
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            <Button.Group fluid widths={2}>
                                                                <Button
                                                                    basic
                                                                    name={tarif.id}
                                                                    positive
                                                                    type='submit'
                                                                    onClick={(e) => {
                                                                        handleFinalFormSubmit()
                                                                        setEditTarget(e.currentTarget.name)
                                                                    }}
                                                                    icon='check'
                                                                    loading={submitting}
                                                                />
                                                                <Button
                                                                    negative
                                                                    basic
                                                                    type='button'
                                                                    onClick={() => {
                                                                        setEditForm(false);
                                                                    }}
                                                                    icon='cancel'
                                                                />
                                                            </Button.Group>
                                                        </Table.Cell>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Table.Cell>{tarif.quantite}</Table.Cell>
                                                        <Table.Cell>{tarif.prix}€</Table.Cell>
                                                        <Table.Cell>{tarif!.fabricant || tarif!.companyName}</Table.Cell>
                                                        <Table.Cell>{tarif!.productReference}</Table.Cell>
                                                        <Table.Cell>{new Date(tarif!.dateCreation).toLocaleDateString('en-GB')}</Table.Cell>
                                                        <Table.Cell>
                                                            <Button.Group fluid widths={3}>
                                                                <Button
                                                                    onClick={(e) => {
                                                                        setEditForm(true);
                                                                        setEditTarget(e.currentTarget.name)
                                                                        setQuantite(tarif.quantite)
                                                                        setProductReference(tarif.productReference)
                                                                        setDate(tarif.dateCreation.toLocaleString())
                                                                        setPrix(tarif.prix)
                                                                    }}
                                                                    basic
                                                                    color="blue"
                                                                    icon='edit outline'
                                                                    name={tarif.id}
                                                                    style={{ marginRight: '2%' }}
                                                                />
                                                                <Button
                                                                    name={tarif.id}
                                                                    onClick={(e) => {
                                                                        openModal(<DeleteTarifFournisseurModal TarifId={tarif!.id} />)
                                                                        setDeleteTarget(e.currentTarget.name)
                                                                    }}
                                                                    loading={deletingtarifFournisseur && deleteTarget === tarif.id.toString()}
                                                                    basic
                                                                    negative
                                                                    icon='trash'
                                                                />
                                                            </Button.Group>
                                                        </Table.Cell>
                                                    </>
                                                )}
                                            </Table.Row>
                                        ))}
                                </Table.Body>
                            </Table>
                        </>
                    )}
                </Grid.Column>
            </Grid>
        </Tab.Pane>
    );
};
export default observer(TarifFournisseurDescription);
