import React from 'react';
import { Header, Grid, Segment, Item, Reveal, Button, Modal, Image } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import NotFound from '../../../app/layout/NoFound';
import { IArticle } from '../../../app/models/IArticle';
import { useState } from 'react';
import { MdOutlineCancelPresentation } from 'react-icons/md';

interface IProps {
    article: IArticle,
    loading: boolean,
    isLoggedIn: boolean;
    submitting: boolean;
    disable: (id: number) => Promise<void>;
    enable: (id: number) => Promise<void>;
    duplicate: (product: IArticle) => Promise<void>;
}
const ArticleHeader: React.FC<IProps> = ({ article, loading, isLoggedIn, disable, enable, duplicate, submitting }) => {

    const [modalOpen, setModalOpen] = useState(false);

    if (!article) return <NotFound />;
    return (
        <Segment>
            <Modal
                open={modalOpen}
                size='large'
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >
                <Modal.Content style={{ display: 'flex', justifyContent: "center" }}>
                    <Button
                        type='button'
                        style={{ position: "absolute", top: '-16px', right: "-16px", zIndex: 8040, width: 32, height: 32, cursor: "pointer", color: "white", backgroundColor: "black", borderRadius: 50, border: "3px solid white", padding: 0, boxShadow:"-2px 2px 10px black"}}
                        onClick={() => { setModalOpen(false) }}
                    >
                        <span style={{ fontSize:18 }}>x</span>
                    </Button>
                    <Image style={{ height: 600 }} src={article.uri} />
               </Modal.Content>
            </Modal>
            <Grid>
                <Grid.Column width={14}>
                    <Item.Group>
                        <Item>
                            <Item.Image style={{ display: 'flex', height: 150, cursor: "zoom-in" }} src={article.uri}  onClick={(e:any) => { setModalOpen(true); }}/>
                            <Item.Content verticalAlign='middle' style={{marginLeft:30}}>
                                <Header as='h2'>{article.reference} {article!.label}</Header>
                            </Item.Content>
                        </Item>
                    </Item.Group>
                </Grid.Column>
                <Grid.Column width={2}>
                    <Button
                        fluid
                        size="small"
                        color='orange'
                        loading={submitting}
                        disabled={submitting}
                        content="Dubliquer"
                        onClick={()=>duplicate(article)}
                    />
                    {isLoggedIn &&
                        <Reveal animated='move'>
                            <Reveal.Content visible style={{ width: '100%' }}>
                                <Button
                                fluid
                                size="small"
                                color={article.isActivated ? 'green' : 'red'}
                                content={article.isActivated ? 'Actif' : 'Inactif '}
                                />
                            </Reveal.Content>
                            <Reveal.Content hidden>
                                <Button
                                    loading={loading}
                                fluid
                                size="small"
                                    basic
                                color={article.isActivated ? 'red' : 'green'}
                                content={article.isActivated ? 'Désactiver' : 'Activer'}
                                    onClick={
                                        article.isActivated
                                            ? () => disable(article.productId)
                                            : () => enable(article.productId)
                                    }
                                />
                            </Reveal.Content>
                        </Reveal>
                    }
                </Grid.Column>
            </Grid>
        </Segment >
    );
};
export default observer(ArticleHeader);