import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { Button, Header, Icon, Container } from 'semantic-ui-react';
import { IModePaiement } from '../../../app/models/IModePaiement';
import { BaseStoreContext } from '../../../app/store/baseStore';

interface IProps {
    modePaiement: IModePaiement;
}

const DeleteModePaiementModal: React.FC<IProps> = ({ modePaiement }) => {
    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    const { deleteModePaiement, loadCountPaiement, countPaiement} = baseStore.modePaiementStore;
    useEffect(() => {
        loadCountPaiement(modePaiement.paiementId);
    }, []);

    return (
        <Container textAlign='center'>
            <Header icon='trash' content='Supprimer mode paiement' style={{ marginLeft: 20 }} />
            <p>
                Attention ! Ce mode est affecté a : <br />
                client(s) : {countPaiement?.nbreClients}<br />
                Franchiseur(s) : {countPaiement?.nbreFranchiseurs}<br />
                Fournisseur(s) : {countPaiement?.nbreFournisseurs}
            </p>

            <Button.Group fluid>
                <Button onClick={() => closeModal()}>
                    <Icon name='remove' /> Annuler
                </Button>
                <Button.Or text='Ou' />
                <Button
                    name={modePaiement.paiementId}
                    color='green'
                    onClick={(e) => {
                        deleteModePaiement(modePaiement.paiementId)
                        closeModal()
                    }}>
                    <Icon name='checkmark' /> Confirmer
                </Button>
            </Button.Group>

        </Container>
    )
}
export default observer(DeleteModePaiementModal);
