import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { Table } from "semantic-ui-react";
import { numberFormat } from "../../app/common/options/IStyle";
import { IReliquat } from "../../app/models/IExpedition";
import { BaseStoreContext } from "../../app/store/baseStore";
import NoResult from "../commande/commandeDashboard/ErrorNoResult";

const ReliquatsList = () => {
    const baseStore = useContext(BaseStoreContext);
    const { reliquats } = baseStore.expeditionStore;
    if (reliquats.length < 1) return < NoResult name="Réliquats" />
    else
        return (
            <>
                <Table compact='very' style={{ marginTop: '1em' }} >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>DATE</Table.HeaderCell>
                            <Table.HeaderCell>REF COMMANDE</Table.HeaderCell>
                            <Table.HeaderCell>CLIENT</Table.HeaderCell>
                            <Table.HeaderCell>ARTICLE</Table.HeaderCell>
                            <Table.HeaderCell>QTE A LIVRER</Table.HeaderCell>
                            <Table.HeaderCell>QTE LIVREE</Table.HeaderCell>
                            <Table.HeaderCell>QTE RESTE A LIVRER</Table.HeaderCell>
                            <Table.HeaderCell>TOTAL HT</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {reliquats && reliquats.map((item: IReliquat) => (
                            <Table.Row key={item.id}>
                                <Table.Cell>{new Date(item.orderDate).toLocaleDateString('en-GB')}</Table.Cell>
                                <Table.Cell>{<span><a style={{ background: "none", cursor: 'pointer' }} href={`/detailCommande/${item.orderRef.substring(7)}`}>{item.orderRef}</a></span>}</Table.Cell>
                                <Table.Cell>{item.agencyName} - {item.companyName}</Table.Cell>
                                {/*<Table.Cell>{item.productCode} - {item.productLabel}</Table.Cell>*/}
                                <Table.Cell>{item.isPack ? !item.isInPack ? "(PACK) " : "(" + item.productCode + ") " : ""}{item.isInPack ? item.productId + "-" + item.productPackLabel : item.productCode + "-" + item.productLabel}</Table.Cell>
                                <Table.Cell>{item.quantiteALivree}</Table.Cell>
                                <Table.Cell>{item.quantiteLivree}</Table.Cell>
                                <Table.Cell>{item.quantiteResteLivre}</Table.Cell>
                                <Table.Cell>{numberFormat.format(item.unitPrice * item.quantiteResteLivre)}</Table.Cell>

                                {/*<Table.Cell>*/}
                                {/*    <span><a style={{ background: "none", cursor: 'pointer' }} href={`/detailExpedition/${item.orderDispatchId}`}><b>{item.orderDispatchRef}</b></a></span>*/}
                                {/*</Table.Cell>*/}

                                {/*<Table.Cell>{numberFormat.format(item.countAmountWithTax)}</Table.Cell>*/}
                                {/*<Table.Cell>{numberFormat.format(item.countAmountWithoutTax)}</Table.Cell>*/}
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </>
        );
}
export default observer(ReliquatsList);