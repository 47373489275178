import { observer } from "mobx-react-lite"
import { useContext, useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { Button, Form, Grid, Icon, Modal, Tab } from "semantic-ui-react";
import { BaseStoreContext } from "../../../app/store/baseStore";
import { Form as FinalForm } from 'react-final-form';
import InputSelect from "../../../app/common/form/inputSelect";
import { IArticleListByFranchiseur } from "../../../app/models/IClient";
import { IProduct } from "../../../app/models/IArticle";
import { toast } from "react-toastify";
import AchatGroupeeModal from "../../franchiseur/DetailFranchiseur/AchatGroupeeModal";
import { history } from '../../../index';


const AddAchatGContent = () => {

    const baseRepo = useContext(BaseStoreContext);
    const { listPrices, clearListPrice, createSpecialOffer } = baseRepo.specialOfferStore;

    const [codeFranchiseur, setCodeFranchiseur] = useState<string >("");
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [productId, setProductId] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [modalButton, setModalButton] = useState(false);

    const handleChangeCodeFranch = async (e: any) => {
        setCodeFranchiseur(e);
    }

    const handleChangeDateDebut = (e: any) => {
        let date = e.target.value;
        let newdate = new Date(new Date().setDate(new Date().getDate()));
        let newdateFormatted = formattedDate(newdate);
        if (date >= newdateFormatted) {
            setStartDate(date);
        } else {
            alert("date début doit etre superieur ou égal a la date d'aujourd'hui");
            setStartDate(newdateFormatted);
        }
    }

    const handleChangeDateFin = (e: any) => {
        let day1 = e.target.value
        setEndDate(day1);

        let day2 = startDate;

        if (day2! >= day1) {
            alert("date fin doit etre superieur a date debut");
            let newdate = new Date(new Date().setDate(new Date(day2!).getDate() + 7));
            let x = formattedDate(newdate);
            console.log(x);
            setEndDate(x);
        }
    }

    const formattedDate = (date: Date) => {
        var year = date.toLocaleString("default", { year: "numeric" });
        var month = date.toLocaleString("default", { month: "2-digit" });
        var day = date.toLocaleString("default", { day: "2-digit" });

        // Generate yyyy-mm-dd date string
        var formattedDate = year + "-" + month + "-" + day;
        return formattedDate;
    }

    const handleSetArticle = (e: any) => {
        setProductId(e!.productId);        
    }

    const getOptions = async (inputValue: string) => {
        var newListeArticle: IProduct[] = [];
        let idtonumber = Number(codeFranchiseur!);
        newListeArticle = await baseRepo.articleStore.loadArticleOptions(idtonumber!, inputValue);
        return newListeArticle;
    }

    const handleModalButtonClick = () => {
        setModalButton(true);
    }

    const handleFinalFormSubmit = (values: any) => {
        let newAchatGroupee = {
            ...values,
            startDate: startDate,
            endDate: endDate,
            productId: productId,
            companyId: codeFranchiseur,
            promotionCode: localStorage.getItem("codePromo"),
            prices: listPrices
        }
        console.log(newAchatGroupee);
        createSpecialOffer(newAchatGroupee).then(() => {
            clearListPrice();
            setStartDate('');
            setEndDate('');
            history.push('/offreDashboard');
        });
    }
    const clearForm = () => {
        clearListPrice();
    }

    return (

        <Tab.Pane>
            <Grid>
                <Grid.Column width={16} style={{ paddingBottom: 0 }} >

                    <FinalForm
                        onSubmit={handleFinalFormSubmit}

                        render={({ handleSubmit }) => (
                            <Form onSubmit={handleSubmit} error >

                                <Grid.Column>
                                    <div className="field">
                                        <h5> Code Promo: &nbsp; {localStorage.getItem("codePromo")} </h5>
                                    </div>
                                </Grid.Column>
                                <Grid columns={2} divided>



                                    <Grid.Column>
                                        <h5>Client  </h5>
                                        <AsyncSelect
                                            name="form-field-name"
                                            noOptionsMessage={() => "Code introuvable"}
                                            placeholder='Franchiseur'
                                            cacheOptions
                                            defaultOptions
                                            getOptionLabel={e => e!.text}
                                            getOptionValue={e => e!.value}
                                            loadOptions={baseRepo.franchiseurStore.loadFranchiseurOptions}
                                            onChange={e => handleChangeCodeFranch(e!.key)}
                                        />
                                    </Grid.Column>

                                    <Grid.Column>
                                        <div className="field">
                                            <h5>Article</h5>
                                            {codeFranchiseur != "" ? (
                                                <AsyncSelect
                                                    key={codeFranchiseur.toString()}
                                                    name="form-field-name"
                                                    noOptionsMessage={() => "Code introuvable"}
                                                    cacheOptions
                                                    defaultOptions
                                                    placeholder='Article'
                                                    getOptionLabel={e => e!.productId + " - " + e!.label}
                                                    getOptionValue={e => e!.productId.toString()}
                                                    loadOptions={getOptions}
                                                    onChange={e => handleSetArticle(e)}
                                                />
                                            ) : (
                                                <></>
                                            )}



                                        </div>
                                    </Grid.Column>

                                    <Grid.Row>
                                        <Grid.Column>
                                            <h5>Date début</h5>
                                            <input value={startDate} type="date" placeholder='Date début' onChange={(e: any) => handleChangeDateDebut(e)} />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <h5>Date fin</h5>
                                            <input value={endDate} placeholder='Date fin' type="date" onChange={(e) => handleChangeDateFin(e)} />
                                        </Grid.Column>
                                    </Grid.Row>


                                    <Grid.Column>
                                        <h5> Les prix </h5>
                                        <Button onClick={() => setModalOpen(true)} floated='left' type='button'>Ajouter prix par quantités</Button>

                                    </Grid.Column>



                                </Grid>

                                {listPrices.length > 0 ? (
                                    <Form.Group unstackable style={{ marginLeft: "70%" }} >
                                        <Button.Group floated="right">
                                            <Button onClick={() => clearForm} floated='right' type='button'>Annuler</Button>
                                            <Button.Or />
                                            <Button
                                                positive
                                                floated='right'
                                                type='submit'
                                            >
                                                Confirmer
                                            </Button>
                                        </Button.Group>
                                    </Form.Group>
                                ) : (
                                    <></>
                                )}

                            </Form>
                        )}
                    />
                </Grid.Column>
            </Grid>

            <Modal
                open={modalOpen}
                size='large'
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >
                <Modal.Header icon='add' style={{ marginLeft: 20 }} >
                    Liste des prix et quantitées
                </Modal.Header>

                <Modal.Content scrolling>
                    <AchatGroupeeModal modalButton={modalButton} setModalOpen={setModalOpen} setModalButton={setModalButton} />
                </Modal.Content>

                <Modal.Actions>
                    <div style={{ paddingBottom: 20, marginTop: 10, marginRight: 10, display: 'inline-block' }}>
                        <Button.Group floated="right">
                            <Button
                                type='button'
                                floated='right'
                                onClick={() => {
                                    setModalOpen(false)
                                    clearListPrice()
                                }}
                            >
                                <Icon name='remove' /> Annuler
                            </Button>
                            <Button.Or text='Ou' />
                            <Button
                                positive
                                type='submit'
                                floated='right'
                                onClick={(e) => {
                                    if (listPrices.length > 0) {
                                        handleModalButtonClick()
                                        setModalOpen(false)
                                    } else {
                                        toast.error("vous devez ajouter des prix");
                                    }
                                }}
                            >
                                <Icon name='checkmark' /> Confirmer
                            </Button>
                        </Button.Group>
                    </div>
                </Modal.Actions>

            </Modal>

        </Tab.Pane>

    );
}

export default observer(AddAchatGContent);
