import React, { useContext, useEffect, useState } from 'react';
import { GridColumn, Grid, Loader, Button, Modal, Icon } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import InfiniteScroll from 'react-infinite-scroller';
import ListItemPlaceholder from '../../../app/common/form/ListItemPlaceholder';
import Breadcrumbs from '../../dashboard/breadcrumbs';
import { FaPlusCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ArticleServiceList from './ArticleServiceList';
import ArticleServiceFilter from './ArticleServiceFilter';

const ArticleServiceDashboard: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { loadServices, loadingService, pageService, totalPageService, setPageService } = baseStore.articleStore;
    const [loadingNext, setLoadingNext] = useState(false);

    const handleGetNext = () => {
        setLoadingNext(true);
        setPageService(pageService + 1);
        loadServices().then(() => setLoadingNext(false));
    }
    useEffect(() => {
        loadServices();
    }, []);

    return (

        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Services", root: "" },
                    { key: 1, name: "Liste services", root: "/articleService" },
                ]}
            />
            <Grid>
                <Grid.Row style={{ padding: 0, margin: "5px 20px 0 20px" }}>
                    <Grid.Column style={{ padding: 0, margin: 0 }}>

                        <Button
                            style={{ float: 'right' }}
                            content="Ajouter Service"
                            className="andi-btn filled success"
                            icon={(<FaPlusCircle style={{ marginRight: "10px", fontSize: "20px" }} />)}
                            as={Link}
                            to={"/addArticleService"}                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ padding: 0, margin: "0 20px 0 20px" }}>

                    <ArticleServiceFilter />

                </Grid.Row>
                <GridColumn width={16} style={{ marginTop: 2 }}>

                    <Grid style={{ marginTop: 2 }}>
                        <GridColumn width={16}>
                            {loadingService && pageService === 0 ? <ListItemPlaceholder /> : (
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={handleGetNext}
                                    hasMore={!loadingNext && pageService + 1 < totalPageService}
                                    initialLoad={false}
                                >
                                    <ArticleServiceList />
                                </InfiniteScroll>
                            )}
                        </GridColumn>
                    </Grid>
                </GridColumn>

                <Grid.Column width={16}>
                    <Loader active={loadingNext} />
                </Grid.Column>
            </Grid>
        </div>
    );
}
export default observer(ArticleServiceDashboard);