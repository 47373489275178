import { useContext } from 'react';
import { Tab, Grid, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import NotFound from '../../../app/layout/NoFound';
import CommandeEditForm from './CommandeEditform';
import CommandeDuplicationInformations from './CommandeDuplicationInformations';
import FormGridColumn from '../../../app/common/form/FormGridColumn';


const setPaiementType = (status: string) => {
    switch (status) {
        case '31':
            return 'Carte Bancaire';
        case '03':
            return 'Chèque de banque';
        case '01':
            return 'Espèce';
        case '15':
            return 'Prélèvement Bancaire';
        case '11':
            return 'Virement Bancaire';
        default:
            return status;
    }
}
const numberFormat = new Intl.NumberFormat('FR-fr', {
    style: 'currency',
    currency: 'EUR',
    useGrouping: true,
});
const CommandeDescription = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { commande, editCommande, submitting } = baseRepo.commandeStore;
    if (!commande) {
        return <NotFound />
    }
    else {
        return (
            <Tab.Pane>
                <Grid>
                    <Grid.Column width={16}>
                        <Button
                            onClick={() => { baseRepo.commandeStore.changeButtonState(!baseRepo.commandeStore.editCommandeMode) }}
                            floated='right'
                            color={baseRepo.commandeStore.editCommandeMode ? 'red' : 'green'}
                            basic
                            content={baseRepo.commandeStore.editCommandeMode ? 'Annuler' : 'Modifier'}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        {baseRepo.commandeStore.duplicationMode ? (
                            <CommandeDuplicationInformations />
                        ) : (
                            baseRepo.commandeStore.editCommandeMode ? (
                                <CommandeEditForm editCommande={editCommande} commande={commande!} submitting={submitting} />
                            ) : (
                                <Grid className="ui stackable">
                                    <Grid.Row style={{ padding: 0, margin: 0 }}>
                                        <FormGridColumn header="Client" content={commande!.companyName} root={commande!.companyType == "Franchiseur" ? `/detailFranchiseur/${commande.companyId}` : `/detailClient/${commande.companyId}`} />
                                        <FormGridColumn header="Date Commande" content={new Date(commande!.orderDate).toLocaleDateString('en-GB')} />
                                        <FormGridColumn header="Transport" content={commande!.deliveryCompany} />
                                        {/*<FormGridColumn header="Livraison" content={commande!.deliveryAddress1} />*/}
                                        <FormGridColumn header="Montant HT" content={numberFormat.format(commande!.amountWithoutTaxes)} />
                                        <FormGridColumn header="Montant TTC" content={numberFormat.format(commande!.amountWithTaxes)} />
                                        <FormGridColumn header="Mode de paiement" content={setPaiementType(commande!.paymentMode)} />
                                        <FormGridColumn header="Commentaires" content={commande!.comment} />
                                        <FormGridColumn header="Transporteur" content={commande!.deliveryService} />
                                    </Grid.Row>
                                </Grid>
                            )

                        )}
                    </Grid.Column>
                </Grid>
            </Tab.Pane>
        );
    }
}
export default observer(CommandeDescription);