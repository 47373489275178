import React from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import AddCommandeFournisseurContent from './AddCommandeFournisseurContent';
import Breadcrumbs from '../../dashboard/breadcrumbs';

const AddCommandeFournisseurPage: React.FC = ({ }) => {
    return (
        <Grid.Row>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Achats", root: "" },
                    { key: 1, name: "Commandes Fournisseurs", root: "/commandeFournisseurDashboard" },
                    { key: 2, name: "Ajouter commande Fournisseur", root: "/addCommandeFournisseur" },
                ]}
            />
            <Grid>
                <Grid.Column width={16}>
                    <Button
                        floated="right"
                        content="Retour"
                        color="orange"
                        icon='arrow alternate circle left outline'
                        basic
                        as={Link}
                        to="/commandeFournisseurDashboard"
                    />
                </Grid.Column>
                <Grid.Column width={16}>
                    <AddCommandeFournisseurContent />
                </Grid.Column>

            </Grid>
        </Grid.Row>
    );
}

export default observer(AddCommandeFournisseurPage);