import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Form, Button, Grid, Input } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import { IProductFournisseur } from '../../../app/models/IArticle';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { IFactureFournisseur, IFactureFournisseurLine } from '../../../app/models/IFactureFournisseur';
import CreatableSelect from 'react-select/creatable';
import { ITarifFournisseur } from '../../../app/models/ITarifFournisseur';

interface IProps {
    setAddArticleMode: Dispatch<SetStateAction<boolean>>;
    facture: IFactureFournisseur;
}

const AddFactureArticle: React.FC<IProps> = ({ setAddArticleMode, facture }) => {

    const baseStore = useContext(BaseStoreContext);
    const { loadArticlesFournisseur, loadingArticle, articlesFournisseur } = baseStore.articleStore;
    const { addArticle, addingArticle } = baseStore.factureFournisseurStore;
    const { loadingInitial, loadTarifFournisseurList, tarifFournisseurs, loadTarifFournisseurByTypeAndQuantite } = baseStore.tarifFournisseurStore;
    const [minQuantite, setMinQuantite] = useState<number | null>();
    const [validArticle, setValidArticle] = useState(true);
    const [article, setArticle] = useState<IProductFournisseur>();
    const [unitPrice, setUnitPrice] = useState<number>(0);
    const [unitPriceWithTaxes, setUnitPriceWithTaxes] = useState<number>(0);
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [totalPriceWithTaxes, setTotalPriceWithTaxes] = useState<number>(0);
    const [quantity, setQuantity] = useState<number | null>();
    const [disabled, setDisabled] = useState(true);
    const [remise, setRemise] = useState<number>(0);
    const [comment, setComment] = useState<string>("");
    const [tarif, setTarif] = useState<ITarifFournisseur | null>();

    useEffect(() => {
        facture.fournisseur ? loadArticlesFournisseur(facture.fournisseur.fournisseurId, 'Fournisseur') : loadArticlesFournisseur(facture.company.id, 'Franchiseur');
    }, [])

    const handleSetArticle = (e: IProductFournisseur) => {
        setArticle(e);
        setValidArticle(true);
        loadTarifFournisseurList(e.productId)
            .then((data: ITarifFournisseur[] | undefined) => {
                const tarifFournisseur = data!.filter(x => x.lastPrice == true)[0] ?? data![0];
                if (tarifFournisseur == undefined) {
                    setTarif(null)
                    setMinQuantite(0);
                    setQuantity(0);
                    setUnitPrice(0);
                    setUnitPriceWithTaxes(0);
                    setTotalPrice(0);
                    setTotalPriceWithTaxes(0);
                }
                else {
                    setTarif(tarifFournisseur)
                    setMinQuantite(tarifFournisseur.quantite);
                    setQuantity(tarifFournisseur.quantite);
                    setUnitPrice(tarifFournisseur.prix);
                    setUnitPriceWithTaxes(tarifFournisseur.prix + (tarifFournisseur.prix * e.tvaRate / 100));
                    setTotalPrice(tarifFournisseur.prix * tarifFournisseur.quantite);
                    setTotalPriceWithTaxes(tarifFournisseur.prix * tarifFournisseur.quantite + (tarifFournisseur.prix * e.tvaRate / 100) * tarifFournisseur.quantite);
                }

                setDisabled(false);
                setRemise(0);

                setValidArticle(false);
            });
    }
    const handleSetInformation = (e: any) => {
        var value = e.target.value == "" ? 0 : parseFloat(e.target.value);
        const tauxTva = article!.tvaRate!;
        const UnitPriceWithTaxes = value + value * tauxTva / 100;
        setUnitPrice(parseFloat(e.target.value));
        setUnitPriceWithTaxes(UnitPriceWithTaxes);
        setTotalPrice(value * quantity! - (value * quantity! * remise / 100));
        setTotalPriceWithTaxes(UnitPriceWithTaxes * quantity! - (UnitPriceWithTaxes * quantity! * remise / 100));
    }
    const handleSetPrixByTypeandQuantite = (e: any) => {
        setValidArticle(true);
        const parsedQuantite = parseFloat(e.quantite);
        setTarif(e);
        if (parsedQuantite > 0) {
            setQuantity(parsedQuantite);
            loadTarifFournisseurByTypeAndQuantite(article!.productId, facture.fournisseur ? 'Fournisseur' : 'Franchiseur', parsedQuantite)
                .then(data => {
                    const total = parsedQuantite * data!;
                    const tauxTva = article!.tvaRate;
                    const taxePrice = (data! * (100 + tauxTva)) / 100;
                    const totalTaxe = (total * (100 + tauxTva)) / 100;
                    setUnitPrice(data!);
                    setUnitPriceWithTaxes(taxePrice);
                    setTotalPrice(total - total * remise / 100);
                    setTotalPriceWithTaxes(totalTaxe - totalTaxe * remise / 100);
                    setValidArticle(false);
                });
        } else {
            toast.error("Quantité minimal est " + 1);
            setQuantity(minQuantite);
            setValidArticle(false);
        }
    }

    const handleChangeQuantity = (inputValue: string) => {
        setTimeout(() => {

            const newOption: ITarifFournisseur = {
                quantite: parseInt(inputValue),
                fournisseur: facture!.fournisseur?.fournisseurId,
                franchiseur: facture!.company?.id,
                dateCreation: tarif?.dateCreation!,
                prix: tarif?.prix ?? 0,
                article: article!.reference,
                companyName: facture!.company?.companyName,
                fabricant: facture!.fournisseur?.fabricant,
                productReference: article!.reference,
                type: facture!.fournisseur ? '' : '',
                id: tarif?.id ?? 0,
                lastPrice: false
            }
            setTarif(newOption);
            handleSetPrixByTypeandQuantite(newOption);
        }, 1000);

    }

    const handleChangePriceRemise = (e: any) => {
        const Remise = e.target.value;
        setRemise(Remise);
        setTotalPrice(unitPrice * quantity! - (unitPrice * quantity! * Remise / 100));
        setTotalPriceWithTaxes(unitPriceWithTaxes * quantity! - (unitPriceWithTaxes * quantity! * Remise / 100));
    }

    const handleFinalFormSubmit = (values: any) => {
        let newArticle: IFactureFournisseurLine = {
            ...values,
            purchaseInvoice: facture.id,
            quantity: quantity!,
            totalPrice: unitPrice * quantity!,
            unitPrice: unitPrice,
            totalPriceWithTaxes: unitPriceWithTaxes * quantity!,
            unitPriceWithTaxes: unitPriceWithTaxes,
            product: article,
            article: article?.productId,
            discount: remise,
            comment: comment,
        };
        addArticle(newArticle);
        setAddArticleMode(false);
    }

    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            render={({ handleSubmit, invalid }) => (
                <Form onSubmit={handleSubmit} error >
                    <Form.Group unstackable widths={2}>
                        <div className="field">
                            <h5>Article</h5>
                            <Select isSearchable name="article" isLoading={loadingArticle} options={articlesFournisseur}
                                getOptionLabel={e => e!.reference + ' - ' + e!.label}
                                getOptionValue={e => e!.productId.toString()}
                                onChange={(e) => handleSetArticle(e!)}
                            />
                        </div>

                    </Form.Group>
                    <Form.Group unstackable widths={2}>
                        <div className="field">
                            <label>Quantité *</label>
                            {!validArticle && (
                                <CreatableSelect name="quantiy"
                                    isDisabled={disabled}
                                    isLoading={loadingInitial}
                                    options={tarifFournisseurs.filter(x => facture.fournisseur ? x.fournisseur == facture.fournisseur.fournisseurId : x.franchiseur == facture.company.id)}
                                    value={tarif}
                                    getOptionLabel={e => e?.quantite ? e?.quantite.toString() : `Affecter cette quantité`}
                                    getOptionValue={e => e?.quantite?.toString()}
                                    placeholder='Quantité'
                                    onChange={e => handleSetPrixByTypeandQuantite(e)}
                                    onCreateOption={handleChangeQuantity}
                                    formatCreateLabel={() => `Affecter cette quantité`}
                                />
                            )}
                        </div>
                        <div className="field">
                            <label style={{ marginTop: 32 }} color="grey">TVA : {article?.tvaRate}</label>
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={2}>
                        <div className="field">
                            <label>Prix unitaire</label>
                            <Input disabled={validArticle} placeholder="Prix unitaire" type='number' step="0.0001" min='0' value={unitPrice} onChange={handleSetInformation} />
                        </div>
                        <div className="field">
                            <label>Prix unitaire avec taxes</label>
                            <input name='unitPriceTaxes' disabled={true} value={unitPriceWithTaxes.toFixed(3)} />
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={2}>
                        <div className="field">
                            <label>Prix Total</label>
                            <input name='totalPrice' disabled={true} placeholder="Prix Total" value={totalPrice.toFixed(2)} />
                        </div>
                        <div className="field">
                            <label>Prix Total avec taxes</label>
                            <input name='totalPriceTaxes' disabled={true} value={totalPriceWithTaxes.toFixed(2)} />
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={3}>
                        <div className="field">
                            <label>Remise</label>
                            <input name='remise' min={0} max={100} defaultValue={0} value={remise} type="number" onChange={(e) => handleChangePriceRemise(e)} />
                        </div>
                        <Form.Field width={16}>
                            <label>Commentaire</label>
                            <input name='comment' type="text" onChange={(e) => setComment(e.target.value)} />
                        </Form.Field >
                    </Form.Group>
                    <Grid unstackable>
                        {article?.paperFormat &&
                            <Grid.Column width={8}>
                                <div className="field">
                                    <label color="grey">Format: <span style={{ fontSize: 14, color: 'grey' }}>{article?.paperFormat}</span></label>
                                </div>
                            </Grid.Column>
                        }
                        <Grid.Column width={8}>
                            <div className="field">
                                <label color="grey">Poids unitaire: <span style={{ fontSize: 14, color: 'grey' }}>{article?.weight} Kg</span></label>
                            </div>
                        </Grid.Column>
                        {article?.printing &&
                            <Grid.Column width={8}>
                                <div className="field">
                                    <label color="grey">Impression: <span style={{ fontSize: 14, color: 'grey' }}>{article?.printing}</span></label>
                                </div>
                            </Grid.Column>
                        }
                        {article?.paperWeight &&
                            <Grid.Column width={8}>
                                <div className="field">
                                    <label color="grey">Support: <span style={{ fontSize: 14, color: 'grey' }}>{article?.paperWeight}</span></label>
                                </div>
                            </Grid.Column>
                        }
                        {article?.deliveryDelay &&
                            <Grid.Column width={8}>
                                <div className="field">
                                    <label color="grey">Délais de livraison: <span style={{ fontSize: 14, color: 'grey' }}>{article?.deliveryDelay}</span></label>
                                </div>
                            </Grid.Column>
                        }
                    </Grid>
                    <Form.Group unstackable style={{ marginLeft: "70%" }} >
                        <Button.Group floated="right">
                            <Button onClick={() => setAddArticleMode(false)} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive disabled={addingArticle || invalid || validArticle || unitPrice.toString() == "NaN"} loading={addingArticle} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </Form.Group>
                </Form>
            )}
        />
    );
}
export default observer(AddFactureArticle);
