import { BaseStore } from "./baseStore";
import { observable, action, runInAction } from 'mobx';
import { IInventory, IInventoryCompany } from "../models/IInventory";
import agent from "../agent/agent";
import { toast } from "react-toastify";

export default class InvetoryStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
    }

    @observable loadingInitial = false;
    @observable adding = false;
    @observable downloading = false;
    @observable exporting = false;
    @observable loadingInventory = false;
    @observable loadingInventoryCompanies = false;
    @observable closing = false;
    @observable deleting = false;
    @observable showBtnConfirm = false;
    @observable uploading = false;
    @observable confirming = false;
    @observable InventoryList: IInventory[] = [];
    @observable Inventory: IInventory|null = null ;
    @observable InventoryCompanyList: IInventoryCompany[] = [];

    @action loadInventories = async () => {
        this.loadingInitial = true;
        this.showBtnConfirm = false;

        try {
            const inventories = await agent.inventoryAgent.list();
            runInAction(() => {
                this.InventoryList = inventories;
                this.loadingInitial = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingInitial = false;
            })
            console.log(e);
        }
    }

    @action loadCompanyInventories = async (id : number) => {
        this.loadingInventoryCompanies = true;
        this.showBtnConfirm = false;

        try {
            const inventories = await agent.inventoryAgent.listCompanies(id);
            runInAction(() => {
                this.InventoryCompanyList = inventories;
                this.loadingInventoryCompanies = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingInventoryCompanies = false;
            })
            console.log(e);
        }
    }

    @action addInventory = async (inv: Partial<IInventory>) => {
        this.adding = true;
        this.showBtnConfirm = false;

        try {
            const inventory = await agent.inventoryAgent.add(inv);

            runInAction(() => {
                this.InventoryList.push(inventory);
                this.adding = false;
            })
        } catch (e) {
            runInAction(() => {
                this.adding = false;
            })
            console.log(e);
        }
    }

    @action addInventoryCompany = async (inv: Partial<IInventoryCompany>) => {
        this.adding = true;
        this.showBtnConfirm = false;
        try {
            const inventory = await agent.inventoryAgent.addInventoryCompany(inv);

            runInAction(() => {
                this.InventoryCompanyList.push(inventory);
                this.adding = false;
            })
        } catch (e) {
            runInAction(() => {
                this.adding = false;
            })
            console.log(e);
        }
    }

    @action downloadInventoryCompanies = async (id: number, company :string) => {
        this.downloading = true;
        this.showBtnConfirm = false;
        try {
            await agent.inventoryAgent.downloadInventoryCompanies(id, company);
            runInAction(() => {
                this.downloading = false;
            })
        } catch (e) {
            runInAction(() => {
                this.downloading = false;
            })
            console.log(e);
        }
    }

    @action uploadInventoryCompanies = async (id: number, inventoryId:number, file: Blob) => {
        this.uploading = true;
        try {
            var inventoriesCompany = await agent.inventoryAgent.uploadInventoryCompanies(file, id, inventoryId);
            runInAction(() => {
                inventoriesCompany.map((inventoryCompany: IInventoryCompany) => {
                    var index = this.InventoryCompanyList.findIndex(x => x.id == inventoryCompany.id);
                    this.InventoryCompanyList[index].isVerified = inventoryCompany.isVerified;
                    if (index != -1)
                        this.InventoryCompanyList[index].inventoryProducts = inventoryCompany.inventoryProducts;
                    else
                    {
                        this.InventoryCompanyList.push(inventoryCompany)

                    }
                })
                this.showBtnConfirm = true;
                this.uploading = false;
                toast.success('Les produits ont été importés avec succés');
            })
        } catch (e) {
            runInAction(() => {
                this.uploading = false;
            })
            console.log(e);
        }
    }

    @action confirmInventoryProducts = async (inv: IInventoryCompany[], invId: number) => {
        this.confirming = true;
        try {
            const inventories = await agent.inventoryAgent.addInventoryProducts(inv, invId);
            runInAction(() => {
                this.InventoryCompanyList = inventories;
                toast.success('Les produits sont affectés avec succés');
                this.confirming = false;
                this.showBtnConfirm = false;
            })
        } catch (e) {
            runInAction(() => {
                this.confirming = false;
            })
            console.log(e);
        }
    }

    @action deleteInventoryCompanies = async (id: number) => {
        this.deleting = true;
        this.showBtnConfirm = false;
        try {
            await agent.inventoryAgent.deleteInventoryCompany(id);
            runInAction(() => {
                this.InventoryCompanyList = this.InventoryCompanyList.filter(x => x.id != id);
                this.deleting = false;
            })
        } catch (e) {
            runInAction(() => {
                this.deleting = false;
            })
            console.log(e);
        }
    }

    @action closeInventory = async (id: number, date:string) => {
        this.closing = true;
        this.showBtnConfirm = false;
        try {
            const inventory = await agent.inventoryAgent.closeInventory(id,date);
            runInAction(() => {
                this.Inventory = inventory;
                toast.success('Cet inventaire a été clôturé');
                this.closing = false;
            })
        } catch (e) {
            runInAction(() => {
                this.closing = false;
            })
            console.log(e);
        }
    }

    @action loadInventory = async (id: number) => {
        this.loadingInventory = true;
        this.showBtnConfirm = false;
        try {
            const inventory = await agent.inventoryAgent.getInventory(id);
            runInAction(() => {
                this.Inventory = inventory;
                this.loadingInventory = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingInventory = false;
            })
            console.log(e);
        }
    }

    @action exportInventory = async (id: number) => {
        this.exporting = true;
        try {
            await agent.inventoryAgent.exportInventory(id);
            runInAction(() => {
                this.exporting = false;
            })
        } catch (e) {
            runInAction(() => {
                this.exporting = false;
            })
            console.log(e);
        }
    }
}