import React, { useContext, useState } from 'react';
import { Table, Label, Button, Modal, Input, Icon, Header } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../app/store/baseStore';
import NoResult from '../commande/commandeDashboard/ErrorNoResult';
import { Link } from 'react-router-dom';
import { FaBoxOpen, FaFileInvoice } from 'react-icons/fa'
import { IExpeditionCommande } from '../../app/models/IExpedition';
import _ from 'lodash';
import { TbTruckDelivery } from 'react-icons/tb';
import DeleteOrderDispatchModal from './DeleteOrderDispatchModal';

const getStatus = (status: string) => {
    switch (status) {
        case 'EnAttente':
            return 'En Attente';
        case 'EnCours':
            return 'En Cours';
        case 'Termine':
            return 'Termine';
        case 'Expedie':
            return 'Expediée';
        case 'Livre':
            return 'Livrée';
        case 'Facture':
            return 'Facturée';
        case 'Retour':
            return 'Retour';
        default:
            return '';
    }
}
const numberFormat = new Intl.NumberFormat('FR-fr', {
    style: 'currency',
    currency: 'EUR',
    useGrouping: true,
});
const ListCommandeExpedier: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { expeditions, factureListExpeditions, submitting, deletingOrder } = baseStore.expeditionStore;
    const { openModal } = baseStore.modalStore;
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    //const [ids, setIds] = useState<number[]>([]);
    const [id, setId] = useState<number>();
    const [modalOpen, setModalOpen] = useState(false);
    const [date, setDate] = useState<string>(new Date().toISOString());

    //const handleCheck = (key: number, value: any) => {
    //    if (value.target.checked) {
    //        setIds([...ids, key]);
    //    } else {
    //        setIds(_.without(ids, key));
    //    }
    //}

    //const handleCheckAll = (value: any) => {
    //    var tab: number[] = [];
    //    if (value.target.checked) {
    //        expeditions.map((stock) => {
    //            if (stock.dispatchState === "Expedie" && stock.isBilled == false)
    //                tab.push(stock.id);
    //        });
    //        setIds(tab);
    //    } else {
    //        setIds([]);
    //    }
    //}

    //const getBackgroundColor = (key: number) => {
    //    return ids.includes(key) ? 'darkgrey' : 'white';
    //}

    if (expeditions.length < 1) return < NoResult name="Expedition" />
    else
        return (
            <>
                <Table compact='very' style={{ marginTop: '1em' }} >
                    <Table.Header>
                        <Table.Row style={{ textAlign: 'center' }}>
                            <Table.HeaderCell>Réference</Table.HeaderCell>
                            <Table.HeaderCell>Ref Commande</Table.HeaderCell>
                            <Table.HeaderCell>Montant HT</Table.HeaderCell>
                            <Table.HeaderCell>Montant TTC</Table.HeaderCell>
                            <Table.HeaderCell>Nom Client</Table.HeaderCell>
                            <Table.HeaderCell>N° Préparation</Table.HeaderCell>
                            <Table.HeaderCell>Date Expédition</Table.HeaderCell>
                            <Table.HeaderCell>Mode Appro</Table.HeaderCell>
                            <Table.HeaderCell>Etat</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {expeditions && expeditions.map((stock: IExpeditionCommande, key) => (
                            <Table.Row key={stock.id} style={{ textAlign: 'center' }}>
                                <Table.Cell>
                                    <Header size='small'><Label style={{ marginLeft: '1px', marginTop: '5px', marginBottom: '5px', backgroundColor: 'white', display:'ruby' }} as={Link} to={`/detailExpedition/${stock.id}`}>{stock!.reference}</Label></Header>
                                </Table.Cell>
                                <Table.Cell><a href={`/detailCommande/${stock.refOrder.substring(7)}`}>{stock.refOrder}</a></Table.Cell>
                                <Table.Cell>{numberFormat.format(stock.amountWithoutTaxes)}</Table.Cell>
                                <Table.Cell>{numberFormat.format(stock.amountWithTaxes)}</Table.Cell>
                                <Table.Cell>{stock.agencyName + "-" + stock.companyName}</Table.Cell>
                                <Table.Cell>{stock.orderPrepareItemId}</Table.Cell>
                                <Table.Cell>{new Date(stock.dispatchDate).toLocaleDateString('en-GB')}</Table.Cell>
                                <Table.Cell style={{ textAlign: 'center' }}>{stock.modeAppro == "STOCK" ? <FaBoxOpen style={{ fontSize: 24 }} /> : stock.modeAppro == "DIRECT" ? <TbTruckDelivery style={{ fontSize: 24 }} /> : null}</Table.Cell>
                                <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }}>{stock.isCanceled == false ? getStatus(stock.dispatchState) : "Annulé"}</Table.Cell>
                                <Table.Cell >
                                    {!stock.isCanceled &&
                                        <>
                                            {stock.typee == "BR" ? (
                                                <Button
                                                    color="orange"
                                                    cursor="pointer"
                                                    content=' Facturer'
                                                    loading={submitting}
                                                    style={{ width: 70, paddingRight: 5, paddingLeft: 5, fontSize: 11, display: (stock.dispatchState === "Expedie" && stock.isBilled == false) ? 'block' : 'none' }}
                                                    icon={<FaFileInvoice style={{ fontSize: 10 }} />}
                                                    onClick={
                                                        () => baseStore.expeditionStore.OrderDispatchFacture(stock.id)}
                                                />

                                            ) : (
                                                <Button
                                                    color="orange"
                                                    cursor="pointer"
                                                    content=' Facturer'
                                                    loading={submitting}
                                                    style={{ width: 70, paddingRight: 5, paddingLeft: 5, fontSize: 11, display: (stock.dispatchState === "Expedie" && stock.isBilled == false) ? 'block' : 'none' }}
                                                    icon={<FaFileInvoice style={{ fontSize: 10 }} />}
                                                    onClick={() => { setId(stock.id); setModalOpen(true) }}
                                                />
                                            )}
                                        </>
                                    }

                                    {/*<Button*/}
                                    {/*    color="green"*/}
                                    {/*    cursor="pointer"*/}
                                    {/*    content='Livrée'*/}
                                    {/*    style={{ width: 70, paddingRight: 5, paddingLeft: 5, fontSize: 11, display: stock.dispatchState === "Expedie" ? 'block' : 'none' }}*/}
                                    {/*    icon='dolly'*/}
                                    {/*    onClick={*/}
                                    {/*        () => baseStore.expeditionStore.editStatusOrderDispatch(stock.id)*/}
                                    {/*    }*/}
                                    {/*/>*/}
                                </Table.Cell>
                                <Table.Cell>
                                    {stock?.isBilled == false && stock.dispatchState != "Facture" ? (
                                        <Button.Group fluid widths={3}>
                                            <Button
                                                style={{ marginRight: '3%' }}
                                                name={stock.id}
                                                basic
                                                size="small"
                                                color='red'
                                                icon='trash'
                                                onClick={(e) => {
                                                    openModal(<DeleteOrderDispatchModal commande={stock} />)
                                                    setDeleteTarget(e.currentTarget.name)
                                                }}
                                                loading={deletingOrder && deleteTarget === stock.id.toString()}
                                            />
                                        </Button.Group>
                                    ) : (<></>
                                    )}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
                <Modal
                    open={modalOpen}
                    size='tiny'
                    closeOnEscape={true}
                    closeOnRootNodeClick={true}
                >
                    <Modal.Header icon='add' style={{ marginLeft: 20 }} >
                        Séléctionnez la date de facturation
                    </Modal.Header>
                    <Modal.Content style={{ textAlign: "center" }}>
                        <Input name='creationDate' onChange={(e: any) => setDate(e.target.value)} value={date} style={{ height: 50, borderRadius: 4, color: "green", width: 250, border: "1px solid #ccc" }} type='date' />
                    </Modal.Content>
                    <Modal.Actions style={{ display: 'grid' }}>
                        <div style={{ padding: 15 }}>
                            <Button.Group floated="right">
                                <Button
                                    type='button'
                                    floated='right'
                                    onClick={() => {
                                        setModalOpen(false)
                                    }}
                                >
                                    <Icon name='remove' /> Annuler
                                </Button>
                                <Button.Or text='Ou' />
                                <Button
                                    disabled={false}
                                    positive
                                    type='submit'
                                    floated='right'
                                    onClick={(e) => {
                                        setModalOpen(false)
                                        factureListExpeditions([id!], date)
                                    }}
                                >
                                    <Icon name='checkmark' /> Confirmer
                                </Button>
                            </Button.Group>
                        </div>
                    </Modal.Actions>
                </Modal>
            </>
        ); 
}
export default observer(ListCommandeExpedier);