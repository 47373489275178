import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Breadcrumbs from '../../dashboard/breadcrumbs';
import { Button, Grid, GridColumn, Label, Loader, Modal } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { FaFileExcel, FaPlusCircle } from 'react-icons/fa';
import { TbFence } from 'react-icons/tb';
import ListItemPlaceholder from '../../../app/common/form/ListItemPlaceholder';
import InfiniteScroll from 'react-infinite-scroller';
import FactureFilter from './FactureFilter';
import { BaseStoreContext } from '../../../app/store/baseStore';
import ListeFactures from './ListeFactures';
import CloturerByMonth from '../detailFacture/CloturerByMonth';
import { numberFormat } from '../../../app/common/options/IStyle';

const FactureDashboard: React.FC = () => {

    const baseStore = useContext(BaseStoreContext);
    const { loadingInitial, page, totalPages, setPage, loadListesFactures, totalHt, totalTtc } = baseStore.factureStore;
    const [loadingNext, setLoadingNext] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        loadListesFactures();
    }, []);

    const handleGetNext = () => {
        setLoadingNext(true);
        setPage(page + 1);
        loadListesFactures().then(() => setLoadingNext(false));
    }

    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Vente", root: "" },
                    { key: 1, name: "Factures", root: "/factureDashboard" },
                ]}
            />
            <Modal
                open={modalOpen}
                size='small'
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >
                <Modal.Header icon='add' >
                   Clôturer facture
                    <Button
                        style={{ height: 35 }}
                        type='button' icon='x' color='red'
                        floated='right'
                        onClick={() => { setModalOpen(false) }}
                    >
                    </Button>
                </Modal.Header>
                <Modal.Content>
                    <CloturerByMonth setModalOpen={setModalOpen} />
                </Modal.Content>
            </Modal>
            <Grid>
                <Grid.Row style={{ width: "100%" }}>
                    <Grid.Column style={{ padding: 0, margin: 0, marginRight: 20 }}>
                        <Button
                            size="small"
                            style={{ paddingTop: 0, paddingBottom: 0, height: 32 }}
                            floated="right"
                            content="Clôturer Facture"
                            color='green'
                            icon={(<TbFence style={{ marginRight: "10px" }} />)}
                            onClick={() => { setModalOpen(true) }}
                            
                        />
                        <Button
                            size="small"
                            style={{ paddingTop: 0, paddingBottom: 0, height: 32 }}
                            floated="right"
                            content="Ajouter Facture"
                            className="andi-btn filled warning"
                            icon={(<FaPlusCircle style={{ marginRight: "10px" }} />)}
                            as={Link}
                            to="/addFacture"
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ padding: 0, margin: "0 20px 0 20px" }}>
                    <FactureFilter />
                </Grid.Row>
                <GridColumn width={16} style={{ marginTop: 2 }}>
                    <Label>Total HT : {numberFormat.format(totalHt)}</Label>
                    <Label>Total TTC : {numberFormat.format(totalTtc)}</Label>
                    <Grid style={{ marginTop: 2 }}>
                        <GridColumn width={16}>
                            {loadingInitial && page === 0 ? <ListItemPlaceholder /> : (
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={handleGetNext}
                                    hasMore={!loadingNext && page + 1 < totalPages}
                                    initialLoad={false}
                                >
                                    <ListeFactures />
                                </InfiniteScroll>
                            )}
                        </GridColumn>
                    </Grid>
                </GridColumn>
                <Grid.Column width={16}>
                    <Loader active={loadingNext} />
                </Grid.Column>
            </Grid>
        </div>
        );
}
export default observer(FactureDashboard);
