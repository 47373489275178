import React, { useContext, useEffect, useState } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button, Grid, Input } from 'semantic-ui-react';
import TextInput from '../../../app/common/form/inputText';
import { observer } from 'mobx-react-lite';
import InputSelect from '../../../app/common/form/inputSelect';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { ICommande } from '../../../app/models/ICommande';
import { ITListStatutCommand, ITListDeliveryService, ITListPaymentMode } from '../../../app/common/options/IAchat';
import AsyncSelect from 'react-select/async';


interface IProps {
    editCommande: (commande: Partial<ICommande>) => void;
    commande: ICommande;
    submitting: boolean;
}
const CommandeEditform: React.FC<IProps> = ({ editCommande, commande, submitting }) => {
    const baseStore = useContext(BaseStoreContext);
    const [dateCommande, setDateCommande] = useState<any>(new Date(commande.orderDate).toISOString().split('T')[0]);
    const [searchResultsClient, setSearchResultsClient] = useState<any>(commande.agencyName);
    const [selectedLabelClient, setSelectedLabelClient] = useState<any>(commande.agencyName + "-" + commande.companyName);
    const handleChangeClient = (value: any) => {
        setSearchResultsClient(value.agencyName);
        setSelectedLabelClient(value!.agencyName + "-" + value!.companyName);
    }
    const handleFinalFormSubmit = (values: any) => {

        let newCommande = {
            ...values,
            orderDate: dateCommande,
            agencyName: searchResultsClient,
        };
        editCommande(newCommande);
    }
    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            initialValues={commande!}
            render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit} error>
                    <Grid columns={2} divided>
                        <Grid.Row>
                            <Grid.Column>
                                <h5>Client</h5>
                                <AsyncSelect
                                    name="form-field-name"
                                    noOptionsMessage={() => "Code introuvable"}
                                    cacheOptions
                                    defaultOptions
                                    placeholder={selectedLabelClient}
                                    value={searchResultsClient}
                                    getOptionLabel={e => e!.agencyName + "-" + e!.companyName}
                                    loadOptions={baseStore.clientStore.loadClientOptions}
                                    onChange={handleChangeClient}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <h5>Statut</h5>
                                <Form.Select name='orderState' component={InputSelect} options={ITListStatutCommand} value={commande!.orderState} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <h5>Transporteur</h5>
                                <Field name='deliveryService' component={InputSelect} options={ITListDeliveryService} value={commande!.deliveryService} />
                            </Grid.Column>
                            <Grid.Column>
                                <h5>Commentaire</h5>
                                <Field name='comment' component={TextInput} placeholder='Commentaire' value={commande!.comment} />
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column>
                                <h5>Mode de paiement</h5>
                                <Field name='paymentMode' component={InputSelect} options={ITListPaymentMode} value={commande!.paymentMode} />
                            </Grid.Column>
                            {commande.isManual &&
                                <Grid.Column>
                                    <h5>Date commande</h5>
                                    <Input
                                        type="date"
                                        name="orderDate"
                                        defaultValue={new Date(commande.orderDate).toISOString().split('T')[0]}
                                        onChange={(e) => setDateCommande(e.target.value)}
                                    />
                                </Grid.Column>
                            }

                        </Grid.Row>

                    </Grid>
                    <div style={{ marginTop: '20px' }}>
                        <Button.Group floated="right">
                            <Button disabled={submitting} onClick={() => baseStore.commandeStore.changeButtonState(false)} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </div>
                </Form>
            )}
        />
    );
};

export default observer(CommandeEditform);