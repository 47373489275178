import { Tab } from "semantic-ui-react"
import FactureDescription from "./FactureDescription"
import FactureArticle from "./FactureArticle"
import FactureClient from "./FactureClient"
import ReglementInformation from "../../Reglement/dashboard/ReglementInformation"
import ObjetLier from "./ObjetLier"
import { useContext } from "react"
import { BaseStoreContext } from "../../../app/store/baseStore"
import { observer } from "mobx-react-lite"
import { IFacture } from "../../../app/models/IFacture"


interface IProps {
    setActiveTab: (activeIndex: any) => void;
    Facture: IFacture;
}
const DevisContent: React.FC<IProps> = ({ setActiveTab, Facture }) => {
    const baseStore = useContext(BaseStoreContext);
    const panes = [
        { menuItem: 'Général', render: () => <FactureDescription /> },
        { menuItem: 'Article(s)', render: () => <FactureArticle /> },
        { menuItem: 'Informations', render: () => <FactureClient /> },
        Facture?.invoiceType == "Facture_Avoir" ? {}:{ menuItem: 'Reglement', render: () => <ReglementInformation /> },
        { menuItem: 'Objets Liés', render: () => <ObjetLier /> },
    ]
    return (
        <Tab
            menu={{ fluid: true, vertical: true }}
            menuPosition='right'
            activeIndex={baseStore.factureStore.activeTab}
            panes={panes}
            onTabChange={(e, data) => setActiveTab(data.activeIndex)}
        />
    )
}

export default observer(DevisContent);