import React, { useContext, useEffect, useState } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button } from 'semantic-ui-react';
import TextInput from '../../../app/common/form/inputText';
import { observer } from 'mobx-react-lite';
import { IArticle } from '../../../app/models/IArticle';
import { BaseStoreContext } from '../../../app/store/baseStore';
import Select from 'react-select/';
import CreatableSelect from 'react-select/creatable';

interface IProps {
    editArticle: (article: IArticle) => void;
    article: IArticle;
}
const ArticleServiceEdit: React.FC<IProps> = ({ editArticle, article }) => {
    const baseStore = useContext(BaseStoreContext);
    const { ITListTva, loadTvasActifs, defaultTva } = baseStore.tvaStore;
    const {  submittingDeliveryDelay, IDeliveryDelayList, loadDeliveryDelay } = baseStore.articleStore;
    useEffect(() => {

        loadTvasActifs();
        loadDeliveryDelay();
    }, [])

    const [tva, setTva] = useState<number | undefined>(defaultTva?.id);
    const [ref, setRef] = useState(article!.externalReference.substring(5, article!.externalReference.indexOf(article.reference)));
    const [deliveryDelay, setDeliveryDelay] = useState<string>(article.deliveryDelay);


    const handleChangeRef = (e: any) => {
        setRef(e.target.value)
    }

    const handleChangeTva = (e: any) => {
        setTva(e);
    }

    const handleChangeDeliveryDelay = (inputValue: string) => {
        setTimeout(() => {
            setDeliveryDelay(inputValue);
        }, 1000);

    }
    const handleFinalFormSubmit = (values: any) => {

        let newArticle: IArticle = {
            ...values,
            ExternalReference: ref,
            tva: tva || defaultTva!.id,
            deliveryDelay: deliveryDelay,
        };
        editArticle(newArticle);
    }

    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            initialValues={article!}
            render={({ handleSubmit, submitting }) => (
                <Form onSubmit={handleSubmit} error>
                    <Form.Group unstackable widths={3}>
                        <div className="field">
                            <label>Libellé </label>
                            <Field name='label' component={TextInput} placeholder="libelle" initialValue={article!.label} />
                        </div>
                        <div className="field">
                            <label>Référence centrale </label>
                            <input type="text" defaultValue={article!.externalReference.substring(5, article!.externalReference.indexOf(article.reference))}
                                onChange={handleChangeRef} required />
                        </div>
                        <div className="field">
                            <label>Code comptable </label>
                            <Field name='codeComptable' component={TextInput} type="number" placeholder="Code comptable" initialValue={article!.codeComptable} />
                        </div>
                    </Form.Group>

                    <Form.Group unstackable widths={3}>
                        <div className="field">
                            <label>Délai de livraison (Jours) <a style={{ color: 'red', fontSize: 17 }}>*</a></label>
                            <CreatableSelect name="delaiLivraison"
                                isDisabled={submittingDeliveryDelay}
                                isLoading={submittingDeliveryDelay}
                                options={IDeliveryDelayList}
                                value={{ label: deliveryDelay, value: deliveryDelay }}
                                placeholder='Délai de livraison'
                                onChange={(deliveryDelay: any) => {
                                    setDeliveryDelay(deliveryDelay.value);
                                }}
                                onCreateOption={handleChangeDeliveryDelay}
                                formatCreateLabel={() => `Créer`}
                            />
                        </div>
                        <div className="field">
                            <label>Tva</label>
                            <Select
                                getOptionLabel={e => e.text}
                                getOptionValue={e => e.value.toString()}
                                options={ITListTva}
                                name='tva'
                                placeholder={defaultTva?.nom + ' - ' + defaultTva?.taux + '%'}
                                onChange={(e) => handleChangeTva(e!.value)}
                                defaultValue={ITListTva.find(x => x.value == tva)}
                            />
                        </div>
                    </Form.Group>


                    <div style={{ marginTop: '20px' }}>
                        <Button.Group floated="right">
                            <Button disabled={submitting} onClick={() => { baseStore.articleStore.changeButtonState(!baseStore.articleStore.editArticleMode) }} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive disabled={submitting} loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </div>
                </Form>
            )}
        />
    );
};

export default observer(ArticleServiceEdit);