import React, { useContext, Fragment } from 'react';
import { Item, Label } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import FournisseurListItem from './FounisseurListItem';
import AucunFournisseur from './AucunFournisseur';

const ListFournisseur: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { fournisseurByFabricant } = baseStore.fournisseurStore;
    if (fournisseurByFabricant.length < 1) return < AucunFournisseur />
    return (
        <Item.Group divided>
            <Fragment>
                {fournisseurByFabricant.map(([group, fournisseurs]) => (
                    <Fragment key={group} >
                        <Item.Group>
                            {fournisseurs.map((fournisseur) => (
                                <FournisseurListItem key={fournisseur.fournisseurId} fournisseur={fournisseur} />
                            ))}
                        </Item.Group>
                    </Fragment>
                ))}
            </Fragment>
        </Item.Group>
    );
}

export default observer(ListFournisseur);