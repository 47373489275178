import React, {useContext, useState } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button, Grid } from 'semantic-ui-react';
import TextInput from '../../../app/common/form/inputText';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import AsyncSelect from 'react-select/async';

interface IProps {
    match: number
}
const AddAdresseClient: React.FC<IProps> = ({ match }) => {
    const baseRepo = useContext(BaseStoreContext);
    const { create } = baseRepo.adresseStore;
    const [country, setCountry] = useState<any>("FR");
    const [codePostal, setCodePostal] = useState(null);
    const [city, setCity] = useState<any>();
    const handleChangeCountry = (value: any) => {
        setCountry(value.iso);
    }
    const handleChangedeliveryPostalCode = (value: any) => {
        setCodePostal(value.codePostal);
        setCity(value.city);
    }
    const handleChangePostalCode = (e: any, result: any) => {
        setCodePostal(result.value);
    }
    const handleChangeCity = (e: any, result: any) => {
        setCity(result.value);
    }
    const handleFinalFormSubmit = (values: any) => {
        let newAdresse = {
            ...values,
            company: match,
            country: country,
            city: city,
            postalCode: codePostal,

        };
        create(newAdresse);
        baseRepo.clientStore.changeButtonState(false)
    }
    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            render={({ handleSubmit, invalid, pristine, submitting }) => (
                <Form onSubmit={handleSubmit} error>
                    <Grid columns={2} divided>
                        <Grid.Column>
                            <h5 >Pays</h5>
                            <AsyncSelect
                                name="form-field-name"
                                noOptionsMessage={() => "Code introuvable"}
                                cacheOptions
                                defaultOptions
                                placeholder={country}
                                value={country}
                                getOptionLabel={e => e!.pays + '(' + e!.iso + ')'}
                                getOptionValue={e => e!.iso}
                                loadOptions={baseRepo.countryCodeStore.getCountryCodes}
                                onChange={handleChangeCountry}

                            />
                        </Grid.Column>
                 
                        {country == "FR" ?
                            <Grid.Column>
                                <h5>Code postal </h5>
                                <AsyncSelect
                                    name="form-field-name"
                                    noOptionsMessage={() => "Code introuvable"}
                                    cacheOptions
                                    defaultOptions
                                    placeholder={codePostal ?? "Code postal" }
                                    value={codePostal}
                                    getOptionLabel={e => e!.city + '(' + e!.codePostal + ')'}
                                    getOptionValue={e => e!.codePostal}
                                    loadOptions={baseRepo.cityCodeStore.getCityCodes}
                                    onChange={handleChangedeliveryPostalCode}
                                />
                            </Grid.Column> :
                            <Grid.Column>
                                <h5 >Code Postal</h5>
                                <Form.Input name='postalCode' component={TextInput} placeholder="Code postal" type='text' onChange={handleChangePostalCode} />
                            </Grid.Column>
                        }
                        <Grid.Column>
                            <h5 >Ville </h5>
                            <Form.Input name='city' value={city} placeholder="Ville " onChange={handleChangeCity} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>Adresse </h5>
                            <Field name='address' component={TextInput} placeholder="Adresse" />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>Adresse Complement </h5>
                            <Field name='additionalAddress' component={TextInput} placeholder="Adresse Complement" />
                        </Grid.Column>
                        <Grid.Column>
                            <h5 >Societé</h5>
                            <Field name='companyName' component={TextInput} placeholder="Societé"/>
                        </Grid.Column>
                        <Grid.Column>
                            <h5 >Téléphone</h5>
                            <Field name='phone' component={TextInput} type='tel' placeholder="Téléphone"/>
                        </Grid.Column>
                        <Grid.Column>
                            <h5 >Email</h5>
                            <Field name='email' component={TextInput} placeholder="Email" />
                        </Grid.Column>
                    </Grid>
                    <div style={{ marginTop: '30px' }}>
                        <Button.Group floated="right">
                            <Button disabled={submitting} onClick={() => { baseRepo.clientStore.changeButtonState(!baseRepo.clientStore.editClientMode) }} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive disabled={submitting || invalid || pristine} loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </div>
                </Form >
            )}
        />
    );
};

export default observer(AddAdresseClient);