import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Breadcrumbs from '../../dashboard/breadcrumbs';
import { Button, Dropdown, Form, Grid, GridColumn } from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroller';
import ListItemPlaceholder from '../../../app/common/form/ListItemPlaceholder';
import { BaseStoreContext } from '../../../app/store/baseStore';
import ListeAchatGroupees from './ListeAchatGroupees';
import { FaPlusCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import OffreFilter from './OffreFilter';

const AchatGroupeeDashboard: React.FC = () => {

    const baseStore = useContext(BaseStoreContext);
    const { loadingInitial, page, totalPages, setPage, loadSpecialOffers, setLimit, clearListOffer, offerCount } = baseStore.specialOfferStore;
    const [loadingNext, setLoadingNext] = useState(false);

    useEffect(() => {
        loadSpecialOffers();
    }, []);

    const handleGetNext = () => {
        setLoadingNext(true);
        setPage(page + 1);
        loadSpecialOffers().then(() => setLoadingNext(false));
    }
    const options = [
        { key: 1, text: '10 Offres', value: 10 },
        { key: 2, text: '50 Offres', value: 50 },
        { key: 3, text: '100 Offres', value: 100 },
    ]

    const handleChangeSelect = (e: any, result: any) => {
        setPage(0);
        setLimit(result.value);
        clearListOffer().then(() => {
            loadSpecialOffers();
        });
    }

    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Achat Groupés", root: "" },
                    { key: 1, name: "Dashboard", root: "/offreDashboard" },
                ]}
            />
            <Grid>
                <GridColumn width={12} style={{ marginTop: 2 }}>
                    <Grid style={{ marginTop: 2 }}>
                        

                        <GridColumn width={16}>
                            <Grid style={{ marginTop: 2 }}>

                                <Grid.Row columns={2} >
                                    <Grid.Column style={{ padding: 0, margin: 0 }}>
                                        <h6>Afficher: &nbsp; <Dropdown button floating labeled options={options} defaultValue={options[0].value} onChange={(e, data) => handleChangeSelect(e, data)} />
                                        </h6>
                                    </Grid.Column>
                                    <Grid.Column style={{ padding: 0, margin: 0, left: '38%' }}>
                                        <h6> Total : {offerCount}</h6>
                                        </Grid.Column>

                                </Grid.Row>

                                </Grid>
                            
                            {loadingInitial && page === 0 ? <ListItemPlaceholder /> : (
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={handleGetNext}
                                    hasMore={!loadingNext && page + 1 < totalPages}
                                    initialLoad={false}
                                >
                                    <ListeAchatGroupees />
                                </InfiniteScroll>
                            )}
                        </GridColumn>
                    </Grid>
                </GridColumn>
                <Grid.Column width={4}>
                    <Grid.Column width={16}>
                    </Grid.Column>
                    <Button
                        style={{ width: "100%" }}
                        content="Ajouter offre"
                        className="andi-btn filled warning"
                        icon={(<FaPlusCircle style={{ marginRight: "10px", fontSize: "20px" }} />)}
                        as={Link}
                        to="/addAchatGroupee"
                    />
                    <OffreFilter />
                </Grid.Column>
            </Grid>
        </div>
        );
}
export default observer(AchatGroupeeDashboard);