import { useContext } from 'react';
import { Tab, Header, List, Button, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import NotFound from '../../../app/layout/NoFound';
import FranchiseurEditForm from './FranchiseurEditform';
import { useEffect } from 'react';
import { MdArticle, MdOutlineArticle, MdMonetizationOn } from 'react-icons/md';

const numberFormat = new Intl.NumberFormat('FR-fr', {
    style: 'currency',
    currency: 'EUR',
    currencySign: 'accounting'
});

const FranchiseurDescription = () => {

    const baseRepo = useContext(BaseStoreContext);
    const { franchiseur, editFranchiseur } = baseRepo.franchiseurStore;
    const ccount = 0;
    useEffect(() => {
        baseRepo.franchiseurStore.changeButtonState(false);
    }, [])

    if (!franchiseur) {
        return <NotFound />
    }
    else {
        return (
            <Tab.Pane>
                <Grid>
                    <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                        <Header floated='left' icon='code' content={`${franchiseur!.agencyName}`} />
                        <Button
                            onClick={() => { baseRepo.franchiseurStore.changeButtonState(!baseRepo.franchiseurStore.editFranchiseurMode) }}
                            floated='right'
                            size='small'
                            content={baseRepo.franchiseurStore.editFranchiseurMode ? 'Annuler' : 'Modifier'}
                        />
                    </Grid.Column>


                    <Grid.Column width={16}>
                        {baseRepo.franchiseurStore.editFranchiseurMode ? (
                            <FranchiseurEditForm editFranchiseur={editFranchiseur} franchiseur={franchiseur!} />
                        ) : (
                            <Grid columns={2} divided>
                                <Grid>
                                    <Grid.Row columns={3} className="ui grid stackable" style={{ backgroundColor: "rgba(255,255,255,.7)", borderRadius: 10, paddingBottom: 0, marginBottom:10 }}>
                                        <Grid.Column style={{ borderRight: '1px solid #eee', margin: 0 }}>
                                            <MdArticle style={{ background: '#e32c26', height: 35, width: 35, padding: 7, marginRight: 10, borderRadius: 50, color: "white", float: "left" }} />
                                            <span style={{ paddingTop: 5, display: 'block', fontSize: 17 }}>Commandes: <br /><b style={{ paddingLeft: 5, float: 'right' }}>{franchiseur!.totalCmd} €</b></span>
                                        </Grid.Column>
                                        <Grid.Column style={{ borderRight: '1px solid #eee', margin: 0 }}>
                                            <MdOutlineArticle style={{ background: '#ff9307', height: 35, width: 35, padding: 7, marginRight: 10, borderRadius: 50, color: "white", float: "left" }} />
                                            <span style={{ paddingTop: 5, display: 'block', fontSize: 17 }}>Factures: <br /><b style={{ paddingLeft: 5, float: 'right' }}>{franchiseur!.totalInvoices} €</b></span>
                                        </Grid.Column>
                                        <Grid.Column style={{ margin: 0 }}>
                                            <MdMonetizationOn style={{ background: '#1757b8', height: 35, width: 35, padding: 7, marginRight: 10, borderRadius: 50, color: "white", float: "left" }} />
                                            <span style={{ paddingTop: 5, display: 'block', fontSize: 17 }}>En Cours: <br /><b style={{ paddingLeft: 5, float: 'right' }}>{franchiseur!.totalInvoiceNotPayed} €</b></span>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Column width={8} style={{ borderTop: '1px solid rgb(238, 238, 238)' }}>
                                        <h5>{franchiseur!.companyName}</h5>
                                        {franchiseur!.invoiceAddress1}
                                        {franchiseur!.invoiceAddress2}
                                        <p>{franchiseur!.invoicePostalCode} {franchiseur!.invoiceCity} {franchiseur!.invoiceCountry} </p>
                                    </Grid.Column>
                                    <Grid.Column width={8} style={{ borderTop: '1px solid rgb(238, 238, 238)' }}>
                                        <List style={{ marginTop: '5px' }}>
                                            <List.Item>
                                                <List.Icon name='user' />
                                                <List.Content>&nbsp;{franchiseur.deliverySalutations} {franchiseur.deliveryLastName} {franchiseur.deliveryFirstName} </List.Content>
                                            </List.Item>
                                            <List.Item>
                                                    <List.Icon name='phone' />
                                                    <List.Content>&nbsp;{franchiseur.deliveryPhone}</List.Content>
                                            </List.Item>
                                            <List.Item>
                                                <List.Icon name='mail' />
                                                <List.Content>{franchiseur.invoiceEmails?.split(';').map(x => <>{x}<br /></>)}</List.Content>
                                            </List.Item>
                                        </List>
                                    </Grid.Column>

                                    <Grid.Column width={16}>
                                        <hr />
                                    </Grid.Column>
                                    <Grid.Column width={16}>
                                        <h5> Zone d'intervention: &nbsp;{franchiseur!.interventionZone}</h5>
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <h5>Capital: {franchiseur!.capitalValue} €</h5>
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <h5> Pack d'Ouverture: {franchiseur!.openingPackAmount ? numberFormat.format(parseFloat(franchiseur!.openingPackAmount.toString().replace(",", "."))) : null}</h5>
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <h5>RCS: &nbsp;{franchiseur!.rcs}</h5>
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <h5> Siret: &nbsp;{franchiseur!.siretNumber}</h5>
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <h5>  Tva Intra : {franchiseur!.europeanTvaNumber}</h5>
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <h5> Code Ape: {franchiseur!.apeCode}</h5>
                                    </Grid.Column>
                                </Grid>
                                <Grid.Row style={{ display: 'none' }}>
                                    <Grid.Column>
                                        <List>
                                            <List.Item>
                                                <List.Icon name='wordpress forms' />
                                                <List.Content><h5>  Site Web : &nbsp;{franchiseur!.website}</h5></List.Content>
                                            </List.Item>
                                        </List>
                                    </Grid.Column>
                                    <Grid.Column style={{ padding: "0.5rem" }}>
                                        <List>
                                            <List.Item>
                                                <List.Icon name='sort numeric up' />
                                                <List.Content><h5> Numéro d'agrement : &nbsp;{franchiseur!.agreementNumber}</h5></List.Content>
                                            </List.Item>
                                        </List>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        )}
                    </Grid.Column>
                </Grid>
            </Tab.Pane>
        );
    }
}
export default observer(FranchiseurDescription);