import { useContext } from 'react';
import { Tab, Header, Button, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import NotFound from '../../../app/layout/NoFound';
import Specificationclienteditform from '../clientDetails/specificationclienteditform'
import FormGridColumn from '../../../app/common/form/FormGridColumn';
const Specification = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { client, editClientspecification, submitting } = baseRepo.clientStore;
    if (!client) {
        return <NotFound />
    }
    else {
        return (
            <Tab.Pane>
                <Grid>
                    <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                        <Header floated='left' icon='address card' content={`Spécification ${client!.companyName}`} />
                        <Button
                            onClick={() => { baseRepo.clientStore.changeButtonState(!baseRepo.clientStore.editClientMode) }}
                            floated='right'
                            basic
                            content={baseRepo.clientStore.editClientMode ? 'Annuler' : 'Modifier'}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        {baseRepo.clientStore.editClientMode ? (
                            <Specificationclienteditform editClient={editClientspecification} client={client!} loading={submitting} />
                        ) : (
                            <Grid className="ui stackable">
                                <Grid.Row>
                                    <FormGridColumn icon="calendar times" header="Jours de fermeture" content={client!.deliveryClosingDays} />
                                    <FormGridColumn icon="calendar times" header="Horaires d'ouverture" content={client!.deliveryOpeningHours} />
                                    <FormGridColumn icon="qrcode" header="Digit code" content={client!.deliveryDigiCode} />
                                    <FormGridColumn icon="qrcode" header="Interphone" content={client!.deliveryInterphone} />
                                </Grid.Row>
                            </Grid>
                        )}
                    </Grid.Column>
                </Grid>
            </Tab.Pane>
        );
    }
}
export default observer(Specification);