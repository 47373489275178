import React, { useContext } from 'react';
import { Button, Container, Header, Icon } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';

interface IProps {
    commandes: number[];
    setIds: Function;
}


const FacturerCommandesModal: React.FC<IProps> = ({ commandes, setIds }) => {
    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    const { factureListOrders, setPredicate } = baseStore.commandeStore;



    return (
        <Container textAlign='center'>
            <Header icon='file alternate' content='Facturer' style={{ marginLeft: 20 }} />
            <p>
                Comment voulez vous facturer ces {commandes.length} commandes ?
            </p>

            <Button attached='left' size='large'
                onClick={() => {
                    factureListOrders(commandes, "commande").then(() => {
                        setIds([]);
                        closeModal();
                        setPredicate('validated', 'true');
                    })
                }}>
                <Icon name='shopping cart' />
                Commande
            </Button>

            <Button attached='right' size='large'
                onClick={() => {
                    factureListOrders(commandes, "client").then(() => {
                        setIds([]);
                        closeModal();
                        setPredicate('validated', 'true');
                    })
                }}>
                <Icon name='user' />
                par Client
            </Button>
            
        </Container>
        );
}
export default FacturerCommandesModal;