import React, { useContext, useEffect, useState } from 'react';
import { GridColumn, Grid, Loader, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import ListCommandes from './ListCommandes';
import CommandeFilter from './CommandeFilter';
import ListItemPlaceholder from '../../../app/common/form/ListItemPlaceholder';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';
import { FaPlusCircle } from 'react-icons/fa';
import Breadcrumbs from '../../dashboard/breadcrumbs';

const CommandesDashboard: React.FC = () => {

    const baseStore = useContext(BaseStoreContext);
    const { loadCommandes, loadingInitial, page, totalPages, setPage } = baseStore.commandeStore;
    const [loadingNext, setLoadingNext] = useState(false);

    const handleGetNext = () => {
        setLoadingNext(true);
        setPage(page + 1);
        loadCommandes().then(() => setLoadingNext(false));
    }
    useEffect(() => {
        loadCommandes();
    }, []);

    //if (loadingInitial) return <Loading content="Chargement des commandes ..." />

    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Vente", root: "" },
                    { key: 1, name: "Commandes", root: "/commandeDashboard" },
                ]}
            />
            <Grid>
                <Grid.Row style={{ width: "100%" }}>
                    <Grid.Column style={{ padding: 0, margin: 0, marginRight:20 }}>
                        <Button
                            size="small"
                            style={{ paddingTop: 0, paddingBottom: 0, height:32 }}
                            floated="right"
                            content="Ajouter Commande"
                            className="andi-btn filled warning"
                            icon={(<FaPlusCircle style={{ marginRight: "10px"}} />)}
                            as={Link}
                            to="/addCommande"
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row  style={{ padding: 0, margin: "0 20px 0 20px" }}>
                    <CommandeFilter />
                </Grid.Row>
                <GridColumn width={16} style={{ marginTop: 2 }}>
                    <Grid style={{ marginTop: 2 }}>
                        <GridColumn width={16}>
                            {loadingInitial && page === 0 ? <ListItemPlaceholder /> : (
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={handleGetNext}
                                    hasMore={!loadingNext && page + 1 < totalPages}
                                    initialLoad={false}
                                >
                                    <ListCommandes />
                                </InfiniteScroll>
                            )}
                        </GridColumn>
                    </Grid>
                </GridColumn>

                <Grid.Column width={16}>
                    <Loader active={loadingNext} />
                </Grid.Column>
            </Grid>
        </div>
    );
}
export default observer(CommandesDashboard);