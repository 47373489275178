import React, { useState, useContext, useEffect } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button, Grid } from 'semantic-ui-react';
import TextInput from '../../../app/common/form/inputText';
import { observer } from 'mobx-react-lite';
import { IFranchiseur } from '../../../app/models/IFranchiseur';
import { IInterfaceList, ITListJoursFermeture } from '../../../app/common/options/IAchat';
import { BaseStoreContext } from '../../../app/store/baseStore';
import Select from 'react-select';

interface IProps {
    editFranchiseur: (profile: Partial<IFranchiseur>) => void;
    franchiseur: IFranchiseur;
    loading: boolean
}

const Specificationfranchiseureditform: React.FC<IProps> = ({ editFranchiseur, franchiseur, loading }) => {
    const baseStore = useContext(BaseStoreContext);
    const [days, setDays] = useState<string[]>([]);

    const handleChange = (e: any, result: any) => {
        var days: string[] = [];
        e.map((item: any) => (
            days.push(item.value)
        ))
        setDays(days);
    }

    useEffect(() => {
        var d = [];

        for (var i = 0; i < ITListJoursFermeture.length; i++) {
            if (franchiseur.deliveryClosingDays?.includes(ITListJoursFermeture[i].text))
                d.push(ITListJoursFermeture[i].value)
        }
        setDays(d);
    }, [])
    const daysList = (num: IInterfaceList) => {
        if (franchiseur.deliveryClosingDays?.includes(num.text)) {
            return num
        }
        return false;
    }
    const handleFinalFormSubmit = (values: any) => {
        var stringDays = "";
        if (days.length == 0)
            stringDays = "NR";
        else {
            for (let i = 0; i < days.length; i++) {
                var text = days[i];
                if (i == days.length - 1 && days.length > 1)
                    text = " et " + text;
                if (i > 0 && i < days.length - 1)
                    text = " , " + text;
                stringDays += text;
            }
        }
        let newFranchiseur: IFranchiseur = {
            ...values,
            deliveryClosingDays: stringDays,
        };
        editFranchiseur(newFranchiseur);
    }
    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            initialValues={franchiseur!}
            render={({ handleSubmit, invalid, submitting }) => (
                <Form onSubmit={handleSubmit} error>
                    <Grid columns={2}>
                        <Grid.Column>
                            <h5>Jours de fermeture</h5>
                            <Select
                                isMulti
                                placeholder={'Choisisez les jours ..'}
                                defaultValue={ITListJoursFermeture.filter((e) => daysList(e))}
                                options={ITListJoursFermeture}
                                getOptionLabel={e => e!.text}
                                getOptionValue={e => e!.value}
                                classNamePrefix="select"
                                onChange={handleChange}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>Horaires d'ouverture</h5>
                            <Field name='deliveryOpeningHours' component={TextInput} placeholder="Horaires d'ouverture" value={franchiseur!.deliveryOpeningHours} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>Digicode</h5>
                            <Field name='deliveryDigiCode' component={TextInput} placeholder='Digicode' type='text' value={franchiseur!.deliveryDigiCode} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>Interphone</h5>
                            <Field name='deliveryInterphone' component={TextInput} placeholder='Interphone' type='text' value={franchiseur!.deliveryInterphone} />
                        </Grid.Column>
                    </Grid>
                    <Button.Group floated="right" style={{marginTop:20}}>
                        <Button disabled={submitting} onClick={() => { baseStore.franchiseurStore.changeButtonState(!baseStore.franchiseurStore.editFranchiseurMode) }} floated='right' type='button'>Annuler</Button>
                        <Button.Or />
                        <Button positive disabled={submitting || invalid} loading={loading} floated='right' type='submit'>Confirmer</Button>
                    </Button.Group>
                </Form>
            )}
        />
    );
};

export default observer(Specificationfranchiseureditform);