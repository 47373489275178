import React, { useContext, useEffect, useState } from 'react';
import { GridColumn, Grid, Loader, Label } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import InfiniteScroll from 'react-infinite-scroller';
import { BaseStoreContext } from '../../app/store/baseStore';
import ListItemPlaceholder from '../../app/common/form/ListItemPlaceholder';
import Breadcrumbs from '../dashboard/breadcrumbs';
import ListPreparation from './ListPreparation';
import PreparationFilter from './PreparationFilter';

const PreparationDashboard: React.FC = () => {

    const baseStore = useContext(BaseStoreContext);
    const { loadPreparationList, loadingInitial, page, totalPages, setPage } = baseStore.preparationStore;
    const [loadingNext, setLoadingNext] = useState(false);

    const handleGetNext = () => {
        setLoadingNext(true);
        setPage(page + 1);
        loadPreparationList().then(() => setLoadingNext(false));
    }

    useEffect(() => {
        loadPreparationList();
    }, []);

    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Preparation", root: "" },
                    { key: 1, name: "Preparation de commande", root: "/preparationDashboard" },
                ]}
            />
            <Grid>
                <Grid.Row style={{ padding: 0, margin: "10px 20px 0 20px" }}>
                    <PreparationFilter />
                </Grid.Row>
                <GridColumn width={16} >
                    {loadingInitial && page === 0 ? <ListItemPlaceholder /> : (
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={handleGetNext}
                            hasMore={!loadingNext && page + 1 < totalPages}
                            initialLoad={false}
                        >
                            <ListPreparation />
                        </InfiniteScroll>
                    )}
                </GridColumn>
                <Grid.Column width={16} style={{ margin: 20 }}>
                    <Loader active={loadingNext} />
                </Grid.Column>
            </Grid>
        </div>
    );
}
export default observer(PreparationDashboard);