import React, { useState } from 'react';
import { Card, Popup, Header } from 'semantic-ui-react';
import { IProductItem } from '../../../app/models/IArticle';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

interface IProps {
    article: IProductItem
}
const ArticleItem: React.FC<IProps> = ({ article }) => {
    const [isHover, setIsHover] = useState(false);
    const handleMouseEnter = () => {
        setIsHover(true);
    };
    const handleMouseLeave = () => {
        setIsHover(false);
    };

    return (
        <Card.Group >
            <Popup key={article.productId}
                trigger={
                    <Card as={Link} to={`/detailArticle/${article.productId}`} >
                        <a style={{ display: 'block', width: '100%', position: 'relative', textDecoration: 'none', overflow: 'hidden',paddingTop : 32, color: "#fd7e2d" }}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}>
                            <div
                                style={{
                                    height: 180,
                                    width: '100%',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center center',
                                    backgroundImage: "url(" + article.uri + ")",
                                    overflow: 'hidden', position: 'relative'
                                }}>
                            </div>
                        </a>                
                        {article.companyId != 0 ? <div className="tagfranchiseur" ><span>{article.franchiseurLibelle}</span></div> : <div className="tagfranchiseur" ><span>AUCUN RESEAU</span></div>}
                        <Card.Content extra>
                            {!article.isActivated && (<div className="ribbonRight" ><span>Non actif</span></div>)}
                            {article.isPack && (<div className="ribbonLeft r2" ><span>Pack</span></div>)}
                            {article.customizationType != 'None' && ((<div className="personnalisable" ><span>Personnalisable</span></div>))}
                            {article.belongToOpeningPack && ((<div className="openingPack" ><span>Pack d'ouverture</span></div>))}                  
                            <Card.Header
                                as={Link} to={`/detailArticle/${article.productId}`}
                                style={{ fontSize: 17 }} textAlign='center'>{article.label}</Card.Header>
                            <Card.Meta textAlign='center'>
                                <div style={{ paddingTop:'5px' }}>
                                    <Header sub > {article.reference}</Header>
                                </div>
                                <div></div>
                            </Card.Meta>
                        </Card.Content>
                    </Card>
                }
                content={article.label}
                hideOnScroll
                mouseEnterDelay={200}
                mouseLeaveDelay={600}
            />
        </Card.Group>
    );
}
export default observer(ArticleItem);