import React, { useContext, useEffect, useState } from 'react';
import { Header, Grid, Segment, Item, Reveal, Button, Input, Label } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import Loading from '../../../app/layout/Loading';
import { VscFilePdf } from 'react-icons/vsc';
import OrderFournisseurModal from './OrderFournisseurModal';
import { ICommande, IOrderLine, ITVA } from '../../../app/models/ICommande';
import BonDePreparation from '../../preparationCommande/BonDePreparation';
import { FiEdit, FiCheckSquare } from 'react-icons/fi';
import { MdOutlineCancelPresentation } from 'react-icons/md';
import { toast } from 'react-toastify';
import CancelCommandeModal from '../commandeDashboard/CancelCommandeModal';
import { checkDeliveryState } from './CommandeAticle';

const style = {
    margin: 0,
    padding: 0,
    paddingLeft: 5,
}
const styleTitle = {
    margin: 0,
    padding: 0,
    paddingLeft: 5,
    fontWeight: "bold"
}

const setPaiementType = (status: string) => {
    switch (status) {
        case '31':
            return 'Carte Bancaire';
        case '03':
            return 'Chèque de banque';
        case '01':
            return 'Espèce';
        case '15':
            return 'Prélèvement Bancaire';
        case '11':
            return 'Virement Bancaire';
        default:
            return status;
    }
}
const getColor = (status: string) => {
    switch (status) {
        case 'Payé':
            return 'green';
        case 'Impayé':
            return 'red';
        case 'Partiellement payé':
            return 'blue';
        default:
            return 'black';
    }
}
const getDisplay = (status: string) => {
    switch (status) {

        case 'Cancelled':
            return false;
        case 'WaitingValidation':
            return false;
        case 'WaitingToBeSentToCrm':
            return false;
        case 'Rejected':
            return false;
        case 'WaitingForOnlinePayment':
            return false;
        default:
            return true;
    }
}
const numberFormat = new Intl.NumberFormat('FR-fr', {
    style: 'currency',
    currency: 'EUR',
    useGrouping: true,
});

interface IProps {
    id: string,
    loading: boolean,
    enAttente: (id: number) => Promise<void>;
    valide: (id: number) => Promise<void>;
}
const CommandeHeader: React.FC<IProps> = ({ id, loading, enAttente, valide }) => {
    const baseRepo = useContext(BaseStoreContext);
    const { openModal } = baseRepo.modalStore;
    const { submitting } = baseRepo.preparationStore;
    const { loadCommande, commande, generatePDF, generateOpeningPack, dispatchOrder, articleWithModeAppro, editDeliveryCosts, loadBtnCancel, dupliquer, loadingUploadProforma, UploadProforma } = baseRepo.commandeStore;
    const [editDeliveryCost, setEditDeliveryCost] = useState<boolean>(false);
    const [deliveryCost, setDeliveryCost] = useState<number>();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        loadCommande(id);
    }, [])

    const checkStatus = (lines: IOrderLine[]) => {
        if (lines.filter(x => x.isService == true).length == lines.length)
            return "NOTLIVRED"
        for (var i = 0; i < lines.length; i++) {
            if (lines[i].isService == false) {
                var statut = checkDeliveryState(lines[i].orderDeliveryInfos, lines[i].quantity).status;
                if (statut != "RECEIVED")
                    return "NOTLIVRED"
            }
        }
        return "Livré"
    }
    const handleChangeDeliveryCost = (e: any) => {
        if (deliveryCost && deliveryCost != commande?.deliveryCosts) {
            let obj: Partial<ICommande> = {
                id: commande!.id,
                deliveryCosts: deliveryCost,
            };
            editDeliveryCosts(obj)
            setEditDeliveryCost(false)
        }
        else
            toast.warning("Rien à changé!!")
    }

    const handlePDF = () => {
        generatePDF(id);
    }

    if (!commande) return <Loading content="Chargement du Commande ..." />;
    return (
        <>
            <Grid style={{ margin: 0, padding: 0 }}>
                <Grid.Column width={16} >
                    <Button
                        style={{ display: commande.isManual && !commande.isBilled && commande?.isCanceled == false ? 'block' : 'none' }}
                        floated="right"
                        content="Annuler la Commande"
                        loading={loadBtnCancel}
                        color='red'
                        size="tiny"
                        cursor="pointer"
                        onClick={(e) => {
                            openModal(<CancelCommandeModal commande={commande} />)
                        }} />


                    <Button
                        style={{ display: commande.isManual && !commande.isBilled && commande?.isCanceled == true ? 'block' : 'none' }}
                        floated="right"
                        content="Activer la Commande"
                        loading={loadBtnCancel}
                        color='green'
                        size="tiny"
                        cursor="pointer"
                        onClick={(e) => {
                            openModal(<CancelCommandeModal commande={commande} />)
                        }} />
                </Grid.Column>
            </Grid >
            <Segment>
                <OrderFournisseurModal open={open} setOpen={setOpen} id={id} />
                <Grid columns={16}>
                    <Grid.Column width={9}>
                        <Item.Group style={{ padding: '20px 20px 0 20px' }}>
                            <Item>
                                <Item.Content verticalAlign='middle'>
                                    <Header style={{ paddingBottom: 10, fontSize: 18 }}>Commande n° {commande!.reference} </Header><br />
                                    <Header style={{ paddingBottom: 10, fontSize: 18 }}>{commande?.agencyName}-{commande?.companyName}</Header>
                                    <p style={{ width: 380 }}>{new Date(commande?.orderDate).toLocaleString('en-GB')}  <span style={{ color: "green" }}>{setPaiementType(commande.paymentMode)}</span></p>
                                </Item.Content>
                                {commande.isOpeningPackConsummed == false && commande.companyType != 'Franchiseur' && (
                                    <div style={{ borderRadius: 5, backgroundColor: 'darkseagreen', fontFamily: 'helvetica', color: 'white', margin: 5, padding: 5, textAlign: 'center', width: 240 }}>
                                        <div>
                                            Montant restant pris en charge pour votre pack d’ouverture:
                                            <br />
                                            <span style={{ fontWeight: 'bold', fontFamily: 'helvetica' }}>{commande.companyOpeningPackAmount.toFixed(2)} € TTC</span>
                                        </div>
                                    </div>
                                )}
                                {commande!.isCanceled == false ? (
                                    <VscFilePdf
                                        title="Télécharger PDF"
                                        color={baseRepo.commandeStore.generatePdfMode ? 'orange' : 'green'}
                                        onMouseEnter={() => {
                                            baseRepo.commandeStore.changeButtonPdfState(!baseRepo.commandeStore.generatePdfMode);
                                        }}
                                        onMouseLeave={() => {
                                            baseRepo.commandeStore.changeButtonPdfState(!baseRepo.commandeStore.generatePdfMode);
                                        }}
                                        style={{ marginLeft: 20, fontSize: "30px", cursor: 'pointer', content: "dddd" }}
                                        onClick={handlePDF} />
                                ) : (<></>
                                )}
                            </Item>
                            <Item>
                                <Item.Content verticalAlign='middle'>
                                    <span><b>Facturation :</b> {commande!.invoiceCompany}, {commande!.invoiceAddress1} {commande!.invoicePostalCode} {commande!.invoiceCity}, {commande!.invoiceCountry}</span>
                                    <br />
                                    <span><b>Livraison :</b> {commande!.deliveryCompany}, {commande!.deliveryAddress1} {commande!.deliveryPostalCode} {commande!.deliveryCity}, {commande!.deliveryCountry}</span>
                                </Item.Content>
                            </Item>
                        </Item.Group>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Grid style={{ margin: 10 }}>
                            <Grid.Row style={{ margin: 0, padding: 0 }}>
                                <Grid.Column width={8} style={styleTitle}>
                                    Montant HT
                                </Grid.Column>
                                <Grid.Column width={8} style={style}>
                                    {numberFormat.format(commande.amountWithoutTaxes)}
                                </Grid.Column>
                                <hr style={{ textAlign: "center", width: "100%" }} />
                                <Grid.Column width={8} style={styleTitle}>
                                    Frais Port
                                </Grid.Column>
                                <Grid.Column width={8} style={style}>
                                    {editDeliveryCost ?
                                        (
                                            <>
                                                <Input name='creationDate' onChange={(e: any) => setDeliveryCost(e.target.value)} style={{ marginLeft: 10, height: 25, width: 100 }} defaultValue={commande?.deliveryCosts!} type='number' step='0.01' />
                                                <FiCheckSquare color="green" style={{ marginLeft: 10, fontSize: 18 }} onClick={(e) => handleChangeDeliveryCost(e)} />
                                                <MdOutlineCancelPresentation color="red" style={{ marginLeft: 10, fontSize: 18 }} onClick={(e) => setEditDeliveryCost(false)} />
                                            </>
                                        ) : (
                                            <>
                                                {numberFormat.format(commande.deliveryCosts)}
                                                <FiEdit style={{ marginLeft: 10 }} onClick={(e) => setEditDeliveryCost(true)} />
                                            </>
                                        )
                                    }
                                </Grid.Column>
                                {commande.tvaPrices && commande.tvaPrices.map((value: ITVA) => (
                                    <>
                                        <hr style={{ textAlign: "center", width: "100%" }} />
                                        <Grid.Column width={8} style={styleTitle}>
                                            TVA {value.key} %
                                        </Grid.Column>
                                        <Grid.Column width={8} style={style}>
                                            {numberFormat.format(value.value)}
                                        </Grid.Column>
                                    </>
                                ))}
                                <hr style={{ textAlign: "center", width: "100%" }} />
                                <Grid.Column width={8} style={styleTitle}>
                                    Montant TTC
                                </Grid.Column>
                                <Grid.Column width={8} style={style}>
                                    {numberFormat.format(commande.amountWithTaxes)}
                                </Grid.Column>
                                <Grid.Column width={8} style={{ style, marginBottom: 0, padding: 0, paddingTop: 10 }}>
                                    <Label basic style={{ marginBottom: 0 }} color={getColor(commande.paymentState)}>
                                        {commande.paymentState}
                                    </Label>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <Button.Group style={{ width: '100%' }}>
                            {(commande.orderLines.find(x => x.modeAppro != "VenteDirecte") || articleWithModeAppro) ? (
                                <Button
                                    style={{ borderRight: "2px solid White", width: '100%', display: (!commande.isDelivered && getDisplay(commande.orderState)) ? 'block' : 'none' }}
                                    onClick={(e) => {
                                        openModal(<BonDePreparation id={commande.id} />)
                                    }}
                                    fluid
                                    size='small'
                                    color='purple'
                                    floated='left'
                                    disabled={commande.isCanceled || submitting}
                                    content={'Préparer'}
                                    loading={submitting}
                                />) : (
                                <Button
                                    style={{ borderRight: "2px solid White", width: '100%', display: (!commande.isDelivered && getDisplay(commande.orderState)) ? 'block' : 'none' }}
                                    onClick={(e) => { dispatchOrder(commande.id) }}
                                    fluid
                                    size='small'
                                    color='yellow'
                                    floated='left'
                                    disabled={commande.isCanceled}
                                    content={'Expédier'}
                                />)}

                            {/*<Button*/}
                            {/*    style={{ marginTop: '3px', display: (commande.isBilled == false) && ((articleWithModeAppro || commande.amountWithoutTaxes > 0) && commande.orderState != "WaitingValidation") ? "block" : "none" }}*/}
                            {/*    onClick={(e) => {*/}
                            {/*        openModal(<CommandeFactureModal Order={commande} />)*/}
                            {/*    }}*/}
                            {/*    size='small'*/}
                            {/*    fluid*/}
                            {/*    color='orange'*/}
                            {/*    disabled={commande.isCanceled}*/}
                            {/*    content={'Facturer'}*/}

                            {/*/>*/}
                        </Button.Group>

                        {(commande.orderLines.find(x => x.modeAppro != "Stock") || commande.deliveryCosts > 0) &&

                            <Button.Group vertical style={{ width: '100%', marginTop: 4 }}>
                                <Button
                                    fluid
                                    size="small"
                                    color="blue"
                                    disabled={commande.isCanceled}
                                    icon='add circle'
                                    style={{ fontSize: 13 }}
                                    content="Commande Fournisseur"
                                    onClick={() => { setOpen(true) }}
                                />
                            </Button.Group>
                        }
                        <Button.Group vertical style={{ width: '100%', marginTop: 4 }}>
                            <Button
                                size="small"
                                disabled={commande.isCanceled}
                                color={baseRepo.commandeStore.duplicationMode ? 'red' : 'orange'}
                                onClick={() => {
                                    dupliquer(commande.id)
                                }}
                                content={'Dupliquer'}
                            />
                        </Button.Group>

                        {commande.isBilled == false &&
                            <>
                                {commande.orderLines.length == 0 ? (
                                    <Reveal >
                                        <Reveal.Content visible style={{ width: '100%' }}>
                                            <Button
                                                size='small'
                                                fluid
                                                color={'red'}
                                                content={'Commande En Attente '}
                                            />

                                        </Reveal.Content>
                                    </Reveal>
                                ) : (
                                    <Reveal animated='move'>
                                        <Reveal.Content visible style={{ width: '100%' }}>
                                            <Button
                                                size='small'
                                                disabled={commande.isCanceled}
                                                fluid
                                                color={getDisplay(commande.orderState) ? 'green' : 'red'}
                                                content={getDisplay(commande.orderState) ? 'Commande valide' : 'En Attente '}
                                            />
                                        </Reveal.Content>
                                        <Reveal.Content hidden>
                                            <Button
                                                size='small'
                                                disabled={commande.isCanceled}
                                                loading={loading}
                                                fluid
                                                basic
                                                color={getDisplay(commande.orderState) ? 'red' : 'green'}
                                                content={!getDisplay(commande.orderState) ? 'Valider' : 'EnAttente'}
                                                onClick={() => {
                                                    getDisplay(commande.orderState)
                                                        ? enAttente(commande.id)
                                                        : valide(commande.id)
                                                }}
                                            />
                                        </Reveal.Content>
                                    </Reveal>
                                )
                                }

                            </>
                        }
                        <Button.Group vertical style={{ width: '100%', marginTop: 4 }}>
                            <Button
                                size="small"
                                icon="upload"
                                color="grey"
                                loading={loadingUploadProforma}
                                onClick={() => {
                                    UploadProforma(commande.id)
                                }}
                                content={'Exporter Proforma'}
                            />
                        </Button.Group>
                        {commande.isBilled == false && commande.isOpeningPackConsummed == false && commande.companyType != 'Franchiseur' && commande.hasOpeningPackProduct > 0
                            && commande.orderState == "Validated" && commande.isManual &&
                            < Button
                                fluid
                                disabled={commande.isCanceled}
                                size="small"
                                color="blue"
                                icon='add circle'
                                content="Utiliser pack"
                                onClick={() => {
                                    generateOpeningPack(commande.id)
                                }}
                                style={{ fontSize: 13 }}
                            />
                        }
                    </Grid.Column>
                    {commande!.comment &&
                        <Grid.Column width={16}>
                            <hr />
                            <span style={{ color: 'green' }}>{commande!.comment}</span>
                        </Grid.Column>
                    }
                </Grid>
            </Segment >
        </>
    );
};
export default observer(CommandeHeader);