import { BaseStore } from "./baseStore";
import { observable, action, runInAction } from 'mobx';
import agent from '../agent/agent';
import { toast } from "react-toastify";
import { IFormeJuridique, IFormeJuridiqueList } from "../models/IFormeJuridique";

export default class FormeJuridiqueStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
    }
    @observable loadingFormeJuridique = false;
    @observable loadingInitial = false;
    @observable submitting = false;
    @observable deletingFormeJuridique = false;
    @observable formeJuridique: IFormeJuridique | null = null;
    @observable formeJuridiques: IFormeJuridique[] = [];
    @observable formeJuridiqueRegestry = new Map();
    @observable formeJuridiquesCount = 0;
    @observable editModeFormeJuridique: boolean = false;
    @observable addModeFormeJuridique: boolean = false;
    @observable loadingFormeJuridiqueAction = false;
    @observable ITListFormeJuridique: IFormeJuridiqueList[] = [];

    @action changeButtonStateEdit = async (newState: boolean) => {
        this.editModeFormeJuridique = newState;
    }

    @action changeButtonStateAdd = async (newState: boolean) => {
        this.addModeFormeJuridique = newState;
    }
    @action loadFormeJuridiques = async () => {
        this.loadingInitial = true;
        this.ITListFormeJuridique = [];
        try {
            const formeJuridiques = await agent.formeJuridiqueAgent.list();
            runInAction(() => {
                this.formeJuridiques = formeJuridiques;
                formeJuridiques.forEach((formeJuridique) => {
                    let forme: IFormeJuridiqueList = {
                        key: formeJuridique.label,
                        text: formeJuridique.label,
                        value: formeJuridique.label
                    }
                    this.ITListFormeJuridique.push(forme);
                })
                this.formeJuridiques = formeJuridiques;
                this.loadingInitial = false;
            })
        } catch (e) {
            toast.error("Problem loading forme juridique!");
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }

    @action loadFormeJuridique = async (id: string) => {
        this.loadingFormeJuridique = true;
        let formeJuridique = this.getFormeJuridique(id);
        if (formeJuridique) {
            this.formeJuridique = formeJuridique;
            this.loadingFormeJuridique = false;
            return formeJuridique;
        }
        else {
            try {
                formeJuridique = await agent.formeJuridiqueAgent.details(id);
                runInAction(() => {
                    formeJuridique.dateCreation = formeJuridique.dateCreation.split('T')[0];
                    this.formeJuridiqueRegestry.set(formeJuridique.formeJuridiqueId, formeJuridique);
                    this.formeJuridique = formeJuridique;
                    this.loadingFormeJuridique = false;
                })
                return formeJuridique;
            } catch (e) {
                runInAction(() => {
                    this.loadingFormeJuridique = false;
                })
                console.log(e);
            }
        }
    }

    getFormeJuridique = (id: string) => {
        return this.formeJuridiqueRegestry.get(id);
    }

    @action create = async (values: IFormeJuridique) => {
        this.submitting = true;
        const user = await agent.userAgent.current();
        try {
            await agent.formeJuridiqueAgent.create(values);
            runInAction(() => {
                this.formeJuridiqueRegestry.set(values.formeJuridiqueId, values)
                this.formeJuridique = values;
                this.formeJuridique.owner = user.username;
                this.formeJuridiques.push(this.formeJuridique);
                this.submitting = false;
                toast.success("ajout éffectué avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    @action edit = async (values: Partial<IFormeJuridique>) => {
        this.submitting = true;
        try {
            await agent.formeJuridiqueAgent.update(values);
            let formeJuridique = await agent.formeJuridiqueAgent.details(values.formeJuridiqueId!);
            runInAction(() => {
                this.formeJuridiques = this.formeJuridiques.filter(a => a.formeJuridiqueId != values.formeJuridiqueId);
                this.formeJuridiques.push(formeJuridique);
                toast.success("Modification éffectuée avec succès");
                this.submitting = true;
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }

    }

    @action deleteFormeJuridique = async (id: string) => {
        this.loadingFormeJuridiqueAction = true;
        var forme = await agent.formeJuridiqueAgent.details(id);
        if (forme.label == "SARL") {
            runInAction(() => {
                toast.warning("Cette forme juridique est par defaut et ne peut pas étre supprimer");
                this.loadingFormeJuridiqueAction = false;
            })           
        }
        else {
            try {
                await agent.formeJuridiqueAgent.delete(id);
                runInAction(() => {
                    this.loadingFormeJuridiqueAction = false;
                    this.formeJuridiques = this.formeJuridiques.filter(a => a.formeJuridiqueId !== id);
                    toast.success("suppression éffectuée avec succès");
                })
            }
            catch (e) {
                console.log(e);
                toast.error("Problème de suppression du mode de paiement!");
                runInAction(() => {
                    this.loadingFormeJuridiqueAction = false;
                })
            }
        }

    }
}