import React from 'react';
import { Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import AddFranchiseurContent from './AddFranchiseurContent';
import AddFranchiseurHeader from './AddFranchiseurHeader';
import Breadcrumbs from '../../dashboard/breadcrumbs';


const AddFranchiseurPage: React.FC = ({ }) => {
    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0,name: "Tiers", root: "" },
                    { key: 1,name: "Réseaux", root: "/franchiseurDashboard" },
                    { key: 2,name: "Ajouter franchiseur", root: "/addFranchiseur" },
                ]}
            />
        <Grid>
            <Grid.Row>
              
                <Grid.Column width={16}>
                    <Grid style={{ marginTop: 2 }}>
                        <Grid.Column width={16}>
                            <AddFranchiseurHeader />
                            <AddFranchiseurContent />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
         
            </Grid.Row>
        </Grid>
        </div>
    );
}

export default observer(AddFranchiseurPage);