import React, { useContext, useEffect, useState } from 'react';
import { Form, Item, Table, Button, Header, Progress, Reveal, Checkbox } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import Loading from '../../../app/layout/Loading';
import { observer } from 'mobx-react-lite';
import TextInput from '../../../app/common/form/inputText';
import EntrepotForm from './AddEntrepotModal';
import DeleteEntrepotModal from './DeleteEntrepotModal';
import Breadcrumbs from '../../dashboard/breadcrumbs';
import DisableEntrepotModal from './DisableEntrepotModal';

const ListEntrepot: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { loadEntrepots, loadingInitial, edit, entrepots, loadingEntrepotAction, deletingEntrepot, progress, submitting, disable, enable, loadingSetMain } = baseStore.entrepotStore;
    const [editTarget, setEditTarget] = useState<number | undefined>(undefined);
    const [editForm, setEditForm] = useState(false);
    const [nom, setNom] = useState<string | undefined>(undefined);
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    const { openModal } = baseStore.modalStore;

    const handleFinalFormSubmit = () => {
        let newEntrepot = {
            libelle: nom,
            entrepotsId: editTarget,
        };
        edit(newEntrepot);
        setEditForm(false)
    }
    useEffect(() => {
        loadEntrepots();
    }, [loadEntrepots]);

    if (loadingInitial) return <Loading content="Chargement des Entrepot ..." />

    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Paramétres", root: "" },
                    { key: 1, name: "Entrepot", root: "/dashboardEntrepot" },
                ]}
            />
            <div style={{ margin: '2rem 1rem' }}>
                <Item.Group>
                    <Item>
                        <Item.Content>
                            <Header as='h2' icon='dolly'></Header>
                            <Header as='h2'>{'ENTREPOT(S)'}</Header>
                            <Button
                                floated="right"
                                content={baseStore.entrepotStore.addMode ? 'Annuler' : 'Ajouter ENTREPOT'}
                                color="orange"
                                icon='arrow alternate circle left outline'
                                basic
                                onClick={() => { baseStore.entrepotStore.changeButtonStateAdd(!baseStore.entrepotStore.addMode) }}
                            />
                        </Item.Content>
                    </Item>
                </Item.Group>
                {baseStore.entrepotStore.addMode &&
                    <div>
                        <EntrepotForm />
                        <br />
                    </div>
                }
                {submitting &&
                    <Progress color='orange' percent={progress} size='tiny' />
                }
                <Table loading={false} celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Libelle</Table.HeaderCell>
                            <Table.HeaderCell>Principal</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {entrepots && entrepots.map((entrepot: any) => (
                            <Table.Row key={entrepot.entrepotsId}>
                                {editForm && editTarget === entrepot.entrepotsId ? (
                                    <>
                                        <Table.Cell selectable>
                                            <Form.Input name='libelle' component={TextInput} placeholder='Libelle' defaultValue={entrepot.libelle} style={{ width: '100%', padding: '1%' }} onChange={event => setNom(event.target.value)} />
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Checkbox checked={entrepot.isMain} onChange={() => baseStore.entrepotStore.setMain(entrepot.entrepotsId)} disabled={loadingSetMain || entrepot.isMain} />
                                        </Table.Cell>
                                        <Table.Cell >
                                            <Button.Group widths={2}>
                                                <Button
                                                    type='button'
                                                    onClick={() => {
                                                        setEditForm(false);
                                                    }}>
                                                    Annuler
                                                </Button>
                                                <Button.Or />
                                                <Button
                                                    name={entrepot.entrepotsId}
                                                    positive
                                                    type='submit'
                                                    onClick={(e) => {
                                                        handleFinalFormSubmit()
                                                        setEditTarget(parseInt(e.currentTarget.name))
                                                    }}
                                                >Confirmer</Button>
                                            </Button.Group>
                                        </Table.Cell>
                                    </>
                                ) : (
                                    <>
                                            <Table.Cell>{entrepot.libelle}</Table.Cell>
                                            <Table.Cell>
                                                <Checkbox checked={entrepot.isMain} onChange={() => baseStore.entrepotStore.setMain(entrepot.entrepotsId)} disabled={loadingSetMain || entrepot.isMain} />
                                            </Table.Cell>
                                        <Table.Cell>
                                            <Button.Group  widths={3}>
                                                <Button
                                                    basic
                                                    onClick={(e) => {
                                                        setEditForm(true);
                                                        setEditTarget(parseInt(e.currentTarget.name))
                                                        setNom(entrepot.libelle)
                                                    }}
                                                    color="blue"
                                                    icon='edit outline'
                                                    content='Modifier'
                                                    size="small"
                                                    style={{ marginRight: '2%' }}
                                                    name={entrepot.entrepotsId}
                                                />
                                                <Button
                                                    style={{ marginRight: '2%' }}
                                                    name={entrepot.entrepotsId}
                                                    disabled={ entrepot.isMain}
                                                    basic
                                                    size="small"
                                                    color='red'
                                                    icon='trash'
                                                    content='Supprimer'
                                                    onClick={(e) => {
                                                        openModal(<DeleteEntrepotModal entrepot={entrepot} />)
                                                        setDeleteTarget(e.currentTarget.name)
                                                    }}
                                                    loading={loadingEntrepotAction && deleteTarget === entrepot.entrepotsId && submitting}
                                                />
                                                <Reveal animated='move'>
                                                    <Reveal.Content visible style={{ width: '100%' }}>
                                                        <Button
                                                            fluid
                                                            color={entrepot.active ? 'green' : 'red'}
                                                            content={entrepot.active ? ' Active' : ' désactivé '}
                                                            loading={submitting}
                                                        />
                                                    </Reveal.Content>
                                                    <Reveal.Content hidden>
                                                        <Button
                                                            loading={deletingEntrepot}
                                                            fluid
                                                            basic
                                                            color={entrepot.active ? 'red' : 'green'}
                                                            content={entrepot.active ? 'Désactiver' : 'Activer'}
                                                            onClick={
                                                                entrepot.active
                                                                    ? () => disable(entrepot.entrepotsId).then((data: any) => {
                                                                        data > 0 && openModal(<DisableEntrepotModal count={data} />)
                                                                    })
                                                                    : () => enable(entrepot.entrepotsId)
                                                            }
                                                        />
                                                    </Reveal.Content>
                                                </Reveal>
                                            </Button.Group>
                                        </Table.Cell>
                                    </>
                                )}
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>
        </div>
    );
}
export default observer(ListEntrepot);