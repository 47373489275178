import React from 'react'
import { Tab } from 'semantic-ui-react';
import CommandeFournisseur from './CommandeFournisseur';
import FournisseurAchatDescription from './FournisseurAchatDescription';
import FournisseurComplementInfosDescription from './FournisseurComplementInfosDescription';
import FournisseurComptabiliteDescription from './FournisseurComptabiliteDescription';
import FournisseurDescription from './FournisseurDescription';
import FournisseurFichier from './FournisseurFichier';
import FournisseurPhoto from './FournisseurPhoto';

const panes = [
    { menuItem: 'Detail Fournisseur', render: () => <FournisseurDescription /> },
    { menuItem: 'Achats', render: () => <FournisseurAchatDescription /> },
    { menuItem: 'Commandes', render: () => <CommandeFournisseur /> },
    { menuItem: 'Comptabilité', render: () => <FournisseurComptabiliteDescription /> },
    { menuItem: 'Autre Informations', render: () => <FournisseurComplementInfosDescription /> },
    { menuItem: 'Logo', render: () => <FournisseurPhoto /> },
    { menuItem: 'Fichier(s) à télécharger', render: () => <FournisseurFichier /> },
]

interface IProps {
    setActiveTab: (activeIndex: any) => void;
}

const FournisseurContent: React.FC<IProps> = ({ setActiveTab }) => {

    return (
        <Tab
            menu={{ fluid: true, vertical: true }}
            menuPosition='right'
            panes={panes}
            onTabChange={(e, data) => setActiveTab(data.activeIndex)}
        />
    )
}

export default FournisseurContent