import React, { useContext } from 'react';
import { Button, Header, Icon, Container } from 'semantic-ui-react';
import {  IOrder } from '../../../app/models/ICommande';
import { BaseStoreContext } from '../../../app/store/baseStore';

interface IProps {
    commande: IOrder;
}

const DeleteCommandeModal: React.FC<IProps> = ({ commande }) => {
    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    const { deleteOrder } = baseStore.commandeStore;

    return (
        <Container textAlign='center'>
            <Header icon='trash' content='Supprimer une Commande' style={{ marginLeft: 20 }} />
            <p>
                Voulez vous supprimer cette commande ?
            </p>
            <Button.Group fluid>
                <Button onClick={() => closeModal()}>
                    <Icon name='remove' /> Annuler
                </Button>
                <Button.Or text='Ou' />
                <Button
                    name={commande.id}
                    color='green'
                    onClick={(e) => {
                        deleteOrder(commande.id)
                        closeModal()
                    }}>
                    <Icon name='checkmark' /> Confirmer
                </Button>
            </Button.Group>
        </Container>
    )
}
export default DeleteCommandeModal;
