import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button, Grid } from 'semantic-ui-react';
import TextInput from '../../../app/common/form/inputText';
import { observer } from 'mobx-react-lite';
import { history } from '../../../index';
import { combineValidators, composeValidators, isRequired } from 'revalidate';
import { IDevis } from '../../../app/models/IDevis';


const validate = combineValidators({
    deliveryPhone: composeValidators(
        isRequired)({
            message: "Veuillez saisir la téléphone"
        }),
    invoicePhone: composeValidators(
        isRequired)({
            message: "Veuillez saisir la téléphone mobile"
        }),
    deliveryEmail: composeValidators(
        isRequired)({
            message: "Veuillez saisir l'adresse email"
        }),
    deliveryDigiCode: composeValidators(
        isRequired)({
            message: "Veuillez saisir la digit code"
        }),
    deliveryInterphone: composeValidators(
        isRequired)({
            message: "Veuillez saisir l'interphone"
        }),
    openHours: composeValidators(
        isRequired)({
            message: "Veuillez saisir les horaires douverture"
        }),
    closingDays: composeValidators(
        isRequired)({
            message: "Veuillez saisir les jours de fermeture"
        }),
});

interface IProps {
    editDevis: (commande: Partial<IDevis>) => void;
    devis: IDevis;
    submitting: boolean;
}
const DevisClientEditForm: React.FC<IProps> = ({ editDevis, devis, submitting }) => {
    const handleFinalFormSubmit = (values: any) => {
        let newDevis = {
            ...values,
        };
        editDevis(newDevis);
    }
    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            validate={validate}
            initialValues={devis!}
            render={({ handleSubmit, invalid }) => (
                <Form onSubmit={handleSubmit} error>
                    <Grid columns={2} divided>
                        <Grid.Row>
                            <Grid.Column>
                                <h5>Téléphone *</h5>
                                <Field name='deliveryPhone' component={TextInput} placeholder='Téléphone' value={devis!.deliveryPhone} type='number' />
                            </Grid.Column>
                            <Grid.Column>
                                <h5>Téléphone Mobile *</h5>
                                <Field name='invoicePhone' component={TextInput} placeholder='Téléphone Mobile' value={devis!.invoicePhone} type='number' />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <h5>Email *</h5>
                                <Field name='deliveryEmail' component={TextInput} placeholder='Email' value={devis!.deliveryEmail} />
                            </Grid.Column>
                            <Grid.Column>
                                <h5>Digit code *</h5>
                                <Field name='deliveryDigiCode' component={TextInput} placeholder='Digit code' value={devis!.deliveryDigiCode} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <h5>Interphone *</h5>
                                <Field name='deliveryInterphone' component={TextInput} placeholder='Interphone' value={devis!.deliveryInterphone} />
                            </Grid.Column>
                            <Grid.Column>
                                <h5>Horaires d'ouverture *</h5>
                                <Field name='openHours' component={TextInput} placeholder='Horaires douverture' value={devis!.openHours} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <h5>Jours de fermeture *</h5>
                                <Field name='closingDays' component={TextInput} placeholder='Jours de fermeture' value={devis!.closingDays} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <div style={{ marginTop: '20px' }}>
                        <Button.Group floated="right">
                                <Button onClick={() => history.push('/devisDashboard')} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive disabled={submitting || invalid} loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </div>
                </Form>
            )}
        />
    );
};

export default observer(DevisClientEditForm);