import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Button, Header, Icon, Container, Radio, Grid, Table, Label, List } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { AiTwotonePushpin } from 'react-icons/ai';
import Select from 'react-select';
import { IReglement } from '../../../app/models/IReglement';
import { observer } from 'mobx-react-lite';
import { numberFormat } from '../../../app/common/options/IStyle';

interface IProps {
    idCompany: number;
    reglement: IReglement;
    setModalOpen: Dispatch<SetStateAction<boolean>>;

}

const AffectReglement: React.FC<IProps> = ({ idCompany, reglement, setModalOpen }) => {
    const baseStore = useContext(BaseStoreContext);
    const { loadingCommandeCompany, ItListCommande, loadCommandesByCompany } = baseStore.commandeStore;
    const { loadingFactureCompany, loadFacturesByCompany, ITListFActureByCompany } = baseStore.factureStore;
    const { affect, reglementNotConsumedByClient, ITListReglementNotConsumedByClient, loadingReglementNotConsumedByClient, loadReglementByFranchiseur, remboursement, totalRBT, totalReste } = baseStore.reglementStore;
    const [valeur, setValeur] = useState<string>();
    const [showText, setShowText] = useState(false);
    const [showText1, setShowText1] = useState(false);
    const [showText2, setShowText2] = useState(false);
    const [commande, setCommande] = useState();
    const [facture, setFacture] = useState();
    const [rbt, setRbt] = useState();
    const handleChangeCommande = (e: any) => {
        setCommande(e.value);
    }
    useEffect(() => {
        let obj: any = {
            companyId: idCompany,
            regId: reglement.idReglement

        }; reglementNotConsumedByClient(obj)
    }, [])
    return (
        <Container textAlign='center'>

            <Header icon={<AiTwotonePushpin />} content='Affecter le reglement' style={{ marginLeft: 20 }} />
            <Grid columns={16} style={{alignItems:"center"}}>
                <Grid.Row style={{ }}>
                    {!reglement.hasConsumed &&
                        <Radio
                            label='Commande'
                            name='radioGroup'
                            value='Commande'
                            onChange={() => setValeur("Commande")}
                            checked={valeur == 'Commande'}
                            onClick={() => { setShowText(true); setShowText1(false); setShowText2(false); loadCommandesByCompany(idCompany) }}
                        />}
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    {!reglement.hasConsumed &&

                        <Radio
                            label='Facture'
                            name='radioGroup'
                            value='Facture'
                            checked={valeur == 'Facture'}
                            onChange={() => setValeur("Facture")}
                            onClick={() => { setShowText1(true); setShowText(false); setShowText2(false); loadFacturesByCompany(idCompany) }}
                        />}
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    {reglement.type != "RBT" &&
                        <Radio
                            label='Remboursement'
                            name='radioGroup'
                            value='RBT'
                            checked={valeur == 'RBT'}
                            onChange={() => setValeur("RBT")}
                            onClick={() => {
                                setShowText2(true); setShowText(false); setShowText1(false);
                            }}
                        />
                    }
                    &nbsp;
                    &nbsp;
                    &nbsp;
                </Grid.Row>
                <Grid.Row style={{  marginTop: '-20px' }}>
                    {showText &&
                        <>
                            <Select
                                isLoading={loadingCommandeCompany}
                                placeholder={'Commande ..'}
                                options={ItListCommande}
                                getOptionLabel={e => e!.text}
                                getOptionValue={e => e!.value}
                                classNamePrefix="select"
                                onChange={handleChangeCommande} />
                        </>
                    }
                    {showText1 &&
                        <>
                            <Select
                                isLoading={loadingFactureCompany}
                                placeholder={'Facture ..'}
                                options={ITListFActureByCompany}
                                getOptionLabel={e => e!.text}
                                getOptionValue={e => e!.value}
                                classNamePrefix="select"
                                onChange={(e: any) => setFacture(e!.value)} />
                        </>
                    }
                    {showText2 &&
                        <>
                            <Label> Reste à rembourser: {numberFormat.format(totalReste)}</Label>
                            {totalRBT != 0 &&
                            <Label>Total montant rembourser : {numberFormat.format(totalRBT)}</Label>
                            }
                        {totalRBT != 0 &&
                            <Table style={{ overflowY: 'scroll', width: 500 }} size='small' compact='very' >
                                    <Table.Header>
                                        <Table.HeaderCell>Type</Table.HeaderCell>
                                        <Table.HeaderCell>Montant</Table.HeaderCell>
                                        <Table.HeaderCell>Date</Table.HeaderCell>
                                    </Table.Header>

                                    <Table.Body>
                                        {remboursement
                                            && remboursement.map((rbt: IReglement,) => (<>
                                                <Table.Cell>{rbt.type}</Table.Cell>
                                                <Table.Cell>{numberFormat.format(rbt.montant*(-1))}</Table.Cell>
                                                <Table.Cell>{new Date(rbt.creationDate).toLocaleDateString("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" })}</Table.Cell>
                                            </>))}

                                    </Table.Body>
                                </Table>
                            }
                            &nbsp;
                            &nbsp;
                        &nbsp;

                            <Select
                                isLoading={loadingReglementNotConsumedByClient}
                                placeholder={'Remboursement ..'}
                                options={ITListReglementNotConsumedByClient}
                                getOptionLabel={e => e!.text}
                                getOptionValue={e => e!.value}
                                classNamePrefix="select"
                                onChange={(e: any) => setRbt(e!.value)} />
                        </>
                    }
                </Grid.Row>
                <Grid.Row>
                    <Button.Group fluid>
                        <Button onClick={() => { setModalOpen(false) }} floated='right' type='button'>Annuler</Button>
                        <Button
                            color='green'
                            onClick={(e) => {
                                let newReglement = {
                                    idReglement: reglement.idReglement,
                                    montant: reglement.montant,
                                    commande: commande != 0 ? commande : 0,
                                    facture: facture != 0 ? facture : 0,
                                    rbt: rbt != null ? rbt : null
                                };
                                affect(newReglement).then(() => {
                                    loadReglementByFranchiseur(idCompany, null);
                                }

                                );

                                setModalOpen(false)
                            }}>
                            <Icon name='checkmark' /> Confirmer
                        </Button>
                    </Button.Group>
                </Grid.Row>

            </Grid>
        </Container>
    )
}
export default observer(AffectReglement);
