import React, { useContext, useState } from 'react';
import { Button, Header, Icon, Container, Input } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../app/store/baseStore';

interface IProps {
    id: number;
}

const CloseInventoryModal: React.FC<IProps> = ({ id}) => {
    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    const { closing, closeInventory } = baseStore.inventoryStore;
    const [date, setDate] = useState<string>(new Date().toLocaleDateString("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" }));
    

    const handleSubmit = () => {
        closeInventory(id, date)
        closeModal();

    }

    return (
        <Container textAlign='center'>
            <Header icon='edit' content="Clôturer l'inventaire" style={{ margin: "10px 0 40px 20px" }} />
            <div style={{  marginBottom: 10 }}>
                <h6>Date de clôture:</h6>
                <Input name='creationDate' value={date} onChange={(e: any) => setDate(e.target.value)} placeholder='date de paiement' type='date' />
            </div>
            <Button.Group style={{ marginTop: 30, marginBottom: 20 }}>
                <Button onClick={() => { closeModal(); }}>
                    <Icon name='remove' /> Annuler
                </Button>
                <Button
                    color='green'
                    loading={closing}
                    onClick={(e) => {
                        handleSubmit();
                    }}>
                    <Icon name='checkmark' /> Confirmer
                </Button>
            </Button.Group>

        </Container>
    );
}
export default observer(CloseInventoryModal);