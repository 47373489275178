import { action, computed, observable, reaction, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../agent/agent";
import { BaseStore } from "./baseStore";
import { history } from '../../index';
import { IFacture, IFactureList, IGroupInvoiceDto, IInvoiceDto, IInvoiceLine, IlisteFactureForAvoir, IListRelatedElements, IRemisAVList, IRemiseAvoir } from "../models/IFacture";
import { IDeviCFList } from "../models/IDevis";
import { IClient, IClientList } from "../models/IClient";
import { IFranchiseurList } from "../models/IFranchiseur";

const LIMIT = 100;

export default class FactureStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0;
                this.factureRegestry.clear();
                this.loadListesFactures();
            }
        )
        reaction(
            () => this.activeTab,
            activeTab => {
                if (activeTab != '')
                    return true;
            }
        )
    }


    @observable page = 0;
    @observable loadingInitial = false;
    @observable loadingListFacture = false;
    @observable looading = false;
    @observable loadingFactureCompany = false;
    @observable loadingFactureNotClosed = false;
    @observable loadingFacture = false;
    @observable activeTab: string = "";
    @observable predicate = new Map();
    @observable factureRegestry = new Map();
    @observable submitting = false;
    @observable sending = false;
    @observable cloturable = false;
    @observable cloturableMail = false;
    @observable FactureForAvoir: IlisteFactureForAvoir[] = [];
    @observable facturesCount: number = 0;
    @observable searchedByDate = false;
    @observable factures: IInvoiceDto[] = [];
    @observable invoices: IInvoiceDto[] = [];
    @observable facture: IFacture | null = null;
    @observable editDFactureMode = false;
    @observable generatePdfMode = false;
    @observable ITListClientsFranchis: IDeviCFList[] = [];
    @observable ITListFActureByCompany: IFactureList[] = [];
    @observable addingArticle = false;
    @observable progress: number = 50;
    @observable deletingFactureArticle = false;
    @observable relatedElementsList: IListRelatedElements[] = [];
    @observable selectedRemiseAvoir: IRemiseAvoir[] = [];
    @observable remiseAvoireListe: IRemiseAvoir[] = [];
    @observable selectremisAVList: IRemisAVList[] = [];
    @observable invoiceLine: IInvoiceLine | null = null;
    @observable FranchiseurRemise: IRemiseAvoir[] = [];
    @observable invoiceNotClosed: IGroupInvoiceDto[] = [];
    @observable deletingFacture = false;
    @observable totalTtc: number = 0;
    @observable totalHt: number = 0;
    @observable totalTTC: number = 0;
    @observable totalHT: number = 0;
    @observable totalRemaining: number = 0;


    @computed get axiosParams() {
        const params = new URLSearchParams();
        params.append('limit', String(LIMIT));
        params.append('offset', `${this.page ? this.page * LIMIT : 0}`);
        this.predicate.forEach((value, key) => {
            if (key === 'startDate') {
                params.append(key, value.toISOString())
            } if (key === 'searchMultiple') {
                var searchClient = [...value][0];
                var article = [...value][1];
                var search = [...value][2];
                var Key = [...value][3];
                var sort = [...value][4];
                var dateCommande = [...value][5];
                var dateCommandeTo = [...value][6];
                var searchClientAll = [...value][7];
                var searchNCC = [...value][8];
                params.append(searchClient[0], searchClient[1])
                params.append(article[0], article[1])
                params.append(search[0], search[1])
                params.append(Key[0], Key[1])
                params.append(sort[0], sort[1])
                params.append(dateCommande[0], dateCommande[1])
                params.append(dateCommandeTo[0], dateCommandeTo[1])
                params.append(searchClientAll[0], searchClientAll[1])
                params.append(searchNCC[0], searchNCC[1])
            } else {
                params.append(key, value)
            }
        })
        return params;
    }

    @computed get totalPages() {
        if (this.searchedByDate)
            return Math.ceil(100 / LIMIT)
        else
            return Math.ceil(this.facturesCount / LIMIT);
    }

    @action setPage = (page: number) => {
        this.page = page;
    }

    @action setPredicate = (predicate: string, value: any) => {
        this.factures = [];
        this.predicate.clear();
        if (predicate !== 'searchMultiple') {
            this.predicate.clear();
        }
        if (predicate !== 'all') {
            this.predicate.set(predicate, value);
        }
    }

    @action setActiveTab = async (activeIndex: string) => {
        runInAction(() => {
            this.activeTab = activeIndex;
        })
    }

    @action changeButtonState = async (newState: boolean) => {
        this.editDFactureMode = newState;
    }

    @action changeButtonPdfState = async (newState: boolean) => {
        this.generatePdfMode = newState;
    }

    @action create = async (values: Partial<IFacture>) => {
        this.submitting = true;
        try {
            var id = await agent.factureAgent.create(values);
            runInAction(() => {
                this.factureRegestry.set(values.id, values)
                this.submitting = false;
                toast.success("ajout effectué avec succès");
            })
            history.push(`/detailFacture/${id}`);
        } catch (e) {
            toast.error("Problème d'ajout d'une facture!");
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    @action getListFactureByUser = async (id: string) => {
        this.FactureForAvoir = [];
        try {
            var res = await agent.factureAgent.getByUser(id);
            runInAction(() => {
                res.forEach((facture) => {
                    let listFacture: IlisteFactureForAvoir = {
                        Key: facture.id.toString(),
                        text: '(' + facture.id.toString() + ')_' + facture.reference,
                        value: facture.id.toString()
                    }
                    this.FactureForAvoir.push(listFacture);
                })
            });
            return res;
        } catch (e) {
            console.log(e);
        }
    }

    @action loadListesFactures = async () => {
        this.loadingListFacture = true;
        try {
            const factureEnveloppe = await agent.factureAgent.list(this.axiosParams);
            const { factures, facturesCount, searchedByDate, totalHt, totalTtc } = factureEnveloppe;
            runInAction(() => {
                if (this.page == 0) {
                    this.factures = factures;
                } else {
                    factures.forEach((factur) => {
                        this.factures.push(factur);
                    })
                }
                this.searchedByDate = searchedByDate;
                this.facturesCount = facturesCount;
                this.totalTtc = totalTtc;
                this.totalHt = totalHt;
                this.loadingListFacture = false;
            });

        } catch (e) {
            runInAction(() => {
                this.loadingListFacture = false;
            })
            console.log(e);
        }
    }

    @action loadListesFacturesExporte = async (search: any) => {
        this.loadingInitial = true;
        this.invoices = [];
        try {
            const invoicesEnveloppe = await agent.factureAgent.listFactureExporter(search);
            const { factures, totalHt, totalTtc, totalRemaining } = invoicesEnveloppe;

            runInAction(() => {
                if (this.page == 0) {
                    this.invoices = factures;
                } else {
                    factures.forEach((factur) => {
                        this.invoices.push(factur);
                    })
                }
                this.totalHT = totalHt;
                this.totalTTC = totalTtc;
                this.totalRemaining = totalRemaining;
                this.loadingInitial = false;
            });
        } catch (e) {
            runInAction(() => {
                this.loadingInitial = false;
            })
            console.log(e);
        }
    }
    @action ExportFacture = async (obj: any) => {
        this.looading = true;
        try {
            await agent.factureAgent.exportExcel(obj);
            runInAction(() => {
                this.looading = false;
            })
        } catch (e) {
            runInAction(() => {
                this.looading = false;
            })
            console.log(e);
        }
    }

    @action loadFacture = async (id: string) => {
        this.loadingFacture = true;

        this.facture = null;
        try {
            var facture = await agent.factureAgent.details(id);
            runInAction(() => {
                this.facture = facture;
                this.loadingFacture = false;
            });
        } catch (e) {
            runInAction(() => {
                this.loadingFacture = false;
            });
            console.log(e);
        }
    }

    @action loadRemiseAffected = async (id: number) => {
        this.selectedRemiseAvoir = [];
        try {
            var getselectedRemise = await agent.factureAgent.remiseAvoirByFacture(id);
            runInAction(() => {
                if (getselectedRemise != null)
                    this.selectedRemiseAvoir = getselectedRemise;
            })
        } catch (e) {
            console.log(e);
        }
    }

    @action listClientsByFranchiseur = async (facture: IFacture) => {
        this.ITListClientsFranchis = [];
        this.loadingInitial = true;
        let newList: any = [];
        let listClient: IClient[] = [];

        const paramsObj = { limit: '', offset: '' };
        const searchParams = new URLSearchParams(paramsObj);

        await agent.franchiseurAgent.details(facture.idCompany).then((data) => {
            let franchiseurById: IFranchiseurList = {
                key: data?.agencyName.toString(),
                text: data?.agencyName.toString() + ", " + data?.companyName,
                value: data?.agencyName.toString()
            };
            newList.push(franchiseurById);
        });


        let id = facture.idCompany;

        var clientEnveloppe = await agent.clientAgent.listClientsFranchise(id, searchParams);
        runInAction(() => {
            this.loadingInitial = false;
        })

        listClient = clientEnveloppe.clients;
        listClient.forEach((client) => {
            let clients: IClientList = {
                key: client.agencyName,
                text: client.agencyName,
                value: client.agencyName
            };
            newList.push(clients);
        });
        runInAction(() => {
            this.ITListClientsFranchis = newList;
        })
    }

    @action editFacture = async (values: Partial<IFacture>) => {
        this.submitting = true;
        try {
            var reference = await agent.factureAgent.update(values);
            runInAction(() => {
                this.facture = { ...this.facture!, ...values };
                this.facture.reference = reference;
                this.submitting = false;
                this.changeButtonState(false);
                toast.success("Facture modifié avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action editInformationClientFacture = async (values: Partial<IFacture>) => {
        this.submitting = true;
        try {
            await agent.factureAgent.updateInformationClientFacture(values);
            runInAction(() => {
                this.facture = { ...this.facture!, ...values };
                this.submitting = false;
                this.changeButtonState(false);
                this.loadingInitial = true;
                toast.success("Facture modifiée avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    };

    @action addArticle = async (invoiceLine: IInvoiceLine, idFacture: number) => {
        this.addingArticle = true;
        try {
            const prices = await agent.factureAgent.addInvoiceLine(invoiceLine, idFacture);
            runInAction(() => {
                this.editDFactureMode = false;
                this.factureRegestry.set(invoiceLine.id, invoiceLine)
                prices.invoiceLines.forEach((item) => {
                    this.facture!.invoiceLines.push(item);
                })
                this.facture!.amountWithoutTaxes = prices.ht;
                this.facture!.amountWithTaxes = prices.ttc;
                this.facture!.remainingPayment = prices.remainingPayment;
                this.facture!.deliveryCosts = prices.deliveryCost;
                this.facture!.tvaPrices = prices.tvaPrices;
                this.addingArticle = false;
                toast.success("ajout effectué avec succès");
            })
        }
        catch (e) {
            console.log(e);
            toast.error("Problème de mise à jour article!");
            runInAction(() => {
                this.addingArticle = false;
            })
        }
    }

    @action editInvoiceLine = async (id: number, values: IInvoiceLine, montant: number) => {
        this.submitting = true;
        this.progress = 80;
        try {
            const prices = await agent.factureAgent.updateInvoiceLine(id, values);
            runInAction(() => {
                this.submitting = false;
                this.facture!.invoiceLines[this.facture!.invoiceLines.findIndex(x => x.id == values.id)] = values;
                this.facture!.amountWithoutTaxes = prices.ht;
                this.facture!.amountWithTaxes = prices.ttc;
                this.facture!.deliveryCosts = prices.deliveryCost;
                this.facture!.tvaPrices = prices.tvaPrices;
                this.facture!.remainingPayment = (this.facture!.remainingPayment - this.facture!.invoiceLines.find(x => x.id == id)!.totalPriceWithTaxes!) + montant;
                this.factureRegestry.set(values.id, values);
                toast.success("Ligne de facture modifié avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action updateLine = async (id: number, values: IInvoiceLine) => {
        this.submitting = true;
        this.progress = 80;
        try {
            const prices = await agent.factureAgent.updateLine(id, values);
            runInAction(() => {
                this.submitting = false;
                this.facture!.invoiceLines[this.facture!.invoiceLines.findIndex(x => x.id == values.id)] = values;
                this.facture!.amountWithoutTaxes = prices.ht;
                this.facture!.amountWithTaxes = prices.ttc;
                toast.success("Ligne de facture modifié avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action deleteInvoiceArticle = async (idFactureLine: number, invoiceLine: IInvoiceLine) => {
        this.deletingFactureArticle = true;
        try {
            const prices = await agent.factureAgent.deleteInvoiceArticle(idFactureLine);
            runInAction(() => {
                this.facture!.invoiceLines = this.facture!.invoiceLines.filter(a => a.id !== idFactureLine);
                this.facture!.amountWithoutTaxes = prices.ht;
                this.facture!.amountWithTaxes = prices.ttc;
                this.facture!.deliveryCosts = prices.deliveryCost;
                this.facture!.tvaPrices = prices.tvaPrices;
                this.facture!.remainingPayment -= invoiceLine.totalPriceWithTaxes!;
                if (this.facture!.amountWithoutTaxes == 0)
                    this.facture!.invoiceStatus = "Brouillon";
                this.deletingFactureArticle = false;
                toast.success("ligne de facture supprimée avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Probléme de suppression!");
            runInAction(() => {
                this.deletingFactureArticle = false;
            })
        }
    }

    @action generatePDF = async (id: string, ref: string) => {
        try {
            await agent.factureAgent.generatePDF(id, ref);
        }
        catch (e) {
            console.log(e);
        }
    }
    @action brouillon = async (id: number) => {
        this.deletingFactureArticle = true;
        try {
            await agent.factureAgent.brouillon(id);
            runInAction(() => {
                this.deletingFactureArticle = false;
                this.facture!.invoiceStatus = "Brouillon";
                toast.success("facture brouillonne");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de mettre la facture en brouillon!");
            runInAction(() => {
                this.deletingFactureArticle = false;
            })
        }
    }

    @action valide = async (id: number) => {
        this.deletingFactureArticle = true;
        try {
            var ref = await agent.factureAgent.valide(id);
            runInAction(() => {
                this.deletingFactureArticle = false;
                this.facture!.invoiceStatus = "Valider";
                this.facture!.reference = ref + '';
                toast.success("Facture Validée");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de validation de la facture!");
            runInAction(() => {
                this.deletingFactureArticle = false;
            })
        }
    };

    @action getRelatedObject = async (id: number) => {
        this.submitting = true;
        this.relatedElementsList = [];
        var typeFacture = this.facture?.invoiceType;
        if (typeFacture == "Facture_Avoir")
            typeFacture = "Facture-Avoir";
        try {
            var result = await agent.factureAgent.relatedObject(id, typeFacture!);
            runInAction(() => {
                this.relatedElementsList = result;
                this.submitting = false;

            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Aucun objet liés");
        }
    }

    @action getRemiseAvoirListe = async (id: number) => {
        this.remiseAvoireListe = [];
        this.selectremisAVList = [];
        try {
            var result = await agent.factureAgent.remiseAvoirListe(id);
            runInAction(() => {
                this.remiseAvoireListe = result;
                result.map(item => {
                    let newSelectitem: IRemisAVList = {
                        label: item.reference + ": " + item.amountWithoutTaxes + '€ HT/ ' + item.amountWithTaxes + '€ TTC: ',
                        value: item
                    };
                    this.selectremisAVList.push(newSelectitem);
                })
            })
        } catch (e) {
            console.log(e);
        }
    }

    @action affecteRemiseAvoirToFacture = async (idRemise: number, idFacturTarget: number) => {
        this.selectedRemiseAvoir = [];
        try {
            await agent.factureAgent.updateRemiseAv(idRemise, idFacturTarget);
            runInAction(() => {
                var objselected = this.remiseAvoireListe.find(x => x.id == idRemise);
                this.selectedRemiseAvoir.push(objselected!);
            });
        } catch (e) {
            console.log(e);
        }
    }

    @action setChangeRemaining = async (newState: number) => {
        this.facture!.remainingPayment -= newState;
    }

    @action setChangeRemaining1 = async (newState: number) => {
        this.facture!.remainingPayment += newState;
    }

    @action editRemaining1 = async (oldState: number, newState: number) => {
        this.facture!.remainingPayment = (this.facture!.remainingPayment + oldState) - newState;
    }

    @action getRemiseFranchiseur = async (id: number) => {
        this.loadingInitial = true;
        this.FranchiseurRemise = [];
        try {


            const results = await agent.factureAgent.FranchiseurRemise(id);
            runInAction(() => {
                this.FranchiseurRemise = results;
                this.loadingInitial = false;
            });
        } catch (e) {
            console.log(e);
            this.loadingInitial = false;
        }
    }

    @action PartitionRemiseAvoir = async (values: Partial<IRemiseAvoir>) => {
        this.submitting = true;
        try {
            const results = await agent.factureAgent.PartitionRemiseAvoir(values);
            runInAction(() => {
                this.FranchiseurRemise[this.FranchiseurRemise.findIndex(x => x.id == values.id)].amountWithTaxes -= values!.montant!;
                this.FranchiseurRemise.push(results);
                this.submitting = false;
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action deleteFacture = async (id: number) => {
        this.deletingFacture = true;
        try {
            await agent.factureAgent.deleteFacture(id);
            runInAction(() => {
                this.deletingFacture = false;
                this.factures = this.factures.filter(a => a.id !== id);
                toast.success("suppression éffectuée avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.deletingFacture = false;
            })
        }
    }

    @action FusionFactureList = async (ids: number[]) => {
        this.submitting = true;
        try {
            await agent.factureAgent.generatePDFByListFactures(ids);
            runInAction(() => {
                this.submitting = false;
            })
            history.push('/factureDashboard')
        } catch (e) {
            toast.error("Problème d'ajouts de liste select  !");
            runInAction(() => {
                this.submitting = false;
            })
        }
    }

    @action SendMails = async (ids: number[]) => {
        this.sending = true;
        try {
            await agent.factureAgent.sendMails(ids);
            runInAction(() => {
                this.sending = false;
            })
            toast.success("Les emails ont été envoyés avec succès");
        } catch (e) {
            toast.error("Problème d'ajouts de liste select  !");
            runInAction(() => {
                this.sending = false;
            })
        }
    }

    @action SendMail = async (id: number) => {
        this.sending = true;
        try {
            await agent.factureAgent.sendMail(id);
            runInAction(() => {
                this.sending = false;
            })
            toast.success("L'email a été envoyé au client avec succès ");
        } catch (e) {
            toast.error("Problème d'ajouts de liste select  !");
            runInAction(() => {
                this.sending = false;
            })
        }
    }

    @action loadFacturesByCompany = async (id: number) => {
        this.loadingFactureCompany = true;
        this.ITListFActureByCompany = [];
        try {
            const invoices = await agent.factureAgent.getFactureByCompany(id);
            runInAction(() => {
                invoices.forEach((invoice) => {
                    let orders: IFactureList = {
                        Key: invoice.id,
                        text: invoice.reference,
                        value: invoice.id.toString(),
                    }
                    this.ITListFActureByCompany.push(orders)
                });
                this.loadingFactureCompany = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingFactureCompany = false;
            })
            console.log(e);
        }
    }

    @action ExportPDF = async (obj: any) => {
        this.loadingInitial = true;
        try {
            await agent.factureAgent.exportPDF(obj);
            runInAction(() => {
                this.loadingInitial = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingInitial = false;
            })
            console.log(e);
        }
    }
    @action loadListesFacturesNotClosed = async () => {
        this.loadingFactureNotClosed = true;
        this.invoiceNotClosed = [];
        try {
            const invoices = await agent.factureAgent.getFactureByMonth();
            var x = invoices;
            runInAction(() => {
                this.invoiceNotClosed = invoices;
                this.loadingFactureNotClosed = false;
            });
        } catch (e) {
            runInAction(() => {
                this.loadingFactureNotClosed = false;
            })
            console.log(e);
        }
    }
    @action cloturerFacture = async (obj: any) => {
        obj.type == "cloturerEnvoyer" ? this.cloturableMail = true : this.cloturable = true;
        try {
            await agent.factureAgent.cloturerFacture(obj);
            runInAction(() => {
                this.invoiceNotClosed = this.invoiceNotClosed.filter(x => x.year != obj.year && x.month != obj.month)
                obj.type == "cloturerEnvoyer" ? this.cloturableMail = false : this.cloturable = false;
            })
            toast.success("Les factures sont cloturées  !");
        } catch (e) {
            toast.error("Problème de cloturer les facture  !");
            runInAction(() => {
                this.cloturable = false;
                this.cloturableMail = false
            })
        }
    }


    @action exportComptables = async (obj: any) => {
        this.looading = true;
        try {
            await agent.factureAgent.exportComptabilité(obj);
            runInAction(() => {
                this.looading = false;
            })
            toast.success("Le fichier a été exporté avec succès!");
        } catch (e) {
            toast.error("Problème d'export fichier  !");
            runInAction(() => {
                this.looading = false;
            })
        }
    }

    @action exportVentes = async (obj: any) => {
        this.looading = true;
        try {
            await agent.factureAgent.exportVentes(obj);
            runInAction(() => {
                this.looading = false;
            })
            toast.success("Le fichier a été exporté avec succès!");
        } catch (e) {
            toast.error("Problème d'export fichier  !");
            runInAction(() => {
                this.looading = false;
            })
        }
    }

    @action checkDate = async (invoiceDate: Date) => {
        try {
            var isValid = await agent.factureAgent.checkForInvoiceDate(invoiceDate);
            return isValid;
        } catch (e) {
            toast.error("Problème de vérfication  !");
            return false;
        }
    }
}