import { IArticle, IProductAttribute } from "../../models/IArticle";
import { IClient } from "../../models/IClient";
import { IFournisseurArticle } from "../../models/IFournisseurArticle";
import { IFamille } from "../../models/IFamille";
import { IFranchiseur } from "../../models/IFranchiseur";
import { IModePaiement } from "../../models/IModePaiement";
import { IModeReglement } from "../../models/IModeReglement";
import { ICommande } from "../../models/ICommande";
import { IDevis } from "../../models/IDevis";
import { ITva } from "../../models/ITva";
import { IEntrepot } from "../../models/IEntrepot";
import { ITarifFranchiseur } from "../../models/ITarifFranchiseur";
import { IAdresse } from "../../models/IAdresse";
import { IImpression } from "../../models/IImpression";
import { IOption } from "../../models/IOption";
import { ISupport } from "../../models/ISupport";
import { IMouvementStock } from "../../models/IMouvementStock";
import { IAccount } from "../../models/IAccount";
import { IReglement } from "../../models/IReglement";
import { ICommandeFournisseur } from "../../models/ICommandeFournisseur";
import { IArticleCompose } from "../../models/IArticleCompose";
import { IPreparationCommande } from "../../models/IPreparation";
import { ITypeMarche } from "../../models/ITypeMarche";
import { IExpeditionCommande } from "../../models/IExpedition";
import { IArticleStock } from "../../models/IArticleStock";


export const combineTimeAndDate = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const stringDate = `${year}-${month}-${day}`;
    return new Date(stringDate);
}

export const setClientProps = (client: IClient) => {
    client.dateCreation = new Date(client.dateCreation);
    return client;
}

export const setArticleProps = (article: IArticle) => {
    article.dateCreation = new Date(article.dateCreation);
    return article;
}

export const setCommandeProps = (commande: ICommande) => {
    //commande.orderDate = new Date(commande.orderDate);
    return commande;
}
export const setDevisProps = (devis: IDevis) => {
    return devis;
}
export const setCommandeFournisseurProps = (commandeFournisseur: ICommandeFournisseur) => {
    return commandeFournisseur;
}

export const setFranchiseurProps = (franchiseur: IFranchiseur) => {
    franchiseur.dateCreation = new Date(franchiseur.dateCreation);
    return franchiseur;
}

export const setModePaiementProps = (modePaiement: IModePaiement) => {
    modePaiement.dateCreation = new Date(modePaiement.dateCreation);
    return modePaiement;
}

export const setTvaProps = (tva: ITva) => {
    tva.dateCreation = new Date(tva.dateCreation);
    return tva;
}

export const setFournisseurArticleProps = (fournisseurArticle: IFournisseurArticle) => {
    fournisseurArticle.dateLivraison = new Date(fournisseurArticle.dateLivraison);
    return fournisseurArticle;
}

export const setModeReglementProps = (modeReglement: IModeReglement) => {
    modeReglement.dateCreation = new Date(modeReglement.dateCreation);
    return modeReglement;
}

export const setFamilleProps = (famille: IFamille) => {
    famille.dateCreation = new Date(famille.dateCreation);
    return famille;
}

export const setTarifFranchiseurProps = (tarifFranchiseur: ITarifFranchiseur) => {
    tarifFranchiseur.dateCreation = new Date(tarifFranchiseur.dateCreation);
    return tarifFranchiseur;
}
export const setEntrepotProps = (entrepot: IEntrepot) => {
    entrepot.dateCreation = new Date(entrepot.dateCreation);
    return entrepot;
}
export const setAdressesProps = (adresse: IAdresse) => {
    return adresse;
}
export const setImpressionProps = (impression: IImpression) => {
    return impression;
}
export const setOptionProps = (option: IOption) => {
    return option;
}
export const setSupportProps = (support: ISupport) => {
    return support;
}
export const setMouvementStockProps = (stock: IMouvementStock) => {
    return stock;
}
export const setAccountProps = (Account: IAccount) => {
    return Account;
}
export const setReglementProps = (Reglement: IReglement) => {
    return Reglement;
}
export const setArticleComposeProps = (packs: IArticleCompose) => {
    return packs;
}
export const setPreparationProps = (packs: IPreparationCommande) => {
    return packs;
}
export const setTypeMarcheProps = (typeMarche: ITypeMarche) => {
    return typeMarche;
}
export const setExpeditionProps = (stock: IExpeditionCommande) => {
    return stock;
}
export const setAlertStockProps = (packs: IArticleStock) => {
    return packs;
}

export const getType = (type: string) => {
    switch (type) {
        case 'FactureFournisseur':
            return 'Facture Fournisseur';
        case 'Reception':
            return 'R�ception';
        case 'CommandeFournisseur':
            return 'Commande Fournisseur';
        case 'Expedition':
            return 'Exp�dition';
        case 'Preparation':
            return 'Pr�paration';
        default:
            return type;
    }
}

export const getTypeObjetLie = (type: string) => {
    switch (type) {

        case "Devis":
            return "Devis Client";
        case 'Commande':
            return "Commande Client";
        case 'CommandeFournisseur':
            return "Commande Fournisseur";
        case 'Expedition':
            return "Livraison Client";
        case 'Preparation':
            return "Preparation";
        case 'Reception':
            return "Reception Fournisseur";
        case 'Facture':
            return "Facture Client";
        case 'Facture-Avoir':
            return "Facture Avoir";
        case 'Facture_Acompte':
            return "Facture Acompte";
        case 'FactureFournisseur':
            return "Facture Fournisseur";
        default:
            return type;
    }
}

export const convertStringToArray = (values: string) => {
    var productAttributes: IProductAttribute[] = [];
    var tab = values.split(';');
    tab.map((x: string, index) => {
        productAttributes.push({ key: index, text: x, value: x })
    })
    return productAttributes;
}
