import { useContext } from 'react';
import { Tab, Header, Button, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import CommandeClientEditForm from './CommandeClientEditForm';
import FormGridColumn from '../../../app/common/form/FormGridColumn';

const CommandeClient = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { commande, editInformationClientCommande, submitting } = baseRepo.commandeStore;

    return (
        <Tab.Pane>
            <Grid>
                <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                    <Header floated='left' icon='user' content={`Information de client: ${commande!.agencyName}`} />
                    <Button
                        onClick={() => { baseRepo.commandeStore.changeButtonState(!baseRepo.commandeStore.editCommandeMode) }}
                        floated='right'
                        basic
                        content={baseRepo.commandeStore.editCommandeMode ? 'Annuler' : 'Modifier'}
                    />
                </Grid.Column>
                <Grid.Column width={16}>
                    {baseRepo.commandeStore.editCommandeMode ? (
                        <CommandeClientEditForm editCommande={editInformationClientCommande} commande={commande!} submitting={submitting} />
                    ) : (
                        <Grid className="ui stackable">
                                <Grid.Row style={{ padding: 0, margin: 0 }}>
                                    <FormGridColumn header="Code Client" content={commande?.agencyName} />
                                    <FormGridColumn header="Nom D'agence" content={commande?.companyName} />
                                    <FormGridColumn header="Digit code" content={commande!.deliveryDigiCode} />
                                    <FormGridColumn header="Interphone" content={commande!.deliveryInterphone} />
                                    <FormGridColumn header="Horaires d'ouverture" content={commande!.openHours} />
                                    <FormGridColumn header="Jours de fermeture" content={commande!.closingDays} />
                                    <FormGridColumn header="Email" content={commande!.deliveryEmail && commande!.deliveryEmail.split(';').map(x => <>{x}<br /></>)} />
                            </Grid.Row>
                        </Grid>
                    )}
                </Grid.Column>
            </Grid>
        </Tab.Pane>
    );
}
export default observer(CommandeClient);


