import React, { useContext, useEffect } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router-dom';
import Loading from '../../../app/layout/Loading';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import Breadcrumbs from '../../dashboard/breadcrumbs';
import ArticleServiceContent from './ArticleServiceContent';
import ArticleServiceHeader from './ArticleServiceHeader';

interface RouteParams {
    id: string,
}

interface IProps extends RouteComponentProps<RouteParams> { }
const ArticleServicePage: React.FC<IProps> = ({ match }) => {
    const baseStore = useContext(BaseStoreContext);
    const { loadArticle, setActiveTab, loadingArticle, article, loadingInitial } = baseStore.articleStore;

    useEffect(() => {
        loadArticle(parseInt(match.params.id));
    }, [loadArticle, match])
    if (loadingArticle) return <Loading content="Chargement de l article ..." />
    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Articles", root: "" },
                    { key: 1, name: "Liste services", root: "/articleService" },
                    { key: 2, name: "" + article?.label, root: `/detailService/${article?.productId}` },
                ]}
            />
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Grid style={{ marginTop: 2 }}>
                            <Grid.Column width={16}>
                                <Header floated='left' content={'Mettre à jour le service '} />
                            </Grid.Column>
                            <Grid.Column width={16}>
                                <ArticleServiceHeader article={article!} />
                                <ArticleServiceContent setActiveTab={setActiveTab} article={article!} loading={loadingInitial} id={match.params.id}/>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}

export default observer(ArticleServicePage);