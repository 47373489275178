import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { Button, Header, Container, Form} from 'semantic-ui-react';
import { IEntrepot } from '../../app/models/IEntrepot';
import { IBonDePreparation } from '../../app/models/IPreparation';
import { BaseStoreContext } from '../../app/store/baseStore';

interface IProps {
    id: number;
}

const BonDePreparation: React.FC<IProps> = ({ id }) => {
    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    const { save} = baseStore.preparationStore;
    const { loadingInitial, loadEntrepots, entrepots } = baseStore.entrepotStore;
    const [entrepot, setEntrepot] = useState<IEntrepot>();


    useEffect(() => {
        loadEntrepots();
    }, [])

    const handleFinalFormSubmit = () => {
        var newPreparation: IBonDePreparation = {
            Order: id,
            EntrepotId: entrepot?.entrepotsId! ?? entrepots[entrepots.findIndex(x => x.isMain == true)]?.entrepotsId!,
        }
        save(newPreparation)
        closeModal()
    }

    return (
        <Container textAlign='center' >
            <Header icon='add' content='Créer Préparation' style={{ margin: "10px 0 40px 20px" }} />
            <Form.Group unstackable widths={1}>
                <div className="field" style={{ marginTop: 13 }}>
                    <Select
                        name='entrepots'
                        placeholder={entrepots[entrepots.findIndex(x => x.isMain == true)]?.libelle ?? "Séléctionnez un entrepôt.."}
                        isLoading={loadingInitial}
                        options={entrepots}
                        getOptionLabel={e => e!.libelle}
                        getOptionValue={e => e!.entrepotsId.toString()}
                        onChange={e => { setEntrepot(e!); }}
                    />
                </div>
            </Form.Group>
            <Button.Group style={{ marginTop: 30, marginBottom: 20 }}>
                <Button floated='right'
                    type='button'
                    size="small"
                    style={{ marginRight: 3 }}
                    onClick={(e) => {
                        closeModal()
                    }}>Annuler
                </Button>
                <Button positive floated='right'
                    onClick={handleFinalFormSubmit}
                    size="small"
                    type='submit'>Confirmer</Button>
            </Button.Group>
        </Container>
    )
}
export default observer(BonDePreparation);
