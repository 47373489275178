import { observer } from 'mobx-react-lite';
import React, { useContext,useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { TbShoppingCartDiscount } from 'react-icons/tb';
import { Button, Header, Container, Form, Segment, Input } from 'semantic-ui-react';
import { IRemiseAvoir } from '../../../app/models/IFacture';
import { BaseStoreContext } from '../../../app/store/baseStore';


interface IProps {
    Remise: IRemiseAvoir;
}

const FranchiseurRemiseModal: React.FC<IProps> = ({ Remise }) => {
    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    const [montant, setMontant] = useState(Remise.amountWithTaxes/2);
    const {PartitionRemiseAvoir } = baseStore.factureStore;
  
    const handleChangeMontant = (e: any, result: any) => {
        setMontant(result.value);
    }
  
    const handleFinalFormSubmit = (values: any) => {
        let newDevis: IRemiseAvoir = {
            ...values,
            id: Remise.id,
            montant: montant,
        };
        PartitionRemiseAvoir(newDevis);
        closeModal();
    }

    return (
        <Container textAlign='center' style={{ width: "100%" }} >
            <Segment style={{ backgroundColor: 'rgb(255,255,255)', padding: '20px' }}>
                <Header icon={<TbShoppingCartDiscount  />} content=' Partition de Remise Avoir' style={{ marginBottom: 20 }} />
                <FinalForm
                    onSubmit={handleFinalFormSubmit}
                    render={({ handleSubmit, submitting }) => (
                        <Form onSubmit={handleSubmit} error >
                            
                            <Form.Group unstackable widths={1}>
                                <label>Avoir 1</label>
                                    <Input 
                                    type='number'
                                    style={{ width: '283px' }}
                                    className="montant"
                                    onChange={handleChangeMontant}
                                    value={montant}
                                    step={0.01}
                                    />                                                                  
                            </Form.Group>
                            <Form.Group unstackable widths={1}>
                                <label>Avoir 2</label>
                                <Input
                                    disabled
                                    style={{ width: '283px' }}
                                    className="secondMontant"
                                    value={(Remise.amountWithTaxes - montant).toFixed(2)}
                                />

                            </Form.Group>
                           
                            <Form.Group unstackable style={{ marginLeft: "40%" }} >
                                <Button disabled={submitting} floated='right'
                                    type='button'
                                    onClick={(e) => {
                                        closeModal()
                                    }}>Annuler
                                </Button>
                                <Button.Or />
                                <Button positive loading={submitting} floated='right' type='submit'>Confirmer</Button>
                            </Form.Group>
                        </Form>
                    )}
                />
            </Segment >
        </Container>
    )
}
export default observer(FranchiseurRemiseModal);
