import { GridColumn } from "semantic-ui-react";
import { useState } from "react";
import Chart from "react-apexcharts";

import { observer } from "mobx-react-lite";
interface IProps {
    sales: number[]
    purchases: number[]
}
const StatisticsBarChart: React.FC<IProps> = ({ sales, purchases }) => {
    const d = new Date();
    const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
    const [state] = useState({
        options: {
            minHeight: 300,
            colors: ['#EA5826', '#FEBE39'],
            fill: {
                type: ['gradient','gradient'],
                gradient: {
                    shadeIntensity: 0.5,
                    type: 'diagonal',
                    opacityFrom: 1,
                    opacityTo: 1,
                  
                }
            },
            plotOptions: {
                bar: {
                    borderRadius: 5,
                }
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                categories: months.splice(d.getMonth() + 1, 12 - d.getMonth() +1 ).concat(months.splice(0, d.getMonth() + 1)),
                formatter: function (val: any) {
                    return val + "€";
                },
            },
            yaxis: {
 
                labels: {
                    formatter: function (val:any) {
                        return val + " €";
                    }
                }

            },
        },
        
    })
    return (
        <GridColumn style={{  borderRadius: 5 , minHeight:350, background:"white", textAlign:"center"}} >
            <span style={{ fontSize: 18, fontWeight: 700, fontFamily: 'serif' }}>Ventes et d'achats au cours des 12 derniers mois</span>

            <Chart
                
                options={state.options}
                series={[
                    {
                        name: "Ventes",
                        data: sales
                    },
                    {
                        name: "Achats",
                        data: purchases
                    }
                ]}
                type="bar"
                height = "80%"
            />
        </GridColumn>
    )
}
export default observer(StatisticsBarChart);



