import { BaseStore } from "./baseStore";
import { observable, action, runInAction } from 'mobx';
import agent from '../agent/agent';
import { toast } from "react-toastify";
import { setAccountProps } from "../common/util/util";
import { IAccount, IAccountList } from "../models/IAccount";

export default class AccountStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
    }
    @observable loadingAccountAction = false;
    @observable loadingAccount = false;
    @observable loadingInitialAccount = false;
    @observable submitting = false;
    @observable deletingAccount = false;
    @observable account: IAccount | null = null;
    @observable accounts: IAccount[] = [];
    @observable accountRegestry = new Map();
    @observable ITListAccount: IAccountList[] = [];
    @observable editAccount: boolean = false;
    @observable addMode: boolean = false;
    @observable progress: number = 50;

    @action changeButtonStateEdit = async (newState: boolean) => {
        this.editAccount = newState;
    }

    @action changeButtonStateAdd = async (newState: boolean) => {
        this.addMode = newState;
    }

    @action loadAccounts = async () => {
        this.loadingInitialAccount = true;
        this.ITListAccount = [];
        try {
            const Accounts = await agent.accountAgent.list();
            runInAction(() => {
                this.accounts = Accounts;
                Accounts.forEach((Account) => {
                    setAccountProps(Account);
                    this.accountRegestry.set(Account.id, Account);
                    let Accountt: IAccountList = {
                        key: Account.id,
                        text: Account.name,
                        value: Account.name
                    }
                    this.ITListAccount.push(Accountt)
                })
                this.loadingInitialAccount = false;
            })
        } catch (e) {
            toast.error("Problem loading comptes!");
            runInAction(() => {
                this.loadingInitialAccount = false;
            })
        }
    }

    @action loadAccount = async (id: string) => {

        this.loadingAccount = true;
        let account = this.getAccount(id);
        if (account) {
            this.account = account;
            this.loadingAccount = false;
            return account;
        }
        else {
            try {
                account = await agent.accountAgent.details(id);
                runInAction(() => {
                    this.account = account;
                    this.loadingAccount = false;
                })
                return account;
            } catch (e) {
                runInAction(() => {
                    this.loadingAccount = false;
                })
                console.log(e);
            }
        }
    }

    getAccount = (id: string) => {
        return this.accountRegestry.get(id);
    }

    @action create = async (values: IAccount) => {
        this.submitting = true;
        const user = await agent.userAgent.current();
        try {
            await agent.accountAgent.create(values);
            runInAction(() => {
                this.accountRegestry.set(values.id, values)
                this.account = values;
                this.accounts.push(this.account);
                this.submitting = false;
                toast.success("ajout éffectué avec succès");
            })

        } catch (e) {
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    @action edit = async (values: Partial<IAccount>) => {
        this.submitting = true;
        this.progress = 80;
        try {
            await agent.accountAgent.update(values);
            let account = await agent.accountAgent.details(values.id!);
            runInAction(() => {
                this.accounts = this.accounts.filter(a => a.id != values.id);
                this.accounts.push(account);
                toast.success("Modification éffectuée avec succès");
                this.submitting = false;
            })

        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action deleteAccount = async (id: string) => {
        this.loadingAccountAction = true;
        var mode = await agent.accountAgent.details(id);
        try {
            await agent.accountAgent.delete(id);
            runInAction(() => {
                this.loadingAccountAction = false;
                this.accounts = this.accounts.filter(a => a.id !== id);
                toast.success("suppression éffectuée avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de suppréssion de compte!");
            runInAction(() => {
                this.loadingAccountAction = false;
            })
        }

    }
}