import { observer } from "mobx-react-lite";
import { Fragment, useContext, useState } from "react";
import { FormInput, Header, Menu } from "semantic-ui-react";
import { BaseStoreContext } from "../../app/store/baseStore";


const AlertStockFilter = () => {

    const baseStore = useContext(BaseStoreContext);
    const { predicate, setPredicate } = baseStore.articleStockStore;
    const [searchResults, setSearchResults] = useState([]);

    const handleChange = (event: any) => {
        setSearchResults(event.target.value);
        if (event.target.value.length > 2)
            setPredicate('search', event.target.value)
        if (event.target.value.length === 0)
            setPredicate('all', 'true')
    }
 
    const handleFilter = (key: string, value: string) => {
        setPredicate(key, value);
        setSearchResults([]);
    }

    return (
        <Fragment>
            <Menu vertical size={'large'} style={{ width: '100%', marginTop: 15 }}>
                <Header icon={'filter'} attached color='teal' content={'Filters'} />
                <FormInput
                    icon='search'
                    type="text"
                    placeholder="Rechercher"
                    value={searchResults}
                    onChange={handleChange}
                    style={{ width: '100%' }}
                />
          
                <Menu.Item
                    active={predicate.size === 0}
                    onClick={() => handleFilter('all', 'true')}
                    color='teal'
                    name={'all'}
                    content={'Toutes les alert'}
                />
            </Menu>
        </Fragment>
    );
};
export default observer(AlertStockFilter);