import { Item, Segment, Icon, Button } from 'semantic-ui-react';
import { IClient } from '../../../app/models/IClient';
import { Link } from 'react-router-dom';
import React, { useContext } from 'react';
import AddCommandeClientModal from '../../commande/addCommande/AddCommandeClientModal';
import { BaseStoreContext } from '../../../app/store/baseStore';

const stylesNonactif = {
    border: 'solid',
    borderColor:'red',
    borderWidth: 2
}

const ClientListItem: React.FC<{ client: IClient }> = ({ client }) => {
    const baseStore = useContext(BaseStoreContext);
    const { openModal } = baseStore.modalStore;

    return (
        <Segment.Group>
            <Segment>
                <Item.Group>
                    <Item>
                            <Item.Image
                            size='small'
                            src={client.uri}
                            style={client.isActive ? null : stylesNonactif} />
                        <Item.Content>
                            <Item.Header as={Link} to={`/detailClient/${client.id}`}>
                               {client.companyName}
                            </Item.Header>
                            <Item.Description>
                                <div>Code : <span style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }}>{client.agencyName}</span></div>
                                Type Client : <span style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }}>{client.companyType}</span>
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Segment>
            <Segment>
                <Icon name='mail' />{client.invoiceEmails?.split(';').map(x => x + " / ")} &nbsp;
                <Icon name='fax' />{client.invoicePhone} &nbsp;
                <Icon name='mobile alternate' />{client.deliveryPhone} &nbsp;
                <Icon name='user' />{client.deliveryLastName} {client.deliveryFirstName}
            </Segment>
            <Segment>
                <Icon name='address card' /> Adresse de facturation : {client.invoiceAddress1}, {client.invoicePostalCode}, {client.invoiceCity}
            </Segment>
            <Segment>
                <Icon name='address card outline' /> Adresse de livraison : {client.deliveryAddress1}, {client.deliveryPostalCode}, {client.deliveryCity}
            </Segment>
            <Segment>
                <Icon name='payment' />Mode de paiement: {client.modePaiement}
            </Segment>

            <Segment clearing>
                <Button
                    as={Link}
                    to={`/detailClient/${client.id}`}
                    floated='right'
                    content='Détail'
                    color='blue'
                />
                <Button
                    name={client.id}
                    content="Ajouter Commande"
                    color="orange"
                    floated='right'
                    as={Link}
                    onClick={(e) => {
                        openModal(<AddCommandeClientModal client={client} />)
                    }}
                />            
            </Segment>
        </Segment.Group>);
}

export default ClientListItem;