import React, { useContext } from 'react';
import { Segment, Grid, Modal, Button } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import FileUploadWidget from '../../../app/common/fileUpload/FileUploadWidget';
import { observer } from 'mobx-react-lite';
import { IInfo } from '../../../app/models/IClient';
import { history } from '../../..';



const AddFranchise: React.FC = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { loadFile, addFranchise, setModal, count, infos, setChangeStateModal } = baseRepo.clientStore;
    const handleUploadFile = (file: Blob) => {
        addFranchise(file);
    }

    return (
        <Segment basic>
            <Grid>
                <Grid.Column width={16}>
                    <FileUploadWidget
                        uploadFile={handleUploadFile}
                        loading={loadFile}
                    />
                </Grid.Column>
            </Grid>
            <Modal
                open={setModal}
                size='mini'
                
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >
                <Modal.Header icon='add' >
                    Client(s) ajouté(s)
                    <Button
                        style={{ height: 35 }}
                        type='button' icon='x' color='red'
                        floated='right'
                        onClick={() => {
                            history.push('/franchiseurDashboard');
                            setChangeStateModal(false);
                        }}
                    >
                    </Button>
                </Modal.Header>
                <Modal.Content style={{ height: 260, overflow : 'scroll' }}>
                    Vous avez {count} clients ajoutés: 

                    {infos && infos.map((info: IInfo, key) => (
                        <>
                            <br />
                            <li>

                            <span>
                                {info.agencyName}-{info.companyName}
                                </span>
                                </li>

                        </>))}
                </Modal.Content>

            </Modal>
        </Segment >
    );
}
export default observer(AddFranchise);
