import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { Button, Header, Icon, Container } from 'semantic-ui-react';
import { IModeReglement } from '../../../app/models/IModeReglement';
import { BaseStoreContext } from '../../../app/store/baseStore';

interface IProps {
    modeReglement: IModeReglement;
}

const DeleteModeReglementModal: React.FC<IProps> = ({ modeReglement }) => {
    const baseStore = useContext(BaseStoreContext);
    const { closeModal } = baseStore.modalStore;
    const { loadCountReglement, deleteModeReglement, countReglement } = baseStore.modeReglementStore;
    useEffect(() => {
        loadCountReglement(modeReglement.reglementId);
    }, []);
    return (
        <Container textAlign='center'>
            <Header icon='trash' content='Supprimer échéance' style={{ marginLeft: 20 }} />
            <p>
                Attention ! Ce mode est affecté a : <br/>
                client(s) : {countReglement?.nbreClients}<br />
                Franchiseur(s) : {countReglement?.nbreFranchiseurs}<br />
                Fournisseur(s) : {countReglement?.nbreFournisseurs}
            </p>

            <Button.Group fluid>
                <Button onClick={() => closeModal()}>
                    <Icon name='remove' /> Annuler
                </Button>
                <Button.Or text='Ou' />
                <Button
                    name={modeReglement.reglementId}
                    color='green'
                    onClick={(e) => {
                        deleteModeReglement(modeReglement.reglementId)
                        closeModal()
                    }}>
                    <Icon name='checkmark' /> Confirmer
                </Button>
            </Button.Group>

        </Container>
    )
}
export default observer(DeleteModeReglementModal);
