import { useContext, useEffect } from 'react';
import { Tab, Header, Button, Grid, Table } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import { TbShoppingCartDiscount } from 'react-icons/tb';
import { AiOutlineShareAlt } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import FranchiseurRemiseModal from './FranchiseurRemiseModal';
import { numberFormat } from '../../../app/common/options/IStyle';

interface UrlParams {
    id: string;
}


const FranchiseurRemise: React.FC = () => {
    const baseStore = useContext(BaseStoreContext)
    const { getRemiseFranchiseur, FranchiseurRemise } = baseStore.factureStore;
    const { id } = useParams<UrlParams>();
    const { openModal } = baseStore.modalStore;
    useEffect(() => {
        getRemiseFranchiseur(parseInt(id));
    }, [getRemiseFranchiseur])
    return (
        <Tab.Pane>
            <Grid>
                <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                    <Header floated='left' icon={<TbShoppingCartDiscount style={{ marginRight: 10, marginTop: 4 }} />} content=" Avoirs" />
                </Grid.Column>
                <Grid.Column width={16}>

                    <Table loading={false} celled style={{ textAlign: "center" }}>
                        <Table.Header >
                            <Table.Row>
                                <Table.HeaderCell>Ref</Table.HeaderCell>
                                <Table.HeaderCell>Consommation</Table.HeaderCell>
                                <Table.HeaderCell>Montant TTC</Table.HeaderCell>
                                <Table.HeaderCell>Date</Table.HeaderCell>
                                <Table.HeaderCell style={{ textAlign: "center" }}>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        {FranchiseurRemise &&
                            FranchiseurRemise.map(Remise => (
                                <Table.Body>
                                    <Table.Cell><a href={`/detailFacture/${Remise.invoiceIdOrigin}`}>{Remise.reference} </a></Table.Cell>
                                    <Table.Cell>{Remise.used ? "Consommée" : "Non consommée"}</Table.Cell>
                                    <Table.Cell>{numberFormat.format(Remise.amountWithTaxes)}</Table.Cell>
                                    <Table.Cell>{new Date(Remise.remiseAvoirDate).toLocaleDateString('en-GB')}</Table.Cell>
                                    {!Remise.used &&
                                        <Table.Cell>
                                            <Button
                                                basic
                                                color="blue"
                                                icon={<AiOutlineShareAlt />}
                                                onClick={(e) => {
                                                    openModal(<FranchiseurRemiseModal Remise={Remise} />)
                                                }}
                                            />
                                        </Table.Cell>
                                    }
                                </Table.Body>
                            ))}
                    </Table>

                </Grid.Column>
            </Grid>
        </Tab.Pane>
    );
};
export default observer(FranchiseurRemise);
