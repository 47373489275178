import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { Button, Form, FormInput, Grid } from "semantic-ui-react";
import { Form as FinalForm } from 'react-final-form';
import Select, { components } from "react-select";
import { FaSearch, FaSort } from "react-icons/fa";
import AsyncSelect from 'react-select/async';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { MdCancel } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BaseStoreContext } from "../../app/store/baseStore";

const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <FaSort color='green' />
        </components.DropdownIndicator>
    );
};

const customStyles = {
    option: (provided: any, state: { isSelected: any; }) => ({
        ...provided,
        color: state.isSelected ? 'orange' : 'grey',
        backgroundColor: state.isSelected ? "#eee" : "white",
        borderBottom: '1px solid #eee',
    }),
    valueContainer: (props: any) => ({
        ...props,
        paddingTop: 0,
        height: '39.201px',
        minHeight: '39.201px',
    }),
    control: (baseStyles: any, state: any) => ({
        ...baseStyles,
        height: '39.201px',
        minHeight: '39.201px',
    }),
}

const SoldesFilter = () => {
    const { RangePicker } = DatePicker;
    const baseStore = useContext(BaseStoreContext);
    const {loadingExport, ExportSolde, setPredicateSolde } = baseStore.clientStore;
    const [searchDateCommande, setSearchDateCommande] = useState<any>('');
    const [searchDateCommandeTo, setSearchDateCommandeTo] = useState<any>('');
    const [searchResultsClient, setSearchResultsClient] = useState<any>('');
    const [selectedLabelClient, setSelectedLabelClient] = useState<any>('Client');
    const [selectedSolde, setSolde] = useState<any>(0);
    const [searchResultsClientAll, setSearchResultsClientAll] = useState(false);

    const rangePresets: {
        label: string;
        value: [Dayjs, Dayjs];
    }[] = [
            { label: '7 derniers jours', value: [dayjs().add(-7, 'd'), dayjs()] },
            { label: '14 derniers jours', value: [dayjs().add(-14, 'd'), dayjs()] },
            { label: '30 derniers jours', value: [dayjs().add(-30, 'd'), dayjs()] },
            { label: '90 derniers jours', value: [dayjs().add(-90, 'd'), dayjs()] },
        ];



    const handleChangeClient = (value: any) => {
        setSearchResultsClient(value.id);
        setSearchResultsClientAll(value.all);
        setSelectedLabelClient(value!.agencyName + "-" + value!.companyName + (value!.all ? "-Tous les réseaux" : ""));
    }
    const handleChangeSolde = (event: any) => {
        setSolde(event.target.value);
    }

    const onRangeChange = (dates: null | (Dayjs | null)[]) => {
        if (dates) {
            setSearchDateCommande(dates[0]?.toISOString());
            setSearchDateCommandeTo(dates[1]?.toISOString());
        }
    };
    const handleSearch = (event: any) => {

        if (event == 'clear') {
            setSearchDateCommande('')
            setSearchDateCommandeTo('')
            setSearchResultsClient('')
            setSolde('')
            setSelectedLabelClient('Client')
            setPredicateSolde('all', 'true')
        }
        else if (event == 'submit') {
            var predicate = new Map()
            predicate.set('searchClient', searchResultsClient)
            predicate.set('solde', selectedSolde)
            predicate.set("dateCommande", searchDateCommande);
            predicate.set("dateCommandeTo", searchDateCommandeTo);
            predicate.set('searchResultsClientAll', searchResultsClientAll)
            if (searchResultsClient != "" || selectedSolde != 0 || searchDateCommande != "" || searchDateCommandeTo != "" )
                setPredicateSolde('searchMultiple', predicate)
            else if (searchResultsClient == "" && selectedSolde == 0 && searchDateCommande == "" && searchDateCommandeTo == "" )
                setPredicateSolde('all', 'true')
           
        }
        else if (event == 'clique') {
            let obj: any = {
                searchClient: searchResultsClient,
                searchResultsClientAll: searchResultsClientAll,
                solde: selectedSolde,
                dateCommande: searchDateCommande,
                dateCommandeTo: searchDateCommandeTo,
                selectedLabelClient: selectedLabelClient
            }; ExportSolde(obj);
        }
    }
    return (
        <FinalForm
            onSubmit={handleSearch}
            render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit} error style={{ width: "100%", fontSize: 14 }}>
                    <Grid>
                        <Grid.Row columns={4} style={{ padding: 0, margin: 0 }}>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <AsyncSelect
                                    name="form-field-name"
                                    noOptionsMessage={() => "Code introuvable"}
                                    cacheOptions
                                    defaultOptions
                                    placeholder={selectedLabelClient}
                                    value={searchResultsClient}
                                    getOptionLabel={e=>e!.agencyName + "-" + e!.companyName + (e!.all ? "-Tous les réseaux" : "")}
                                    loadOptions={baseStore.clientStore.loadOptions}
                                    onChange={handleChangeClient}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                 <FormInput
                                    name="solde"
                                    value={selectedSolde}
                                    style={{ height: '100%' }}
                                    onChange={handleChangeSolde}
                                    type='number'
                                    step="0.0001"

                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>

                                <RangePicker
                                    presets={rangePresets}
                                    onChange={onRangeChange}
                                    format={'DD/MM/YYYY'}
                                    placeholder={["Début", "Fin"]}
                                    style={{ width: '100%', height: '39.201px' }}
                                />

                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0, display: 'flex' }}>
                                <Button style={{ padding: 5, margin: "0 0 0 10px", color: 'blue', background: "none" }} type='submit' onClick={(e: any) => handleSearch('submit')} >
                                    <FaSearch />
                                </Button>
                                <Button style={{ float: "right", padding: 5, margin: 0, color: 'green', background: "none" }} >
                                    <MdCancel style={{ fontSize: 17 }} onClick={(e: any) => handleSearch('clear')} />
                                </Button>
                                <Button loading={loadingExport} style={{ float: "right", padding: 5, margin: 0, color: 'green', background: "none" }} >
                                    <RiFileExcel2Fill style={{ fontSize: 17 }} onClick={(e: any) => handleSearch('clique')} />
                                </Button>
                            </Grid.Column >

                        </Grid.Row>
                    </Grid>
                </Form >
            )}
        />
    );
};
export default observer(SoldesFilter);