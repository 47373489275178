import { observer } from "mobx-react-lite";
import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { downloadExcel } from "react-export-table-to-excel";
import { FaFileExcel } from "react-icons/fa";
import { Item, Loader, Button, Icon } from "semantic-ui-react";
import { BaseStoreContext } from "../../../app/store/baseStore";

interface IProps {
    id: number;
    agencyName: string;
    setModalOpen: Dispatch<SetStateAction<boolean>>;

}

const ListPacksModal: React.FC<IProps> = ({ id, agencyName, setModalOpen }) => {
    const baseStore = useContext(BaseStoreContext);
    const { listPackAmount, loadingDownloadPack, exportOpeningPack } = baseStore.franchiseurStore;
    const header = ["Code Client", "Client", "NOM", "PRENOM","MAIL","Solde restant de pack"];
    useEffect(() => {
        exportOpeningPack(id);
    }, []);
    function handleDownloadExcel() {
        downloadExcel({
            fileName:`SOLDE-PACK-OUVERTURE-${agencyName}`,
            sheet: "SOLDE-PACK-OUVERTURE",
            tablePayload: {
                header,
                body: listPackAmount,
            },
        });
    }

    return (
        <Item style={{ }}>
            <Item.Content>
                {loadingDownloadPack ?
                    <Loader content="Loading..." /> :

                    (
                        <div style={{ margin: 30 }}>     
                            <Button onClick={() => setModalOpen(false)} style={{ textAlign: 'right', justifyContent: 'center', height: '100%', marginBottom: "5px" }} floated="right" color="red">
                                <Icon name='remove' /> Fermer
                            </Button>
                            <Button
                                style={{ textAlign: 'right', justifyContent: 'center', height: '100%', marginBottom: "5px" }}
                                content="Export excel"
                                floated="right"
                                onClick={handleDownloadExcel}
                                className="andi-btn filled success"
                                icon={(<FaFileExcel style={{ marginRight: "10px", fontSize: "20px" }} />)}
                            />
                            <table className="scrollTable" style={{ borderCollapse: 'collapse', width: '100%',marginTop:20, border: '1px solid #eee', borderRadius: 5 }}>
                                <tbody className="ui celled striped table" >
                                    <tr style={{ color: 'blue', textAlign: "center" }}>
                                        {header.map((head) => (
                                            <th key={head}> {head} </th>
                                        ))}
                                    </tr>
                                    {listPackAmount.map((item, i) => (
                                        <tr key={i} >

                                            <td key={item.agencyName}>{item.agencyName}</td>
                                            <td key={item.agencyName}>{item.companyName}</td>
                                            <td key={item.agencyName}>{item.deliveryFirstName}</td>
                                            <td key={item.agencyName}>{item.deliveryLastName}</td>
                                            <td key={item.agencyName}>{item.deliveryEmails?.split(';').map(x => <>{x}</>)}</td>
                                            <td style={{width:100}} key={item.agencyName}>{item.openingPackAmount}</td>
                                        
                                        </tr>
                                    ))}
                                </tbody>
                            </table>                         
                        </div>
                    )}
            </Item.Content>
        </Item>
    );
}

export default observer(ListPacksModal);