import { BaseStore } from "./baseStore";
import { observable, action, runInAction} from 'mobx';
import agent from '../agent/agent';
import { toast } from "react-toastify";
import { IOption, IOptionList } from "../models/IOption";

export default class ImpressionStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
    }
    @observable loadingOptionAction = false;
    @observable loadingOption = false;
    @observable loadingInitial = false;
    @observable submittingOption = false;
    @observable deletingOption = false;
    @observable option: IOption | null = null;
    @observable options: IOption[] = [];
    @observable optionRegestry = new Map();
    @observable ITListOption: IOptionList[] = [];
    @observable ITListOptionArticle: IOptionList[] = [];
    @observable editOption: boolean = false;
    @observable addMode: boolean = false;
    @observable progress: number = 50;

    @action changeButtonStateEdit = async (newState: boolean) => {
        this.editOption = newState;
    }

    @action changeButtonStateAdd = async (newState: boolean) => {
        this.addMode = newState;
    }

    @action loadOptions = async () => {
        this.loadingInitial = true;
        this.ITListOption = [];
        try {
            const options = await agent.optionAgent.list();
            runInAction(() => {
                options.forEach((option) => {
                    let optionss: IOptionList = {
                        key: option.optionId,
                        label: option.libelle,
                        value: option.libelle
                    }
                    this.ITListOption.push(optionss)
                })
                this.options = options;
                this.loadingInitial = false;
            })
        } catch (e) {
            toast.error("Problem loading option!");
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }

    getOption = (id: string) => {
        return this.optionRegestry.get(id);
    }

    @action create = async (values: IOption) => {
        this.submittingOption = true;
        try {
            await agent.optionAgent.create(values);
            runInAction(() => {
                this.optionRegestry.set(values.optionId, values)
                this.option = values;
                const newOption: IOptionList = {
                    key: values.optionId,
                    label: values.libelle,
                    value: values.libelle
                }
                this.ITListOption.push(newOption);
                this.options.push(this.option);
                this.submittingOption = false;
                toast.success("ajout éffectué avec succès");
            })

        } catch (e) {
            runInAction(() => {
                this.submittingOption = false;
                console.log(e);
            })
        }
    }

    @action edit = async (values: Partial<IOption>) => {
        this.submittingOption = true;
        this.progress = 80;
        try {
            await agent.optionAgent.update(values);
            let option = await agent.optionAgent.details(values.optionId!);
            runInAction(() => {
                this.options = this.options.filter(a => a.optionId != values.optionId);
                this.options.push(option);
                toast.success("Modification éffectuée avec succès");
                this.submittingOption = false;
            })

        } catch (e) {
            runInAction(() => {
                this.submittingOption = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action deleteOption = async (id: string) => {
        this.loadingOptionAction = true;
        var mode = await agent.optionAgent.details(id);
        try {
            await agent.optionAgent.delete(id);
            runInAction(() => {
                this.loadingOptionAction = false;
                this.options = this.options.filter(a => a.optionId !== id);
                toast.success("suppression éffectuée avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de suppréssion d'option!");
            runInAction(() => {
                this.loadingOptionAction = false;
            })
        }

    }

    @action loadOptionsArticle = async (id: string) => {
        this.loadingOption = true;
        this.ITListOptionArticle = [];
        try {
            const optionsArticle = await agent.optionAgent.listByArticle(id);
            runInAction(() => {
                optionsArticle.forEach((optionArticle) => {
                    let optionn: IOptionList = {
                        key: optionArticle.option.optionId,
                        label: optionArticle.option.libelle,
                        value: optionArticle.option.optionId
                    }
                    this.ITListOptionArticle.push(optionn)
                })
                this.loadingOption = false;
            })
        } catch (e) {
            toast.error("Problem loading option article!");
            runInAction(() => {
                this.loadingOption = false;
            })
        }
    }

    loadOptionsProduct = async (id: string) => {
        return await agent.optionAgent.listByArticle(id).then(r => r);
    };
}