import { BaseStore } from "./baseStore";
import { observable, action, runInAction, computed, reaction } from 'mobx';
import agent from '../agent/agent';
import { toast } from "react-toastify";
import { history } from '../../index';
import { IArticle } from "../models/IArticle";
import { IDetailExpedition, IDispatch, IDispatchOrder, IExpeditionCommande, IOrderDispatch, IReliquat } from "../models/IExpedition";
import { IEntrepot, IEntrepotList } from "../models/IEntrepot";

const LIMIT = 20;

export default class ExpeditionStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0;
                this.loadExpeditionList();
            }
        )
        reaction(
            () => this.predicateReliquat.keys(),
            () => {
                this.pageReliquats = 0;
                this.loadReliquatsList();
            }
        )
        reaction(
            () => this.activeTab,
            activeTab => {
                if (activeTab != '')
                    return true;
            }
        )
    }
    @observable submitting = false;
    @observable validate = false;
    @observable loadingInitialExpedition = false;
    @observable loadingInitialReliquat = false;
    @observable loadingInitialExpeditionAF = false;
    @observable loadingInitialRetourAF = false;
    @observable loadingInitial = false;
    @observable downloading = false;
    @observable loadingReliquat = false;
    @observable loadingEntrepots = false;
    @observable loadingProducts = false;
    @observable loadingStock = false;
    @observable loadingExport = false;
    @observable loadingExportEx = false;
    @observable expeditions: IExpeditionCommande[] = [];
    @observable expeditionsAF: IDispatch[] = [];
    @observable retourAF: IDispatch[] = [];
    @observable ITList: IDetailExpedition[] = [];
    @observable reliquats: IReliquat[] = [];
    @observable ListEntrepot: IEntrepot[] = [];
    @observable ITAllListEntrepot: IEntrepotList[] = [];
    @observable editExpedition: boolean = false;
    @observable addExpedition: boolean = false;
    @observable expeditionCount: number = 0;
    @observable reliquatsCount: number = 0;
    @observable totalTtc: number = 0;
    @observable totalHt: number = 0;
    @observable totaux: number = 0;
    @observable page = 0
    @observable pageReliquats = 0
    @observable predicate = new Map();
    @observable predicateReliquat = new Map();
    @observable ITListArticle: IArticle[] = [];
    @observable isDispatched: boolean = false;
    @observable orderDispatch: IDispatchOrder | null = null;
    @observable generatePdfMode = false;
    @observable activeTab: string = "0";
    @observable isTerminated: boolean = false;
    @observable deletingOrder = false;
    @observable loadBtnCancel = false;


    @computed get axiosParams() {
        const params = new URLSearchParams();
        params.append('limit', String(LIMIT));
        params.append('offset', `${this.page ? this.page * LIMIT : 0}`);
        params.append('limitReliquat', String(LIMIT));
        params.append('offsetReliquat', `${this.pageReliquats ? this.pageReliquats * LIMIT : 0}`);
        this.predicateReliquat.forEach((value, key) => {
            if (key === 'startDate') {
                params.append(key, value.toISOString())
            }
            if (key === 'searchMultiple') {
                var searchClient = [...value][0];
                var searchCommande = [...value][1];
                var dateCommande = [...value][2];
                var dateCommandeTo = [...value][3];
                var article = [...value][4];
                var searchClientAll = [...value][5];
                params.append(searchClient[0], searchClient[1])
                params.append(searchCommande[0], searchCommande[1])
                params.append(dateCommande[0], dateCommande[1])
                params.append(dateCommandeTo[0], dateCommandeTo[1])
                params.append(article[0], article[1])
                params.append(searchClientAll[0], searchClientAll[1])
            }
            else {
                params.append(key, value)
            }
        })
        this.predicate.forEach((value, key) => {
            if (key === 'startDate') {
                params.append(key, value.toISOString())
            } if (key === 'searchMultiple') {
                var search = [...value][0];
                var searchClient = [...value][1];
                var searchCommande = [...value][2];
                var Key = [...value][3];
                var sort = [...value][4];
                var dateCommande = [...value][5];
                var dateCommandeTo = [...value][6];
                var type = [...value][7];
                var modeAppro = [...value][8];
                var modePaiement = [...value][9];
                var article = [...value][10];
                var searchClientAll = [...value][11];
                params.append(search[0], search[1])
                params.append(searchClient[0], searchClient[1])
                params.append(searchCommande[0], searchCommande[1])
                params.append(Key[0], Key[1])
                params.append(sort[0], sort[1])
                params.append(dateCommande[0], dateCommande[1])
                params.append(dateCommandeTo[0], dateCommandeTo[1])
                params.append(type[0], type[1])
                params.append(modeAppro[0], modeAppro[1])
                params.append(modePaiement[0], modePaiement[1])
                params.append(article[0], article[1])
                params.append(searchClientAll[0], searchClientAll[1])
            } else {
                params.append(key, value)
            }
        })
        return params;
    }

    @computed get totalPages() {
        return Math.ceil(this.expeditionCount / LIMIT);
    }

    @action setPage = (page: number) => {
        this.page = page;
    }

    @computed get totalPageReliquats() {
        return Math.ceil(this.reliquatsCount / LIMIT);
    }

    @action setPageReliquat = (page: number) => {
        this.pageReliquats = page;
    }

    @action setPredicate = (predicate: string, value: any) => {
        if (this.predicate.get(predicate)) {
            this.expeditions = [];
        }
            this.predicate.clear();
        
        if (predicate == 'all') {
            this.predicate.clear();
        }
        else {
            this.predicate.set(predicate, value);
        }
    }
    @action setPredicateReliquat = (predicate: string, value: any) => {
        if (this.predicateReliquat.get(predicate)) {
            this.reliquats = [];
            this.predicateReliquat.clear();
        }
        if (predicate == 'all') {
            this.predicateReliquat.clear();
        }
        else {
            this.predicateReliquat.set(predicate, value);
        }
    }

    @action changeButtonStateEdit = async (newState: boolean) => {
        this.editExpedition = newState;
    }

    @action changeButtonStateAdd = async (newState: boolean) => {
        this.addExpedition = newState;
    }
    @action changeButtonPdfState = async (newState: boolean) => {
        this.generatePdfMode = newState;
    }

    @action loadExpeditionList = async () => {
        this.loadingInitialExpedition = true;
        try {
            const stockEnveloppe = await agent.expeditionAgent.list(this.axiosParams);
            const { dispatch, dispatchCount, totalHt, totalTtc } = stockEnveloppe;
            runInAction(() => {
                    if (this.page == 0) {
                        this.expeditions = dispatch;
                    } else {
                        dispatch.map(orderDispatch => {
                            this.expeditions.push(orderDispatch);
                        })
                    }
                this.expeditionCount = dispatchCount;
                this.totalTtc = totalTtc;
                this.totalHt = totalHt;
                this.loadingInitialExpedition = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingInitialExpedition = false;
            })
        }
    }

    @action loadReliquatsList = async () => {
        this.loadingInitialReliquat = true;
        try {
            const reliquatsEnveloppe = await agent.expeditionAgent.listReliquats(this.axiosParams);
            const { reliquats, reliquatsCount,totaux } = reliquatsEnveloppe;
            runInAction(() => {
                if (this.pageReliquats == 0) {
                    this.reliquats = reliquats;
                    this.totaux = totaux;
                }
                else {
                    reliquats.forEach((reliquat) => { this.reliquats.push(reliquat); })
                    this.totaux += totaux;
                }
                this.reliquatsCount = reliquatsCount;
                this.loadingInitialReliquat = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingInitialReliquat = false;
            })
        }
    }


    @action loadExpeditionAFacturerList = async (obj: any) => {
        this.loadingInitialExpeditionAF = true;
        try {
            const dispatchEnv = await agent.expeditionAgent.getBlToBeInvoiced(obj);
            const { dispatchs, totalHt, totalTtc } = dispatchEnv;

            runInAction(() => {
                this.expeditionsAF = dispatchs;
                this.totalTtc = totalTtc;
                this.totalHt = totalHt;
                this.loadingInitialExpeditionAF = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingInitialExpeditionAF = false;
            })
        }
    }

    @action loadRetourAFacturerList = async (obj: any) => {
        this.loadingInitialRetourAF = true;
        try {
            const dispatchEnv = await agent.expeditionAgent.getBrToBeInvoiced(obj);
            const { dispatchs, totalHt, totalTtc } = dispatchEnv;

            runInAction(() => {
                this.retourAF = dispatchs;
                this.totalTtc = totalTtc;
                this.totalHt = totalHt;
                this.loadingInitialRetourAF = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingInitialRetourAF = false;
            })
        }
    }

    @action ExportBLAFacture = async (obj: any) => {
        this.loadingInitial = true;
        try {
            await agent.expeditionAgent.exportExcel(obj);
            runInAction(() => {
                this.loadingInitial = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingInitial = false;
            })
            console.log(e);
        }
    }

    @action ExportBRAFacture = async (obj: any) => {
        this.loadingInitial = true;
        try {
            await agent.expeditionAgent.exportBrToBeInvoiced(obj);
            runInAction(() => {
                this.loadingInitial = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingInitial = false;
            })
            console.log(e);
        }
    }


    @action create = async (idExpedition: number) => {
        this.submitting = true;
        try {
            var id = await agent.expeditionAgent.create(idExpedition);
            runInAction(() => {
                toast.success("stock expédier avec succès");
                history.push(`/detailExpedition/${id}`)
                this.submitting = false;
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
        }
    }

    @action loadExpeditionLineByIdExpedition = async (id: number) => {
        this.loadingInitialExpedition = true;
        let status = true;
        this.ITList = [];
        try {
            let expedition = await agent.expeditionAgent.details(id);
            runInAction(() => {
                this.orderDispatch = expedition
                expedition.orderDispatchLines.forEach((item) => {
                    this.ITList.push(item);
                    if (item.dispatchLineState == "EnAttente" || item.dispatchLineState == "Expedie")
                        status = false;
                })
                this.isDispatched = status;
                this.loadingInitialExpedition = false;
            })
        } catch (e) {
            toast.error("Problem loading forme stock!");
            runInAction(() => {
                this.loadingInitialExpedition = false;
            })
        }
    }

    @action generatePDF = async (id: number) => {
        try {
            await agent.expeditionAgent.generatePDF(id);
            toast.success("téléchargement avec succès")
        }
        catch (e) {
            console.log(e);
        }
    }

    @action downloadBLValorisé = async (id: number) => {
        this.downloading = true;
        try {
            await agent.expeditionAgent.downloadBLValorisé(id);
            toast.success("téléchargement avec succès")
            runInAction(() => {
                this.downloading = false;
            })
        }
        catch (e) {
            console.log(e);
            runInAction(() => {
                this.downloading = false;
            })
        }
    }

    @action loadEntrepotByPreparationLine = async (id: number) => {
        this.loadingEntrepots = true;
        this.ITAllListEntrepot = [];
        try {
            const entrepot = await agent.expeditionAgent.listEntrepotByPreparationLines(id);
            runInAction(() => {
                this.ListEntrepot = entrepot;
                entrepot.forEach((depot) => {
                    let ENTREPOT: IEntrepotList = {
                        key: depot.entrepotsId,
                        text: depot.libelle,
                        value: depot.entrepotsId
                    }
                    this.ITAllListEntrepot.push(ENTREPOT)
                })
                this.loadingEntrepots = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingEntrepots = false;
            })
        }
    }

    @action editStatusOrderDispatchLine = async (values: number) => {
        this.validate = true;
        try {
            await agent.expeditionAgent.updateStatusOrderDispatchLine(values);
            runInAction(() => {
                this.orderDispatch!.dispatchState = "Expedie";
                this.ITList.filter(x => x.orderDispatchs == values).forEach(item => {

                    item.dispatchLineState = "Expedie"
                })
                this.validate = false;
            })
            toast.success("Expédition du stock éffectuée avec succès");

        } catch (e) {
            runInAction(() => {
                this.validate = false;
            })
        }
    }

    @action valideTous = async (values: number) => {
        this.submitting = true;
        try {
            await agent.expeditionAgent.valideTous(values);
            let expedition = await agent.expeditionAgent.details(values);
            runInAction(() => {
                this.orderDispatch = expedition;
                this.ITList.filter(x => x.orderDispatchs == values).forEach(item => {

                    item.dispatchLineState = "Termine"
                    item.quantiteLivree = item.quantiteALivree!;
                    item.quantiteResteLivre = 0;
                })
                this.orderDispatch!.dispatchState = 'Termine';
                this.submitting = false;
                this.isDispatched = true;
            })
            toast.success("Expéditions valider avec succès");

        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
        }
    }

    @action editStatusOrderDispatch = async (values: number) => {
        this.submitting = true;
        try {
            await agent.expeditionAgent.updateStatusOrderDispatch(values);
            let expedition = await agent.expeditionAgent.details(values);
            runInAction(() => {
                this.orderDispatch = expedition;
                this.orderDispatch!.dispatchState = "Livre";
                this.ITList.filter(x => x.orderDispatchs == this.orderDispatch?.id).forEach(item => {

                    item.dispatchLineState = "Livre"
                })
                this.submitting = false;
            })
            toast.success("Livraison du stock éffectuée avec succès");
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
        }
    }

    @action OrderDispatchFacture = async (id: number) => {
        this.submitting = true;
        try {
            var factureId = await agent.expeditionAgent.generateFacture(id);
            let expedition = await agent.expeditionAgent.details(id);
            runInAction(() => {
                this.orderDispatch = expedition;
                this.orderDispatch!.dispatchState = "Facture";
                this.ITList.filter(x => x.orderDispatchs == id).forEach(item => {

                    item.dispatchLineState = "Facture"
                })
                this.submitting = false;
                toast.success("transformation faite avec succès");
            })
            history.push(`/detailFacture/${factureId}`);
        } catch (e) {
            toast.error("Problème de generation de facture!");
            runInAction(() => {
                this.submitting = false;
            })
        }
    }


    @action factureListExpeditions = async (ids: number[], date: string) => {
        this.submitting = true;
        try {
            await agent.expeditionAgent.generateFactureByListExpeditions(ids, date);
            runInAction(() => {
                this.submitting = false;
            })
            history.push('/factureDashboard')
        } catch (e) {
            toast.error("Problème d'ajouts de liste select  !");
            runInAction(() => {
                this.submitting = false;
            })
        }
    }

    @action edit = async (values: Partial<IDetailExpedition>) => {
        this.submitting = true;
        var status = true;
        try {
            var x = await agent.expeditionAgent.update(values);
            runInAction(() => {
                this.ITList.map((item, index) => {
                    if (item.id == values.id) {
                        this.ITList[index].quantiteLivree = values.quantiteLivree!;
                        this.ITList[index].quantiteResteLivre = x;
                        //this.ITList[index].quantiteALivree == values.quantiteLivree! ?
                        //    this.ITList[index].dispatchLineState = "Termine" :
                        //    this.ITList[index].dispatchLineState = "EnCours";
                        //if (this.ITList[index].quantiteLivree == 0)
                        //    this.ITList[index].dispatchLineState = "EnAttente";
                        if (values.quantiteLivree == this.ITList[index].quantiteALivree) {
                            this.ITList[index].dispatchLineState = "Termine"
                            this.orderDispatch!.dispatchState = 'Termine';
                        }
                        if (this.ITList[index].quantiteALivree > values.quantiteLivree!) {
                            this.ITList[index].dispatchLineState = "EnCours"
                            this.orderDispatch!.dispatchState = 'EnCours';
                        }

                    }
                });
                this.ITList.map((item) => {
                    if (item.dispatchLineState == "EnAttente" || item.dispatchLineState == "Expedie")
                        status = false;
                });
                this.isDispatched = status;
                if (status)
                    this.orderDispatch!.dispatchState = 'Termine';
                toast.success("Modification éffectuée avec succès");
                this.submitting = false;
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }

    }

    @action Retour = async (id: number, orderDis: IOrderDispatch) => {
        this.submitting = true;
        try {
            var id = await agent.expeditionAgent.BonDeRetour(orderDis);
            this.loadExpeditionLineByIdExpedition(id);
            runInAction(() => {
                history.push(`/detailExpedition/${id}`)
                toast.success("Bon de retour a étét créé avec succès");
                this.submitting = false;
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }

    }

    @action editDispatchDate = async (obj: Partial<IDetailExpedition>) => {
        this.submitting = true;
        try {
            var id = await agent.expeditionAgent.updateOrderDispatchDate(obj);
            runInAction(() => {
                if (id == obj.id)
                    this.orderDispatch!.dispatchDate = obj.dispatchDate!;
                this.submitting = false;
            })
            if (id != obj.id)
                history.push(`/expeditionDashboard`)
        } catch (e) {
            toast.error("Problème d'ajouts de liste select  !");
            runInAction(() => {
                this.submitting = false;
            })
        }
    }

    @action editNbrColis = async (obj: Partial<IDetailExpedition>) => {
        this.submitting = true;
        try {
            var nbrColis = await agent.expeditionAgent.updateOrderDispatchNbrColis(obj);
            runInAction(() => {
                this.orderDispatch!.nbrColis = nbrColis;
                toast.success("nombre de colis ajouté avec succes")
                this.submitting = false;
            })

        } catch (e) {
            toast.error("Problème d'ajouts de liste select  !");
            runInAction(() => {
                this.submitting = false;
            })
        }
    }

    @action editDispatchLineDate = async (obj: any) => {
        this.submitting = true;
        try {
            var id = await agent.expeditionAgent.updateOrderDispatchLineDate(obj);
            runInAction(() => {
                this.submitting = false;
                history.push(`/detailExpedition/${id}`);
                toast.success("Les dates sont modifiés avec succées");
            })
        } catch (e) {
            toast.error("Problème d'ajouts de liste select  !");
            runInAction(() => {
                this.submitting = false;
            })
        }
    }

    @action deleteOrder = async (id: number) => {
        this.deletingOrder = true;
        try {
            await agent.expeditionAgent.deleteOrderDispatch(id);
            runInAction(() => {
                this.deletingOrder = false;
                this.expeditions = this.expeditions.filter(a => a.id !== id);
                history.push(`/expeditionDashboard`);

                toast.success("suppression éffectuée avec succès");
            })
        } catch (e) {
            toast.error("Problème de suppresion !");
            runInAction(() => {
                this.deletingOrder = false;
            })
        }
    }

    @action canceled = async (id: number) => {
        this.loadBtnCancel = true;
        try {
            await agent.expeditionAgent.cancel(id);
            runInAction(() => {

                this.orderDispatch!.isCanceled = true;
                toast.success("BL désactivé avec succès");
            })
            this.loadBtnCancel = false;

        } catch (e) {
            runInAction(() => {
                this.loadBtnCancel = false;
            })
        }
    }

    @action actived = async (id: number) => {
        this.loadBtnCancel = true;
        try {
            await agent.expeditionAgent.cancel(id);
            runInAction(() => {
                this.orderDispatch!.isCanceled = false;
                toast.success("BL activé avec succès");
            })
            this.loadBtnCancel = false;

        } catch (e) {
            runInAction(() => {
                this.loadBtnCancel = false;
            })
        }
    }
    @action ExportReliquat = async (obj: any) => {
        this.loadingExport = true;
        try {
            await agent.expeditionAgent.exportReliquat(obj);
            runInAction(() => {
                this.loadingExport = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingExport = false;
            })
            console.log(e);
        }
    }

    @action SortirReiliquats = async (id: number, dateBl: string) => {
        this.loadingReliquat = true;
        try {
            var idBl = await agent.expeditionAgent.sortirReliquats(id, dateBl);
            runInAction(() => {
                this.loadingReliquat = false;
                toast.success("Un nouveau BL a été créé avec succès");
                this.loadExpeditionLineByIdExpedition(idBl);
                history.push(`/detailExpedition/${idBl}`)
            })
        } catch (e) {
            runInAction(() => {
                this.loadingReliquat = false;
            })
        }
    }
    @action ExportExpeditions = async (obj: any) => {
        this.loadingExportEx = true;
        try {
            await agent.expeditionAgent.exportExpeditionExcel(obj);
            runInAction(() => {
                this.loadingExportEx = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingExportEx = false;
            })
            console.log(e);
        }
    }


    @action checkMvtExpeditions = async () => {
        try {
            await agent.expeditionAgent.checkMvt();
            runInAction(() => {
                toast.success("good")
            })
        } catch (e) {
            runInAction(() => {
            })
            console.log(e);
        }
    }
}