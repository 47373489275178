import React from 'react'
import { Message } from 'semantic-ui-react'

const AucunProduit = () => (
    <div style={{ marginTop: '50px' }}>
        <Message
            error
            list={[
                "Aucun produit pour le moment",
                "Veuillez ajouter des articles pour accèder à la liste",
            ]}
        />
    </div>
)

export default AucunProduit
