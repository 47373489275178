import React, { useContext, useEffect, useState } from 'react';
import { GridColumn, Grid, Loader, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import InfiniteScroll from 'react-infinite-scroller';
import { BaseStoreContext } from '../../app/store/baseStore';
import Loading from '../../app/layout/Loading';
import ListItemPlaceholder from '../../app/common/form/ListItemPlaceholder';
import ListMouvementStock from './ListMouvementStock';
import Breadcrumbs from '../dashboard/breadcrumbs';
import StockMultipleSearch from './StockMultipleSearch';
import { FaFileExcel } from 'react-icons/fa';

const MouvementStockDashboard: React.FC = () => {

    const baseStore = useContext(BaseStoreContext);
    const { loadStocksMouvement, loadingInitial, page, totalPages, setPage } = baseStore.mouvementStockStore;
    const [loadingNext, setLoadingNext] = useState(false);

    const handleGetNext = () => {
        setLoadingNext(true);
        setPage(page + 1);
        loadStocksMouvement().then(() => setLoadingNext(false));
    }
   
    useEffect(() => {
        loadStocksMouvement();
    }, [loadStocksMouvement]);

    return (
        <div> 
            <Breadcrumbs
                items={[
                    { key: 0,name: "Stock", root: "" },
                    { key: 1,name: "Mouvement de stock", root: "/mouvementStockDashboard" },
                ]}
            />          
            <Grid>      
                <Grid.Row style={{ padding: 0, margin: "0 20px 0 20px" }}>
                    <StockMultipleSearch />
                </Grid.Row>
            <GridColumn width={16} style={{ marginTop: 2 }}>
                <Grid style={{ marginTop: 2 }}>
                    <GridColumn width={16}>
                        {loadingInitial && page === 0 ? <ListItemPlaceholder /> : (
                            <InfiniteScroll
                                pageStart={0}
                                loadMore={handleGetNext}
                                hasMore={!loadingNext && page + 1 < totalPages}
                                initialLoad={false}
                            >
                                <ListMouvementStock />
                            </InfiniteScroll>
                        )}
                    </GridColumn>
                </Grid>
            </GridColumn>
            <Grid.Column width={4}>
                <Grid.Column width={16}>
                </Grid.Column>
            </Grid.Column>
            <Grid.Column width={16}>
                <Loader active={loadingNext} />
            </Grid.Column>
        </Grid>
        </div>
    );
}
export default observer(MouvementStockDashboard);