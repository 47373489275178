import React, {useState } from 'react';
import {Button, Segment, Accordion} from 'semantic-ui-react';
import ListImpression from '../../Impression/dashboard/listImpression';
import ListOption from '../../Option/dashboard/listOption';
import ListSupport from '../../Support/dashboard/listSupport';


const ParametreArticleForm = () => {
    const [state, setState] = useState({ activeIndex: -1 });
    const handleClick = (e: any, titleProps: any) => {
        const { index } = titleProps
        const { activeIndex } = state
        const newIndex = activeIndex === index ? -1 : index
        setState({ activeIndex: newIndex })
    }
    const { activeIndex } = state
    return (
        <Segment raised style={{ backgroundColor: 'rgb(220,219,219)' }}>
                
              <Accordion fluid styled>
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={handleClick}
                  style={{ fontSize: 'large', color: 'black' }}>
                  <i className="dropdown icon"></i>
                  Impression
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                  <ListImpression />
                </Accordion.Content>
              </Accordion>
              <Accordion fluid styled>
                <Accordion.Title
                  onClick={handleClick}
                  active={activeIndex === 1}
                  index={1}
                  style={{ fontSize: 'large', color: 'black' }}>
                  <i className="dropdown icon"></i>
                  Option
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 1}>
                  <ListOption />
                </Accordion.Content>
              </Accordion>
              <Accordion fluid styled>
                <Accordion.Title
                  onClick={handleClick}
                  active={activeIndex === 2}
                  index={2}
                  style={{ fontSize: 'large', color: 'black' }}>
                  <i className="dropdown icon"></i>
                  Support
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 2}>
                  <ListSupport />
                </Accordion.Content>
              </Accordion>            
        </Segment >
    );
}
export default ParametreArticleForm;
