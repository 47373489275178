import { observer } from 'mobx-react-lite';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import Breadcrumbs from '../../dashboard/breadcrumbs';
import AddFactureContent from './AddFactureContent';
import AddFactureHeader from './AddFactureHeader';


interface IDetailParams {
    origin: string;
    id: string;
}

const AddFacturePage: React.FC<RouteComponentProps<IDetailParams>> = ({ match }) => {

    const clientId = match.params.id;
    const origin = match.params.origin;

    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Vente", root: "" },
                    { key: 1, name: "Factures", root: "/factureDashboard" },
                    { key: 2, name: "Ajouter Facture", root: "/addFacture" },
                ]}
            />
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Grid style={{ marginTop: 2 }}>
                            <Grid.Column width={16}>
                                <AddFactureHeader />
                                <AddFactureContent clientId={clientId} origin={origin} />
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>

                </Grid.Row>
            </Grid>
        </div>
        );
}

export default observer(AddFacturePage);