import React, { useContext, useState, useEffect, useRef} from 'react';
import { Table, Header, Label, Button, Reveal, Grid, Item, ItemContent } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import NoResult from './ErrorNoResult';
import { Link } from 'react-router-dom';
import DeleteCommandeModal from './DeleteCommandeModal';
import CancelCommandeModal from './CancelCommandeModal';
import _ from 'lodash';
import FacturerCommandesModal from './FacturerCommandesModal';
import { IOrder } from '../../../app/models/ICommande';

const numberFormat = new Intl.NumberFormat('FR-fr', {
    style: 'currency',
    currency: 'EUR',
    useGrouping: true,
});


const getStatus = (status: string) => {
    switch (status) {
        case 'Validated':
            return 'Validée';
        case 'Delivered':
            return 'Livrée';
        case 'Cancelled':
            return 'Annulée';
        case 'WaitingValidation':
            return 'En Attente';
        case 'WaitingToBeSentToCrm':
            return 'Ecommerce';
        case 'SentToCrm':
            return 'Ecommerce';
        case 'Prepared':
            return 'Preparée';
        case 'Rejected':
            return 'Rejetée';
        case 'Dispatched':
            return 'Expediée';
        case 'Facture':
            return 'Facturée';
        case 'Partially_Prepared':
            return 'Preparée partiellement';
        case 'Preparing':
            return 'Préparation EnCours';
        case 'Delivering':
            return 'Livraison EnCours';
        case 'WaitingForOnlinePayment':
            return 'En attente de paiement';
        case 'OnlinePaymentAccepted':
            return 'Paiement en ligne accepté';
        default:
            return '';
    }
}
const setPaiementType = (status: string) => {
    switch (status) {
        case '31':
            return 'Carte Bancaire';
        case '03':
            return 'Chèque de banque';
        case '01':
            return 'Espèce';
        case '15':
            return 'Prélèvement Bancaire';
        case '11':
            return 'Virement Bancaire';
        default:
            return status;
    }
}
const getColor = (status: string) => {
    switch (status) {
        case 'Payé':
            return 'green';
        case 'Impayé':
            return 'red';
        case 'Partiellement payé':
            return 'blue';
        default:
            return 'black';
    }
}

const ListCommandes: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { commandes, deletingOrder, commandesFiltreCount, commandesHTCount } = baseStore.commandeStore;
    const { openModal } = baseStore.modalStore;
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);

    var arrayids = new Array<number>()
    const [ids, setIds] = useState<number[]>(arrayids);
    const ref = useRef<HTMLInputElement[]>([]);

    useEffect(() => {
        console.log(ids);
    }, [ids]);

    const handleCheck = (key: number, value: any) => {
        console.log(key);
        console.log(value);
        if (value.target.checked) {
            setIds([...ids, key]);
        } else {
            setIds(_.without(ids, key));
        }
    }

    const getBackgroundColor = (key: number)  => {
        return ids.includes(key) ? 'darkgrey' : 'white';
    }

    const Footer = () => {
        return <footer className='footer footer.collapse' style={{
            background: 'gray', textAlign: 'center', left: 0, right: 0, opacity: 0.8
        }}>
            <Button positive type='submit' style={{ margin: '5px' }} onClick={(e) => {
                openModal(<FacturerCommandesModal commandes={ids} setIds={setIds}  />)
            }}  >Facturer</Button>
        </footer>;
    }

    //uncheck all selected checkboxs
    const Unchecked = () => {
        if (ref.current != null) {
        for (let i = 0; i < ref.current.length; i++) {
            ref.current[i].checked = false;
            }
        }
    }

    if (commandes.length < 1) return < NoResult name="Commande" />
    else
        return (
            <Grid>
                <Grid.Row style={{ marginTop: '-50px', marginBottom: '-10px' }} >
                    <Item style={{ borderRadius: 5, border: "1px solid grey" }}>
                        <ItemContent style={{ padding: 5 }}>
                            <b style={{ color: 'grey' }}>{commandesFiltreCount} Commandes ({numberFormat.format(commandesHTCount)} HT) </b>
                            </ItemContent >
                        </Item>

                </Grid.Row>
                <Grid.Row>
                <Table compact='very' style={{ marginTop: '0em' }} textAlign='center' >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell>Référence</Table.HeaderCell>
                            <Table.HeaderCell>Client</Table.HeaderCell>
                            <Table.HeaderCell>Date Commande</Table.HeaderCell>
                            <Table.HeaderCell>Montant HT</Table.HeaderCell>
                            <Table.HeaderCell>TVA</Table.HeaderCell>
                            <Table.HeaderCell>Montant TTC</Table.HeaderCell>
                            <Table.HeaderCell>Mode de paiement</Table.HeaderCell>
                            <Table.HeaderCell>Etat de paiement</Table.HeaderCell>
                            <Table.HeaderCell>Etat</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {commandes && commandes.map((commande: IOrder, key) => (
                            <>
                                <Table.Row key={commande.id} style={{ backgroundColor: getBackgroundColor(commande.id) }} >
                                    <Table.Cell> {commande.orderState == "Validated" ? (<input type="checkbox" ref={(value: HTMLInputElement) => { ref.current[key] = value }} onChange={(value) => handleCheck(commande.id, value)} />) : ("")}  </Table.Cell>
                                    <Table.Cell>
                                        <Header size='small'><Label style={{ marginLeft: '1px', marginTop: '5px', marginBottom: '5px' }} as={Link} to={`/detailCommande/${commande.id}`}>{commande!.reference}</Label></Header>
                                    </Table.Cell>
                                    <Table.Cell>{commande.agencyName}-{commande.companyName}</Table.Cell>
                                    <Table.Cell>{new Date(commande.orderDate).toLocaleDateString('en-GB')}</Table.Cell>
                                    <Table.Cell>{numberFormat.format(commande.amountWithoutTaxes)}</Table.Cell>
                                    <Table.Cell>{numberFormat.format(commande.amountWithTaxes - commande.amountWithoutTaxes)}</Table.Cell>
                                    <Table.Cell>{numberFormat.format(commande.amountWithTaxes)}</Table.Cell>
                                    <Table.Cell><span style={{ color: "green" }}>{setPaiementType(commande.paymentMode)}</span></Table.Cell>
                                    <Table.Cell><span style={{ color: getColor(commande.paymentState) }}>{commande.paymentState}</span></Table.Cell>
                                    <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }} >{commande.isCanceled == false ? getStatus(commande.orderState) : "Annulé"}</Table.Cell>
                                    <Table.Cell>
                                        {commande.orderState === "WaitingValidation" || commande.orderState === "Validated" ? (
                                            <Button.Group fluid widths={3}>
                                                <Button
                                                    style={{ marginRight: '3%' }}
                                                    name={commande.id}
                                                    basic
                                                    size="small"
                                                    color='red'
                                                    icon='trash'
                                                    onClick={(e) => {
                                                        openModal(<DeleteCommandeModal commande={commande} />)
                                                        setDeleteTarget(e.currentTarget.name)
                                                    }}
                                                    loading={deletingOrder && deleteTarget === commande.id.toString()}
                                                />
                                            </Button.Group>
                                        ) : (<></>
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                                <td colSpan={8} style={{ paddingTop: 0 }}>
                                    {commande.franchiseOrderId != 0 &&
                                        <span style={{ width: '100%', color: 'rgb(255 106 0)', fontSize: 16, fontWeight: 500 }}>Complément de la commande  {commande.franchiseOrderId}</span>
                                    }
                                    {commande.franchiseId != 0 &&
                                        <span style={{ backgroundColor: 'darkseagreen', color: 'white', borderRadius: 5, padding: 5, float: 'right' }}>Pack d'ouverture</span>
                                    }

                                </td>
                            </>
                        ))}
                    </Table.Body>
                </Table>
                {ids.length > 0 ? (<Footer />) :(<br/>)}
                    </Grid.Row>

            </Grid>
        );
}

export default observer(ListCommandes);