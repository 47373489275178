import { BaseStore } from "./baseStore";
import { observable, action, runInAction } from 'mobx';
import agent from '../agent/agent';
import { toast } from "react-toastify";
import { setTvaProps } from "../common/util/util";
import { ITva, ITvaList } from "../models/ITva";

export default class TvaStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
    }
    @observable loadingTvaAction = false;
    @observable loadingTva = false;
    @observable loadingInitial = false;
    @observable submitting = false;
    @observable deletingTva = false;
    @observable tva: ITva | null = null;
    @observable tvas: ITva[] = [];
    @observable defaultTva: ITva | undefined;
    @observable tvaRegestry = new Map();
    @observable ITListTva: ITvaList[] = [];
    @observable editTva: boolean = false;
    @observable addMode: boolean = false;
    @observable progress: number = 50;

    @action changeButtonStateEdit = async (newState: boolean) => {
        this.editTva = newState;
    }

    @action changeButtonStateAdd = async (newState: boolean) => {
        this.addMode = newState;
    }

    @action loadTvas = async () => {
        this.loadingInitial = true;

        try {
            const tvas = await agent.tvaAgent.list();
            runInAction(() => {
                this.tvas = tvas;
                tvas.forEach((tva) => {
                    setTvaProps(tva);
                    this.tvaRegestry.set(tva.id, tva);
                })
                this.loadingInitial = false;
            })
        } catch (e) {
            toast.error("Problem loading tva!");
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }
    @action loadTvasActifs = async () => {
        this.loadingInitial = true;
        this.ITListTva = [];
        try {
            const tvas = await agent.tvaAgent.list();
            runInAction(() => {
                this.tvas = tvas;
                tvas.filter(x => x.isActive).forEach((tva) => {
                    setTvaProps(tva);
                    this.tvaRegestry.set(tva.id, tva);
                    let TVA: ITvaList = {
                        key: tva.id,
                        text: tva.nom + ' - ' + tva.taux + '%',
                        value: tva.id
                    }
                    this.ITListTva.push(TVA)
                })
                this.defaultTva = tvas.find(x => x.isDefault);
                this.loadingInitial = false;
            })
        } catch (e) {
            toast.error("Problem loading tva!");
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }

    getTva = (id: string) => {
        return this.tvaRegestry.get(id);
    }

    @action create = async (values: ITva) => {
        this.submitting = true;
        try {
            await agent.tvaAgent.create(values);
            runInAction(() => {
                this.tvaRegestry.set(values.id, values)
                this.tva = values;
                this.tvas.push(this.tva);
                this.submitting = false;
                toast.success("ajout éffectué avec succès");
            })

        } catch (e) {
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    @action edit = async (values: Partial<ITva>) => {
        this.submitting = true;
        this.progress = 80;
        try {
            await agent.tvaAgent.update(values);
            let tva = await agent.tvaAgent.details(values.id!);
            runInAction(() => {
                this.tvas = this.tvas.filter(a => a.id != values.id);
                this.tvas.push(tva);
                toast.success("Modification éffectuée avec succès");
                this.submitting = false;
            })

        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action disable = async (id: number) => {
        this.deletingTva = true;
        try {
            await agent.tvaAgent.disable(id);
            runInAction(() => {
                this.deletingTva = false;
                this.tva!.isActive = false;
                toast.success("tva désactivé avec succès");
            })
        } catch (e) {
            console.log(e);
            runInAction(() => {
                this.deletingTva = false;
            })
        }
    }

    @action enable = async (id: number) => {
        this.deletingTva = true;
        try {
            await agent.tvaAgent.enable(id);
            runInAction(() => {
                this.deletingTva = false;
                this.tva!.isActive = true;
                toast.success("tva activé avec succès");
            })
        } catch (e) {
            console.log(e);
            runInAction(() => {
                this.deletingTva = false;
            })
        }
    }

    @action isDefault = async (id: number) => {
        this.loadingTvaAction = true;
        try {
            await agent.tvaAgent.default(id);
            runInAction(() => {
                this.loadingTvaAction = false;
                this.tvas.find(a => a.isDefault)!.isDefault = false;
                this.tvas.find(a => a.id === id)!.isDefault = true;
                toast.success("tva par default changé avec succès");
            })
        } catch (e) {
            console.log(e);
            runInAction(() => {
                this.loadingTvaAction = false;
            })
        }
    }

}