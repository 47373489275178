import React, { Dispatch, SetStateAction, useContext } from 'react';
import { Button, Header, Icon, Container } from 'semantic-ui-react';
import { IAdresse } from '../../../app/models/IAdresse';
import { IOrderLine } from '../../../app/models/ICommande';
import { BaseStoreContext } from '../../../app/store/baseStore';

interface IProps {
    Adress: IAdresse;
    setModalOpen: Dispatch<SetStateAction<boolean>>;
}
const getType = (MainIn: boolean, MainDelivery: boolean) => {
    if (MainIn && MainDelivery)
        return 'livraison et de facturation';
    else if (MainIn)
        return 'facturation';
    else if (MainDelivery)
        return 'livraison';
    

}
const DeleteAdressModal: React.FC<IProps> = ({ Adress, setModalOpen }) => {
    const baseStore = useContext(BaseStoreContext);
    const { deleteAdresse } = baseStore.adresseStore;
    return (
        
        <Container textAlign='center'>
            {Adress.isMainDeliveryAddress || Adress.isMainInvoiceAddress ? (
                <>
                    <p>
                        Vous ne pouvez pas supprimer cette adresse
                        parcequ'elle est une adresse de {getType(Adress.isMainInvoiceAddress, Adress.isMainDeliveryAddress)}.
                    </p>
                    <Button onClick={() => setModalOpen(false)}>
                        <Icon name='remove' /> Annuler
                    </Button>
                </>
            ) : (
                    <>
                        <p>
                            Voulez vous supprimer cette adresse ?
                        </p>
                        <Button.Group fluid>
                            <Button onClick={() => setModalOpen(false)}>
                                <Icon name='remove' /> Annuler
                            </Button>
                            <Button.Or text='Ou' />
                            <Button
                                color='green'
                                onClick={(e) => {
                                    deleteAdresse(Adress.id);
                                    setModalOpen(false)
                                }}>
                                <Icon name='checkmark' /> Confirmer
                            </Button>
                        </Button.Group>
                        </>
                    )}
            

        </Container>
    )
}
export default DeleteAdressModal;
