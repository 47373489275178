import React, { useContext, useEffect } from 'react';
import { Button, Grid, Header } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Loading from '../../../app/layout/Loading';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import FournisseurHeader from './FournisseurHeader';
import FournisseurContent from './FournisseurContent';
import Breadcrumbs from '../../dashboard/breadcrumbs';

interface RouteParams {
    id: string,
}

interface IProps extends RouteComponentProps<RouteParams> { }
const FournisseurPage: React.FC<IProps> = ({ match }) => {
    const baseStore = useContext(BaseStoreContext);
    const { loadFournisseur, setActiveTab, loadingFournisseur, fournisseur, enable, disable, deletingFournisseur } = baseStore.fournisseurStore;
    const { isLoggedIn } = baseStore.userStore;

    useEffect(() => {
        loadFournisseur(parseInt(match.params.id));
    }, [loadFournisseur, match])

    if (loadingFournisseur) return <Loading content="Chargement des Fournisseurs ..." />
    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0,name: "Tiers", root: "" },
                    { key: 1,name: "Fournisseurs", root: "/fournisseurDashboard" },
                    { key: 2,name: "" + fournisseur?.fabricant, root: `/detailFournisseur/${fournisseur?.fournisseurId}` },
                ]}
            />
        <Grid>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Grid style={{ marginTop: 2 }}>
                        <Grid.Column width={16}>
                            <Header floated='left' content={'Mettre à jour le fournisseur '} />
                            <Button
                                floated="right"
                                content="Retour vers fournisseurs"
                                color="orange"
                                icon='arrow alternate circle left outline'
                                basic
                                as={Link}
                                to="/fournisseurDashboard"
                            />
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <FournisseurHeader fournisseur={fournisseur!} disable={disable} enable={enable} isLoggedIn={isLoggedIn} loading={deletingFournisseur} />
                            <FournisseurContent setActiveTab={setActiveTab} />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
            </Grid>
        </div>
    );
}

export default observer(FournisseurPage);