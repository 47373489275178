import React, { useContext, useEffect, useState } from 'react';
import { GridColumn, Grid, Loader, Button, Modal, Icon } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import ArticleList from './ArticleList';
import InfiniteScroll from 'react-infinite-scroller';
import ListItemPlaceholder from '../../../app/common/form/ListItemPlaceholder';
import Breadcrumbs from '../../dashboard/breadcrumbs';
import { FaPlusCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ArticleFilter from './ArticleFilter';

const ArticleDashboard: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { loadArticles, loadingInitial, page, totalPages, setPage } = baseStore.articleStore;
    const { loadFournisseurs,  } = baseStore.fournisseurStore;
    const [loadingNext, setLoadingNext] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const handleGetNext = () => {
        setLoadingNext(true);
        setPage(page + 1);
        loadArticles().then(() => setLoadingNext(false));
    }
    useEffect(() => {
        loadArticles();
        loadFournisseurs();
    }, []);

    return (

        <div>
            <Modal
                open={modalOpen}
                size='mini'
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >
                <Modal.Header icon='add' >
                    Ajout Produit/Service
                    <Button
                        style={{ height: 35 }}
                        type='button' icon='x' color='red'
                        floated='right'
                        onClick={() => { setModalOpen(false) }}
                    >
                    </Button>
                </Modal.Header>
                <Modal.Content>

                    <Button  color='green' as={Link}
                        to={"/addArticleService"}
                        style={{ width: '47%', marginBottom: 20 }}
                        onClick={() => setModalOpen(false)}>
                        Service
                    </Button>
                    <Button color='orange' floated='right' as={Link}
                        to={"/addArticles"}
                        style={{ width: '47%',marginBottom:20 }}
                        onClick={() => setModalOpen(false)}>
                        Produit
                    </Button>                
                </Modal.Content>
                
            </Modal>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Articles", root: "" },
                    { key: 1, name: "Liste articles", root: "/articles" },
                ]}
            />
            <Grid>
                <Grid.Row style={{ padding: 0, margin: "5px 20px 0 20px" }}>
                    <Grid.Column style={{ padding: 0, margin: 0 }}>

                        <Button
                            style={{ float: 'right' }}
                            content="Ajouter Article"
                            className="andi-btn filled success"
                            icon={(<FaPlusCircle style={{ marginRight: "10px", fontSize: "20px" }} />)}
                            onClick={() => { setModalOpen(true) }}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ padding: 0, margin: "0 20px 0 20px" }}>

                    <ArticleFilter />

                </Grid.Row>
                <GridColumn width={16} style={{ marginTop: 2 }}>

                    <Grid style={{ marginTop: 2 }}>
                        <GridColumn width={16}>
                            {loadingInitial && page === 0 ? <ListItemPlaceholder /> : (
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={handleGetNext}
                                    hasMore={!loadingNext && page + 1 < totalPages}
                                    initialLoad={false}
                                >
                                    <ArticleList />
                                </InfiniteScroll>
                            )}
                        </GridColumn>
                    </Grid>
                </GridColumn>

                <Grid.Column width={16}>
                    <Loader active={loadingNext} />
                </Grid.Column>
            </Grid>
        </div>
    );
}
export default observer(ArticleDashboard);