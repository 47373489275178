import React, { useContext } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button, Grid } from 'semantic-ui-react';
import TextInput from '../../../app/common/form/inputText';
import { observer } from 'mobx-react-lite';
import { IFournisseur } from '../../../app/models/IFournisseur';
import { BaseStoreContext } from '../../../app/store/baseStore';

interface IProps {
    editFournisseur: (profil: Partial<IFournisseur>) => void;
    fournisseur: IFournisseur;
}
const FournisseurEditForm: React.FC<IProps> = ({ editFournisseur, fournisseur }) => {
    const baseStore = useContext(BaseStoreContext);



    return (
        <FinalForm
            onSubmit={editFournisseur}
            initialValues={fournisseur!}
            render={({ handleSubmit, invalid, pristine, submitting }) => (
                <Form onSubmit={handleSubmit} error>
                    <Grid columns={2} >
                        <Grid.Column>
                            <h5 >Société</h5>
                            <Field name='fabricant' component={TextInput} placeholder='Société' type='text' value={fournisseur!.fabricant} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5 >Code Fournisseur</h5>
                            <Field name='codeFournisseur' component={TextInput} placeholder='codeFournisseur' type='text' value={fournisseur!.codeFournisseur} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5 >Nom Contact</h5>
                            <Field name='nomContact' component={TextInput} placeholder='Nom Contact' type='text' value={fournisseur!.nomContact} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>Adresse</h5>
                            <Field name='adresse' component={TextInput} placeholder='Adresse' type='text' value={fournisseur!.adresse} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>Code Postal</h5>
                            <Field name='codePostal' component={TextInput} placeholder='code Postal' type='text' value={fournisseur!.codePostal} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5 >Ville</h5>
                            <Field name='ville' component={TextInput} placeholder='Ville' type='text' value={fournisseur!.ville} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>Complement d'adresse</h5>
                            <Field name='adresseComplement' component={TextInput} placeholder='adresse Complement' type='text' value={fournisseur!.adresseComplement} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>Pays</h5>
                            <Field name='country' component={TextInput} placeholder='Pays' type='text' value={fournisseur!.country} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>TVA Intracommunautaire</h5>
                            <Field name='europeanTvaNumber' component={TextInput} placeholder='TVA Intracommunautaire' type='text' value={fournisseur!.europeanTvaNumber} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>Email</h5>
                            <Field name='email' component={TextInput} type='email' value={fournisseur!.email} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>Telephone</h5>
                            <Field name='telephone' component={TextInput} value={fournisseur!.telephone} type='number' />
                        </Grid.Column>                   
                        <Grid.Column>
                            <h5>Siret</h5>
                            <Field name='siret' component={TextInput} placeholder='Siret' type='text' value={fournisseur!.siret} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>Siren</h5>
                            <Field name='siren' component={TextInput} placeholder='Siren' type='text' value={fournisseur!.siren} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>Cnuf</h5>
                            <Field name='cnuf' component={TextInput} placeholder='Cnuf' type='text' value={fournisseur!.cnuf} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>Naf</h5>
                            <Field name='naf' component={TextInput} placeholder='Naf' type='text' value={fournisseur!.naf} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>Site Web</h5>
                            <Field name='siteWeb' component={TextInput} placeholder='Site Web' type='text' value={fournisseur!.siteWeb} />
                        </Grid.Column>
                       
                    </Grid>
                    <div style={{ marginTop: '30px' }}>
                        <Button.Group floated="right">
                            <Button disabled={submitting} onClick={() => { baseStore.fournisseurStore.changeButtonState(!baseStore.fournisseurStore.editFournisseurMode) }} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive disabled={submitting || invalid || pristine} loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </div>
                </Form >
            )}
        />
    );
};
export default observer(FournisseurEditForm);