import React from 'react';
import { Grid } from 'semantic-ui-react';
import Breadcrumbs from '../../dashboard/breadcrumbs';
import AddFactureContent from './AddFactureContent';
import AddFactureHeader from './AddFactureHeader';
import { observer } from 'mobx-react-lite';

const AddFacturePage: React.FC = () => {
    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Achat", root: "" },
                    { key: 1, name: "Factures Fournisseurs", root: "/factureFournisseurDashboard" },
                    { key: 2, name: "Ajouter Facture", root: "/addFactureFournisseur" },
                ]}
            />
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Grid style={{ marginTop: 2 }}>
                            <Grid.Column width={16}>
                                <AddFactureHeader />
                                <AddFactureContent />
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>

                </Grid.Row>
            </Grid>
        </div>
    );
}
export default observer(AddFacturePage);