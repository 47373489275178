import { useContext, useEffect, useState } from 'react';
import { Form, Button, Segment, Checkbox, Input } from 'semantic-ui-react';
import { Form as FinalForm, Field } from 'react-final-form';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { ITListCustomizedType, ITListProductType } from '../../../app/common/options/IAchat';
import { history } from '../../../index';
import { observer } from 'mobx-react-lite';
import { IArticle, IListDeliveryDelay } from '../../../app/models/IArticle';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select/';


const AddArticleServiceContent = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { create, submitting, submittingDeliveryDelay, IDeliveryDelayList, loadDeliveryDelay } = baseRepo.articleStore;

    const { loadingInitial, ITListTva, loadTvasActifs, defaultTva } = baseRepo.tvaStore;
    const [tva, setTva] = useState<number | undefined>(undefined);
    const [valueDeliveryDelay, setValueDeliveryDelay] = useState<IListDeliveryDelay | null>();
    const [deliveryDelay, setDeliveryDelay] = useState('');
    const [libelle, setLibelle] = useState('');
    const [ref, setRef] = useState('');
    const [codeComptable, setcodeComptable] = useState('');

  

    useEffect(() => {
        loadTvasActifs();       
        loadDeliveryDelay();
    }, [])

    const handleChangeTva = (e: any) => {
        setTva(e);
    }
    const handleChangeDeliveryDelay = (inputValue: string) => {
        setTimeout(() => {
            const newOption: IListDeliveryDelay = {
                label: inputValue,
                value: inputValue
            }
            setDeliveryDelay(inputValue);
            setValueDeliveryDelay(newOption);
        }, 1000);

    }

    const handleChangeLibelle = (e: any) => {
        let libelle = e.target.value;
        setLibelle(libelle);
        let chaine = libelle.charAt(0);
        while (libelle.indexOf(" ") != -1) {
            chaine = chaine + libelle.charAt(libelle.indexOf(" ") + 1);
            libelle = libelle.substring(libelle.indexOf(" ") + 1);
        }
        setRef(chaine.toUpperCase());

    }

    const handleFinalFormSubmit = (values: any) => {
      
        let newArticle: IArticle = {
            ...values,
            side: 'recto',
            agencyName: '999',
            tva: tva || defaultTva!.id,        
            externalReference: ref,
            label: libelle,
            deliveryDelay: deliveryDelay,
            codeComptable: codeComptable,
            isService:true,
        };
        create(newArticle);
    }
    return (
        <Segment style={{ backgroundColor: 'rgb(255,255,255)', padding: '20px' }}>
            <FinalForm
                onSubmit={handleFinalFormSubmit}
                render={({ handleSubmit, invalid }) => (
                    <Form onSubmit={handleSubmit} error >
                        <Form.Group unstackable widths={2}>
                            <div className="field">
                                <label>Libellé <a style={{ color: 'red', fontSize: 17 }}>*</a></label>
                                <Input name='libelle' placeholder="libelle" onChange={handleChangeLibelle} />
                            </div>
                            <div className="field">
                                <label>Référence centrale <a style={{ color: 'red', fontSize: 17 }}>*</a></label>
                                <input name='refCentrale' defaultValue={ref} onChange={e => setRef(e.target.value)} />
                            </div>
                     
                        </Form.Group>                   
                    
                        <Form.Group unstackable widths={3}>
                            <div className="field">
                                <label>Délai de livraison (Jours)<a style={{ color: 'red', fontSize: 17 }}>*</a></label>
                                <CreatableSelect name="delaiLivraison"
                                    isDisabled={submittingDeliveryDelay}
                                    isLoading={submittingDeliveryDelay}
                                    options={IDeliveryDelayList}
                                    value={valueDeliveryDelay}
                                    placeholder='Délai de livraison'
                                    onChange={(deliveryDelay: any) => {
                                        setDeliveryDelay(deliveryDelay.value);
                                        setValueDeliveryDelay(deliveryDelay);
                                    }}
                                    onCreateOption={handleChangeDeliveryDelay}
                                    formatCreateLabel={() => `Créer`}
                                />
                            </div>
                            <div className="field">
                                <label>TVA <a style={{ color: 'red', fontSize: 17 }}>*</a></label>
                                <Select name='tva'
                                    getOptionValue={e => e.value.toString()}
                                    getOptionLabel={e => e.text}
                                    isLoading={loadingInitial}
                                    options={ITListTva}
                                    placeholder={loadingInitial ? "TVA par défaut..." : defaultTva?.nom + ' - ' + defaultTva?.taux + '%'}
                                    onChange={(e) => handleChangeTva(e!.value)} />
                            </div>
                            <div className="field">
                                <label>Code Comptable <a style={{ color: 'red', fontSize: 17 }}>*</a></label>
                                <input name='codeComptable' defaultValue={codeComptable} onChange={e => setcodeComptable(e.target.value)} />
                            </div>
                        </Form.Group>
                        <Form.Group unstackable style={{ marginLeft: "80%" }} >
                            <Button.Group floated="right">
                                <Button disabled={submitting} onClick={() => history.push('/articles')} floated='right' type='button'>Annuler</Button>
                                <Button.Or />
                                <Button positive disabled={submitting || invalid ||  deliveryDelay == '' || libelle == "" || ref == ""} loading={submitting} floated='right' type='submit'>Confirmer</Button>
                            </Button.Group>
                        </Form.Group>
                    </Form>
                )}
            />
        </Segment >
    );
}
export default observer(AddArticleServiceContent);
