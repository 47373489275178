import React, { useContext, useEffect, useState } from 'react';
import { GridColumn, Grid, Loader } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../app/store/baseStore';
import Breadcrumbs from '../dashboard/breadcrumbs';
import ListItemPlaceholder from '../../app/common/form/ListItemPlaceholder';
import ReliquatsFilter from './ReliquatsFilter';
import ReliquatsList from './ReliquatsList';
import InfiniteScroll from 'react-infinite-scroller';

const ReliquatsPage: React.FC = () => {

    const baseStore = useContext(BaseStoreContext);
    const { loadReliquatsList, loadingInitialReliquat, setPageReliquat, pageReliquats, totalPageReliquats } = baseStore.expeditionStore;
    const [loadingNext, setLoadingNext] = useState(false);

    const handleGetNext = () => {
        setLoadingNext(true);
        setPageReliquat(pageReliquats + 1);      
        loadReliquatsList().then(() => setLoadingNext(false));
    }

    useEffect(() => {
      loadReliquatsList();
    }, []);

    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Expédition", root: "" },
                    { key: 1, name: "Reliquats", root: "/reliquats" },
                ]}
            />

            <Grid>
                <GridColumn width={16}>

                    <Grid.Row style={{ padding: 0, margin: "10px 20px 0 20px" }}>
                        <ReliquatsFilter />
                    </Grid.Row>

                </GridColumn>
                <GridColumn width={16} >
                    {loadingInitialReliquat && pageReliquats === 0 ? <ListItemPlaceholder /> : (
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={handleGetNext}
                            hasMore={!loadingNext && pageReliquats + 1 < totalPageReliquats}
                            initialLoad={false}
                        >
                            <ReliquatsList />
                        </InfiniteScroll>
                    )}
                </GridColumn>
                <Grid.Column width={16} style={{ margin: 20 }}>
                    <Loader active={loadingNext} />
                </Grid.Column>
            </Grid>
        </div>
    );
}
export default observer(ReliquatsPage);