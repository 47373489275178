import { BaseStore } from "./baseStore";
import { observable, action, computed, runInAction, reaction, toJS } from 'mobx';
import agent from '../agent/agent';
import { toast } from "react-toastify";
import { setDevisProps } from "../common/util/util";
import { history } from '../../index';
import { IDeviCFList, IDevis } from "../models/IDevis";
import { IOrderLine } from "../models/ICommande";
import { IClient, IClientList } from "../models/IClient";
import { IFranchiseurList } from "../models/IFranchiseur";
import { IListRelatedElements } from "../models/IFacture";

const LIMIT = 20;

export default class DevisStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0;
                this.devisRegestry.clear();
                this.loadListDevis();
            }
        )
        reaction(
            () => this.activeTab,
            activeTab => {
                if (activeTab != '')
                    return true;
            }
        )
    }
    @observable loadingInitial = false;
    @observable loadingExport = false;
    @observable addingArticle = false;
    @observable deletingDevisArticle = false;
    @observable loadingDevis = false;
    @observable loadingQuoteLine = false;
    @observable submitting = false;
    @observable editDevisMode = false;
    @observable generatePdfMode = false;
    @observable deviss: IDevis[] = [];
    @observable devis: IDevis | null = null;
    @observable quoteLine: IOrderLine | null = null;
    @observable devisRegestry = new Map();
    @observable quoteLineRegestry = new Map();
    @observable devisCount: number = 0;
    @observable totalHt: number = 0;
    @observable totalTtc: number = 0;
    @observable predicate = new Map();
    @observable page = 0
    @observable activeTab: string = "";
    @observable progress: number = 50;
    @observable duplicationMode = false;
    @observable ITListClientsFranchis: IDeviCFList[] = [];
    @observable editState: boolean = false;
    @observable relatedElementsList: IListRelatedElements[] = [];
    @observable deletingQuote = false;


    @computed get axiosParams() {
        const params = new URLSearchParams();
        params.append('limit', String(LIMIT));
        params.append('offset', `${this.page ? this.page * LIMIT : 0}`);
        this.predicate.forEach((value, key) => {
            if (key === 'startDate') {
                params.append(key, value.toISOString())
            } if (key === 'searchMultiple') {
                var searchClient = [...value][0]
                var article = [...value][1];
                var search = [...value][2];
                var key = [...value][3];
                var sort = [...value][4];
                var dateDevis = [...value][5];
                var dateDevisTo = [...value][6];
                var searchClientAll = [...value][7];
                params.append(searchClient[0], searchClient[1])
                params.append(article[0], article[1])
                params.append(search[0], search[1])
                params.append(key[0], key[1])
                params.append(sort[0], sort[1])
                params.append(dateDevis[0], dateDevis[1])
                params.append(dateDevisTo[0], dateDevisTo[1])
                params.append(searchClientAll[0], searchClientAll[1])
            } else {
                params.append(key, value)
            }
        })
        return params;
    }
    

    @computed get totalPages() {
        return Math.ceil(this.devisCount / LIMIT);
    }


    @action setPage = (page: number) => {
        this.page = page;
    }

    @action setPredicate = (predicate: string, value: any) => {
        if (predicate != 'all')
            this.deviss = [];
        this.predicate.clear();
        if (predicate !== 'searchMultiple') {
            this.predicate.clear();
        }
        if (predicate !== 'all') {
            this.predicate.set(predicate, value);
        }
    }

    getQuoteLine = (id: number) => {
        return this.quoteLineRegestry.get(id);
    }

    sortByStatus(deviss: IDevis[]) {
        const sortedDevis = deviss.sort(
            (a, b) => new Date(a.quoteDate).getTime() - new Date(b.quoteDate).getTime());
        const sotedByStatus = sortedDevis.sort((a, b) => a.quoteState.localeCompare(b.quoteState));

        return Object.entries(
            sotedByStatus.reduce(
                (deviss, devis) => {

                    const cate = devis.quoteState;
                    deviss[cate] = deviss[cate]
                        ? [...deviss[cate], devis]
                        : [devis];
                    return deviss;
                },
                {} as { [key: string]: IDevis[] }
            )
        );
    }

    @action changeButtonState = async (newState: boolean) => {
        this.editDevisMode = newState;
    }

    @action changeButtonPdfState = async (newState: boolean) => {
        this.generatePdfMode = newState;
    }

    @action changeDuplicationState = async (newState: boolean) => {
        this.duplicationMode = newState;
    }

    @action loadListDevis = async () => {
        this.loadingInitial = true;

        try {
            const devisEnveloppe = await agent.devisAgent.list(this.axiosParams);
            const { deviss, devisCount, totalHt,totalTtc } = devisEnveloppe;
            runInAction(() => {
                deviss.forEach((devis) => {
                    setDevisProps(devis);
                    this.devisRegestry.set(devis.id, devis);
                    if (this.page == 0) {
                        this.deviss = deviss;
                    } else {
                        this.deviss.push(devis);
                    }
                })
                this.devisCount = devisCount;
                this.totalTtc = totalTtc;
                this.totalHt = totalHt;
                this.loadingInitial = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingInitial = false;
            })
            console.log(e);
        }
    }

    @action create = async (values: Partial<IDevis>) => {
        this.submitting = true;
        try {
            var id = await agent.devisAgent.create(values);
            
            runInAction(() => {
                this.devisRegestry.set(values.id, values)
                this.submitting = false;
                toast.success("ajout effectué avec succès");
            })
            history.push(`/detailDevis/${id}`);
        } catch (e) {
            toast.error("Problème d'ajout d'un devis!");
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    @action dupliquer = async (id: number) => {
       this.submitting = true;
       try {
          var quote= await agent.devisAgent.duplicate(id);
            runInAction(() => {
                this.submitting = false;
               toast.success("devis dupliqué avec succès");
           })
            history.push(`/detailDevis/${quote}`);
        } catch (e) {
           toast.error("Problème de duplication!");
           runInAction(() => {
               this.submitting = false;
               console.log(e);
           })
       }
    }

    @action loadDevis = async (id: string) => {
        this.loadingDevis = true;
            try {
                let devis = await agent.devisAgent.details(id);
                runInAction(() => {
                    this.devis = devis;
                    this.devisRegestry.set(devis.id, devis);
                    this.loadingDevis = false;
                })
                return devis;
            } catch (e) {
                runInAction(() => {
                    this.loadingDevis = false;
                })
                console.log(e);
            }
    }

    @action editDevis = async (values: Partial<IDevis>) => {
        this.submitting = true;
        try {
            await agent.devisAgent.update(values);
            runInAction(() => {
                this.devisRegestry.set(values.id, values);
                this.devis = { ...this.devis!, ...values };
                this.submitting = false;
                this.changeButtonState(false);
                this.loadingInitial = true;
                toast.success("Devis modifié avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action editAdressesDevis = async (id : number, idAdresse : string ) => {
        this.submitting = true;
        try {
            await agent.devisAgent.updateAdresseDevis(id, idAdresse);
            runInAction(() => {
                this.submitting = false;
                this.changeButtonState(false);
                this.loadingInitial = true;
                this.loadingDevis = true;
                this.loadDevis(id.toString());
                toast.success("Adresse du devis modifiée avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    };

    @action editInformationClientDevis = async (values: Partial<IDevis>) => {
        this.submitting = true;
        try {
            await agent.devisAgent.updateInformationClientDevis(values);
            runInAction(() => {
                this.devisRegestry.set(values.id, values);
                this.devis = { ...this.devis!, ...values };
                this.submitting = false;
                this.changeButtonState(false);
                this.loadingInitial = true;
                this.loadingDevis = true;
                toast.success("devis modifiée avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    };

    @action clearActivity = () => {
        this.devis = null;
    };

    @action setActiveTab = async (activeIndex: string) => {
        runInAction(() => {
            this.activeTab = activeIndex;
        })
    }

    @action addArticle = async (quoteLine: IOrderLine, idDevis: number) => {
        this.addingArticle = true;
        try {
            const prices = await agent.devisAgent.addQuoteLine(quoteLine, idDevis);
            runInAction(() => {
                this.editDevisMode = false;
                this.devisRegestry.set(quoteLine.id, quoteLine)
                prices.orderLines.forEach((item) => {
                    this.devis!.orderLines.push(item);
                })
                this.devis!.amountWithoutTaxes = prices.ht;
                this.devis!.amountWithTaxes = prices.ttc;
                this.devis!.deliveryCosts = prices.deliveryCost;
                this.devis!.tvaPrices = prices.tvaPrices;
                this.addingArticle = false;
                toast.success("ajout effectué avec succès");
            })
        }
        catch (e) {
            console.log(e);
            toast.error("Problème de mise à jour article!");
            runInAction(() => {
                this.addingArticle = false;
            })
        }
    }

    @action deleteQuoteArticle = async (idDevis: number, quoteLine: IOrderLine) => {
        this.deletingDevisArticle = true;
        try {
            const prices = await agent.devisAgent.deleteDevisArticle(idDevis);
            runInAction(() => {
                this.devis!.orderLines = this.devis!.orderLines.filter(a => a.id !== idDevis);
                this.devis!.amountWithoutTaxes = prices.ht;
                this.devis!.amountWithTaxes = prices.ttc;
                this.devis!.deliveryCosts = prices.deliveryCost;
                this.devis!.tvaPrices = prices.tvaPrices;
                if (this.devis!.amountWithoutTaxes == 0)
                    this.devis!.quoteState = "EnAttente";
                this.deletingDevisArticle = false;
                toast.success("ligne de devis supprimée avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Probléme de suppression!");
            runInAction(() => {
                this.deletingDevisArticle = false;
            })
        }
    }

    @action addRemiseGlobal = async (values: Partial<IDevis>, typeRemise: string) => {
        this.submitting = true;
        try {
            await agent.commandeAgent.remiseGlobale(values, typeRemise);
            runInAction(() => {
                let devisDiscount = values.discount!;
                if (typeRemise === 'pourcentage')
                    devisDiscount = (values.discount! * values!.amountWithoutTaxes!) / 100;

                values!.amountWithoutTaxes! -= devisDiscount;
                this.devis = { ...this.devis!, ...values };
                this.submitting = false;
                this.changeButtonState(false);
                this.loadingInitial = true;
                toast.success("Remise ajoutée avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    };

    @action editQuoteLine = async (id: number, values: IOrderLine) => {
        this.submitting = true;
        this.progress = 80;
        try {
            const prices=await agent.devisAgent.updateQuoteLine(id, values);
            runInAction(() => {
                this.submitting = false;
                this.devis!.orderLines[this.devis!.orderLines.findIndex(x => x.id == values.id)] = values;
                this.devis!.amountWithoutTaxes = prices.ht;
                this.devis!.amountWithTaxes = prices.ttc;
                this.devis!.deliveryCosts = prices.deliveryCost;
                this.devis!.tvaPrices = prices.tvaPrices;
                this.devisRegestry.set(values.id, values);
                toast.success("Ligne de devis modifié avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action loadQuoteLine = async (id: number) => {
        this.loadingQuoteLine = true;
        let quoteLine = this.getQuoteLine(id);
        if (quoteLine) {
            this.quoteLine = quoteLine;
            this.loadingQuoteLine = false;
            return toJS(quoteLine);
        }
        else {
            try {
                quoteLine = await agent.devisAgent.detailQuoteLine(id);
                runInAction(() => {
                    this.quoteLine = quoteLine;
                    this.quoteLineRegestry.set(quoteLine.id, quoteLine);
                    this.loadingQuoteLine = false;
                })
                return quoteLine;
            } catch (e) {
                runInAction(() => {
                    this.loadingQuoteLine = false;
                })
                console.log(e);
            }
        }
    }

    @action devisCommande = async (id: number) => {
        this.submitting = true;
        try {
            var idCommande =await agent.devisAgent.transformation(id);
            runInAction(() => {
                this.submitting = false;
                toast.success("transformation faite avec succès");             
            })
            history.push(`/detailCommande/${idCommande}`);
        } catch (e) {
            toast.error("Problème de duplication!");
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    @action exportDevis = async (obj:any) => {
        this.loadingExport = true;
        try {
            await agent.devisAgent.exportExcel(obj);
            runInAction(() => {
                this.loadingExport = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingExport = false;
            })
            console.log(e);
        }
    }

    @action listClientsByFranchiseur = async (devis: IDevis) => {
        this.ITListClientsFranchis = [];
        this.loadingInitial = true;
        let newList: any = [];
        let listClient: IClient[] = [];

        const paramsObj = { limit: '', offset: '' };
        const searchParams = new URLSearchParams(paramsObj);

            await agent.franchiseurAgent.details(devis.companyId).then((data) => {
                let franchiseurById: IFranchiseurList = {
                    key: data?.agencyName.toString(),
                    text: data?.agencyName.toString() + ", " + data?.companyName,
                    value: data?.agencyName.toString()
                };
                newList.push(franchiseurById);
            });


            let id = devis.companyId;

            var clientEnveloppe = await agent.clientAgent.listClientsFranchise(id, searchParams);
            runInAction(() => {
                this.loadingInitial = false;
            })

            listClient = clientEnveloppe.clients;
            listClient.forEach((client) => {
                let clients: IClientList = {
                    key: client.agencyName,
                    text: client.agencyName,
                    value: client.agencyName
                };
                newList.push(clients);
            });
            runInAction(() => {
                this.ITListClientsFranchis = newList;
            })
  
    }

    @action enAttente = async (id: number) => {
        this.deletingDevisArticle = true;
        try {
            await agent.devisAgent.enAttente(id);
            runInAction(() => {
                this.deletingDevisArticle = false;
                this.devis!.quoteState = "EnAttente";
                toast.success("Devis En Attente");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de mettre le devis en attente!");
            runInAction(() => {
                this.deletingDevisArticle = false;
            })
        }
    }

    @action valide = async (id: number) => {
        this.deletingDevisArticle = true;
        try {
            var ref=await agent.devisAgent.valide(id);
            runInAction(() => {
                this.deletingDevisArticle = false;
                this.devis!.quoteState = "Valide";
                this.devis!.reference = ref+'';
                toast.success("Devis Valide");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de validation de devis!");
            runInAction(() => {
                this.deletingDevisArticle = false;
            })
        }
    };
    @action generatePDF = async (id: string) => {
        try {
            await agent.devisAgent.generatePDF(id);
        }
        catch (e) {
            console.log(e);
        }
    }

    @action getRelatedObjectForDevis = async (id: number) => {
        this.submitting = true;
        this.relatedElementsList = [];
        try {
            var result = await agent.factureAgent.relatedObject(id,"Devis");
            runInAction(() => {
                this.relatedElementsList = result;
                this.submitting = false;

            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Aucun objet liés");
        }
    }

    @action deleteQuote = async (id: number) => {
        this.deletingQuote = true;
        try {
            await agent.devisAgent.deleteQuote(id);
            runInAction(() => {
                this.deletingQuote = false;
                this.deviss = this.deviss.filter(a => a.id !== id);
                toast.success("suppression éffectuée avec succès");
            })
        } catch (e) {
            toast.error("Problème de suppresion !");
            runInAction(() => {
                this.deletingQuote = false;
            })
        }
    }

}