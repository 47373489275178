import { useContext, useEffect } from 'react';
import { Tab, Header, Button, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import NotFound from '../../../app/layout/NoFound';
import Specificationfranchiseureditform from '../DetailFranchiseur/specificationFranchiseurEditForm';
import FormGridColumn from '../../../app/common/form/FormGridColumn';
const Specification = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { franchiseur, editFranchiseurspecification, submitting } = baseRepo.franchiseurStore;
    useEffect(() => {
        baseRepo.franchiseurStore.changeButtonState(false)
    }, [])
    if (!franchiseur) {
        return <NotFound />
    }
    else {
        return (
            <Tab.Pane>
                <Grid>
                    <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                        <Header floated='left' icon='address card' content={`Informations de livraison`} />
                        <Button
                            onClick={() => { baseRepo.franchiseurStore.changeButtonState(!baseRepo.franchiseurStore.editFranchiseurMode) }}
                            floated='right'
                            basic
                            content={baseRepo.franchiseurStore.editFranchiseurMode ? 'Annuler' : 'Modifier'}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        {baseRepo.franchiseurStore.editFranchiseurMode ? (
                            <Specificationfranchiseureditform editFranchiseur={editFranchiseurspecification} franchiseur={franchiseur!} loading={submitting} />
                        ) : (
                            <Grid className="ui stackable">
                                    <Grid.Row>
                                        <FormGridColumn icon="calendar times" header="Jours de fermeture" content={franchiseur!.deliveryClosingDays} />
                                        <FormGridColumn icon="calendar times" header="Horaires d'ouverture" content={franchiseur!.deliveryOpeningHours} />
                                        <FormGridColumn icon="qrcode" header="Digit code" content={franchiseur!.deliveryDigiCode} />
                                        <FormGridColumn icon="qrcode" header="Interphone" content={franchiseur!.deliveryInterphone} />
                                </Grid.Row>
                            </Grid>
                        )}
                    </Grid.Column>
                </Grid>
            </Tab.Pane>
        );
    }
}
export default observer(Specification);