import React from 'react';
import { Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import AddCommandeContent from './AddCommandeContent';
import AddCommandeHeader from './AddCommandeHeader';
import Breadcrumbs from '../../dashboard/breadcrumbs';

const AddCommandePage: React.FC = () => {
    return (
        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Vente", root: "" },
                    { key: 1, name: "Liste des commandes", root: "/CommandeDashboard" },
                    { key: 2, name: "Ajouter_Commande", root: "/addCommande" },
                ]}
            />
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Grid style={{ marginTop: 2 }}>
                            <Grid.Column width={16}>
                                <AddCommandeHeader />
                                <AddCommandeContent/>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>

                </Grid.Row>
            </Grid>
        </div>
    );
}
export default observer(AddCommandePage);