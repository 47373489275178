import { BaseStore } from "./baseStore";
import { observable, action, runInAction } from 'mobx';
import agent from '../agent/agent';
import { toast } from "react-toastify";
import { IFormeTarifFournisseurList, ITarifFournisseur, ITarifFournisseurList } from "../models/ITarifFournisseur";

export default class TarifFournisseurStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
    }
    @observable loadingTarifFournisseurAction = false;
    @observable loadingInitial = false;
    @observable deletingtarifFournisseur = false;
    @observable submitting = false;
    @observable tarif: ITarifFournisseur | null = null;
    @observable tarifFournisseurs: ITarifFournisseur[] = [];
    @observable tarifProductFournisseurs: ITarifFournisseur[] = [];
    @observable tarifRegestry = new Map();
    @observable editTarifFournisseur: boolean = false;
    @observable addTarifFournisseur: boolean = false;
    @observable loadingTarifFournisseur = false;
    @observable ITListFormeTarifFournisseur: IFormeTarifFournisseurList[] = [];

    @action changeButtonState = async (newState: boolean) => {
        this.editTarifFournisseur = newState;
    }

    @action changeButtonStateAdd = async (newState: boolean) => {
        this.addTarifFournisseur = newState;
    }

    @action loadTarifFournisseurList = async (id: number) => {
        this.loadingInitial = true;
        this.tarifFournisseurs = [];
        try {
            const tarifFournisseur = await agent.tarifFournisseurAgent.list(id);
            runInAction(() => {
                this.tarifFournisseurs = tarifFournisseur;
                this.loadingInitial = false;
            })
            return tarifFournisseur;
        } catch (e) {
            toast.error("Problem loading forme tarif!");
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }

    @action loadTarifFournisseurByProduct = async (id: number) => {
        this.loadingInitial = true;
        this.tarifProductFournisseurs = [];
        try {
            const tarifFournisseur = await agent.tarifFournisseurAgent.listByProduct(id);
            runInAction(() => {
                this.tarifProductFournisseurs = tarifFournisseur;
                this.loadingInitial = false;
            })
        } catch (e) {
            toast.error("Problem loading forme tarif!");
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }

    @action loadTarifFournisseur = async (id: number) => {

        this.loadingTarifFournisseur = true;
        let tarifs = this.getTarifFournisser(id);
        if (tarifs) {
            this.tarif = tarifs;
            this.loadingTarifFournisseur = false;
            return tarifs;
        }
        else {
            try {
                tarifs = await agent.tarifFournisseurAgent.details(id);
                runInAction(() => {
                    tarifs.dateCreation = tarifs.dateCreation.split('T')[0];
                    this.tarifRegestry.set(tarifs.familleId, tarifs);
                    this.tarif = tarifs;
                    this.loadingTarifFournisseur = false;
                })
                return tarifs;
            } catch (e) {
                runInAction(() => {
                    this.loadingTarifFournisseur = false;
                })
                console.log(e);
            }
        }
    }

    @action create = async (values: ITarifFournisseur) => {
        this.submitting = true;
        try {
            var tarif=await agent.tarifFournisseurAgent.create(values);
            runInAction(() => {
                this.tarifProductFournisseurs.push(tarif);
                this.submitting = false;
                toast.success("ajout éffectué avec succès");
            })

        } catch (e) {
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    getTarifFournisser = (id: number) => {
        return this.tarifRegestry.get(id);
    }

    @action edit = async (values: Partial<ITarifFournisseur>) => {
        this.submitting = true;
        try {
            await agent.tarifFournisseurAgent.update(values);
            runInAction(() => {

                var index = this.tarifProductFournisseurs.findIndex(a => a.id == values.id);
                if (index != -1)
                {
                    this.tarifProductFournisseurs[index].quantite = values.quantite!
                    this.tarifProductFournisseurs[index].prix = values.prix!
                    this.tarifProductFournisseurs[index].productReference = values.productReference!
                    this.tarifProductFournisseurs[index].dateCreation = values.dateCreation!
                }
                
                toast.success("Modification éffectuée avec succès");
                this.submitting = false;
            })

        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action deleteTarif = async (id: number) => {
        this.loadingTarifFournisseurAction = true;
        try {
            await agent.tarifFournisseurAgent.delete(id);
            runInAction(() => {
                this.loadingTarifFournisseurAction = false;
                this.tarifProductFournisseurs = this.tarifProductFournisseurs.filter(a => a.id != id);
                toast.success("suppression éffectuée avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de suppréssion!");
            runInAction(() => {
                this.loadingTarifFournisseurAction = false;
            })
        }
    }

    @action loadTarifFournisseurByTypeAndQuantite = async (id: number, type: string, quantite: number) => {
        try {
            var tarifs = (await agent.tarifFournisseurAgent.detailsTarifByQuantite(id, type, quantite)).prix;
            return tarifs;
        } catch (e) {
            toast.error("Problem loading !");
            console.log(e);
        }
    }
}