import React, { useContext, useEffect } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button, Grid } from 'semantic-ui-react';
import TextInput from '../../../app/common/form/inputText';
import { observer } from 'mobx-react-lite';
import { IFournisseur } from '../../../app/models/IFournisseur';
import { BaseStoreContext } from '../../../app/store/baseStore';
import InputSelect from '../../../app/common/form/inputSelect';
import { ITListTypeDevise } from '../../../app/common/options/IAchat';

interface IProps {
    editFournisseur: (profile: Partial<IFournisseur>) => void;
    fournisseur: IFournisseur;
}

const FournisseurComptabiliteEditForm: React.FC<IProps> = ({ editFournisseur, fournisseur }) => {
    const baseStore = useContext(BaseStoreContext);
    const { loadModePaiements, ITListModePaiements } = baseStore.modePaiementStore;
    const { loadModeReglements, ITListModeReglement } = baseStore.modeReglementStore;
    useEffect(() => {
        loadModePaiements();
        loadModeReglements();
    }, [loadModePaiements, loadModeReglements])
    const handleFinalFormSubmit = (values: any) => {
        let newFournisseur = {
            ...values,
        };
        editFournisseur(newFournisseur);
    }
    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            initialValues={fournisseur!}
            render={({ handleSubmit, invalid, submitting }) => (
                <Form onSubmit={handleSubmit} error>
                    <Grid columns={2} >
                        <Grid.Row>
                            <Grid.Column>
                                <h5>Compte Collectif</h5>
                                <Field name='compteCollectif' component={TextInput} placeholder='Compte Collectif' type='text' value={fournisseur!.compteCollectif} />
                            </Grid.Column>
                            <Grid.Column>
                                <h5 >Compte Auxiliere</h5>
                                <Field name='compteAuxiliere' component={TextInput} placeholder='Compte Auxiliere' type='text' value={fournisseur!.compteAuxiliere} />
                            </Grid.Column>                        
                     
                            {/*<Grid.Column>*/}
                            {/*    <h5>Status Compte</h5>*/}
                            {/*    <Field name='statusCompte' component={TextInput} placeholder='Status Compte' type='text' value={fournisseur!.statusCompte} />*/}
                            {/*</Grid.Column>*/}                           
                            <Grid.Column>
                                <h5>Echéance </h5>
                                <Field component={InputSelect} options={ITListModeReglement} placeholder={fournisseur!.modeReglement} name='modeReglement' defaultValue={fournisseur!.modeReglement} value={fournisseur!.modeReglement} />
                            </Grid.Column>
                            <Grid.Column>
                                <h5>Mode de paiement </h5>
                                <Field component={InputSelect} options={ITListModePaiements} placeholder={fournisseur!.modePaiement} name='modePaiement' defaultValue={fournisseur!.modePaiement} value={fournisseur!.modePaiement} />
                            </Grid.Column>
                            <Grid.Column>
                                <h5>Devise</h5>
                                <Field name='devise' component={InputSelect} options={ITListTypeDevise} placeholder={fournisseur!.devise} value={fournisseur!.devise} />
                            </Grid.Column>
                            <Grid.Column>
                                <h5 >Societe</h5>
                                <Field name='societe' component={TextInput} placeholder='Societe' type='text' value={fournisseur!.societe} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                        </Grid.Row>
                    </Grid>
                    <Button.Group floated="right">
                        <Button disabled={submitting} onClick={() => { baseStore.fournisseurStore.changeButtonState(!baseStore.fournisseurStore.editFournisseurMode) }} floated='right' type='button'>Annuler</Button>
                        <Button.Or />
                        <Button positive disabled={submitting || invalid} loading={submitting} floated='right' type='submit'>Confirmer</Button>
                    </Button.Group>
                </Form>
            )}
        />
    );
};

export default observer(FournisseurComptabiliteEditForm);