import React, { useContext, useState } from 'react';
import { Table, Header, Label, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { Link } from 'react-router-dom';
import NoResult from './erreurNoResult';
import DevisMultipleSearch from './DevisMultipleSearch';
import DeleteDevisModal from './DeleteDevisModal';

const getStatus = (status: string) => {
    switch (status) {
        case 'EnAttente':
            return 'En Attente';
        case 'Valide':
            return 'Validé';
        case 'Transformed':
            return 'Commandé';
        default:
            return '';
    }
}


const ListDevis: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { deviss, deletingQuote } = baseStore.devisStore;
    const { openModal } = baseStore.modalStore;
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    if (deviss.length < 1) return < NoResult name="Devis" />
    else
        return (
            <>
                <Table compact='very' style={{ marginTop: '0em' }} textAlign='center'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Référence</Table.HeaderCell>
                            <Table.HeaderCell>Client</Table.HeaderCell>
                            <Table.HeaderCell>Date Devis</Table.HeaderCell>
                            <Table.HeaderCell>Montant HT</Table.HeaderCell>
                            <Table.HeaderCell>TVA</Table.HeaderCell>
                            <Table.HeaderCell>Montant TTC</Table.HeaderCell>
                            <Table.HeaderCell>Etat</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {deviss && deviss.map((devis: any) => (
                            <Table.Row key={devis.id}>
                                <Table.Cell>
                                    <Header size='small'><Label style={{ marginLeft: '1px', marginTop: '5px', marginBottom: '5px' }} as={Link} to={`/detailDevis/${devis.id}`}>{devis.reference}</Label></Header>
                                </Table.Cell>
                                <Table.Cell>{devis.agencyName} - {devis.companyName}</Table.Cell>
                                <Table.Cell>{new Date(devis.quoteDate).toLocaleDateString('en-GB')}</Table.Cell>
                                <Table.Cell>{devis.amountWithoutTaxes.toFixed(2)}</Table.Cell>
                                <Table.Cell>{(devis.amountWithTaxes - devis.amountWithoutTaxes).toFixed(2)}</Table.Cell>
                                <Table.Cell>{devis.amountWithTaxes.toFixed(2)}</Table.Cell>
                                <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }} >{getStatus(devis.quoteState)}</Table.Cell>
                                <Table.Cell>
                                    {devis.quoteState === "EnAttente" || devis.quoteState === "Valide" ? (
                                        <Button.Group fluid widths={3}>
                                            <Button
                                                style={{ marginRight: '3%' }}
                                                name={devis.id}
                                                basic
                                                color='red'
                                                icon='trash'
                                                onClick={(e) => {
                                                    openModal(<DeleteDevisModal devis={devis} />)
                                                    setDeleteTarget(e.currentTarget.name)
                                                }}
                                                loading={deletingQuote && deleteTarget === devis.id}
                                            />
                                        </Button.Group>
                                    ) : (<></>
                                    )}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </>
        );
}

export default observer(ListDevis);