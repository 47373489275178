import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";
import { Button, Form, FormInput, Grid, Label } from "semantic-ui-react";
import { BaseStoreContext } from "../../app/store/baseStore";
import { Form as FinalForm } from 'react-final-form';
import Select, { components } from "react-select";
import { FaFileExcel, FaSearch, FaSort } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { numberFormat } from "../../app/common/options/IStyle";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import AsyncSelect from "react-select/async";

const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <FaSort color='green' />
        </components.DropdownIndicator>
    );
};

const customStyles = {
    option: (provided: any, state: { isSelected: any; }) => ({
        ...provided,
        color: state.isSelected ? 'orange' : 'grey',
        backgroundColor: state.isSelected ? "#eee" : "white",
        borderBottom: '1px solid #eee',
    }),
    valueContainer: (props: any) => ({
        ...props,
        paddingTop: 0,
        height: '39.201px',
        minHeight: '39.201px',
    }),
    control: (baseStyles: any, state: any) => ({
        ...baseStyles,
        height: '39.201px',
        minHeight: '39.201px',
    }),
}


const PreparationFilter = () => {
    const { RangePicker } = DatePicker;
    const baseStore = useContext(BaseStoreContext);
    const { setPredicate, totalMontant, loadingInitial, exportPreparations, loadingExport } = baseStore.preparationStore;
    const [searchResults, setSearchResults] = useState([]);
    const [searchCommande, setSearchCommande] = useState<any>('');
    const [selectedLabelSort, setSelectedLabelSort] = useState<any>('Filtrer ...');
    const [selectedKey, setSelectedKey] = useState<any>('');
    const [selectedSort, setSelectedSort] = useState<any>('');
    const [selectedLabelTri, setSelectedTri] = useState<any>('Trier...');
    const [searchDateCommande, setSearchDateCommande] = useState<any>('');
    const [searchDateCommandeTo, setSearchDateCommandeTo] = useState<any>('');
    const [searchResultsClientAll, setSearchResultsClientAll] = useState(false);
    const [searchClient, setSearchClient] = useState<any>('');
    const [selectedLabelClient, setSelectedLabelClient] = useState<any>('Client');
    const options = [
        { key: 'All', label: 'Tous' },
        { key: 'EnAttente', label: 'En Attente' },
        { key: 'EnCours', label: 'En Cours' },
        { key: 'Termine', label: 'Terminée' },
        { key: 'Cloture', label: 'Clôturée' },
        { key: 'Expedie', label: 'Expediée' },
        { key: 'Annule', label: 'Annulé' },
    ];
    const optionsTri = [
        { key: 'DateUp', text: 'Plus recent', value: 'DateUp' },
        { key: 'DateDown', text: 'Plus ancien', value: 'DateDown' },
        ,
    ];
    const rangePresets: {
        label: string;
        value: [Dayjs, Dayjs];
    }[] = [
            { label: '7 derniers jours', value: [dayjs().add(-7, 'd'), dayjs()] },
            { label: '14 derniers jours', value: [dayjs().add(-14, 'd'), dayjs()] },
            { label: '30 derniers jours', value: [dayjs().add(-30, 'd'), dayjs()] },
            { label: '90 derniers jours', value: [dayjs().add(-90, 'd'), dayjs()] },
        ];
    const handleChange = (event: any) => {
        setSearchResults(event.target.value);
    }
    const handleChangeCommande = (event: any) => {
        setSearchCommande(event.target.value);
    }
    const handleFilter = (key: string, value: string) => {
        setSelectedKey(key);
        setSelectedLabelSort(value)
    }
    const handleChangeTri = (value: any) => {
        setSelectedSort(value.value);
        let labelSelected: any = value.text;
        setSelectedTri(labelSelected);
    }
    const handleChangeClient = (value: any) => {
        setSearchClient(value.id);
        setSearchResultsClientAll(value.all);
        setSelectedLabelClient(value!.agencyName + "-" + value!.companyName + (value!.all ? "-Tous les réseaux" : ""));
    }
    const onRangeChange = (dates: null | (Dayjs | null)[]) => {
        if (dates) {
            setSearchDateCommande(dates[0]?.toISOString());
            setSearchDateCommandeTo(dates[1]?.toISOString());
        } else {
            setSearchDateCommande("");
            setSearchDateCommandeTo("");
        }
    };
    const handleSearch = (event: any) => {
        if (event == 'clique') {
            let obj: any = {
                search: searchResults,
                key: selectedKey,
                sort: selectedSort,
                dateCommande: searchDateCommande,
                dateCommandeTo: searchDateCommandeTo,
                searchCommande: searchCommande,
                searchClientAll: searchResultsClientAll,
                searchClient: searchClient,
                selectedLabelClient: selectedLabelClient,
            }; exportPreparations(obj);
        }
        else if (event == 'clear') {
            setSelectedKey('');
            setSelectedSort(null)
            setSearchCommande('')
            setSelectedLabelSort('Filtrer ...')
            setSelectedTri('Trier ...')
            setSearchDateCommande('')
            setSearchDateCommandeTo('')
            setSearchResults([])
            setSelectedLabelClient('Client')
            setSearchResultsClientAll(false);
            setSearchClient('')
            onRangeChange(null);
            setPredicate('all', 'true')
        }
        else if (event == 'submit') {
            var predicate = new Map()
            predicate.set('search', searchResults)
            predicate.set('searchCommande', searchCommande)
            predicate.set('searchClient', searchClient)
            predicate.set('key', selectedKey)
            predicate.set('sort', selectedSort)
            predicate.set("dateCommande", searchDateCommande);
            predicate.set("dateCommandeTo", searchDateCommandeTo);
            predicate.set('searchClientAll', searchResultsClientAll)
            if (searchResults!.length > 2 || searchClient != "" || searchCommande! >0 || selectedKey != undefined || selectedSort != undefined
                || searchDateCommande != undefined || searchDateCommandeTo != undefined)
                setPredicate('searchMultiple', predicate)
            if (searchResults!.length == 0 && searchClient.length == 0 && searchCommande==0 && selectedKey == '' && selectedSort == ''
                && searchDateCommande == '' && searchDateCommandeTo == '')
                setPredicate('all', 'true')
        }
    }
    return (
        <FinalForm
            onSubmit={handleSearch}
            render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit} error style={{ width: "100%", fontSize: 14 }}>
                    <Grid>
                        <Grid.Row columns={6} style={{ padding: 2, margin: 0 }}>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <FormInput
                                    icon='search'
                                    type="text"
                                    placeholder="Préparation"
                                    value={searchResults}
                                    onChange={handleChange}
                                    style={{ width: '100%' }}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <FormInput
                                    icon='search'
                                    type="text"
                                    placeholder="Ref Commande"
                                    value={searchCommande}
                                    onChange={handleChangeCommande}
                                    style={{ width: '100%' }}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <AsyncSelect
                                    name="form-field-name"
                                    noOptionsMessage={() => "Code introuvable"}
                                    cacheOptions
                                    defaultOptions
                                    placeholder={selectedLabelClient}
                                    value={searchClient}
                                    getOptionLabel={e => e!.agencyName + "-" + e!.companyName + (e!.all ? "-Tous les réseaux" : "")}
                                    loadOptions={baseStore.clientStore.loadOptions}
                                    onChange={handleChangeClient}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <RangePicker
                                    presets={rangePresets}
                                    onChange={onRangeChange}
                                    format={'DD/MM/YYYY'}
                                    placeholder={["Début", "Fin"]}
                                    style={{ width: '100%', height: '39.201px' }}
                                />

                            </Grid.Column>

                            <Grid.Column style={{ padding: 2, margin: 0 }}>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable={false}
                                    components={{ DropdownIndicator }}
                                    styles={customStyles}
                                    options={options}
                                    value={selectedKey}
                                    getOptionLabel={e => e.label}
                                    onChange={(e) => handleFilter(e.key, e.label)}
                                    placeholder={selectedLabelSort ?? 'Filtrer les articles'}
                                    isClearable={true}

                                />
                            </Grid.Column>
                            <Grid.Column style={{ padding: 2, margin: 0, display: 'flex' }}>
                                <Select
                                    isSearchable={false}
                                    components={{ DropdownIndicator }}
                                    styles={customStyles}
                                    options={optionsTri}
                                    value={selectedSort}
                                    getOptionLabel={e => e.text}
                                    onChange={(e) => handleChangeTri(e)}
                                    placeholder={selectedLabelTri} />
                                <Button style={{ padding: 5, margin: "0 0 0 10px", color: 'blue', background: "none" }} type="submit" onClick={(e: any) => handleSearch('submit')} >
                                    <FaSearch  />
                                </Button>
                                <Button style={{ float: "right", padding: 5, margin: 0, color: 'green', background: "none" }} >
                                    <MdCancel style={{ fontSize: 17 }} onClick={(e: any) => handleSearch('clear')} />
                                </Button>
                            </Grid.Column >
                        </Grid.Row>
                    </Grid>
                    {!loadingInitial &&
                        <>
                        <Label>Total montant : {numberFormat.format(totalMontant)}</Label>
                        </>
                    }
                    <Button
                        size="tiny"
                        loading={loadingExport}
                        style={{ height: 32 }}
                        floated='right'
                        content="Export Excel"
                        className="andi-btn filled success"
                        icon={(<FaFileExcel style={{ marginRight: "10px" }} />)}
                        onClick={(e: any) => handleSearch('clique')}

                    />
                </Form >
            )}
        />
    );
};
export default observer(PreparationFilter);