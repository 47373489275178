import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { Form, Button, Input } from 'semantic-ui-react';
import { Field, Form as FinalForm } from 'react-final-form';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../app/store/baseStore';
import { IPassword, IResponsable, IUserCompany } from '../../app/models/IUser';


interface IProps {
    user: IUserCompany ;
    setModalOpen: Dispatch<SetStateAction<boolean>>;
}

const EditUserModal: React.FC<IProps> = ({ user, setModalOpen }) => {

    const baseStore = useContext(BaseStoreContext);
    const { submitting, editPassword } = baseStore.userStore;
    const [newPasswd, setNewPassword] = useState("");
    const [confirmPassword, setconfirmPassword] = useState();
    const [label, setLabel] = useState<string>();
    const [correct, setCorrect] = useState(false);

    const handleChangePassword = (e: any) => {
        setNewPassword(e.target.value);
        if (e.target.value != confirmPassword) {
            setCorrect(false);
            setLabel("Vérifier votre mot de passe");
        } else {
            setCorrect(true);
            setLabel("Mot de passe correcte");
            setconfirmPassword(e.target.value);
        }
    }
    const handleChangeConfirmPasswd = (e: any, result: any) => {
        if (result.value != newPasswd) {
            setCorrect(false);
            setLabel("Vérifier votre mot de passe");
            setconfirmPassword(result.value);
        } else {
            setCorrect(true);
            setLabel("Mot de passe correcte");
            setconfirmPassword(result.value);
        }
    }
    const handleFinalFormSubmit = (values: any) => {
        let newPassword: IPassword = {          
            id: user!.id,
            newPassword: newPasswd,
        }
        editPassword(newPassword);
        setModalOpen(false);
    }

    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            render={({ handleSubmit, invalid }) => (
                <Form onSubmit={handleSubmit} error >
                    <Form.Group unstackable widths={1}>
                        <div className="field">
                            <label>Nouveau mot de passe</label>
                            <Input name='newPassword' placeholder="Nouveau mot de passe" onChange={handleChangePassword} type='text' style={{ width: 270 }} />
                        </div>                       
                    </Form.Group>
                    <Form.Group unstackable widths={1}>
                        <div className="field">
                            <label>Confirmation du nouveau mot de passe</label>
                            <Input placeholder="Confirmation du nouveau mot de passe" onChange={handleChangeConfirmPasswd} type='text' style={{ width: '100%' }} />
                            {confirmPassword && ((confirmPassword != newPasswd) ? <p className="ui pointing above prompt label " >{label}</p> : <p className="ui pointing green basic label" >{label}</p>)}
                        </div>
                    </Form.Group>
                    <Form.Group unstackable style={{ marginLeft: "70%" }} >
                        <Button.Group floated="right">
                            <Button onClick={() => { setModalOpen(false) }} floated='right' type='button' icon='x'/>
                            <Button.Or />
                            <Button positive disabled={submitting || invalid || !correct} loading={submitting} floated='right' type='submit' icon='check'/>
                        </Button.Group>
                    </Form.Group>
                </Form>
            )}
        />
    );
}
export default observer(EditUserModal);
