import React, { useContext, useState } from 'react';
import { Tab, Header, Card, Image, Button, Grid } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import PhotoUploadWidget from '../../../app/common/photoUpload/PhotoUploadWidget';
import { observer } from 'mobx-react-lite';

const ArticlePhoto = () => {
    const baseStore = useContext(BaseStoreContext)
    const { article, updatePhoto, uploadPhoto, uploadingPhoto, setMainPhoto, deletePhoto, loadingPhotoAction } = baseStore.articleStore;
    const [addPhotoMode, setAddPhotoMode] = useState(false);
    const [updatePhotoMode, setUpdatePhotoMode] = useState(false);
    const [target, setTarget] = useState<string | undefined>(undefined);
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    const [updateTarget, setUpdateTarget] = useState<string | undefined>(undefined);
    const handleUploadImage = (photo: Blob, id = article!.productId) => {
        uploadPhoto(photo, id).then(() => setAddPhotoMode(false));
    }
    const handleUpdateImage = (photo: Blob, id = article!.productId) => {
        updatePhoto(photo, id, updateTarget!).then(() => setUpdatePhotoMode(false));
    }
    return (
        <Tab.Pane>
            <Grid>
                <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                    <Header floated='left' icon='image' content='Logo' />
                    <Button
                        onClick={() => setAddPhotoMode(!addPhotoMode)}
                        floated='right'
                        basic
                        disabled={article!.articlePhotos.length == 1}
                        style={{ display: !updatePhotoMode ? "block" : "none" }}
                        content={addPhotoMode ? 'Annuler' : 'Ajouter Logo'}
                    />
                    <Button
                        onClick={() => setUpdatePhotoMode(false)}
                        floated='right'
                        basic
                        style={{ display: updatePhotoMode? "block":"none"}}
                        content={'Annuler'}
                    />
                </Grid.Column>
                <Grid.Column width={16}>
                    {addPhotoMode ? (
                        <PhotoUploadWidget
                            uploadPhoto={handleUploadImage}
                            updatePhoto={handleUpdateImage}
                            loading={uploadingPhoto}
                            isUpdate={false}
                        />
                    ) : (
                            updatePhotoMode ? (
                        <PhotoUploadWidget
                            uploadPhoto={handleUploadImage}
                            updatePhoto={handleUpdateImage}
                            loading={uploadingPhoto}
                            isUpdate={true}
                        />):(
                        <Card.Group itemsPerRow={5}>
                            {article &&
                                article.articlePhotos.map(photo => (
                                    <Card key={photo.id}>
                                        <Image src={photo.url} />
                                        {(
                                            <Button.Group fluid widths={5}>
                                                <Button
                                                    onClick={e => {
                                                        setMainPhoto(photo, article!.productId);
                                                        setTarget(e.currentTarget.name);
                                                    }}
                                                    name={photo.id}
                                                    disabled={photo.isMain}
                                                    loading={loadingPhotoAction && target === photo.id}
                                                    basic
                                                    positive
                                                    content='Eco'
                                                />
                                                <Button
                                                    name={photo.id}
                                                    onClick={(e) => {
                                                        
                                                        setUpdateTarget(e.currentTarget.name)
                                                        setUpdatePhotoMode(true)
                                                    }}
                                                    loading={loadingPhotoAction && deleteTarget === photo.id}
                                                    basic
                                                    color="grey"
                                                    icon='edit'
                                                />
                                                <Button
                                                    name={photo.id}
                                                    onClick={(e) => {
                                                        deletePhoto(photo, article!.productId);
                                                        setDeleteTarget(e.currentTarget.name)
                                                    }}
                                                    loading={loadingPhotoAction && deleteTarget === photo.id}
                                                    basic
                                                    negative
                                                    icon='trash'
                                                />
                                            </Button.Group>
                                        )}
                                    </Card>
                                ))}
                        </Card.Group>
                    ))}
                </Grid.Column>
            </Grid>
        </Tab.Pane>
    );
};
export default observer(ArticlePhoto);
